import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import authStyles from '../authStyles';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '../../../components/TextField/TextField';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormikSelect from '../../../components/Formik/FormikSelect';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { buildDropdown } from '../../../utilities/utils';
import { RESTRICTED, UNRESTRICTED, CUSTOM } from '../../../constants/equities';
import { CLASS_CATEGORY_DESCRIPTION_MAXIMUM_LENGTH } from '../../../constants/assets';
import RadioButtons from '../../../components/RadioButtons';

const EquityInformationCategory = (props) => {
    const classes = authStyles();
    let defaultClassCategoryNumber = [];
    props.initialValues.classes.forEach(function (class_element) {
        defaultClassCategoryNumber[
            'number_of_class_categories_' + class_element.class_description
        ] = 1;
    });

    const [classCategoryNumber, setClassCategoryNumber] = useState(
        defaultClassCategoryNumber
    );

    const nextStep = () => {
        props.nextStep();
    };

    const listCategories = (name, class_description, values) => {
        let items = [];
        let categories = props.initialValues.categories.filter((element) => {
            return element.class_description === class_description;
        });

        if (categories.length !== 0) {
            let end =
                classCategoryNumber[name] < categories.length
                    ? classCategoryNumber[name]
                    : categories.length;
            for (var i = 0; i < end; i++) {
                items.push(
                    <Grid container spacing={1}>
                        {/*<Grid item xs={12} md={6}>
                        <Field
                            component={TextField}
                            type="text"
                            name={
                                'class_' + i + '_' + class_description
                            }
                            variant="outlined"
                            margin="normal"
                            readonly
                            disabled
                            fullWidth
                            placeholder={class_description}
                            InputLabelProps={{
                                style: {
                                    pointerEvents: 'auto',
                                },
                            }}
                            label="Class"
                            inputProps={{
                                maxLength: CLASS_DESCRIPTION_MAXIMUM_LENGTH,
                            }}
                        /></Grid>*/}
                        <Grid item xs={12} md={6}>
                            <Field
                                component={TextField}
                                type="text"
                                name={
                                    'category_description_' +
                                    i +
                                    '_' +
                                    class_description
                                }
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                placeholder={'Apple Founder Asset'}
                                InputLabelProps={{
                                    style: {
                                        pointerEvents: 'auto',
                                    },
                                }}
                                inputProps={{
                                    maxLength: CLASS_CATEGORY_DESCRIPTION_MAXIMUM_LENGTH,
                                }}
                                label="Category Description"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={TextField}
                                type="text"
                                name={
                                    'current_price_' +
                                    i +
                                    '_' +
                                    class_description
                                }
                                numeric={1}
                                label="Current price"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {/*<Field
                                component={TextField}
                                type="number"
                                name={
                                    'category_price_' +
                                    i +
                                    '_' +
                                    class_description
                                }
                                variant="outlined"
                                margin="normal"
                                label={'Price per asset'}
                                placeholder={'0.00'}
                                //numeric={1}
                                fullWidth
                            />*/}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RadioButtons
                                label="Transfer restrictions"
                                name={
                                    'transfer_restrictions_' +
                                    i +
                                    '_' +
                                    class_description
                                }
                                row
                                defaultValue={UNRESTRICTED}
                                options={[
                                    {
                                        label: 'Unrestricted',
                                        value: UNRESTRICTED,
                                    },
                                    {
                                        label: 'Restricted',
                                        value: RESTRICTED,
                                    },
                                    {
                                        label: 'Custom',
                                        value: CUSTOM,
                                    },
                                ]}
                            />
                        </Grid>

                        {values[
                            'transfer_restrictions_' +
                                i +
                                '_' +
                                class_description
                        ] === CUSTOM && (
                            <Grid item xs={12} md={6}>
                                <Field
                                    component={TextField}
                                    type="number"
                                    name={
                                        'lock_period_' +
                                        i +
                                        '_' +
                                        class_description
                                    }
                                    variant="outlined"
                                    margin="normal"
                                    label={'Lock period (for transfers)'}
                                    placeholder={'90'}
                                    fullWidth
                                />
                            </Grid>
                        )}
                    </Grid>
                );
            }
            if (classCategoryNumber[name] > categories.length) {
                for (
                    i = categories.length;
                    i < classCategoryNumber[name];
                    i++
                ) {
                    items.push(
                        <Grid container spacing={1}>
                            {/*<Grid item xs={12} md={6}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    name={
                                        'class_' + i + '_' + class_description
                                    }
                                    variant="outlined"
                                    margin="normal"
                                    readonly
                                    disabled
                                    fullWidth
                                    placeholder={class_description}
                                    InputLabelProps={{
                                        style: {
                                            pointerEvents: 'auto',
                                        },
                                    }}
                                    label="Class2"
                                    inputProps={{
                                        maxLength: CLASS_CATEGORY_DESCRIPTION_MAXIMUM_LENGTH,
                                    }}
                                />
                            </Grid>*/}
                            <Grid item xs={12} md={6}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    name={
                                        'category_description_' +
                                        i +
                                        '_' +
                                        class_description
                                    }
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    placeholder={'Apple Founder Asset'}
                                    InputLabelProps={{
                                        style: {
                                            pointerEvents: 'auto',
                                        },
                                    }}
                                    inputProps={{
                                        maxLength: CLASS_CATEGORY_DESCRIPTION_MAXIMUM_LENGTH,
                                    }}
                                    label="Category Description"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    name={
                                        'current_price_' +
                                        i +
                                        '_' +
                                        class_description
                                    }
                                    numeric={1}
                                    label="Current price"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                            {/*<Grid item xs={12} md={6}>
                                <Field
                                    component={TextField}
                                    type="number"
                                    name={
                                        'category_price_' +
                                        i +
                                        '_' +
                                        class_description
                                    }
                                    variant="outlined"
                                    margin="normal"
                                    label={'Price per asset'}
                                    placeholder={'0.00'}
                                    //numeric={1}
                                    fullWidth
                                />
                            </Grid>*/}
                            <Grid item xs={12} md={6}>
                                <RadioButtons
                                    label="Transfer restrictions"
                                    name={
                                        'transfer_restrictions_' +
                                        i +
                                        '_' +
                                        class_description
                                    }
                                    row
                                    defaultValue={UNRESTRICTED}
                                    options={[
                                        {
                                            label: 'Unrestricted',
                                            value: UNRESTRICTED,
                                        },
                                        {
                                            label: 'Restricted',
                                            value: RESTRICTED,
                                        },
                                        {
                                            label: 'Custom',
                                            value: CUSTOM,
                                        },
                                    ]}
                                />
                            </Grid>

                            {values[
                                'transfer_restrictions_' +
                                    i +
                                    '_' +
                                    class_description
                            ] === CUSTOM && (
                                <Grid item xs={12} md={6}>
                                    <Field
                                        component={TextField}
                                        type="number"
                                        name={
                                            'lock_period_' +
                                            i +
                                            '_' +
                                            class_description
                                        }
                                        variant="outlined"
                                        margin="normal"
                                        label={'Lock period (for transfers)'}
                                        placeholder={'90'}
                                        fullWidth
                                    />
                                </Grid>
                            )}
                        </Grid>
                    );
                }
            }
        } else {
            if (classCategoryNumber[name] > 0) {
                for (i = 0; i < classCategoryNumber[name]; i++) {
                    items.push(
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                                <Typography>
                                    <h5>
                                        Asset class category #{i + 1}: for Class{' '}
                                        {class_description}
                                    </h5>
                                </Typography>
                            </Grid>

                            {/*<Grid item xs={12} md={6}>
                                <Field
                                    type="text"
                                    name={
                                        'class_' + i + '_' + class_description
                                    }
                                    fullWidth
                                    margin="normal"
                                    hidden
                                    label="Class3"
                                    readonly
                                    inputProps={{
                                        maxLength: CLASS_DESCRIPTION_MAXIMUM_LENGTH,
                                    }}
                                />
                            </Grid>*/}
                            <Grid item xs={12} md={6}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    name={
                                        'category_description_' +
                                        i +
                                        '_' +
                                        class_description
                                    }
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    placeholder={'Apple Founder Asset'}
                                    InputLabelProps={{
                                        style: {
                                            pointerEvents: 'auto',
                                        },
                                    }}
                                    inputProps={{
                                        maxLength: CLASS_CATEGORY_DESCRIPTION_MAXIMUM_LENGTH,
                                    }}
                                    label="Category Description"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    name={
                                        'current_price_' +
                                        i +
                                        '_' +
                                        class_description
                                    }
                                    numeric={1}
                                    label="Current price"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                            {/*<Grid item xs={12} md={6}>
                                <Field
                                    component={TextField}
                                    type="number"
                                    name={
                                        'category_price_' +
                                        i +
                                        '_' +
                                        class_description
                                    }
                                    variant="outlined"
                                    margin="normal"
                                    label={'Price per asset'}
                                    placeholder={'0.00'}
                                    //numeric={1}
                                    fullWidth
                                />
                            </Grid>*/}
                            <Grid item xs={12} md={6}>
                                <RadioButtons
                                    label="Transfer restrictions"
                                    name={
                                        'transfer_restrictions_' +
                                        i +
                                        '_' +
                                        class_description
                                    }
                                    row
                                    defaultValue={UNRESTRICTED}
                                    options={[
                                        {
                                            label: 'Unrestricted',
                                            value: UNRESTRICTED,
                                        },
                                        {
                                            label: 'Restricted',
                                            value: RESTRICTED,
                                        },
                                        {
                                            label: 'Custom',
                                            value: CUSTOM,
                                        },
                                    ]}
                                />
                            </Grid>
                            {values[
                                'transfer_restrictions_' +
                                    i +
                                    '_' +
                                    class_description
                            ] === CUSTOM && (
                                <Grid item xs={12} md={6}>
                                    <Field
                                        component={TextField}
                                        type="number"
                                        name={
                                            'lock_period_' +
                                            i +
                                            '_' +
                                            class_description
                                        }
                                        variant="outlined"
                                        margin="normal"
                                        label={'Lock period (for transfers)'}
                                        placeholder={'90'}
                                        fullWidth
                                    />
                                </Grid>
                            )}
                        </Grid>
                    );
                }
            } else {
                items.push(
                    <Grid container spacing={1}>
                        {/*<Grid item xs={12} md={4}>
                            <Field
                                component={TextField}
                                type="text"
                                name={'class_0_' + class_description}
                                variant="outlined"
                                margin="normal"
                                readonly
                                disabled
                                fullWidth
                                placeholder={class_description}
                                InputLabelProps={{
                                    style: {
                                        pointerEvents: 'auto',
                                    },
                                }}
                                label="Class4"
                                inputProps={{
                                    maxLength: CLASS_CATEGORY_DESCRIPTION_MAXIMUM_LENGTH,
                                }}
                            />
                        </Grid>*/}
                        <Grid item xs={12} md={4}>
                            <Field
                                component={TextField}
                                type="text"
                                name={
                                    'category_description_0_' +
                                    class_description
                                }
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                placeholder={'Apple Founder Asset'}
                                InputLabelProps={{
                                    style: {
                                        pointerEvents: 'auto',
                                    },
                                }}
                                label="Category Description"
                                inputProps={{
                                    maxLength: CLASS_CATEGORY_DESCRIPTION_MAXIMUM_LENGTH,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={TextField}
                                type="text"
                                name={
                                    'current_price_0_' +
                                    i +
                                    '_' +
                                    class_description
                                }
                                numeric={1}
                                label="Current price"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        {/*<Grid item xs={12} md={6}>
                            <Field
                                component={TextField}
                                type="number"
                                name={'category_price_0_' + class_description}
                                variant="outlined"
                                margin="normal"
                                label={'Price per asset'}
                                placeholder={'0.00'}
                                //numeric={1}
                                fullWidth
                            />
                        </Grid>*/}
                        <Grid item xs={12} md={6}>
                            <RadioButtons
                                label="Transfer restrictions"
                                name={
                                    'transfer_restrictions_0_' +
                                    class_description
                                }
                                row
                                defaultValue={UNRESTRICTED}
                                options={[
                                    {
                                        label: 'Unrestricted',
                                        value: UNRESTRICTED,
                                    },
                                    {
                                        label: 'Restricted',
                                        value: RESTRICTED,
                                    },
                                    {
                                        label: 'Custom',
                                        value: CUSTOM,
                                    },
                                ]}
                            />
                        </Grid>

                        {values[
                            'transfer_restrictions_' +
                                i +
                                '_' +
                                class_description
                        ] === CUSTOM && (
                            <Grid item xs={12} md={6}>
                                <Field
                                    component={TextField}
                                    type="number"
                                    name={'lock_period_0_' + class_description}
                                    variant="outlined"
                                    margin="normal"
                                    label={'Lock period (for transfers)'}
                                    placeholder={'90'}
                                    fullWidth
                                />
                            </Grid>
                        )}
                    </Grid>
                );
            }
        }

        return items;
    };

    const changeClassCategoryNumber = (value, name) => {
        setClassCategoryNumber({
            ...classCategoryNumber,
            [name]: value,
        });
    };

    return (
        <div id="step7">
            <Formik
                initialValues={props.initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    let id = 0;
                    values['categories'] = [];
                    props.initialValues.classes.forEach(function (
                        class_element
                    ) {
                        for (
                            var i = 0;
                            i <
                            classCategoryNumber[
                                'number_of_class_categories_' +
                                    class_element.class_description
                            ];
                            i++
                        ) {
                            if (
                                class_element.class_name !== undefined &&
                                values[
                                    'category_description_' +
                                        i +
                                        '_' +
                                        class_element.class_description
                                ]
                            ) {
                                values['categories'].push({
                                    id: id,
                                    class_name: class_element.class_name,
                                    class_description:
                                        class_element.class_description,
                                    category_description:
                                        values[
                                            'category_description_' +
                                                i +
                                                '_' +
                                                class_element.class_description
                                        ],
                                    current_price:
                                        values[
                                            'current_price_' +
                                                i +
                                                '_' +
                                                class_element.class_description
                                        ],
                                    category_price:
                                        values[
                                            'category_price_' +
                                                i +
                                                '_' +
                                                class_element.class_description
                                        ],
                                    transfer_restrictions:
                                        values[
                                            'transfer_restrictions_' +
                                                i +
                                                '_' +
                                                class_element.class_description
                                        ],
                                    lock_period: values[
                                        'lock_period_' +
                                            i +
                                            '_' +
                                            class_element.class_description
                                    ]
                                        ? values[
                                              'lock_period_' +
                                                  i +
                                                  '_' +
                                                  class_element.class_description
                                          ]
                                        : null,
                                });
                            }

                            id++;
                        }
                    });
                    values['class_categories_number'] = classCategoryNumber;

                    props.saveValues(values);
                    props.initialValues.classes.forEach(function (
                        class_element
                    ) {
                        for (
                            var i = 0;
                            i <
                            classCategoryNumber[
                                'number_of_class_categories_' +
                                    class_element.class_name
                            ];
                            i++
                        ) {
                            delete props.initialValues[
                                'category_description_' +
                                    i +
                                    '_' +
                                    class_element.class_description
                            ];
                            delete props.initialValues[
                                'current_price_' +
                                    i +
                                    '_' +
                                    class_element.class_description
                            ];
                            delete props.initialValues[
                                'category_price_' +
                                    i +
                                    '_' +
                                    class_element.class_description
                            ];
                            delete props.initialValues[
                                'transfer_restrictions_' +
                                    i +
                                    '_' +
                                    class_element.class_description
                            ];
                            delete props.initialValues[
                                'lock_period_' +
                                    i +
                                    '_' +
                                    class_element.class_description
                            ];
                            delete props.initialValues[
                                class_element.class_description
                            ];
                        }
                    });
                    nextStep();
                }}
            >
                {({ values, isSubmitting }) => (
                    <Paper className={classes.paper}>
                        <Typography
                            component="div"
                            style={{ textAlign: 'center' }}
                        >
                            <h2>Equity Information — Category</h2>
                        </Typography>
                        <Container>
                            <Form className={classes.form} noValidate>
                                {props.initialValues.classes.map(
                                    (item, key) => (
                                        <Grid key={key}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} md={12}>
                                                    <InputLabel
                                                        style={{
                                                            color: '#666',
                                                            fontSize: '1rem',
                                                        }}
                                                    >
                                                        {key + 1} .How many
                                                        categories do you want
                                                        to track within the
                                                        class{' '}
                                                        <b>
                                                            {
                                                                item.class_description
                                                            }
                                                        </b>
                                                    </InputLabel>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={6}
                                                        className="m-b-10"
                                                    >
                                                        <Field
                                                            component={
                                                                FormikSelect
                                                            }
                                                            name={
                                                                'number_of_class_categories_' +
                                                                item.class_description
                                                            }
                                                            onChange={(e) =>
                                                                changeClassCategoryNumber(
                                                                    e.target
                                                                        .value,
                                                                    'number_of_class_categories_' +
                                                                        item.class_description
                                                                )
                                                            }
                                                            variant="outlined"
                                                            fullWidth
                                                            inputProps={{
                                                                id:
                                                                    'number_of_class_categories_',
                                                            }}
                                                            defaultValue={1}
                                                        >
                                                            {buildDropdown(5)}
                                                        </Field>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            {listCategories(
                                                'number_of_class_categories_' +
                                                    item.class_description,
                                                item.class_description,
                                                values
                                            )}

                                            {key !==
                                                props.initialValues.classes
                                                    .length -
                                                    1 && (
                                                <Divider className="m-y-20" />
                                            )}
                                        </Grid>
                                    )
                                )}

                                <div className={classes.actions}>
                                    <ButtonGroup
                                        aria-label="outlined primary button group"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            className={
                                                classes.secondaryButtonLabel
                                            }
                                            color="secondary"
                                            onClick={() => props.previousStep()}
                                            style={{ margin: 'inherit' }}
                                        >
                                            <ArrowBackIosIcon /> BACK
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="outlined"
                                            className={classes.buttonLabel}
                                            color="primary"
                                            style={{
                                                margin: 'auto',
                                            }}
                                        >
                                            CONTINUE
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            className={
                                                classes.secondaryButtonLabel
                                            }
                                            color="secondary"
                                            onClick={nextStep}
                                            style={{ margin: 'inherit' }}
                                        >
                                            SKIP
                                            <DoubleArrowIcon />
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </Form>
                        </Container>
                    </Paper>
                )}
            </Formik>
        </div>
    );
};

export default EquityInformationCategory;
