import Tabs from '@mui/material/Tabs';

import { styled } from '@mui/material/styles';

const StyledTabs = styled(Tabs)({
    '& .MuiTabs-flexContainer': {
        justifyContent: 'center',
    }
})

export default StyledTabs