import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import { styled } from '@mui/material/styles';

const StyledButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'active'
})(({ active }) => ({
    background: active ? '#3E9CD9' : 'initial',
    color: active ? 'white' : '#3E9CD9',
    '&.Mui-disabled': {
        color: active ? 'white' : '#3E9CD9',
    }
}))

const CandlestickChart = (props) => {
    const [chartData, setChartData] = useState([])
    const [socket, setSocket] = useState(null)
    const [period, setPeriod] = useState('month')

    useEffect(() => {
        const socketClient = new WebSocket('wss://ws.trading.digtl.co');
        
        socketClient.onopen = () => {
            socketClient.send(
                JSON.stringify({
                    event: 'GET_MARKET_DATA',
                    symbol: props.currency || 'BMN1',
                    level:  props.period || period,
                })
            );        
        }

        socketClient.onmessage = (message) => {
            if (message.data) {
                const msgContent = JSON.parse(message.data);

                switch (msgContent.event) {
                    case 'SEND_MARKET_DATA':
                        const marketData = JSON.parse(message.data)
                        setChartData(translateChartData(marketData.data));
                        break;
                    default:
                        break;
                }
            }
        }

        setSocket(socketClient)
    }, []);

    const translateChartData = (data) => {
        let chartData = []
        let prevClose = undefined;
        data.map(entry => {
            prevClose = entry.Close ? entry.Close : prevClose || 0;
            chartData.push({
                x: entry.Date,
                y: [entry.High || prevClose, entry.Low || prevClose, prevClose, entry.Open || prevClose]
            });
            return null;
        });

        return [{
            data: chartData
        }];
    }

    const getMarketData = (currency, period) => {
        socket.send(
            JSON.stringify({
                event: 'GET_MARKET_DATA',
                symbol: currency,
                level: period,
            })
        );
    }

    const updateChart = (period) => {
        setPeriod(period);
        getMarketData(props.currency || 'BMN1', period);
    }

    return (
        <>
            <Chart
                options={{
                    chart: {
                        type: 'candlestick',
                        height: 350,
                    },
                    title: {
                        text: props.currencyName || 'BMN1 (MERJ)',
                        align: 'left',
                    },
                    xaxis: {
                        type: 'datetime',
                    },
                    yaxis: {
                        tooltip: {
                            enabled: true,
                        },
                    },
                }}
                series={chartData}
                width="100%"
                type="candlestick"
                height={250}
            />
            <ButtonGroup sx={{ marginLeft: '15px' }} variant="outlined" aria-label="text button group">
                <StyledButton disabled={period === 'day'} active={period === 'day'} onClick={() => updateChart('day')} size="small">1D</StyledButton>
                <StyledButton disabled={period === 'week'} active={period === 'week'} onClick={() => updateChart('week')} size="small">5D</StyledButton>
                <StyledButton disabled={period === 'month'} active={period === 'month'} onClick={() => updateChart('month')} size="small">1M</StyledButton>
                <StyledButton disabled={period === '3months'} active={period === '3months'} onClick={() => updateChart('3months')} size="small">3M</StyledButton>
                <StyledButton disabled={period === 'year'} active={period === 'year'} onClick={() => updateChart('year')} size="small">1Y</StyledButton>
            </ButtonGroup>
        </>
    );
};

export default CandlestickChart;
