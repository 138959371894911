import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as config from './../../../config';
import request from '../../../utilities/request';
import { useSnackbar } from 'notistack';
import * as shareholderActions from '../../../store/shareholder/shareholderActions';
import * as investorInviteActions from '../../../store/investorInvite/investorInviteActions';
import investorStyles from '../../../components/styles/investorStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import authStyles from '../authStyles';
import Button from '@mui/material/Button';
import TextField from '../../../components/TextField/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
const Invite = (props) => {
    const classes = authStyles();
    const investorClasses = investorStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [toShareholder, setToShareholder] = useState(null);

    useEffect(props.shareholderActions.getShareholdersList, []);
    useEffect(props.investorInviteActions.getInvestorInvites, []);

    const handleShareholderChange = (value, type) => {
        let investor = props.shareholders.filter((element) => {
            return element.investor === value;
        })[0];
        setToShareholder(investor);
    };

    return (
        <Formik
            initialValues={{}}
            onSubmit={(values, { setSubmitting }) => {
                values['investor_email'] = toShareholder.email;
                values['investor_id'] = toShareholder.investor_id;
                values['first_name'] = toShareholder.first_name;
                values['last_name'] = toShareholder.last_name;
                setToShareholder(null);
                request
                    .post(`${config.API_BASE_URL}/investors/invite`, values)
                    .then((response) => {
                        setSubmitting(false);
                        enqueueSnackbar(
                            'Invite sent to shareholder successfully',
                            {
                                variant: 'success',
                            }
                        );
                    })
                    .catch((error) => {
                        enqueueSnackbar('Error occurred', {
                            variant: 'error',
                        });
                        setSubmitting(false);
                    });
                closeSnackbar();
            }}
        >
            {({ isSubmitting }) => (
                <Form noValidate>
                    <Grid xs={12} sm={12} md={12}>
                        <Typography className={classes.title}>
                            Find the person
                        </Typography>
                        <Typography>
                            Before sending an invitation, we need to make sure
                            that this person has existing Investor profile
                        </Typography>
                        <br />
                        <Grid container>
                            <Grid>
                                <Typography>
                                    Please select the Investor profile
                                    &nbsp;&nbsp;
                                </Typography>
                            </Grid>
                            <Grid xs={12} sm={12} md={12} item>
                                <Autocomplete
                                    name="to"
                                    id="free-solo-demo"
                                    value={
                                        toShareholder !== null &&
                                        toShareholder !== undefined
                                            ? toShareholder.investor
                                                ? toShareholder.investor
                                                : ''
                                            : ''
                                    }
                                    onInputChange={(event, newInputValue) => {
                                        handleShareholderChange(
                                            newInputValue,
                                            'investor'
                                        );
                                    }}
                                    options={
                                        props.shareholders
                                            ? props.shareholders.map(
                                                  (option) => option.investor
                                              )
                                            : []
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            type="text"
                                            label="Investor"
                                            margin="normal"
                                            variant="outlined"
                                            name="to"
                                            fullWidth
                                            placeholder={'Search...'}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <br />

                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                className={investorClasses.inviteLeftColumn}
                            >
                                {toShareholder
                                    ? toShareholder.investor
                                    : 'Email'}
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                className={investorClasses.inviteRightColumn}
                            >
                                {toShareholder ? toShareholder.email : ''}
                            </Grid>
                        </Grid>

                        <br />
                        <Grid
                            item
                            xs={12}
                            md={12}
                            sm={12}
                            className={classes.alignCenter}
                        >
                            <Button
                                type="submit"
                                variant="outlined"
                                className={classes.buttonLabel}
                                color="primary"
                                disabled={
                                    isSubmitting ||
                                    toShareholder === null ||
                                    toShareholder === undefined
                                }
                            >
                                {!isSubmitting ? (
                                    <b>Send Invite</b>
                                ) : (
                                    <CircularProgress size={24} />
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};
const mapStateToProps = ({ profile, shareholders, investorInvites }) => {
    return {
        profile: profile,
        shareholders: shareholders.data,
        investorInvites: investorInvites,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        shareholderActions: bindActionCreators(shareholderActions, dispatch),
        investorInviteActions: bindActionCreators(
            investorInviteActions,
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Invite);
