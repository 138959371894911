import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import Grid from '@mui/material/Grid';
import * as config from './../../../config';
import request from '../../../utilities/request';
import authStyles from '../authStyles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useSnackbar } from 'notistack';
//import investorStyles from '../../../components/styles/investorStyles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '../../../components/TextField/TextField';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DateFormatter } from '../../../utilities/formatters';
import CircularProgress from '@mui/material/CircularProgress';
import DataTable from '../../../components/DataTable/MuiDataTable';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import * as investorNotificationActions from '../../../store/investorNotification/investorNotificationActions';
import * as shareholderActions from '../../../store/shareholder/shareholderActions';
import * as eventActions from '../../../store/event/eventActions';
//import { SUBSCRIPTION_PRO } from '../../../constants/profile';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import LockedTile from '../../../components/LockedTile';
import { SUBSCRIPTION_PRO } from '../../../constants/profile';
import InputLabel from '@mui/material/InputLabel';
import FormikSelect from '../../../components/Formik/FormikSelect';
import MenuItem from '@mui/material/MenuItem';
import EmailIcon from '@mui/icons-material/Email';
let initialValues = {};
const Communications = (props) => {
    const classes = authStyles();
    //const investorClasses = investorStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [eventDate, setEventDate] = useState(
        DateFormatter({
            value: new Date(),
            format: 'date_raw',
        })
    );

    const [eventEndDate, setEventEndDate] = useState(null);
    const [eventStartTime, setEventStartTime] = useState(Date.now());
    const [eventEndTime, setEventEndTime] = useState(Date.now());
    const [allDayEvent, setAllDayEvent] = useState(false);
    const [fewDaysEvent, setFewDaysEvent] = useState(false);
    const [joinInfo, setJoinInfo] = useState(null);
    const [eventName, setEventName] = useState(null);
    const [eventDescription, setEventDescription] = useState(null);
    const [eventLocation, setEventLocation] = useState(null);
    const [message, setMessage] = useState(null);
    const [sentMessages, setSentMessages] = useState([]);
    const [receivedMessages, setReceivedMessages] = useState([]);
    const [receivedMessageDialog, setReceivedMessageDialog] = useState(false);
    const [sentMessageDialog, setSentMessageDialog] = useState(false);
    const [openedMessage, setOpenedMessage] = useState(null);
    const [messageDetails, setMessageDetails] = useState([]);
    const [eventKey, setEventKey] = useState(100);
    const [key, setKey] = useState(1);
    const [toShareholder, setToShareholder] = useState(null)
    useEffect(props.investorNotificationActions.getInvestorNotifications, []);
    useEffect(props.shareholderActions.getShareholdersList, []);
    useEffect(props.eventActions.getRecentEvents, []);

    useEffect(() => {
        if (props.investorNotifications.data) {
            setSentMessages(props.investorNotifications.data.data.sent);
            setReceivedMessages(props.investorNotifications.data.data.received);
        }
    }, [props.investorNotifications.data, sentMessages /*, receivedMessages*/]);

    const handleShareholderChange = (e) => {
        if (e.target.getAttribute('data-value') === 'all') {
            setToShareholder({ investor_id: 'all' });
        } else {
            let investor = props.shareholders.filter((element) => {
                return (
                    element.investor_id ===
                    parseInt(e.target.getAttribute('data-value'))
                );
            })[0];

            setToShareholder(investor);
        }
    };

    const handleEditorChange = (content) => {
      setMessage(content);
    };

    /*const handleDateChange = (date) => {
        console.log(date;
        setEventDate(
            DateFormatter({
                value: date.target.value,
                format: 'date_raw',
            })
        );
    };*/
    const displayReceivedMessageDialog = (message) => {
        if (message.status === 'UNREAD') {
            request
                .post(`${config.API_BASE_URL}/investors/notification/edit`, {
                    status: 'READ',
                    id: message.id,
                    investor_id: message.investor_id,
                })
                .then((response) => {
                    props.investorNotificationActions.getInvestorNotifications();
                })
                .catch((error) => {});
        }
        setOpenedMessage(message);
        setReceivedMessageDialog(true);
    };

    const displaySentMessageDialog = (message) => {
        setOpenedMessage(message);
        setSentMessageDialog(true);
        request
            .get(
                `${config.API_BASE_URL}/investors/notifications/${message.message_id}`
            )
            .then((response) => {
                setMessageDetails(response.data.data);
            })
            .catch((error) => {});
    };

    const closeDialog = () => {
        setReceivedMessageDialog(false);
        setSentMessageDialog(false);
    };

    const handleAllDayEventChange = () => {
        setAllDayEvent(!allDayEvent);
    };

    const handleFewDaysEventChange = () => {
        setFewDaysEvent(!fewDaysEvent);
    };

    const handleTimeChange = (type, value) => {
        if (type === 'start') {
            setEventStartTime(value);
        } else {
            setEventEndTime(value);
        }
    };

    return (
        <LockedTile  requiredPlan={SUBSCRIPTION_PRO}>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={7} md={7}>
                    <Paper elevation={3} className={classes.paper}>
                        <Typography className={classes.title}>
                            Send General Notification
                        </Typography>
                        <Formik
                            initialValues={{}}
                            key={key}
                            onSubmit={(values, { setSubmitting }) => {
                                values['message'] = message;
                                values['investor_id'] =
                                    toShareholder.investor_id;

                                request
                                    .post(
                                        `${config.API_BASE_URL}/investors/notification`,
                                        values
                                    )
                                    .then((response) => {
                                        props.investorNotificationActions.getInvestorNotifications();
                                        setKey(key + 1);
                                        enqueueSnackbar(
                                            'Message sent successfully',
                                            {
                                                variant: 'success',
                                            }
                                        );
                                    })
                                    .catch((error) => {
                                        enqueueSnackbar(
                                            error.response.data.message,
                                            {
                                                variant: 'error',
                                            }
                                        );
                                        setKey(key + 1);
                                        setSubmitting(false);
                                        setKey(key + 1);
                                    });
                            }}
                        >
                            {({ isSubmitting }) => (
                                <Form noValidate>
                                    <Grid container>
                                        <Field
                                            component={FormikSelect}
                                            name="to"
                                            variant="outlined"
                                            label="To"
                                            fullWidth
                                            defaultValue={'all'}
                                            value={
                                                toShareholder !== null &&
                                                toShareholder !== undefined
                                                    ? toShareholder.investor_id
                                                        ? toShareholder.investor_id
                                                        : ''
                                                    : ''
                                            }
                                        >
                                            <MenuItem
                                                key={'all'}
                                                value={'all'}
                                                onClick={
                                                    handleShareholderChange
                                                }
                                            >
                                                {'All Holders'}
                                            </MenuItem>
                                            {props.shareholders &&
                                                props.shareholders.map(
                                                    (shareholder) => (
                                                        <MenuItem
                                                            key={
                                                                shareholder.investor_id
                                                            }
                                                            value={
                                                                shareholder.investor_id
                                                            }
                                                            onClick={
                                                                handleShareholderChange
                                                            }
                                                        >
                                                            {
                                                                shareholder.investor
                                                            }
                                                        </MenuItem>
                                                    )
                                                )}
                                        </Field>
                                    </Grid>

                                    <Grid container>
                                        <Field
                                            component={TextField}
                                            type="text"
                                            name={'subject'}
                                            variant="outlined"
                                            label="Subject"
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid container>
                                        <Grid container>
                                            <InputLabel
                                                className={classes.label}
                                                htmlFor="message"
                                            >
                                                Message
                                            </InputLabel>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Editor
                                                apiKey="uf9matrx4ivq9fcl3zpum8qtj7b4bb79w95bmuwn5d2hkhyi"
                                                initialValue="<p></p>"
                                                name="message"
                                                init={{
                                                    height: 250,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount',
                                                    ],
                                                    toolbar:
                                                        'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                                                }}
                                                onEditorChange={
                                                    handleEditorChange
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <br />
                                    <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        sm={12}
                                        className={classes.alignCenter}
                                    >
                                        <Button
                                            type="submit"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.buttonLabel}
                                            disabled={isSubmitting}
                                        >
                                            {!isSubmitting ? (
                                                <div className="flex-left">
                                                    <EmailIcon />
                                                    <b>&nbsp; Send Notice</b>
                                                </div>
                                            ) : (
                                                <CircularProgress size={24} />
                                            )}
                                        </Button>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Paper>{' '}
                </Grid>
                <Grid item xs={12} sm={5} md={5}>
                <Paper elevation={3} className={classes.paper}>
                    <Typography className={classes.title}>
                        <b>
                            Notification History{' '}
                            { '(' +
                                  receivedMessages.filter(
                                      (item) => item.status === 'UNREAD'
                                  ).length +
                                  ' UNREAD)'}
                        </b>
                    </Typography>

                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-label="simple table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Subject</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Message</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {receivedMessages.length > 0 ? (
                                    receivedMessages.map((row, i) => (
                                        <TableRow key={row.id}>
                                            <TableCell>
                                                {row.status === 'UNREAD' ? (
                                                    <b
                                                        className={
                                                            classes.blueText
                                                        }
                                                    >
                                                        {row.full_name}
                                                    </b>
                                                ) : (
                                                    <p>{row.full_name}</p>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {row.status === 'UNREAD' ? (
                                                    <b
                                                        className={
                                                            classes.blueText
                                                        }
                                                    >
                                                        {' '}
                                                        {row.subject}
                                                    </b>
                                                ) : (
                                                    row.subject
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {row.status === false ? (
                                                    <b
                                                        className={
                                                            classes.blueText
                                                        }
                                                    >
                                                        {' '}
                                                        {
                                                            <DateFormatter
                                                                value={
                                                                    row.created_at
                                                                }
                                                                format="date"
                                                            />
                                                        }
                                                    </b>
                                                ) : (
                                                    <DateFormatter
                                                        value={
                                                            row.created_at
                                                        }
                                                        format="date"
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <TouchAppIcon
                                                    className={
                                                        classes.touchIcon
                                                    }
                                                    onClick={() =>
                                                        displayReceivedMessageDialog(
                                                            row
                                                        )
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell
                                            colSpan={4}
                                            style={{
                                                textAlign: 'center',
                                                fontSize: '1.1rem',
                                            }}
                                        >
                                            {!props.investorNotifications
                                                .data ? (
                                                <CircularProgress
                                                    size={24}
                                                />
                                            ) : (
                                                'No data'
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
            {props.investorNotification &&
            props.investorNotification.loading ? (
                <Grid container item xs={12} justifyContent="center">
                    <CircularProgress />
                </Grid>
            ) : (
                <React.Fragment>
                    <DataTable
                        title={
                            'Sent History (' +
                                  sentMessages.length +
                                  ' SENT)'
                        }
                        data={
                            sentMessages && sentMessages.length > 0
                                ? sentMessages
                                : []
                        }
                        columns={[
                            {
                                label: 'Subject',
                                name: 'subject',
                            },
                            {
                                label: 'Date',
                                name: 'created_at',
                                options: {
                                    customBodyRender: (value) => (
                                        <span>
                                            {
                                                <DateFormatter
                                                    value={value}
                                                    format="date"
                                                />
                                            }
                                        </span>
                                    ),
                                },
                            },
                            {
                                label: 'Message',
                                name: 'message_id',
                                options: {
                                    download: false,
                                    print: false,
                                    customBodyRenderLite: (
                                        dataIndex
                                    ) => {
                                        const row =
                                            sentMessages[dataIndex];
                                        return (
                                            <TouchAppIcon
                                                className={
                                                    classes.touchIcon
                                                }
                                                onClick={() =>
                                                    displaySentMessageDialog(
                                                        row
                                                    )
                                                }
                                            />
                                        );
                                    },
                                },
                            },
                        ]}
                        shareSort={2}
                    />
                </React.Fragment>
            )}
        </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Paper elevation={3} className={classes.paper}>
                            <Typography className={classes.title}>
                                Events
                            </Typography>
                            <br />
                            <br />
                            <Formik
                                initialValues={initialValues}
                                key={eventKey}
                                onSubmit={(values, { setSubmitting }) => {
                                    values['event_date'] = eventDate;
                                    values['end_date'] = eventEndDate;
                                    values['start_time'] = eventStartTime;
                                    values['end_time'] = eventEndTime;
                                    values['name'] = eventName;
                                    values['description'] = eventDescription;
                                    values['location'] = eventLocation;
                                    values['join_info'] = joinInfo;
                                    values['all_day'] = allDayEvent;
                                    values['few_days'] = fewDaysEvent;

                                    request
                                        .post(
                                            `${config.API_BASE_URL}/event`,
                                            values
                                        )
                                        .then((response) => {
                                            props.eventActions.getRecentEvents();
                                            setEventKey(eventKey + 1);
                                            enqueueSnackbar(
                                                'Event added successfully',
                                                {
                                                    variant: 'success',
                                                }
                                            );
                                        })
                                        .catch((error) => {
                                            enqueueSnackbar(
                                                error.response.data.message,
                                                {
                                                    variant: 'error',
                                                }
                                            );
                                            setSubmitting(false);
                                            setEventKey(eventKey + 1);
                                        });
                                }}
                            >
                                {({ isSubmitting }) => (
                                    <Form noValidate>
                                        {
                                            <Grid container spacing={2}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    sm={6}
                                                >
                                                    <TextField
                                                        type="text"
                                                        label="Title"
                                                        name="event_name"
                                                        variant="outlined"
                                                        fullWidth
                                                        onChange={(e) =>
                                                            setEventName(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <br />
                                                    <br />

                                                    <TextField
                                                        type="text"
                                                        label="Location"
                                                        name="event_location"
                                                        variant="outlined"
                                                        fullWidth
                                                        onChange={(e) =>
                                                            setEventLocation(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <br />
                                                    <br />

                                                    <TextField
                                                        type="text"
                                                        label="Join info (if online)"
                                                        name="join_info"
                                                        variant="outlined"
                                                        fullWidth
                                                        onChange={(e) =>
                                                            setJoinInfo(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <br />
                                                    <br />
                                                    <TextField
                                                        type="text"
                                                        label="Description"
                                                        name="event_description"
                                                        variant="outlined"
                                                        fullWidth
                                                        onChange={(e) =>
                                                            setEventDescription(
                                                                e.target.value
                                                            )
                                                        }
                                                        multiline
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    sm={6}
                                                    spacing={2}
                                                >
                                                    <Grid item xs={12} md={12}>
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDayjs
                                                            }
                                                        >
                                                            <DatePicker
                                                                renderInput={(
                                                                    props
                                                                ) => (
                                                                    <TextField
                                                                        fullWidth
                                                                        {...props}
                                                                    />
                                                                )}
                                                                label="Date"
                                                                fullWidth
                                                                value={
                                                                    eventDate
                                                                }
                                                                onChange={(
                                                                    newValue
                                                                ) => {
                                                                    setEventDate(
                                                                        newValue
                                                                    );
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    {fewDaysEvent && (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            md={12}
                                                        >
                                                            <br />
                                                            <LocalizationProvider
                                                                dateAdapter={
                                                                    AdapterDayjs
                                                                }
                                                            >
                                                                <DatePicker
                                                                    renderInput={(
                                                                        props
                                                                    ) => (
                                                                        <TextField
                                                                            fullWidth
                                                                            {...props}
                                                                        />
                                                                    )}
                                                                    label="End Date"
                                                                    fullWidth
                                                                    value={
                                                                        eventEndDate
                                                                    }
                                                                    onChange={(
                                                                        newValue
                                                                    ) => {
                                                                        setEventEndDate(
                                                                            newValue
                                                                        );
                                                                    }}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                    )}
                                                    <br />
                                                    <Grid
                                                        item
                                                        xs={10}
                                                        sm={10}
                                                        md={10}
                                                    >
                                                        <FormControlLabel
                                                            label="Few days"
                                                            control={
                                                                <Checkbox
                                                                    onChange={
                                                                        handleFewDaysEventChange
                                                                    }
                                                                    checked={
                                                                        fewDaysEvent
                                                                    }
                                                                    name={
                                                                        fewDaysEvent
                                                                    }
                                                                    color="primary"
                                                                />
                                                            }
                                                        />
                                                    </Grid>

                                                    <br />
                                                    {!allDayEvent && (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            md={12}
                                                        >
                                                            <LocalizationProvider
                                                                dateAdapter={
                                                                    AdapterDayjs
                                                                }
                                                            >
                                                                <TimePicker
                                                                    renderInput={(
                                                                        props
                                                                    ) => (
                                                                        <TextField
                                                                            fullWidth
                                                                            {...props}
                                                                        />
                                                                    )}
                                                                    label="Starts at"
                                                                    fullWidth
                                                                    value={
                                                                        eventStartTime
                                                                    }
                                                                    onChange={(
                                                                        newValue
                                                                    ) => {
                                                                        handleTimeChange(
                                                                            'start',
                                                                            newValue
                                                                        );
                                                                    }}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                    )}
                                                    <br />
                                                    {!allDayEvent && (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            md={12}
                                                            sm={12}
                                                        >
                                                            <LocalizationProvider
                                                                dateAdapter={
                                                                    AdapterDayjs
                                                                }
                                                            >
                                                                <TimePicker
                                                                    renderInput={(
                                                                        props
                                                                    ) => (
                                                                        <TextField
                                                                            fullWidth
                                                                            {...props}
                                                                        />
                                                                    )}
                                                                    label="Ends at"
                                                                    fullWidth
                                                                    value={
                                                                        eventEndTime
                                                                    }
                                                                    onChange={(
                                                                        newValue
                                                                    ) => {
                                                                        handleTimeChange(
                                                                            'end',
                                                                            newValue
                                                                        );
                                                                    }}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                    )}
                                                    <br />

                                                    <Grid
                                                        item
                                                        xs={10}
                                                        sm={10}
                                                        md={10}
                                                    >
                                                        <FormControlLabel
                                                            label="All day"
                                                            control={
                                                                <Checkbox
                                                                    onChange={
                                                                        handleAllDayEventChange
                                                                    }
                                                                    checked={
                                                                        allDayEvent
                                                                    }
                                                                    name={
                                                                        allDayEvent
                                                                    }
                                                                    color="primary"
                                                                />
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    sm={12}
                                                    className={
                                                        classes.alignCenter
                                                    }
                                                >
                                                    <Button
                                                        type="submit"
                                                        variant="outlined"
                                                        color="primary"
                                                        className={
                                                            classes.buttonLabel
                                                        }
                                                    >
                                                        <b>Add event</b>
                                                    </Button>
                                                </Grid>
                                                {/*<Grid item xs={12} md={12} sm={12}>
                                                <Typography variant="overline">
                                                    <b>Upcoming events</b>
                                                </Typography>
                                                {!props.events ? (
                                                    <Typography variant="body2">
                                                        <CircularProgress
                                                            size={24}
                                                        />
                                                    </Typography>
                                                ) : props.events &&
                                                  props.events.upcoming &&
                                                  props.events.upcoming.length >
                                                      0 ? (
                                                    <Grid>
                                                        {props.events.upcoming.map(
                                                            (eventItem, i) => (
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        md={6}
                                                                        sm={6}
                                                                        className={
                                                                            investorClasses.eventsLeftColumn
                                                                        }
                                                                    >
                                                                        {
                                                                            eventItem.name
                                                                        }
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        md={6}
                                                                        sm={6}
                                                                        className={
                                                                            investorClasses.eventsRightColumn
                                                                        }
                                                                    >
                                                                        {DateFormatter(
                                                                            {
                                                                                value:
                                                                                    eventItem.event_date,
                                                                                format:
                                                                                    'date',
                                                                            }
                                                                        )}
                                                                    </Grid>{' '}
                                                                    <br />
                                                                </Grid>
                                                            )
                                                        )}
                                                    </Grid>
                                                ) : (
                                                    <Typography
                                                        variant="body2"
                                                        component="p"
                                                    >
                                                        There are no upcoming
                                                        events{' '}
                                                    </Typography>
                                                )}
                                            </Grid>*/}
                                                {/*<Grid item xs={12} md={12} sm={12}>
                                                <Typography variant="overline">
                                                    <b>Past events</b>
                                                </Typography>{' '}
                                                {!props.events ? (
                                                    <Typography variant="body2">
                                                        <CircularProgress
                                                            size={24}
                                                        />
                                                    </Typography>
                                                ) : props.events &&
                                                  props.events.past &&
                                                  props.events.past.length >
                                                      0 ? (
                                                    <Grid>
                                                        {props.events.past.map(
                                                            (event, i) => (
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        md={6}
                                                                        sm={6}
                                                                        className={
                                                                            investorClasses.eventsLeftColumn
                                                                        }
                                                                    >
                                                                        {
                                                                            event.name
                                                                        }
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        md={6}
                                                                        sm={6}
                                                                        className={
                                                                            investorClasses.eventsRightColumn
                                                                        }
                                                                    >
                                                                        {DateFormatter(
                                                                            {
                                                                                value:
                                                                                    event.event_date,
                                                                                format:
                                                                                    'date',
                                                                            }
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                            )
                                                        )}
                                                    </Grid>
                                                ) : (
                                                    <Typography
                                                        variant="body2"
                                                        component="p"
                                                    >
                                                        There are no previous
                                                        events{' '}
                                                    </Typography>
                                                )}
                                            </Grid>*/}
                                            </Grid>
                                        }
                                    </Form>
                                )}
                            </Formik>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <React.Fragment>
                            <DataTable
                                title={'Upcoming Events'}
                                data={
                                    props.events &&
                                    props.events.upcoming.length > 0
                                        ? props.events.upcoming
                                        : []
                                }
                                columns={[
                                    {
                                        label: 'Event',
                                        name: 'name',
                                    },
                                    {
                                        label: 'Description',
                                        name: 'description',
                                    },
                                    {
                                        label: 'Start Date',
                                        name: 'event_date',
                                        options: {
                                            customBodyRender: (value) => (
                                                <span>
                                                    <DateFormatter
                                                        value={value}
                                                        format="date"
                                                    />
                                                </span>
                                            ),
                                        },
                                    },
                                    {
                                        label: 'End Date',
                                        name: 'end_date',
                                        options: {
                                            customBodyRender: (value) => (
                                                <span>
                                                    {value !== null ? (
                                                        <DateFormatter
                                                            value={value}
                                                            format="date"
                                                        />
                                                    ) : (
                                                        ''
                                                    )}
                                                </span>
                                            ),
                                        },
                                    },
                                    {
                                        label: 'Start Time',
                                        name: 'end_time',
                                        options: {
                                            customBodyRender: (value) => (
                                                <span>
                                                    {value !== null
                                                        ? value
                                                        : ''}
                                                </span>
                                            ),
                                        },
                                    },
                                    {
                                        label: 'End Time',
                                        name: 'end_time',
                                        options: {
                                            customBodyRender: (value) => (
                                                <span>
                                                    {value !== null
                                                        ? value
                                                        : ''}
                                                </span>
                                            ),
                                        },
                                    },
                                    {
                                        label: 'All day event',
                                        name: 'all_day',
                                        options: {
                                            customBodyRender: (value) => (
                                                <span>
                                                    {value === 1 ? 'YES' : 'NO'}
                                                </span>
                                            ),
                                        },
                                    },
                                    {
                                        label: 'Few days event',
                                        name: 'few_days',
                                        options: {
                                            customBodyRender: (value) => (
                                                <span>
                                                    {value === 1 ? 'YES' : 'NO'}
                                                </span>
                                            ),
                                        },
                                    },
                                ]}
                            />
                        </React.Fragment>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <React.Fragment>
                            <DataTable
                                title={'Past Events'}
                                data={
                                    props.events && props.events.past.length > 0
                                        ? props.events.past
                                        : []
                                }
                                columns={[
                                    {
                                        label: 'Event',
                                        name: 'name',
                                    },
                                    {
                                        label: 'Description',
                                        name: 'description',
                                    },
                                    {
                                        label: 'Start Date',
                                        name: 'event_date',
                                        options: {
                                            customBodyRender: (value) => (
                                                <span>
                                                    <DateFormatter
                                                        value={value}
                                                        format="date"
                                                    />
                                                </span>
                                            ),
                                        },
                                    },
                                    {
                                        label: 'End Date',
                                        name: 'end_date',
                                        options: {
                                            customBodyRender: (value) => (
                                                <span>
                                                    {value !== null ? (
                                                        <DateFormatter
                                                            value={value}
                                                            format="date"
                                                        />
                                                    ) : (
                                                        ''
                                                    )}
                                                </span>
                                            ),
                                        },
                                    },
                                    {
                                        label: 'Start Time',
                                        name: 'end_time',
                                        options: {
                                            customBodyRender: (value) => (
                                                <span>
                                                    {value !== null
                                                        ? value
                                                        : ''}
                                                </span>
                                            ),
                                        },
                                    },
                                    {
                                        label: 'End Time',
                                        name: 'end_time',
                                        options: {
                                            customBodyRender: (value) => (
                                                <span>
                                                    {value !== null
                                                        ? value
                                                        : ''}
                                                </span>
                                            ),
                                        },
                                    },
                                    {
                                        label: 'All day event',
                                        name: 'all_day',
                                        options: {
                                            customBodyRender: (value) => (
                                                <span>
                                                    {value === 1 ? 'YES' : 'NO'}
                                                </span>
                                            ),
                                        },
                                    },
                                    {
                                        label: 'Few days event',
                                        name: 'few_days',
                                        options: {
                                            customBodyRender: (value) => (
                                                <span>
                                                    {value === 1 ? 'YES' : 'NO'}
                                                </span>
                                            ),
                                        },
                                    },
                                ]}
                            />
                        </React.Fragment>
                    </Grid>

                </Grid>

                <Dialog
                maxWidth="sm"
                fullWidth
                open={receivedMessageDialog}
                onBackdropClick={(e) => setReceivedMessageDialog(false)}
            >
                <DialogTitle className={classes.dialogTitle}>
                    Subject: {openedMessage ? openedMessage.subject : ''}
                </DialogTitle>
                <DialogContent>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={12}>
                            <Grid item xs={12}>
                                {openedMessage ? (
                                    <div>
                                        <Typography component="div">
                                            Investor: {openedMessage.full_name}
                                        </Typography>
                                        <br />
                                        <Typography component="div">
                                            Message:{' '}
                                        </Typography>
                                        <br />
                                        <Typography
                                            component="div"
                                            dangerouslySetInnerHTML={{
                                                __html: openedMessage.message,
                                            }}
                                        />
                                    </div>
                                ) : (
                                    ''
                                )}
                            </Grid>
                            <DialogActions>
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    onClick={() => {
                                        closeDialog();
                                    }}
                                >
                                    <b>Close</b>
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
                <Dialog
                    maxWidth="sm"
                    fullWidth
                    open={sentMessageDialog}
                    onBackdropClick={(e) => setSentMessageDialog(false)}
                >
                    <DialogTitle className={classes.dialogTitle}>
                        Subject: {openedMessage ? openedMessage.subject : ''}
                        <br />
                    </DialogTitle>
                    <DialogContent>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} md={12}>
                                <Grid item xs={12}>
                                    {openedMessage ? (
                                        <Grid>
                                            <Typography component="div">
                                                <b>Message: </b>
                                            </Typography>
                                            <br />
                                            <Typography
                                                component="div"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        openedMessage.message,
                                                }}
                                            />
                                        </Grid>
                                    ) : (
                                        ''
                                    )}
                                </Grid>
                                <hr />
                                {messageDetails.length > 0 ? (
                                    <TableContainer>
                                        <Table
                                            className={classes.table}
                                            aria-label="simple table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Investor
                                                    </TableCell>
                                                    <TableCell>
                                                        Status
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {messageDetails.map(
                                                    (row, i) => (
                                                        <TableRow key={row.id}>
                                                            <TableCell>
                                                                {row.full_name}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.status}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    <Grid container justifyContent="center">
                                        <CircularProgress size={24} />
                                    </Grid>
                                )}

                                <DialogActions>
                                    <Button
                                        color="secondary"
                                        variant="outlined"
                                        className={classes.secondaryButtonLabel}
                                        onClick={() => {
                                            closeDialog();
                                        }}
                                    >
                                        <b>Close</b>
                                    </Button>
                                </DialogActions>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>
        </LockedTile>
    );
};

const mapStateToProps = ({
    profile,
    investorNotifications,
    shareholders,
    events,
}) => {
    return {
        profile: profile,
        investorNotifications: investorNotifications,
        shareholders: shareholders.data,
        events: events.recent_events.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        investorNotificationActions: bindActionCreators(
            investorNotificationActions,
            dispatch
        ),
        shareholderActions: bindActionCreators(shareholderActions, dispatch),
        eventActions: bindActionCreators(eventActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Communications);
