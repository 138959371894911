import request from '../../utilities/request';
import * as config from '../../config';
import * as investorNotificationTypes from './investorNotificationTypes';

export const getInvestorNotificationSuccess = (summary) => {
    return {
        type: investorNotificationTypes.GET_INVESTOR_NOTIFICATION_SUCCESS,
        payload: summary,
    };
};

export const setInvestorNotificationLoading = (loading) => {
    return {
        type: investorNotificationTypes.SET_INVESTOR_NOTIFICATION_LOADING,
        payload: loading,
    };
};

export const getInvestorNotifications = () => {
    return (dispatch) => {
        dispatch(setInvestorNotificationLoading(true));
        request
            .get(`${config.API_BASE_URL}/investors/notifications`)
            .then((res) => {
                dispatch(getInvestorNotificationSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setInvestorNotificationLoading(false));
            });
    };
};
