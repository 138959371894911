import React, { useState } from 'react';

import { Tab, Tabs, Box } from '@mui/material';

import {
    Assets,
    Managers,
    Wallet,
    Categories,
    Gaid,
    GreenWallet,
} from './tabs';
import StyledPaper from '../StyledPaper';

const LiquidTabs = [
    { value: 'assets', label: 'Assets' },
    { value: 'categories', label: 'Categories' },
    { value: 'managers', label: 'Managers' },
    { value: 'wallet', label: 'Wallet' },
    { value: 'greenWallet', label: 'GreenWallet' },
    { value: 'gaid', label: 'Gaid' },
];

const LiquidIssuance = (props) => {
    const [value, setValue] = useState('assets');
    const [currentTab, setCurrentTab] = useState(<Assets />);
    const tabChange = (_event, newValue) => {
        setValue(newValue);
        switch (newValue) {
            case 'assets':
                setCurrentTab(<Assets />);
                break;
            case 'wallet':
                setCurrentTab(<Wallet />);
                break;
            case 'categories':
                setCurrentTab(<Categories />);
                break;
            case 'managers':
                setCurrentTab(<Managers />);
                break;
            case 'gaid':
                setCurrentTab(<Gaid />);
                break;
            case 'greenWallet':
                setCurrentTab(<GreenWallet />);
                break;
            default:
                setCurrentTab(<Assets />);
        }
    };

    return (
        <Box>
            <StyledPaper elevation={3} marginBottom={20}>
                <Tabs
                    value={value}
                    onChange={tabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {LiquidTabs.map((item) => (
                        <Tab
                            key={item.value}
                            value={item.value}
                            label={item.label}
                        />
                    ))}
                </Tabs>
                <br />
            </StyledPaper>
            {currentTab}
        </Box>
    );
};

export default LiquidIssuance;
