import makeStyles from '@mui/styles/makeStyles';

const investorDocumentStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: 15,
        padding: 10,
        height: '100%',
    },
    iconColor: {
        color: '#3E9CD9',
    },
    gridLeft: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'left',
    },
    gridRight: {
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'right',
    },
    gridText: {
        textDecoration: 'underline',
        fontSize: '1rem',
        color: '#3E9CD9',
    },
    dialogActions: {
        marginTop: '1.5rem',
        alignItems: 'center',
    },
}));

export default investorDocumentStyles;
