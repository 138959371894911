import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import authStyles from '../authStyles';
import { useSnackbar } from 'notistack';
import * as config from './../../../config';
import request from '../../../utilities/request';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CreateIcon from '@mui/icons-material/Create';
import TodayIcon from '@mui/icons-material/Today';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Container from '@mui/material/Container';
import TextField from '../../../components/TextField/TextField';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sponsorAdvisorUpdateActions from '../../../store/sponsorAdvisorUpdate/sponsorAdvisorUpdateActions';
import LockedTile from '../../../components/LockedTile';

const StyledTableRow = styled(TableRow)({
    '&:nth-of-type(odd)': {
        color: '#3E9CD9',
    },
});

const validationSchema = Yup.object({
    value: Yup.string().required('Please enter required information'),
});

let initialValues = {};
const SponsorAdvisorUpdate = (props) => {
    const classes = authStyles();
    const { enqueueSnackbar } = useSnackbar();
    let demo;
    let listing;
    try {
        demo = props.profile.demo;
        listing = props.profile.scope === 'list';
    } catch (e) {
        demo = null;
        listing = null;
    }

    const [sentHistoryDialog, setSentHistoryDialog] = useState(false);
    const [sentHistoryData, setSentHistoryData] = useState([]);
    const [editDialog, setEditDialog] = useState(false);
    const [confirmationDialog, setConfirmationDialog] = useState(false);
    const [editData, setEditData] = useState([]);
    const [editDataArray, setEditDataArray] = useState([]);
    const [key, setKey] = useState(1);

    useEffect(props.sponsorAdvisorUpdateActions.getSponsorAdvisorUpdates, []);

    /*  const handleChange = (event) => {
        setSwitchValue(!switchValue);
    };*/

    /*  let sentHistory = [
        { id: 1, name: 'Board of Directors', date: '11/23/2020' },
    ];*/

    let updates = [
        {
            title: 'Change to corporate calendar',
            type: 'CORPORATE_CALENDAR',
        },
        {
            title: 'Change of external auditors',
            type: 'EXTERNAL_AUDITORS',
        },
        {
            title: 'Update of Board of Directors',
            type: 'BOARD_OF_DIRECTORS',
        },
        {
            title: 'Change of legal name (or company name)',
            type: 'LEGAL_COMPANY_NAME',
        },
        {
            title:
                'Change of company registration number in its place of Incorporation',
            type: 'REGISTRATION_NUMBER',
        },
        {
            title: 'Change of address of registered office and invoice address',
            type: 'ADDRESS',
        },
        {
            title: 'Change of website address',
            type: 'WEBSITE_ADDRESS',
        },

        {
            title: 'Change in corporate officers',
            type: 'CORPORATE_OFFICERS',
        },
        {
            title: "Company's fiscal year change",
            type: 'FISCAL_YEAR',
        },
        {
            title: 'Change of company secretary',
            type: 'COMPANY_SECRETARY',
        },
        {
            title: 'Change of balance sheet date (close of financial year)',
            type: 'BALANCE_SHEET_DATA',
        },

        {
            title: 'Capitalization issues',
            type: 'CAPITALIZATION_ISSUES',
        },
        { title: 'Right issues', type: 'RIGHT_ISSUES' },

        { title: 'Asset buybacks', change: 'No', type: 'ASSET_BUYBACKS' },
        {
            title: 'Takeovers and reverse takeovers',
            type: 'TAKEOVERS',
        },
        {
            title: 'Event of impending default',
            type: 'EVENT_OF_IMPENDING_DEFAULT',
        },

        {
            title: 'Any fundamental change in the business',
            type: 'ANY_FUNDAMENTAL_CHANGE',
        },
        {
            title: 'Other',
            type: 'OTHER',
        },

        {
            title: 'None',
            type: 'NONE',
        },
    ];

    let defaultSwitchValues = [];
    updates.map((update) => {
        defaultSwitchValues.push({ [update.type]: false });
        return true;
    });

    const [switchValues, setSwitchValues] = useState([]);

    const closeDialog = () => {
        setKey(key + 1);
        setSentHistoryDialog(false);
        setEditDialog(false);
        setConfirmationDialog(false);
    };

    const displaySentHistoryDialog = (data) => {
        request
            .get(
                `${config.API_BASE_URL}/sponsor-advisor-updates/type/` +
                    data['type'] +
                    `?date=` +
                    data['created_at']
            )
            .then((response) => {
                if (response.data.length > 0) {
                    data['after_update'] = response.data[0].value;
                    if (response.data[1]) {
                        data['before_update'] = response.data[1].value;
                    }
                } else {
                    data['after_update'] = '';
                    data['before_update'] = '';
                }
                setSentHistoryData(data);
                setSentHistoryDialog(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const displayEditDialog = (target, data) => {
        if (target.name === 'NONE') {
            /*  let switchValuesArray = [];
            for (var i = 0; i < editDataArray.length; i++) {
                switchValuesArray.push({
                    [editDataArray[i].type]: false,
                });
            }
            switchValuesArray.push({
                [target.name]: target.checked,
            });*/
            setSwitchValues({ [target.name]: target.checked });
            setEditDataArray([
                {
                    title: 'None',
                    type: 'NONE',
                    value: ' ',
                },
            ]);
            setKey(key + 1);
        } else {
            setSwitchValues({
                ...switchValues,
                [target.name]: target.checked,
                NONE: false,
            });
            if (target.checked) {
                let edited = false;
                if (editDataArray.length > 0) {
                    edited = editDataArray.map((item) => {
                        if (item.type === data.type) {
                            data['value'] = item.value;
                        }
                        return item.type === data.type;
                    });

                    let filteredArray = editDataArray.filter(
                        (item) => item.type !== 'NONE'
                    );
                    setEditDataArray(filteredArray);
                }

                if (edited) {
                    setEditData(data);
                    setEditDialog(true);
                } else {
                    request
                        .get(
                            `${config.API_BASE_URL}/sponsor-advisor-updates/type/` +
                                data['type']
                        )
                        .then((response) => {
                            if (response.data.length > 0) {
                                data['value'] = response.data[0].value;
                            } else {
                                data['value'] = '';
                            }
                            setEditData(data);
                            setEditDialog(true);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            } else {
                if (editDataArray.length > 0) {
                    let filteredArray = editDataArray.filter(
                        (item) => item.type !== data.type
                    );
                    setEditDataArray(filteredArray);
                    setEditDialog(false);
                }
            }
        }
    };

    const displayConfirmationDialog = () => {
        setConfirmationDialog(true);
    };

    return (
        <LockedTile>
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {}}
            >
                <Form>
                    <Typography component="div" style={{ textAlign: 'right' }}>
                        <h3>
                            Listing Status:{' '}
                            <b style={{ color: '#3E9CD9' }}>Active</b>
                        </h3>
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Paper elevation={3} className={classes.paper}>
                                <Typography className={classes.title}>
                                    Please check the corresponding box if any of
                                    the below changes have occured in the last
                                    month
                                </Typography>
                                <Grid container>
                                    {updates.map((row, i) => (
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            sm={6}
                                            md={6}
                                        >
                                            <Grid item xs={2} sm={1} md={1}>
                                                <FormControlLabel
                                                    label=""
                                                    control={
                                                        <Checkbox
                                                            key={key}
                                                            checked={
                                                                switchValues[
                                                                    row.type
                                                                ]
                                                            }
                                                            onChange={(event) =>
                                                                displayEditDialog(
                                                                    event.target,
                                                                    row
                                                                )
                                                            }
                                                            name={row.type}
                                                            color="primary"
                                                        />
                                                    }
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={10}
                                                sm={10}
                                                md={10}
                                                className={
                                                    classes.checkboxLabel
                                                }
                                            >
                                                {row.title}
                                            </Grid>{' '}
                                        </Grid>
                                    ))}
                                </Grid>

                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: '1rem',
                                    }}
                                >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        disabled={editDataArray.length <= 0}
                                        color="primary"
                                        onClick={() => {
                                            displayConfirmationDialog();
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Paper elevation={3} className={classes.paper}>
                                <Typography className={classes.title}>
                                    Sent History
                                </Typography>
                                <TableContainer>
                                    <Table
                                        style={{ border: 'none' }}
                                        className={classes.table}
                                        aria-label="simple table"
                                    >
                                        <TableBody>
                                            {(demo || listing) &&
                                            props.sponsorAdvisorUpdates.data ? (
                                                props.sponsorAdvisorUpdates.data.map(
                                                    (
                                                        sponsorAdvisorUpdate,
                                                        i
                                                    ) => (
                                                        <StyledTableRow key={i}>
                                                            <TableCell
                                                                className={
                                                                    classes.tableCell
                                                                }
                                                                style={{
                                                                    cursor:
                                                                        'pointer',
                                                                }}
                                                                onClick={() => {
                                                                    displaySentHistoryDialog(
                                                                        sponsorAdvisorUpdate
                                                                    );
                                                                }}
                                                            >
                                                                {
                                                                    sponsorAdvisorUpdate.title
                                                                }
                                                            </TableCell>
                                                            <TableCell
                                                                className={
                                                                    classes.tableCell
                                                                }
                                                            >
                                                                {
                                                                    sponsorAdvisorUpdate.created_at
                                                                }
                                                            </TableCell>
                                                        </StyledTableRow>
                                                    )
                                                )
                                            ) : (
                                                <TableRow
                                                    style={{
                                                        fontSize: '1.1rem',
                                                    }}
                                                ></TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>{' '}
                            </Paper>
                        </Grid>
                    </Grid>

                    <Dialog
                        maxWidth="sm"
                        fullWidth
                        open={sentHistoryDialog}
                        onBackdropClick={(e) => setSentHistoryDialog(false)}
                    >
                        <DialogTitle className={classes.dialogTitle}>
                            Sent History
                            <br />
                        </DialogTitle>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={6} sm={12} md={12}>
                                    <List style={{ marginTop: -25 }}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <CreateIcon
                                                    fontSize="large"
                                                    style={{ color: '#3E9CD9' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        variant="body2"
                                                        component="p"
                                                        style={{
                                                            color: '#0067b3',
                                                        }}
                                                    >
                                                        Title
                                                    </Typography>
                                                }
                                                secondary={
                                                    <React.Fragment>
                                                        <b
                                                            style={{
                                                                fontSize:
                                                                    '1.1rem',
                                                                color: 'black',
                                                            }}
                                                        >
                                                            {sentHistoryData.length !==
                                                            null
                                                                ? sentHistoryData.title
                                                                : ''}
                                                        </b>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <List style={{ marginTop: -25 }}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <TodayIcon
                                                    fontSize="large"
                                                    style={{ color: '#3E9CD9' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        variant="body2"
                                                        component="p"
                                                        style={{
                                                            color: '#0067b3',
                                                        }}
                                                    >
                                                        Date
                                                    </Typography>
                                                }
                                                secondary={
                                                    <React.Fragment>
                                                        <b
                                                            style={{
                                                                fontSize:
                                                                    '1.1rem',
                                                                color: 'black',
                                                            }}
                                                        >
                                                            {sentHistoryData.length !==
                                                            null
                                                                ? sentHistoryData.created_at
                                                                : ''}
                                                        </b>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <hr
                                    style={{ color: '#3E9CD9', width: '100%' }}
                                />
                                <Grid>
                                    <Typography
                                        component="div"
                                        className="flex-left"
                                    >
                                        <NavigateNextIcon />
                                        <h3 style={{ color: '#3E9CD9' }}>
                                            After Update
                                        </h3>
                                    </Typography>
                                    <ListItem>
                                        <ListItemText
                                            style={{
                                                whiteSpace: 'pre-line',
                                            }}
                                            primary={
                                                sentHistoryData.after_update
                                            }
                                        />
                                    </ListItem>
                                </Grid>
                                <hr
                                    style={{ color: '#3E9CD9', width: '100%' }}
                                />
                                <Grid>
                                    <Typography
                                        component="div"
                                        className="flex-left"
                                    >
                                        <NavigateBeforeIcon />
                                        <h3 style={{ color: '#3E9CD9' }}>
                                            Before Update
                                        </h3>
                                    </Typography>
                                    <ListItem>
                                        <ListItemText
                                            style={{
                                                whiteSpace: 'pre-line',
                                            }}
                                            primary={
                                                sentHistoryData.before_update
                                            }
                                        />
                                    </ListItem>
                                </Grid>{' '}
                            </Grid>
                        </DialogContent>
                        <DialogActions
                            style={{
                                marginTop: '1.5rem',
                                alignItems: 'right',
                            }}
                        >
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => {
                                    closeDialog();
                                }}
                            >
                                <b>Close</b>
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        maxWidth="sm"
                        fullWidth
                        open={editDialog}
                        onBackdropClick={(e) => setEditDialog(false)}
                    >
                        <DialogTitle className={classes.dialogTitle}>
                            {editData.title}
                        </DialogTitle>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12} md={12} sm={12}>
                                    <Formik
                                        validationSchema={validationSchema}
                                        initialValues={editData}
                                        onSubmit={(
                                            values,
                                            { setSubmitting }
                                        ) => {
                                            editDataArray.push(values);
                                            setEditDataArray(editDataArray);
                                            setEditDialog(false);
                                        }}
                                    >
                                        {({ isSubmitting }) => (
                                            <Container>
                                                <Form
                                                    className={classes.form}
                                                    noValidate
                                                >
                                                    <Field
                                                        component={TextField}
                                                        name="value"
                                                        type="text"
                                                        label={editData.title}
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        required
                                                        multiline
                                                        rows={5}
                                                    />

                                                    <DialogActions
                                                        style={{
                                                            marginTop: '1.5rem',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Button
                                                            color="secondary"
                                                            variant="outlined"
                                                            onClick={() => {
                                                                closeDialog();
                                                            }}
                                                        >
                                                            <b>Cancel</b>
                                                        </Button>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            <b>Save</b>
                                                        </Button>
                                                    </DialogActions>
                                                </Form>
                                            </Container>
                                        )}
                                    </Formik>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>

                    <Dialog
                        maxWidth="sm"
                        fullWidth
                        open={confirmationDialog}
                        onBackdropClick={(e) => setConfirmationDialog(false)}
                    >
                        <DialogTitle className={classes.dialogTitle}>
                            Submit
                            <br />
                        </DialogTitle>
                        <DialogContent>
                            <Formik
                                initialValues={editDataArray}
                                onSubmit={(values, { setSubmitting }) => {
                                    request
                                        .post(
                                            `${config.API_BASE_URL}/sponsor-advisor-updates`,
                                            values
                                        )
                                        .then((response) => {
                                            enqueueSnackbar(
                                                'Information has been updated successfully',
                                                {
                                                    variant: 'success',
                                                }
                                            );

                                            setKey(key + 1);

                                            let switchValuesArray = [];
                                            for (
                                                var i = 0;
                                                i < editDataArray.length;
                                                i++
                                            ) {
                                                switchValuesArray.push({
                                                    [editDataArray[i]
                                                        .type]: false,
                                                });
                                            }
                                            setSwitchValues(switchValuesArray);
                                            setEditDataArray([]);
                                            setConfirmationDialog(false);
                                            props.sponsorAdvisorUpdateActions.getSponsorAdvisorUpdates();
                                        })
                                        .catch(() => {
                                            enqueueSnackbar('Error', {
                                                variant: 'error',
                                            });
                                            setConfirmationDialog(false);
                                            setKey(key + 1);
                                        });
                                }}
                            >
                                {({ isSubmitting }) => (
                                    <Container>
                                        <Form
                                            className={classes.form}
                                            noValidate
                                        >
                                            <Typography>
                                                Are you sure information is
                                                correct?
                                            </Typography>

                                            <DialogActions
                                                style={{
                                                    marginTop: '1.5rem',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Button
                                                    color="secondary"
                                                    variant="outlined"
                                                    onClick={() => {
                                                        closeDialog();
                                                    }}
                                                >
                                                    <b>Cancel</b>
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                >
                                                    {!isSubmitting ? (
                                                        <b>Save</b>
                                                    ) : (
                                                        <CircularProgress
                                                            size={24}
                                                        />
                                                    )}
                                                </Button>
                                            </DialogActions>
                                        </Form>
                                    </Container>
                                )}
                            </Formik>
                        </DialogContent>
                    </Dialog>
                </Form>
            </Formik>
        </LockedTile>
    );
};
const mapStateToProps = ({ sponsorAdvisorUpdates, profile }) => {
    return {
        sponsorAdvisorUpdates: sponsorAdvisorUpdates,
        profile: profile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        sponsorAdvisorUpdateActions: bindActionCreators(
            sponsorAdvisorUpdateActions,
            dispatch
        ),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SponsorAdvisorUpdate);
