import useSWR from "swr"
import { API_BASE_URL } from "../../config"
import fetcher from "../../utilities/fetcher"

const useGreenWalletTransactions = (investor_id, amp_user_id) => {
    const { data, error, isValidating, mutate } = useSWR(`${API_BASE_URL}/blockchain/${investor_id}/sub-accounts/${amp_user_id}/transactions`, fetcher)

    return {
        greenWalletTransactions: data,
        greenWalletTransactionsLoading: !error && !data,
        greenWalletTransactionsValidating: isValidating,
        greenWalletTransactionsError: error,
        updateGreenWalletTransactions: () => mutate()
    }
}

export default useGreenWalletTransactions