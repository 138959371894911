import React, { useState } from 'react';
import {
    Switch,
    Route,
    useRouteMatch,
    NavLink,
    useLocation,
} from 'react-router-dom';
import Container from '@mui/material/Container';

import Header from '../components/Header/Header';
import Footer from '../components/Footer';
import AdminListRoutes from './admin/AdminListRoutes';
import AdminUserRoutes from './admin/AdminUserRoutes';
import AdminTreeBRRoutes from './admin/AdminTreeBRRoutes';
import AdminTransferAgentRoutes from './admin/AdminTransferAgentRoutes';
import StyledTabs from '../components/Tabs/StyledTabs';
import StyledMainTab from '../components/Tabs/StyledMainTab';
import { connect } from 'react-redux';
import Loading from 'react-fullscreen-loading';

import LogoImage from '../assets/img/new_logo.png';
import {
    DASHBOARD,
    LISTX,
    TREEBR,
    TRANSFER_AGENTS,
} from './../constants/routes';
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AdminRoutes = (props) => {
    let { path } = useRouteMatch();
    const location = useLocation();

    const getActiveTab = () => {
        switch (location.pathname) {
            case '/admin/':
            case '/admin/dash':
            case '/admin/preferences':
                return 0;
            case '/admin/listx':
                return 1;
            case '/admin/treebr':
                return 2;
            case '/admin/transfer-agents':
                return 3;
            default:
                return 0;
        }
    };

    const [value, setValue] = useState(getActiveTab());
    return (
        <>
            <Header type="admin" companyLogo={LogoImage} />
            <Container className="main-container">
                {/* Loading screen */}
                <Loading
                    loading={!props.profile.preferred_tabs}
                    background="#fff"
                    loaderColor="#3E9CD9"
                />
                {props.profile.preferred_tabs && (
                    <React.Fragment>
                        <main>
                            <StyledTabs
                                scrollButtons="auto"
                                value={value}
                                onChange={(e, v) => setValue(v)}
                                variant="scrollable"
                                aria-label="simple tabs example"
                                style={{
                                    backgroundColor: '#fff',
                                    color: '#000',
                                }}
                                TabIndicatorProps={{
                                    style: { background: '#e0e0e0' },
                                }}
                            >
                                <StyledMainTab
                                    value={0}
                                    label={
                                        value === DASHBOARD ? (
                                            <b>Dashboard</b>
                                        ) : (
                                            'Dashboard'
                                        )
                                    }
                                    to={`${path}/`}
                                    component={NavLink}
                                    {...a11yProps(0)}
                                />
                                <StyledMainTab
                                    value={1}
                                    label={
                                        value === LISTX ? <b>ListX</b> : 'ListX'
                                    }
                                    {...a11yProps(2)}
                                    to={`${path}/listx`}
                                    component={NavLink}
                                />
                                <StyledMainTab
                                    value={2}
                                    label={
                                        value === TREEBR ? (
                                            <b>TreeBR</b>
                                        ) : (
                                            'TreeBR'
                                        )
                                    }
                                    {...a11yProps(3)}
                                    to={`${path}/treebr`}
                                    component={NavLink}
                                />
                                <StyledMainTab
                                    value={3}
                                    label={
                                        value === TRANSFER_AGENTS ? (
                                            <b>Transfer agents</b>
                                        ) : (
                                            'Transfer Agents'
                                        )
                                    }
                                    {...a11yProps(3)}
                                    to={`${path}/transfer-agents`}
                                    component={NavLink}
                                />
                            </StyledTabs>

                            <Switch>
                                <Route
                                    path={`${path}/dash`}
                                    component={AdminUserRoutes}
                                />
                                <Route
                                    path={`${path}/listx`}
                                    component={AdminListRoutes}
                                />
                                <Route
                                    path={`${path}/treebr`}
                                    component={AdminTreeBRRoutes}
                                />
                                <Route
                                    path={`${path}/transfer-agents`}
                                    component={AdminTransferAgentRoutes}
                                />
                                <Route
                                    path={path}
                                    component={AdminUserRoutes}
                                />
                            </Switch>
                        </main>
                    </React.Fragment>
                )}
                <Footer hideRelease={true} />
            </Container>
        </>
    );
};

const mapStateToProps = ({ profile }) => {
    return {
        profile: profile,
    };
};

export default connect(mapStateToProps, null)(AdminRoutes);
