import request from '../../utilities/request';
import * as config from '../../config';
import * as issuerInviteTypes from './issuerInviteTypes';

import { snackbar } from '../../utilities/snackbarUtils'
import { parseError } from '../../utilities/utils';

/**
 * LOAD ISSUER INVITES.
 */
export const getIssuerInvitesSuccess = (invites) => {
    return {
        type: issuerInviteTypes.GET_ISSUER_INVITES_SUCCESS,
        payload: invites,
    };
};

export const setIssuerInvitesLoading = (loading) => {
    return {
        type: issuerInviteTypes.SET_ISSUER_INVITES_LOADING,
        payload: loading,
    };
};

export const getIssuerInvites = () => {
    return (dispatch) => {
        dispatch(setIssuerInvitesLoading(true));
        request.get(`${config.API_BASE_URL}/issuer-invites`)            
            .then((res) => {
                dispatch(getIssuerInvitesSuccess(res.data));
            })
            .catch((error) => {
                let errorMessage = parseError(error, true)
                snackbar.error(errorMessage)
            })
            .finally(() => {
                dispatch(setIssuerInvitesLoading(false));
            });
    };
};

/**
 * SEND ISSUER INVITES
 */
 export const sendInvitesSuccess = (invites) => {
    return {
        type: issuerInviteTypes.SEND_ISSUER_INVITES_SUCCESS,
        payload: invites,
    };
};

export const sendInvites = (data, setSubmitting, reset, successCallback = null) => {
    setSubmitting(true)
    return (dispatch) => {
        dispatch(setIssuerInvitesLoading(true));
        request.post(`${config.API_BASE_URL}/issuer-invite`, data)            
            .then((res) => {
                snackbar.success('Invites successfully sent.')
                dispatch(sendInvitesSuccess(res.data));
                reset()

                // Invoke callback, if it exists
                if (successCallback) {
                    successCallback()
                }
            })
            .catch((error) => {
                let errorMessage = parseError(error, true)
                snackbar.error(errorMessage)
            })
            .finally(() => {
                setSubmitting(false)
                dispatch(setIssuerInvitesLoading(false));
            });
    };
};

/**
 * CANCEL AN INVITE
 */
export const cancelInviteSuccess = () => {
    return {
        type: issuerInviteTypes.SEND_ISSUER_INVITES_SUCCESS,
    };
};

export const cancelInvite = (inviteId, successCallback, dialogActions) => {
    return (dispatch) => {
        dispatch(setIssuerInvitesLoading(true));
        dialogActions.loadDialog(true)
        request.delete(`${config.API_BASE_URL}/issuer-invites/${inviteId}`)            
            .then((res) => {
                snackbar.success(res.data.message)
                dispatch(cancelInviteSuccess());
                dialogActions.closeDialog()
                
                // Invoke callback, if it exists
                if (successCallback) {
                    successCallback()
                }
            })
            .catch((error) => {
                let errorMessage = parseError(error, true)
                snackbar.error(errorMessage)
            })
            .finally(() => {
                dispatch(setIssuerInvitesLoading(false));
                dialogActions.loadDialog(false)
            });
    };
};