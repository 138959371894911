import React from 'react';
import { DateTimePicker } from 'formik-mui-lab';
import FormControl from '@mui/material/FormControl';

const FormikDateTimePicker = (props) => {
    const { fullWidth } = props

    return (
        <FormControl fullWidth={fullWidth}>
            <DateTimePicker
                {...props}
            />
        </FormControl>
    );
};

export default FormikDateTimePicker;
