import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as config from './../../../config';
import request from '../../../utilities/request';
//import { isViewMode } from '../../../utilities/utils';
import { useSnackbar } from 'notistack';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import authStyles from '../authStyles';
import transferStyles from '../../../components/styles/transferStyles';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import TextField from '../../../components/TextField/TextField';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import * as capTableActions from '../../../store/capTable/capTableActions';
import CircularProgress from '@mui/material/CircularProgress';
import LockedTile from '../../../components/LockedTile';
import { SUBSCRIPTION_PRO } from '../../../constants/profile';
import { RETIREMENT } from '../../../constants/transfers';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Fab } from '@mui/material';

import {
    validateFileExtension,
    allowedFiles,
    allowedFilesMessage,
} from '../../../utilities/utils';
const formData = new FormData();
formData.append('document', '');

const Retirements = (props) => {
    const classes = authStyles();
    const { closeSnackbar } = useSnackbar();
    const transferClasses = transferStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [shareClass, setShareClass] = useState(null);
    const [category, setCategory] = useState(null);
    const [retirementReason, setRetirementReason] = useState('');
    const [fromShareholder, setFromShareholder] = useState(null);

    let [read, setRead] = useState(0);
    const [amount, setAmount] = useState(0);
    const [shareholderShares, setShareholderShares] = useState(0);
    const [initialValues, setInitialValues] = useState({
        amount: amount,
        reason: retirementReason,
    });

    const [retirementDocument, setRetirementDocument] = useState('');

    useEffect(props.capTableActions.getClasses, []);

    useEffect(() => {
        if (props.classes && props.classes.length && read !== 1) {
            setShareClass(props.classes[0].id);
            props.capTableActions.getCategoriesByClassId(props.classes[0].id);
            if (props.categories && props.categories.length) {
                setCategory(props.categories[0].id);
                props.capTableActions.getShareholdersByCategory(
                    props.categories[0].id
                );
                setRead(1);
            }
        }
    }, [
        props.capTableActions,
        props.classes,
        props.categories,
        props.shareholders,
        read,
    ]);

    async function fetchCategories(class_id) {
        const categories = await request.get(
            `${config.API_BASE_URL}/share-classes/categories/${class_id}`
        );
        if (categories.data.length > 0) {
            setCategory(categories.data[0].id);
            props.capTableActions.getCategoriesByClassId(class_id);
            props.capTableActions.getShareholdersByCategory(
                categories.data[0].id
            );
        } else {
            setCategory(null);
            props.capTableActions.getCategoriesByClassId(null);
            props.capTableActions.getShareholdersByCategory(null);
            setFromShareholder(null);
        }
    }
    const handleClassChange = (class_id) => {
        setShareClass(class_id);
        fetchCategories(class_id);
    };

    const handleCategoryChange = (category_id) => {
        setCategory(category_id);
        if (category_id === null) {
            setFromShareholder(null);
        } else {
            props.capTableActions.getShareholdersByCategory(category_id);
        }
    };

    const handleShareholderChange = (value, type) => {
        let investor = props.shareholders.filter((element) => {
            return element.investor === value;
        })[0];
        setFromShareholder(investor);
        if (investor) {
            setShareholderShares(investor.shares);
            setAmount(investor.shares);
            setInitialValues({
                amount: investor.shares,
                reason: retirementReason,
            });
        } else {
            setShareholderShares(0);
            setAmount(0);
            setInitialValues({
                amount: 0,
                reason: '',
            });
        }
    };

    const uploadFile = (file) => {
        if (!validateFileExtension(file)) {
            enqueueSnackbar(allowedFilesMessage, {
                variant: 'error',
            });
            setTimeout(function () {
                closeSnackbar();
            }, 3500);
        } else {
            formData.set('document', file, file.name);
            setRetirementDocument(file.name);
        }
    };

    return (
        <LockedTile
            requiredPlan={SUBSCRIPTION_PRO}
            //lock={isViewMode(props.profile)}
        >
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={(values, { setSubmitting }) => {
                    if (
                        shareClass === null ||
                        shareClass === undefined ||
                        category === null ||
                        category === undefined ||
                        fromShareholder === null ||
                        fromShareholder === undefined ||
                        retirementDocument === null ||
                        retirementDocument === undefined ||
                        retirementReason === null ||
                        retirementReason === undefined ||
                        retirementReason === ''
                    ) {
                        enqueueSnackbar(
                            'Something went wrong. Please enter all information',
                            {
                                variant: 'error',
                            }
                        );
                        setSubmitting(false);
                    } else if (
                        parseFloat(amount.replace(/,/g, '')) >
                        parseFloat(fromShareholder.shares.replace(/,/g, ''))
                    ) {
                        enqueueSnackbar(
                            'Amount of assets cannot be higer than total amount of assets that holder owns',
                            {
                                variant: 'error',
                            }
                        );
                        setSubmitting(false);
                    } else {
                        values['share_class_id'] = shareClass;
                        values['equity_id'] = category;
                        values['sender_id'] = fromShareholder.investor_id;
                        values['sender'] = fromShareholder;
                        values['type'] = RETIREMENT;
                        values['reason'] = retirementReason;
                        values['amount'] = parseFloat(amount.replace(/,/g, ''));
                        values['is_routine'] = false;
                        for (var key in values) {
                            formData.append(key, values[key]);
                        }
                        values['document'] = formData;
                        request
                            .post(
                                `${config.API_BASE_URL}/transfers/issuer/retirement`,
                                formData
                            )
                            .then((response) => {
                                setFromShareholder(null);
                                setSubmitting(false);
                                setAmount('');
                                setRetirementReason('');
                                enqueueSnackbar(
                                    'Retirement submitted successfully',
                                    {
                                        variant: 'success',
                                    }
                                );
                            })
                            .catch((error) => {
                                setSubmitting(false);
                            });
                    }
                }}
            >
                {({ isSubmitting }) => (
                    <Form noValidate>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={8} md={8}>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography className={classes.title}>
                                        Retirements
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel
                                                className={classes.label}
                                            >
                                                Select class
                                            </InputLabel>

                                            <FormControl
                                                variant="outlined"
                                                className={classes.formControl}
                                                fullWidth
                                            >
                                                {props.classes &&
                                                props.classes.length ? (
                                                    <Select
                                                        value={
                                                            shareClass
                                                                ? shareClass
                                                                : props
                                                                      .classes[0]
                                                                      .id
                                                        }
                                                        onChange={(e) =>
                                                            handleClassChange(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        {props.classes.map(
                                                            (class_item, i) => (
                                                                <MenuItem
                                                                    value={
                                                                        class_item.id
                                                                    }
                                                                >
                                                                    {
                                                                        class_item.class_description
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                ) : (
                                                    <Select
                                                        value={null}
                                                        onChange={(e) =>
                                                            setShareClass(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        <MenuItem>
                                                            No data
                                                        </MenuItem>
                                                    </Select>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel
                                                className={classes.label}
                                            >
                                                Select category
                                            </InputLabel>
                                            <FormControl
                                                variant="outlined"
                                                className={classes.formControl}
                                                fullWidth
                                            >
                                                {props.categories &&
                                                props.categories.length ? (
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={
                                                            category
                                                                ? category
                                                                : props
                                                                      .categories[0]
                                                                      .id
                                                        }
                                                        onChange={(e) =>
                                                            handleCategoryChange(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        {props.categories.map(
                                                            (
                                                                category_item,
                                                                i
                                                            ) => (
                                                                <MenuItem
                                                                    value={
                                                                        category_item.id
                                                                    }
                                                                >
                                                                    {
                                                                        category_item.description
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                ) : (
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={category}
                                                        onChange={(e) =>
                                                            setCategory(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        <MenuItem>
                                                            No data
                                                        </MenuItem>
                                                    </Select>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <InputLabel
                                                className={classes.label}
                                            >
                                                Investor
                                            </InputLabel>

                                            <Autocomplete
                                                name="from"
                                                id="free-solo-demo"
                                                value={
                                                    fromShareholder !== null &&
                                                    fromShareholder !==
                                                        undefined
                                                        ? fromShareholder.investor
                                                            ? fromShareholder.investor
                                                            : ''
                                                        : ''
                                                }
                                                onInputChange={(
                                                    event,
                                                    newInputValue
                                                ) => {
                                                    handleShareholderChange(
                                                        newInputValue,
                                                        'from'
                                                    );
                                                }}
                                                options={
                                                    props.shareholders
                                                        ? props.shareholders.map(
                                                              (option) =>
                                                                  option.investor
                                                          )
                                                        : []
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        type="text"
                                                        label="Investor"
                                                        margin="normal"
                                                        variant="outlined"
                                                        name="from"
                                                        fullWidth
                                                        placeholder={
                                                            'Search...'
                                                        }
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <InputLabel
                                                className={classes.label}
                                            >
                                                Amount
                                            </InputLabel>
                                            <TextField
                                                component={TextField}
                                                type="text"
                                                name="amount"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                required
                                                defaultValue={amount}
                                                numeric={1}
                                                onKeyUp={(e) =>
                                                    setAmount(e.target.value)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <InputLabel
                                                className={classes.label}
                                            >
                                                Document
                                            </InputLabel>
                                            <Grid container spacing={3}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={6}
                                                >
                                                    <TextField
                                                        id="standard-basic"
                                                        variant="outlined"
                                                        value={
                                                            retirementDocument
                                                        }
                                                        placeholder=""
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={6}
                                                >
                                                    <Fab
                                                        sx={{ float: 'left' }}
                                                        component="label"
                                                        variant="extended"
                                                        color="primary"
                                                        className={
                                                            classes.browseButton
                                                        }
                                                    >
                                                        Browse
                                                        <input
                                                            onChange={(e) => {
                                                                uploadFile(
                                                                    e.target
                                                                        .files[0]
                                                                );
                                                            }}
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                            type="file"
                                                            name="document"
                                                            accept={allowedFiles.join(
                                                                ','
                                                            )}
                                                        />
                                                    </Fab>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12}>
                                            <InputLabel
                                                className={classes.label}
                                            >
                                                Retirement Reason
                                            </InputLabel>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                name="reason"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                multiline
                                                rows={2}
                                                required
                                                defaultValue={retirementReason}
                                                onKeyUp={(e) =>
                                                    setRetirementReason(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={3} md={4}>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography className={classes.title}>
                                        Confirm Retirement
                                    </Typography>

                                    <List>
                                        <ListItem>
                                            <ListItemAvatar
                                                className={
                                                    transferClasses.greyTitle
                                                }
                                            >
                                                Investor:&nbsp;
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            transferClasses.blackTitle
                                                        }
                                                    >
                                                        <b>
                                                            {fromShareholder
                                                                ? fromShareholder.investor
                                                                    ? fromShareholder.investor
                                                                    : ''
                                                                : ''}
                                                        </b>
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar
                                                className={
                                                    transferClasses.greyTitle
                                                }
                                            >
                                                Total Shareholder Shares:&nbsp;
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            transferClasses.blackTitle
                                                        }
                                                    >
                                                        <b>
                                                            {shareholderShares
                                                                ? shareholderShares
                                                                : ''}
                                                        </b>
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar
                                                className={
                                                    transferClasses.greyTitle
                                                }
                                            >
                                                Amount:&nbsp;
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            transferClasses.blackTitle
                                                        }
                                                    >
                                                        <b>
                                                            {amount
                                                                ? amount
                                                                : ''}
                                                        </b>
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar
                                                className={
                                                    transferClasses.greyTitle
                                                }
                                            >
                                                Retirement Reason:&nbsp;
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            transferClasses.blackTitle
                                                        }
                                                    >
                                                        <b>
                                                            {retirementReason
                                                                ? retirementReason
                                                                : ''}
                                                        </b>
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </List>

                                    <div
                                        className={classes.buttonGroupAlignment}
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={
                                                classes.buttonGroupAlignment
                                            }
                                            disabled={
                                                isSubmitting ||
                                                !fromShareholder ||
                                                shareClass === null ||
                                                shareClass === undefined ||
                                                category === null ||
                                                category === undefined ||
                                                retirementDocument === null ||
                                                retirementDocument ===
                                                    undefined ||
                                                retirementReason === null ||
                                                retirementReason ===
                                                    undefined ||
                                                retirementReason === ''
                                            }
                                        >
                                            {!isSubmitting ? (
                                                <div className="flex-left">
                                                    Submit for approval
                                                </div>
                                            ) : (
                                                <CircularProgress size={24} />
                                            )}
                                        </Button>
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </LockedTile>
    );
};

const mapStateToProps = ({ profile, capTable, categories, shareholders }) => {
    return {
        profile: profile,
        classes: capTable.classes.data,
        categories: capTable.categories.data,
        shareholders: capTable.shareholders.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        capTableActions: bindActionCreators(capTableActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Retirements);
