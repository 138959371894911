import React from 'react'

import Grid from '@mui/material/Grid'

import UpdatePassword from '../../issuer/Dashboard/UpdatePassword'

const AdminPreferences = () => {
    return (
        <div>
            <Grid container spacing={2} justifyContent="center">
                <UpdatePassword padding={10} />
            </Grid>
        </div>
    );
}

export default AdminPreferences
