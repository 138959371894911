import { snackbar } from './snackbarUtils'
import { find } from 'lodash';
import { getHost } from './utils';

/**
 * Check if the page has been referred to back from Stripe.
 * @param {string} referrer Referrer URL
 */
export const checkStripeReferrer = (
        referrer = 'https://checkout.stripe.com/', 
        successMessage = 'Your payment was successful. You may now log in.',
        failMessage = 'Your payment has failed. You can try to repeat the payment once you log in.'
        ) => {
    let paymentStatus = new URLSearchParams(window.location.search).get('payment');
    if (document.referrer === referrer) {
        if (paymentStatus === 'success') {
            snackbar.success(successMessage);
        }

        if (paymentStatus === 'fail') {
            snackbar.error(failMessage);
        }
    }
} 

/**
 * Generate a Stripe checkout link.
 * @param {object} payment 
 * @param {object} paymentActions 
 * @param {string} tier 
 * @param {string} email 
 * @param {string} password 
 * @returns 
 */
export const upgradeSubscription = (payment, paymentActions, tier, email = null, password = null) => {
    let priceId = find(payment.pricePlans.data, { name: tier })?.price_id
    if (!priceId) {
        snackbar.error('Invalid price plan.');
        return;
    }

    let data = {
        success_url: getHost() + '/login?payment=success',
        cancel_url: getHost() + '/login?payment=fail',
        // success_url: 'https://dash.digtl.co/login?payment=success',
        // cancel_url: 'https://dash.digtl.co/login?payment=fail',
        issuer_email: email,
        issuer_password: password,
        price_id: priceId,
    };

    paymentActions.createCheckoutSession(data)
};