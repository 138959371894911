import * as offeringTypes from './offeringTypes';

const initialState = {
    data: null,
    loading: true,
};

const offeringReducer = (state = initialState, action) => {
    switch (action.type) {
        case offeringTypes.GET_OFFERINGS_SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case offeringTypes.SET_OFFERINGS_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        default:
            return state;
    }
};

export default offeringReducer;
