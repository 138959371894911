import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid'
import MuiPagination from '@mui/material/Pagination'

const Pagination = (props) => {

    const { count, callback, search } = props

    const [page, setPage] = useState(1)

    const handleChange = (e, value) => {
        setPage(value)
        callback(value, search)
    }

    useEffect(() => {
        setPage(1)
        callback(page, search)
        // eslint-disable-next-line
    }, [search])

    return (
        <Grid container justifyContent="center">
            <Grid item>
                <br/>
                <MuiPagination 
                    count={count} page={page} 
                    onChange={handleChange}
                    showFirstButton showLastButton
                    color="primary"
                />
            </Grid>
        </Grid>
    );
}

Pagination.propTypes = {
    count: PropTypes.number.isRequired,
    callback: PropTypes.func.isRequired
}

export default Pagination
