import jwtDecode from 'jwt-decode';
import { setStorageItem, getStorageItem, clearStorage, clearStorageItem } from './storage';

export const getAccessToken = () => getStorageItem('access_token');

export const setAccessToken = (value) => setStorageItem('access_token', value);

export const getRefreshToken = () => getStorageItem('refresh_token');

export const setRefreshToken = (value) =>
    setStorageItem('refresh_token', value);

export const resetSession = () => clearStorage();

export const isRefreshTokenValid = () => {
    const refreshToken = getRefreshToken();

    if (refreshToken) {
        try {
            const decodedToken = jwtDecode(refreshToken);

            if (decodedToken && decodedToken.exp) {
                return decodedToken.exp > Date.now() / 1000 || true;
            }
        } catch (error) {
            console.log(error);
        }
    }

    return false;
};
export const handleLogout = () => {
    // const refreshToken = getRefreshToken();
    resetSession();
    window.location.reload(); // quick hack
    //profileActions.clearProfile();
    //history.replace({ pathname: '/login' });
};

export const getAccountType = () => {
    const token = getAccessToken()

    if (token) {
        try {
            const decoded = jwtDecode(token)

            if (decoded.scopes.includes('manage-users')) {
                return 'admin'
            }
            if (decoded.scopes.includes('login-as')) {
                return 'transfer-agent'
            }
            return 'issuer'   
        } catch (e) {
            return null
        }
    }

    return null
}

/**
 * Get and verify admin token.
 */
export const getTokenByType = (type = 'access_token') => {
    const token = getStorageItem(type)

    try {
        jwtDecode(token)
    } catch (e) {
        clearStorageItem(type)
        return null
    }

    return token
}