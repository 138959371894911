import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import authStyles from '../authStyles';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';

import fundraiseStyles from '../../../components/styles/fundraiseStyles';
import LockedTile from '../../../components/LockedTile';
import { SUBSCRIPTION_ENTERPRISE } from '../../../constants/profile';

let initialValues = {
    document_0: true,
    document_1: false,
    document_2: true,
    document_3: false,
};
const Templates = (props) => {
    const classes = authStyles();
    const fundraiseClasses = fundraiseStyles();
    let demo;
    try {
        demo = props.profile.demo;
    } catch (e) {
        demo = null;
    }

    let [refreshForm, setRefreshForm] = useState(0);
    let [documentNumber, setDocumentNumber] = useState(3);

    let [documents, setDocuments] = useState([
        'Reg D Offering Memorandum.doc',
        'Prospectus.doc',
        'Fundraise Board Resolutions.doc',
        'Token Issuance Resolution.doc',
    ]);
    const uploadFile = (file) => {
        documentNumber++;
        setDocumentNumber(documentNumber);
        documents.push(file.name);
        setDocuments(documents);
        refreshForm++;

        initialValues['document_' + documentNumber] = false;

        setRefreshForm(refreshForm);
    };

    const deleteDocument = (key) => {
        documents.splice(key, 1);
        setDocuments(documents);
        refreshForm++;
        setRefreshForm(refreshForm);
    };

    return (
        <LockedTile requiredPlan={SUBSCRIPTION_ENTERPRISE}>
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {}}
                key={refreshForm}
            >
                <Form noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                            <Paper elevation={3} className={fundraiseClasses.paper}>
                                <Typography className={fundraiseClasses.title}>
                                    Investor Documents
                                </Typography>

                                {demo && documents.length > 0 ? (
                                    documents.map((document, i) => (
                                        <Grid container key={i}>
                                            <Grid
                                                item
                                                xs={9}
                                                className={
                                                    fundraiseClasses.documentTitle
                                                }
                                            >
                                                {document}
                                            </Grid>
                                            <Grid item xs={3}>
                                                <IconButton
                                                    className={
                                                        fundraiseClasses.deleteIcon
                                                    }
                                                    onClick={() =>
                                                        deleteDocument(i)
                                                    }
                                                    size="large">
                                                    <DeleteForeverIcon /> Delete
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    ))
                                ) : (
                                    <Grid
                                        style={{
                                            textAlign: 'center',
                                            fontSize: '1.1rem',
                                        }}
                                    >
                                        No data
                                    </Grid>
                                )}
                                <Grid
                                    className={classes.actions}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <Button
                                        component="label"
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                    >
                                        <b>ADD NEW DOCUMENT</b>
                                        <input
                                            onChange={(e) => {
                                                uploadFile(e.target.files[0]);
                                            }}
                                            style={{ display: 'none' }}
                                            type="file"
                                            name="documents"
                                            accept="*"
                                        />
                                    </Button>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </LockedTile>
    );
};
const mapStateToProps = ({ profile }) => {
    return {
        profile: profile,
    };
};

export default connect(mapStateToProps)(Templates);
