import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, Grid } from '@mui/material';
import * as Yup from 'yup';

import TextField from '../../../TextField/TextField';
import { assets } from '../../api';
import { snackbar } from '../../../../utilities/snackbarUtils';
import { errorParser } from '../../utils/errorParser';

const initialValues = {
    amountToBurn: '',
};

const validationSchema = Yup.object({
    amountToBurn: Yup.string()
        .required('Please enter the total amount')
        .matches(
            /^(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)$/,
            'Please enter a valid amount.'
        )
        .notOneOf(['0'], 'The amount cannot be 0.'),
});

const Burn = (props) => {
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                assets
                    .burn(props.assetUuid, values)
                    .then(() => resetForm())
                    .catch((err) => snackbar.error(errorParser(err)))
                    .finally(setSubmitting(false));
            }}
        >
            {({ submitForm, isSubmitting, setFieldValue }) => (
                <Form noValidate style={{ width: '100%' }}>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                    >
                        <Grid item xs={12}>
                            <Field
                                component={TextField}
                                name="amountToBurn"
                                label="Amount To Burn"
                                type="number"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={submitForm}
                                disabled={isSubmitting}
                            >
                                Burn Assets
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default Burn;
