import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { DateFormatter } from '../../../utilities/formatters';
import request from '../../../utilities/request';
import * as config from './../../../config';

const ShareholderNoticeDetails = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const { open, id, onClose } = props;

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            const response = await request.get(
                `${config.API_BASE_URL}/notice/${id}`
            );
            setData({ ...response.data.data });
            setIsLoading(false);
        }
        if (id) {
            fetchData();
        }
    }, [id]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Holder Notice Details</DialogTitle>
            {!isLoading ? (
                <DialogContent dividers>
                    <Typography variant="h5" component="h3">
                        {data.title}
                    </Typography>
                    <em>
                        <DateFormatter
                            value={data.published_on}
                            format="date"
                        />
                    </em>
                    <p>Here are the details for {data.title}</p>
                </DialogContent>
            ) : (
                <LinearProgress />
            )}
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ShareholderNoticeDetails;
