import request from '../../utilities/request';
import * as config from '../../config';
import * as eventTypes from './eventTypes';

export const getRecentEventsSuccess = (summary) => {
    return {
        type: eventTypes.GET_RECENT_EVENTS_SUCCESS,
        payload: summary,
    };
};

export const setRecentEventsLoading = (loading) => {
    return {
        type: eventTypes.SET_RECENT_EVENTS_LOADING,
        payload: loading,
    };
};

export const getRecentEvents = () => {
    return (dispatch) => {
        dispatch(setRecentEventsLoading(true));
        request
            .get(`${config.API_BASE_URL}/events`)
            .then((res) => {
                dispatch(getRecentEventsSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setRecentEventsLoading(false));
            });
    };
};
