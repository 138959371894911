import React from 'react';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';

const StyledFormControl = styled(FormControl)({
    marginTop: '16px',
    marginBottom: '8px',
});

const FormikSelect = (props) => {
    const { fullWidth } = props;

    return (
        <StyledFormControl fullWidth={fullWidth}>
            <InputLabel>{props.label}</InputLabel>
            <Select {...props} />
        </StyledFormControl>
    );
};

export default FormikSelect;
