import React, { useState } from 'react';
import * as config from './../../../config';
import request from '../../../utilities/request';
import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';

import { Formik, Form, Field } from 'formik';
import TextField from '../../../components/TextField/TextField';
import FormikSelect from '../../../components/Formik/FormikSelect';
import RadioButtons from '../../../components/RadioButtons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as capTableActions from '../../../store/capTable/capTableActions';
import authStyles from '../authStyles';
import { RESTRICTED, UNRESTRICTED, CUSTOM } from '../../../constants/equities';
const EditCategoryDialog = (props) => {
    const { categoryData, dialogIsOpen, setDialogIsOpen } = props;
    const classes = authStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [categoryClass, setCategoryClass] = useState(
        props.classes &&
            props.classes.length > 0 &&
            props.classes[0].class_description
    );
    const closeDialog = () => {
        setDialogIsOpen(false);
    };

    return (
        <Dialog
            maxWidth="sm"
            fullWidth
            open={dialogIsOpen}
            onBackdropClick={(e) => setDialogIsOpen(false)}
        >
            <DialogTitle className={classes.dialogTitle}>
                Edit Category
                <IconButton
                    className={classes.closeButton}
                    onClick={closeDialog}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
                <br />
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <Formik
                            initialValues={categoryData}
                            //validationSchema={categoryValidationSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                let data = {
                                    class: categoryClass, //values['category_class_name'],
                                    description: values['category_description'],
                                    price: values['category_price']
                                        ? values['category_price']
                                        : null,
                                    category_id: categoryData.category_id,
                                    transfer_restrictions:
                                        values['transfer_restrictions'],
                                    lock_period: values['lock_period']
                                        ? values['lock_period']
                                        : null,
                                    current_price: values['current_price']
                                        ? values['current_price']
                                        : null,
                                };
                                request
                                    .patch(
                                        `${config.API_BASE_URL}/share-class-categories/edit`,
                                        data
                                    )
                                    .then((response) => {
                                        enqueueSnackbar(
                                            'Asset Class Category has been updated successfully',
                                            {
                                                variant: 'success',
                                            }
                                        );
                                        setDialogIsOpen(false);
                                        props.capTableActions.getClasses();
                                        props.capTableActions.getClassCategories();
                                        props.setClassTableKey(
                                            props.classTableKey + 1
                                        );
                                    })
                                    .catch(() => {
                                        enqueueSnackbar(
                                            'Error occured while updating asset class category',
                                            {
                                                variant: 'error',
                                            }
                                        );
                                        setSubmitting(false);
                                    });
                            }}
                        >
                            {({ values, isSubmitting }) => (
                                <Form className={classes.form} noValidate>
                                    <Grid>
                                        <Field
                                            component={TextField}
                                            type="text"
                                            label="Category Description"
                                            name={'category_description'}
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            placeholder={'Apple Founder Asset'}
                                        />

                                        {props.classes &&
                                        props.classes.length > 0 ? (
                                            <Grid>
                                                <Field
                                                    component={FormikSelect}
                                                    name="category_class_name"
                                                    variant="outlined"
                                                    inputProps={{
                                                        id:
                                                            'category_class_name',
                                                    }}
                                                    label="Class *"
                                                    fullWidth
                                                    defaultValue={
                                                        props.classes[0]
                                                            .class_description
                                                    }
                                                    onChange={(e) => {
                                                        setCategoryClass(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    {props.classes.map(
                                                        (item) => (
                                                            <MenuItem
                                                                key={
                                                                    item.class_description
                                                                }
                                                                value={
                                                                    item.class_description
                                                                }
                                                            >
                                                                {
                                                                    item.class_description
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Field>
                                            </Grid>
                                        ) : (
                                            ''
                                        )}
                                        <Field
                                            component={TextField}
                                            type="text"
                                            label="Number of assets in category"
                                            name={'category_amount'}
                                            variant="outlined"
                                            displayOnly
                                            margin="normal"
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                        <RadioButtons
                                            label="Transfer restrictions"
                                            name="transfer_restrictions"
                                            options={[
                                                {
                                                    label: 'Unrestricted',
                                                    value: UNRESTRICTED,
                                                },
                                                {
                                                    label: 'Restricted',
                                                    value: RESTRICTED,
                                                },
                                                {
                                                    label: 'Custom',
                                                    value: CUSTOM,
                                                },
                                            ]}
                                        />
                                        {values.transfer_restrictions ===
                                            CUSTOM && (
                                            <Field
                                                component={TextField}
                                                type="number"
                                                name="lock_period"
                                                variant="outlined"
                                                margin="normal"
                                                label={
                                                    'Lock period (for transfers)'
                                                }
                                                placeholder={'90'}
                                                fullWidth
                                            />
                                        )}
                                        <Field
                                            component={TextField}
                                            type="text"
                                            numeric={1}
                                            name="category_price"
                                            variant="outlined"
                                            margin="normal"
                                            label={'Price per asset'}
                                            placeholder={'0.00'}
                                            //numeric={1}
                                            fullWidth
                                        />
                                        <Field
                                            component={TextField}
                                            name="current_price"
                                            type="text"
                                            numeric={1}
                                            label="Current price"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                        />
                                    </Grid>
                                    <DialogActions className={classes.actions}>
                                        <Button
                                            color="secondary"
                                            variant="outlined"
                                            className={
                                                classes.secondaryButtonLabel
                                            }
                                            onClick={() => {
                                                closeDialog();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="outlined"
                                            className={classes.buttonLabel}
                                            color="primary"
                                            disabled={isSubmitting}
                                        >
                                            Save
                                        </Button>
                                    </DialogActions>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
const mapStateToProps = ({ profile, capTable, category_loading }) => {
    return {
        profile: profile,
        categories: capTable.categories.data,
        classes: capTable.classes.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        capTableActions: bindActionCreators(capTableActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCategoryDialog);
