import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Formik } from 'formik';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import * as config from './../../../config';
import request from '../../../utilities/request';
import { useSnackbar } from 'notistack';
import * as transferAgentActions from '../../../store/transferAgent/transferAgentActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import authStyles from '../../issuer/authStyles';
import transferStyles from '../../../components/styles/transferStyles';
import InputLabel from '@mui/material/InputLabel';
import { DateFormatter } from '../../../utilities/formatters';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '../../../components/TextField/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DataTable from '../../../components/DataTable/MuiDataTable';
import Humanize from 'humanize-plus';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const Reports = (props) => {
    const classes = authStyles();
    const transferClasses = transferStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { issuers, transferAgentActions } = props;
    const [isGeneratingReport, setIsGeneratingReport] = useState(false);
    const [transactionHistoryData, setTransactionHistoryData] = useState(null);
    const [shareholderListData, setShareholderListData] = useState(null);

    const [issuer, setIssuer] = useState(
        issuers && issuers.data ? issuers.data[0] : null
    );
    const [allOption] = useState([{ name: 'ALL', id: 'all' }]);
    useEffect(transferAgentActions.getIssuers, []);

    const [selectedFromDate, setSelectedFromDate] = useState(
        DateFormatter({
            value: new Date(),
            format: 'date_raw',
        })
    );
    const [selectedToDate, setSelectedToDate] = useState(
        DateFormatter({
            value: new Date(),
            format: 'date_raw',
        })
    );

    let report_types = [
        {
            reason: 'transfer_turnaround',
            description: 'Transfer Turnaround',
        },
        {
            reason: 'correspondance_log',
            description: 'Correspondence Log',
        },
        {
            reason: 'correspondance_turnaround',
            description: 'Correspondence Turnaround',
        },
        {
            reason: 'transaction_history',
            description: 'Transaction History',
        },
        {
            reason: 'shareholder_list',
            description: 'Holder List',
        },
        {
            reason: 'shareholder_list_summary',
            description: 'Holder List (summary)',
        },
        {
            reason: 'shareholder_statement',
            description: 'Holder Statement',
        },
    ];
    let time_periods = [
        {
            type: 'date_range',
            description: 'Specific time period',
        },
        {
            type: 'from_account_inception',
            description: 'From account inception',
        },
    ];

    const [reportType, setReportType] = useState(report_types[0].reason);
    const [timePeriod, setTimePeriod] = useState(time_periods[0].type);

    const handleFromDateChange = (date) => {
        setSelectedFromDate(
            DateFormatter({
                value: date.target.value,
                format: 'date_raw',
            })
        );
    };

    const handleToDateChange = (date) => {
        setSelectedToDate(
            DateFormatter({
                value: date,
                format: 'date_raw',
            })
        );
    };

    const handleIssuerChange = (value, type) => {
        if (issuers && issuers.data) {
            let selectedIssuer = allOption
                .concat(issuers.data)
                .filter((element) => {
                    return element.name === value;
                })[0];
            setIssuer(selectedIssuer);
        }
    };

    const generateReport = () => {
        setIsGeneratingReport(true);

        if (
            reportType === null ||
            reportType === undefined ||
            issuer === null ||
            issuer === undefined ||
            (timePeriod === 'date_range' &&
                (selectedFromDate === null ||
                    selectedFromDate === undefined ||
                    selectedToDate === null ||
                    selectedToDate === undefined))
        ) {
            enqueueSnackbar(
                'Something went wrong. Please enter all information',
                {
                    variant: 'error',
                }
            );
            setIsGeneratingReport(false);
        } else {
            if (reportType === 'transaction_history') {
                request
                    .get(
                        `${config.API_BASE_URL}/transfer-agent/issuer/transaction-history`,
                        {
                            params: {
                                template_type: reportType,
                                issuer_id: issuer.id,
                                from_date: selectedFromDate
                                    ? selectedFromDate
                                    : null,
                                to_date: selectedToDate ? selectedToDate : null,
                                time_period: timePeriod,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.data) {
                            setTransactionHistoryData(res.data);
                        }
                        setIsGeneratingReport(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setIsGeneratingReport(false);
                    });
            } else if (
                reportType === 'shareholder_list' ||
                reportType === 'shareholder_list_summary'
            ) {
                request
                    .get(
                        `${config.API_BASE_URL}/transfer-agent/issuer/shareholder-list`,
                        {
                            params: {
                                template_type: reportType,
                                issuer_id: issuer.id,
                                from_date: selectedFromDate
                                    ? selectedFromDate
                                    : null,
                                to_date: selectedToDate ? selectedToDate : null,
                                time_period: timePeriod,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.data) {
                            setShareholderListData(res.data);
                        }
                        setIsGeneratingReport(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setIsGeneratingReport(false);
                    });
            } else {
                request
                    .post(`${config.API_BASE_URL}/transfer-agents/reports`, {
                        template_type: reportType,
                        issuer_id: issuer.id,
                        from_date: selectedFromDate ? selectedFromDate : null,
                        to_date: selectedToDate ? selectedToDate : null,
                        time_period: timePeriod,
                    })
                    .then((response) => {
                        /*var link = document.createElement('a');
                        link.href = '#';
                        link.download = reportType + '.pdf';
                        link.dispatchEvent(new MouseEvent('click'));*/
                        window.open(response.data.report_url, '_blank').focus();
                        setIsGeneratingReport(false);
                    })
                    .catch((error) => {
                        setIsGeneratingReport(false);
                    });
            }
        }
    };

    return (
        <Formik initialValues={{ from_date: selectedFromDate }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} sm={12}>
                                <Typography className={classes.title}>
                                    Reports
                                </Typography>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <InputLabel className={classes.label}>
                                            Issuer
                                        </InputLabel>
                                        <Autocomplete
                                            id="free-solo-demo"
                                            value={
                                                issuer !== null &&
                                                issuer !== undefined
                                                    ? issuer.name
                                                        ? issuer.name
                                                        : ''
                                                    : 'Select Issuer'
                                            }
                                            onInputChange={(
                                                event,
                                                newInputValue
                                            ) => {
                                                handleIssuerChange(
                                                    newInputValue
                                                );
                                            }}
                                            options={
                                                issuers.data
                                                    ? allOption
                                                          .concat(issuers.data)
                                                          .map(
                                                              (issuer, id) =>
                                                                  issuer.name
                                                          )
                                                    : []
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    type="text"
                                                    label=""
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <InputLabel className={classes.label}>
                                            Type
                                        </InputLabel>
                                        <FormControl
                                            variant="outlined"
                                            className={classes.formControl}
                                            fullWidth
                                            style={{ marginTop: '20px' }}
                                        >
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={reportType}
                                                fullWidth
                                                onChange={(e) =>
                                                    setReportType(
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                {report_types.map(
                                                    (report_type, i) => (
                                                        <MenuItem
                                                            value={
                                                                report_type.reason
                                                            }
                                                        >
                                                            {
                                                                report_type.description
                                                            }
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <InputLabel className={classes.label}>
                                            Time period:
                                        </InputLabel>
                                        <br />
                                        <FormControl
                                            variant="outlined"
                                            className={classes.formControl}
                                            fullWidth
                                        >
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={timePeriod}
                                                fullWidth
                                                onChange={(e) =>
                                                    setTimePeriod(
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                {time_periods.map(
                                                    (time_period, i) => (
                                                        <MenuItem
                                                            value={
                                                                time_period.type
                                                            }
                                                        >
                                                            {
                                                                time_period.description
                                                            }
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                        <br />
                                        <br />
                                        {timePeriod === 'date_range' && (
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        name="from_date"
                                                        label="From"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                            required: true,
                                                        }}
                                                        type="date"
                                                        defaultValue={
                                                            selectedFromDate
                                                        }
                                                        fullWidth
                                                        onChange={
                                                            handleFromDateChange
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        name="to_date"
                                                        label="To"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                            required: true,
                                                        }}
                                                        type="date"
                                                        defaultValue={
                                                            selectedToDate
                                                        }
                                                        fullWidth
                                                        onChange={
                                                            handleToDateChange
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}

                                        {/*    <Grid>
                                            {timePeriod === 'date_range' && (
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                >
                                                    <Grid container spacing={2}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            md={6}
                                                        >
                                                            <DatePicker
                                                                variant="inline"
                                                                inputVariant="outlined"
                                                                format="MM/dd/yyyy"
                                                                margin="normal"
                                                                label="From: "
                                                                fullWidth
                                                                required
                                                                value={
                                                                    selectedFromDate
                                                                }
                                                                renderInput={(
                                                                    props
                                                                ) => (
                                                                    <TextField
                                                                        fullWidth
                                                                        {...props}
                                                                    />
                                                                )}
                                                                onChange={
                                                                    handleFromDateChange
                                                                }
                                                                KeyboardButtonProps={{
                                                                    'aria-label':
                                                                        'change date',
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            md={6}
                                                        >
                                                            <DatePicker
                                                                variant="inline"
                                                                inputVariant="outlined"
                                                                format="MM/dd/yyyy"
                                                                margin="normal"
                                                                id="date-picker-inline"
                                                                label="To: "
                                                                fullWidth
                                                                required
                                                                value={
                                                                    selectedToDate
                                                                }
                                                                renderInput={(
                                                                    props
                                                                ) => (
                                                                    <TextField
                                                                        fullWidth
                                                                        {...props}
                                                                    />
                                                                )}
                                                                onChange={
                                                                    handleToDateChange
                                                                }
                                                                KeyboardButtonProps={{
                                                                    'aria-label':
                                                                        'change date',
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </LocalizationProvider>
                                            )}
                                        </Grid>*/}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        className={
                                            transferClasses.buttonAlignment
                                        }
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            onClick={generateReport}
                                            disabled={isGeneratingReport}
                                        >
                                            {isGeneratingReport ? (
                                                <CircularProgress size={24} />
                                            ) : (
                                                'Generate Report'
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <br />
                        <br />
                        <br />
                        <Grid>
                            {reportType === 'transaction_history' &&
                                transactionHistoryData && (
                                    <DataTable
                                        title={'Transaction History'}
                                        data={
                                            transactionHistoryData
                                                ? transactionHistoryData
                                                : []
                                        }
                                        columns={[
                                            {
                                                label: 'Ticker',
                                                name: 'ticker',
                                                /*options: {
                                                customBodyRender: (value) => (
                                                    <Typography variant="overline">
                                                        {value.ticker}
                                                    </Typography>
                                                ),
                                            },*/
                                            },

                                            {
                                                label: 'From',
                                                name: 'sender_name',
                                                /*options: {
                                                customBodyRender: (value) => (
                                                    <span>
                                                        {value &&
                                                        value.first_name
                                                            ? value.first_name
                                                            : '-'}
                                                    </span>
                                                ),
                                            },*/
                                            },
                                            {
                                                label: 'Sender Wallet',
                                                name: 'sender',

                                                options: {
                                                    download: false,
                                                    print: false,
                                                    customBodyRender: (
                                                        value
                                                    ) => (
                                                        <div>
                                                            <Tooltip
                                                                title={
                                                                    value &&
                                                                    value.wallet
                                                                        ? value
                                                                              .wallet
                                                                              .public_address
                                                                        : 'Not available'
                                                                }
                                                            >
                                                                <IconButton size="small">
                                                                    <AccountBalanceWalletIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    ),
                                                },
                                            },
                                            {
                                                label: 'To',
                                                name: 'receiver_name',
                                                /*options: {
                                                customBodyRender: (value) => (
                                                    <span>
                                                        {value &&
                                                        value.first_name
                                                            ? value.first_name
                                                            : '-'}
                                                    </span>
                                                ),
                                            },*/
                                            },
                                            {
                                                label: 'Recipient wallet',
                                                name: 'receiver',
                                                options: {
                                                    download: false,
                                                    print: false,
                                                    customBodyRender: (
                                                        value
                                                    ) => (
                                                        <div>
                                                            <Tooltip
                                                                title={
                                                                    value &&
                                                                    value.wallet
                                                                        ? value
                                                                              .wallet
                                                                              .public_address
                                                                        : 'Not available'
                                                                }
                                                            >
                                                                <IconButton size="small">
                                                                    <AccountBalanceWalletIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    ),
                                                },
                                            },
                                            {
                                                label: 'Amount',
                                                name: 'amount',
                                                options: {
                                                    customBodyRender: (
                                                        value
                                                    ) => (
                                                        <span
                                                            style={{
                                                                color:
                                                                    '#3E9CD9',
                                                            }}
                                                        >
                                                            <b>
                                                                {Humanize.formatNumber(
                                                                    value,
                                                                    2
                                                                )}
                                                            </b>
                                                        </span>
                                                    ),
                                                },
                                            },
                                            {
                                                label: 'Price per Asset',
                                                name: 'price_per_share',
                                                options: {
                                                    customBodyRender: (
                                                        value
                                                    ) => (
                                                        <span
                                                            style={{
                                                                color:
                                                                    '#3E9CD9',
                                                            }}
                                                        >
                                                            <b>
                                                                {Humanize.formatNumber(
                                                                    value,
                                                                    2
                                                                )}
                                                            </b>
                                                        </span>
                                                    ),
                                                },
                                            },
                                            {
                                                label: '',
                                                name: 'submitter',
                                                options: {
                                                    display: 'excluded',
                                                    download: false,
                                                    print: false,
                                                },
                                            },
                                            {
                                                label: '',
                                                name: 'reviewer',
                                                options: {
                                                    display: 'excluded',
                                                    download: false,
                                                    print: false,
                                                },
                                            },
                                            {
                                                label: '',
                                                name: 'updated_at',
                                                options: {
                                                    display: 'excluded',
                                                    download: false,
                                                    print: false,
                                                    customBodyRender: (
                                                        value
                                                    ) => (
                                                        <span>
                                                            {DateFormatter({
                                                                value: value,
                                                                format: 'date',
                                                            })}
                                                        </span>
                                                    ),
                                                },
                                            },
                                            {
                                                label: '',
                                                name: 'transaction',
                                                options: {
                                                    display: 'excluded',
                                                    download: false,
                                                    print: false,
                                                },
                                            },
                                            {
                                                label: 'Status',
                                                name: 'status',
                                                options: {
                                                    customBodyRender: (
                                                        value
                                                    ) => (
                                                        <div
                                                            className={
                                                                value ===
                                                                'pending'
                                                                    ? transferClasses.grey
                                                                    : value ===
                                                                      'approved'
                                                                    ? transferClasses.green
                                                                    : transferClasses.red
                                                            }
                                                        >
                                                            {value
                                                                ? value.toUpperCase()
                                                                : ''}
                                                        </div>
                                                    ),
                                                },
                                            },
                                            {
                                                label: 'Type',
                                                name: 'type',
                                                options: {
                                                    customBodyRender: (
                                                        value
                                                    ) => (
                                                        <div>
                                                            {value
                                                                ? value.toUpperCase()
                                                                : ''}
                                                        </div>
                                                    ),
                                                },
                                            },
                                            {
                                                label: '',
                                                name: 'status',
                                                options: {
                                                    display: 'excluded',
                                                    download: false,
                                                    print: false,
                                                },
                                            },
                                            {
                                                label: '',
                                                name: 'documents',
                                                options: {
                                                    display: 'excluded',
                                                    download: false,
                                                    print: false,
                                                },
                                            },
                                            {
                                                label: 'Sender Wallet',
                                                name: 'sender_wallet',
                                                options: {
                                                    display: 'excluded',
                                                    download: true,
                                                    print: true,
                                                },
                                            },
                                            {
                                                label: 'Recipient Wallet',
                                                name: 'receiver_wallet',
                                                options: {
                                                    display: 'excluded',
                                                    download: true,
                                                    print: true,
                                                },
                                            },
                                            {
                                                label: 'Issuer',
                                                name: 'submitter',
                                                options: {
                                                    display:
                                                        issuer.id !== 'all'
                                                            ? 'excluded'
                                                            : true,
                                                    download:
                                                        issuer.id !== 'all'
                                                            ? 'excluded'
                                                            : true,
                                                    print:
                                                        issuer.id !== 'all'
                                                            ? 'excluded'
                                                            : true,
                                                    customBodyRender: (
                                                        value
                                                    ) => (
                                                        <div>
                                                            {value
                                                                ? value.company_name
                                                                : ''}
                                                        </div>
                                                    ),
                                                },
                                            },
                                        ]}
                                        expandable={{
                                            expandableRows: true,
                                            expandableRowsHeader: false,
                                            expandableRowsOnClick: true,
                                            renderExpandableRow: (
                                                rowData,
                                                rowMeta
                                            ) => {
                                                const colSpan =
                                                    rowData.length + 1;

                                                return (
                                                    <TableRow>
                                                        <TableCell
                                                            colSpan={colSpan}
                                                            className={
                                                                transferClasses.historyDetailsTable
                                                            }
                                                        >
                                                            <Grid container>
                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                    md={2}
                                                                    sm={2}
                                                                    className={
                                                                        transferClasses.greyTitle
                                                                    }
                                                                >
                                                                    Submitted
                                                                    by:
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    md={6}
                                                                    sm={6}
                                                                    className={
                                                                        transferClasses.blackTitle
                                                                    }
                                                                >
                                                                    {rowData[7]
                                                                        .company_name
                                                                        ? rowData[7]
                                                                              .company_name
                                                                        : ''}
                                                                </Grid>
                                                            </Grid>

                                                            <Grid container>
                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                    md={2}
                                                                    sm={2}
                                                                    className={
                                                                        transferClasses.greyTitle
                                                                    }
                                                                >
                                                                    {rowData[13] ===
                                                                    'approved'
                                                                        ? 'Approved by:'
                                                                        : rowData[13] ===
                                                                          'rejected'
                                                                        ? 'Rejected by:'
                                                                        : ''}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    md={6}
                                                                    sm={6}
                                                                    className={
                                                                        transferClasses.blackTitle
                                                                    }
                                                                >
                                                                    {rowData[13] !==
                                                                    'pending'
                                                                        ? rowData[8] &&
                                                                          rowData[8]
                                                                              .company_name
                                                                            ? rowData[8]
                                                                                  .company_name
                                                                            : ''
                                                                        : ''}
                                                                </Grid>
                                                            </Grid>

                                                            <Grid container>
                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                    md={2}
                                                                    sm={2}
                                                                    className={
                                                                        transferClasses.greyTitle
                                                                    }
                                                                >
                                                                    {rowData[13] ===
                                                                    'approved'
                                                                        ? 'Approved at:'
                                                                        : rowData[13] ===
                                                                          'rejected'
                                                                        ? 'Rejected at:'
                                                                        : ''}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    md={6}
                                                                    sm={6}
                                                                    className={
                                                                        transferClasses.blackTitle
                                                                    }
                                                                >
                                                                    {rowData[13] !==
                                                                        'pending' &&
                                                                    rowData[9]
                                                                        ? rowData[9]
                                                                        : ''}
                                                                </Grid>
                                                            </Grid>

                                                            {rowData[10] && (
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={2}
                                                                        md={2}
                                                                        sm={2}
                                                                        className={
                                                                            transferClasses.greyTitle
                                                                        }
                                                                    >
                                                                        Transaction
                                                                        Hash:
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        md={6}
                                                                        sm={6}
                                                                        className={
                                                                            transferClasses.blueContent
                                                                        }
                                                                    >
                                                                        {rowData[10] &&
                                                                        rowData[10]
                                                                            .tx_id
                                                                            ? rowData[9]
                                                                                  .tx_id
                                                                            : ''}
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                            <Grid container>
                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                    md={2}
                                                                    sm={2}
                                                                    className={
                                                                        transferClasses.greyTitle
                                                                    }
                                                                >
                                                                    Documents:
                                                                </Grid>
                                                            </Grid>
                                                            {rowData[14].map(
                                                                (
                                                                    document,
                                                                    i
                                                                ) => (
                                                                    <div>
                                                                        <a
                                                                            href={
                                                                                document.document
                                                                            }
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            {
                                                                                document.document
                                                                            }
                                                                        </a>
                                                                    </div>
                                                                )
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            },
                                        }}
                                    />
                                )}
                        </Grid>

                        <Grid>
                            {reportType === 'shareholder_list' &&
                                shareholderListData && (
                                    <DataTable
                                        title={'Holder List'}
                                        data={
                                            shareholderListData
                                                ? shareholderListData
                                                : []
                                        }
                                        columns={[
                                            {
                                                label: 'First Name',
                                                name: 'first_name',
                                            },
                                            {
                                                label: 'Last Name',
                                                name: 'last_name',
                                            },
                                            {
                                                label: 'Company',
                                                name: 'company_name',
                                            },
                                            {
                                                label: 'Email',
                                                name: 'email',
                                            },
                                            {
                                                label: 'Phone',
                                                name: 'phone',
                                            },
                                            {
                                                label: 'State',
                                                name: 'state',
                                            },
                                            {
                                                label: 'Country',
                                                name: 'country',
                                            },
                                            {
                                                label: 'City',
                                                name: 'city',
                                            },
                                            {
                                                label: 'Address',
                                                name: 'address',
                                            },
                                            {
                                                label: 'Assets',
                                                name: 'shares',
                                            },
                                            {
                                                label: 'Issuer',
                                                name: 'issuer',
                                                options: {
                                                    display:
                                                        issuer.id !== 'all'
                                                            ? 'excluded'
                                                            : true,
                                                    download:
                                                        issuer.id !== 'all'
                                                            ? 'excluded'
                                                            : true,
                                                    print:
                                                        issuer.id !== 'all'
                                                            ? 'excluded'
                                                            : true,
                                                },
                                            },
                                        ]}
                                    />
                                )}
                        </Grid>

                        <Grid>
                            {reportType === 'shareholder_list_summary' && (
                                <DataTable
                                    title={'Holder List (summary)'}
                                    data={
                                        shareholderListData
                                            ? shareholderListData
                                            : []
                                    }
                                    columns={[
                                        {
                                            label: 'First Name',
                                            name: 'first_name',
                                        },
                                        {
                                            label: 'Last Name',
                                            name: 'last_name',
                                        },
                                        {
                                            label: 'Address',
                                            name: 'address',
                                        },
                                        {
                                            label: 'Assets',
                                            name: 'shares',
                                        },
                                        {
                                            label: 'Issuer',
                                            name: 'issuer',
                                            options: {
                                                display:
                                                    issuer.id !== 'all'
                                                        ? 'excluded'
                                                        : true,
                                                download:
                                                    issuer.id !== 'all'
                                                        ? 'excluded'
                                                        : true,
                                                print:
                                                    issuer.id !== 'all'
                                                        ? 'excluded'
                                                        : true,
                                            },
                                        },
                                    ]}
                                />
                            )}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Formik>
    );
};

const mapStateToProps = ({ profile, transferAgent }) => {
    return {
        profile: profile,
        issuers: transferAgent.issuers,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        transferAgentActions: bindActionCreators(
            transferAgentActions,
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
