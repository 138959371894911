import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, Grid, Typography } from '@mui/material'

import issueAsset from '../../../../api/blockchain/issueAsset';
import useElementWallets from '../../../../api/blockchain/useElementWallets';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { snackbar } from '../../../../utilities/snackbarUtils'
import { errorParser } from '../../../../components/LiquidForm/utils/errorParser';

import Input from '../../../../components/Form/Input';
import Checkbox from '../../../../components/Form/Checkbox';
import ComboBox from '../../../../components/Form/ComboBox';
import SubmitButton from '../../../../components/Form/SubmitButton';
import { domainRegMatch } from '../../../../constants/blockchain';
import HelpTooltip from '../../../../components/HelpTooltip/HelpTooltip';

const IssueDialog = ({ open, handleClose, updateAssets }) => {

    const { wallets, walletsLoading } = useElementWallets()

    const schema = yup.object({
        name: yup.string().required('Asset name is required.').min(5).max(255),
        ticker: yup.string().required('Asset ticker is required.').min(3).max(5),
        amount: yup.number().moreThan(0, 'Amount has to be greater than 0').required('Asset amount is required.'),
        precision: yup.number().moreThan(-1, 'Precision has to be greater than 0').required('Asset precision is required.').min(0).max(8),
        domain: yup.string().required('Domain name is required').matches(domainRegMatch, 'Domain should be a valid URl'),
        wallet: yup.string().required('You have to select a wallet.'),
        is_confidential: yup.boolean(),
        is_reissuable: yup.boolean(),
    });

    const defaultValues = {
        name: '',
        ticker: '',
        amount: 0,
        precision: 8,
        domain: '',
        wallet: '',
        is_confidential: false,
        is_reissuable: true,
    }

    const { handleSubmit, control, reset, formState: { isSubmitting, errors }, } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    const onSubmit = async (data) => {
        // console.log(data)
        await issueAsset(data).then(response => {
            snackbar.success('Succesfully issued the asset.');
            reset(defaultValues);
            handleClose()
            updateAssets()
        }).catch((error) => {
            snackbar.error(errorParser(error));
        })
    };

    return (
        <Dialog open={open} onClose={() => { handleClose(); reset(defaultValues); }}>
            <DialogTitle>Issue an asset</DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <div style={{ display: 'flex', color: 'gray' }}>
                        <Typography variant='body2'>
                            Create a new digital asset
                        </Typography>
                    </div>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Input
                                autoFocus
                                name="name"
                                label="Name"
                                type="text"
                                fullWidth
                                error={errors?.name}
                                control={control}
                                variant="outlined"
                                placeholder="Asset name"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                name="ticker"
                                label="Ticker"
                                type="text"
                                fullWidth
                                error={errors?.ticker}
                                control={control}
                                variant="outlined"
                                placeholder="Asset ticker (3-5 characters)"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                name="amount"
                                label="Number of securities"
                                fullWidth
                                numeric
                                error={errors?.amount}
                                control={control}
                                variant="outlined"
                                placeholder="0"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                name="domain"
                                label="Domain"
                                type="text"
                                fullWidth
                                error={errors?.domain}
                                control={control}
                                variant="outlined"
                                placeholder="digtl.co"
                            />
                            <div style={{ display: 'flex', color: 'gray', alignItems: 'center', marginTop: '0.2em' }}>
                                <Typography variant='caption'>
                                    The domain name is used to later <b>register</b> and verify
                                    your asset.
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Input
                                    name="precision"
                                    label="Precision"
                                    type="number"
                                    fullWidth
                                    error={errors?.precision}
                                    control={control}
                                    variant="outlined"
                                    placeholder="0"
                                />
                                <HelpTooltip title={
                                    <><b>Note:</b> The Liquid blockchain explorer will not display the asset precision until you <b>register the asset</b>, thereby verifing your ownership.
                                    </>
                                } />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <ComboBox
                                options={wallets}
                                optionLabel={'wallet'}
                                valueLabel={'wallet'}
                                disabled={walletsLoading}
                                loading={walletsLoading}
                                label="Issuing wallet"
                                name="wallet"
                                error={errors.wallet}
                                control={control}
                                defaultValue={wallets[0]}
                            />
                            <div style={{ display: 'flex', color: 'gray', alignItems: 'center' }}>
                                <Alert variant='outlined' severity="info" sx={{ marginTop: '10px' }}>
                                    The asset will be issued to the wallet you choose.
                                    Issuance is free, but you will need to <b>fund your wallet</b> for
                                    transfer, reissuance and burn operations.
                                </Alert>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <FormGroup>
                                <Checkbox
                                    name="is_confidential"
                                    defaultChecked={false}
                                    label="Hide total issuance?"
                                    control={control}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Checkbox
                                    name="is_reissuable"
                                    defaultChecked={true}
                                    label="Is reissuable?"
                                    control={control}
                                />
                            </FormGroup>
                            {/* <FormGroup>
                                <Checkbox
                                    name="mirror_cap_table"
                                    defaultChecked={false}
                                    label="Mirror the cap table?"
                                    disabled={true}
                                    control={control}
                                />
                                <div style={{ display: 'flex', color: 'gray', alignItems: 'center' }}>
                                    <Typography variant='caption'>
                                        The asset will immediately be transfered
                                        to holders upon issuance, based
                                        on your cap table.
                                    </Typography>
                                </div>
                            </FormGroup> */}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <SubmitButton
                        text='Issue'
                        disabled={isSubmitting}
                        loading={isSubmitting}
                    />
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default IssueDialog