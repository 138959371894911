import React, { useState } from 'react';

import Container from '@mui/material/Container';

import PersonalInformation from './PersonalInformation';
import Password from './Password';
import SubscriptionType from './SubscriptionType';
import CompanyInformation from './CompanyInformation';
import EquityInformationClass from './EquityInformationClass';
import EquityInformationShare from './EquityInformationShare';
import EquityInformationCategory from './EquityInformationCategory';
import CapitalizationTable from './CapitalizationTable';
import CapitalizationTableOptions from './CapitalizationTableOptions';
import UploadDocuments from './UploadDocuments';
import Review from './Review';
import Complete from './Complete';
import InvestorAccount from './InvestorAccount';
import DotsStepper from './DotsStepper';
import Header from '../../../components/Header/Header';
import InviteTeamMembers from './InviteTeamMembers';

let initialValues = {
    account_type: 'issuer',
    password: '',
    confirm_password: '',
    human: '',
    subscription_type: '',
    company_name: '',
    legal_entity_name: '',
    registered_country: '',
    raised_capital: false,
    intend_to_raise: false,
    number_of_shareholders: '',
    total_number_of_fully_diluted_shares: '',
    cap_table_input_type: '',
    //citizenship: '',
    input_type: '',
    number_of_share_classes: 1,
    classes: [
        {
            class_name: 'Common',
            class_description: '',
            excluding_option: false,
            name: '',
            equity_main_currency: null,
            amount_invested: '',
            valuation: '',
            class_price: '',
            authorized_shares: '',
        },
    ],
    vesting_equities: 'no',
    option_plan: 'no',
    track_different_categories: 'no',
    categories: [],
    entity_type: 'individual',
    shareholders: [],
    excluding_option: false,
    investors: [],
    exercise_price: '',
    option_expiration_term: '',
    additional_comment: '',
    email: '',
    class_categories_number: [],
    first_name: '',
    last_name: '',
    captcha: '',
    authorized_shares: '0',
    currency: 'USD',
};

const formData = new FormData();
formData.append('csv_template', '');
formData.append('articles_of_incorporation', '');
formData.append('shareholders_agreement', '');
formData.append('amendments ', '');
formData.append('additional_documents', '');
formData.append('new_documents', '');

const Signup = () => {
    const [step, setStep] = useState(0);
    const [stepperKey, setStepperKey] = useState(0);

    const saveValues = (field_value) => {
        return function () {
            initialValues = Object.assign({}, initialValues, field_value);
            if (step === 0 || step === 1 || step === 3 || step === 11) {
                setStepperKey(stepperKey + 1);
            }
        }.bind()();
    };

    const nextStep = () => {
        setStep(step + 1);
    };

    const previousStep = () => {
        setStep(step - 1);
    };

    const skipNextPage = () => {
        setStep(step + 2);
    };

    const setCustomStep = (step) => {
        setStep(step);
    };

    const showStep = () => {
        switch (step) {
            case 0:
                return (
                    <PersonalInformation
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                    />
                );
            case 1:
                return (
                    <Password
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                    />
                );
            case 2:
                return (
                    <SubscriptionType
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                    />
                );
            case 3:
                return (
                    <CompanyInformation
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        setCustomStep={setCustomStep}
                        formData={formData}
                    />
                );
            case 4:
                return (
                    <EquityInformationClass
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                    />
                );
            case 5:
                return (
                    <EquityInformationShare
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        skipNextPage={skipNextPage}
                    />
                );
            case 6:
                return (
                    <EquityInformationCategory
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        skipNextPage={skipNextPage}
                    />
                );
            case 7:
                return (
                    <CapitalizationTable
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        skipNextPage={skipNextPage}
                        setCustomStep={setCustomStep}
                    />
                );
            case 8:
                return (
                    <CapitalizationTableOptions
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        skipNextPage={skipNextPage}
                    />
                );
            case 9:
                return (
                    <UploadDocuments
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        setCustomStep={setCustomStep}
                        saveValues={saveValues}
                        formData={formData}
                    />
                );
            case 10:
                return (
                    <Review
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        formData={formData}
                    />
                );
            case 11:
                return (
                    <InviteTeamMembers
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        formData={formData}
                        setCustomStep={setCustomStep}
                    />
                );
            case 12:
                return (
                    <Complete
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        formData={formData}
                        setCustomStep={setCustomStep}
                    />
                );
            case 13:
                return (
                    <InvestorAccount
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        formData={formData}
                        setCustomStep={setCustomStep}
                    />
                );
            default:
                return (
                    <PersonalInformation
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                    />
                );
        }
    };

    return (
        <>
            <Header type="investor" />
            <Container>
                <div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <DotsStepper
                            key={stepperKey}
                            step={step}
                            nextStep={nextStep}
                            previousStep={previousStep}
                            skipNextPage={skipNextPage}
                            initialValues={initialValues}
                            setCustomStep={setCustomStep}
                        />
                    </div>
                    {showStep()}
                </div>
            </Container>
        </>
    );
};

export default Signup;
