import axios from 'axios';
import { setAccessToken, setRefreshToken } from './auth';
import { snackbar } from './snackbarUtils';

import * as config from '../config';
import * as profileActions from '../store/profile/profileActions';

export const handleLogin = (email, password, history, dispatch) => {
    // If email and password are blank, don't try to log in.
    // Instead, just redirect to the login page.
    if (email && password) {
        axios
            .post(`${config.API_BASE_URL}/login`, {
                email: email,
                password: password,
            })
            .then((response) => {
                const { token, companyLogo } = response.data;
                setAccessToken(token);
                setRefreshToken(token);
                localStorage.setItem('user-email', email);
                localStorage.setItem('company-logo', companyLogo);
                dispatch(profileActions.getProfile());
                history.replace('/issuer/issue');
                snackbar.success('Welcome to the Issuer dashboard!');
            })
            .catch((error) => {
                snackbar.error(
                    'There has been an error logging you in. Please try again.'
                );
            });
        return;
    }

    window.location.href = '/login';
};
