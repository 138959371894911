import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

import Input from '../../../../components/Form/Input';
import SubmitButton from '../../../../components/Form/SubmitButton';
import { snackbar } from '../../../../utilities/snackbarUtils'

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import createWallet from '../../../../api/blockchain/createWallet';
import { errorParser } from '../../../../components/LiquidForm/utils/errorParser';


const CreateWalletDialog = (props) => {
    const schema = yup.object({
        name: yup.string().required('Wallet name is required.')
    });

    const defaultValues = {
        name: ''
    }

    const { handleSubmit, control, reset, formState: { isSubmitting, errors }, } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    const onSubmit = async (data) => {
        await createWallet(data).then(response => {
            snackbar.success('New wallet was successfully created.');
            reset(defaultValues);
            props.handleClose()
            props.updateWallets()
        }).catch((error) => {
            snackbar.error(errorParser(error));
        })
    };

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Create a wallet</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Create a new Element wallet to start your blockchain journey.
                        <Alert variant='outlined' severity="info" sx={{ marginTop: '10px' }}>
                            You can <b>issue</b> an asset without funding the wallet, but when you want to <b>distribute</b> assets
                            to your holders, your wallet should be <b>funded</b> with <b>Liquid Bitcoin (L-BTC).</b>
                        </Alert>
                    </DialogContentText>
                    <br />
                    <Input
                        autoFocus
                        name="name"
                        label="Wallet name..."
                        type="text"
                        fullWidth
                        error={errors?.name}
                        control={control}
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose}>Cancel</Button>
                    <SubmitButton 
                        text='Create'
                        disabled={isSubmitting}
                        loading={isSubmitting}
                    />
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default CreateWalletDialog