import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import Container from '@mui/material/Container';
import { useSnackbar } from 'notistack';
import authStyles from '../../authStyles';
import fundraiseOfferingStyles from '../../../../components/styles/fundraiseOfferingStyles';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '../../../../components/TextField/TextField';
import Grid from '@mui/material/Grid';
import { CheckboxWithLabel } from 'formik-mui';
import FundButtonGroup from '../FundButtonGroup';
const SecStatement = (props) => {
    const classes = authStyles();
    const offeringClasses = fundraiseOfferingStyles();
    const { enqueueSnackbar } = useSnackbar();
    let [refreshForm, setRefreshForm] = useState(0);
    const [accreditedInvestor, setAccreditedInvestor] = useState(
        props.initialValues.accredited_investor
            ? props.initialValues.accredited_investor === true
                ? 'yes'
                : 'no'
            : 'no'
    );

    const [accreditedInvestorType, setAccreditedInvestorType] = useState(
        props.initialValues.accredited_investor_type
            ? props.initialValues.accredited_investor_type
            : 'TYPE1'
    );

    const [backupWithholding, setBackupWithholding] = useState(
        props.initialValues.backup_withholding
            ? props.initialValues.backup_withholding === true
                ? 'yes'
                : 'no'
            : 'no'
    );

    const [isUsResident, setIsUsResident] = useState(
        props.initialValues.us_resident
            ? props.initialValues.us_resident === true
                ? 'yes'
                : 'no'
            : 'yes'
    );

    const [agreementAccepted, setAgreementAccepted] = useState(
        props.initialValues.customer_agreement_accepted
    );

    let confirmation_types = [
        {
            name:
                'Google me and you’ll find enough information about me to see that it’s reasonable to assume I’m an accredited investor.',
            value: 'google',
        },
        {
            name:
                'Look at our website and you’ll see it contains information that will show it’s reasonable to consider us accredited:',
            value: 'website',
        },
        {
            name:
                'I’m investing $250,000 or more into this offering and certify and attest that 1) none of these funds belong to or are being financed by any third party, 2) that I am not taking out a loan or using proceeds from a sale of my primary residence to make this investment, and 3) that I am not using the proceeds from and in conjunction with the sale of my primary residence to make any part of this investment. Investing $250,000 of my own unencumbered free cash or securities is not something that an unaccredited investor could reasonably do.',
            value: 'large_investment',
        },
        {
            name:
                'Call my accountant, he/she will tell you I’m accredited — Their contact info:',
            value: 'accountant',
        },
        {
            name:
                'Call my broker/registered investment adviser, he/she will tell you I’m accredited — Their contact info:',
            value: 'broker',
        },
        {
            name:
                'Call my lawyer, he/she will tell you I’m accredited — Their contact info',
            value: 'lawyer',
        },
        {
            name:
                'I’ll send you proof and email or fax my tax returns (most recent 2 years, only gross income page(s) necessary). Send them to info@ihq.com or fax to (917) 791-8350.',
            value: 'email_tax_returns',
        },
        {
            name:
                'I’ll send you proof and email or fax my bank statement, my securities statement, my IRA or 401K statement(s), my financial statement (prepared by a CPA), or other document(s) showing that my net worth exceeds $1 million (excluding my primary residence, but including any secondary or investment real estate). I will also send you a list of all my liabilities (other than those associated with my primary residence) and hereby certify and attest that I am disclosing all liabilities so you can make a reasonable calculation that my net worth exceeds the Rule 506(c) requirement. I further authorize FundAmerica Technologies and its third-party providers to obtain a credit report to confirm my liabilities as required by SEC rules. Send documents to accreditation@fundamerica.com or fax to (917) 791-8350.',
            value: 'email_bank_statement',
        },
    ];

    const nextStep = () => {
        props.nextStep();
    };

    const previousStep = () => {
        props.previousStep();
    };

    const closeDialog = () => {
        props.closeDialog();
    };

    const changeAccreditedInvestor = (e) => {
        setAccreditedInvestor(e.target.value);
    };

    const changeAccreditedInvestorType = (e) => {
        setAccreditedInvestorType(e.target.value);
    };

    const changeIsUsResident = (e) => {
        setIsUsResident(e.target.value);
    };

    const changeBackupWithholding = (e) => {
        setBackupWithholding(e.target.value);
    };

    return (
        <div id="step2">
            <Formik
                key={refreshForm}
                initialValues={props.initialValues}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    if (values['customer_agreement_accepted'] === false) {
                        enqueueSnackbar(
                            'Please accept customer agreement to continue',
                            {
                                variant: 'error',
                            }
                        );
                        refreshForm++;
                        setRefreshForm(refreshForm);
                    } else {
                        if (accreditedInvestor === 'yes') {
                            values['accredited_investor'] = true;
                        } else {
                            values['accredited_investor'] = false;
                        }

                        values[
                            'accredited_investor_type'
                        ] = accreditedInvestorType;

                        props.saveValues(values);
                        nextStep();
                        props.changeKey();
                    }
                }}
            >
                {({ setFieldValue }) => (
                    <Container maxWidth="lg">
                        <Form className={classes.form} noValidate>
                            <Typography component="div">
                                <i>
                                    This information is needed for us to comply
                                    with SEC and state securities regulations.
                                </i>
                            </Typography>
                            <br />
                            <Typography component="div">
                                <b>
                                    Are you an "accredited" investor (meaning do
                                    you earn over $200,000 per year, have a net
                                    worth of $1m or more, or are an
                                    institutional investor)?
                                </b>
                            </Typography>
                            <br />
                            <RadioGroup
                                name="accredited_investor"
                                row
                                required
                                defaultValue={accreditedInvestor}
                            >
                                <FormControlLabel
                                    value={'yes'}
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                    labelPlacement="end"
                                    onChange={changeAccreditedInvestor}
                                />
                                <FormControlLabel
                                    value={'no'}
                                    control={<Radio color="primary" />}
                                    label="No"
                                    labelPlacement="end"
                                    onChange={changeAccreditedInvestor}
                                />
                            </RadioGroup>
                            <hr className={offeringClasses.horizontalLine} />
                            <Typography component="div">
                                <b>
                                    Are you an "accredited" investor (meaning do
                                    you earn over $200,000 per year, have a net
                                    worth of $1m or more, or are an
                                    institutional investor)?
                                </b>
                            </Typography>
                            <br />
                            <RadioGroup
                                name="accredited_investor_type"
                                row
                                required
                                defaultValue={accreditedInvestorType}
                            >
                                <FormControlLabel
                                    value={'TYPE1'}
                                    control={<Radio color="primary" />}
                                    label="I have an individual net worth, or joint net worth with my spouse, that exceeds $1 million including any IRA's, 401K's and other retirement accounts, but excluding the net value of my primary residence."
                                    labelPlacement="end"
                                    onChange={changeAccreditedInvestorType}
                                />
                                <FormControlLabel
                                    value={'TYPE2'}
                                    control={<Radio color="primary" />}
                                    label="I am an individual with income of over $200,000 in each of the last two years, or joint income with my spouse exceeding $300,000 in those years, and I reasonably expect at least the same this year."
                                    labelPlacement="end"
                                    onChange={changeAccreditedInvestorType}
                                />
                            </RadioGroup>
                            <hr className={offeringClasses.horizontalLine} />
                            <Typography component="div">
                                <b>
                                    The SEC requires that holders in 506(c)
                                    offerings be “reasonably” confirmed as to
                                    their accredited status. SEC 17 CFR Parts
                                    230, 239 and 242 — Section 3. A, B & C
                                    (starting on page 26).
                                </b>
                            </Typography>
                            <br />
                            <Typography component="div">
                                <b>
                                    There are a number of ways to do this.
                                    Please help us get this done by letting us
                                    know whichever is easiest for you (check all
                                    that apply):
                                </b>
                            </Typography>
                            <br />
                            <Grid container spacing={1}>
                                {confirmation_types.map((confirmation_type) => (
                                    <Grid>
                                        <Field
                                            component={CheckboxWithLabel}
                                            type="checkbox"
                                            name={confirmation_type.value}
                                            Label={{
                                                label: (
                                                    <div
                                                        className={
                                                            classes.checkbox
                                                        }
                                                    >
                                                        {confirmation_type.name}
                                                    </div>
                                                ),
                                            }}
                                            required
                                        />
                                        <br /> <br />
                                    </Grid>
                                ))}
                            </Grid>
                            <br />
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Field
                                        component={TextField}
                                        name="sec_statement_note"
                                        type="text"
                                        label="Other notes and things to consider (optional):"
                                        rows={5}
                                        multiline
                                        fullWidth
                                        placeholder={'optional'}
                                    />
                                </Grid>
                            </Grid>
                            <hr className={offeringClasses.horizontalLine} />
                            <Typography component="div">
                                <b>
                                    Substitute Form W-9 Statement: Under penalty
                                    of perjury, by accepting the agreement below
                                    I certify that I have provided my correct
                                    taxpayer identification number, and
                                </b>
                            </Typography>
                            <br />
                            <RadioGroup
                                name="accredited_investor_type"
                                row
                                required
                                defaultValue={isUsResident}
                            >
                                <FormControlLabel
                                    value={'yes'}
                                    control={<Radio color="primary" />}
                                    label="I am a US citizen, US resident, or other US person."
                                    labelPlacement="end"
                                    onChange={changeIsUsResident}
                                />
                                <FormControlLabel
                                    value={'no'}
                                    control={<Radio color="primary" />}
                                    label="I am not a US citizen, US resident, or other US person."
                                    labelPlacement="end"
                                    onChange={changeIsUsResident}
                                />
                            </RadioGroup>

                            <Typography component="div">
                                <b>And</b>
                            </Typography>
                            <br />
                            <RadioGroup
                                name="backup_withholding"
                                row
                                required
                                defaultValue={backupWithholding}
                            >
                                <FormControlLabel
                                    value={'no'}
                                    control={<Radio color="primary" />}
                                    label=" I am exempt from backup withholding."
                                    labelPlacement="end"
                                    onChange={changeBackupWithholding}
                                />
                                <FormControlLabel
                                    value={'yes'}
                                    control={<Radio color="primary" />}
                                    label="I am subject to backup withholding."
                                    labelPlacement="end"
                                    onChange={changeBackupWithholding}
                                />
                            </RadioGroup>
                            <hr className={offeringClasses.horizontalLine} />
                            <Field
                                component={TextField}
                                name="script"
                                type="text"
                                rows={2}
                                label="Security Agreement"
                                multiline
                                fullWidth
                                defaultValue={'Securities Agreement'}
                                inputProps={{ readOnly: true }}
                            />
                            <br />
                            <br />
                            <Grid container spacing={1}>
                                <Field
                                    component={CheckboxWithLabel}
                                    type="checkbox"
                                    name="customer_agreement_accepted"
                                    Label={{
                                        label: (
                                            <div className={classes.checkbox}>
                                                Accept customer agreement
                                            </div>
                                        ),
                                    }}
                                    checked={agreementAccepted}
                                    onClick={() =>
                                        setAgreementAccepted(!agreementAccepted)
                                    }
                                    required
                                />
                            </Grid>

                            <FundButtonGroup
                                previousStep={previousStep}
                                closeDialog={closeDialog}
                            />
                        </Form>
                    </Container>
                )}
            </Formik>
        </div>
    );
};

export default SecStatement;
