import React from 'react';
import * as config from './../../../config';
import request from '../../../utilities/request';
import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';

import { Formik, Form, Field } from 'formik';
import TextField from '../../../components/TextField/TextField';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as capTableActions from '../../../store/capTable/capTableActions';
import authStyles from '../authStyles';
const DeleteCategoryDialog = (props) => {
    const { categoryData, dialogIsOpen, setDialogIsOpen } = props;
    const classes = authStyles();
    const { enqueueSnackbar } = useSnackbar();

    const closeDialog = () => {
        setDialogIsOpen(false);
    };

    return (
        <Dialog maxWidth="sm" fullWidth open={dialogIsOpen}>
            <DialogTitle className={classes.dialogTitle}>
                Delete Category
            </DialogTitle>
            <DialogContent>
                <Typography component="h2" style={{ color: '#ED7571' }}>
                    <b>
                        {' '}
                        Are you sure that you want to delete{' '}
                        {categoryData.category_description} category? All
                        related information will be deleted permanently.
                    </b>
                </Typography>
                <Grid container justifyContent="center">
                    <Grid item xs={12} md={12}>
                        <Formik
                            initialValues={categoryData}
                            onSubmit={(values, { setSubmitting }) => {
                                let confirm_deletion = '';
                                if (
                                    values['confirm_deletion'] !== null &&
                                    values['confirm_deletion'] !== undefined
                                ) {
                                    confirm_deletion = values[
                                        'confirm_deletion'
                                    ].toLowerCase();
                                }

                                if (confirm_deletion === 'delete') {
                                    request
                                        .delete(
                                            `${config.API_BASE_URL}/share-class-categories/${categoryData.category_id}/delete`
                                        )
                                        .then((response) => {
                                            enqueueSnackbar(
                                                'Asset Class Category has been deleted successfully',
                                                {
                                                    variant: 'success',
                                                }
                                            );
                                            setDialogIsOpen(false);
                                            props.capTableActions.getClassCategories();
                                            props.setClassTableKey(
                                                props.classTableKey + 1
                                            );
                                        })
                                        .catch(() => {
                                            enqueueSnackbar(
                                                'Error occured while deleting asset class category',
                                                {
                                                    variant: 'error',
                                                }
                                            );
                                            setSubmitting(false);
                                        });
                                } else {
                                    enqueueSnackbar(
                                        'Please type "delete" in the box to confirm request',
                                        {
                                            variant: 'error',
                                        }
                                    );
                                }
                            }}
                        >
                            {({ isSubmitting }) => (
                                <Container>
                                    <Form className={classes.form} noValidate>
                                        <Field
                                            component={TextField}
                                            type="text"
                                            name="confirm_deletion"
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            label={
                                                <span>
                                                    Type{' '}
                                                    <b className="ihq-red">
                                                        delete
                                                    </b>{' '}
                                                    in the box to confirm your
                                                    request
                                                </span>
                                            }
                                            fullWidth
                                        />
                                        <DialogActions
                                            className={classes.actions}
                                        >
                                            <Button
                                                color="secondary"
                                                variant="outlined"
                                                className={
                                                    classes.secondaryButtonLabel
                                                }
                                                onClick={() => {
                                                    closeDialog();
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="submit"
                                                variant="outlined"
                                                className={classes.buttonLabel}
                                                color="primary"
                                                disabled={isSubmitting}
                                            >
                                                Delete
                                            </Button>
                                        </DialogActions>
                                    </Form>
                                </Container>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
const mapStateToProps = ({ profile, capTable, category_loading }) => {
    return {
        profile: profile,
        categories: capTable.categories.data,
        classes: capTable.classes.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        capTableActions: bindActionCreators(capTableActions, dispatch),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteCategoryDialog);
