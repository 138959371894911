import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import {
    Switch,
    Route,
    useRouteMatch,
    NavLink,
    useLocation,
} from 'react-router-dom';

import TabPanel from '../../components/TabPanel';
import SubMenu from '../../components/SubMenu';
import Dashboard from '../../pages/admin/Dashboard/Dashboard';
import AdminPreferences from '../../pages/admin/Dashboard/AdminPreferences';

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const AdminUserRoutes = (props) => {
    let { path } = useRouteMatch();
    const location = useLocation();

    const getActiveTab = () => {
        switch (location.pathname) {
            case '/admin/dash':
            case '/admin':
                return 0;
            case '/admin/preferences':
                return 1;
            default:
                return 0;
        }
    };

    const [value, setValue] = useState(getActiveTab());
    return (
        <div>
            <SubMenu
                value={value}
                onChange={(e, v) => setValue(v)}
            >
                <Tab
                    label="Manage Users"
                    {...a11yProps(0)}
                    to={`${path}`}
                    component={NavLink}
                />
                <Tab
                    label="Preferences"
                    {...a11yProps(1)}
                    to={`${path}/preferences`}
                    component={NavLink}
                />
            </SubMenu>
            <TabPanel>
                <Switch>
                    <Route exact path={`${path}`} component={Dashboard} />
                    <Route exact path={`${path}/preferences`} component={AdminPreferences} />
                </Switch>
            </TabPanel>
        </div>
    );
};

export default AdminUserRoutes;
