import React, { useEffect } from 'react';
import DataTable from '../../../components/DataTable/MuiDataTable';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as offeringActions from '../../../store/interimOffering/offeringActions';
import * as subscriptionAgreementActions from '../../../store/subscriptionAgreements/subscriptionAgreementActions';
import treeBRColumns from '../../../utilities/treeBRColumns';

import Humanize from 'humanize-plus';
import LockedTile from '../../../components/LockedTile';
import { SUBSCRIPTION_ENTERPRISE } from '../../../constants/profile';

const subscriptionAgreementColums = [
    {
        name: 'first_name',
        label: 'First Name',
    },
    {
        name: 'last_name',
        label: 'Last Name',
    },
    {
        name: 'legal_name',
        label: 'Legal Name',
    },
    {
        name: 'email',
        label: 'Email',
    },
    {
        name: 'dg_number',
        label: 'DG Number',
    },
    {
        name: 'country_of_citizen',
        label: 'Country of Citizen',
    },
    {
        name: 'num_shares',
        label: 'Assets commited',
    },
    {
        name: 'subscription_agreement',
        label: 'Subscription Agreement',
    },
    {
        name: 'funds',
        label: 'Funds',
        options: {
            customBodyRender: (value) => (
                <span>${Humanize.formatNumber(value, 2)}</span>
            ),
        },
    },
    {
        name: 'kyc_status',
        label: 'KYC Status',
    },
];

const RegisteredInterests = (props) => {
    const { offerings, subscriptionAgreements } = props;

    useEffect(props.offeringActions.getIssuerOfferings, []);
    useEffect(props.subscriptionAgreementActions.getSubscriptionAgreements, []);

    return (
        <LockedTile requiredPlan={SUBSCRIPTION_ENTERPRISE}>
            {/* Subscription agreements */}
            {subscriptionAgreements.loading ? (
                <Grid container item xs={12} justifyContent="center">
                    <CircularProgress />
                </Grid>
            ) : (
                <DataTable
                    title={'Subscription Agreements'}
                    fontSize={10}
                    data={
                        subscriptionAgreements.data &&
                        subscriptionAgreements.data.data.length > 0
                            ? subscriptionAgreements.data.data
                            : []
                    }
                    columns={subscriptionAgreementColums}
                />
            )}

            <br />
            <br />

            {/* Registered interests */}
            {offerings.loading ? (
                <Grid container item xs={12} justifyContent="center">
                    <CircularProgress />
                </Grid>
            ) : (
                <DataTable
                    title={'Registered Interest'}
                    data={
                        offerings &&
                        offerings.data &&
                        offerings.data.data &&
                        offerings.data.data.length > 0
                            ? offerings.data.data
                            : []
                    }
                    columns={treeBRColumns}
                    shareSort={2}
                />
            )}
        </LockedTile>
    );
};

const mapStateToProps = ({ offerings, subscriptionAgreements }) => {
    return {
        offerings: offerings,
        subscriptionAgreements: subscriptionAgreements,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        offeringActions: bindActionCreators(offeringActions, dispatch),
        subscriptionAgreementActions: bindActionCreators(
            subscriptionAgreementActions,
            dispatch
        ),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisteredInterests);
