import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box } from '@mui/material';

import { snackbar } from '../../../../utilities/snackbarUtils';
import { assets } from '../../api';
import { errorParser } from '../../utils/errorParser';
import TableComponent from '../Table';

const confirmedColumns = [
    { headerName: 'Amount', field: 'amount' },
    { headerName: 'Gaid', field: 'gaid' },
    { headerName: 'Owner', field: 'owner' },
];

const lostReissuanceColumns = [
    { headerName: 'TXID', field: 'txid' },
    { headerName: 'Vout', field: 'vout' },
];

const Balance = ({ assetUuid }) => {
    const [accountBalance, setAccountBalance] = useState({});

    useEffect(() => {
        getBalance();
    }, [assetUuid]);

    const getBalance = async () => {
        try {
            const data = (await assets.balance(assetUuid)).data;
            setAccountBalance(data);
        } catch (err) {
            snackbar.error(errorParser(err));
        }
    };

    return (
        <Box mb={2}>
            <Typography textAlign="center" variant="body1">
                Asset Balance
            </Typography>
            {accountBalance ? (
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                >
                    {!!accountBalance?.confirmedBalance?.length && (
                        <Grid item xs={12} md={6}>
                            <Typography textAlign="center" variant="body2">
                                Confirmed Balance
                            </Typography>
                            <Box mt={2}>
                                <TableComponent
                                    columns={confirmedColumns}
                                    items={accountBalance.confirmedBalance}
                                />
                            </Box>
                        </Grid>
                    )}
                    {!!accountBalance?.lostOutputs?.length && (
                        <Grid item xs={12} md={6}>
                            <Typography textAlign="center" variant="body2">
                                Lost Outputs
                            </Typography>
                            <Box mt={2}>
                                <TableComponent
                                    columns={lostReissuanceColumns}
                                    items={accountBalance.lostOutputs}
                                />
                            </Box>
                        </Grid>
                    )}
                    {!!accountBalance?.reissuanceLostOutputs?.length && (
                        <Grid item xs={12}>
                            <Typography textAlign="center" variant="body2">
                                Reissuance Lost Outputs
                            </Typography>
                            <Box mt={2}>
                                <TableComponent
                                    columns={lostReissuanceColumns}
                                    items={accountBalance.reissuanceLostOutputs}
                                />
                            </Box>
                        </Grid>
                    )}
                </Grid>
            ) : (
                <Typography textAlign="center" variant="body2">
                    Balance is empty
                </Typography>
            )}
        </Box>
    );
};

export default Balance;
