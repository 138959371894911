import React from 'react'
import { Button, IconButton, Stack, Tooltip, Typography } from '@mui/material'

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import GroupsIcon from '@mui/icons-material/Groups';

import useAmpCategories from '../../../api/blockchain/useAmpCategories';

import BCTable from './Utils/BCTable';
import DataLoading from './Utils/DataLoading';
import NoData from './Utils/NoData';
import DataError from './Utils/DataError';

import WhitelistAssetsDialog from './Whitelists/WhitelistAssetsDialog';
import WhitelistHoldersDialog from './Whitelists/WhitelistHoldersDialog';
import CreateCategoryDialog from './Whitelists/CreateCategoryDialog';
import DeleteCategoryDialog from './Whitelists/DeleteCategoryDialog';
import EditCategoryDialog from './Whitelists/EditCategoryDialog';

const Whitelists = (props) => {
    const [openAssets, setOpenAssets] = React.useState(false);
    const [openShareholders, setOpenShareholders] = React.useState(false);
    const [openCreateCategory, setOpenCreateCategory] = React.useState(false);
    const [openEditCategory, setOpenEditCategory] = React.useState(false);
    const [openDeleteCategory, setOpenDeleteCategory] = React.useState(false);

    const [activeCategory, setActiveCategory] = React.useState(null);

    const { ampCategories, ampCategoriesLoading, ampCategoriesError, ampCategoriesValidating, updateAmpCategories } = useAmpCategories()

    const handleOpenAssets = (category) => {
        setOpenAssets(true);
        setActiveCategory(category)
    };

    const handleCloseAssets = () => {
        setOpenAssets(false);
        setActiveCategory(null)
    };

    const handleOpenShareholders = (category) => {
        setOpenShareholders(true);
        setActiveCategory(category)
    };

    const handleCloseShareholders = () => {
        setOpenShareholders(false);
        setActiveCategory(null)
    };

    const handleOpenCreateCategory = () => {
        setOpenCreateCategory(true);
    };

    const handleCloseCreateCategory = () => {
        setOpenCreateCategory(false);
    };

    const handleOpenDeleteCategory = (category) => {
        setOpenDeleteCategory(true);
        setActiveCategory(category)
    };

    const handleCloseDeleteCategory = () => {
        setOpenDeleteCategory(false);
        setActiveCategory(null)
    };

    const handleOpenEditCategory = (category) => {
        setOpenEditCategory(true);
        setActiveCategory(category)
    };

    const handleCloseEditCategory = () => {
        setOpenEditCategory(false);
        setActiveCategory(null)
    };

    const assignmentButtons = (row) => <>
        <Button variant="contained" size="small" startIcon={<AccountBalanceWalletIcon />} sx={{ marginRight: '10px;' }}
            onClick={() => handleOpenAssets(row)}
        >
            Assets
        </Button>
        <Button variant="outlined" size="small" startIcon={<GroupsIcon />}
            onClick={() => handleOpenShareholders(row)}
        >
            Holders
        </Button>
    </>

    const actionButtons = (row) => <>
        <Tooltip title="Edit whitelist">
            <IconButton aria-label="edit" color="secondary"
                onClick={() => handleOpenEditCategory(row)}
            >
                <EditIcon />
            </IconButton>
        </Tooltip>
        <Tooltip title="Delete whitelist">
            <IconButton aria-label="delete" color="danger"
                onClick={() => handleOpenDeleteCategory(row)}
            >
                <DeleteIcon />
            </IconButton>
        </Tooltip>
    </>

    return (
        <>
            <Typography variant="h6">
                My whitelists
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent='space-between' gap={0} sx={{ color: 'gray' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant='body2'>
                        Whitelists are used to restrict transfers - <br />
                        only holders within the same whitelist can trade with an asset in the whitelist.
                    </Typography>
                </div>
                <Button variant="contained" startIcon={<AddIcon />} sx={{ float: 'right' }}
                    onClick={handleOpenCreateCategory}
                >
                    New whitelist
                </Button>
            </Stack>
            <br />
            {/* AMP category data loading */}
            <DataLoading 
                loading={ampCategoriesLoading}
                text='Loading your whitelists...'
            />

            {/* Wallet data loaded */}
            {
                (!ampCategoriesLoading && ampCategories) &&
                <BCTable
                    columns={[
                        { name: 'name', label: 'Category', bold: true },
                        { name: 'description', label: 'Description' },
                        {
                            name: 'assignments', label: 'Assignment', type: 'actions', render: (row) => (
                                assignmentButtons(row)
                            )
                        },
                        {
                            name: 'actions', label: 'Actions', type: 'actions', render: (row) => (
                                actionButtons(row)
                            )
                        }
                    ]}
                    data={ampCategories}
                />
            }
            <br />
            {/* No whitelist data */}
            <NoData 
                show={!ampCategoriesValidating && !ampCategoriesError && ampCategories.length === 0}
                text='You have no active whitelists.'
            />

            {/* AMP whitelist error */}
            <DataError
                show={ampCategoriesError && !ampCategories}
                text='Could not load whitelists due to error. Please try again.'
            />

            {
                openAssets &&
                <WhitelistAssetsDialog open={openAssets} handleClose={handleCloseAssets} category={activeCategory} />
            }
            {
                openShareholders &&
                <WhitelistHoldersDialog open={openShareholders} handleClose={handleCloseShareholders} category={activeCategory} />
            }
            {
                openCreateCategory &&
                <CreateCategoryDialog open={openCreateCategory} handleClose={handleCloseCreateCategory} updateCategories={updateAmpCategories} />
            }
            {
                openDeleteCategory &&
                <DeleteCategoryDialog open={openDeleteCategory} handleClose={handleCloseDeleteCategory} category={activeCategory} updateCategories={updateAmpCategories} />
            }
            {
                openEditCategory &&
                <EditCategoryDialog open={openEditCategory} handleClose={handleCloseEditCategory} category={activeCategory} updateCategories={updateAmpCategories} />
            }
        </>
    )
}

export default Whitelists