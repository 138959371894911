import axios from 'axios';

import * as authTypes from './authTypes';
import * as config from '../../config';

import {
    setAccessToken,
    setRefreshToken,
    resetSession,
} from '../../utilities/auth';
import { parseError } from '../../utilities/utils';
import { getProfile, clearProfile } from '../profile/profileActions';

export const getOtpDataSuccess = (otp) => {
    return {
        type: authTypes.GET_OTP_DATA_SUCCESS,
        payload: otp,
    };
};

export const clearOtpDataSuccess = () => {
    return {
        type: authTypes.CLEAR_OTP_DATA_SUCCESS,
    };
};

/**
 * Log into the system.
 */
export const login = (
    values,
    closeSnackbar,
    enqueueSnackbar,
    setSubmitting,
    history,
    isAdmin,
    has2fa = false
) => {
    return (dispatch) => {
        closeSnackbar();
        let route = isAdmin ? 'admin/login' : 'login';

        axios
            .post(`${config.API_BASE_URL}/${route}`, values)
            .then((response) => {
                /* Login with 2FA */
                if (has2fa) {
                    setSubmitting(false);
                    dispatch(getOtpDataSuccess(response.data));
                    /* Regular login */
                } else {
                    handleLogin(
                        dispatch,
                        response,
                        values['email'],
                        enqueueSnackbar,
                        history
                    );
                }
            })
            .catch(() => {
                enqueueSnackbar(
                    'Your email address or password is not correct',
                    { variant: 'error' }
                );
            })
            .finally(() => {
                setSubmitting(false);
            });
    };
};

/**
 * Submit and verify a user's OTP.
 */
export const submitOtp = (
    values,
    closeSnackbar,
    enqueueSnackbar,
    setSubmitting,
    history,
    isAdmin
) => {
    return (dispatch) => {
        closeSnackbar();

        axios
            .post(`${config.API_BASE_URL}/admin/verify`, values)
            .then((response) => {
                handleLogin(
                    dispatch,
                    response,
                    values['email'],
                    enqueueSnackbar,
                    history,
                    isAdmin
                );
                dispatch(clearOtpDataSuccess());
            })
            .catch((error) => {
                enqueueSnackbar(parseError(error), { variant: 'error' });
            })
            .finally(() => {
                setSubmitting(false);
            });
    };
};

/**
 * Clear OTP data
 */
export const clearOtp = () => {
    return (dispatch) => {
        dispatch(clearOtpDataSuccess());
    };
};

/**
 * Common login operations to be peformed
 * upon sucesful authentication.
 */
const handleLogin = (
    dispatch,
    response,
    email,
    enqueueSnackbar,
    history,
    isAdmin
) => {
    const { token, companyLogo, type } = response.data;

    setAccessToken(token);
    setRefreshToken(token);

    localStorage.setItem('user-email', email);
    localStorage.setItem('company-logo', companyLogo);

    dispatch(getProfile());

    if (isAdmin) {
        history.push('/admin');
    } else if (
        type === 'transfer-agent-owner' ||
        type === 'transfer-agent-admin'
    ) {
        history.push('/transfer-agent');
    } else {
        history.push('/issuer/issue');
    }
};

/**
 * Log out of the system.
 */
export const logout = (history, isAdmin) => {
    // const refreshToken = getRefreshToken();
    // axios.post(`${config.API_BASE_URL}/auth/logout/`, {
    //     refresh_token: refreshToken,
    // });

    resetSession();
    clearProfile();

    history.replace({ pathname: !isAdmin ? '/' : '/admin/login' });
};
