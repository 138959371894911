import React from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    Typography,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import useUserInfo from '../../../../api/blockchain/useUserInfo';
import useAmpCategories from '../../../../api/blockchain/useAmpCategories';
import assignHolderToCategory from '../../../../api/blockchain/assignHolderToCategory';
import removeHolderFromCategory from '../../../../api/blockchain/removeHolderFromCategory';

import { snackbar } from '../../../../utilities/snackbarUtils';
import { errorParser } from '../../../../components/LiquidForm/utils/errorParser';
import moment from 'moment';
import find from 'lodash/find';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import ComboBox from '../../../../components/Form/ComboBox';
import SubmitButton from '../../../../components/Form/SubmitButton';

import BCTable from '../Utils/BCTable';
import DataLoading from '../Utils/DataLoading';
import NoData from '../Utils/NoData';
import DataError from '../Utils/DataError';

const AssignShareholderDialog = ({
    open,
    handleClose,
    holder,
    categories,
    updateUser,
}) => {
    const schema = yup.object({
        category_id: yup
            .string()
            .required('Selecting a category is required.')
            .nullable(),
    });

    const defaultValues = {
        category_id: '',
    };

    const {
        handleSubmit,
        control,
        reset,
        formState: { isSubmitting, errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    const onSubmit = async (data) => {
        await assignHolderToCategory(data.category_id, holder?.amp_user_id)
            .then((response) => {
                snackbar.success('Succesfully whitelisted the holder.');
                reset(defaultValues);
                handleClose();
                updateUser();
            })
            .catch((error) => {
                snackbar.error(errorParser(error));
            });
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Whitelist a holder</DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <DialogContentText>
                        <div style={{ display: 'flex', color: 'gray' }}>
                            <Typography variant="body2">
                                Assign whitelists to the holder to enable
                                trading within them.
                            </Typography>
                        </div>
                        <br />
                        <ComboBox
                            options={categories}
                            optionLabel={'name'}
                            valueLabel={'id'}
                            disabled={!categories}
                            loading={!categories}
                            label="Category"
                            name="category_id"
                            error={errors.category_id}
                            control={control}
                            defaultValue={categories[0]}
                        />

                        <br />
                        <br />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <SubmitButton
                        text="Assign"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                    />
                </DialogActions>
            </form>
        </Dialog>
    );
};

const DeleteShareholderDialog = ({
    open,
    handleClose,
    category,
    holder,
    updateUser,
}) => {
    const [loading, setLoading] = React.useState(false);

    const onSubmit = async () => {
        setLoading(true);
        await removeHolderFromCategory(category?.id, holder?.amp_user_id)
            .then((response) => {
                snackbar.success(
                    'Succesfully removed whitelisting for the holder.'
                );
                handleClose();
                updateUser();
            })
            .catch((error) => {
                snackbar.error(errorParser(error));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Holder whitelisting</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div style={{ display: 'flex', color: 'gray' }}>
                        <Typography variant="body2">
                            Are you sure you want to remove{' '}
                            <b>{holder?.holder}</b> from the{' '}
                            <b>"{category?.name}"</b> whitelist?
                        </Typography>
                    </div>
                    <br />
                    <br />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <SubmitButton
                    text="Remove"
                    color="danger"
                    disabled={loading}
                    loading={loading}
                    onClick={onSubmit}
                />
            </DialogActions>
        </Dialog>
    );
};

const HolderCategoriesDialog = ({ open, handleClose, user }) => {
    const [holderCategories, setHolderCategories] = React.useState([]);
    const [nonHolderCategories, setNonHolderCategories] = React.useState([]);
    const {
        ampCategories,
        ampCategoriesLoading,
        ampCategoriesError,
    } = useAmpCategories();
    const { userInfo, userInfoLoading, updateUserInfo } = useUserInfo(
        user?.amp_user_id
    );

    const [openAssignShareholder, setOpenAssignShareholder] = React.useState(
        false
    );
    const [openDeleteShareholder, setOpenDeleteShareholder] = React.useState(
        false
    );
    const [activeCategory, setActiveCategory] = React.useState(null);

    React.useEffect(() => {
        if (
            userInfo &&
            userInfo?.categories &&
            ampCategories &&
            ampCategories.length !== 0
        ) {
            let holderCats = [];
            for (let category of userInfo?.categories) {
                let cat = find(ampCategories, {
                    id: category,
                });
                if (cat) {
                    holderCats.push(cat);
                }
            }
            setHolderCategories(holderCats);
            setNonHolderCategories(
                ampCategories.filter((x) => !holderCats.includes(x))
            );
        }
    }, [userInfo, ampCategories]);

    const handleOpenAssignShareholder = () => {
        setOpenAssignShareholder(true);
    };

    const handleCloseAssignShareholder = () => {
        setOpenAssignShareholder(false);
    };

    const handleOpenDeleteShareholder = (category) => {
        setOpenDeleteShareholder(true);
        setActiveCategory(category);
    };

    const handleCloseDeleteShareholder = () => {
        setOpenDeleteShareholder(false);
        setActiveCategory(null);
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg">
            <DialogTitle>Holder whitelisting</DialogTitle>
            <DialogContent>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={12}
                >
                    <div>
                        <Typography variant="body1" as="h5">
                            {user?.holder}
                        </Typography>
                        <Typography
                            variant="caption"
                            as="p"
                            sx={{ color: 'gray' }}
                        >
                            Holder since{' '}
                            {moment(user?.created_at).format('MMM DD, YYYY')}
                            {user?.registered_at && (
                                <>
                                    <br />
                                    Registered since{' '}
                                    {moment(user?.registered_at).format(
                                        'MMM DD, YYYY'
                                    )}
                                </>
                            )}
                        </Typography>
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            size="small"
                            startIcon={<AddIcon />}
                            onClick={() => handleOpenAssignShareholder()}
                        >
                            Assign
                        </Button>
                    </div>
                </Stack>
                <br />
                <Typography variant="body1" as="h5">
                    Whitelists
                </Typography>
                <div style={{ display: 'flex', color: 'gray' }}>
                    <Typography variant="body2">
                        Whitelists are used for <b>enabling asset transfers</b>{' '}
                        - only holders within the same whitelist can receive a
                        whitelisted asset.
                    </Typography>
                </div>
                <br />
                {
                    <DataLoading
                        loading={userInfoLoading || ampCategoriesLoading}
                        text="Loading holder categories..."
                    />
                }
                {!userInfoLoading &&
                    userInfo &&
                    !ampCategoriesLoading &&
                    ampCategories && (
                        <BCTable
                            columns={[
                                { name: 'name', label: 'Category', bold: true },
                                {
                                    name: 'actions',
                                    label: 'Actions',
                                    type: 'actions',
                                    render: (row) => (
                                        <Button
                                            variant="contained"
                                            color="danger"
                                            size="small"
                                            startIcon={<DeleteForeverIcon />}
                                            onClick={() =>
                                                handleOpenDeleteShareholder(row)
                                            }
                                        >
                                            Remove
                                        </Button>
                                    ),
                                },
                            ]}
                            data={holderCategories}
                        />
                    )}
                <br />
                {/* No data */}
                <NoData
                    show={
                        !ampCategoriesLoading &&
                        !ampCategoriesError &&
                        holderCategories.length === 0
                    }
                    text="This holder has no whitelists."
                />

                {/* AMP asset error */}
                <DataError
                    show={
                        ampCategoriesError &&
                        (!holderCategories || holderCategories.length === 0)
                    }
                    text="Could not load holder whitelists due to error. Please try again."
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>

            {openAssignShareholder && (
                <AssignShareholderDialog
                    open={openAssignShareholder}
                    handleClose={handleCloseAssignShareholder}
                    categories={nonHolderCategories}
                    holder={user}
                    updateUser={updateUserInfo}
                />
            )}
            {openDeleteShareholder && (
                <DeleteShareholderDialog
                    open={openDeleteShareholder}
                    handleClose={handleCloseDeleteShareholder}
                    category={activeCategory}
                    holder={user}
                    updateUser={updateUserInfo}
                />
            )}
        </Dialog>
    );
};

export default HolderCategoriesDialog;
