import React from 'react';
import BaseTable from '../BaseTable/BaseTable';
import request from '../../utilities/request';

class DataTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            updateKey: null,
            defaultDataColumns: [],
            dataColumns: [],
            dataColumnGroups: [],
            dataRows: [],
            dataTotals: null,
            totalCount: 0,
            currentPage: 0,
            rowsPerPage: 10,
            sortColumn: '',
            sortDirection: 'desc',
            searchValue: '',
            selectedColumnGroup: null,
            selectedColumns: null,
            managedDataRows: [],
            managedDataRequested: false,
            isLoadingRows: true,
            isLoadingTotals: true,
            loadingError: false,
        };
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate() {
        this.load();
    }

    load = () => {
        const {
            updateKey,
            preLoading,
            dataColumns,
            dataColumnGroups,
            displayTotals,
        } = this.props;

        let selectedColumnGroup, selectedColumns;

        if (dataColumnGroups && dataColumnGroups.length) {
            selectedColumnGroup = dataColumnGroups[0].id;
            selectedColumns = dataColumns.filter((column) =>
                this.checkColumnGroup(column, selectedColumnGroup)
            );
        }

        if (!preLoading && updateKey !== this.state.updateKey) {
            this.setState(
                {
                    updateKey: updateKey,
                    defaultDataColumns: dataColumns,
                    dataColumns: selectedColumns
                        ? selectedColumns
                        : dataColumns,
                    dataColumnGroups: dataColumnGroups,
                    selectedColumnGroup: selectedColumnGroup,
                    selectedColumns: selectedColumns,
                    currentPage: 0,
                    isLoadingRows: true,
                    isLoadingTotals: !!displayTotals,
                },
                () => {
                    this.loadData();

                    if (displayTotals) {
                        this.loadTotals();
                    }
                }
            );
        }
    };

    loadData = () => {
        if (this.props.managed) {
            this.loadManagedData();
        } else {
            this.loadRemoteData();
        }
    };

    loadRemoteData = () => {
        const {
            currentPage,
            rowsPerPage,
            sortColumn,
            sortDirection,
            dataColumns,
            searchValue,
        } = this.state;

        const {
            remoteUrl,
            remoteMethod = 'get',
            remoteBody = {},
            remoteParameters,
        } = this.props;

        const parameters = { ...remoteParameters };

        if (currentPage || currentPage === 0) {
            parameters.page = currentPage + 1;
        }

        if (rowsPerPage) {
            parameters.limit = rowsPerPage;
        }

        if (
            sortDirection &&
            sortColumn &&
            dataColumns.find((column) =>
                [column.id, column.sortId].includes(sortColumn)
            )
        ) {
            parameters.sort = `${
                sortDirection === 'desc' ? '-' : ''
            }${sortColumn}`;
        }

        if (searchValue) {
            parameters.search = searchValue;
        }

        request
            .request({
                url: remoteUrl,
                method: remoteMethod,
                data: remoteBody,
                params: parameters,
            })
            .then((response) => {
                const newState = {};

                const { page, count, limit, sort } = response.data;

                if (count !== 9223372036854776000) {
                    response.data.count = limit * (page - 1) + count;
                }
                newState.dataRows = response.data.list;
                newState.totalCount = response.data.count;
                newState.sortDirection = sort[0] === '-' ? 'desc' : 'asc';
                newState.sortColumn = sort.substring(sort[0] === '-' ? 1 : 0);

                this.setState({
                    ...newState,
                    isLoadingRows: false,
                    loadingError: false,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoadingRows: false,
                    loadingError: true,
                });

                console.log(error);
            });
    };

    loadManagedData = () => {
        const { managedDataRequested, managedDataRows } = this.state;

        const {
            remoteUrl,
            remoteMethod = 'get',
            remoteBody = {},
            remoteParameters,
        } = this.props;

        const parameters = { ...remoteParameters };

        if (managedDataRequested) {
            const newState = this.generateManagedDataState(managedDataRows);

            this.setState({
                ...newState,
                isLoadingRows: false,
                loadingError: false,
            });
        } else {
            this.setState({ managedDataRequested: true });

            request
                .request({
                    url: remoteUrl,
                    method: remoteMethod,
                    data: remoteBody,
                    params: parameters,
                })
                .then((response) => {
                    const managedDataRows = response.data;
                    const dataRows = JSON.parse(
                        JSON.stringify(managedDataRows)
                    );

                    const newState = this.generateManagedDataState(dataRows);

                    this.setState({
                        managedDataRows,
                        ...newState,
                        isLoadingRows: false,
                        loadingError: false,
                    });
                })
                .catch((error) => {
                    this.setState({
                        isLoadingRows: false,
                        loadingError: true,
                    });

                    console.log(error);
                });
        }
    };

    generateManagedDataState = (dataRows) => {
        const {
            currentPage,
            rowsPerPage,
            sortColumn,
            sortDirection,
        } = this.state;

        const { dataRowPreParser } = this.props;

        const newState = {};

        const startIndex = currentPage * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;

        if (dataRowPreParser) {
            dataRows = dataRows.map((row) => dataRowPreParser(row));
        }

        dataRows.sort(this.createManagedSortHandler(sortColumn, sortDirection));

        newState.dataRows = dataRows.slice(startIndex, endIndex);
        newState.totalCount = dataRows.length;

        return newState;
    };

    loadTotals = () => {
        const { remoteUrl, remoteParameters } = this.props;

        const { searchValue } = this.state;

        const parameters = { total: true, ...remoteParameters };

        if (searchValue) {
            parameters.search = searchValue;
        }

        request
            .get(remoteUrl, {
                params: parameters,
            })
            .then((response) => {
                this.setState({
                    isLoadingTotals: false,
                    dataTotals: {
                        ...response.data.list[0],
                        isTotalRow: true,
                    },
                });
            })
            .catch((error) => {
                this.setState({
                    isLoadingTotals: false,
                });

                console.log(error);
            });
    };

    checkColumnGroup = (column, group) =>
        !column.group ||
        (column.group.constructor === Array
            ? column.group.includes(group)
            : column.group === group);

    createManagedSortHandler = (column, direction) => {
        const order = direction === 'desc' ? -1 : 1;
        return (a, b) => {
            const result =
                a[column] < b[column] ? -1 : a[column] > b[column] ? 1 : 0;
            return result * order;
        };
    };

    handleChangePage = (event, page) => {
        this.setState({ isLoadingRows: true, currentPage: page }, () => {
            this.loadData();
        });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState(
            {
                isLoadingRows: true,
                currentPage: 0,
                rowsPerPage: event.target.value,
            },
            () => {
                this.loadData();
            }
        );
    };

    handleChangeSort = (_, column) => {
        const newState = { isLoadingRows: true };

        if (this.state.sortColumn === column) {
            newState.sortDirection =
                this.state.sortDirection === 'desc' ? 'asc' : 'desc';
        } else {
            newState.sortColumn = column;
        }

        this.setState(newState, () => {
            this.loadData();
        });
    };

    handleChangeColumnGroup = (_, columnGroup) => {
        this.setState((state) => ({
            dataColumns: state.defaultDataColumns.filter((column) =>
                this.checkColumnGroup(column, columnGroup)
            ),
            selectedColumnGroup: columnGroup,
        }));
    };

    handleChangeSearch = (value) => {
        const { displayTotals } = this.state;

        this.setState(
            {
                isLoadingRows: true,
                isLoadingTotals: !!displayTotals,
                searchValue: value,
            },
            () => {
                this.loadData();

                if (displayTotals) {
                    this.loadTotals();
                }
            }
        );
    };

    render() {
        const { isLoadingRows, isLoadingTotals, ...rest } = this.state;

        return (
            <BaseTable
                {...rest}
                {...this.props}
                isLoading={isLoadingRows || isLoadingTotals}
                onChangeColumnGroup={this.handleChangeColumnGroup}
                onChangeSort={this.handleChangeSort}
                onChangeSearch={this.handleChangeSearch}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
        );
    }
}

export default DataTable;
