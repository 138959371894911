export const DASHBOARD_ROUTES = [
    '/issuer/',
    '/issuer/company',
    '/issuer/user',
    '/issuer/templates',
    '/issuer/dash',
    '/issuer/setup-wizard',
    '/issuer/preferences',
    '/issuer/dash/company',
    '/issuer/dash/user',
    '/issuer/dash/templates',
    '/issuer/dash/dash',
    '/issuer/dash/setup-wizard',
    '/issuer/dash/preferences',
];

export const CAP_TABLE_ROUTES = [
    '/issuer/issue/cap-table',
    //'/issuer/issue/cap-table/new',
    '/issuer/issue/cap-table/class-info',
    //'/issuer/issue/cap-table/category-info',
    '/issuer/issue/cap-table/holder-info',
    '/issuer/issue/cap-table/kyc',
    '/issuer/issue/cap-table/documents',
    '/issuer/issue/cap-table/blockchain',
    'transfer-routes',
    '/issuer/issue/cap-table/transfer-agents',
    //'/issuer/issue/cap-table/scenarios',
    '/issuer/issue/cap-table/events',
    '/issuer/issue/cap-table/setup-wizard',

];

export const ISSUE_ROUTES = [
    '/issuer/issue/issue/issuance',
    '/issuer/issue/issue/transfers',
];

export const INVESTORS_ROUTES = [
    '/issuer/issue/holders',
    '/issuer/issue/holders/events',
    '/issuer/issue/holders/kyc',
    '/issuer/issue/holders/documents',
    '/issuer/issue/holders/distributions',
    //'/issuer/issue/holders/wallets',
    '/issuer/issue/holders/invite',
    // '/issuer/issue/holders/investor-view',
];

export const FUNDRAISE_ROUTES = [
    '/issuer/issue/fundraise',
    '/issuer/issue/fundraise/data-room',
    '/issuer/issue/fundraise/templates',
    '/issuer/issue/fundraise/registered-interests',
   // '/issuer/issue/fundraise/offerings',
   // '/issuer/issue/fundraise/verified-partners',
   // '/issuer/issue/fundraise/funding-options',
];

export const TRANSFERS_ROUTES = [
    '/issuer/issue/transfers',
    '/issuer/issue/transfers/transfer',
  //'/issuer/issue/transfers/issuance',
    '/issuer/issue/transfers/retirement',
    '/issuer/issue/transfers/approvals',
    '/issuer/issue/transfers/history',
    '/issuer/issue/transfers/external',
    '/issuer/issue/transfers/transfer-agents',
];

export const LIST_ROUTES_DEMO = [
    '/issuer/list/list',
    '/issuer/list/list/disclosure-requirements',
    '/issuer/list/list/sponsor-advisor-updates',
    '/issuer/list/list/documents-templates',
    '/issuer/list/list/listing-details',
    '/issuer/list/list/verified-partners',
];

export const LIST_ROUTES = [
    '/issuer/list/list',
    '/issuer/list/list/disclosure-requirements',
    '/issuer/list/list/documents-templates',
    '/issuer/list/list/listing-details',
    '/issuer/list/list/verified-partners',
];

export const TRADE_ROUTES = [
    '/issuer/list/trade',
    '/issuer/list/trade/history',
];
