import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import {
    Switch,
    Route,
    useRouteMatch,
    NavLink,
    useLocation,
} from 'react-router-dom';

import TabPanel from '../../components/TabPanel';
import SubMenu from '../../components/SubMenu';
import Issuers from '../../pages/admin/ListX/Issuers';

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const AdminListRoutes = (props) => {
    let { path } = useRouteMatch();
    const location = useLocation();

    const getActiveTab = () => {
        switch (location.pathname) {
            case '/admin/listx':
                return 0;
            default:
                return 0;
        }
    };

    const [value, setValue] = useState(getActiveTab());
    return (
        <div>
            <SubMenu
                value={value}
                onChange={(e, v) => setValue(v)}
            >
                <Tab
                    label="Issuers"
                    {...a11yProps(0)}
                    to={`${path}`}
                    component={NavLink}
                />
                {/* <Tab
                    label="Manage users"
                    {...a11yProps(1)}
                    to={`${path}/history`}
                    component={NavLink}
                /> */}
            </SubMenu>
            <TabPanel>
                <Switch>
                    <Route exact path={`${path}`} component={Issuers} />
                    {/* <Route exact path={`${path}/users`} component={History} /> */}
                </Switch>
            </TabPanel>
        </div>
    );
};

export default AdminListRoutes;
