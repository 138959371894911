import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import authStyles from '../authStyles';
import TextField from '../../../components/TextField/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ReCAPTCHA from 'react-google-recaptcha';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
const validationSchema = Yup.object({
    /*password: Yup.string()
        .required('Please enter your password')
        //  .min(8, 'Password is too short - must containt at least 8 characters.')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,

            'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, number and one symbol'
        ),*/
    confirm_password: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Please confirm your password'),
    human: Yup.string().required(''),
});

const Password = (props) => {
    const classes = authStyles();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [requiredData, setRequiredData] = useState([]);
    const [captcha, setCaptcha] = useState('');

    const [lowercase, setLowercase] = useState(props.initialValues.password ? true : false);
    const [uppercase, setUppercase] = useState(props.initialValues.password ? true : false);
    const [number, setNumber] = useState(props.initialValues.password ? true : false);
    const [character, setCharacter] = useState(props.initialValues.password ? true : false);
    const [minLength, setMinLength] = useState(props.initialValues.password ? true : false);
    const [withoutSpace, setWithoutSpace] = useState(props.initialValues.password ? true : false);

    const nextStep = () => {
        props.nextStep();
    };

    const changeShowPassword = () => {
        showPassword === true ? setShowPassword(false) : setShowPassword(true);
    };

    const changeShowConfirmPassword = () => {
        showConfirmPassword === true
            ? setShowConfirmPassword(false)
            : setShowConfirmPassword(true);
    };

    const setRequiredField = (e) => {
        if (e.target.name === 'password') {
            requiredData['password'] = e.target.value;

            let password = e.target.value;

            //length check
            if (password.length >= 8) {
                setMinLength(true);
            } else {
                setMinLength(false);
            }

            //uppercase
            if (password !== password.toLowerCase()) {
                setUppercase(true);
            } else {
                setUppercase(false);
            }

            //lowercase
            if (password !== password.toUpperCase()) {
                setLowercase(true);
            } else {
                setLowercase(false);
            }

            //number checked
            if (/\d/.test(password)) {
                setNumber(true);
            } else {
                setNumber(false);
            }

            let character = '!@#$%^&*(),.?":{}|<>~`_-+=[];\''.split('').some(char => password.includes(char))
            if (character) {
                setCharacter(true);
            } else {
                setCharacter(false);
            }

            //withoutSpace
            if (!password.includes(' ')) {
                setWithoutSpace(true);
            } else {
                setWithoutSpace(false);
            }
        }

        if (e.target.name === 'confirm_password') {
            requiredData['confirm_password'] = e.target.value;
        }

        if (e.target.name === 'human') {
            requiredData['human'] = e.target.checked;
        }

        setRequiredData(requiredData);
        props.saveValues(requiredData);
    };

    const onCaptchaChange = (value) => {
        requiredData['human'] = true;
        setCaptcha(value);
        setRequiredData(requiredData);
        props.saveValues(requiredData);
    };

    return (
        <div id="step2">
            <Formik
                initialValues={props.initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    if (captcha !== '') {
                        values['captcha'] = captcha;
                        props.saveValues(values);
                        nextStep();
                    } else {
                        resetForm({ values: '' });
                    }
                }}
            >
                {({ setFieldValue }) => (
                    <Paper className={classes.paper}>
                        <Typography
                            component="div"
                            style={{ textAlign: 'center' }}
                        >
                            <h2>
                                First, let's create a strong password for your
                                account
                            </h2>
                            <h5>
                                Our suggestion is minimum 8 characters, one
                                upper case, at least one number and one symbol
                            </h5>
                        </Typography>
                        <Container maxWidth="sm">
                            <Form className={classes.form} noValidate>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            component={TextField}
                                            type={
                                                showPassword === true
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            label="Password"
                                            name="password"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            required
                                            InputProps={{
                                                tabIndex: 0,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={
                                                                changeShowPassword
                                                            }
                                                            size="large"
                                                        >
                                                            {showPassword ? (
                                                                <VisibilityIcon />
                                                            ) : (
                                                                <VisibilityOffIcon />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onKeyUp={setRequiredField}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            component={TextField}
                                            type={
                                                showConfirmPassword === true
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            label="Confirm Password"
                                            name="confirm_password"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            required
                                            InputProps={{
                                                tabIndex: 1,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={
                                                                changeShowConfirmPassword
                                                            }
                                                            size="large"
                                                        >
                                                            {showConfirmPassword ? (
                                                                <VisibilityIcon />
                                                            ) : (
                                                                <VisibilityOffIcon />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onKeyUp={setRequiredField}
                                        />
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid>Password Requirements:</Grid>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        style={{
                                            fontSize: '13px',
                                            lineHeight: '33px',
                                        }}
                                    >
                                        <CheckIcon
                                            className={
                                                lowercase
                                                    ? classes.conditionChecked
                                                    : classes.conditionPending
                                            }
                                        />
                                        At least one lowercase letter [a-z]
                                        <br />
                                        <CheckIcon
                                            className={
                                                uppercase
                                                    ? classes.conditionChecked
                                                    : classes.conditionPending
                                            }
                                        />
                                        At least one uppercase letter [A-Z]
                                        <br />
                                        <CheckIcon
                                            className={
                                                character
                                                    ? classes.conditionChecked
                                                    : classes.conditionPending
                                            }
                                        />
                                        Contains a special character (e.g. $ % ^ &)
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        style={{
                                            fontSize: '13px',
                                            lineHeight: '33px',
                                        }}
                                    >
                                        <CheckIcon
                                            className={
                                                number
                                                    ? classes.conditionChecked
                                                    : classes.conditionPending
                                            }
                                        />
                                        At least one number
                                        <br />
                                        <CheckIcon
                                            className={
                                                minLength
                                                    ? classes.conditionChecked
                                                    : classes.conditionPending
                                            }
                                        />
                                        Minimum 8 characters
                                        <br />
                                        <CheckIcon
                                            className={
                                                withoutSpace
                                                    ? classes.conditionChecked
                                                    : classes.conditionPending
                                            }
                                        />
                                        Cannot contain space
                                    </Grid>
                                </Grid>{' '}
                                <br />
                                <Field
                                    name="human"
                                    component={ReCAPTCHA}
                                    style={{
                                        textAlign: '-webkit-center',
                                        marginTop: 10,
                                    }}
                                    sitekey="6LcyKOoZAAAAAHRxgiouz4hfTP1lgChfWieXu-9_"
                                    onChange={onCaptchaChange}
                                    required
                                />
                                {/* <Field
                                component={CheckboxWithLabel}
                                type="checkbox"
                                name="human"
                                Label={{
                                    label: (
                                        <div className={classes.checkbox}>
                                            I'm a human
                                        </div>
                                    ),
                                }}
                                required
                                onClick={setRequiredField}
                            />*/}
                                <FormHelperText error>
                                    <ErrorMessage name="human" />
                                </FormHelperText>
                                <div
                                    className={classes.actions}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        className={classes.buttonLabel}
                                        color="primary"
                                        //  onClick={nextStep}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        disabled={
                                            uppercase === false ||
                                            lowercase === false ||
                                            number === false ||
                                            character === false ||
                                            withoutSpace === false
                                        }
                                    >
                                        CONTINUE
                                    </Button>
                                </div>
                            </Form>
                        </Container>
                    </Paper>
                )}
            </Formik>
        </div>
    );
};

export default Password;
