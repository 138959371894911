import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material'
import moment from 'moment';
import React from 'react'
import HelpTooltip from '../../../../components/HelpTooltip/HelpTooltip';
import Humanize from 'humanize-plus';

const BCTable = ({ columns, data, sx, usePagination = true }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (e, page) => {
        setPage(page)
    }

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0)
    }

    const paginate = (data) => {
        return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }

    const createData = (data) => {
        let tableData = []

        for (let i = 0; i < data.length; i++) {
            tableData[i] = {}
            for (let column of columns) {
                // Add general columns
                tableData[i][column.name] = {}
                if (column.name in data[i]) {
                    switch (column.type) {
                        case 'date':
                            tableData[i][column.name]['data'] = data[i][column.name] ? moment(data[i][column.name]).format(column.format) : null
                            break
                        // Amount type for assets: either read from 'precision' column, or specify custom precision
                        case 'amount':
                            let precision
                            if (column.precison) {
                                precision = column.precison
                            } else {
                                precision =  data[i].precision ?? 0
                            }
                            tableData[i][column.name]['data'] = Humanize.formatNumber(data[i][column.name], precision)
                            break
                        default:
                            // Use a custom render, or stick with the default one
                            if (column.render) {
                                tableData[i][column.name]['data'] = column.render(data[i])
                            } else {
                                tableData[i][column.name]['data'] = data[i][column.name]
                            }
                    }

                    // extra data
                    if (column.extraInfoColumn) {
                        tableData[i][column.name]['extra'] = data[i][column.extraInfoColumn]
                    }
                }

                // Add action columns
                if (column.type === 'actions') {
                    tableData[i][column.name]['data'] = column.render(data[i])
                }
            }
        }

        if (usePagination && tableData.length > 10) {
            return paginate(tableData)
        }

        return tableData
    }

    return (
        <>
            <TableContainer sx={sx}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {
                                columns.map((column, i) => (
                                    <TableCell key={i} sx={{ border: 0, color: 'gray' }}>
                                        {column.label}
                                        {
                                            column.tooltip &&
                                            <HelpTooltip title={column.tooltip} />
                                        }
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && createData(data).map((row, i) => (
                            <TableRow
                                key={i}
                                sx={{ border: 0 }}
                            >
                                {
                                    Object.keys(row).map((column, j) => {
                                        return (
                                            <TableCell key={j} sx={{ border: 0 }} >
                                                { columns[j]?.bold ? <b>{row[column].data}</b> : row[column].data }
                                                {
                                                    row[column].extra &&
                                                    <Typography variant='caption' as='p' sx={{ color: 'gray' }}>{row[column].extra}</Typography>
                                                }
                                            </TableCell>
                                        )
                                    })
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                (usePagination && data && data.length > 10) &&
                <TablePagination
                    component="div"
                    count={data.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            }
        </>
    )
}

export default BCTable