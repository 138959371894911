import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import Paper from '../../../components/StyledPaper';
import useThreads from '../../../api/messaging/useThreads';
import CircularLoader from '../../../components/CircularLoader';
import * as config from './../../../config';
import dompurify from 'dompurify';

import { formatDateTimeForChat } from '../../../utilities/time';
import useMessages from '../../../api/messaging/useMessages';
import { Editor } from '@tinymce/tinymce-react';
import request from '../../../utilities/request';
import useCommunicationUsers from '../../../api/messaging/useCommunicationUsers';
import useUnreadMessages from '../../../api/messaging/useUnreadMessages';
import LockedTile from '../../../components/LockedTile';
import { SUBSCRIPTION_PRO } from '../../../constants/profile';
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    chatSection: {
        width: '100%',
        // height: '100vh'
    },
    headBG: {
        backgroundColor: '#e0e0e0',
    },
    borderRight500: {
        borderRight: '1px solid #e0e0e0',
    },
    messageArea: {
        height: '60vh',
        overflowY: 'auto',
    },
    buttonLabel: {
        color: '#3E9CD9',
        cursor: 'pointer',
        marginBottom: '1rem',
        textTransform: 'none',
        fontSize: '1rem',
    },
    secondaryButtonLabel: {
        color: '#000000',
        cursor: 'pointer',
        marginBottom: '1rem',
        textTransform: 'none',
        fontSize: '1rem',
    },
});

const Chat = () => {
    const classes = useStyles();
    const [recipient, setRecipient] = useState(null);
    const messagesEndRef = useRef(null);

    const [message, setMessage] = useState(null);
    const [file, setFile] = useState(null);
    const [editorKey, setEditorKey] = useState(1);
    const [loading, setLoading] = useState(false);

    const { updateUnreadMessages } = useUnreadMessages();
    const {
        threads,
        threadsLoading,
        threadsError,
        updateThreads,
    } = useThreads();
    const { commsUsers, commsUsersLoading } = useCommunicationUsers();
    const {
        messages,
        messagesLoading,
        messagesError,
        updateMessages,
    } = useMessages(recipient?.user_id);

    const handleEditorChange = (content) => {
        setMessage(content);
    };

    const uploadFile = (file, type) => {
        setFile(file);
    };

    const removeFile = () => {
        setFile(null);
    };

    /*const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };*/

    useEffect(() => {
        //scrollToBottom();
    }, [messages]);

    const downloadFile = (fileName, filePath) => {
        const link = document.createElement('a');
        link.href = filePath;
        link.target = '_blank';
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Open a user and refresh messages
    const openChat = (user) => {
        setRecipient(user);
        setTimeout(() => {
            updateUnreadMessages();
            updateThreads();
        }, 1000);
    };

    const submitChat = () => {
        let formData = new FormData();
        formData.append('message', message);
        if (file) {
            formData.set('document', file, file.name);
        }

        setLoading(true);
        request
            .post(
                `${config.API_BASE_URL}/messages/${recipient?.user_id}`,
                formData
            )
            .then((response) => {
                updateMessages();
                updateThreads();
                setFile(null);
                setMessage(null);
                setEditorKey(editorKey * 2);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <LockedTile requiredPlan={SUBSCRIPTION_PRO}>
            <Paper>
                <Grid container className={classes.chatSection}>
                    <Grid item xs={3} className={classes.borderRight500}>
                        {recipient && (
                            <List>
                                <ListItem button key="">
                                    <ListItemIcon>
                                        <Avatar alt={recipient.user}>
                                            {recipient.user
                                                .split(' ')
                                                .map((n) => n[0])}
                                        </Avatar>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={recipient.user}
                                    ></ListItemText>
                                </ListItem>
                            </List>
                        )}
                        <Divider />
                        {/* User search */}
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            {!commsUsersLoading && commsUsers && (
                                <Autocomplete
                                    id="search-commns"
                                    options={commsUsers}
                                    autoHighlight
                                    fullWidth
                                    clearOnBlur
                                    getOptionLabel={(option) =>
                                        option.company_name
                                            ? option.company_name
                                            : option.user
                                    }
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            sx={{
                                                '& > img': {
                                                    mr: 2,
                                                    flexShrink: 0,
                                                },
                                            }}
                                            {...props}
                                        >
                                            <span
                                                onClick={() =>
                                                    setRecipient(option)
                                                }
                                            >
                                                {option.company_name}
                                                <br />
                                                <Typography variant="caption">
                                                    Rep: {option.user}
                                                </Typography>
                                                <br />
                                                <Typography variant="caption">
                                                    {option.user_id === 3
                                                        ? 'transfer agent'
                                                        : 'issuer'}
                                                </Typography>
                                            </span>
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Search..."
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                        <Divider />
                        <List>
                            {/* Threads list */}
                            {threadsError && (
                                <Typography variant="body2">
                                    Could not load message threads...
                                </Typography>
                            )}
                            {threadsLoading && !threadsError && (
                                <CircularLoader />
                            )}
                            {threads &&
                                threads.map((thread, i) => (
                                    <ListItem
                                        button
                                        key={i}
                                        onClick={() =>
                                            openChat(thread.participants[0])
                                        }
                                        secondaryAction={
                                            thread.unread > 0 && (
                                                <Chip
                                                    color="danger"
                                                    label={thread?.unread}
                                                />
                                            )
                                        }
                                    >
                                        <ListItemIcon>
                                            <Avatar
                                                alt={
                                                    thread.participants &&
                                                    thread.participants.length >
                                                        0
                                                        ? thread.participants[0]
                                                              .user
                                                        : ''
                                                }
                                            >
                                                {thread.participants &&
                                                thread.participants.length > 0
                                                    ? thread.participants[0].user
                                                          .split(' ')
                                                          .map((n) => n[0])
                                                    : ''}
                                            </Avatar>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                thread.participants &&
                                                thread.participants.length > 0
                                                    ? thread.participants[0]
                                                          .user
                                                    : ''
                                            }
                                            secondary={formatDateTimeForChat(
                                                thread.updated_at
                                            )}
                                        />
                                    </ListItem>
                                ))}
                        </List>
                    </Grid>
                    <Grid item xs={9}>
                        <List
                            className={classes.messageArea}
                            sx={{ paddingLeft: '20px' }}
                        >
                            {/* No participans selected */}
                            {!recipient && (
                                <Typography align="center" variant="body1">
                                    Click on a user to view your conversations.
                                </Typography>
                            )}
                            {/* Errors and message loading */}
                            {messagesError && (
                                <Typography align="center" variant="body2">
                                    Could not load messages in the thread...
                                    <br />
                                    Please try again.
                                </Typography>
                            )}
                            {messagesLoading && !messagesError && (
                                <CircularLoader />
                            )}
                            {messages &&
                                messages.map((message, i) => {
                                    let style =
                                        message.user_id !== recipient?.user_id
                                            ? {
                                                  background: 'lightblue',
                                                  borderRadius: '1em',
                                                  marginBottom: '1em',
                                              }
                                            : { marginBottom: '1em' };
                                    let align =
                                        message.user_id !== recipient?.user_id
                                            ? 'right'
                                            : 'left';
                                    return (
                                        <ListItem key={i} sx={style}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    {message.message !==
                                                        'null' &&
                                                        message.message !==
                                                            null && (
                                                            <ListItemText
                                                                align={align}
                                                                primary={
                                                                    <div
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: dompurify.sanitize(
                                                                                message.message,
                                                                                {
                                                                                    FORCE_BODY: true,
                                                                                }
                                                                            ),
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        )}
                                                </Grid>
                                                {
                                                    // handle files
                                                    message.file_path && (
                                                        <ListItemText
                                                            align={align}
                                                            primary={
                                                                <Chip
                                                                    icon={
                                                                        <DownloadIcon />
                                                                    }
                                                                    label={
                                                                        message.file_name
                                                                    }
                                                                    onClick={() =>
                                                                        downloadFile(
                                                                            message.file_name,
                                                                            message.file_path
                                                                        )
                                                                    }
                                                                />
                                                            }
                                                        />
                                                    )
                                                }
                                                <Grid item xs={12}>
                                                    <ListItemText
                                                        align={align}
                                                        secondary={formatDateTimeForChat(
                                                            message.created_at
                                                        )}
                                                    ></ListItemText>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    );
                                })}
                            {messages && <ListItem ref={messagesEndRef} />}
                        </List>
                        <Divider />
                        {
                            // Disable text area until a user is selected.
                            recipient && (
                                <Grid container style={{ padding: '20px' }}>
                                    <Grid item xs={12}>
                                        <Editor
                                            key={editorKey}
                                            disabled={loading}
                                            apiKey="uf9matrx4ivq9fcl3zpum8qtj7b4bb79w95bmuwn5d2hkhyi"
                                            // initialValue="<p></p>"
                                            init={{
                                                height: 200,
                                                menubar: false,
                                                forced_root_block: 'div',
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount',
                                                ],
                                                toolbar:
                                                    'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                                            }}
                                            onEditorChange={handleEditorChange}
                                        />
                                        <br />
                                        <Button
                                            variant="outlined"
                                            className={classes.buttonLabel}
                                            color="primary"
                                            startIcon={
                                                loading ? (
                                                    <ScheduleSendIcon />
                                                ) : (
                                                    <SendIcon />
                                                )
                                            }
                                            disabled={
                                                (!message && !file) || loading
                                            }
                                            onClick={submitChat}
                                        >
                                            Send
                                        </Button>
                                        <Button
                                            className={classes.buttonLabel}
                                            color="primary"
                                            name="document"
                                            disabled={loading}
                                            startIcon={<AttachFileIcon />}
                                        >
                                            <input
                                                onChange={(e) => {
                                                    uploadFile(
                                                        e.target.files[0]
                                                    );
                                                }}
                                                style={{ display: 'none' }}
                                                type="file"
                                            />
                                            <b>Attach File</b>
                                        </Button>
                                        {file && (
                                            <Typography
                                                component="p"
                                                variant="body2"
                                            >
                                                <br />
                                                File to upload:{' '}
                                                <b>{file.name}</b>
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={removeFile}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Paper>
        </LockedTile>
    );
};

export default Chat;
