import React from 'react';
import { Form } from 'formik';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import authStyles from '../../authStyles';
import Typography from '@mui/material/Typography';
import fundraiseStyles from '../../../../components/styles/fundraiseOfferingStyles';
const Congratulations = (props) => {
    const classes = authStyles();
    const offeringFundraiseClasses = fundraiseStyles();

    const closeDialog = () => {
        props.closeDialog();
    };

    return (
        <div id="step5">
            <Container maxWidth="lg">
                <Form className={classes.form} noValidate>
                    <Typography component="div">
                        <h3>Congratulations,</h3>
                    </Typography>
                    <Typography component="div">
                        <h3>
                            Your investment commitment is in! We will email you
                            a confirmation and will let you know as soon as your
                            funds have been received.
                        </h3>
                    </Typography>

                    <Typography component="div">
                        <h3>
                            <br />
                            Sincerely, <br />
                            <br />
                            Jimmy Bingham
                            <br /> CEO
                            <br /> World Wide Residential
                        </h3>
                    </Typography>

                    <div className={offeringFundraiseClasses.actions}>
                        <Button
                            variant="contained"
                            className={classes.buttonWithAlignment}
                            size="small"
                            onClick={closeDialog}
                        >
                            Close
                        </Button>
                    </div>
                </Form>
            </Container>
        </div>
    );
};

export default Congratulations;
