import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useSnackbar } from 'notistack';
import * as config from './../../../config';
import request from '../../../utilities/request';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DataTable from '../../../components/DataTable/MuiDataTable';
import CircularProgress from '@mui/material/CircularProgress';
import * as transferAgentActions from '../../../store/transferAgent/transferAgentActions';
import * as capTableActions from '../../../store/capTable/capTableActions';
import authStyles from '../../issuer/authStyles';
import LockedTile from '../../../components/LockedTile';
import { SUBSCRIPTION_PRO } from '../../../constants/profile';
import { Editor } from '@tinymce/tinymce-react';
import TextField from '../../../components/TextField/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import HelpTooltip from '../../../components/HelpTooltip/HelpTooltip';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    //Badge,
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import WarningIcon from '@mui/icons-material/Warning';
import EmailIcon from '@mui/icons-material/Email';

const TAPartners = (props) => {
    const classes = authStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [requestDialog, setRequestDialog] = useState(false);
    const [message, setMessage] = useState('');
    const [transferAgent, setTransferAgent] = useState(null);
    const [company, setCompany] = useState(null);

    useEffect(() => {
        if (props.profile) {
            setCompany(props.profile.company_name);
        }
    }, [props.profile]);

    useEffect(props.capTableActions.getClasses, []);
    const [shareClass, setShareClass] = React.useState([]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    useEffect(() => props.transferAgentActions.getTaList(), [
        props.transferAgentActions,
    ]);

    const displayRequestDialog = (transferAgent) => {
        capTableActions.getClasses();
        setTransferAgent(transferAgent);
        setRequestDialog(true);
        setShareClass(
            props.classes && props.classes.length > 0
                ? [props.classes[0].class_description]
                : []
        );
    };

    const closeRequestDialog = () => {
        setRequestDialog(false);
    };

    const handleEditorChange = (content) => {
        setMessage(content);
    };

    const handleShareClassChange = (
        event: SelectChangeEvent<typeof shareClass>
    ) => {
        const {
            target: { value },
        } = event;
        setShareClass(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };

    return (
        <div>
            {' '}
            {/*<Badge
                style={{ width: '115px', float: 'right', fontSize: '14px' }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                color="danger"
                badgeContent={'Coming Soon'}
            ></Badge>*/}
            <LockedTile requiredPlan={SUBSCRIPTION_PRO}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {props.loading || isSubmitting ? (
                            <Grid
                                container
                                item
                                xs={12}
                                justifyContent="center"
                            >
                                <CircularProgress />
                            </Grid>
                        ) : (
                            <DataTable
                                title={'TA Partners'}
                                data={
                                    props.transferAgents &&
                                    props.transferAgents.data
                                        ? props.transferAgents.data
                                        : []
                                }
                                columns={[
                                    {
                                        label: 'Name',
                                        name: 'company_name',
                                    },
                                    {
                                        label: 'Actions',
                                        name: 'id',
                                        options: {
                                            download: false,
                                            print: false,
                                            customBodyRenderLite: (
                                                dataIndex
                                            ) => {
                                                const row =
                                                    props.transferAgents.data[
                                                        dataIndex
                                                    ];
                                                return (
                                                    <React.Fragment>
                                                        <Button
                                                            style={{
                                                                marginTop:
                                                                    '1rem',
                                                            }}
                                                            variant="outlined"
                                                            className={
                                                                classes.buttonLabel
                                                            }
                                                            size="small"
                                                            disabled={
                                                                row[
                                                                    'ta_partnership_status'
                                                                ] === 'sent'
                                                            }
                                                            onClick={() => {
                                                                displayRequestDialog(
                                                                    row
                                                                );
                                                            }}
                                                        >
                                                            Send Partnership
                                                            Request
                                                        </Button>
                                                        <IconButton
                                                            aria-label="Message"
                                                            title="Send a message"
                                                        >
                                                            <EmailIcon />
                                                        </IconButton>
                                                    </React.Fragment>
                                                );
                                            },
                                        },
                                    },
                                    {
                                        label: 'Partnership Status',
                                        name: 'ta_partnership_status',
                                        options: {
                                            customBodyRender: (value) => (
                                                <div>
                                                    {value
                                                        ? value.toUpperCase()
                                                        : ''}
                                                </div>
                                            ),
                                        },
                                    },
                                ]}
                            />
                        )}
                    </Grid>
                    <Dialog
                        maxWidth="sm"
                        fullWidth
                        open={requestDialog}
                        onBackdropClick={(e) => setRequestDialog(false)}
                    >
                        <DialogTitle className={classes.dialogTitle}>
                            Send request to{' '}
                            {transferAgent && transferAgent.company_name
                                ? transferAgent.company_name
                                : ''}
                        </DialogTitle>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12} md={12}>
                                    <Formik
                                        initialValues={{
                                            subject: '',
                                            message: message,
                                        }}
                                        //validationSchema={}
                                        onSubmit={(
                                            values,
                                            { setSubmitting }
                                        ) => {
                                            if (
                                                values['subject'] !== null &&
                                                values['subject'] !==
                                                    undefined &&
                                                values['subject'] !== '' &&
                                                message !== null &&
                                                message !== undefined &&
                                                message !== '' &&
                                                shareClass.length !== 0
                                            ) {
                                                setIsSubmitting(true);
                                                let shareClassIds = [];

                                                shareClass.forEach(
                                                    (selectedClass) => {
                                                        let id = props.classes.filter(
                                                            (element) => {
                                                                return (
                                                                    element.class_description ===
                                                                    selectedClass
                                                                );
                                                            }
                                                        )[0].id;
                                                        shareClassIds.push(id);
                                                    }
                                                );

                                                request
                                                    .post(
                                                        `${config.API_BASE_URL}/transfer-agents/partnership`,
                                                        {
                                                            transfer_agent_id:
                                                                transferAgent.id,
                                                            subject:
                                                                values[
                                                                    'subject'
                                                                ],
                                                            message: message,
                                                            company: company,
                                                            share_class_ids: shareClassIds,
                                                        }
                                                    )
                                                    .then((response) => {
                                                        props.transferAgentActions.getTaList();
                                                        setIsSubmitting(false);
                                                        setRequestDialog(false);
                                                        enqueueSnackbar(
                                                            'Partnership request sent successfully',
                                                            {
                                                                variant:
                                                                    'success',
                                                            }
                                                        );
                                                    })
                                                    .catch((error) => {
                                                        setSubmitting(false);
                                                        enqueueSnackbar(
                                                            error.response.data
                                                                .message,
                                                            {
                                                                variant:
                                                                    'error',
                                                            }
                                                        );
                                                    });
                                            } else {
                                                setSubmitting(false);
                                                enqueueSnackbar(
                                                    'Please enter subject and message, select one or many classes',
                                                    {
                                                        variant: 'error',
                                                    }
                                                );
                                            }
                                        }}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form
                                                className={classes.form}
                                                noValidate
                                            >
                                                <Grid container spacing={1}>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                        md={6}
                                                    >
                                                        <InputLabel
                                                            className={
                                                                classes.label
                                                            }
                                                            htmlFor="company"
                                                        >
                                                            Select company{' '}
                                                            <HelpTooltip
                                                                color="#3e9cd9"
                                                                title="If you have multiple companies, please select the company"
                                                            />
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            value={company}
                                                            onChange={(e) =>
                                                                setCompany(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            fullWidth
                                                        >
                                                            {props.profile &&
                                                            props.profile
                                                                .company_name ? (
                                                                <MenuItem
                                                                    value={
                                                                        props
                                                                            .profile
                                                                            .company_name
                                                                    }
                                                                >
                                                                    {
                                                                        props
                                                                            .profile
                                                                            .company_name
                                                                    }
                                                                </MenuItem>
                                                            ) : (
                                                                <MenuItem
                                                                    disabled
                                                                    value={null}
                                                                >
                                                                    No Data
                                                                </MenuItem>
                                                            )}
                                                        </Select>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                        md={6}
                                                    >
                                                        <InputLabel
                                                            className={
                                                                classes.label
                                                            }
                                                            htmlFor="share_class"
                                                        >
                                                            Asset Class{' '}
                                                            <HelpTooltip
                                                                color="#3e9cd9"
                                                                title="Please specify which Class from cap table should the TA have access to"
                                                            />
                                                        </InputLabel>

                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            multiple
                                                            value={shareClass}
                                                            onChange={
                                                                handleShareClassChange
                                                            }
                                                            renderValue={(
                                                                selected
                                                            ) =>
                                                                selected.join(
                                                                    ', '
                                                                )
                                                            }
                                                            MenuProps={
                                                                MenuProps
                                                            }
                                                            fullWidth
                                                        >
                                                            {props.classes
                                                                .length > 0 &&
                                                                props.classes.map(
                                                                    (name) => (
                                                                        <MenuItem
                                                                            key={
                                                                                name.class_description
                                                                            }
                                                                            value={
                                                                                name.class_description
                                                                            }
                                                                        >
                                                                            <Checkbox
                                                                                checked={
                                                                                    shareClass.indexOf(
                                                                                        name.class_description
                                                                                    ) >
                                                                                    -1
                                                                                }
                                                                            />
                                                                            <ListItemText
                                                                                primary={
                                                                                    name.class_description
                                                                                }
                                                                            />
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                        </Select>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <Grid container>
                                                    {props.classes.length ===
                                                    0 ? (
                                                        <span>
                                                            <WarningIcon />{' '}
                                                            <span
                                                                className={
                                                                    classes.redText
                                                                }
                                                            >
                                                                no cap table
                                                                detected! please
                                                                upload or create
                                                                your cap table
                                                                first, in order
                                                                to initiate
                                                                partnership
                                                                request
                                                            </span>
                                                        </span>
                                                    ) : null}
                                                </Grid>
                                                <br />
                                                <Grid container>
                                                    <InputLabel
                                                        className={
                                                            classes.label
                                                        }
                                                        htmlFor="message"
                                                    >
                                                        To
                                                    </InputLabel>
                                                </Grid>
                                                <span>
                                                    {transferAgent &&
                                                    transferAgent.contact_email
                                                        ? transferAgent.contact_email
                                                        : 'N/A'}
                                                </span>
                                                <br />
                                                <br />
                                                <Grid container>
                                                    <InputLabel
                                                        className={
                                                            classes.label
                                                        }
                                                        htmlFor="subject"
                                                    >
                                                        Subject
                                                    </InputLabel>
                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        name={'subject'}
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid container>
                                                    <InputLabel
                                                        className={
                                                            classes.label
                                                        }
                                                        htmlFor="message"
                                                    >
                                                        Message
                                                    </InputLabel>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                >
                                                    <Editor
                                                        apiKey="uf9matrx4ivq9fcl3zpum8qtj7b4bb79w95bmuwn5d2hkhyi"
                                                        initialValue="<p></p>"
                                                        name="message"
                                                        init={{
                                                            height: 250,
                                                            menubar: false,
                                                            plugins: [
                                                                'advlist autolink lists link image charmap print preview anchor',
                                                                'searchreplace visualblocks code fullscreen',
                                                                'insertdatetime media table paste code help wordcount',
                                                            ],
                                                            toolbar:
                                                                'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                                                        }}
                                                        onEditorChange={
                                                            handleEditorChange
                                                        }
                                                    />
                                                </Grid>
                                                <DialogActions
                                                    className={classes.actions}
                                                >
                                                    <Button
                                                        color="secondary"
                                                        variant="outlined"
                                                        className={
                                                            classes.secondaryButtonLabel
                                                        }
                                                        onClick={() => {
                                                            closeRequestDialog();
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        variant="outlined"
                                                        className={
                                                            classes.buttonLabel
                                                        }
                                                        type="submit"
                                                        disabled={
                                                            props.classes
                                                                .length === 0
                                                        }
                                                    >
                                                        {!isSubmitting ? (
                                                            <span>Send</span>
                                                        ) : (
                                                            <CircularProgress
                                                                size={24}
                                                            />
                                                        )}
                                                    </Button>
                                                </DialogActions>
                                            </Form>
                                        )}
                                    </Formik>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </Grid>
            </LockedTile>
        </div>
    );
};

const mapStateToProps = ({ profile, transferAgent, capTable }) => {
    return {
        profile: profile,
        transferAgents: transferAgent.taList,
        loading: transferAgent.taList.loading,
        classes: capTable.classes.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        transferAgentActions: bindActionCreators(
            transferAgentActions,
            dispatch
        ),
        capTableActions: bindActionCreators(capTableActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TAPartners);
