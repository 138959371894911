import React, { useState } from 'react';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';

import TabPanel from '../../components/TabPanel';
import SubMenu from '../../components/SubMenu';
import Overview from '../../pages/issuer/Trade/Overview';
import History from '../../pages/issuer/Trade/History';

import VerticalContainer from '../../components/VerticalContainer';
import { TRADE_ROUTES } from './routeList';

const TradeRoutes = (props) => {
    let { path } = useRouteMatch();
    const location = useLocation();

    const getActiveTab = () => {
        let index = TRADE_ROUTES.indexOf(location.pathname);
        return index >= 0 ? index : 0;
    };

    const tabs = [
        {
            label: 'Overview',
            path: `${path}`,
            display: true,
        },
        {
            label: 'History',
            path: `${path}/history`,
            display: true,
        },
    ];

    const [value, setValue] = useState(getActiveTab());
    return (
        <VerticalContainer>
            <SubMenu
                orientation="vertical"
                value={value}
                onChange={(e, v) => setValue(v)}
                tabs={tabs}
                title="Trade"
            />
            <TabPanel>
                <Switch>
                    <Route exact path={`${path}`} component={Overview} />
                    <Route exact path={`${path}/history`} component={History} />
                </Switch>
            </TabPanel>
        </VerticalContainer>
    );
};

export default TradeRoutes;
