import makeStyles from '@mui/styles/makeStyles';

const issuerRouteStyles = makeStyles((theme) => ({
    divider: {
        marginBottom: '2rem',
        borderBottomWidth: 2,
    },
}));

export default issuerRouteStyles;
