import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import BaseTableCell from './BaseTableCell';
import Tooltip from '../../../Tooltip';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    content: {
        position: 'absolute',
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    spacer: {
        height: 0,
        overflow: 'hidden',
        display: 'none',
    },
}));

export default function TruncatedTableCell(props) {
    const { col, item } = props;
    const classes = useStyles();

    const copyToClipboard = (event, item) => {
        event.preventDefault();
        event.stopPropagation();
        if (col.copy) {
            navigator.clipboard.writeText(item.toString());
        }
    };

    return (
        <BaseTableCell col={col} item={item}>
            <Box className={classes.container}>
                <Tooltip
                    title={
                        item?.tooltipValue ||
                        item[col.tooltipValue] ||
                        item[col.field]
                    }
                    placement="top"
                    disableHoverListener={!col.useTooltip}
                >
                    <Box
                        className={classes.content}
                        onClick={(e) => copyToClipboard(e, item[col.field])}
                    >
                        {item[col.field] || 'N/A'}
                    </Box>
                </Tooltip>
                <Box className={classes.spacer}>{item[col.field] || 'N/A'}</Box>
                <span>&nbsp;</span>
            </Box>
        </BaseTableCell>
    );
}
