import request from '../../utilities/request';
import * as config from '../../config';
import * as fundingTypes from './fundingTypes';

export const getFundingSuccess = (summary) => {
    return {
        type: fundingTypes.GET_FUNDING_SUCCESS,
        payload: summary,
    };
};

export const getOfferingsSuccess = (summary) => {
    return {
        type: fundingTypes.GET_OFFERINGS_SUCCESS,
        payload: summary,
    };
};

export const setFundingLoading = (loading) => {
    return {
        type: fundingTypes.SET_FUNDING_LOADING,
        payload: loading,
    };
};

export const setOfferingsLoading = (loading) => {
    return {
        type: fundingTypes.SET_OFFERINGS_LOADING,
        payload: loading,
    };
};

export const getFundInvestorDocuments = () => {
    return (dispatch) => {
        dispatch(setFundingLoading(true));
        request
            .get(`${config.API_BASE_URL}/fundraise/investor-documents`)
            .then((res) => {
                dispatch(getFundingSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setFundingLoading(false));
            });
    };
};

export const getOfferings = () => {
    return (dispatch) => {
        dispatch(setOfferingsLoading(true));
        request
            .get(`${config.API_BASE_URL}/fundraise/offerings`)
            .then((res) => {
                dispatch(getOfferingsSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setOfferingsLoading(false));
            });
    };
};
