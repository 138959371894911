import axios from 'axios';
import {
    getAccessToken,
    setAccessToken,
    getRefreshToken,
    setRefreshToken,
    resetSession,
} from './auth';
import * as config from '../config';

const request = axios.create();

const cache = {
    skipInstances: [],
    refreshCall: undefined,
    requestQueueInterceptorId: undefined,
};

function refreshToken(request) {
    return axios
        .post(`${config.API_BASE_URL}/auth/token/`, {
            refresh_token: getRefreshToken(),
        })
        .then((response) => {
            const { access_token, refresh_token } = response.data.data;

            setAccessToken(access_token);
            setRefreshToken(refresh_token);

            request.config.headers['Content-Type'] = 'application/json';
            request.config.headers['Accept'] = 'application/json';
            request.config.headers[
                'Authorization'
            ] = `Bearer ${getAccessToken()}`;

            return Promise.resolve(true);
        })
        .catch(() => {
            resetSession();

            window.location.replace('/login');
        });
}

function createAuthRefreshInterceptor(instance, refreshAuthCall) {
    return instance.interceptors.response.use(
        (res) => {
            return res;
        },
        (error) => {
            const options = {
                instance: undefined,
                skipWhileRefreshing: false,
            };

            if (!shouldInterceptError(error, options, instance, cache)) {
                return Promise.reject(error);
            }

            cache.skipInstances.push(instance);
            const refreshing = createRefreshCall(error, refreshAuthCall, cache);

            createRequestQueueInterceptor(instance, cache);

            return refreshing
                .finally(() => {
                    cache.refreshCall = undefined;
                    instance.interceptors.request.eject(
                        cache.requestQueueInterceptorId
                    );
                    cache.skipInstances = cache.skipInstances.filter(
                        (item) => item !== instance
                    );
                    cache.requestQueueInterceptorId = undefined;
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
                .then(() => {
                    error.config.headers['Content-Type'] = 'application/json';
                    error.config.headers['Accept'] = 'application/json';
                    error.config.headers[
                        'Authorization'
                    ] = `Bearer ${getAccessToken()}`;
                    error.config.skipAuthRefresh = true;
                    return axios(error.response.config);
                });
        }
    );
}

function shouldInterceptError(error, options, instance, cache) {
    return (
        error &&
        !(error.config && error.config.skipAuthRefresh) &&
        error.response &&
        error.response.data &&
        error.response.data.status === 403 &&
        !(options.skipWhileRefreshing && cache.skipInstances.includes(instance))
    );
}

function createRefreshCall(error, fn, cache) {
    if (!cache.refreshCall) {
        cache.refreshCall = fn(error);
        if (typeof cache.refreshCall.then !== 'function') {
            return Promise.reject();
        }
    }
    return cache.refreshCall;
}

function createRequestQueueInterceptor(instance, cache) {
    if (typeof cache.requestQueueInterceptorId === 'undefined') {
        cache.requestQueueInterceptorId = instance.interceptors.request.use(
            (request) => {
                return cache.refreshCall
                    .catch(() => {
                        throw new axios.Cancel('Request call failed');
                    })
                    .then(() => {
                        return request;
                    });
            }
        );
    }
    return cache.requestQueueInterceptorId;
}

createAuthRefreshInterceptor(request, refreshToken);

request.interceptors.request.use((request) => {
    request.headers['Content-Type'] = 'application/json';
    request.headers['Accept'] = 'application/json';
    request.headers['Authorization'] = `Bearer ${getAccessToken()}`;
    return request;
});

export default request;
