import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Button,
    Select,
    FormControl,
    MenuItem,
    InputLabel,
    Modal,
    TextField as TextInput,
} from '@mui/material';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';

import StyledPaper from '../../../StyledPaper';
import { greenWallet } from '../../api';
import CircularLoader from '../../../CircularLoader';
import Settings from './Settings';
import { modalStyles } from '../../../styles/modalStyles';
import { snackbar } from '../../../../utilities/snackbarUtils';
import TextField from '../../../TextField/TextField';
import { User, Account } from './index';

const validationSchema = Yup.object({
    amount: Yup.number()
        .required('Please enter an Amount.')
        .min(1, 'Minimal amount is 1')
        .max(2100000000000000, 'Maximum amount is 2100000000000000')
        .notOneOf(['0'], 'The Amount cannot be 0.'),
    assetId: Yup.string().required('Please enter Asset Id.'),
    address: Yup.string().required('Please enter Address.'),
});

const Wallet = ({ logout }) => {
    const classes = modalStyles();
    const [wallet, setWallet] = useState({ loading: false });
    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState();
    const [createModal, setCreateModal] = useState(false);
    const [accountName, setAccountName] = useState('');
    const [creationLoading, setCreationLoading] = useState(false);
    const [currencies, setCurrencies] = useState([]);

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('walletData'));
        setWallet({ name: data.wallet, pin: data.pin, loading: false });
    }, []);

    useEffect(() => {
        if (wallet.name) {
            getAccountsData();
        }
    }, [wallet.name]);
    const getAccountsData = async () => {
        const accountCreds = {
            wallet: wallet.name,
            pin: wallet.pin,
        };
        setWallet({ ...wallet, loading: true });
        await getAccounts();
        const walletSettings = (
            await greenWallet.getSettings({
                ...accountCreds,
            })
        ).data;
        await getCurrencies(accountCreds);
        setWallet({
            ...wallet,
            settings: walletSettings,
            loading: false,
        });
    };

    const getAccounts = async () => {
        const res = (
            await greenWallet.getAccounts({
                wallet: wallet.name,
                pin: wallet.pin,
            })
        ).data;
        if (res.length && selectedAccount?.gaid !== res[0].gaid) {
            setSelectedAccount(res[0]);
        }
        setAccounts(res);
    };

    const getCurrencies = async (creds) => {
        const res = (await greenWallet.getCurrencies({ ...creds })).data;
        const currencies = [...new Set(res.map((item) => item.currency))];
        const formatted = currencies.map((item) => ({
            currency: item,
            exchange: res.reduce(
                (previousValue, currentValue) =>
                    currentValue.currency === item
                        ? [...previousValue, currentValue.exchange]
                        : previousValue,
                []
            ),
        }));
        setCurrencies(formatted);
    };

    const changeAccount = (e) => {
        setSelectedAccount(accounts.find((item) => item.id === e.target.value));
    };

    const createAccount = async () => {
        if (accountName.trim()) {
            setCreationLoading(true);
            await greenWallet
                .createAccount({
                    name: accountName,
                    wallet: wallet.name,
                    pin: wallet.pin,
                })
                .finally(() => setCreationLoading(false));
            await getAccounts();
            setCreateModal(false);
            setAccountName('');
            setCreationLoading(false);
        } else {
            snackbar.error('Please enter Account Name!');
        }
    };

    return (
        <>
            <StyledPaper elevation={3} marginBottom="20px">
                <Box
                    display="flex"
                    justifyContent="space-around"
                    padding={2}
                    alignItems="center"
                >
                    <Box flexGrow="1">
                        <Typography variant="h5">{wallet.name}</Typography>
                    </Box>
                    {!!accounts.length && (
                        <Box flexGrow="0.2">
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="account">Account</InputLabel>
                                <Select
                                    labelId="account"
                                    value={selectedAccount?.id}
                                    onChange={changeAccount}
                                    autoWidth
                                    label="Account"
                                >
                                    {accounts.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.gaid}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    )}
                    <Box ml={2}>
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={logout}
                        >
                            Logout
                        </Button>
                    </Box>
                </Box>
                {selectedAccount && (
                    <>
                        <Box my={2}>
                            <Typography variant="body1" textAlign="center">
                                Account Info
                            </Typography>
                        </Box>
                        <Account
                            creds={{
                                wallet: wallet.name,
                                pin: wallet.pin,
                            }}
                            account={selectedAccount}
                        />
                    </>
                )}
                <Box display="flex" justifyContent="center" mt={2}>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => setCreateModal(true)}
                    >
                        Create Account
                    </Button>
                </Box>
            </StyledPaper>
            <StyledPaper elevation={3} marginBottom="20px">
                <Typography variant="body1" textAlign="center">
                    User
                </Typography>
                <User gaid={selectedAccount?.gaid} />
            </StyledPaper>
            <StyledPaper elevation={3} marginBottom="20px">
                <Typography variant="body1" textAlign="center">
                    Send asset from account to address
                </Typography>
                <Formik
                    initialValues={{
                        amount: null,
                        assetId: '',
                        address: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(true);
                        await greenWallet.sendAsset(selectedAccount.id, {
                            ...values,
                            wallet: wallet.name,
                            pin: wallet.pin,
                        });
                        setSubmitting(false);
                    }}
                >
                    {({ submitForm, isSubmitting }) => (
                        <Form>
                            <Box
                                display="flex"
                                alignItems="center"
                                flexDirection="column"
                            >
                                <Field
                                    component={TextField}
                                    name="amount"
                                    label="Amount"
                                    type="number"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />
                                <Field
                                    component={TextField}
                                    name="assetId"
                                    label="Asset Id"
                                    type="text"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />
                                <Field
                                    component={TextField}
                                    name="address"
                                    label="Address"
                                    type="text"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />

                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={submitForm}
                                    disabled={isSubmitting}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </StyledPaper>
            <StyledPaper elevation={3} marginBottom="20px">
                {wallet.loading ? (
                    <CircularLoader />
                ) : (
                    <>
                        <Typography variant="body1" textAlign="center">
                            Settings
                        </Typography>
                        {wallet.settings && (
                            <Settings
                                currencies={currencies}
                                settings={wallet.settings}
                                wallet={{ name: wallet.name, pin: wallet.pin }}
                            />
                        )}
                    </>
                )}
            </StyledPaper>
            <Modal
                className={classes.modal}
                open={createModal}
                onClose={() => setCreateModal(false)}
            >
                <Box className={classes.paper}>
                    <Box mb={2}>
                        <Typography variant="body1">
                            Enter Account Name
                        </Typography>
                    </Box>
                    <TextInput
                        variant="outlined"
                        size="small"
                        onChange={(e) => setAccountName(e.target.value)}
                        label="Account Name"
                    />
                    <Box my={2}>
                        {creationLoading ? (
                            <CircularLoader />
                        ) : (
                            <Button
                                variant="contained"
                                size="small"
                                onClick={createAccount}
                            >
                                Create
                            </Button>
                        )}
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default Wallet;
