import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Modal, Box, Button } from '@mui/material';
import ReactCodeInput from 'react-code-input';

import StyledPaper from '../../StyledPaper';
import { Create, Wallet } from '../components/GreenWallet';
import * as liquidBlocksreamActions from '../../../store/liquidBlocksteam/liquidBlockstreamActions';
import { modalStyles } from '../../styles/modalStyles';
import { greenWallet } from '../api';
import CircularLoader from '../../CircularLoader';
import { snackbar } from '../../../utilities/snackbarUtils';
import TableComponent from '../components/Table';

const tableCell = [
    { field: 'id', headerName: 'Id' },
    { field: 'wallet', headerName: 'Name' },
];

const GreenWallet = () => {
    const classes = modalStyles();
    const dispatch = useDispatch();
    const [wallet, setWallet] = useState({ name: '', confirmed: false });
    const [login, setIsLogin] = useState(false);
    const [pinValidation, setPinValidation] = useState(false);
    const [pinCode, setPinCode] = useState('');

    const { greenWallets, loading } = useSelector(
        (state) => state.liquidBlockstream
    );

    useEffect(() => {
        if (!greenWallets.length) {
            getWallets();
        }
    }, []);

    const getWallets = () => {
        dispatch(liquidBlocksreamActions.getGreenWallets());
    };

    const handleLogin = (id, account) => {
        if (account.auth_method === 'pin') {
            setIsLogin(true);
            setWallet({ name: account.wallet, confirmed: false });
            setPinCode('');
        } else {
            setWallet({ name: account.wallet, confirmed: true });
            localStorage.setItem(
                'walletData',
                JSON.stringify({
                    wallet: account.wallet,
                })
            );
        }
    };
    const confirmPinCode = async () => {
        setPinValidation(true);
        try {
            const response = (
                await greenWallet.validatePin({
                    pin: pinCode,
                    name: wallet.name,
                })
            ).data;
            if (response.success) {
                localStorage.setItem(
                    'walletData',
                    JSON.stringify({
                        pin: pinCode,
                        wallet: wallet.name,
                    })
                );
            }
            setWallet({ name: wallet.name, confirmed: true });
        } catch (error) {
            snackbar.error(error.response.data.message);
        }
        setPinValidation(false);
        setIsLogin(false);
    };
    return (
        <>
            {!wallet.confirmed && (
                <StyledPaper elevation={3} padding={10} marginBottom={20}>
                    {loading ? (
                        <CircularLoader />
                    ) : (
                        <Box>
                            {!!greenWallets.length ? (
                                <TableComponent
                                    columns={tableCell}
                                    items={greenWallets}
                                    onRowClick={handleLogin}
                                />
                            ) : (
                                <Typography variant="body1" textAlign="center">
                                    No wallets
                                </Typography>
                            )}
                        </Box>
                    )}
                </StyledPaper>
            )}
            {!wallet.confirmed && (
                <StyledPaper elevation={3}>
                    <Typography align="center" variant="subtitle1">
                        Create Green Wallet Account
                    </Typography>
                    <Create />
                </StyledPaper>
            )}
            {wallet.confirmed && (
                <Wallet
                    logout={() => setWallet({ data: {}, confirmed: false })}
                />
            )}
            <Modal
                className={classes.modal}
                open={login}
                onClose={() => setIsLogin(false)}
            >
                <Box className={classes.paper}>
                    <Typography textAlign="center">
                        Please enter PIN code
                    </Typography>
                    <Box my={2}>
                        <ReactCodeInput
                            value={pinCode}
                            type="password"
                            fields={6}
                            onChange={(e) => setPinCode(e)}
                        />
                    </Box>
                    {pinValidation ? (
                        <CircularLoader />
                    ) : (
                        <Button
                            onClick={confirmPinCode}
                            variant="contained"
                            disabled={pinCode.length < 6}
                        >
                            Confirm
                        </Button>
                    )}
                </Box>
            </Modal>
        </>
    );
};

export default GreenWallet;
