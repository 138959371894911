import React, { useEffect, useState } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { snackbar } from '../../../../utilities/snackbarUtils';
import TextField from '../../../TextField/TextField';
import { category, user, greenWallet } from '../../api';
import { errorParser } from '../../utils/errorParser';
import TableComponent from '../Table';
import CircularLoader from '../../../CircularLoader';
import { modalStyles } from '../../../styles/modalStyles';
import FormikAutocomplete from '../../../FormikAutocomplete';
import { CheckboxWithLabel } from 'formik-mui';

const tableColumns = [
    { headerName: 'Id', field: 'id' },
    { headerName: 'GAID', field: 'GAID' },
    { headerName: 'Name', field: 'name' },
    { headerName: 'Categories', field: 'categories' },
    { headerName: 'Сreator', field: 'сreator' },
];

const validationSchema = Yup.object({
    name: Yup.string().required('Please enter the User name'),
});

const USER_MODAL_DATA_INIT_VALUE = {
    gaid: null,
    label: '',
    columns: [],
    data: [],
};
const assignSchema = Yup.object({
    categoryId: Yup.string().required('Please select Category.'),
});

const User = ({ gaid }) => {
    const classes = modalStyles();
    const [users, setUsers] = useState({ data: [], loading: false });
    const [categories, setCategories] = useState([]);
    const [modal, setModal] = useState(false);
    const [userModal, setUserModal] = useState(false);
    const [userModalData, setUserModalData] = useState(
        USER_MODAL_DATA_INIT_VALUE
    );
    const [userToAssign, setUserToAssing] = useState({});
    const [assignModal, setAssignModal] = useState(false);
    const [assignLoader, setAssignLoader] = useState(false);

    useEffect(() => {
        getUsers();
        getCategories();
    }, []);

    const getCategories = async () => {
        try {
            const res = (await category.categories()).data;
            const preparedData =
                res &&
                res.map((item) => ({
                    value: item.id,
                    name: `${item.id} - ${item.name || 'N/A'}`,
                }));
            setCategories(preparedData);
        } catch (error) {
            snackbar.error(errorParser(error));
        }
    };

    const getUsers = async () => {
        setUsers({ data: [], loading: true });
        try {
            const res = (await user.getUsers()).data;
            const formattedUsers = !!res.length && prepareUsers(res);
            setUsers({ data: formattedUsers, loading: false });
        } catch (error) {
            snackbar.error(errorParser(error));
        }
    };

    const assignToCategory = (item) => {
        setUserToAssing(item);
        setAssignModal(true);
    };

    const prepareUsers = (data) => {
        return data.map((item) => ({
            ...item,
            categories: item?.categories?.join(', '),
        }));
    };

    const createUser = async (values, setSubmitting, reset) => {
        try {
            await user.create({ ...values, GAID: gaid });
            getUsers();
            reset();
        } catch (error) {
            snackbar.error(errorParser(error));
        }
        setSubmitting(false);
    };
    const assignUser = async (values, setSubmitting, reset) => {
        setAssignLoader(true);
        try {
            await category.assignCategoryToUser(
                values.categoryId,
                values.userId
            );
            reset();
            setAssignModal(false);
            getUsers();
        } catch (error) {
            snackbar.error(errorParser(error));
        }
        setSubmitting(false);
        setAssignLoader(false);
    };

    const handleCreate = () =>
        gaid ? setModal(true) : snackbar.error('Please create an Account!');

    const getUserSummary = async (itemId, item) => {
        setUserModal(true);

        try {
            const response = await user.getSummary(itemId);
            setUserModalData({
                label: 'User Summary',
                data: response.data,
                columns: [
                    {
                        headerName: 'Asset ID',
                        field: 'assetId',
                        cellType: 'truncated',
                        useTooltip: true,
                    },
                    {
                        headerName: 'Asset Uuid',
                        field: 'assetUuid',
                        cellType: 'truncated',
                        useTooltip: true,
                    },
                    { headerName: 'Assignments Sum', field: 'assignmentsSum' },
                    { headerName: 'Balance', field: 'balance' },
                    {
                        headerName: 'Distributions Sum',
                        field: 'distributionsSum',
                    },
                ],
            });
        } catch (err) {
            snackbar.error(errorParser(err));
            setUserModal(false);
            setUserModalData(USER_MODAL_DATA_INIT_VALUE);
        }
    };

    const getUserGaids = async (itemId, item) => {
        setUserModal(true);

        try {
            const response = (await user.getGaids(itemId)).data.map(
                (gaidValue, i) => {
                    return {
                        id: itemId,
                        no: i + 1,
                        gaid: gaidValue,
                    };
                }
            );
            setUserModalData({
                gaid: itemId,
                label: 'User GAIDs',
                data: response,
                columns: [
                    {
                        headerName: 'No',
                        field: 'no',
                    },
                    {
                        headerName: 'GAID',
                        field: 'gaid',
                    },
                ],
            });
        } catch (err) {
            snackbar.error(errorParser(err));
            setUserModal(false);
            setUserModalData(USER_MODAL_DATA_INIT_VALUE);
        }
    };

    const setDefaultGaid = async (itemId, item) => {
        snackbar.info('Setting default GAID...');

        try {
            await user.setDefaultGaid(itemId, {
                gaid: item.gaid,
            });
            snackbar.success('Default GAID was set successfully!');
        } catch (err) {
            snackbar.error(errorParser(err));
        }
    };
    // get all the available GAIDs
    const initAssociateGaid = async (itemId, item) => {
        setUserModal(true);

        const data = JSON.parse(localStorage.getItem('walletData'));

        try {
            const gaids = (
                await greenWallet.getAccounts({ wallet: data.wallet, pin: data.pin })
            ).data;

            setUserModalData({
                associateGaid: itemId,
                label: `Associate GAID to ${item.name}`,
                data: gaids,
                columns: [
                    {
                        headerName: 'No',
                        field: 'id'
                    },
                    {
                        headerName: 'GAID',
                        field: 'gaid'
                    }
                ]
            });
        } catch (err) {
            snackbar.error(errorParser(err));
            setUserModal(false);
            setUserModalData(USER_MODAL_DATA_INIT_VALUE);
        }
    }

    const associateGaid = async (itemId, item) => {
        snackbar.info('Associating GAID...');

        try {
            await user.addGaid(itemId, { gaid: item.gaid });
            snackbar.success('GAID was associated to the user successfully!');
        } catch (err) {
            snackbar.error(errorParser(err));
        }
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Box my={2}>
                {users.loading ? (
                    <CircularLoader />
                ) : (
                    <>
                        {!!users?.data?.length ? (
                            <TableComponent
                                columns={tableColumns}
                                items={users.data}
                                threeDotsActions={[
                                    {
                                        label: 'Show User Summary',
                                        handler: getUserSummary,
                                    },
                                    {
                                        label: 'Show User GAIDs',
                                        handler: getUserGaids,
                                    },
                                    {
                                        label: 'Assign to Category',
                                        handler: (id, item) =>
                                            assignToCategory(item),
                                    },
                                    {
                                        label: 'Associate User to GAID',
                                        handler: initAssociateGaid,
                                    },
                                ]}
                            />
                        ) : (
                            <Typography variant="body2" textAlign="center">
                                There is nothing to show
                            </Typography>
                        )}
                    </>
                )}
            </Box>

            <Button variant="contained" onClick={handleCreate}>
                Create User
            </Button>
            <Modal
                className={classes.modal}
                open={modal}
                onClose={() => setModal(false)}
            >
                <Box className={classes.paper}>
                    <Typography variant="body1" textAlign="center">
                        User Creation
                    </Typography>
                    <Formik
                        initialValues={{
                            isCompany: false,
                            name: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            createUser(values, setSubmitting, resetForm);
                        }}
                    >
                        {({ submitForm, isSubmitting }) => (
                            <Form noValidate style={{ width: '100%' }}>
                                <Box display="flex" flexDirection="column">
                                    <Field
                                        component={TextField}
                                        name="name"
                                        label="Name"
                                        variant="outlined"
                                        margin="normal"
                                        required
                                    />
                                    <Field
                                        component={CheckboxWithLabel}
                                        type="checkbox"
                                        name="isCompany"
                                        Label={{
                                            label: 'Is Company',
                                        }}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={submitForm}
                                        disabled={isSubmitting}
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Modal>
            <Modal
                className={classes.modal}
                open={userModal}
                onClose={() => {
                    setUserModal(false);
                    setUserModalData(USER_MODAL_DATA_INIT_VALUE);
                }}
            >
                <Box className={classes.paper}>
                    {userModalData.data.length ? (
                        <>
                            <Typography variant="body1" textAlign="center">
                                {userModalData.label}
                            </Typography>
                            <TableComponent
                                columns={userModalData.columns}
                                items={userModalData.data}
                                threeDotsActions={(
                                    userModalData.gaid && [
                                        {
                                            label: 'Set as Default GAID',
                                            handler: setDefaultGaid,
                                        }
                                    ]) || (userModalData.associateGaid && [
                                        {
                                            label: 'Associate with User',
                                            handler: (itemId, item) => {
                                                associateGaid(userModalData.associateGaid, item);
                                            },
                                        }
                                    ])
                                }
                            />
                        </>
                    ) : (
                        <CircularLoader />
                    )}
                </Box>
            </Modal>
            <Modal
                className={classes.modal}
                open={assignModal}
                onClose={() => setAssignModal(false)}
            >
                <Box className={classes.paper}>
                    <Typography variant="body1" textAlign="center">
                        Assign User to Category
                    </Typography>
                    <Formik
                        initialValues={{
                            userId: userToAssign.id,
                            categoryId: categories[0]?.value,
                        }}
                        validationSchema={assignSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            assignUser(values, setSubmitting, resetForm);
                        }}
                    >
                        {({ submitForm, isSubmitting }) => (
                            <Form noValidate style={{ width: '100%' }}>
                                <Box display="flex" flexDirection="column">
                                    <Field
                                        component={TextField}
                                        name="userId"
                                        label="User Id"
                                        variant="outlined"
                                        margin="normal"
                                        disabled
                                        required
                                    />
                                    <Field
                                        component={FormikAutocomplete}
                                        name="categoryId"
                                        label="Category Id"
                                        options={categories}
                                        optionKey="name"
                                        dataKey="value"
                                        defaultValue={categories[0]}
                                    />
                                    {assignLoader ? (
                                        <CircularLoader />
                                    ) : (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={submitForm}
                                            disabled={isSubmitting}
                                        >
                                            Submit
                                        </Button>
                                    )}
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Modal>
        </Box>
    );
};

export default User;
