import request from '../../utilities/request'
import * as config from '../../config'
import * as categoryTypes from './categoryTypes'

export const getCategoriesSuccess = (categories) => {
    return {
        type: categoryTypes.GET_CATEGORIES_SUCCESS,
        payload: categories
    }
}

export const setCategoriesLoading = (loading) => {
    return {
        type: categoryTypes.SET_CATEGORIES_LOADING,
        payload: loading
    }
}

export const getCategories = () => {
    return (dispatch) => {
        dispatch(setCategoriesLoading(true))
        request
            .get(`${config.API_BASE_URL}/share-class-categories`)
            .then((res) => {
                dispatch(getCategoriesSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                dispatch(setCategoriesLoading(false))
            });
    };
};