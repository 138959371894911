import { Typography } from '@mui/material'
import React from 'react'

const DataError = ({ show, text }) => {
    return (
        show ?
        <Typography variant='body2' as='p' sx={{ textAlign: 'center' }}>
            {text}
        </Typography> : null
    )
}

export default DataError