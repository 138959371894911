import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DataTable from '../../../components/DataTable/MuiDataTable';
import Humanize from 'humanize-plus';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { DateFormatter } from '../../../utilities/formatters';
import CircularProgress from '@mui/material/CircularProgress';
import * as transferActions from '../../../store/transfer/transferActions';
import transferStyles from '../../../components/styles/transferStyles';
import LockedTile from '../../../components/LockedTile';
import { SUBSCRIPTION_PRO } from '../../../constants/profile';

const History = (props) => {
    const transferClasses = transferStyles();
    useEffect(() => props.transferActions.getAllTransfers(), [
        props.transferActions,
    ]);

    return (
        <LockedTile requiredPlan={SUBSCRIPTION_PRO}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {props.loading ? (
                        <Grid container item xs={12} justifyContent="center">
                            <CircularProgress />
                        </Grid>
                    ) : (
                        <DataTable
                            title={'Transfers History'}
                            data={props.transfers ? props.transfers.data : []}
                            columns={[
                                {
                                    label: 'Ticker',
                                    name: 'equity',
                                    options: {
                                        customBodyRender: (value) => (
                                            <Typography variant="overline">
                                                {value.ticker}
                                            </Typography>
                                        ),
                                    },
                                },
                                {
                                    label: 'From',
                                    name: 'sender',
                                    options: {
                                        customBodyRender: (value) => (
                                            <span>
                                                {value && value.first_name
                                                    ? value.first_name
                                                    : '-'}
                                            </span>
                                        ),
                                    },
                                },
                                {
                                    label: 'Sender Wallet',
                                    name: 'sender',
                                    options: {
                                        customBodyRender: (value) => (
                                            <div>
                                                <Tooltip
                                                    title={
                                                        value && value.wallet
                                                            ? value.wallet
                                                                  .public_address
                                                            : 'Not available'
                                                    }
                                                >
                                                    <IconButton size="small">
                                                        <AccountBalanceWalletIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        ),
                                    },
                                },
                                {
                                    label: 'To',
                                    name: 'receiver',
                                    options: {
                                        customBodyRender: (value) => (
                                            <span>
                                                {value && value.first_name
                                                    ? value.first_name
                                                    : '-'}
                                            </span>
                                        ),
                                    },
                                },
                                {
                                    label: 'Recipient wallet',
                                    name: 'receiver',
                                    options: {
                                        customBodyRender: (value) => (
                                            <div>
                                                <Tooltip
                                                    title={
                                                        value && value.wallet
                                                            ? value.wallet
                                                                  .public_address
                                                            : 'Not available'
                                                    }
                                                >
                                                    <IconButton size="small">
                                                        <AccountBalanceWalletIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        ),
                                    },
                                },
                                {
                                    label: 'Amount',
                                    name: 'amount',
                                    options: {
                                        customBodyRender: (value) => (
                                            <span
                                                style={{
                                                    color: '#3E9CD9',
                                                }}
                                            >
                                                <b>
                                                    {Humanize.formatNumber(
                                                        value,
                                                        2
                                                    )}
                                                </b>
                                            </span>
                                        ),
                                    },
                                },
                                {
                                    label: 'Price per Asset',
                                    name: 'price_per_share',
                                    options: {
                                        customBodyRender: (value) => (
                                            <span
                                                style={{
                                                    color: '#3E9CD9',
                                                }}
                                            >
                                                <b>
                                                    {Humanize.formatNumber(
                                                        value,
                                                        2
                                                    )}
                                                </b>
                                            </span>
                                        ),
                                    },
                                },
                                {
                                    label: '',
                                    name: 'submitter',
                                    options: { display: 'excluded' },
                                },
                                {
                                    label: '',
                                    name: 'reviewer',
                                    options: { display: 'excluded' },
                                },
                                {
                                    label: '',
                                    name: 'updated_at',
                                    options: {
                                        display: 'excluded',
                                        customBodyRender: (value) => (
                                            <span>
                                                {DateFormatter({
                                                    value: value,
                                                    format: 'date',
                                                })}
                                            </span>
                                        ),
                                    },
                                },
                                {
                                    label: '',
                                    name: 'transaction',
                                    options: {
                                        display: 'excluded',
                                    },
                                },
                                {
                                    label: 'Status',
                                    name: 'status',
                                    options: {
                                        customBodyRender: (value) => (
                                            <div
                                                className={
                                                    value === 'pending'
                                                        ? transferClasses.grey
                                                        : value === 'approved'
                                                        ? transferClasses.green
                                                        : transferClasses.red
                                                }
                                            >
                                                {value
                                                    ? value.toUpperCase()
                                                    : ''}
                                            </div>
                                        ),
                                    },
                                },
                                {
                                    label: 'Type',
                                    name: 'type',
                                    options: {
                                        customBodyRender: (value) => (
                                            <div>
                                                {value
                                                    ? value.toUpperCase()
                                                    : ''}
                                            </div>
                                        ),
                                    },
                                },
                                {
                                    label: '',
                                    name: 'status',
                                    options: {
                                        display: 'excluded',
                                    },
                                },
                                {
                                    label: '',
                                    name: 'documents',
                                    options: {
                                        display: 'excluded',
                                    },
                                },
                            ]}
                            expandable={{
                                expandableRows: true,
                                expandableRowsHeader: false,
                                expandableRowsOnClick: true,
                                renderExpandableRow: (rowData, rowMeta) => {
                                    const colSpan = rowData.length + 1;

                                    return (
                                        <TableRow>
                                            <TableCell
                                                colSpan={colSpan}
                                                className={
                                                    transferClasses.historyDetailsTable
                                                }
                                            >
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={2}
                                                        md={2}
                                                        sm={2}
                                                        className={
                                                            transferClasses.greyTitle
                                                        }
                                                    >
                                                        Submitted by:
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        md={6}
                                                        sm={6}
                                                        className={
                                                            transferClasses.blackTitle
                                                        }
                                                    >
                                                        {rowData[7].company_name
                                                            ? rowData[7]
                                                                  .company_name
                                                            : ''}
                                                    </Grid>
                                                </Grid>

                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={2}
                                                        md={2}
                                                        sm={2}
                                                        className={
                                                            transferClasses.greyTitle
                                                        }
                                                    >
                                                        {rowData[13] ===
                                                        'approved'
                                                            ? 'Approved by:'
                                                            : rowData[13] ===
                                                              'rejected'
                                                            ? 'Rejected by:'
                                                            : ''}
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        md={6}
                                                        sm={6}
                                                        className={
                                                            transferClasses.blackTitle
                                                        }
                                                    >
                                                        {rowData[13] !==
                                                        'pending'
                                                            ? rowData[8] &&
                                                              rowData[8]
                                                                  .company_name
                                                                ? rowData[8]
                                                                      .company_name
                                                                : ''
                                                            : ''}
                                                    </Grid>
                                                </Grid>

                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={2}
                                                        md={2}
                                                        sm={2}
                                                        className={
                                                            transferClasses.greyTitle
                                                        }
                                                    >
                                                        {rowData[13] ===
                                                        'approved'
                                                            ? 'Approved at:'
                                                            : rowData[13] ===
                                                              'rejected'
                                                            ? 'Rejected at:'
                                                            : ''}
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        md={6}
                                                        sm={6}
                                                        className={
                                                            transferClasses.blackTitle
                                                        }
                                                    >
                                                        {rowData[13] !==
                                                            'pending' &&
                                                        rowData[9]
                                                            ? rowData[9]
                                                            : ''}
                                                    </Grid>
                                                </Grid>

                                                {rowData[10] && (
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={2}
                                                            md={2}
                                                            sm={2}
                                                            className={
                                                                transferClasses.greyTitle
                                                            }
                                                        >
                                                            Transaction Hash:
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            md={6}
                                                            sm={6}
                                                            className={
                                                                transferClasses.blueContent
                                                            }
                                                        >
                                                            {rowData[10] &&
                                                            rowData[10].tx_id
                                                                ? rowData[9]
                                                                      .tx_id
                                                                : ''}
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={2}
                                                        md={2}
                                                        sm={2}
                                                        className={
                                                            transferClasses.greyTitle
                                                        }
                                                    >
                                                        Documents:
                                                    </Grid>
                                                </Grid>
                                                {rowData[14].map(
                                                    (document, i) => (
                                                        <div>
                                                            <a
                                                                href={
                                                                    document.document
                                                                }
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                {
                                                                    document.document
                                                                }
                                                            </a>
                                                        </div>
                                                    )
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                },
                            }}
                        />
                    )}
                </Grid>
            </Grid>
        </LockedTile>
    );
};

const mapStateToProps = ({ profile, transfers }) => {
    return {
        profile: profile,
        transfers: transfers.data,
        loading: transfers.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        transferActions: bindActionCreators(transferActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(History);
