import makeStyles from '@mui/styles/makeStyles';

const footerStyles = makeStyles((theme) => ({
    active: {
        fontSize: '1.2rem',
        color: 'green',
    },
    centerText: {
        textAlign: 'center',
    },
}));

export default footerStyles;
