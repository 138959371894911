import React from 'react'

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

const CircularLoader = () => {
    return (
        <Grid container item xs={12} justifyContent="center">
            <CircularProgress />
        </Grid>
    );
}

export default CircularLoader
