import React, { useState } from 'react';
import {
    Switch,
    Route,
    useRouteMatch,
    useLocation,
    Redirect,
} from 'react-router-dom';
import issuerRouteStyles from './issuerRouteStyles';
import TabPanel from '../../components/TabPanel';
import SubMenu from '../../components/SubMenu';
import Transfers from '../../pages/issuer/Transfer/Transfers';
import Issuance from '../../pages/issuer/Transfer/Issuance';
import Container from '@mui/material/Container';
//import VerticalContainer from '../../components/VerticalContainer';
import Divider from '@mui/material/Divider';
import { ISSUE_ROUTES } from './routeList';
import BlockchainScreen from '../../pages/issuer/Blockchain/BlockchainScreen';
const IssueRoutes = (props) => {
    const classes = issuerRouteStyles();
    let { path } = useRouteMatch();
    const location = useLocation();

    const getActiveTab = () => {
        let index = ISSUE_ROUTES.indexOf(location.pathname);
        return index >= 0 ? index : 0;
    };

    const [value, setValue] = useState(getActiveTab());

    const tabs = [
        {
            label: 'Blockchain',
            path: `${path}/blockchain`,
        },
        {
            label: 'Issuance',
            path: `${path}/issuance`,
            display: true,
        },
        {
            label: 'Transfers',
            path: `${path}/transfers`,
            display: true,
        },
    ];

    return (
        <Container>
            <SubMenu
                value={value}
                onChange={(e, v) => setValue(v)}
                tabs={tabs}
                title="Digital Security"
            />
            <Divider
                orientation="horizontal"
                fullWidth
                className={classes.divider}
            ></Divider>
            <TabPanel>
                <Switch>
                    <Route exact path={`${path}/`}>
                        <Redirect to={`${path}/blockchain`} />
                    </Route>
                    <Route
                        exact
                        path={`${path}/blockchain`}
                        component={BlockchainScreen}
                    />
                    <Route
                        exact
                        path={`${path}/issuance`}
                        component={Issuance}
                    />
                    <Route
                        exact
                        path={`${path}/transfers`}
                        component={Transfers}
                    />
                </Switch>
            </TabPanel>
        </Container>
    );
};

export default IssueRoutes;
