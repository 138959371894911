import React, { useEffect } from 'react';
import {
    BrowserRouter,
    Switch,
    Route,
    Redirect,
    useLocation,
} from 'react-router-dom';
import Page from '../components/Page/Page';
import Login from '../pages/user/Login/Login';
import ForgotPassword from '../pages/user/ForgotPassword/ForgotPassword';
import ResetPassword from '../pages/user/ForgotPassword/ResetPassword';
import CreateNewAccount from '../pages/user/IssuerInvite/CreateNewAccount';
import InvestorRoutes from './InvestorRoutes';
import IssuerRoutes from './IssuerRoutes';
import AdminRoutes from './AdminRoutes';
import TransferAgentRoutes from './TransferAgentRoutes';
import { isRefreshTokenValid, getAccountType } from '../utilities/auth';
//import Sidebar from '../pages/issuer/Sidebar/Sidebar';

import Signup from '../pages/user/Signup/Signup';

const Routes = () => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Switch>
                <PublicRoute path="/login" exact>
                    <Login />
                </PublicRoute>
                <PublicRoute path="/admin/login" exact>
                    <Login isAdmin={true} />
                </PublicRoute>
                <PublicRoute path="/signup" exact>
                    <Signup />
                </PublicRoute>

                <PublicRoute path="/forgot-password" exact>
                    <ForgotPassword />
                </PublicRoute>
                <Route path="/reset-password/:hash">
                    <ResetPassword />
                </Route>
                <Route path="/issuer-join/:hash">
                    <CreateNewAccount />
                </Route>
                <PrivateRoute path="/investor">
                    <InvestorRoutes />
                </PrivateRoute>
                <PrivateRoute path="/issuer">
                    <IssuerRoutes />
                </PrivateRoute>
                <PrivateRoute path="/admin">
                    <AdminRoutes />
                </PrivateRoute>
                <PrivateRoute path="/transfer-agent">
                    <TransferAgentRoutes />
                </PrivateRoute>
                <DefaultRoute />
            </Switch>
        </BrowserRouter>
    );
};

const DefaultRoute = () => {
    return (
        <Route
            path="/"
            exact
            render={() =>
                isRefreshTokenValid() ? (
                    <Redirect to="/issuer/issue" />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
};

const PrivateRoute = ({ children, ...rest }) => {
    const accountType = getAccountType();

    const redirect = () => {
        switch (rest.path) {
            case '/admin':
                return '/admin/login';
            default:
                return '/login';
        }
    };

    const canView =
        (rest.path === '/admin' && accountType === 'admin') ||
        rest.path !== '/admin'; // only admin can view admin pages

    return (
        <Route
            {...rest}
            render={({ location }) =>
                isRefreshTokenValid() ? (
                    canView ? (
                        <Page key={rest.location.pathname}>{children}</Page>
                    ) : (
                        <Redirect to={{ pathname: '/issuer' }} />
                    )
                ) : (
                    <Redirect
                        to={{ pathname: redirect(), state: { from: location } }}
                    />
                )
            }
        />
    );
};

const PublicRoute = ({ children, ...rest }) => {
    const redirect = () => {
        switch (getAccountType()) {
            case 'admin':
                return '/admin';
            default:
                return '/';
        }
    };

    return (
        <Route
            {...rest}
            render={() =>
                isRefreshTokenValid() ? (
                    <Redirect to={{ pathname: redirect() }} />
                ) : (
                    <div>{children}</div>
                )
            }
        />
    );
};

const ScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return null;
};

export default Routes;
