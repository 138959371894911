export const GET_ISSUERS_SUCCESS = 'GET_ISSUERS_SUCCESS'
export const SET_ISSUERS_LOADING = 'SET_ISSUERS_LOADING'

export const SET_LOGIN_AS_LOADING = 'SET_LOGIN_AS_LOADING'

export const GET_ADMINISTRATORS_SUCCESS = 'GET_ADMINISTRATORS_SUCCESS'
export const SET_ADMINISTRATORS_LOADING = 'SET_ADMINISTRATORS_LOADING'

export const ADD_ADMINISTRATOR_SUCCESS = 'ADD_ADMINISTRATOR_SUCCESS'
export const DELETE_ADMINISTRATOR_SUCCESS = 'DELETE_ADMINISTRATOR_SUCCESS'

export const GET_TRANSFER_AGENTS_SUCCESS = 'GET_TRANSFER_AGENTS_SUCCESS'
export const SET_TRANSFER_AGENTS_LOADING = 'SET_TRANSFER_AGENTS_LOADING'

export const ADD_TRANSFER_AGENT_SUCCESS = 'ADD_TRANSFER_AGENT_SUCCESS'
export const DELETE_TRANSFER_AGENT_SUCCESS = 'DELETE_TRANSFER_AGENT_SUCCESS'