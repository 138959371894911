import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Paper from '../../../components/StyledPaper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as profileActions from '../../../store/profile/profileActions';
import * as issuerInviteActions from '../../../store/issuerInvite/issuerInviteActions';
import TextField from '../../../components/TextField/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import InfoIcon from '@mui/icons-material/Info';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
const validationSchema = Yup.object({
    invites: Yup.array().of(
        Yup.object().shape({
            first_name: Yup.string().required('Please enter their first name'),
            last_name: Yup.string().required('Please enter their last name'),
            email: Yup.string()
                .email('Please enter a valid email address')
                .required("Please enter the invitee's email address"),
        })
    ),
});

const initialValues = {
    invites: [],
};

const InviteModule = (props) => {
    const { issuerInviteActions, issuerEmail, successCallback } = props;
    const [displayEmailDialog, setDisplayEmailDialog] = useState(false);
    const [invites, setInvites] = useState([]);
    const handleAddInvite = (values, setValues) => {
        const invites = [...values.invites];
        invites.push({ email: '', first_name: '', last_name: '' });
        setValues({ ...values, invites });
    };

    const handleRemoveInvite = (values, setValues, errors, setErrors, i) => {
        const invites = [...values.invites];
        invites.splice(i, 1);
        setValues({ ...values, invites });
        // handleFormikErrors(errors, setErrors, i)
    };

    const displayEmailTemplate = (values) => {
        setInvites(values.invites);
        console.log(values.invites);
        setDisplayEmailDialog(true);
    };

    const closeDialog = () => {
        setDisplayEmailDialog(false);
    };

    // const handleFormikErrors = (errors, setErrors, i) => {
    //     const invites = [...errors.invites];
    //     invites.splice(i, 1);
    //     setErrors({ ...errors, ...invites })
    // }

    const content = () => {
        return (
            <div>
                <Typography variant="overline">
                    <h1>Invite Team Members</h1>
                </Typography>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm, setSubmitting }) => {
                        if (issuerEmail) {
                            values['issuer_email'] = issuerEmail;
                        }

                        const reset = () => {
                            resetForm(initialValues);
                        };
                        issuerInviteActions.sendInvites(
                            values,
                            setSubmitting,
                            reset,
                            successCallback
                        );
                    }}
                >
                    {({
                        isSubmitting,
                        values,
                        errors,
                        setErrors,
                        setValues,
                    }) => (
                        <Form noValidate>
                            {values.invites.map((invite, i) => (
                                <>
                                    <Grid
                                        container
                                        spacing={1}
                                        key={i}
                                        sx={{
                                            paddingLeft: '16px',
                                            paddingRight: '16px',
                                            marginBottom: '16px',
                                        }}
                                    >
                                        <Grid item xs={12} lg={4}>
                                            <Field
                                                component={TextField}
                                                type="email"
                                                label="Email"
                                                name={`invites.${i}.email`}
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4}>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="First name"
                                                name={`invites.${i}.first_name`}
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4}>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Last name"
                                                name={`invites.${i}.last_name`}
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                color="danger"
                                                disabled={isSubmitting}
                                                onClick={() =>
                                                    handleRemoveInvite(
                                                        values,
                                                        setValues,
                                                        errors,
                                                        setErrors,
                                                        i
                                                    )
                                                }
                                            >
                                                <DeleteIcon />
                                                Remove invite
                                            </Button>
                                        </Grid>
                                        {values.invites &&
                                            i !== values.invites.length - 1 && (
                                                <Grid item xs={12}>
                                                    <Divider
                                                        sx={{
                                                            marginTop: '16px',
                                                        }}
                                                    />
                                                </Grid>
                                            )}
                                    </Grid>
                                </>
                            ))}
                            <ButtonGroup sx={{ paddingLeft: '16px' }}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    disabled={isSubmitting}
                                    onClick={() =>
                                        handleAddInvite(values, setValues)
                                    }
                                >
                                    <AddIcon />
                                    Add invite
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                        isSubmitting || !values.invites.length
                                    }
                                    type="submit"
                                >
                                    {isSubmitting ? (
                                        <CircularProgress size={24} />
                                    ) : (
                                        <>
                                            <SendIcon />
                                            Send invites
                                        </>
                                    )}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    disabled={isSubmitting}
                                    onClick={() => displayEmailTemplate(values)}
                                >
                                    <InfoIcon />
                                    Preview email
                                </Button>
                            </ButtonGroup>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    };

    return (
        <React.Fragment>
            {/* Invite new issuers */}
            <Grid item xs={props.xs || 12}>
                {props.skipPaperComponent ? (
                    <div>{content()}</div>
                ) : (
                    <Paper padding={props.padding}>{content()}</Paper>
                )}
            </Grid>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={displayEmailDialog}
                onBackdropClick={(e) => setDisplayEmailDialog(false)}
            >
                <DialogTitle>
                    Invite Issuer Email Preview
                    <br />
                    <br />
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <div
                                style={{ align: 'center', textAlign: 'center' }}
                            >
                                <p
                                    style={{
                                        color: '#1e1e2d',
                                        fontWeight: 500,
                                        margin: 0,
                                        fontSize: '15px',
                                    }}
                                >
                                    Dear
                                    <b>
                                        {/*
                                        {invites && invites.length > 0
                                            ? invites.map((invite, i) => (
                                                  <span>
                                                      {' '}
                                                      {invite.first_name}
                                                      {i < invites.length - 1
                                                          ? ' /'
                                                          : ','}
                                                  </span>
                                              ))
                                            : '[first name]'}
                                        */}{' '}
                                        {invites && invites.length === 1
                                            ? invites[0].first_name +
                                              ' ' +
                                              invites[0].last_name
                                            : '[first name],'}
                                    </b>
                                </p>
                                <p
                                    style={{
                                        color: '#1e1e2d',
                                        fontWeight: 500,
                                        margin: 0,
                                        fontSize: '15px',
                                    }}
                                >
                                    <b>
                                        {props.signup
                                            ? props.issuerFirstName
                                                ? props.issuerFirstName +
                                                  ' ' +
                                                  props.issuerLastName
                                                : '[issuer name]'
                                            : props.profile &&
                                              props.profile.name
                                            ? props.profile.name
                                            : '[issuer name]'}
                                    </b>{' '}
                                    is inviting you to create an account with
                                    DIGTL. The DIGTL platform will allow you to
                                    issue a digital token for{' '}
                                    <b>
                                        {props.signup
                                            ? props.issuerCompanyName
                                                ? props.issuerCompanyName
                                                : '[company name]'
                                            : props.profile &&
                                              props.profile.company_name
                                            ? props.profile.company_name
                                            : '[company name]'}
                                    </b>
                                    , manage your cap table and connect with
                                    your holders, launch IPO or crowd funding
                                    campaigns, list your token on an exchange,
                                    and much more.
                                </p>
                                <span
                                    style={{
                                        display: 'inline-block',
                                        verticalAlign: 'middle',
                                        margin: '29px 0 26px',
                                        borderBottom: '1px solid #cecece',
                                        width: '100px',
                                    }}
                                ></span>
                                <p
                                    style={{
                                        color: '#455056',
                                        fontSize: '15px',
                                        lineHeight: '24px',
                                        margin: 0,
                                    }}
                                >
                                    A unique link to create your account has
                                    been generated for you.
                                </p>
                                <br />
                                <p
                                    style={{
                                        color: '#455056',
                                        fontSize: '15px',
                                        lineHeight: '24px',
                                        margin: 0,
                                    }}
                                >
                                    To create your account, click the following
                                    link and follow the instructions.
                                </p>
                                <p
                                    style={{
                                        background: '#3DA3D7',
                                        textDecoration: 'none !important',
                                        fontWeight: 500,
                                        marginTop: '35px',
                                        color: '#fff',
                                        textTransform: 'uppercase',
                                        fontSize: '14px',
                                        padding: '10px 24px',
                                        display: 'inline-block',
                                        borderRadius: '50px',
                                    }}
                                >
                                    Join DIGTL
                                </p>
                            </div>

                            <DialogActions>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        closeDialog();
                                    }}
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

const mapStateToProps = ({ profile, issuerInvite }) => {
    return {
        profile: profile,
        issuerInvite: issuerInvite,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        profileActions: bindActionCreators(profileActions, dispatch),
        issuerInviteActions: bindActionCreators(issuerInviteActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteModule);
