import makeStyles from '@mui/styles/makeStyles';

const investorStyles = makeStyles((theme) => ({
    eventsLeftColumn: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '22px',
        display: 'flex',
        alignItems: 'left',
        color: '#333333',
    },
    eventsRightColumn: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '22px',
        display: 'flex',
        alignItems: 'flex-end',
        color: '#3E9CD9',
        alignContent: 'flex-end',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
    },
    inviteLeftColumn: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '22px',
        display: 'flex',
        alignItems: 'left',
        color: '#333333',
        marginBottom: '0.8rem',
    },
    inviteRightColumn: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '18px',
        display: 'flex',
        alignItems: 'flex-end',
        alignContent: 'flex-end',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
        color: '#7D8696',
        marginBottom: '0.8rem',
    },
    snippetTitle: {
        fontFamily: 'Saira',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '17px',
        lineHeight: '25px',
        display: 'flex',
        alignItems: 'center',
        textDecorationLine: 'underline',
        color: '#3E9CD9',
    },
    overviewLeft: {
        fontFamily: 'Saira',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '30px',
        display: 'flex',
        alignItems: 'center',
        color: '#333333',
        marginBottom: '1rem',
    },
    overviewRight: {
        fontFamily: 'Saira',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '30px',
        lineHeight: '35px',
        display: 'flex',
        alignItems: 'center',
        color: '#3E9CD9',
        marginBottom: '1rem',
    },
    distributionsBlackCell: {
        fontFamily: 'Saira',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '17px',
        lineHeight: '25px',
        //display: 'flex',
        alignItems: 'center',
        color: '#333333',
    },
    distributionsBlueCell: {
        fontFamily: 'Saira',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '17px',
        lineHeight: '25px',
        //display: 'flex',
        alignItems: 'center',
        color: '#3E9CD9',
    },
    greyTableHead: {
        fontFamily: 'Saira',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '22px',
        color: '#7D8696',
    },
    dialogTitle: {
        fontFamily: 'Saira',
        fontStyle: 'normal',
        fontWeight: 550,
        fontSize: '24px',
        lineHeight: '34px',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
        color: '#333333',
    },
}));

export default investorStyles;
