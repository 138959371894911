import React, { useState } from 'react';
import { Formik, Form /*, Field*/ } from 'formik';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import authStyles from '../authStyles';
import investorStyles from '../../../components/styles/investorStyles';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import TextField from '../../../components/TextField/TextField';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
//import FormikSelect from '../../../components/Formik/FormikSelect';
import MenuItem from '@mui/material/MenuItem';
import ButtonGroup from '@mui/material/ButtonGroup';
import Switch from '@mui/material/Switch';
//import { CheckboxWithLabel } from 'formik-mui';
import LockedTile from '../../../components/LockedTile';

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);
/*let initialValues = {
    exclude_categories_0: true,
    exclude_categories_1: false,
    exclude_categories_2: true,
};*/
const Distributions = (props) => {
    const classes = authStyles();
    const investorClasses = investorStyles();
    const [isOneTime, setIsOneTime] = useState(true);
    const [equity, setEquity] = useState('equity');
    const [shareClass, setShareClass] = useState('no-data');

    const handleChange = (event) => {
        setIsOneTime(!isOneTime);
    };
    /*    let distributions = [
        {
            share_class: 'AppleCommon_A',
            total: '$10,000',
            divident_per_share: '$1.00',
            record_date: '11/02/2020',
            payment_date: '11/05/2020',
        },
    ];
    let exclude_categories = [
        'AppleCommon_A_Founder',
        'AppleCommon_A_Employee',
        'Apple_Employee_Options',
    ];*/

    /*let demo;
    try {
        demo = props.profile.demo;
    } catch (e) {
        demo = null;
    }*/

    return (
        <LockedTile lock={true}>
            <Formik
                //initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {}}
            >
                <Form noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Paper elevation={3} className={classes.paper}>
                                <br />
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Grid
                                            component="label"
                                            container
                                            className="flex-left"
                                        >
                                            <span>One time &nbsp;</span>
                                            <AntSwitch
                                                checked={!isOneTime}
                                                onChange={handleChange}
                                                name="isFullyDiluted"
                                            />
                                            &nbsp;
                                            <span>Recurring</span>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6}>
                                        <InputLabel
                                            className={classes.label}
                                            htmlFor="equity"
                                        >
                                            Equity
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={equity}
                                            onChange={(e) =>
                                                setEquity(e.target.value)
                                            }
                                            fullWidth
                                        >
                                            <MenuItem value={'equity'}>
                                                Equity
                                            </MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <InputLabel
                                            className={classes.label}
                                            htmlFor="share_class"
                                        >
                                            Asset Class
                                        </InputLabel>

                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={shareClass}
                                            onChange={(e) =>
                                                setShareClass(e.target.value)
                                            }
                                            fullWidth
                                        >
                                            <MenuItem value={'no-data'}>
                                                {'No data'}
                                            </MenuItem>
                                        </Select>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <InputLabel
                                            className={classes.label}
                                            htmlFor="amount"
                                        >
                                            Amount
                                        </InputLabel>

                                        <TextField
                                            type="number"
                                            name="amount"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            placeholder={'$xx,xx'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <InputLabel
                                            className={classes.label}
                                            htmlFor="total"
                                        >
                                            Total
                                        </InputLabel>

                                        <TextField
                                            type="number"
                                            name="total"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            placeholder={'$xx,xx'}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <InputLabel
                                            className={classes.label}
                                            htmlFor="record_date"
                                        >
                                            Record Date
                                        </InputLabel>

                                        <TextField
                                            type="date"
                                            name="record_date"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <InputLabel
                                            className={classes.label}
                                            htmlFor="payment_date"
                                        >
                                            Payment Date
                                        </InputLabel>
                                        <TextField
                                            type="date"
                                            name="payment_date"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <InputLabel
                                            className={classes.label}
                                            htmlFor="payment_date"
                                        >
                                            Categories to exclude:
                                        </InputLabel>

                                        {/*    {demo &&
                                            exclude_categories.map(
                                                (category, i) => (
                                                    <Grid item xs={6} key={i}>
                                                        <Field
                                                            component={
                                                                CheckboxWithLabel
                                                            }
                                                            type="checkbox"
                                                            name={
                                                                'exclude_categories_' +
                                                                i
                                                            }
                                                            Label={{
                                                                label: (
                                                                    <div
                                                                        className={
                                                                            classes.checkbox
                                                                        }
                                                                    >
                                                                        {
                                                                            category
                                                                        }
                                                                    </div>
                                                                ),
                                                            }}
                                                        />{' '}
                                                    </Grid>
                                                )
                                            )}*/}
                                        {'No data'}
                                    </Grid>
                                </Grid>

                                <div className={classes.actions}>
                                    <ButtonGroup
                                        aria-label="outlined primary button group"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            Review
                                        </Button>
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                        >
                                            Submit
                                        </Button>
                                    </ButtonGroup>
                                </div>
                                <hr
                                    style={{
                                        marginTop: '2rem',
                                        marginBottom: '2rem',
                                    }}
                                />
                                <Typography
                                    variant="body2"
                                    component="p"
                                    style={{
                                        textAlign: 'center',
                                        fontSize: 20,
                                        marginBottom: '0.5rem',
                                    }}
                                >
                                    <b> Dividend history</b>
                                </Typography>
                                <TableContainer>
                                    <Table
                                        className={classes.table}
                                        aria-label="simple table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    className={
                                                        investorClasses.greyTableHead
                                                    }
                                                >
                                                    Asset class
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        investorClasses.greyTableHead
                                                    }
                                                >
                                                    Total
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        investorClasses.greyTableHead
                                                    }
                                                >
                                                    Dividend per share
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        investorClasses.greyTableHead
                                                    }
                                                >
                                                    Record Date
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        investorClasses.greyTableHead
                                                    }
                                                >
                                                    Payment Date
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {/*{demo &&
                                                distributions.map((row, i) => (
                                                    <TableRow key={i}>
                                                        <TableCell
                                                            className={
                                                                investorClasses.distributionsBlackCell
                                                            }
                                                        >
                                                            {row.share_class}
                                                        </TableCell>

                                                        <TableCell
                                                            className={
                                                                investorClasses.distributionsBlueCell
                                                            }
                                                        >
                                                            {row.total}
                                                        </TableCell>
                                                        <TableCell
                                                            className={
                                                                investorClasses.distributionsBlueCell
                                                            }
                                                        >
                                                            {
                                                                row.divident_per_share
                                                            }
                                                        </TableCell>
                                                        <TableCell
                                                            className={
                                                                investorClasses.distributionsBlackCell
                                                            }
                                                        >
                                                            {row.record_date}
                                                        </TableCell>
                                                        <TableCell
                                                            className={
                                                                investorClasses.distributionsBlackCell
                                                            }
                                                        >
                                                            {row.payment_date}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}*/}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <p
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '1.1rem',
                                    }}
                                >
                                    No data{' '}
                                </p>
                            </Paper>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </LockedTile>
    );
};
const mapStateToProps = ({ profile }) => {
    return {
        profile: profile,
    };
};

export default connect(mapStateToProps)(Distributions);
