import React, { useState, useEffect } from 'react';
import * as config from './../../../config';
import request from '../../../utilities/request';
import authStyles from '../authStyles';
import listTemplateStyles from '../../../components/styles/listTemplateStyles';
import { useSnackbar } from 'notistack';
import * as listTemplateActions from '../../../store/template/listTemplateActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as registry from '../../../utilities/registry';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import { Formik, Form, Field } from 'formik';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import HelpTooltip from '../../../components/HelpTooltip/HelpTooltip';
import Paper from '@mui/material/Paper';
import ListElement from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TodayIcon from '@mui/icons-material/Today';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import EventNoteIcon from '@mui/icons-material/EventNote';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import Button from '@mui/material/Button';
import TextField from '../../../components/TextField/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormikSelect from '../../../components/Formik/FormikSelect';
import ButtonGroup from '@mui/material/ButtonGroup';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { DateFormatter } from '../../../utilities/formatters';
import { Link } from 'react-router-dom';

import FormikDateTimePicker from '../../../components/Formik/FormikDateTimePicker';
import FormikDatePicker from '../../../components/Formik/FormikDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import CircularProgress from '@mui/material/CircularProgress';

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import LockedTile from '../../../components/LockedTile';
import { SUBSCRIPTION_ENTERPRISE } from '../../../constants/profile';

const DocumentsTemplates = (props) => {
    const classes = authStyles();
    const listTemplateClasses = listTemplateStyles();
    const { enqueueSnackbar } = useSnackbar();

    const generateCurrentDate = () => {
        return DateFormatter({
            value: new Date(),
            format: 'date',
        });
    };

    const generateExDate = (recordDate) => {
        var ex_date = new Date(recordDate);
        ex_date.setDate(ex_date.getDate() + 1); // tomorrow
        if (ex_date.getDay() === 0) ex_date.setDate(ex_date.getDate() + 1);
        else if (ex_date.getDay() === 6) ex_date.setDate(ex_date.getDate() + 2);
        setExDividendDate(
            DateFormatter({
                value: ex_date,
                format: 'date',
            })
        );
    };

    const [templateDialog, setTemplateDialog] = useState(false);
    const [templateInitialValues, setTemplateInitialValues] = useState({});
    const [templateType, setTemplateType] = useState();
    const [templateTitle, setTemplateTitle] = useState();
    const [templatePreview, setTemplatePreview] = useState(false);
    const [numberOfResolutions, setNumberOfResolutions] = useState(1);
    const today = new Date().toLocaleDateString();
    const [issuerData, setIssuerData] = useState([]);
    const [validationSchema, setValidationSchema] = useState(null);
    const [error, setError] = useState(false);

    const [finalizationDate, setFinalizationDate] = useState(
        /*generateCurrentDate()*/ null
    );
    const [recordDate, setRecordDate] = useState(generateCurrentDate());
    const [paymentDate, setPaymentDate] = useState(generateCurrentDate());
    const [agmDate, setAgmDate] = useState(generateCurrentDate());
    const [agmResultDate, setAgmResultDate] = useState(generateCurrentDate());
    const [shareholderRecordDate, setShareholderRecordDate] = useState(
        generateCurrentDate()
    );
    const [closedPeriodStartDate, setClosedPeriodStartDate] = useState(
        generateCurrentDate()
    );
    const [periodEndDate, setPeriodEndDate] = useState(generateCurrentDate());
    const [
        financialsPostingAnticipatedDate,
        setFinancialsPostingAnticipatedDate,
    ] = useState(generateCurrentDate());

    const [dividendResolutions, setDividendResolutions] = useState();
    const [exDividendDate, setExDividendDate] = useState('');
    const f = new FormData();
    f.append('dividend_resolutions_document', null);
    const [formData, setFormData] = useState(f);

    const uploadFile = (file, name) => {
        f.set('dividend_resolutions_document', file, file.name);
        setFormData(f);
        setDividendResolutions(file.name);
    };

    const removeFile = () => {
        f.set('dividend_resolutions_document', null);
        setFormData(f);
        setDividendResolutions(null);
    };
    useEffect(props.listTemplateActions.getTemplateData, []);

    useEffect(() => {
        const { listTemplates } = props;
        if (listTemplates.data) {
            if (listTemplates.data.issuer.incorporation_country) {
                let incorporation_country = registry.countries.filter(
                    (element) => {
                        return (
                            element.id ===
                            listTemplates.data.issuer.incorporation_country
                        );
                    }
                );

                if (incorporation_country[0]) {
                    listTemplates.data.issuer.incorporation_country =
                        incorporation_country[0].name;
                    setIssuerData(listTemplates.data.issuer);
                }
            }
        }
    }, [props]);

    const displayTemplateDialog = (template_type, template_title) => {
        const { listTemplates } = props;
        if (listTemplates.data) {
            let data = {};
            if (listTemplates.data) {
                if (template_type === 'dividend_announcement') {
                    data['dividend_gross_amount_per_share'] = listTemplates.data
                        .dividend_gross_amount_per_share
                        ? listTemplates.data.dividend_gross_amount_per_share
                        : 0;
                    if (
                        listTemplates.data.dividend_finalization_date !== null
                    ) {
                        setFinalizationDate(
                            DateFormatter({
                                value:
                                    listTemplates.data
                                        .dividend_finalization_date,
                                format: 'date',
                            })
                        );
                    }

                    if (listTemplates.data.dividend_record_date !== null) {
                        setRecordDate(
                            DateFormatter({
                                value: listTemplates.data.dividend_record_date,
                                format: 'date',
                            })
                        );

                        generateExDate(listTemplates.data.dividend_record_date);
                    }

                    if (listTemplates.data.dividend_payment_date !== null) {
                        setPaymentDate(
                            DateFormatter({
                                value: listTemplates.data.dividend_payment_date,
                                format: 'date',
                            })
                        );
                    }

                    data['quarter'] = 'Q1';
                    data['year'] = new Date().getFullYear().toString();
                    data['cash_dividend_type'] = 'Interim';
                    data['dividend_declared_out_of_income'] = 'Working capital';
                    data['authorized_shares'] = 0;
                    data['subject_securities_class'] = '';
                    data['local_dividend_tax_rate'] = '';
                    data['contact'] = '';
                    data['secretary_director_name'] = '';

                    const validation = Yup.object({
                        dividend_gross_amount_per_share: Yup.string().required(
                            'Please enter dividend gross amount per asset'
                        ),
                    });
                    setValidationSchema(validation);
                } else if (
                    template_type === 'agm_announcement' ||
                    template_type === 'agm_results'
                ) {
                    if (listTemplates.data.agm_date !== null) {
                        data['agm_date'] = DateFormatter({
                            value: listTemplates.data.agm_date,
                            format: 'date',
                        });
                        setAgmDate(data['agm_date']);
                    }

                    if (listTemplates.data.agm_result_date !== null) {
                        data['agm_result_date'] = DateFormatter({
                            value: listTemplates.data.agm_result_date,
                            format: 'date',
                        });
                        setAgmResultDate(data['agm_result_date']);
                    }

                    if (listTemplates.data.resolutions) {
                        if (listTemplates.data.resolutions.length >= 1) {
                            setNumberOfResolutions(
                                listTemplates.data.resolutions.length
                            );
                            for (
                                var i = 0;
                                i < listTemplates.data.resolutions.length;
                                i++
                            ) {
                                data['resolution_' + (i + 1)] =
                                    listTemplates.data.resolutions[i];
                            }
                        }
                    }

                    data['number_of_resolutions'] = numberOfResolutions;

                    if (template_type === 'agm_announcement') {
                        let validation = Yup.object({
                            number_of_resolutions: Yup.string()
                                .min(1)
                                .required('Please enter number of resolutions'),
                            resolution_1: Yup.string().required(
                                'Please enter at least one resolution'
                            ),
                        });

                        setValidationSchema(validation);
                    }

                    data['agm_result_text'] =
                        listTemplates.data.agm_result_text !== null
                            ? listTemplates.data.agm_result_text
                            : '';
                } else if (
                    template_type === 'closed_period_start_announcement'
                ) {
                } else if (template_type === 'closed_period_end_announcement') {
                } else if (template_type === 'miscellaneous_announcement') {
                    data['announcement_name'] = '';
                    data['announcement_body'] = '';
                    const validation = Yup.object({
                        announcement_name: Yup.string().required(
                            'Please enter announcement name'
                        ),
                        announcement_body: Yup.string().required(
                            'Please enter announcement'
                        ),
                    });
                    setValidationSchema(validation);
                }
            }

            setTemplateInitialValues(data);
            setTemplateType(template_type);
            setTemplateTitle(template_title);
            setTemplatePreview(false);
            setTemplateDialog(true);
        }
    };

    const saveDocument = (values, setSubmitting) => {
        values['template_type'] = templateType;
        values['today'] = today;
        values['issuer'] = issuerData;
        if (templateType === 'dividend_announcement') {
            values['dividend_finalization_date'] = finalizationDate;
            values['dividend_record_date'] = recordDate;
            values['dividend_payment_date'] = paymentDate;
            if (
                values['other_relevant_information'] === undefined ||
                values['other_relevant_information'] === ''
            ) {
                values['other_relevant_information'] = 'N/A';
            }

            for (var x in values) {
                if (x === 'issuer') {
                    formData.append(x, JSON.stringify(values[x]));
                } else {
                    formData.append(x, values[x]);
                }
            }
            setFormData(formData);
        } else if (templateType === 'agm_announcement') {
            values['agm_date'] = agmDate;
            values['shareholder_record_date'] = shareholderRecordDate;
            values['resolutions'] = [];
            for (var i = 1; i <= numberOfResolutions; i++) {
                values['resolutions'].push({
                    resolution: values['resolution_' + i],
                });
                delete values['resolution_' + i];
            }
        } else if (templateType === 'agm_results') {
            values['agm_date'] = agmDate;
            values['agm_result_date'] = agmResultDate;
            values['resolutions'] = [];
        } else if (templateType === 'closed_period_start_announcement') {
            values['closed_period_start_date'] = closedPeriodStartDate;
            values['period_end_date'] = periodEndDate;
            values[
                'financials_posting_anticipated_date'
            ] = financialsPostingAnticipatedDate;
        } else if (templateType === 'closed_period_end_announcement') {
            values['closed_period_start_date'] = closedPeriodStartDate;
            values['period_end_date'] = periodEndDate;
        }

        let data;
        if (templateType === 'dividend_announcement') {
            data = formData;
        } else {
            data = values;
        }

        request
            .post(`${config.API_BASE_URL}/templates/generate`, data)
            .then((response) => {
                enqueueSnackbar('Information has been updated successfully', {
                    variant: 'success',
                });

                setTemplateDialog(false);
                props.listTemplateActions.getTemplateData();
            })
            .catch((error) => {
                enqueueSnackbar(
                    'Error occured. Please check if all data is entered correctly',
                    {
                        variant: 'error',
                    }
                );
                setSubmitting(false);
            });
    };

    const closeDialog = () => {
        setTemplateDialog(false);
        setTemplatePreview(false);
        setTemplateInitialValues({});
        setError(false);
    };

    const openDocumentExample = (url, type) => {
        window.open(url, type);
    };

    useEffect(() => {
        validateForm(templateType, false);
    });

    const handleDataChange = (e, name = '') => {
        let isError = false;
        if (
            e.target.name === 'announcement_name' ||
            e.target.name === 'announcement_body' ||
            e.target.name === 'subject_securities_class' ||
            e.target.name === 'contact' ||
            e.target.name === 'secretary_director_name' ||
            e.target.name === 'local_dividend_tax_rate'
        ) {
            if (e.target.value === undefined || e.target.value === '') {
                isError = true;
            }
        } else if (
            e.target.name === 'number_of_resolutions' ||
            e.target.name === 'dividend_gross_amount_per_share' ||
            e.target.name === 'authorized_shares'
        ) {
            if (
                e.target.value <= 0 ||
                e.target.value === '' ||
                e.target.value === undefined
            ) {
                isError = true;
            }
        }

        if (isError) {
            setError(true);
        } else {
            setTemplateInitialValues({
                ...templateInitialValues,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleResolutionsNumberChange = (e) => {
        if (e.target.value) {
            setNumberOfResolutions(e.target.value);
        }
    };

    const buildResolutionFields = (number) => {
        let items = [];
        for (var i = 1; i <= number; i++) {
            items.push(
                <Grid key={i}>
                    <br />
                    <Field
                        component={TextField}
                        type="text"
                        name={'resolution_' + i}
                        variant="outlined"
                        required
                        fullWidth
                        label={'Resolution ' + i}
                        onKeyUp={handleDataChange}
                        multiline
                        rows={3}
                    ></Field>
                </Grid>
            );
        }
        return items;
    };

    const listResolutions = () => {
        let items = [];
        for (var i = 1; i <= numberOfResolutions; i++) {
            items.push(
                <div>
                    <b>{i}.</b> {templateInitialValues['resolution_' + i]}{' '}
                </div>
            );
        }
        return items;
    };

    const handleDateChange = (date, type, format = 'date') => {
        if (type === 'dividend_finalization_date' && date === null) {
            setFinalizationDate(null);
        } else {
            let newDate = DateFormatter({
                value: date,
                format: format,
            });
            switch (type) {
                case 'dividend_finalization_date':
                    setFinalizationDate(newDate);
                    break;
                case 'dividend_record_date':
                    setRecordDate(newDate);
                    generateExDate(newDate);
                    break;
                case 'dividend_payment_date':
                    setPaymentDate(newDate);
                    break;
                case 'agm_date':
                    setAgmDate(newDate);
                    break;
                case 'agm_result_date':
                    setAgmResultDate(newDate);
                    break;
                case 'shareholder_record_date':
                    setShareholderRecordDate(newDate);
                    break;
                case 'closed_period_start_date':
                    setClosedPeriodStartDate(newDate);
                    break;
                case 'period_end_date':
                    setPeriodEndDate(newDate);
                    break;
                case 'financials_posting_anticipated_date':
                    setFinancialsPostingAnticipatedDate(newDate);
                    break;
                default:
                    setPaymentDate(newDate);
                    break;
            }
        }
    };

    const buildDropdown = (type) => {
        let items = [];
        let year = new Date().getFullYear();
        for (var i = 2021; i <= year + 1; i++) {
            items.push(
                <MenuItem key={i} value={i.toString()}>
                    {i}
                </MenuItem>
            );
        }

        return items;
    };

    const templateHeader = () => {
        let items = [];
        if (issuerData.is_available) {
            items.push(
                <Grid key={issuerData.company_logo}>
                    {issuerData.company_logo ? (
                        <img
                            src={issuerData.company_logo}
                            alt="Exponential"
                            className={classes.logo}
                        />
                    ) : null}
                    <div className={classes.centerText}>
                        <b>{issuerData.legal_entity_name}</b>
                        <br /> <br />
                        (Incorporated in the {issuerData.incorporation_country}
                        )<br />
                        (Company Registration Number:{' '}
                        {issuerData.company_registration_number}
                        ) <br />
                        (Asset code: {issuerData.company_share_code}
                        ) <br />
                        ISIN: {issuerData.isin}
                        <br />
                        (“the Company”)
                    </div>
                </Grid>
            );
        } else {
            items.push(
                <Grid>
                    {' '}
                    <h2 className={listTemplateClasses.h2}>
                        Company information are missing. Please provide missing
                        information{' '}
                        <Link to="/issuer/preferences/company">here</Link>{' '}
                    </h2>
                </Grid>
            );
        }

        return items;
    };

    const displayFormData = (data, text) => {
        return data ? data : <b className={classes.redText}>[{text}]</b>;
    };

    const validateForm = (templateType, showPreview = true) => {
        let form_error = false;
        if (templateType === 'miscellaneous_announcement') {
            if (
                templateInitialValues.announcement_name === undefined ||
                templateInitialValues.announcement_name === '' ||
                templateInitialValues.announcement_body === undefined ||
                templateInitialValues.announcement_body === ''
            ) {
                form_error = true;
            }
        } else if (templateType === 'agm_announcement') {
            if (
                numberOfResolutions < 1 ||
                numberOfResolutions === undefined ||
                templateInitialValues.resolution_1 === '' ||
                templateInitialValues.resolution_1 === undefined
            ) {
                form_error = true;
            }
        } else if (templateType === 'dividend_announcement') {
            if (
                templateInitialValues.dividend_gross_amount_per_share === '' ||
                templateInitialValues.dividend_gross_amount_per_share ===
                    undefined ||
                templateInitialValues.dividend_gross_amount_per_share <= 0 ||
                templateInitialValues.authorized_shares === '' ||
                templateInitialValues.authorized_shares === undefined ||
                templateInitialValues.authorized_shares <= 0 ||
                templateInitialValues.subject_securities_class === '' ||
                templateInitialValues.local_dividend_tax_rate === '' ||
                templateInitialValues.contact === '' ||
                templateInitialValues.secretary_director_name === ''
            ) {
                form_error = true;
            }
        }
        if (!form_error) {
            setError(false);
            if (showPreview) {
                setTemplatePreview(true);
            }
        } else {
            setError(true);
        }
    };

    let examples = [
        {
            title: 'Pre-listing statement',
            url:
                'https://drive.google.com/file/d/1U-z0hDYsk5v2JVpLFO2aA9cTpRGWVz3d/view',
            icon: (
                <AssessmentIcon
                    fontSize="large"
                    className={listTemplateClasses.listItemAvatar}
                />
            ),
        },
        {
            title: 'Annual financials',
            url:
                'https://drive.google.com/file/d/19n5XuRg0drWQZRrGx1WdtqfAjmWM4V3d/view',
            icon: (
                <AttachMoneyIcon
                    fontSize="large"
                    className={listTemplateClasses.listItemAvatar}
                />
            ),
        },
        {
            title: 'Dividend announcement',
            url:
                'https://s3.eu-west-1.amazonaws.com/merj.exchange/f403a3e77c414444a523322e273928e1.pdf',
            icon: (
                <AnnouncementIcon
                    fontSize="large"
                    className={listTemplateClasses.listItemAvatar}
                />
            ),
        },
        {
            title: 'AGM Announcement',
            url:
                'https://s3.eu-west-1.amazonaws.com/merj.exchange/26f4324f3b0b426bad9be9cd2c274184.pdf',
            icon: (
                <EventNoteIcon
                    fontSize="large"
                    className={listTemplateClasses.listItemAvatar}
                />
            ),
        },
        {
            title: 'AGM results',
            url:
                'https://docs.google.com/document/d/1RkkHJSNwO4f6REuobzy_1lyyjirz7eIHHDRpj6KSSwE/edit',
            icon: (
                <MeetingRoomIcon
                    fontSize="large"
                    className={listTemplateClasses.listItemAvatar}
                />
            ),
        },
    ];

    let templates = [
        {
            title: 'Dividend Announcement',
            url:
                'https://docs.google.com/document/d/1XIWFq3qE964HQN4cAf6BO0R6y2PFZUAdvcPztPXg2xQ/edit?usp=sharing',
            icon: (
                <AnnouncementIcon
                    fontSize="large"
                    className={listTemplateClasses.listItemAvatar}
                />
            ),
            type: 'dividend_announcement',
        },
        {
            title: 'AGM Announcement',
            url:
                'https://docs.google.com/document/d/12K7JhNdJc-06Sy6sAz6peAbML4fakgbceYBR7Keo0No/edit?usp=sharing',
            icon: (
                <EventNoteIcon
                    fontSize="large"
                    className={listTemplateClasses.listItemAvatar}
                />
            ),
            type: 'agm_announcement',
        },
        {
            title: 'AGM Results',
            url:
                'https://docs.google.com/document/d/1RkkHJSNwO4f6REuobzy_1lyyjirz7eIHHDRpj6KSSwE/edit',
            icon: (
                <MeetingRoomIcon
                    fontSize="large"
                    className={listTemplateClasses.listItemAvatar}
                />
            ),
            type: 'agm_results',
        },
        {
            title: 'Closed Period Start Announcement',
            url:
                'https://docs.google.com/document/d/1kgZZfuUz_xaWTow_Ehbu73vwHhr8vQL-xC9vi6DsQSw/edit?usp=sharing',
            icon: (
                <TodayIcon
                    fontSize="large"
                    className={listTemplateClasses.listItemAvatar}
                />
            ),
            type: 'closed_period_start_announcement',
        },
        {
            title: 'Closed Period End Announcement',
            url:
                'https://docs.google.com/document/d/18lh9Gy8VMkJc38g52tJIhRiUiAeCL3drVU9L2v4SofY/edit?usp=sharing',
            icon: (
                <InsertInvitationIcon
                    fontSize="large"
                    className={listTemplateClasses.listItemAvatar}
                />
            ),
            type: 'closed_period_end_announcement',
        },
        {
            title: 'Miscellaneous Announcement Template',
            url:
                'https://docs.google.com/document/d/10CDx654EeTn-YpWtZjoR-7kr51T3ehtX2gHlFBeYp4g/edit?usp=sharing',
            icon: (
                <NewReleasesIcon
                    fontSize="large"
                    className={listTemplateClasses.listItemAvatar}
                />
            ),
            type: 'miscellaneous_announcement',
        },
    ];
    return (
        <LockedTile requiredPlan={SUBSCRIPTION_ENTERPRISE}>
            <Typography component="div" className={classes.rightText}>
                <h3>
                    Listing Status:{' '}
                    <b className={classes.listingStatus}>Active</b>
                </h3>
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                    <Paper elevation={3} className={classes.paper}>
                        <Typography className={classes.title}>
                            Examples{' '}
                            <HelpTooltip
                                title={
                                    'These are exmaple documents from existing issuers to give you a sense of what the completed documents should look like'
                                }
                            />
                        </Typography>
                        <ListElement>
                            {examples.map((example, i) => (
                                <ListItem
                                    key={i}
                                    className={classes.listOverviewLink}
                                    onClick={() =>
                                        openDocumentExample(
                                            example.url,
                                            '_blank'
                                        )
                                    }
                                >
                                    <ListItemAvatar>
                                        {example.icon}
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant="body2"
                                                component="p"
                                                className={
                                                    listTemplateClasses.title
                                                }
                                            >
                                                {example.title}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </ListElement>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <Paper elevation={3} className={classes.paper}>
                        <Typography className={classes.title}>
                            Templates{' '}
                            <HelpTooltip
                                title={
                                    'These are template documents that you can download and edit for your purposes'
                                }
                            />
                        </Typography>
                        {props.listTemplates.data ? (
                            <ListElement>
                                {templates.map((template, i) => (
                                    <ListItem
                                        key={i}
                                        className={classes.listOverviewLink}
                                        onClick={() =>
                                            displayTemplateDialog(
                                                template.type,
                                                template.title
                                            )
                                        }
                                    >
                                        <ListItemAvatar>
                                            {template.icon}
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="body2"
                                                    component="p"
                                                    className={
                                                        listTemplateClasses.title
                                                    }
                                                >
                                                    {template.title}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </ListElement>
                        ) : (
                            <div
                                className={
                                    listTemplateClasses.circularProgressDiv
                                }
                            >
                                <CircularProgress size={24} />
                            </div>
                        )}
                    </Paper>
                </Grid>
            </Grid>

            <Dialog
                maxWidth={'sm'}
                fullWidth
                open={templateDialog}
                onBackdropClick={(e) => setTemplateDialog(false)}
            >
                <DialogTitle>
                    <Typography className={classes.dialogTitle}>
                        {templateTitle}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={12}>
                            <Formik
                                validationSchema={
                                    validationSchema !== null &&
                                    validationSchema
                                }
                                initialValues={templateInitialValues}
                                onSubmit={(values, { setSubmitting }) => {
                                    saveDocument(values, setSubmitting);
                                }}
                            >
                                {({ isSubmitting }) => (
                                    <Form className={classes.form} noValidate>
                                        {!templatePreview &&
                                            templateType ===
                                                'dividend_announcement' && (
                                                <Grid>
                                                    <Typography
                                                        component="h1"
                                                        className={`${classes.blueText} ${classes.centerText}`}
                                                    >
                                                        Announcement
                                                    </Typography>{' '}
                                                    <Grid item xs={12}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                        >
                                                            <br />
                                                            <InputLabel
                                                                className={
                                                                    listTemplateClasses.inputLabel
                                                                }
                                                                htmlFor="class"
                                                            >
                                                                Quarter
                                                            </InputLabel>
                                                            <Field
                                                                component={
                                                                    FormikSelect
                                                                }
                                                                name="quarter"
                                                                variant="outlined"
                                                                label="Quarter"
                                                                required
                                                                fullWidth
                                                                onClick={
                                                                    handleDataChange
                                                                }
                                                                defaultValue={
                                                                    'Q1'
                                                                }
                                                            >
                                                                <MenuItem
                                                                    key={'q1'}
                                                                    value={'Q1'}
                                                                >
                                                                    Q1
                                                                </MenuItem>
                                                                <MenuItem
                                                                    key={'q2'}
                                                                    value={'Q2'}
                                                                >
                                                                    Q2
                                                                </MenuItem>
                                                                <MenuItem
                                                                    key={'q3'}
                                                                    value={'Q3'}
                                                                >
                                                                    Q3
                                                                </MenuItem>
                                                                <MenuItem
                                                                    key={'q4'}
                                                                    value={'Q4'}
                                                                >
                                                                    Q4
                                                                </MenuItem>
                                                            </Field>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                        >
                                                            {' '}
                                                            <br />
                                                            <InputLabel
                                                                className={
                                                                    listTemplateClasses.inputLabel
                                                                }
                                                                htmlFor="class"
                                                            >
                                                                Year
                                                            </InputLabel>
                                                            <Field
                                                                component={
                                                                    FormikSelect
                                                                }
                                                                name="year"
                                                                variant="outlined"
                                                                label="Year"
                                                                required
                                                                fullWidth
                                                                onClick={
                                                                    handleDataChange
                                                                }
                                                                defaultValue={
                                                                    '2021'
                                                                }
                                                            >
                                                                {buildDropdown(
                                                                    'year'
                                                                )}
                                                            </Field>
                                                        </Grid>{' '}
                                                        <br /> <br />
                                                        <Field
                                                            component={
                                                                TextField
                                                            }
                                                            type="text"
                                                            name="dividend_gross_amount_per_share"
                                                            variant="outlined"
                                                            //numeric={1}
                                                            required
                                                            fullWidth
                                                            label="Gross dividend per asset"
                                                            onKeyUp={
                                                                handleDataChange
                                                            }
                                                        ></Field>
                                                        <br /> <br />
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDateFns
                                                            }
                                                        >
                                                            <Field
                                                                component={
                                                                    FormikDatePicker
                                                                }
                                                                name="dividend_finalization_date"
                                                                value={
                                                                    finalizationDate
                                                                }
                                                                label="Finalization Date"
                                                                onChange={(
                                                                    date
                                                                ) =>
                                                                    handleDateChange(
                                                                        date,
                                                                        'dividend_finalization_date'
                                                                    )
                                                                }
                                                                format="MM/dd/yyyy"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                            <br /> <br />
                                                            <Field
                                                                component={
                                                                    FormikDatePicker
                                                                }
                                                                name="dividend_record_date"
                                                                value={
                                                                    recordDate
                                                                }
                                                                label="Record Date"
                                                                onChange={(
                                                                    date
                                                                ) =>
                                                                    handleDateChange(
                                                                        date,
                                                                        'dividend_record_date'
                                                                    )
                                                                }
                                                                format="MM/dd/yyyy"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                            <br /> <br />
                                                            <Field
                                                                component={
                                                                    FormikDatePicker
                                                                }
                                                                name="dividend_payment_date"
                                                                value={
                                                                    paymentDate
                                                                }
                                                                label="Payment Date"
                                                                onChange={(
                                                                    date
                                                                ) =>
                                                                    handleDateChange(
                                                                        date,
                                                                        'dividend_payment_date'
                                                                    )
                                                                }
                                                                format="MM/dd/yyyy"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <br />
                                                    <br />
                                                    <Typography
                                                        component="h1"
                                                        className={`${classes.blueText} ${classes.centerText}`}
                                                    >
                                                        Dividend Board
                                                        Resolution
                                                    </Typography>{' '}
                                                    <br />
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            {' '}
                                                            File to upload:{' '}
                                                            {dividendResolutions !==
                                                            null
                                                                ? dividendResolutions
                                                                : ''}
                                                        </Typography>
                                                        <br />
                                                        <div
                                                            aria-label="outlined primary button group"
                                                            className={
                                                                classes.alignLeft
                                                            }
                                                        >
                                                            <Button
                                                                color="secondary"
                                                                variant="outlined"
                                                                onClick={() => {
                                                                    removeFile();
                                                                }}
                                                                style={{
                                                                    marginRight:
                                                                        '0.7rem',
                                                                }}
                                                            >
                                                                Clear
                                                            </Button>
                                                            <Button
                                                                component="label"
                                                                variant="contained"
                                                                color="primary"
                                                                className={
                                                                    classes.alignCenter
                                                                }
                                                            >
                                                                Browse
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        uploadFile(
                                                                            e
                                                                                .target
                                                                                .files[0],
                                                                            'dividend_resolutions_document'
                                                                        );
                                                                    }}
                                                                    style={{
                                                                        display:
                                                                            'none',
                                                                    }}
                                                                    type="file"
                                                                    name="item_value"
                                                                />
                                                            </Button>
                                                        </div>{' '}
                                                    </Grid>
                                                    <br />
                                                    <Typography
                                                        component="h1"
                                                        className={`${classes.blueText} ${classes.centerText}`}
                                                    >
                                                        Cash Dividend
                                                    </Typography>{' '}
                                                    <br />
                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        name="authorized_shares"
                                                        variant="outlined"
                                                        numeric={1}
                                                        required
                                                        fullWidth
                                                        label="Authorised Assets in Issue"
                                                        onKeyUp={
                                                            handleDataChange
                                                        }
                                                    ></Field>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                    >
                                                        <br />
                                                        <InputLabel
                                                            className={
                                                                listTemplateClasses.inputLabel
                                                            }
                                                            htmlFor="cash_dividend_type"
                                                        >
                                                            Type
                                                        </InputLabel>
                                                        <Field
                                                            component={
                                                                FormikSelect
                                                            }
                                                            name="cash_dividend_type"
                                                            variant="outlined"
                                                            label="Type"
                                                            required
                                                            fullWidth
                                                            onClick={
                                                                handleDataChange
                                                            }
                                                            defaultValue={
                                                                'Interim'
                                                            }
                                                        >
                                                            <MenuItem
                                                                key={'maiden'}
                                                                value={'Maiden'}
                                                            >
                                                                Maiden
                                                            </MenuItem>
                                                            <MenuItem
                                                                key={'interim'}
                                                                value={
                                                                    'Interim'
                                                                }
                                                            >
                                                                Interim
                                                            </MenuItem>
                                                            <MenuItem
                                                                key={'final'}
                                                                value={'Final'}
                                                            >
                                                                Final
                                                            </MenuItem>
                                                        </Field>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                    >
                                                        {' '}
                                                        <br />
                                                        <InputLabel
                                                            className={
                                                                listTemplateClasses.inputLabel
                                                            }
                                                            htmlFor="class"
                                                        >
                                                            Dividend Declared
                                                            out of income
                                                        </InputLabel>
                                                        <Field
                                                            component={
                                                                FormikSelect
                                                            }
                                                            name="dividend_declared_out_of_income"
                                                            variant="outlined"
                                                            label="Dividend Declared out of income"
                                                            required
                                                            fullWidth
                                                            onClick={
                                                                handleDataChange
                                                            }
                                                            defaultValue={
                                                                'Working capital'
                                                            }
                                                        >
                                                            <MenuItem
                                                                key={'reserves'}
                                                                value={
                                                                    'Reserves'
                                                                }
                                                            >
                                                                Reserves
                                                            </MenuItem>{' '}
                                                            <MenuItem
                                                                key={
                                                                    'working_capital'
                                                                }
                                                                value={
                                                                    'Working capital'
                                                                }
                                                            >
                                                                Working capital
                                                            </MenuItem>
                                                        </Field>
                                                    </Grid>
                                                    <br />
                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        name="subject_securities_class"
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        label="Class of Subject Securities"
                                                        onKeyUp={
                                                            handleDataChange
                                                        }
                                                    ></Field>
                                                    <br />
                                                    <br />
                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        name="local_dividend_tax_rate"
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        label="Local Dividend tax rate"
                                                        onKeyUp={
                                                            handleDataChange
                                                        }
                                                    ></Field>{' '}
                                                    <br />
                                                    <br />
                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        name="other_relevant_information"
                                                        variant="outlined"
                                                        fullWidth
                                                        multiline
                                                        rows={3}
                                                        label="Any other additional information which is relevant to the corporate action"
                                                        onKeyUp={
                                                            handleDataChange
                                                        }
                                                    ></Field>{' '}
                                                    <br />
                                                    <br />
                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        name="contact"
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        label="Details of investor relationship contact at MERJ Exchange issuer"
                                                        onKeyUp={
                                                            handleDataChange
                                                        }
                                                    ></Field>{' '}
                                                    <br />
                                                    <br />
                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        name="secretary_director_name"
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        label="Name of Company Secretary/Directors"
                                                        onKeyUp={
                                                            handleDataChange
                                                        }
                                                    ></Field>
                                                    {error && (
                                                        <FormHelperText error>
                                                            Please enter all
                                                            required fields
                                                            (Gross dividend per
                                                            asset and authorised
                                                            asset fields have to
                                                            be greater than 0)
                                                        </FormHelperText>
                                                    )}
                                                </Grid>
                                            )}

                                        {templatePreview &&
                                            templateType ===
                                                'dividend_announcement' && (
                                                <div>
                                                    {templateHeader()}
                                                    <h3
                                                        className={
                                                            classes.centerText
                                                        }
                                                    >
                                                        {' '}
                                                        <hr />
                                                        ANNOUNCEMENT: DIVIDEND
                                                        FOR{' '}
                                                        {displayFormData(
                                                            templateInitialValues.quarter,
                                                            'quarter'
                                                        )}{' '}
                                                        &{' '}
                                                        {displayFormData(
                                                            templateInitialValues.year,
                                                            'year'
                                                        )}
                                                        <hr />
                                                    </h3>
                                                    Notice is hereby given that
                                                    the{' '}
                                                    {displayFormData(
                                                        templateInitialValues.quarter,
                                                        'dividend quarter'
                                                    )}{' '}
                                                    dividend, being a gross
                                                    dividend of $
                                                    {displayFormData(
                                                        templateInitialValues.dividend_gross_amount_per_share,
                                                        'gross dividend per asset'
                                                    )}{' '}
                                                    per asset has been
                                                    recommended by the Board
                                                    from income reserves in
                                                    respect of the{' '}
                                                    {displayFormData(
                                                        templateInitialValues.quarter,
                                                        'quarter'
                                                    )}{' '}
                                                    quarter of{' '}
                                                    {displayFormData(
                                                        templateInitialValues.year,
                                                        'year'
                                                    )}{' '}
                                                    payable to holders recorded
                                                    in the members’ register of
                                                    the company at the close of
                                                    business on{' '}
                                                    {displayFormData(
                                                        recordDate,
                                                        ''
                                                    )}
                                                    .<br /> <br />
                                                    The relevant dates for the
                                                    payment of the dividend are
                                                    as follows:
                                                    <ul>
                                                        <li>
                                                            Finalization date:{' '}
                                                            {displayFormData(
                                                                finalizationDate,
                                                                ''
                                                            )}
                                                        </li>
                                                        <li>
                                                            Record date: close
                                                            of market{' '}
                                                            {displayFormData(
                                                                recordDate,
                                                                ''
                                                            )}
                                                        </li>
                                                        <li>
                                                            Ex Date{' '}
                                                            {displayFormData(
                                                                exDividendDate,
                                                                ''
                                                            )}
                                                        </li>
                                                        <li>
                                                            Payment date:{' '}
                                                            {displayFormData(
                                                                paymentDate,
                                                                ''
                                                            )}
                                                        </li>
                                                    </ul>
                                                    <div>
                                                        <br />
                                                        {today} <br />
                                                        <br />
                                                        Listing Advisor
                                                        <br />
                                                        <br />
                                                        DIGTL
                                                        <br />
                                                        <br />
                                                    </div>
                                                    <div>
                                                        <div
                                                            className={
                                                                classes.centerText
                                                            }
                                                        >
                                                            <b>
                                                                CASH DIVIDEND -
                                                                CORPORATE ACTION
                                                                NOTIFICATION
                                                                FORMS
                                                            </b>
                                                        </div>
                                                        <br />
                                                        <Table>
                                                            <TableRow>
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    Name of
                                                                    Subject
                                                                    Company
                                                                </TableCell>

                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    {displayFormData(
                                                                        issuerData.legal_entity_name,
                                                                        ''
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    Company
                                                                    registration
                                                                    number
                                                                </TableCell>

                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    {displayFormData(
                                                                        issuerData.company_registration_number,
                                                                        ''
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    ISIN
                                                                </TableCell>

                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    {displayFormData(
                                                                        issuerData.isin,
                                                                        ''
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    Authorised
                                                                    Assets in
                                                                    Issue
                                                                </TableCell>

                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    {displayFormData(
                                                                        templateInitialValues.authorized_shares,
                                                                        ''
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    Maiden/
                                                                    interim/
                                                                    Final
                                                                </TableCell>

                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    {displayFormData(
                                                                        templateInitialValues.cash_dividend_type,
                                                                        ''
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    Dividend
                                                                    Declared out
                                                                    of income
                                                                    <br />
                                                                    reserves/
                                                                    working
                                                                    capital
                                                                </TableCell>

                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    {displayFormData(
                                                                        templateInitialValues.dividend_declared_out_of_income,
                                                                        ''
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    Name of
                                                                    Person(s)
                                                                    Furnishing
                                                                    Form
                                                                </TableCell>

                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    {displayFormData(
                                                                        'DIGTL, Listing Advisor',
                                                                        ''
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    Class of
                                                                    Subject
                                                                    Securities
                                                                </TableCell>

                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    {displayFormData(
                                                                        templateInitialValues.subject_securities_class,
                                                                        ''
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    Last date to
                                                                    trade
                                                                </TableCell>

                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    {displayFormData(
                                                                        recordDate,
                                                                        ''
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    Ex date
                                                                </TableCell>

                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    {displayFormData(
                                                                        exDividendDate,
                                                                        ''
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    Record date
                                                                </TableCell>

                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    {displayFormData(
                                                                        recordDate,
                                                                        ''
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    Payment date
                                                                </TableCell>

                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    {displayFormData(
                                                                        paymentDate,
                                                                        ''
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    Price/value
                                                                    of corporate
                                                                    action
                                                                </TableCell>

                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    {displayFormData(
                                                                        templateInitialValues.dividend_gross_amount_per_share,
                                                                        ''
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    Local
                                                                    Dividend tax
                                                                    rate
                                                                </TableCell>

                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    {displayFormData(
                                                                        templateInitialValues.local_dividend_tax_rate,
                                                                        ''
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    Any other
                                                                    additional
                                                                    information
                                                                    which is
                                                                    relevant to
                                                                    the
                                                                    corporate
                                                                    action
                                                                </TableCell>

                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    {displayFormData(
                                                                        templateInitialValues.other_relevant_information,
                                                                        ''
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        </Table>
                                                        <br />
                                                        <span>
                                                            {' '}
                                                            Note: All corporate
                                                            actions must contain
                                                            a note stating the
                                                            “corporate action
                                                            will be subject to
                                                            the relevant local
                                                            dividend tax for
                                                            residents of
                                                            Seychelles or
                                                            withholding tax for
                                                            non-Seychellois.”
                                                        </span>
                                                        <br />
                                                        <br />
                                                        <Table>
                                                            <TableRow>
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    Details of
                                                                    investor
                                                                    relationship
                                                                    contact at
                                                                    MERJ
                                                                    Exchange
                                                                    issuer
                                                                </TableCell>

                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    {displayFormData(
                                                                        templateInitialValues.contact,
                                                                        ''
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    Details of
                                                                    Sponsor
                                                                    Advisor
                                                                </TableCell>

                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    {displayFormData(
                                                                        'DIGTL',
                                                                        ''
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    Name of
                                                                    Company
                                                                    Secretary/Directors
                                                                </TableCell>

                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    {displayFormData(
                                                                        templateInitialValues.secretary_director_name,
                                                                        ''
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        </Table>
                                                    </div>
                                                </div>
                                            )}

                                        {!templatePreview &&
                                            templateType ===
                                                'agm_announcement' && (
                                                <Grid>
                                                    <Grid item xs={12}>
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDateFns
                                                            }
                                                        >
                                                            <Field
                                                                component={
                                                                    FormikDateTimePicker
                                                                }
                                                                name="agm_date"
                                                                value={agmDate}
                                                                label="AGM Date"
                                                                onChange={(
                                                                    date
                                                                ) =>
                                                                    handleDateChange(
                                                                        date,
                                                                        'agm_date',
                                                                        'date_time'
                                                                    )
                                                                }
                                                                format="MM/dd/yyyy HH:mm"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                            <br /> <br />
                                                            <Field
                                                                component={
                                                                    FormikDatePicker
                                                                }
                                                                name="shareholder_record_date"
                                                                value={
                                                                    shareholderRecordDate
                                                                }
                                                                label="AGM holder record date"
                                                                onChange={(
                                                                    date
                                                                ) =>
                                                                    handleDateChange(
                                                                        date,
                                                                        'shareholder_record_date'
                                                                    )
                                                                }
                                                                format="MM/dd/yyyy"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </LocalizationProvider>
                                                        <br />
                                                        <br />
                                                        <Field
                                                            component={
                                                                TextField
                                                            }
                                                            type="number"
                                                            name="number_of_resolutions"
                                                            variant="outlined"
                                                            //numeric={1}
                                                            required
                                                            fullWidth
                                                            label="Number of resolutions"
                                                            onKeyUp={
                                                                handleResolutionsNumberChange
                                                            }
                                                        ></Field>
                                                        {buildResolutionFields(
                                                            numberOfResolutions
                                                        )}
                                                    </Grid>

                                                    {error && (
                                                        <FormHelperText error>
                                                            Please enter all
                                                            required fields
                                                            (there has to be at
                                                            least one
                                                            resolution)
                                                        </FormHelperText>
                                                    )}
                                                </Grid>
                                            )}

                                        {templatePreview &&
                                            templateType ===
                                                'agm_announcement' && (
                                                <div>
                                                    {' '}
                                                    {templateHeader()}
                                                    <h3
                                                        className={
                                                            classes.centerText
                                                        }
                                                    >
                                                        <hr />
                                                        ANNOUNCEMENT: NOTICE OF
                                                        THE ANNUAL GENERAL
                                                        MEETING
                                                        <hr />
                                                    </h3>
                                                    <div>
                                                        Notice is hereby given
                                                        to holders recorded in
                                                        the Company’s securities
                                                        register that on{' '}
                                                        {displayFormData(
                                                            new Date(
                                                                agmDate
                                                            ).getMonth() +
                                                                1 +
                                                                '/' +
                                                                new Date(
                                                                    agmDate
                                                                ).getDate() +
                                                                '/' +
                                                                new Date(
                                                                    agmDate
                                                                ).getFullYear(),
                                                            'date of AGM'
                                                        )}{' '}
                                                        that the Annual General
                                                        Meeting (“AGM”) of{' '}
                                                        {displayFormData(
                                                            issuerData.legal_entity_name,
                                                            'company legal name'
                                                        )}
                                                        will be held for the
                                                        year{' '}
                                                        {displayFormData(
                                                            new Date(
                                                                agmDate
                                                            ).getMonth() +
                                                                1 +
                                                                '/' +
                                                                new Date(
                                                                    agmDate
                                                                ).getDate() +
                                                                '/' +
                                                                new Date(
                                                                    agmDate
                                                                ).getFullYear(),
                                                            'year of AGM'
                                                        )}{' '}
                                                        via electronic
                                                        communication at{' '}
                                                        {displayFormData(
                                                            new Date(
                                                                agmDate
                                                            ).getHours() +
                                                                ':' +
                                                                new Date(
                                                                    agmDate
                                                                ).getMinutes(),
                                                            'time'
                                                        )}{' '}
                                                        to (i) deal with such
                                                        business as may lawfully
                                                        be dealt with at the
                                                        Meeting and (ii)
                                                        consider and, if deemed
                                                        fit, pass, with or
                                                        without modification,
                                                        the Ordinary and Special
                                                        Resolutions set out
                                                        hereunder, as read with
                                                        the MERJ Limited
                                                        Listings Requirements
                                                        (“MERJ Listings
                                                        Requirements”) where the
                                                        Company’s preferred
                                                        assets are listed. The
                                                        Meeting is to be
                                                        participated in by
                                                        holders recorded in the
                                                        Company’s securities
                                                        register as at the
                                                        voting record date of{' '}
                                                        {displayFormData(
                                                            shareholderRecordDate,
                                                            'AGM holder record date'
                                                        )}
                                                        .
                                                    </div>
                                                    <br />
                                                    <div>
                                                        Kindly note that to
                                                        participate, all holders
                                                        will be required to
                                                        register no later than
                                                        48 hours before the time
                                                        of the meeting. Full
                                                        details of the
                                                        registration process and
                                                        requirements will be
                                                        provided to holders.{' '}
                                                    </div>
                                                    <h3>
                                                        Ordinary Resolutions{' '}
                                                    </h3>
                                                    <div>
                                                        To consider and, if
                                                        approved, to pass, with
                                                        or without modification,
                                                        the following{' '}
                                                        {displayFormData(
                                                            numberOfResolutions,
                                                            '# of resolutions'
                                                        )}{' '}
                                                        Ordinary Resolutions:
                                                    </div>{' '}
                                                    <br />
                                                    <div>
                                                        Percentage support
                                                        required for Ordinary
                                                        Resolution No 1 to 8 -
                                                        For these Ordinary
                                                        Resolutions to be
                                                        adopted, the support of
                                                        more than 50% (fifty per
                                                        cent) of the total
                                                        number of votes per
                                                        Ordinary Resolution,
                                                        from the holders present
                                                        at this Meeting is
                                                        required
                                                    </div>
                                                    <br />
                                                    {listResolutions()}
                                                    <div>
                                                        <br />
                                                        {today} <br />
                                                        <br />
                                                        Listing Advisor
                                                        <br />
                                                        <br />
                                                        DIGTL
                                                        <br />
                                                        <br />
                                                    </div>
                                                </div>
                                            )}

                                        {!templatePreview &&
                                            templateType === 'agm_results' && (
                                                <Grid item xs={12}>
                                                    <LocalizationProvider
                                                        dateAdapter={
                                                            AdapterDateFns
                                                        }
                                                    >
                                                        <Field
                                                            component={
                                                                FormikDatePicker
                                                            }
                                                            name="agm_date"
                                                            value={agmDate}
                                                            label="AGM Date"
                                                            onChange={(date) =>
                                                                handleDateChange(
                                                                    date,
                                                                    'agm_date'
                                                                )
                                                            }
                                                            format="MM/dd/yyyy"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                        <br /> <br />
                                                        <Field
                                                            component={
                                                                FormikDatePicker
                                                            }
                                                            name="agm_result_date"
                                                            value={
                                                                agmResultDate
                                                            }
                                                            label="AGM announcement date"
                                                            onChange={(date) =>
                                                                handleDateChange(
                                                                    date,
                                                                    'agm_result_date'
                                                                )
                                                            }
                                                            format="MM/dd/yyyy"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </LocalizationProvider>
                                                    <br />
                                                    <Grid item xs={12}>
                                                        <Field
                                                            component={
                                                                TextField
                                                            }
                                                            name="agm_result_text"
                                                            type="text"
                                                            label="Text"
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            required
                                                            multiline
                                                            rows={5}
                                                            onKeyUp={
                                                                handleDataChange
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}

                                        {templatePreview &&
                                            templateType === 'agm_results' && (
                                                <div>
                                                    {' '}
                                                    {templateHeader()}
                                                    <h3
                                                        className={
                                                            classes.centerText
                                                        }
                                                    >
                                                        <hr />
                                                        ANNOUNCEMENT: ANNUAL
                                                        GENERAL MEETING RESULTS
                                                        <hr />
                                                    </h3>
                                                    <div>
                                                        The Company would like
                                                        to inform the public
                                                        that following the
                                                        announcement made on{' '}
                                                        {displayFormData(
                                                            agmResultDate,
                                                            'AGM announcement date'
                                                        )}
                                                        , all resolutions were
                                                        passed at the Annual
                                                        General Meeting which
                                                        was held on{' '}
                                                        {displayFormData(
                                                            agmDate,
                                                            'AGM date'
                                                        )}{' '}
                                                        as follows:
                                                    </div>
                                                    <br />
                                                    {listResolutions()}
                                                    <br /> <br />
                                                    <div>
                                                        {' '}
                                                        {
                                                            templateInitialValues.agm_result_text
                                                        }
                                                    </div>
                                                    <div>
                                                        <br /> {today} <br />
                                                        Listing Advisor
                                                        <br />
                                                        DIGTL
                                                        <br />
                                                    </div>
                                                </div>
                                            )}

                                        {!templatePreview &&
                                            templateType ===
                                                'closed_period_start_announcement' && (
                                                <Grid item xs={12}>
                                                    <LocalizationProvider
                                                        dateAdapter={
                                                            AdapterDateFns
                                                        }
                                                    >
                                                        <Field
                                                            component={
                                                                FormikDatePicker
                                                            }
                                                            name="closed_period_start_date"
                                                            value={
                                                                closedPeriodStartDate
                                                            }
                                                            label="Start date of closed period"
                                                            onChange={(date) =>
                                                                handleDateChange(
                                                                    date,
                                                                    'closed_period_start_date'
                                                                )
                                                            }
                                                            format="MM/dd/yyyy"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                        <br /> <br />
                                                        <Field
                                                            component={
                                                                FormikDatePicker
                                                            }
                                                            name="period_end_date"
                                                            value={
                                                                periodEndDate
                                                            }
                                                            label="Period end date of financials"
                                                            onChange={(date) =>
                                                                handleDateChange(
                                                                    date,
                                                                    'period_end_date'
                                                                )
                                                            }
                                                            format="MM/dd/yyyy"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                        <br /> <br />
                                                        <Field
                                                            component={
                                                                FormikDatePicker
                                                            }
                                                            name="financials_posting_anticipated_date"
                                                            value={
                                                                financialsPostingAnticipatedDate
                                                            }
                                                            label="Anticipated date of posting of financials"
                                                            onChange={(date) =>
                                                                handleDateChange(
                                                                    date,
                                                                    'financials_posting_anticipated_date'
                                                                )
                                                            }
                                                            format="MM/dd/yyyy"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                            )}

                                        {templatePreview &&
                                            templateType ===
                                                'closed_period_start_announcement' && (
                                                <div>
                                                    {' '}
                                                    {templateHeader()}
                                                    <h3
                                                        className={
                                                            classes.centerText
                                                        }
                                                    >
                                                        {' '}
                                                        <hr />
                                                        ANNOUNCEMENT: CLOSED
                                                        PERIOD
                                                        <hr />
                                                    </h3>
                                                    <div>
                                                        In terms of Section 4.10
                                                        of the MERJ Listing
                                                        Requirements, the
                                                        Company would like to
                                                        inform all affected
                                                        persons that it will be
                                                        in a closed period for
                                                        the trading of its
                                                        assets from the close of
                                                        market on{' '}
                                                        {displayFormData(
                                                            closedPeriodStartDate,
                                                            'start date of closed period'
                                                        )}{' '}
                                                        until such time as the
                                                        audited financial
                                                        results for the period
                                                        ended{' '}
                                                        {displayFormData(
                                                            periodEndDate,
                                                            'period end date of financials'
                                                        )}{' '}
                                                        are published. The
                                                        Company anticipates that
                                                        the results will be
                                                        released by no later
                                                        than{' '}
                                                        {displayFormData(
                                                            financialsPostingAnticipatedDate,
                                                            'anticipated date of posting of financials'
                                                        )}
                                                        .
                                                    </div>
                                                    <br />
                                                    <div>
                                                        During this period, all
                                                        affected persons are
                                                        prohibited from dealing
                                                        in the Company’s assets,
                                                        as it could be implied
                                                        that they may be aware
                                                        of unpublished
                                                        price-sensitive
                                                        information. Unpublished
                                                        price-sensitive
                                                        information is
                                                        information affecting a
                                                        company which if
                                                        released is likely to
                                                        materially affect the
                                                        company’s asset price.
                                                    </div>
                                                    <br />
                                                    <div>
                                                        For the ease of
                                                        reference, the
                                                        definition of “affected
                                                        persons” is included
                                                        below: “affected person”
                                                        means in relation to{' '}
                                                        {displayFormData(
                                                            issuerData.issuer_name,
                                                            'issuer'
                                                        )}
                                                        :
                                                    </div>
                                                    <ol type="1">
                                                        <li>
                                                            any officer or other
                                                            person employed by
                                                            the Company in a
                                                            managerial or
                                                            executive capacity;
                                                            or
                                                        </li>
                                                        <li>
                                                            any officer or
                                                            person employed in
                                                            the finance
                                                            department of the
                                                            Company and the
                                                            finance department
                                                            of the Company’s
                                                            accountant; or
                                                        </li>
                                                        <li>
                                                            the Company’s board
                                                            of directors; or
                                                        </li>
                                                        <li>
                                                            any officer or other
                                                            person employed by
                                                            the Company’s
                                                            Listing Advisor,
                                                            external auditors or
                                                            external Company
                                                            Secretary who works
                                                            on the Company’s
                                                            account;
                                                        </li>
                                                        <li>
                                                            any of the following
                                                            in relation to any
                                                            of the persons
                                                            prescribed in 1)
                                                            through 4) –
                                                            <ol type="a">
                                                                <li>
                                                                    an
                                                                    associated
                                                                    person; and
                                                                </li>
                                                                <li>
                                                                    that
                                                                    person’s
                                                                    spouse; and
                                                                </li>
                                                                <li>
                                                                    that
                                                                    person’s
                                                                    spouse; and
                                                                </li>
                                                                <li>
                                                                    any person
                                                                    who has
                                                                    entered into
                                                                    an agreement
                                                                    with that
                                                                    person
                                                                    relating to
                                                                    a
                                                                    transaction
                                                                    in the
                                                                    securities
                                                                    of the MERJ
                                                                    EXCHANGE
                                                                    issuer; and
                                                                </li>
                                                                <li>
                                                                    a trust,
                                                                    company,
                                                                    holding
                                                                    company,
                                                                    subsidiary,
                                                                    group of
                                                                    companies or
                                                                    any other
                                                                    legal
                                                                    vehicle in
                                                                    which that
                                                                    person has
                                                                    any interest
                                                                    (other than
                                                                    a
                                                                    discretionary
                                                                    interest in
                                                                    the case of
                                                                    a trust
                                                                    beneficiary),
                                                                    assets,
                                                                    control or
                                                                    influence;
                                                                </li>
                                                            </ol>
                                                        </li>
                                                    </ol>
                                                    <div>
                                                        <br />
                                                        {today} <br />
                                                        <br />
                                                        Listing Advisor
                                                        <br />
                                                        <br />
                                                        DIGTL
                                                        <br />
                                                        <br />
                                                    </div>
                                                </div>
                                            )}

                                        {!templatePreview &&
                                            templateType ===
                                                'closed_period_end_announcement' && (
                                                <Grid item xs={12}>
                                                    <LocalizationProvider
                                                        dateAdapter={
                                                            AdapterDateFns
                                                        }
                                                    >
                                                        <Field
                                                            component={
                                                                FormikDatePicker
                                                            }
                                                            name="period_end_date"
                                                            value={
                                                                periodEndDate
                                                            }
                                                            label="Period end date of financials"
                                                            onChange={(date) =>
                                                                handleDateChange(
                                                                    date,
                                                                    'period_end_date'
                                                                )
                                                            }
                                                            format="MM/dd/yyyy"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                        <br /> <br />
                                                        <Field
                                                            component={
                                                                FormikDatePicker
                                                            }
                                                            name="closed_period_start_date"
                                                            value={
                                                                closedPeriodStartDate
                                                            }
                                                            label="Start date of closed period"
                                                            onChange={(date) =>
                                                                handleDateChange(
                                                                    date,
                                                                    'closed_period_start_date'
                                                                )
                                                            }
                                                            format="MM/dd/yyyy"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                            )}

                                        {templatePreview &&
                                            templateType ===
                                                'closed_period_end_announcement' && (
                                                <div>
                                                    {' '}
                                                    {templateHeader()}
                                                    <h3
                                                        className={
                                                            classes.centerText
                                                        }
                                                    >
                                                        {' '}
                                                        <hr />
                                                        ANNOUNCEMENT:
                                                        PUBLICATION OF INTERIM
                                                        RESULTS AND LIFTING OF
                                                        CLOSED PERIOD
                                                        <hr />
                                                    </h3>
                                                    <ol type="1">
                                                        <li>
                                                            The Company has
                                                            published its
                                                            Interim Results for
                                                            the period ended{' '}
                                                            {displayFormData(
                                                                periodEndDate,
                                                                'period end date of financials'
                                                            )}
                                                            .
                                                        </li>
                                                        <li>
                                                            Further to the
                                                            announcement dated{' '}
                                                            {displayFormData(
                                                                closedPeriodStartDate,
                                                                'closed period start announcement date'
                                                            )}
                                                            , the Company would
                                                            like to advise
                                                            “Affected Persons”
                                                            that the closed
                                                            period has been
                                                            lifted.
                                                        </li>
                                                    </ol>
                                                    <div>
                                                        <br />
                                                        {today} <br />
                                                        <br />
                                                        Listing Advisor
                                                        <br />
                                                        <br />
                                                        DIGTL
                                                        <br />
                                                        <br />
                                                    </div>
                                                </div>
                                            )}

                                        {!templatePreview &&
                                            templateType ===
                                                'miscellaneous_announcement' && (
                                                <Grid>
                                                    <Grid item xs={12}>
                                                        <Field
                                                            component={
                                                                TextField
                                                            }
                                                            type="text"
                                                            label="Announcement Name"
                                                            name="announcement_name"
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            required
                                                            onKeyUp={
                                                                handleDataChange
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Field
                                                            component={
                                                                TextField
                                                            }
                                                            name="announcement_body"
                                                            type="text"
                                                            label="Announcement Body"
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            required
                                                            multiline
                                                            rows={5}
                                                            onKeyUp={
                                                                handleDataChange
                                                            }
                                                        />
                                                    </Grid>
                                                    {error && (
                                                        <FormHelperText error>
                                                            Please enter all
                                                            required fields
                                                        </FormHelperText>
                                                    )}
                                                </Grid>
                                            )}

                                        {templatePreview &&
                                            templateType ===
                                                'miscellaneous_announcement' && (
                                                <div>
                                                    {templateHeader()}
                                                    <h3
                                                        className={
                                                            classes.centerText
                                                        }
                                                    >
                                                        <hr />
                                                        ANNOUNCEMENT:{' '}
                                                        {
                                                            templateInitialValues.announcement_name
                                                        }
                                                        <hr />
                                                    </h3>
                                                    <div>
                                                        {' '}
                                                        {
                                                            templateInitialValues.announcement_body
                                                        }
                                                    </div>
                                                    <div>
                                                        <br />
                                                        {today} <br />
                                                        <br />
                                                        Listing Advisor
                                                        <br />
                                                        <br />
                                                        DIGTL
                                                        <br />
                                                        <br />
                                                    </div>
                                                </div>
                                            )}

                                        <DialogActions
                                            className={
                                                listTemplateClasses.dialogActions
                                            }
                                        >
                                            <Button
                                                color="secondary"
                                                variant="outlined"
                                                onClick={() => {
                                                    closeDialog();
                                                }}
                                                disabled={isSubmitting}
                                            >
                                                <b>Cancel</b>
                                            </Button>
                                            {templatePreview === true ? (
                                                <ButtonGroup>
                                                    <Button
                                                        onClick={() =>
                                                            setTemplatePreview(
                                                                false
                                                            )
                                                        }
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        Edit
                                                    </Button>

                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={
                                                            !issuerData.is_available ||
                                                            isSubmitting
                                                        }
                                                    >
                                                        {!isSubmitting ? (
                                                            <span>Save</span>
                                                        ) : (
                                                            <CircularProgress
                                                                size={24}
                                                            />
                                                        )}
                                                    </Button>
                                                </ButtonGroup>
                                            ) : (
                                                <Button
                                                    onClick={() =>
                                                        validateForm(
                                                            templateType
                                                        )
                                                    }
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Preview
                                                </Button>
                                            )}
                                        </DialogActions>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </LockedTile>
    );
};
const mapStateToProps = ({ listTemplates, profile }) => {
    return {
        listTemplates: listTemplates,
        profile: profile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        listTemplateActions: bindActionCreators(listTemplateActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsTemplates);
