import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as profileActions from '../../../store/profile/profileActions';
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import FormPanel from '../../../components/FormPanel/FormPanel';
import FormPanelRow from '../../../components/FormPanelRow/FormPanelRow';
import TextField from '../../../components/TextField/TextField';
import request from '../../../utilities/request';
import { isLargeScreen } from '../../../utilities/utils';
import * as config from '../../../config';
import HelpTooltip from '../../../components/HelpTooltip/HelpTooltip';
import authStyles from '../authStyles';
import ButtonGroup from '@mui/material/ButtonGroup';
import Paper from '../../../components/StyledPaper';
import Grid from '@mui/material/Grid';
import * as registry from '../../../utilities/registry';
import FormikAutocomplete from '../../../components/FormikAutocomplete';
import { SUBSCRIPTION_ENTERPRISE } from '../../../constants/profile';
const initialValues = {
    email: '',
    created_at: '',
    legal_entity_name: '',
    organization_code: '',
    incorporation_country: '',
    company_name: '',
    status: '',
    company_registration_number: '',
    company_share_code: '',
    isin: '',
    company_registered_address: '',
};

const validationSchema = Yup.object({
    legal_entity_name: Yup.string().required(
        'Please enter your legal entity name'
    ),
    company_name: Yup.string().required('Please enter your company name'),
    incorporation_country: Yup.string().required(
        'Please enter your country of incorporation'
    ),
});

const formData = new FormData();
formData.append('logo', '');

const CompanyDetails = (props) => {
    const dispatch = useDispatch();
    const classes = authStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({ ...initialValues });
    const [newLogo, setNewLogo] = useState('');
    const [isUploading, setIsUploading] = useState(false);

    const uploadFile = (file) => {
        formData.set('logo', file, file.name);
        setNewLogo(file.name);
    };

    const uploadLogo = () => {
        setIsUploading(true);
        request
            .post(`${config.API_BASE_URL}/company/logo`, formData)
            .then((response) => {
                localStorage.setItem('company-logo', response.data.companyLogo);
                dispatch(profileActions.getProfile());
                enqueueSnackbar('The new logo has been uploaded', {
                    variant: 'success',
                });
            })
            .catch(() => {
                enqueueSnackbar('An error occurred while saving the new logo', {
                    variant: 'error',
                });
            })
            .finally(() => {
                setNewLogo('');
                setIsUploading(false);
            });
    };

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            const response = await request.get(
                `${config.API_BASE_URL}/company/`
            );

            for (let prop in response.data) {
                if (
                    response.data[prop] === undefined ||
                    response.data[prop] === null
                ) {
                    response.data[prop] = '';
                }
            }

            setData({ ...initialValues, ...response.data });
            setIsLoading(false);
        }
        fetchData();
    }, []);

    return (
        <React.Fragment>
            {!isLoading ? (
                <Formik
                    initialValues={data}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        closeSnackbar();
                        request
                            .patch(`${config.API_BASE_URL}/company`, values)
                            .then(() => {
                                dispatch(profileActions.getProfile());
                                enqueueSnackbar(
                                    'The changes to your profile have been saved',
                                    { variant: 'success' }
                                );
                            })
                            .catch(() => {
                                enqueueSnackbar(
                                    'An error occurred while saving the changes',
                                    { variant: 'error' }
                                );
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {({ isSubmitting }) => (
                        <Paper>
                            <Typography variant="overline">
                                <h1>Company Information</h1>
                            </Typography>
                            <Form noValidate>
                                <FormPanel>
                                    <FormPanelRow></FormPanelRow>
                                    <FormPanelRow
                                        className={
                                            isLargeScreen()
                                                ? null
                                                : 'smallScreenWidth'
                                        }
                                    >
                                        <Field
                                            component={TextField}
                                            name="company_name"
                                            type="text"
                                            label="Company Name"
                                            required
                                            fullWidth={isLargeScreen()}
                                        />{' '}
                                    </FormPanelRow>

                                    <FormPanelRow
                                        className={
                                            isLargeScreen()
                                                ? null
                                                : 'smallScreenWidth'
                                        }
                                    >
                                        <Field
                                            component={FormikAutocomplete}
                                            name="incorporation_country"
                                            variant="outlined"
                                            label="Country of Incorporation *"
                                            fullWidth={isLargeScreen()}
                                            inputProps={{
                                                id: 'incorporation_country',
                                            }}
                                            optionKey="name"
                                            dataKey="id"
                                            required
                                            options={registry.countries}
                                        />
                                    </FormPanelRow>
                                    <FormPanelRow
                                        className={
                                            isLargeScreen()
                                                ? null
                                                : 'smallScreenWidth'
                                        }
                                    >
                                        <Field
                                            component={TextField}
                                            name="company_registration_number"
                                            fullWidth={isLargeScreen()}
                                            type="text"
                                            label="Company Registration Number"
                                        />
                                    </FormPanelRow>
                                    <FormPanelRow
                                        className={
                                            isLargeScreen()
                                                ? null
                                                : 'smallScreenWidth'
                                        }
                                    >
                                        <Field
                                            component={TextField}
                                            name="company_registered_address"
                                            type="text"
                                            label="Company Registered Address"
                                            fullWidth={isLargeScreen()}
                                        />
                                    </FormPanelRow>
                                    {props &&
                                        props.profile &&
                                        props.profile.subscription_model &&
                                        props.profile.subscription_model ===
                                            SUBSCRIPTION_ENTERPRISE && (
                                            <div>
                                                <FormPanelRow
                                                    className={
                                                        isLargeScreen()
                                                            ? null
                                                            : 'smallScreenWidth'
                                                    }
                                                >
                                                    <Field
                                                        component={TextField}
                                                        name="company_share_code"
                                                        type="text"
                                                        label="Company ticker (asset code)"
                                                        fullWidth={isLargeScreen()}
                                                    />
                                                </FormPanelRow>
                                                <FormPanelRow
                                                    className={
                                                        isLargeScreen()
                                                            ? null
                                                            : 'smallScreenWidth'
                                                    }
                                                >
                                                    <Field
                                                        component={TextField}
                                                        name="isin"
                                                        type="text"
                                                        label="ISIN"
                                                        fullWidth={isLargeScreen()}
                                                    />
                                                </FormPanelRow>
                                            </div>
                                        )}
                                </FormPanel>
                                <FormPanel>
                                    <FormPanelRow
                                        title="Upload Logo"
                                        description={
                                            <HelpTooltip
                                                title={
                                                    'This will replace the current logo. Recommended upload: 250x50 px, transparent .png.'
                                                }
                                            />
                                        }
                                    >
                                        {isUploading ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            <ButtonGroup>
                                                <Button
                                                    component="label"
                                                    variant="outlined"
                                                    color="primary"
                                                    className={
                                                        classes.buttonLabel
                                                    }
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    Browse
                                                    <input
                                                        onChange={(e) => {
                                                            uploadFile(
                                                                e.target
                                                                    .files[0]
                                                            );
                                                        }}
                                                        style={{
                                                            display: 'none',
                                                        }}
                                                        type="file"
                                                        name="new_logo"
                                                        accept=".png"
                                                    />
                                                </Button>
                                                <Button
                                                    component="label"
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={newLogo === ''}
                                                    className={classes.button}
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                    }}
                                                    type="submit"
                                                    onClick={() => uploadLogo()}
                                                >
                                                    Upload
                                                </Button>
                                            </ButtonGroup>
                                        )}
                                        {newLogo !== '' ? (
                                            <Typography component="div">
                                                {newLogo}
                                            </Typography>
                                        ) : null}
                                    </FormPanelRow>
                                </FormPanel>
                                <FormPanel>
                                    <FormPanelRow>
                                        <div className={classes.flexCenter}>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                variant="outlined"
                                                className={classes.buttonLabel}
                                                disabled={isSubmitting}
                                            >
                                                {!isSubmitting ? (
                                                    <span>Save</span>
                                                ) : (
                                                    <CircularProgress
                                                        size={24}
                                                    />
                                                )}
                                            </Button>
                                        </div>
                                    </FormPanelRow>
                                </FormPanel>
                            </Form>
                        </Paper>
                    )}
                </Formik>
            ) : (
                <Grid container item xs={12} justifyContent="center">
                    <CircularProgress />
                </Grid>
            )}
        </React.Fragment>
    );
};
const mapStateToProps = ({ profile }) => {
    return {
        profile: profile,
    };
};

export default connect(mapStateToProps, null)(CompanyDetails);
