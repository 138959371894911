import useSWR from "swr"
import { API_BASE_URL } from "../../config"
import fetcher from "../../utilities/fetcher"

const useAssetTransactions = (assetId) => {
    const { data, error, isValidating } = useSWR(() => assetId ? `${API_BASE_URL}/blockchain/transactions/${assetId}` : null, fetcher)

    return {
        assetTransactions: data || [],
        assetTransactionsLoading: !error && !data,
        assetTransactionsError: error,
        assetTransactionsValidating: isValidating,
    }
}

export default useAssetTransactions