import request from '../../utilities/request';
import * as config from '../../config';
import * as transferTypes from './transferTypes';

export const getTransfersSuccess = (summary) => {
    return {
        type: transferTypes.GET_TRANSFERS_SUCCESS,
        payload: summary,
    };
};

export const setTransfersLoading = (loading) => {
    return {
        type: transferTypes.SET_TRANSFERS_LOADING,
        payload: loading,
    };
};

export const getSummarySuccess = (summary) => {
    return {
        type: transferTypes.GET_SUMMARY_SUCCESS,
        payload: summary,
    };
};

export const setSummaryLoading = (loading) => {
    return {
        type: transferTypes.SET_SUMMARY_LOADING,
        payload: loading,
    };
};

export const getPendingTransfers = () => {
    return (dispatch) => {
        dispatch(setTransfersLoading(true));
        request
            .get(`${config.API_BASE_URL}/transfers?status=pending`)
            .then((res) => {
                dispatch(getTransfersSuccess(res));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setTransfersLoading(false));
            });
    };
};

export const getApprovedTransfers = () => {
    return (dispatch) => {
        dispatch(setTransfersLoading(true));
        request
            .get(`${config.API_BASE_URL}/transfers?status=approved`)
            .then((res) => {
                dispatch(getTransfersSuccess(res));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setTransfersLoading(false));
            });
    };
};
export const getAllTransfers = () => {
    return (dispatch) => {
        dispatch(setTransfersLoading(true));
        request
            .get(`${config.API_BASE_URL}/transfers`)
            .then((res) => {
                dispatch(getTransfersSuccess(res));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setTransfersLoading(false));
            });
    };
};

export const getSummary = () => {
    return (dispatch) => {
        dispatch(setSummaryLoading(true));
        request
            .get(`${config.API_BASE_URL}/transfers/summary`)
            .then((res) => {
                dispatch(getSummarySuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setSummaryLoading(false));
            });
    };
};
