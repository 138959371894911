import React from 'react';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import dashBoxStyles from '../styles/dashBoxStyles';
const DashBox = (props) => {
    const classes = dashBoxStyles();
    const location = useLocation();
    const history = useHistory();
    const { id, label, area, color, path } = props;

    const handleClick = () => {
        if (path) {
            history.push(`${location.pathname}${path}`);
        }
    };

    return (
        <div
            key={id}
            className={clsx(classes.container, classes[color], {
                [classes.disabled]: !path && id !== 'welcome',
            })}
            style={{ gridArea: area }}
            onClick={handleClick}
        >
            {label}
        </div>
    );
};

export default DashBox;
