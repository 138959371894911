import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import request from '../../../utilities/request';

import * as config from './../../../config';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import authStyles from '../authStyles';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '../../../components/TextField/TextField';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import * as registry from '../../../utilities/registry';
import FormikSelect from '../../../components/Formik/FormikSelect';
import { CheckboxWithLabel } from 'formik-mui';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { useDispatch } from 'react-redux';
import { snackbar } from '../../../utilities/snackbarUtils';
import CircularProgress from '@mui/material/CircularProgress';
import { downloadCapTableTemplate } from '../../../utilities/utils';
import RemoveDocumentButton from '../../../components/Button/RemoveDocumentButton';
import { handleLogin } from '../../../utilities/login';
import { SUBSCRIPTION_PRO } from '../../../constants/profile';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as paymentActions from '../../../store/payment/paymentActions';
import { upgradeSubscription } from '../../../utilities/payments';
import FormikAutocomplete from '../../../components/FormikAutocomplete';

//import { DropzoneDialog } from 'material-ui-dropzone';
const validationSchema = Yup.object({
    company_name: Yup.string().required('Please enter company name'),
    legal_entity_name: Yup.string().required('Please enter legal entity name'),
    registered_country: Yup.string().required(
        'Please enter country of incorporation'
    ),
    authorized_shares: Yup.string().required(
        'Please provide number of authorized assets'
    ),
    /*authorized_shares: Yup.number().required(
        'Please enter number of authorized assets'
    ),*/
    // total_number_of_fully_diluted_shares: Yup.string().required(
    //     'Please enter the total number of outstanding assets.'
    // ),
    /*    cap_table_input_type: Yup.string().required(
        'Please select how do you want to add Cap Table'
    ),*/
});

const CompanyInformation = (props) => {
    const classes = authStyles();
    const history = useHistory();

    const { payment, paymentActions } = props;

    const [inputType, setInputType] = useState(props.initialValues.input_type);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    let [data, setData] = useState(props.initialValues);
    let [setupWizard] = useState(props.setupWizard);
    const [isLoading, setIsLoading] = useState(false);
    const [requiredData, setRequiredData] = useState([]);

    const [csvTemplate, setCsvTemplate] = useState(false);
    const [fileName, setFileName] = useState('');
    const [file, setFile] = useState();

    const [shareholderNumber, setShareholderNumber] = useState(
        props.initialValues.number_of_shareholders !== ''
            ? props.initialValues.number_of_shareholders
            : null
    );

    const dispatch = useDispatch();
    const uploadFile = (file) => {
        let type = file.type;
        if (file.type === '' && file.name.split('.')[1] === 'csv') {
            type = 'text/csv';
        }

        if (
            type !== 'application/pdf' &&
            type !== 'text/csv' &&
            //    file.type !== 'text/plain' &&
            type !== 'application/vnd.ms-excel' &&
            type !==
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
            snackbar.success(
                'Only .csv, .xlx, .xls, .xlsx and .pdf files are allowed'
            );
        } else {
            props.formData.set('csv_template', file, file.name);
            setCsvTemplate(true);
            setFileName(file.name);
            setFile(file);
        }
    };

    const cancelFile = (name) => {
        props.formData.set('csv_template', null);
        setCsvTemplate(false);
        setFileName(null);
        setFile(null);
    };

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            const response = await request.get(
                `${config.API_BASE_URL}/company/`
            );

            /*    props.initialValues.total_number_of_fully_diluted_shares = response.data.*/

            data['company_name'] = response.data.company_name;
            data['legal_entity_name'] = response.data.legal_entity_name;
            data['registered_country'] = response.data.incorporation_country;
            data['raised_capital'] =
                response.data.has_raised_capital === 1 ? true : false;
            data['intend_to_raise'] =
                response.data.will_raise === 1 ? true : false;
            data['number_of_shareholders'] = response.data.approx_shareholders
                ? response.data.approx_shareholders
                : props.initialValues.number_of_shareholders;
            data['authorized_shares'] = props.profileData
                ? props.profileData.authorized_shares
                    ? props.profileData.authorized_shares
                    : null
                : null;

            setData(data);
            setIsLoading(false);
        }
        if (setupWizard) {
            fetchData();
        }
    }, [data, setupWizard]);

    useEffect(() => {
        if (props.initialValues.subscription_type === SUBSCRIPTION_PRO) {
            paymentActions.getPricePlans();
        }
    }, []);

    const changeInputType = (e) => {
        setError(false);
        setInputType(e.target.value);
    };

    const setRequiredField = (e) => {
        if (e.target.name === 'company_name') {
            requiredData['company_name'] = e.target.value;
        } else if (e.target.name === 'legal_entity_name') {
            requiredData['legal_entity_name'] = e.target.value;
        }

        setRequiredData(requiredData);
        props.saveValues(requiredData);
    };

    const handleShareholderNumberChange = (e) => {
        setShareholderNumber(e.target.getAttribute('data-value'));
    };

    return (
        <div id="step4">
            {!isLoading ? (
                <Formik
                    initialValues={data}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        values['number_of_shareholders'] = shareholderNumber;

                        if (inputType === '') {
                            setError(true);
                        } else if (inputType === 'template') {
                            values['input_type'] = inputType;
                            values['file_name'] = fileName;

                            let data = new FormData();
                            for (var x in values) {
                                data.append(x, values[x]);
                            }

                            data.append('csv_template', file, fileName);

                            if (props.setupWizard) {
                                data.append('setup_wizard', true);
                            }

                            setFile();
                            setFileName('');
                            setCsvTemplate(false);

                            /* Choose appropriate Axios request type */
                            let r;
                            if (props.setupWizard) {
                                r = request;
                            } else {
                                r = axios;
                            }

                            r.post(
                                `${config.API_BASE_URL}/validate-template-data`,
                                data
                            )
                                .then(() => {
                                    props.saveValues(values);
                                    props.setCustomStep(9);
                                })
                                .catch((error) => {
                                    if (
                                        error.response &&
                                        error.response.data.message
                                    ) {
                                        snackbar.error(
                                            error.response.data.message
                                        );
                                    }

                                    setSubmitting(false);
                                });
                        } else if (inputType === 'dashboard') {
                            values['input_type'] = inputType;
                            props.saveValues(values);
                            setLoading(true);
                            axios
                                .post(`${config.API_BASE_URL}/register`, values)
                                .then((response) => {
                                    if (
                                        data.subscription_type ===
                                        SUBSCRIPTION_PRO
                                    ) {
                                        snackbar.success(
                                            'Your account has been created. You will now be redirected to complete your payment.'
                                        );
                                        setTimeout(() => {
                                            upgradeSubscription(
                                                payment,
                                                paymentActions,
                                                data.subscription_type.toUpperCase(),
                                                props.initialValues.email,
                                                props.initialValues.password
                                            );
                                        }, 3000);
                                    } else {
                                        snackbar.success(
                                            'Your account has been created.'
                                        );
                                        handleLogin(
                                            values['email'],
                                            values['password'],
                                            history,
                                            dispatch
                                        );
                                    }
                                })
                                .catch((error) => {
                                    setLoading(true);
                                    snackbar.error(
                                        error.response.data.email ||
                                            'An error occurred while creating your account'
                                    );
                                    setLoading(false);
                                });
                        } else if (inputType === 'manually') {
                            values['input_type'] = inputType;
                            props.saveValues(values);
                            props.nextStep();
                        }
                    }}
                >
                    {({ isSubmitting }) => (
                        <Paper className={classes.paper}>
                            <Typography
                                component="div"
                                style={{ textAlign: 'center' }}
                            >
                                <h2>Your Company Information</h2>
                            </Typography>
                            <Container maxWidth="sm">
                                <Form className={classes.form} noValidate>
                                    <Field
                                        component={TextField}
                                        type="company_name"
                                        label="Company Name"
                                        name="company_name"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        onKeyUp={setRequiredField}
                                    />
                                    <Field
                                        component={TextField}
                                        type="legal_entity_name"
                                        label="Legal Entity Name"
                                        name="legal_entity_name"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        onKeyUp={setRequiredField}
                                    />
                                    <Grid item xs={12}>
                                        <Field
                                            component={FormikAutocomplete}
                                            name="registered_country"
                                            variant="outlined"
                                            label="Country of Incorporation *"
                                            fullWidth
                                            inputProps={{
                                                id: 'registered_country',
                                            }}
                                            optionKey="name"
                                            dataKey="id"
                                            required
                                            options={registry.countries}
                                        />
                                    </Grid>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        numeric={1}
                                        label="Total Authorized Assets"
                                        name="authorized_shares"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                    <br />
                                    <Field
                                        component={CheckboxWithLabel}
                                        type="checkbox"
                                        name="raised_capital"
                                        Label={{
                                            label: (
                                                <div
                                                    className={classes.checkbox}
                                                >
                                                    I have raised outside
                                                    capital in the past
                                                </div>
                                            ),
                                        }}
                                    />
                                    <br />
                                    <Field
                                        component={CheckboxWithLabel}
                                        type="checkbox"
                                        name="intend_to_raise"
                                        Label={{
                                            label: (
                                                <div
                                                    className={classes.checkbox}
                                                >
                                                    I intend to raise capital in
                                                    the next 9 months
                                                </div>
                                            ),
                                        }}
                                    />
                                    <Grid item xs={12}>
                                        <Field
                                            component={FormikSelect}
                                            name="number_of_shareholders"
                                            variant="outlined"
                                            label="How many holders do you have today?"
                                            fullWidth
                                            onClick={
                                                handleShareholderNumberChange
                                            }
                                            value={shareholderNumber}
                                        >
                                            <MenuItem key={'<10'} value={'<10'}>
                                                {'<10'}
                                            </MenuItem>
                                            <MenuItem
                                                key={'10-25'}
                                                value={'10-25'}
                                            >
                                                {'10-25'}
                                            </MenuItem>
                                            <MenuItem key={'25+'} value={'25+'}>
                                                {'25+'}
                                            </MenuItem>
                                        </Field>
                                    </Grid>
                                    <br />
                                    <RadioGroup
                                        name="cap_table_input_type"
                                        row
                                        required
                                        defaultValue={inputType}
                                    >
                                        <FormControlLabel
                                            value="template"
                                            control={<Radio color="primary" />}
                                            label="Upload my cap table (with DIGTL's template)"
                                            labelPlacement="end"
                                            onChange={changeInputType}
                                        />
                                        <FormControlLabel
                                            value="manually"
                                            control={<Radio color="primary" />}
                                            label="Create my cap table manually"
                                            labelPlacement="end"
                                            onChange={changeInputType}
                                        />
                                        {props.setupWizard !== true && (
                                            <FormControlLabel
                                                value="dashboard"
                                                control={
                                                    <Radio color="primary" />
                                                }
                                                label="Skip for now, I'll do this from my Dashboard"
                                                labelPlacement="end"
                                                onChange={changeInputType}
                                            />
                                        )}
                                    </RadioGroup>
                                    {error === true ? (
                                        <Typography
                                            component="div"
                                            style={{
                                                color: '#ED7571',
                                                textAlign: 'center',
                                            }}
                                        >
                                            Please select how do you want to add
                                            Cap Table
                                        </Typography>
                                    ) : null}
                                    <FormHelperText error>
                                        <ErrorMessage name="cap_table_input_type" />
                                    </FormHelperText>
                                    {inputType === 'template' &&
                                    fileName !== '' ? (
                                        <Paper
                                            elevation={3}
                                            style={{
                                                borderRadius: 15,
                                                padding: 10,
                                            }}
                                        >
                                            <Typography
                                                component="p"
                                                variant="body2"
                                            >
                                                Uploaded file:
                                            </Typography>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    xs={9}
                                                    sm={9}
                                                    md={9}
                                                    justify="flex-start"
                                                >
                                                    <Typography
                                                        component="p"
                                                        variant="body1"
                                                    >
                                                        <b>{fileName}</b>
                                                    </Typography>
                                                </Grid>
                                                <RemoveDocumentButton
                                                    onClick={() => {
                                                        cancelFile();
                                                    }}
                                                />
                                            </Grid>
                                        </Paper>
                                    ) : null}
                                    <div>
                                        {!isSubmitting ? (
                                            <div
                                                className={classes.actions}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {inputType === 'template' ? (
                                                    <ButtonGroup
                                                        aria-label="outlined primary button group"
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Button
                                                            component="label"
                                                            variant="outlined"
                                                            className={
                                                                classes.buttonLabel
                                                            }
                                                            color="primary"
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'center',
                                                                alignItems:
                                                                    'center',
                                                            }}
                                                        >
                                                            UPLOAD
                                                            <input
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    uploadFile(
                                                                        e.target
                                                                            .files[0]
                                                                    );
                                                                    e.target.value =
                                                                        '';
                                                                }}
                                                                style={{
                                                                    display:
                                                                        'none',
                                                                }}
                                                                type="file"
                                                                name="file"
                                                                accept=".csv, text/csv, application/vnd.ms-excel,
                                                                application/csv, text/x-csv, application/x-csv,
                                                                text/comma-separated-values, text/x-comma-separated-values,
                                                                application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                            />
                                                        </Button>
                                                        {csvTemplate ===
                                                        true ? (
                                                            <Button
                                                                type="submit"
                                                                variant="outlined"
                                                                className={
                                                                    classes.buttonLabel
                                                                }
                                                                color="primary"
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    justifyContent:
                                                                        'center',
                                                                    alignItems:
                                                                        'center',
                                                                }}
                                                            >
                                                                CONTINUE
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                variant="outlined"
                                                                className={
                                                                    classes.secondaryButtonLabel
                                                                }
                                                                color="secondary"
                                                                onClick={
                                                                    downloadCapTableTemplate
                                                                }
                                                            >
                                                                Download
                                                                template
                                                            </Button>
                                                        )}
                                                    </ButtonGroup>
                                                ) : (
                                                    <Button
                                                        type="submit"
                                                        variant="outlined"
                                                        className={
                                                            classes.buttonLabel
                                                        }
                                                        color="primary"
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                        disabled={
                                                            inputType === '' ||
                                                            inputType ===
                                                                undefined ||
                                                            loading
                                                        }
                                                    >
                                                        {inputType === ''
                                                            ? 'Select how do you want to add Cap Table'
                                                            : 'CONTINUE'}
                                                        {loading && (
                                                            <CircularProgress
                                                                size={24}
                                                                className={
                                                                    classes.buttonProgress
                                                                }
                                                            />
                                                        )}
                                                    </Button>
                                                )}
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <CircularProgress size={24} />
                                            </div>
                                        )}{' '}
                                    </div>
                                </Form>
                            </Container>
                        </Paper>
                    )}
                </Formik>
            ) : (
                <Grid container item xs={12} justifyContent="center">
                    <CircularProgress />
                </Grid>
            )}
        </div>
    );
};

const mapStateToProps = ({ profile, payment }) => {
    return {
        payment: payment,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        paymentActions: bindActionCreators(paymentActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInformation);
