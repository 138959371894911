import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '../../../components/StyledPaper';
import UpdatePassword from './UpdatePassword';
import SubscriptionType from './SubscriptionType';
import IssuerInvites from '../../user/IssuerInvite/IssuerInvites';
import { useSnackbar } from 'notistack';
import authStyles from '../authStyles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as profileActions from '../../../store/profile/profileActions';
import { checkStripeReferrer } from '../../../utilities/payments';
import { SUBSCRIPTION_BASIC } from '../../../constants/profile';
const Preferences = (props) => {
    const classes = authStyles();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState(null);
    useEffect(() => {
        if (props.profile.preferred_tabs) {
            setState(props.profile.preferred_tabs);
        }
    }, [props.profile.preferred_tabs]);
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        checkStripeReferrer(
            'https://checkout.stripe.com/',
            'Your subscription was successful.',
            'Your subscription has failed. Please try again.'
        );
    }, []);

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const savePreferredTabs = () => {
        props.profileActions.updatePreferences(
            state,
            setLoading,
            enqueueSnackbar
        );
    };

    return (
        <div>
            <Grid container spacing={2}>
                <UpdatePassword />
                <SubscriptionType />

                {props.profile &&
                    props.profile.subscription_model &&
                    props.profile.subscription_model !== SUBSCRIPTION_BASIC && (
                        <Grid item xs={12} md={6}>
                            <Paper>
                                <Typography variant="overline">
                                    <h1>Tab Display</h1>
                                </Typography>
                                <FormControl
                                    component="fieldset"
                                    className="content"
                                >
                                    <FormLabel component="legend">
                                        Select DIGTL tabs that you want to see:
                                    </FormLabel>
                                    {state ? (
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={true}
                                                        disabled
                                                        name="dashboard"
                                                    />
                                                }
                                                label="Dashboard"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={state.captable}
                                                        onChange={handleChange}
                                                        name="captable"
                                                    />
                                                }
                                                label="Cap Table"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={state.classes}
                                                        onChange={handleChange}
                                                        name="classes"
                                                    />
                                                }
                                                label="Manage Classes"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={state.holders}
                                                        onChange={handleChange}
                                                        name="holders"
                                                    />
                                                }
                                                label="Holders"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={state.kyc}
                                                        onChange={handleChange}
                                                        name="kyc"
                                                    />
                                                }
                                                label="KYC"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            state.documents
                                                        }
                                                        onChange={handleChange}
                                                        name="documents"
                                                    />
                                                }
                                                label="Documents"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            state.blockchain
                                                        }
                                                        onChange={handleChange}
                                                        name="blockchain"
                                                    />
                                                }
                                                label="Blockchain"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            state.transfers
                                                        }
                                                        onChange={handleChange}
                                                        name="transfers"
                                                    />
                                                }
                                                label="Transfers"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            state.transferagent
                                                        }
                                                        onChange={handleChange}
                                                        name="transferagent"
                                                    />
                                                }
                                                label="Transfer Agent"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={state.events}
                                                        onChange={handleChange}
                                                        name="events"
                                                    />
                                                }
                                                label="Events"
                                            />
                                        </FormGroup>
                                    ) : (
                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <CircularProgress />
                                        </Grid>
                                    )}
                                    <FormHelperText>
                                        You can change this at any point.
                                    </FormHelperText>
                                    <br />
                                    <Button
                                        variant="outlined"
                                        className={classes.buttonLabel}
                                        color="primary"
                                        onClick={savePreferredTabs}
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            'Save'
                                        )}
                                    </Button>
                                </FormControl>
                            </Paper>
                        </Grid>
                    )}
                <Grid item xs={12} md={6}>
                    <Paper>
                        <IssuerInvites skipPaperComponent={true} />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = ({ profile }) => {
    return {
        profile: profile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        profileActions: bindActionCreators(profileActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Preferences);
