import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { greenWallet } from '../../api';
import CircularLoader from '../../../CircularLoader';
import TableComponent from '../Table';

const transactionColumns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
        field: 'createdAt',
        headerName: 'Created At',
    },
    {
        field: 'type',
        headerName: 'Type',
    },
    {
        field: 'fee',
        headerName: 'Fee',
        type: 'number',
    },
    {
        field: 'feeRate',
        headerName: 'Fee Rate',
        type: 'number',
    },
    {
        field: 'amount',
        headerName: 'Amount',
        type: 'number',
    },
    {
        field: 'memo',
        headerName: 'Memo',
    },
];

const balancesColumns = [
    { field: 'assetId', headerName: 'Asset Id' },
    { field: 'name', headerName: 'Name' },
    { field: 'precision', headerName: 'Precision', type: 'number' },
    { field: 'ticker', headerName: 'Iicker' },
    { field: 'issuer', headerName: 'Issuer' },
    { field: 'balance', headerName: 'Balance', type: 'number' },
];
const Account = ({ account, creds }) => {
    const [address, setAddress] = useState({ loading: false });
    const [transactions, setTransactions] = useState({
        data: [],
        loading: false,
    });
    const [balance, setBalance] = useState({ data: [], loading: false });

    useEffect(() => {
        if (account) {
            getAddress();
            getTransactions();
            getBalance();
        }
    }, [account]);

    const getAddress = async () => {
        setAddress({ loading: true });
        const res = (await greenWallet.getAccountAddress(account.id, creds))
            .data;
        setAddress({ ...res, loading: false });
    };

    const getTransactions = async () => {
        setTransactions({ loading: true });
        const res = (
            await greenWallet.getAccountTransactions(account.id, creds)
        ).data;
        setTransactions({ data: [...res], loading: false });
    };

    const getBalance = async () => {
        setBalance({ loading: true });
        const res = (await greenWallet.getAccountBalance(account.id, creds))
            .data;
        setBalance({ data: [...res], loading: false });
    };

    return (
        <Grid container spacing={2} direction="row" justify="center">
            <Grid item xs={12}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography textAlign="center" variant="body1">
                        Address
                    </Typography>
                    {address.loading ? (
                        <CircularLoader />
                    ) : (
                        <>
                            {address.address ? (
                                <Box>{address.address}</Box>
                            ) : (
                                <Typography textAlign="center" variant="body2">
                                    Address Not Found!
                                </Typography>
                            )}
                        </>
                    )}
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box mx={2}>
                    <Typography variant="body1" textAlign="center">
                        Transactions
                    </Typography>
                    {transactions.loading ? (
                        <CircularLoader />
                    ) : (
                        <>
                            {transactions.data && !!transactions.data.length ? (
                                <TableComponent
                                    columns={transactionColumns}
                                    items={transactions.data}
                                />
                            ) : (
                                <Typography variant="body2" textAlign="center">
                                    There're no transactions.
                                </Typography>
                            )}
                        </>
                    )}
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box mx={2}>
                    <Typography variant="body1" textAlign="center">
                        Balances
                    </Typography>
                    {transactions.loading ? (
                        <CircularLoader />
                    ) : (
                        <>
                            {balance.data && !!balance.data.length ? (
                                <TableComponent
                                    columns={balancesColumns}
                                    items={balance.data}
                                />
                            ) : (
                                <Typography variant="body2" textAlign="center">
                                    There're no balances.
                                </Typography>
                            )}
                        </>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};

export default Account;
