import React from 'react';
import { Tooltip } from '@mui/material';
import { makeStyles, withTheme } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    tooltipRoot: (props) => ({
        backgroundColor: theme.palette.common.black,
        maxWidth: props.width || 'auto',
        textAlign: 'center',
        whiteSpace: 'pre-line',
    }),
    tooltipArrow: {
        color: theme.palette.common.black,
    },
}));

export default withTheme(
    ({
        children,
        title,
        placement,
        disableHoverListener,
        tooltipPlacementTop,
        width,
    }) => {
        const classes = useStyles({ width });

        if (!title) {
            return children;
        }

        return (
            <Tooltip
                title={title}
                placement={placement}
                disableHoverListener={disableHoverListener}
                arrow
                classes={{
                    tooltip: classes.tooltipRoot,
                    arrow: classes.tooltipArrow,
                    tooltipPlacementTop: tooltipPlacementTop,
                }}
            >
                {children}
            </Tooltip>
        );
    }
);
