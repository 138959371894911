import React /*, { useState }*/ from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Container from '@mui/material/Container';
import authStyles from '../../authStyles';
import Grid from '@mui/material/Grid';
import { Editor } from '@tinymce/tinymce-react';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import TextField from '../../../../components/TextField/TextField';
import FundButtonGroup from '../FundButtonGroup';
const validationSchema = Yup.object({
    subscription_agreement_representative_name: Yup.string().required(
        'Please enter representative name'
    ),
    subscription_agreement_representative_title: Yup.string().required(
        'Please enter representative title'
    ),
    subscription_agreement_representative_email: Yup.string()
        .email('Please enter a valid email address')
        .required('Please enter representative email'),
    subscription_agreement_signature: Yup.string().required(
        'Please enter signature'
    ),
});
const SubscriptionAgreementTemplate = (props) => {
    const classes = authStyles();
    const nextStep = () => {
        props.nextStep();
    };

    const previousStep = () => {
        props.previousStep();
    };

    const closeDialog = () => {
        props.closeDialog();
    };

    return (
        <div id="step7">
            <Formik
                initialValues={props.initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    props.saveValues(values);
                    nextStep();
                    props.changeKey();
                }}
            >
                {({ setFieldValue }) => (
                    <Container maxWidth="lg">
                        <Form className={classes.form} noValidate>
                            <Typography component="div">
                                <b>
                                    This blank box is where you paste in the
                                    subscription agreement that you want your
                                    holders to e-sign.
                                </b>
                            </Typography>
                            <br />
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <InputLabel
                                        shrink
                                        htmlFor="subscription_agreement_template"
                                    >
                                        Paste in the investor subscription
                                        agreement (including risk disclosures)
                                        created by your attorney:
                                    </InputLabel>
                                    <Editor
                                        apiKey="uf9matrx4ivq9fcl3zpum8qtj7b4bb79w95bmuwn5d2hkhyi"
                                        initialValue="<p></p>"
                                        init={{
                                            height: 250,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount',
                                            ],
                                            toolbar:
                                                'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Representative Name"
                                        name="subscription_agreement_representative_name"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Title"
                                        name="subscription_agreement_representative_title"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Email"
                                        name="subscription_agreement_representative_email"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    label="Signature"
                                    name="subscription_agreement_signature"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <FundButtonGroup
                                previousStep={previousStep}
                                closeDialog={closeDialog}
                            />
                        </Form>
                    </Container>
                )}
            </Formik>
        </div>
    );
};

export default SubscriptionAgreementTemplate;
