import * as kycTypes from './kycTypes';

const initialState = {
    data: null,
    loading: true,
};

const kycReducer = (state = initialState, action) => {
    switch (action.type) {
        case kycTypes.GET_KYC_SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case kycTypes.SET_KYC_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        default:
            return state;
    }
};

export default kycReducer;
