import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Container from '@mui/material/Container';
import authStyles from '../../authStyles';
import { useSnackbar } from 'notistack';
import * as config from './../../../../config';
import request from '../../../../utilities/request';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '../../../../components/TextField/TextField';
import Grid from '@mui/material/Grid';
import FundButtonGroup from '../FundButtonGroup';
const validationSchema = Yup.object({
    offering_agreement_signature: Yup.string().required(
        'Please enter offering agreement signature'
    ),
});
const OfferingAgreement = (props) => {
    const classes = authStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [formFillingPerson, setFormFillingPerson] = useState(
        props.initialValues.form_filling_person
            ? props.initialValues.form_filling_person
            : 'investor'
    );

    const nextStep = () => {
        props.nextStep();
    };

    const previousStep = () => {
        props.previousStep();
    };

    const closeDialog = () => {
        props.closeDialog();
    };

    const changeFormFillingPerson = (e) => {
        setFormFillingPerson(e.target.value);
    };

    return (
        <div id="step4">
            <Formik
                initialValues={props.initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    values['form_filling_person'] = formFillingPerson;

                    props.saveValues(values);
                    request
                        .post(
                            `${config.API_BASE_URL}/fundraise/investment`,
                            values
                        )
                        .then((response) => {
                            enqueueSnackbar(
                                'Information has been saved successfully',
                                {
                                    variant: 'success',
                                }
                            );

                            nextStep();
                            props.changeKey();
                        })
                        .catch((error) => {
                            enqueueSnackbar(error.response.data.message, {
                                variant: 'error',
                            });
                        });
                }}
            >
                {({ setFieldValue }) => (
                    <Container maxWidth="lg">
                        <Form className={classes.form} noValidate>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Field
                                        component={TextField}
                                        name="offering_agreement"
                                        type="text"
                                        label="Offering Agreement"
                                        rows={2}
                                        multiline
                                        fullWidth
                                        inputProps={{ readOnly: true }}
                                        defaultValue="THIS PURCHASE AGREEMENT (this “Agreement”) is made and effective as of the date Seller executes this Agreement (the “Effective Date”) by and between CRA 1250 & 1400 DST, a Delaware statutory trust (“Seller”) and"
                                    />
                                </Grid>
                            </Grid>
                            <RadioGroup
                                name="form_filling_person"
                                row
                                required
                                defaultValue={formFillingPerson}
                            >
                                <FormControlLabel
                                    value={'investor'}
                                    control={<Radio color="primary" />}
                                    label="I am the investor and the authorized signer."
                                    labelPlacement="end"
                                    onChange={changeFormFillingPerson}
                                />
                                <FormControlLabel
                                    value={'another_person'}
                                    control={<Radio color="primary" />}
                                    label={
                                        <div>
                                            I am filling out this information
                                            for another person. An email will be
                                            sent to{' '}
                                            {props.initialValues.investor_name}{' '}
                                            at {props.initialValues.email}{' '}
                                            requesting the final signature.
                                        </div>
                                    }
                                    labelPlacement="end"
                                    onChange={changeFormFillingPerson}
                                />
                            </RadioGroup>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        name="offering_agreement_signature"
                                        variant="outlined"
                                        margin="normal"
                                        label="Offering Agreement Signature"
                                        fullWidth
                                        required
                                        placeholder="Please type your name here to sign Offering Agreement"
                                    />
                                </Grid>
                            </Grid>

                            <FundButtonGroup
                                previousStep={previousStep}
                                closeDialog={closeDialog}
                                last={true}
                            />
                        </Form>
                    </Container>
                )}
            </Formik>
        </div>
    );
};

export default OfferingAgreement;
