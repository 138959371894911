import * as shareholderTypes from './shareholderTypes';

const initialState = {
    data: null,
    loading: true,
};

const shareholderReducer = (state = initialState, action) => {
    switch (action.type) {
        case shareholderTypes.GET_SHAREHOLDERS_LIST_SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case shareholderTypes.SET_SHAREHOLDERS_LIST_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        default:
            return state;
    }
};

export default shareholderReducer;
