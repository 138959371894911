import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { styled } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';

import { NavLink } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as drawerActions from '../store/drawer/drawerActions';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    root: {
        "&.Mui-selected": {
            fontWeight: 700,
            color: "#3E9CD9 !important"
        } 
    },
    title: {
        fontWeight: 700,
        color: "#3E9CD9 !important"
    }
});

const StyledListItemText = styled(ListItemText)({
    paddingLeft: '20px',
    '& .MuiListItemText-primary': {
        textTransform: 'capitalize',
        fontSize: 14,
    }
})

const Drawer = (props) => {
    const classes = useStyles();

    const { open, selected, tabs, title, drawerActions } = props

    const handleClose = () => {
        drawerActions.toggleDrawer(false)
    }

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={handleClose}
            onKeyDown={handleClose}
        >
            <ListItem className={classes.root}>
                <StyledListItemText primary={<Typography className={classes.title} variant="overline">{title}</Typography>} />
            </ListItem>
            <Divider />
            <List>
                {tabs && tabs.map((tab, i) => (
                    <ListItem className={classes.root} component={NavLink} to={tab.path} key={i} button selected={selected === i}>
                        <StyledListItemText primary={tab.label} />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
        <MuiDrawer anchor="left" open={open} onClose={handleClose}>
            {list("left")}
        </MuiDrawer>
    );
}

const mapStateToProps = ({ drawer }) => {
    return {
        open: drawer.open,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        drawerActions: bindActionCreators(drawerActions, dispatch),
    };
};


Drawer.propTypes = {
    tabs: PropTypes.array.isRequired,
    title: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(Drawer)