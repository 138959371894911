import * as transferAgentTypes from './transferAgentTypes';

const initialState = {
    issuers: {
        data: null,
        loading: true,
        counts: null,
    },
    taList: {
        data: null,
        loading: true,
    },
    issuerDocuments: {
        data: null,
        loading: true,
    },
    accountUsers: {
        data: null,
        loading: true,
    },
    taRequests: {
        data: null,
        loading: true,
    },
    transfers: {
        data: null,
        loading: true,
    },
    loginAsLoading: false,
};

const transferAgentReducer = (state = initialState, action) => {
    switch (action.type) {
        /* Get and load issuers */
        case transferAgentTypes.GET_ISSUERS_SUCCESS:
            return {
                ...state,
                issuers: {
                    ...state.issuers,
                    data: action.payload.data,
                    counts: action.payload.counts,
                },
            };
        case transferAgentTypes.SET_ISSUERS_LOADING:
            return {
                ...state,
                issuers: {
                    ...state.issuers,
                    loading: action.payload,
                },
            };
        /* Set "login as different user" */
        case transferAgentTypes.SET_LOGIN_AS_LOADING:
            return {
                ...state,
                loginAsLoading: action.payload,
            };
        case transferAgentTypes.GET_TRANSFER_AGENTS_LIST_SUCCESS:
            return {
                ...state,
                taList: {
                    ...state.taList,
                    data: action.payload,
                },
            };
        case transferAgentTypes.SET_TRANSFER_AGENTS_LIST_LOADING:
            return {
                ...state,
                taList: {
                    ...state.taList,
                    loading: action.payload,
                },
            };
        case transferAgentTypes.GET_ISSUER_DOCUMENTS_SUCCESS:
            return {
                ...state,
                issuerDocuments: {
                    ...state.issuerDocuments,
                    data: action.payload,
                },
            };
        case transferAgentTypes.SET_ISSUER_DOCUMENTS_LOADING:
            return {
                ...state,
                issuerDocuments: {
                    ...state.issuerDocuments,
                    loading: action.payload,
                },
            };
        case transferAgentTypes.GET_ACCOUNT_USERS_SUCCESS:
            return {
                ...state,
                accountUsers: {
                    ...state.accountUsers,
                    data: action.payload,
                },
            };
        case transferAgentTypes.SET_ACCOUNT_USERS_LOADING:
            return {
                ...state,
                accountUsers: {
                    ...state.accountUsers,
                    loading: action.payload,
                },
            };
        case transferAgentTypes.GET_TRANSFER_AGENTS_REQUESTS_SUCCESS:
            return {
                ...state,
                taRequests: {
                    ...state.taRequests,
                    data: action.payload,
                },
            };
        case transferAgentTypes.SET_TRANSFER_AGENTS_REQUESTS_LOADING:
            return {
                ...state,
                taRequests: {
                    ...state.taRequests,
                    loading: action.payload,
                },
            };
        case transferAgentTypes.GET_TA_TRANSFERS_SUCCESS:
            return {
                ...state,
                transfers: {
                    ...state.transfers,
                    loading: action.payload,
                },
            };
        case transferAgentTypes.SET_TA_TRANSFERS_LOADING:
            return {
                ...state,
                transfers: {
                    ...state.transfers,
                    loading: action.payload,
                },
            };
        default:
            return state;
    }
};

export default transferAgentReducer;
