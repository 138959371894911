import React, { useState } from 'react'

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import TextField from './TextField/TextField';
import { generatePassword } from '../utilities/utils';
import { Field } from 'formik';

const PasswordField = (props) => {

    const { label, name, disableAutocomplete, tabIndex,
        setValue // used to generate a random password on click
    } = props

    const [showPassword, setShowPassword] = useState(false);

    /* Handle password visibility */
    const changeShowPassword = () => {
        showPassword === true ? setShowPassword(false) : setShowPassword(true);
    };

    /* Handle randomly-generated password */
    const handlePasswordGeneration = () => {
        setValue(name, generatePassword())
    }

    return (
        <React.Fragment>
            <Field
                component={TextField}
                type={
                    showPassword === true
                        ? 'text'
                        : 'password'
                }
                label={label}
                name={name}
                variant="outlined"
                margin="normal"
                fullWidth
                required
                autoComplete={disableAutocomplete ? 'new-password' : ''}
                InputProps={{
                    tabIndex: tabIndex,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={changeShowPassword}
                                size="large">
                                {showPassword ? (
                                    <VisibilityIcon />
                                ) : (
                                    <VisibilityOffIcon />
                                )}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            {
                // Random password generator
                setValue &&
                <FormHelperText className="link" onClick={handlePasswordGeneration}>
                    Generate random password
                </FormHelperText>
            }
        </React.Fragment>
    );
}

export default PasswordField
