import React /*, { useState }*/ from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Container from '@mui/material/Container';
import authStyles from '../../authStyles';
import TextField from '../../../../components/TextField/TextField';
import Typography from '@mui/material/Typography';
import FundButtonGroup from '../FundButtonGroup';
const validationSchema = Yup.object({
    rta_agreement_name: Yup.string().required('Please enter name'),
    rta_agreement_title: Yup.string().required('Please enter title'),
});
const SignRTAAgreement = (props) => {
    const classes = authStyles();

    const nextStep = () => {
        props.nextStep();
    };

    const previousStep = () => {
        props.previousStep();
    };

    const closeDialog = () => {
        props.closeDialog();
    };

    return (
        <div id="step12">
            <Formik
                initialValues={props.initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    props.saveValues(values);
                    nextStep();
                    props.changeKey();
                }}
            >
                {({ setFieldValue }) => (
                    <Container maxWidth="lg">
                        <Form className={classes.form} noValidate>
                            <Typography component="div">
                                <b>
                                    Registered Transfer Agent Agreement Template
                                    (to be added)
                                </b>
                            </Typography>
                            <Field
                                component={TextField}
                                type="text"
                                label="Type your name here to sign the registered transfer agent agreement"
                                name="rta_agreement_name"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                            />
                            <Field
                                component={TextField}
                                type="text"
                                label="Title"
                                name="rta_agreement_title"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                            />

                            <FundButtonGroup
                                previousStep={previousStep}
                                closeDialog={closeDialog}
                            />
                        </Form>
                    </Container>
                )}
            </Formik>
        </div>
    );
};

export default SignRTAAgreement;
