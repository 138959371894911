import makeStyles from '@mui/styles/makeStyles';

const authStyles = makeStyles((theme) => ({
    container: {
        maxWidth: '27rem',
        alignItems: 'center',
        marginTop: '10rem',
    },
    /*paper: {
    padding: '0.7rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },*/
    paper: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
        borderRadius: '20px',
        padding: '2rem',
        height: '100%',
    },
    brand: {
        fontSize: '2.5rem',
    },
    logo: {
        display: 'block',
        margin: 'auto',
        //    width: '80%',
        marginTop: 20,
        width: '10%',
    },
    /*title: {
        marginBottom: '2rem',
        fontSize: '2rem',
        textAlign: 'center',
    },*/
    title: {
        fontFamily: 'Saira',
        fontStyle: 'normal',
        fontWeight: 550,
        fontSize: '18px',
        lineHeight: '28px',
        color: '#333333',
        paddingBottom: '0.5rem',
    },
    note: {
        fontSize: '0.9rem',
    },
    form: {
        marginTop: '1rem',
    },
    actions: {
        marginTop: '1.5rem',
        alignItems: 'center',
    },
    button: {
        //padding: '0.75rem 1.5rem',
        marginRight: '1rem',
        marginBottom: '1rem',
        //minWidth: '7rem',
        fontSize: '0.875rem',
        textTransform: 'none',
    },
    uploadButton: {
        border: '1px solid #333333',
        boxSizing: 'border-box',
        borderRadius: '20px',
    },
    browseButton: {
        background: '#3E9CD9',
        borderRadius: '20px',
    },
    buttonWithAlignment: {
        marginRight: '1rem',
        marginBottom: '1rem',
        fontSize: '0.875rem',
        textTransform: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    link: {
        display: 'block',
        fontSize: '1rem',
        textAlign: 'left',
        textDecoration: 'none',
        marginTop: '0.5rem',
        marginBottom: '1rem',
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.primary.main,
        },
        fontWeight: '700',
    },
    checkbox: {
        fontSize: '1rem',
        '& > a': {
            textDecoration: 'none',
            color: theme.palette.secondary.main,
            fontWeight: '500',
            marginLeft: '0.25rem',
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
    },
    table: {
        minWidth: 100,
    },
    tableCell: {
        fontSize: '1rem',
    },
    blueText: {
        color: '#3E9CD9',
    },
    redText: {
        color: '#ED7571',
    },
    listOverviewLink: {
        cursor: 'pointer',
        color: '#3E9CD9',
        textDecoration: 'underline',
    },
    // 2 x 1 grid
    fundraiseGrid: {
        display: 'grid !important',
        gap: '1em',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateRows: 'repeat(2, 1fr)',
        justifyItems: 'center',
        '& > *:nth-child(1)': {
            gridArea: '1 / 1 / span 1 / span 1',
        },
        '& > *:nth-child(2)': {
            gridArea: '1 / 2 / span 2 / span 1',
        },
        '& > *:nth-child(3)': {
            gridArea: '2 / 1 / span 1 / span 1',
        },
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr !important',
            gridTemplateRows: '1fr !important',
            '& > *': {
                gridArea: 'auto / 1 / auto / 1 !important',
            },
        },
    },
    listingStatus: {
        color: '#3E9CD9',
    },
    lockIcon: {
        fonSize: 5,
    },
    pointer: {
        cursor: 'pointer',
    },
    centerText: {
        textAlign: 'center',
    },
    rightText: {
        textAlign: 'right',
    },
    rightFloat: {
        float: 'right',
    },
    leftFloat: {
        float: 'left',
    },
    buttonGroupAlignment: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paperSmall: {
        borderRadius: 15,
        padding: 10,
        height: '100%',
    },
    inputLabel: {
        color: '#666',
        fontSize: '0.8rem',
        marginBottom: '0.3rem',
        marginTop: '0.3rem',
    },
    touchIcon: {
        color: '#3E9CD9',
        cursor: 'pointer',
    },
    dialogTitle: {
        fontFamily: 'Saira',
        fontStyle: 'normal',
        fontWeight: 550,
        fontSize: '24px',
        lineHeight: '34px',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
        color: '#333333',
    },
    alignEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    alignCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    alignLeft: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'left',
    },
    redIcon: {
        color: '#FF0000',
    },
    greenIcon: {
        color: '#008000',
    },
    mediumFont: {
        fontSize: '1rem',
    },
    largeFont: {
        fontSize: '1.25rem',
    },
    smallFontGrey: {
        fontSize: '0.75rem',
        color: 'grey',
    },
    alignItemsLeft: {
        alignItems: 'left',
    },
    offeringPaper: {
        padding: '0.7rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        justifyContent: 'left',
        minHeight: '39rem',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(2),
        color: theme.palette.grey[500],
    },
    marginTop: {
        marginTop: '2rem',
    },
    listItem: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '21px',
        display: 'flex',
        alignItems: 'center',
        color: '#333333',
    },
    listItemLarge: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '17px',
        lineHeight: '25px',
        display: 'flex',
        alignItems: 'center',
        textDecorationLine: 'underline',
        color: '#3E9CD9',
    },
    checkboxLabel: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        color: '#333333',
    },
    linkNoneDecoration: {
        textDecoration: 'none',
    },

    listItemSecondary: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '21px',
        display: 'flex',
        alignItems: 'center',
        color: '#3E9CD9',
    },
    label: {
        fontFamily: 'Saira',
        fontStyle: 'normal',
        fontWeight: 550,
        fontSize: '10px',
        lineHeight: '18px',
        textTransform: 'uppercase',
        color: '#333333',
        marginTop: '0.3rem',
        marginBottom: '0.3rem',
    },
    linkStyle: {
        textDecoration: 'none',
        color: 'black',
    },
    assessmentSubtitle: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        color: '#7D8696',
        marginTop: '10px',
    },
    documentTitle: {
        fontFamily: 'Saira',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '25px',
        display: 'flex',
        alignItems: 'center',
        color: '#3E9CD9',
        marginBottom: '0.5rem',
    },
    dashboardListItem: {
        color: '#23587B',
        fontSize: '20px',
    },
    dashboardListItemRight: {
        color: '#23587B',
        fontSize: '20px',
        marginRight: '2.3rem',
    },
    tooltip: {
        color: '#3E9CD9',
    },
    documentsNote: {
        fontSize: '12px',
        color: 'grey',
    },
    buttonLabel: {
        color: '#3E9CD9',
        cursor: 'pointer',
        marginBottom: '1rem',
        textTransform: 'none',
        fontSize: '1rem',
        padding: '6px',
        '&:hover': {
            backgroundColor: '#3E9CD9',
            color: 'white',
        },
    },
    dangerButtonLabel: {
        color: '#FF0000',
        cursor: 'pointer',
        marginBottom: '1rem',
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: '#FF0000',
            color: 'white',
        },
    },
    secondaryButtonLabel: {
        color: '#000000',
        cursor: 'pointer',
        marginBottom: '1rem',
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            backgroundColor: '#000000',
            color: 'white',
        },
    },
    squareButton: {
        color: '#D7D8D7',
    },
    dashboardListItemText: {
        margin: 0,
        fontFamily: 'Saira,Roboto,sans-serif',
        fontWeight: '400',
        fontSize: '0.9rem',
        lineHeight: '1.5',
        display: 'block',
    },
    documentsTitle: {
        margin: 0,
        fontFamily: 'Saira,Roboto,sans-serif',
        fontWeight: '500',
        fontSize: '1.25rem',
        lineHeight: '1.6',
        marginBottom: '2rem',
    },
    errorMessage: {
        fontSize: '10px',
        color: '#ED7571',
    },
    infoMessage: {
        fontSize: '10px',
        color: '#3E9CD9',
    },
    viewByDiv: {
        paddingLeft: '6px',
        float: 'left'
    },
}));

export default authStyles;
