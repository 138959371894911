import request from '../../utilities/request';
import * as config from '../../config';
import * as capTableTypes from './capTableTypes';
import { snackbar } from '../../utilities/snackbarUtils';
import { parseError } from '../../utilities/utils';

import * as profileActions from '../profile/profileActions';

export const getSummarySuccess = (summary) => {
    return {
        type: capTableTypes.GET_SUMMARY_SUCCESS,
        payload: summary,
    };
};

export const setSummaryLoading = (loading) => {
    return {
        type: capTableTypes.SET_SUMMARY_LOADING,
        payload: loading,
    };
};

export const getSummary = () => {
    return (dispatch) => {
        dispatch(setSummaryLoading(true));
        request
            .get(`${config.API_BASE_URL}/summary`)
            .then((res) => {
                dispatch(getSummarySuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setSummaryLoading(false));
            });
    };
};

export const getShareholdersSuccess = (shareholders) => {
    return {
        type: capTableTypes.GET_SHAREHOLDERS_SUCCESS,
        payload: shareholders,
    };
};

export const setShareholdersLoading = (loading) => {
    return {
        type: capTableTypes.SET_SHAREHOLDERS_LOADING,
        payload: loading,
    };
};

export const getShareholders = () => {
    return (dispatch) => {
        dispatch(setShareholdersLoading(true));
        request
            .get(`${config.API_BASE_URL}/shareholders`)
            .then((res) => {
                dispatch(getShareholdersSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setShareholdersLoading(false));
            });
    };
};

export const getClassesSuccess = (classes) => {
    return {
        type: capTableTypes.GET_CLASSES_SUCCESS,
        payload: classes,
    };
};

export const setClassesLoading = (loading) => {
    return {
        type: capTableTypes.SET_CLASSES_LOADING,
        payload: loading,
    };
};

export const getClasses = () => {
    return (dispatch) => {
        dispatch(setClassesLoading(true));
        request
            .get(`${config.API_BASE_URL}/share-classes`)
            .then((res) => {
                dispatch(getClassesSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setClassesLoading(false));
            });
    };
};

export const getClassCategoriesSuccess = (categories) => {
    return {
        type: capTableTypes.GET_CLASS_CATEGORIES_SUCCESS,
        payload: categories,
    };
};

export const setClassCategoriesLoading = (loading) => {
    return {
        type: capTableTypes.SET_CLASS_CATEGORIES_LOADING,
        payload: loading,
    };
};

export const getClassCategories = () => {
    return (dispatch) => {
        dispatch(setClassCategoriesLoading(true));
        request
            .get(`${config.API_BASE_URL}/share-class-categories`)
            .then((res) => {
                dispatch(getClassCategoriesSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setClassCategoriesLoading(false));
            });
    };
};

export const getCategoriesByClassSuccess = (categories) => {
    return {
        type: capTableTypes.GET_CATEGORIES_BY_CLASS_SUCCESS,
        payload: categories,
    };
};

export const setCategoriesByClassLoading = (loading) => {
    return {
        type: capTableTypes.SET_CATEGORIES_BY_CLASS_LOADING,
        payload: loading,
    };
};
export const getCategoriesByClassId = (class_id) => {
    return (dispatch) => {
        dispatch(setCategoriesByClassLoading(true));
        request
            .get(`${config.API_BASE_URL}/share-classes/categories/${class_id}`)
            .then((res) => {
                dispatch(getCategoriesByClassSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setCategoriesByClassLoading(false));
            });
    };
};

export const getCapTableChartSuccess = (CapTableChart) => {
    return {
        type: capTableTypes.GET_CAP_TABLE_CHART_SUCCESS,
        payload: CapTableChart,
    };
};

export const setCapTableChartLoading = (loading) => {
    return {
        type: capTableTypes.SET_CAP_TABLE_CHART_LOADING,
        payload: loading,
    };
};

export const getCapTableChart = () => {
    return (dispatch) => {
        dispatch(setCapTableChartLoading(true));
        request
            .get(`${config.API_BASE_URL}/cap-table-chart`)
            .then((res) => {
                dispatch(getCapTableChartSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setCapTableChartLoading(false));
            });
    };
};

export const getEntrySuccess = (entry) => {
    return {
        type: capTableTypes.GET_ENTRY_SUCCESS,
        payload: entry,
    };
};

export const setEntryLoading = (loading) => {
    return {
        type: capTableTypes.SET_ENTRY_LOADING,
        payload: loading,
    };
};

export const getEntry = (id) => {
    return (dispatch) => {
        dispatch(setEntryLoading(true));
        request
            .get(`${config.API_BASE_URL}/cap-table/entry/${id}`)
            .then((res) => {
                dispatch(getEntrySuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setEntryLoading(false));
            });
    };
};

export const getShareholdersByCategory = (id, shareholder) => {
    return (dispatch) => {
        dispatch(setShareholdersLoading(true));
        request
            .get(`${config.API_BASE_URL}/shareholders/category/${id}`)
            .then((res) => {
                dispatch(getShareholdersSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setShareholdersLoading(false));
            });
    };
};

/**
 * CAP TABLE REGISTRY AND ADDING NEW DATA
 */

export const getRegistrySuccess = (registry) => {
    return {
        type: capTableTypes.GET_REGISTRY_SUCCESS,
        payload: registry,
    };
};

export const setRegistryLoading = (loading) => {
    return {
        type: capTableTypes.SET_REGISTRY_LOADING,
        payload: loading,
    };
};

export const getRegistry = () => {
    return (dispatch) => {
        dispatch(setRegistryLoading(true));
        request
            .get(`${config.API_BASE_URL}/cap-table-registry`)
            .then((res) => {
                dispatch(getRegistrySuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setRegistryLoading(false));
            });
    };
};

export const addShareClassSuccess = (shareholders) => {
    return {
        type: capTableTypes.ADD_SHARE_CLASS_SUCCESS,
        payload: shareholders,
    };
};

export const setShareClassLoading = (loading) => {
    return {
        type: capTableTypes.SET_SHARE_CLASS_LOADING,
        payload: loading,
    };
};

export const addShareClass = (data, setSubmitting) => {
    return (dispatch) => {
        setSubmitting(true);
        dispatch(setShareClassLoading(true));
        request
            .post(`${config.API_BASE_URL}/share-classes`, data)
            .then((res) => {
                snackbar.success('Class added successfully.');
                dispatch(addShareClassSuccess(data));
            })
            .catch((error) => {
                let errorMessage = parseError(error);
                snackbar.error(errorMessage);
                console.log(error);
            })
            .finally(() => {
                setSubmitting(false);
                dispatch(setShareClassLoading(false));
            });
    };
};

export const addShareCategorySuccess = (shareholders) => {
    return {
        type: capTableTypes.ADD_SHARE_CATEGORY_SUCCESS,
        payload: shareholders,
    };
};

export const setShareCategoryLoading = (loading) => {
    return {
        type: capTableTypes.SET_SHARE_CATEGORY_LOADING,
        payload: loading,
    };
};

export const addShareCategory = (data, setSubmitting, success = null) => {
    return (dispatch) => {
        setSubmitting(true);
        dispatch(setShareCategoryLoading(true));
        request
            .post(`${config.API_BASE_URL}/share-class-categories`, data)
            .then((res) => {
                snackbar.success('Category added successfully.');
                dispatch(addShareCategorySuccess(data));
                if (success) success();
            })
            .catch((error) => {
                let errorMessage = parseError(error);
                snackbar.error(errorMessage);
                console.log(error);
            })
            .finally(() => {
                setSubmitting(false);
                dispatch(setShareCategoryLoading(false));
            });
    };
};

export const addInvestorSuccess = (shareholders) => {
    return {
        type: capTableTypes.ADD_INVESTOR_SUCCESS,
        payload: shareholders,
    };
};

export const setInvestorLoading = (loading) => {
    return {
        type: capTableTypes.SET_INVESTOR_LOADING,
        payload: loading,
    };
};

export const addInvestor = (data, setSubmitting, success = null) => {
    return (dispatch) => {
        setSubmitting(true);
        dispatch(setInvestorLoading(true));
        request
            .post(`${config.API_BASE_URL}/shareholders`, data)
            .then((res) => {
                snackbar.success('Holder added successfully.');
                dispatch(addInvestorSuccess(data));
                dispatch(profileActions.getProfile());
                if (success) success();
            })
            .catch((error) => {
                let errorMessage = parseError(error, true);
                snackbar.error(errorMessage);
            })
            .finally(() => {
                setSubmitting(false);
                dispatch(setInvestorLoading(false));
            });
    };
};

/** Update class information */

export const updateClassSuccess = (classes) => {
    return {
        type: capTableTypes.UPDATE_CLASS_SUCCESS,
        payload: classes,
    };
};

export const updateClass = (data) => {
    return (dispatch) => {
        dispatch(updateClassSuccess(data));
    };
};

/** Update shareholder information */
export const updateShareholderSuccess = (shareholder) => {
    return {
        type: capTableTypes.UPDATE_SHAREHOLDER_SUCCESS,
        payload: shareholder,
    };
};

export const updateShareholder = (data, category = null) => {
    return (dispatch) => {
        dispatch(updateShareholderSuccess(data));
        // dispatch(setInvestorLoading(true))
        // request
        //     .post(`${config.API_BASE_URL}/shareholders`, data)
        //     .then((res) => {
        //         dispatch(addInvestorSuccess(res.data));
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     }).finally(() => {
        //         dispatch(setInvestorLoading(false))
        //     });
    };
};

/** Delete a shareholder */
export const deleteShareholderSuccess = (id) => {
    return {
        type: capTableTypes.DELETE_SHAREHOLDER_SUCCESS,
        payload: id,
    };
};

export const deleteShareholder = (id) => {
    return (dispatch) => {
        dispatch(deleteShareholderSuccess(id));
    };
};

export const getInvestorsSuccess = (investors) => {
    return {
        type: capTableTypes.GET_INVESTORS_SUCCESS,
        payload: investors,
    };
};

export const setInvestorsLoading = (loading) => {
    return {
        type: capTableTypes.SET_INVESTORS_LOADING,
        payload: loading,
    };
};

export const getInvestors = () => {
    return (dispatch) => {
        dispatch(setInvestorsLoading(true));
        request
            .get(`${config.API_BASE_URL}/shareholders`)
            .then((res) => {
                dispatch(getInvestorsSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setInvestorsLoading(false));
            });
    };
};
