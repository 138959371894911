import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Humanize from 'humanize-plus';
import { findIndex, filter, cloneDeep } from 'lodash';
import { getStorageItem } from './storage';
import template from '../assets/DIGTL Cap Table Template.zip';
import {
    SUBSCRIPTION_BASIC,
    SUBSCRIPTION_ENTERPRISE,
    SUBSCRIPTION_PRO,
} from '../constants/profile';

export const GetRandomString = (length) => {
    var randomChars =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
        result += randomChars.charAt(
            Math.floor(Math.random() * randomChars.length)
        );
    }
    return result;
};

/**
 * Generate a secure, random password.
 * @param {number} length
 * @link https://stackoverflow.com/a/51540480
 */
export const generatePassword = (length = 12) => {
    const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
    /* Keep generating the random password until it matches criteria */
    while (true) {
        let password = Array(length)
            .fill(
                '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$%^&*'
            )
            .map(
                (x) =>
                    x[
                        Math.floor(
                            (crypto.getRandomValues(new Uint32Array(1))[0] /
                                (0xffffffff + 1)) *
                                x.length
                        )
                    ]
            )
            .join('');

        if (passRegex.test(password)) {
            return password;
        }
    }
};

/**
 * Parse an error message.
 */
export const parseError = (error, preferValidator = false) => {
    let errorMessage;

    if (preferValidator) {
        try {
            let firstError = Object.keys(error.response.data.errors)[0];
            errorMessage = error.response.data.errors[firstError][0];
            return errorMessage;
        } catch (e) {
            if ('message' in error.response.data) {
                return error.response.data.message;
            }

            return 'An unknown error has occured.';
        }
    }

    // "Standard" error message
    if ('message' in error.response.data) {
        errorMessage = error.response.data.message;
        // Laravel Validator error message
    } else {
        let firstError = Object.keys(error.response.data)[0];
        errorMessage = error.response.data[firstError][0];
    }

    return errorMessage;
};

/**
 * Remove an element from an array,
 * and return a deep copy.
 */
export const removeFromArray = (data, field, value) => {
    let i = findIndex(data, {
        [field]: value,
    });

    // Build a new array without the removed element
    let newData = cloneDeep(data);
    newData.splice(i, 1);

    return newData;
};

/**
 * Construct a Menu dropdown from up to a number.
 */
export const buildDropdown = (number) => {
    let items = [];
    for (var i = 1; i <= number; i++) {
        items.push(
            <MenuItem key={i} value={i}>
                {i}
            </MenuItem>
        );
    }

    return items;
};

/**
 * Construct a menu dropdown from given values.
 */
export const buildValuesDropdown = (values) => {
    let items = [];
    values.forEach((value) => {
        items.push(
            <MenuItem key={value} value={value}>
                {value.charAt(0).toUpperCase() + value.slice(1)}
            </MenuItem>
        );
    });

    return items;
};

/**
 * Slice the elements of an array of objects,
 * based on given parameters.
 */
export const filterArray = (collection, conditions) => {
    return filter(collection, conditions);
};

/**
 * Parse a number with , thousand separators.
 */
export const parseNumber = (num) => {
    if (num !== null && num !== undefined) {
        return Number(num.replace(/,/g, ''));
    }
    return 0;
};

/**
 * Generate a cap table template download.
 */
export const downloadCapTableTemplate = () => {
    const link = document.createElement('a');
    link.href = template;
    link.download = 'DIGTL Cap Table Template.zip';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const allowedFiles = [
    '.csv',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.pdf',
    '.txt',
    '.jpg',
    '.png',
    '.tiff',
    '.ppt',
    '.pptx',
    '.html',
    '.htm',
    '.zip',
];
export const allowedFilesMessage = `Only ${allowedFiles
    .slice(0, allowedFiles.length - 1)
    .join(' ')} and ${allowedFiles[allowedFiles.length - 1]} files are allowed`;
//Validate file for investors and fundraising
export const validateFileExtension = (file) => {
    var regex = new RegExp(
        '([a-zA-Z0-9s_\\.-:])+(' + allowedFiles.join('|') + ')$'
    );
    if (regex.test(file.name.toLowerCase())) {
        return true;
    }

    return false;
};

export const isLargeScreen = () => {
    if (window.screen.width >= 720) return true;
    else return false;
};

/**
 * Check if a feature should be locked, due to payment plan.
 * @returns boolean
 */
export const isFeatureLocked = (requiredPlan, currentPlan) => {
    if (requiredPlan === SUBSCRIPTION_BASIC) {
        return false;
    }

    if (requiredPlan === SUBSCRIPTION_PRO) {
        if (
            currentPlan === SUBSCRIPTION_PRO ||
            currentPlan === SUBSCRIPTION_ENTERPRISE
        ) {
            return false;
        }
    }

    if (requiredPlan === SUBSCRIPTION_ENTERPRISE) {
        if (currentPlan === SUBSCRIPTION_ENTERPRISE) {
            return false;
        }
    }

    return true;
};

/**
 * Check if a feature should be locked, due to transfer agent partnership.
 * @returns boolean
 */
export const isViewMode = (profile) => {
    let ta_token = getStorageItem('ta_token');
    if (ta_token && profile.has_transfer_agent) {
        return false;
    } else if (profile.has_transfer_agent) {
        return true;
    } else {
        return false;
    }
};

/**
 * Check if a transfer agent is logged as issuer
 * @returns boolean
 */
export const isTALoggedAsIssuer = (profile) => {
    let ta_token = getStorageItem('ta_token');
    if (ta_token && profile.has_transfer_agent) {
        return true;
    }
    return false;
};

/**
 * Return current host.
 * @returns Host
 */
export const getHost = () => {
    return window.location.protocol + '//' + window.location.host;
};

export const sumObject = (source, dataKey) => {
    let output = 0;
    if (source) {
        for (let data of source) {
            output += Number(data[dataKey]);
        }
    }
    return output;
};

export const stringToInt = (value) => {
    if (value === null) {
        return 0;
    }
    return parseFloat(value.replace(/,/g, ''));
};

export const formatNumber = (value) => {
    if (value) {
        value = value.toString();
        let number = value.split('.')[1];
        if (number) {
            let decimals_zeros = number.split('').every((char) => char === '0');
            if (decimals_zeros) {
                return Humanize.formatNumber(value.split('.')[0]);
            } else {
                return Humanize.formatNumber(value, 2);
            }
        } else {
            return Humanize.formatNumber(value);
        }
    } else {
        return '-';
    }
};

/**
 * Return display value for table columns based on user preferred filters
 * @returns display
 */
export const getColumnDisplayValue = (data, targetColumn, defaultValue = true) => {
    if(data){
        if(targetColumn in data){
           return data[targetColumn]
        }
        return defaultValue;
    } else {
        return true;
    } 
}
