import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import authStyles from '../authStyles';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';

// import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormikSelect from '../../../components/Formik/FormikSelect';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import FormikDatePicker from '../../../components/Formik/FormikDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import LockedTile from '../../../components/LockedTile';
import { SUBSCRIPTION_ENTERPRISE } from '../../../constants/profile';

const StyledTableCell = styled(TableCell)({
    '&.MuiTableCell-head': {
        backgroundColor: 'white',
    },
})

const StyledTableRow = styled(TableRow)({
    '&:nth-of-type(odd)': {
        backgroundColor: 'rgb(214, 232, 246)',
    },
})

let initialValues = {};
const History = (props) => {
    const classes = authStyles();
    let demo;
    try {
        demo =
            localStorage.getItem('user-email').includes('ihqdemo') ||
            props.profile.scope === 'list';
    } catch (e) {
        demo = null;
    }

    const [selectedFromDate, setSelectedFromDate] = useState(
        new Date('04-11-2019')
    );
    const [selectedToDate, setSelectedToDate] = useState(
        new Date('04-11-2020')
    );

    const handleFromDateChange = (date) => {
        setSelectedFromDate(date);
    };

    const handleToDateChange = (date) => {
        setSelectedToDate(date);
    };

    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
        checkedC: true,
        checkedD: true,
        checkedF: false,
        checkedG: true,
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    let data = [
        {
            date: 'Nov 04 2020',
            open: '1710.28',
            high: '1743.37',
            low: '1706.03',
            close: '1742.19',
            adj_close: '1742.19',
            volume: '917464',
        },
        {
            date: 'Nov 03 2020',
            open: '1631.78',
            high: '1661.70',
            low: '1616.62',
            close: '1650.21',
            adj_close: '1650.21',
            volume: '1658000',
        },
        {
            date: 'Nov 02 2020',
            open: '1628.16',
            high: '1660.77',
            low: '1616.03',
            close: '1626.03',
            adj_close: '1626.03',
            volume: '2535400',
        },
        {
            date: 'Oct 30 2020',
            open: '1672.11',
            high: '1687.00',
            low: '1604.46',
            close: '1621.01',
            adj_close: '1621.01',
            volume: '4239100',
        },
        {
            date: 'Oct 29 2020',
            open: '1522.36',
            high: '1593.71',
            low: '1522.24',
            close: '1567.24',
            adj_close: '1567.24',
            volume: '2003100',
        },
        {
            date: 'Oct 28 2020',
            open: '1559.74',
            high: '1561.35',
            low: '1514.62',
            close: '1516.61',
            adj_close: '1516.62',
            volume: '1834000',
        },
    ];

    return (
        <LockedTile requiredPlan={SUBSCRIPTION_ENTERPRISE}>
            <Formik initialValues={initialValues}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={12} sm={6}>
                        <Typography variant="overline">
                            <b style={{ fontSize: '0.80rem' }}>Ticker:</b>
                        </Typography>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.checkedA}
                                        onChange={handleChange}
                                        name="checkedA"
                                        color="primary"
                                    />
                                }
                                label="Equity"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.checkedB}
                                        onChange={handleChange}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="DEBT"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="overline">
                            <b style={{ fontSize: '0.80rem' }}>Exchange:</b>
                        </Typography>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.checkedD}
                                        onChange={handleChange}
                                        name="checkedD"
                                        color="primary"
                                    />
                                }
                                label="Fusang"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.checkedF}
                                        onChange={handleChange}
                                        name="checkedF"
                                        color="primary"
                                    />
                                }
                                label="GSX"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.checkedG}
                                        onChange={handleChange}
                                        name="checkedG"
                                        color="primary"
                                    />
                                }
                                label="MERJ"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: '1.1rem' }}>
                        <Grid item xs={6} sm={4} md={4}>
                            <Field
                                component={FormikSelect}
                                name="show"
                                label="Show"
                                variant="outlined"
                                fullWidth
                                defaultValue="historical_prices"
                            >
                                <MenuItem
                                    key={'historical_prices'}
                                    value={'historical_prices'}
                                >
                                    Historical Prices
                                </MenuItem>
                                <MenuItem key={'dividends'} value={'dividends'}>
                                    Dividends Only
                                </MenuItem>
                                <MenuItem
                                    key={'stock_splits'}
                                    value={'stock_splits'}
                                >
                                    Stock Splits
                                </MenuItem>
                            </Field>
                        </Grid>
                        <Grid item sm={2} md={2}></Grid>
                        <Grid item xs={6} sm={4} md={4}>
                            <Field
                                component={FormikSelect}
                                name="frequency"
                                label="Frequency"
                                variant="outlined"
                                fullWidth
                                defaultValue="daily"
                            >
                                <MenuItem key={'daily'} value={'daily'}>
                                    {'Daily'}
                                </MenuItem>
                                <MenuItem key={'weekly'} value={'weekly'}>
                                    {'Weekly'}
                                </MenuItem>
                                <MenuItem key={'monthly'} value={'monthly'}>
                                    {'Monthly'}
                                </MenuItem>
                            </Field>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                        <Grid item xs={12}>
                            <InputLabel
                                style={{
                                    color: '#666',
                                    fontSize: '1rem',
                                    marginBottom: '0.5rem',
                                }}
                                htmlFor="time_period"
                            >
                                Time Period:
                            </InputLabel>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <Field
                                            name="from_date"
                                            component={FormikDatePicker}
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            label="From:"
                                            value={selectedFromDate}
                                            onChange={handleFromDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Field
                                            name="to_date"
                                            component={FormikDatePicker}
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            label="To:"
                                            value={selectedToDate}
                                            onChange={handleToDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        style={{
                            marginTop: '2rem',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: '1rem',
                            marginRight: '1rem',
                        }}
                    >
                        <Grid container spacing={2}>
                            <TableContainer>
                                <Table
                                    className={classes.table}
                                    aria-label="simple table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell
                                                className={classes.tableCell}
                                            >
                                                <b>Date</b>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className={classes.tableCell}
                                            >
                                                <b>Open</b>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className={classes.tableCell}
                                            >
                                                <b>High</b>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className={classes.tableCell}
                                            >
                                                <b>Low</b>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className={classes.tableCell}
                                            >
                                                <b>Close*</b>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className={classes.tableCell}
                                            >
                                                <b>Adj Close**</b>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className={classes.tableCell}
                                            >
                                                <b>Volume</b>
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {demo &&
                                            data.map((row) => (
                                                <StyledTableRow key={row.date}>
                                                    <StyledTableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                    >
                                                        {row.date}
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                    >
                                                        {row.open}
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                    >
                                                        {row.high}
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                    >
                                                        {row.low}
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                    >
                                                        {row.close}
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                    >
                                                        {row.adj_close}
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                    >
                                                        {row.volume}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>{' '}
                        {demo ? null : (
                            <p
                                style={{
                                    textAlign: 'center',
                                    fontSize: '1.1rem',
                                }}
                            >
                                No data
                            </p>
                        )}
                    </Grid>
                    <p>
                        <br />
                        *Close price adjusted for splits. **Adjusted close price
                        adjusted for both dividends and splits.
                    </p>
                </Grid>
            </Formik>
        </LockedTile>
    );
};

const mapStateToProps = ({ profile }) => {
    return {
        profile: profile,
    };
};

export default connect(mapStateToProps, null)(History);
