import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { formatNumber } from '../../../utilities/utils';
import Humanize from 'humanize-plus';
import Tooltip from '@mui/material/Tooltip';
import { DateFormatter } from '../../../utilities/formatters';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as capTableActions from '../../../store/capTable/capTableActions';

import ShareholderDialog from '../../../components/ShareholderDialog';

const StyledTableCell = styled(TableCell)({
    '&.MuiTableCell-body': {
        fontSize: 14,
    },
    '&.MuiTableCell-head': {
        backgroundColor: '#EAEAEA',
        color: 'black',
    },
    borderBottom: 0,
});

const StyledTableRow = styled(TableRow)({
    '&:nth-of-type(odd)': {
        backgroundColor: '#FFF',
        height: '50px',
    },
    '& > *': {
        borderBottom: 'unset',
        marginBottom: '1rem',
    },
});

function Row(props) {
    const { investor } = props;
    //const [open, setOpen] = useState(false);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);

    const openDialog = (id) => {
        setDialogIsOpen(true);
        props.capTableActions.getEntry(id);
    };

    return (
        <React.Fragment>
            {investor && investor.investor === null ? (
                <StyledTableRow>
                    {' '}
                    <StyledTableCell
                        colSpan={10}
                        align="center"
                        style={{
                            color: '#9F9F9F',
                            fontSize: '1rem',
                        }}
                    >
                        No investors under this category
                    </StyledTableCell>{' '}
                </StyledTableRow>
            ) : (
                <React.Fragment>
                    <ShareholderDialog
                        dialogIsOpen={dialogIsOpen}
                        setDialogIsOpen={setDialogIsOpen}
                    />

                    <StyledTableRow>
                        <StyledTableCell
                            style={{
                                width: '5%',
                            }}
                        ></StyledTableCell>
                        <StyledTableCell
                            onClick={() => openDialog(investor.ct_id)}
                            style={{
                                color: '#9F9F9F',
                                fontSize: '1rem',
                                cursor: 'pointer',
                            }}
                        >
                            <Tooltip
                                title={
                                    "Click on the holder's name to see more information."
                                }
                                arrow
                            >
                                <span>{investor.investor}</span>
                            </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell></StyledTableCell>

                        <StyledTableCell
                            align="left"
                            style={{
                                color: '#9F9F9F',
                                fontSize: '1rem',
                            }}
                        >
                            {formatNumber(investor.shares)}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                            {Humanize.formatNumber(investor['shares_%'], 2)}%
                        </StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell
                            align="left"
                            style={{
                                color: '#9F9F9F',
                                fontSize: '1rem',
                            }}
                        >
                            {investor.acquired_price
                                ? formatNumber(investor.acquired_price)
                                : '-'}
                        </StyledTableCell>
                        <StyledTableCell
                            align="left"
                            style={{
                                color: '#9F9F9F',
                                fontSize: '1rem',
                            }}
                        >
                            {investor.acquired_date
                                ? DateFormatter({
                                      value: investor.acquired_date,
                                      format: 'date',
                                  })
                                : '-'}
                        </StyledTableCell>
                    </StyledTableRow>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        capTableActions: bindActionCreators(capTableActions, dispatch),
    };
};

export default connect(null, mapDispatchToProps)(Row);
