export const GET_SUMMARY_SUCCESS = 'GET_SUMMARY_SUCCESS';
export const SET_SUMMARY_LOADING = 'SET_SUMMARY_LOADING';

export const GET_SHAREHOLDERS_SUCCESS = 'GET_SHAREHOLDERS_SUCCESS';
export const SET_SHAREHOLDERS_LOADING = 'SET_SHAREHOLDERS_LOADING';
export const UPDATE_SHAREHOLDER_SUCCESS = 'UPDATE_SHAREHOLDER_SUCCESS';
export const DELETE_SHAREHOLDER_SUCCESS = 'DELETE_SHAREHOLDER_SUCCESS';

export const GET_CLASSES_SUCCESS = 'GET_CLASSES_SUCCESS';
export const SET_CLASSES_LOADING = 'SET_CLASSES_LOADING';
export const UPDATE_CLASS_SUCCESS = 'UPDATE_CLASS_SUCCESS';
export const DELETE_CLASS_SUCCESS = 'DELETE_CLASS_SUCCESS';

export const GET_CLASS_CATEGORIES_SUCCESS = 'GET_CLASS_CATEGORIES_SUCCESS';
export const SET_CLASS_CATEGORIES_LOADING = 'SET_CLASS_CATEGORIES_LOADING';

export const GET_CATEGORIES_BY_CLASS_SUCCESS =
    'GET_CATEGORIES_BY_CLASS_SUCCESS';
export const SET_CATEGORIES_BY_CLASS_LOADING =
    'SET_CATEGORIES_BY_CLASS_LOADING';

export const GET_CAP_TABLE_CHART_SUCCESS = 'GET_CAP_TABLE_CHART_SUCCESS';
export const SET_CAP_TABLE_CHART_LOADING = 'SET_CAP_TABLE_CHART_LOADING';

export const GET_ENTRY_SUCCESS = 'GET_ENTRY_SUCCESS';
export const SET_ENTRY_LOADING = 'SET_ENTRY_LOADING';

export const ADD_SHARE_CLASS_SUCCESS = 'ADD_SHARE_CLASS_SUCCESS';
export const SET_SHARE_CLASS_LOADING = 'SET_SHARE_CLASS_LOADING';

export const ADD_SHARE_CATEGORY_SUCCESS = 'ADD_SHARE_CATEGORY_SUCCESS';
export const SET_SHARE_CATEGORY_LOADING = 'SET_SHARE_CATEGORY_LOADING';

export const ADD_INVESTOR_SUCCESS = 'ADD_INVESTOR_SUCCESS';
export const SET_INVESTOR_LOADING = 'SET_INVESTOR_LOADING';

export const GET_REGISTRY_SUCCESS = 'GET_REGISTRY_SUCCESS';
export const SET_REGISTRY_LOADING = 'SET_REGISTRY_LOADING';

export const GET_INVESTORS_SUCCESS = 'GET_INVESTORS_SUCCESS';
export const SET_INVESTORS_LOADING = 'SET_INVESTORS_LOADING';
