import useSWR from "swr"
import { API_BASE_URL } from "../../config"
import fetcher from "../../utilities/fetcher"

const useGreenWalletAssets = (investor_id, amp_user_id) => {
    const { data, error, isValidating, mutate } = useSWR(`${API_BASE_URL}/blockchain/${investor_id}/sub-accounts/${amp_user_id}/assets`, fetcher)

    return {
        greenWalletAssets: data,
        greenWalletAssetsLoading: !error && !data,
        greenWalletAssetsValidating: isValidating,
        greenWalletAssetsError: error,
        updateGreenWalletAssets: () => mutate()
    }
}

export default useGreenWalletAssets