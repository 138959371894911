import * as assetIssuanceTypes from './assetIssuanceTypes'

export const setIssuanceStepSuccess = (data) => {
    return {
        type: assetIssuanceTypes.SET_ISSUANCE_STEP,
        payload: data
    }
}

export const setIssuanceStep = (data) => {
    return (dispatch) => {
        dispatch(setIssuanceStepSuccess(data))
    }
}