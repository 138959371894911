import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as profileActions from './store/profile/profileActions';
import CssBaseline from '@mui/material/CssBaseline';
import {
    ThemeProvider,
    StyledEngineProvider,
    createTheme,
} from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { SnackbarProvider } from 'notistack';
import Routes from './routes/Routes';
import { SnackbarUtilsConfigurator } from './utilities/snackbarUtils';
import { isRefreshTokenValid } from './utilities/auth';
import { useSnackbar } from 'notistack';
import './App.css';
import Button from '@mui/material/Button';
const defaultTheme = createTheme();
const theme = createTheme({
    typography: {
        fontFamily: "'Saira', Roboto, sans-serif; !important",
        color: '#333333',
    },
    palette: {
        primary: {
            main: '#3E9CD9',
        },
        secondary: {
            main: '#2d3748',
        },
        background: {
            default: '#F6F6F6',
        },
        danger: defaultTheme.palette.augmentColor({
            color: { main: '#ED7571', contrastText: '#fff' },
            name: 'danger',
        }),
    },
    components: {
        MuiButton: {
            styleOverrides: {
                contained: {
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: '0 2px 6px rgba(0, 74, 128,.25)',
                    },
                },
                containedPrimary: {
                    color: '#FFF',
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                colorPrimary: {
                    height: '2px',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    padding: 14,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    boxShadow: '0 2px 8px rgba(31,45,61,.05)',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    paddingTop: 12,
                    paddingBottom: 12,
                    minHeight: 45,
                    fontSize: '0.9rem',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '3px',
                },
            },
        },
        MuiCollapse: {
            styleOverrides: {
                wrapperInner: {
                    //minWidth: '50em',
                },
            },
        },

        // MuiGrid: {
        //     root: {
        //         //minWidth: '15em',
        //     },
        // },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:last-child td': {
                        borderBottom: 0,
                    },
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: { width: '1.9rem' },
            },
        },
        MuiListItem: {
            styleOverrides: {
                gutters: {
                    paddingLeft: 0,
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paperFullWidth: {
                    background: '#FFFFFF',
                    boxShadow: '0px 0px 60px rgba(0, 0, 0, 0.1)',
                    borderRadius: '20px',
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontFamily: "'Saira', Roboto, sans-serif; !important",
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontFamily: "'Saira', Roboto !important",
                },
            },
        },
        MuiTextField: {
            variants: [{ props: { variant: 'base' } }],
        },
    },
});

const styles = () => ({
    '@global': {
        body: {
            fontFamily: "'Saira', sans-serif;",
            paddingRight: '0 !important',
            overflowY: 'scroll !important',
        },
    },
});
const DismissAction = (id) => {
    const { closeSnackbar } = useSnackbar();
    return (
        <Button color="inherit" onClick={() => closeSnackbar()}>
            <b>Close</b>
        </Button>
    );
};
class App extends React.Component {
    componentDidMount() {
        if (isRefreshTokenValid()) {
            this.props.profileActions.getProfile();
        }
    }

    render() {
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <SnackbarProvider
                        maxSnack={3}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        autoHideDuration={null}
                        action={(snackbarId) => (
                            <DismissAction id={snackbarId} />
                        )}
                    >
                        <SnackbarUtilsConfigurator />
                        <Routes />
                    </SnackbarProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        profileActions: bindActionCreators(profileActions, dispatch),
    };
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(App));
