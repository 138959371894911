import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography } from '@mui/material'

import useWalletAssets from '../../../../api/blockchain/useWalletAssets';

import BCTable from '../Utils/BCTable';

const WalletAssetsDialog = ({ open, handleClose, wallet }) => {
    const { walletAssets, walletAssetsError, walletAssetsLoading } = useWalletAssets(wallet?.wallet)
    return (
        <Dialog open={open} onClose={handleClose} maxWidth='lg'>
            <DialogTitle>Wallet assets ({wallet?.wallet})</DialogTitle>
            <DialogContent>
                <Alert variant='outlined' severity="info" sx={{ marginBottom: '10px' }}>
                    <b>L-BTC balance:</b> {wallet?.balance}
                </Alert>
                {
                    (walletAssetsLoading) &&
                    <>
                        <Typography variant='body2' as='p'>Loading wallet assets...</Typography>
                        <br />
                        <LinearProgress />
                    </>
                }
                {
                    (!walletAssetsLoading && walletAssets) &&
                    <BCTable
                        columns={[
                            { name: 'name', label: 'Asset', bold: true },
                            { name: 'amount', label: 'Circulating amount', type: 'amount' },
                            { name: 'created_at', label: 'Issued on', type: 'date', format: 'MMM DD, YYYY' },
                        ]}
                        data={walletAssets}
                    />
                }
                <br />
                {/* No data */}
                {
                    (!walletAssetsLoading && !walletAssetsError && walletAssets.length === 0) &&
                    <Typography variant='body2' as='p' sx={{ textAlign: 'center' }}>
                        You have no assets in this wallet.
                    </Typography>
                }
                {/* Wallet error */}
                {
                    (walletAssetsError && !walletAssets) &&
                    <Typography variant='body2' as='p' sx={{ textAlign: 'center' }}>
                        Could not load wallet assets due to error. Please try again.
                    </Typography>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default WalletAssetsDialog