import React from 'react';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import authStyles from '../authStyles';
import InputLabel from '@mui/material/InputLabel';
import { useDispatch } from 'react-redux';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

import { handleLogin } from '../../../utilities/login';
import { SUBSCRIPTION_PRO } from '../../../constants/profile';
import { snackbar } from '../../../utilities/snackbarUtils';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as paymentActions from '../../../store/payment/paymentActions';
import { upgradeSubscription } from '../../../utilities/payments';

const Complete = (props) => {
    const classes = authStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { payment, paymentActions } = props;

    const nextStep = () => {
        props.nextStep();
    };

    return (
        <div id="step12">
            <Formik
                initialValues={props.initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    if (
                        props.initialValues.subscription_type ===
                        SUBSCRIPTION_PRO
                    ) {
                        snackbar.success(
                            'Your account has been created. You will be redirected to complete your payment in 3 seconds.'
                        );
                        setTimeout(() => {
                            upgradeSubscription(
                                payment,
                                paymentActions,
                                props.initialValues.subscription_type.toUpperCase(),
                                values['email'],
                                values['password']
                            );
                        }, 3000);
                    } else {
                        handleLogin(
                            values['email'],
                            values['password'],
                            history,
                            dispatch
                        );
                    }
                }}
            >
                {({ isSubmitting }) => (
                    <Paper className={classes.paper}>
                        <Typography
                            component="div"
                            style={{ textAlign: 'center' }}
                        >
                            <h2>Congratulations...</h2>
                            <h2>Holders are going to love you!</h2>
                        </Typography>
                        <Container maxWidth="sm">
                            <Form className={classes.form} noValidate>
                                <InputLabel
                                    style={{
                                        color: '#666',
                                        fontSize: '1rem',
                                        textAlign: 'center',
                                    }}
                                    htmlFor="additional_comment"
                                >
                                    Would you also like to see the Investor
                                    view?
                                </InputLabel>
                                <InputLabel
                                    style={{
                                        color: '#666',
                                        fontSize: '1rem',
                                        textAlign: 'center',
                                    }}
                                    htmlFor="additional_comment"
                                >
                                    {props.initialValues.subscription_type ===
                                    SUBSCRIPTION_PRO ? (
                                        <>
                                            Click "SKIP" to{' '}
                                            <b>finalize your payment</b> <br />{' '}
                                            and go to the Issuer dashboard
                                            instead.
                                        </>
                                    ) : (
                                        'Click "SKIP" to go to the Issuer dashboard instead.'
                                    )}
                                </InputLabel>

                                <div
                                    className={classes.actions}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Button
                                        // type="submit"
                                        variant="outlined"
                                        className={classes.buttonLabel}
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={nextStep}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        Yes, let's create my Investor account
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        className={classes.secondaryButtonLabel}
                                        color="secondary"
                                        title={
                                            props.initialValues
                                                .subscription_type ===
                                            SUBSCRIPTION_PRO
                                                ? 'Finalize Payment & Go to Issuer Dashboard'
                                                : 'Go to Issuer Dashboard'
                                        }
                                        disabled={isSubmitting}
                                    >
                                        <DoubleArrowIcon />
                                        {isSubmitting ? (
                                            <CircularProgress size={30} />
                                        ) : (
                                            'SKIP'
                                        )}
                                    </Button>
                                </div>
                            </Form>
                        </Container>
                    </Paper>
                )}
            </Formik>
        </div>
    );
};

const mapStateToProps = ({ payment }) => {
    return {
        payment: payment,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        paymentActions: bindActionCreators(paymentActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Complete);
