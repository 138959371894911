import React, { useEffect, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { numberRegMatch } from '../../../../constants/blockchain';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../../../../components/Form/Input';
import Select from '../../../../components/Form/Select';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import SubmitButton from '../../../../components/Form/SubmitButton';
import sendAsset from '../../../../api/blockchain/sendAsset';
import { snackbar } from '../../../../utilities/snackbarUtils';
import { errorParser } from '../../../../components/LiquidForm/utils/errorParser';
import { BLOCKSTREAM_EXPLORER_URL } from '../../../../config';
import useGreenWalletAssets from '../../../../api/blockchain/useGreenWalletAssets';
import DataLoading from '../Utils/DataLoading';

const ReviewSend = ({ data, assets }) => {

    const [ selectedAsset, setSelectedAsset ] = useState(null);

    useEffect(() => {
        setSelectedAsset(assets.find(asset => asset.assetId === data.asset));
    }, [data, assets]);

    return <>
        <div style={{ display: 'flex', color: 'gray' }}>
            <Typography variant='body2'>
                Address
            </Typography>
        </div>
        <Typography variant='body2'>
            { data.address }
        </Typography>
        <br />

        <div style={{ display: 'flex', color: 'gray' }}>
            <Typography variant='body2'>
                Asset
            </Typography>
        </div>
        <Typography variant='body2'>
            { selectedAsset?.name }
        </Typography>
        <br />

        <div style={{ display: 'flex', color: 'gray' }}>
            <Typography variant='body2'>
                Amount
            </Typography>
        </div>
        <Typography variant='body2'>
            {data.amount}
        </Typography>
    </>;
}

const AssetsSent = ( { data } ) => {
    
    return <>
        <div style={{ display: 'flex', color: 'gray' }}>
            <Typography variant='body2'>
                Tx
            </Typography>
        </div>
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={6}
        >
            <Typography variant='body2'>
                {data.txhash}
            </Typography>
            <IconButton variant="outlined" size="small"
                sx={{ marginRight: '10px;' }}
                onClick={() => window.open(
                    `${BLOCKSTREAM_EXPLORER_URL}/tx/${data.txhash}`,
                    '_blank'
                )}
            >
                <Tooltip title="View on-chain">
                    <ViewModuleIcon />
                </Tooltip>
            </IconButton>
        </Stack>
    </>;
}

const SendAssetsDialog = ({ user, open, handleClose, acc_amp_user_id }) => {

    const [ reviewing, setReviewing ] = useState(false);
    const [ sentData, setSentData ] = useState(null);

    const [ data, setData ] = useState(null);

    const schema = yup.object({
        address: yup
            .string()
            .required('Please enter the target address.'),
        asset: yup
            .string()
            .required('Please select an asset'),
        amount: yup
            .string()
            .required('Please enter the amount to send.')
            .matches(
                numberRegMatch,
                'Please enter a valid amount.'
            )
    });

    const defaultValues = {
        address: '',
        asset: '',
        amount: '',
    }

    const { greenWalletAssets, greenWalletAssetsLoading } = useGreenWalletAssets(user.id, acc_amp_user_id);

    const { handleSubmit, control, reset, formState: { isSubmitting, errors }, } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    const onSubmit = (data) => {
        
        if (!reviewing) {
            setData(data);
            setReviewing(true);
            return;
        }

        return sendAsset(user?.amp_user_id, data.asset, data.address, data.amount).then((response) => {
            setSentData(response.data);
            setReviewing(false);
            reset();
        }).catch((err) => {
            let errorMessage = errorParser(err);

            if (errorMessage.includes('insufficient_funds')) {
                errorMessage = 'You do not have enough funds to cover the transaction cost plus fees';
            }

            snackbar.error(errorMessage);
        });
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle style={{ display: 'flex', alignItems: 'center'}}>
                    { !sentData ? <><SendIcon /> Send</> : "Transaction Sent" }
                </DialogTitle>
                <DialogContent>
                    { sentData && <AssetsSent data={sentData} /> }
                    { reviewing && !sentData && <ReviewSend data={data} assets={greenWalletAssets} />}
                    { !reviewing && !sentData && (
                        <>
                            <div style={{ display: 'flex', color: 'gray' }}>
                                <Typography variant='body2'>
                                    Address
                                </Typography>
                            </div>
                            <br />
                            <Input
                                autoFocus
                                name="address"
                                label="Address"
                                type="text"
                                fullWidth
                                error={errors?.address}
                                control={control}
                                variant="outlined"
                                placeholder=""
                            />
                            <br />
                            {
                                <DataLoading 
                                    loading={greenWalletAssetsLoading}
                                    text={"Loading subaccount assets..."}
                                />
                            }
                            {
                                !greenWalletAssetsLoading && (
                                    <>
                                        <div style={{ display: 'flex', color: 'gray', marginTop: '10px', marginBottom: '20px' }}>
                                            <Typography variant='body2'>
                                                Asset
                                            </Typography>
                                        </div>
                                        <Select
                                            name="asset"
                                            label="Asset"
                                            fullWidth
                                            error={errors?.asset}
                                            control={control}
                                            default={null}
                                            variant="outlined"
                                            options={greenWalletAssets?.map(asset => ({
                                                text: <Stack 
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    gap={6}
                                                    sx={{width: '100%'}}
                                                >
                                                    <Stack direction="row" alignItems="center" gap={1}>
                                                        { asset.icon && <img alt={asset.name} width={24} height={24} src={`data:image/png;base64,${asset.icon}`} /> }
                                                        <p>{asset.name || asset.assetId}</p>
                                                    </Stack>
                                                    <span>{`${asset.precision ? asset.balance * Math.pow(10, -asset.precision) : asset.balance} ${asset.ticker || ''}`}</span>
                                                </Stack>,
                                                value: asset.assetId
                                            }))}
                                        />
                                    </>
                                )
                            }
                            <div style={{ display: 'flex', color: 'gray', marginTop: '10px' }}>
                                <Typography variant='body2'>
                                    Amount
                                </Typography>
                            </div>
                            <br />
                            <Input
                                name="amount"
                                label="Amount"
                                fullWidth
                                error={errors?.amount}
                                control={control}
                                variant="outlined"
                                placeholder=""
                            />
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button disabled={isSubmitting} onClick={handleClose}>Close</Button>
                    { reviewing && <Button disabled={isSubmitting} onClick={() => setReviewing(false) }>Cancel</Button> }
                    { !sentData && (
                        <SubmitButton 
                            text={reviewing ? 'Send' : 'Review' }
                            disabled={isSubmitting}
                            loading={isSubmitting}
                        />
                    )}
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default SendAssetsDialog;
