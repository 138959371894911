import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import authStyles from '../authStyles';
import HelpTooltip from '../../../components/HelpTooltip/HelpTooltip';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
const EquityInformationShare = (props) => {
    const classes = authStyles();
    //const account_types = [{ id: 'issuer', name: 'Issuer' }];

    const [trackDifferentCategories, setTrackDifferentCategories] = useState(
        props.initialValues.track_different_categories
    );
    const [vestingEquities, setVestingEquities] = useState(
        props.initialValues.vesting_equities
    );
    const [optionPlan, setOptionPlan] = useState(
        props.initialValues.option_plan
    );

    const [displayConfirmationDialog, setDisplayConfirmationDialog] = useState(
        false
    );
    const [
        displayOptionConfirmationDialog,
        setDisplayOptionConfirmationDialog,
    ] = useState(false);

    const [key, setKey] = useState(0);

    const nextStep = () => {
        if (trackDifferentCategories === 'no') {
            props.skipNextPage();
        } else if (trackDifferentCategories === 'yes') {
            props.nextStep();
        }
    };

    const changeTrackDifferentCategories = (e) => {
        if (
            props.initialValues.categories.length > 0 &&
            e.target.value === 'no'
        ) {
            setDisplayConfirmationDialog(true);
        } else {
            setTrackDifferentCategories(e.target.value);
        }
    };

    const cancelTrackCategoriesChange = () => {
        setTrackDifferentCategories('yes');
        setDisplayConfirmationDialog(false);
        setKey(key + 1);
    };

    const changeVestingEquities = (e) => {
        setVestingEquities(e.target.value);
    };

    const changeOptionPlan = (e) => {
        if (
            props.initialValues.investors.length > 0 &&
            e.target.value === 'no'
        ) {
            setDisplayOptionConfirmationDialog(true);
        } else {
            setOptionPlan(e.target.value);
        }
    };

    const cancelOptionPlanChange = () => {
        setOptionPlan('yes');
        setDisplayOptionConfirmationDialog(false);
        setKey(key + 1);
    };

    return (
        <div id="step6">
            <Formik
                key={key}
                initialValues={props.initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    values['vesting_equities'] = vestingEquities;
                    values['option_plan'] = optionPlan;
                    values[
                        'track_different_categories'
                    ] = trackDifferentCategories;
                    props.saveValues(values);
                    nextStep();
                }}
            >
                <Paper className={classes.paper}>
                    <Typography component="div" style={{ textAlign: 'center' }}>
                        <h2>Equity Information — Asset Details</h2>
                        <h5>
                            (You will be able to edit this information later)
                        </h5>
                    </Typography>
                    <Container style={{ textAlign: 'right' }}>
                        <Form className={classes.form} noValidate>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <InputLabel
                                        style={{
                                            color: '#666',
                                            fontSize: '1rem',
                                        }}
                                        htmlFor="vesting_equities"
                                    >
                                        Are there any of your assets subject to
                                        vesting?
                                        <HelpTooltip
                                            title={
                                                'If you have awarded any assets to employees, there may be a vesting schedule.'
                                            }
                                        />
                                    </InputLabel>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <RadioGroup
                                        name="vesting_equities"
                                        onChange={changeVestingEquities}
                                        row
                                        defaultValue={vestingEquities}
                                    >
                                        <FormControlLabel
                                            value={'no'}
                                            control={<Radio color="primary" />}
                                            label="No"
                                            labelPlacement="end"
                                        />

                                        <FormControlLabel
                                            value={'yes'}
                                            control={<Radio color="primary" />}
                                            label="Yes"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <InputLabel
                                        style={{
                                            color: '#666',
                                            fontSize: '1rem',
                                        }}
                                        htmlFor="option_plan"
                                    >
                                        Do you have a stock option plan?
                                        <HelpTooltip
                                            title={
                                                'An option plan would exist if you have set aside assets for current and future employees.'
                                            }
                                        />
                                    </InputLabel>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <RadioGroup
                                        name="option_plan"
                                        row
                                        defaultValue={optionPlan}
                                    >
                                        <FormControlLabel
                                            value={'no'}
                                            control={<Radio color="primary" />}
                                            label="No"
                                            labelPlacement="end"
                                            onChange={changeOptionPlan}
                                        />

                                        <FormControlLabel
                                            value={'yes'}
                                            control={<Radio color="primary" />}
                                            label="Yes"
                                            labelPlacement="end"
                                            onChange={changeOptionPlan}
                                        />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <InputLabel
                                        style={{
                                            color: '#666',
                                            fontSize: '1rem',
                                        }}
                                        htmlFor="track_different_categories"
                                    >
                                        Do you track different categories within
                                        your asset classes?{' '}
                                        <HelpTooltip
                                            title={
                                                'A class of assets is a type of stock that is differentiated by the level of rights holders receive and even when those assets were issued. For example, company might have three asset classes,, designated as Class A and Class B and Advisory assets. This can also include founder assets, employee assets, and assets from different fundraising rounds.'
                                            }
                                        />
                                    </InputLabel>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <RadioGroup
                                        name="track_different_categories"
                                        row
                                        style={{
                                            display: 'flex',
                                        }}
                                        defaultValue={trackDifferentCategories}
                                        onChange={
                                            changeTrackDifferentCategories
                                        }
                                    >
                                        <FormControlLabel
                                            value={'no'}
                                            control={<Radio color="primary" />}
                                            label="No"
                                            labelPlacement="end"
                                        />

                                        <FormControlLabel
                                            value={'yes'}
                                            control={<Radio color="primary" />}
                                            label="Yes"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            <div className={classes.actions}>
                                <ButtonGroup
                                    aria-label="outlined primary button group"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        className={classes.secondaryButtonLabel}
                                        color="secondary"
                                        onClick={() => props.previousStep()}
                                        style={{ margin: 'inherit' }}
                                    >
                                        <ArrowBackIosIcon /> BACK
                                    </Button>{' '}
                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        className={classes.buttonLabel}
                                        color="primary"
                                        style={{ margin: 'auto' }}
                                    >
                                        CONTINUE
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        className={classes.secondaryButtonLabel}
                                        color="secondary"
                                        onClick={nextStep}
                                        style={{ margin: 'inherit' }}
                                    >
                                        SKIP
                                        <DoubleArrowIcon />
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </Form>
                    </Container>
                </Paper>
            </Formik>{' '}
            <Dialog maxWidth="sm" fullWidth open={displayConfirmationDialog}>
                <DialogTitle className={classes.dialogTitle}>
                    Confirmation Dialog
                </DialogTitle>
                <DialogContent>
                    <Typography component="h2" style={{ color: '#ED7571' }}>
                        <b>
                            Are you sure that you want to Remove categories and
                            holders that are added?
                        </b>
                    </Typography>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={12}>
                            <Formik
                                initialValues={{ confirm_deletion: null }}
                                onSubmit={(values, { setSubmitting }) => {
                                    setTrackDifferentCategories('no');

                                    let val = props.initialValues;
                                    val.shareholders = [];
                                    val.categories = [];
                                    props.saveValues(val);
                                    setDisplayConfirmationDialog(false);
                                }}
                            >
                                {({ isSubmitting }) => (
                                    <Container>
                                        <Form
                                            className={classes.form}
                                            noValidate
                                        >
                                            <DialogActions
                                                className={classes.actions}
                                            >
                                                <Button
                                                    color="primary"
                                                    onClick={
                                                        cancelTrackCategoriesChange
                                                    }
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                >
                                                    Confirm
                                                </Button>
                                            </DialogActions>
                                        </Form>
                                    </Container>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={displayOptionConfirmationDialog}
            >
                <DialogTitle className={classes.dialogTitle}>
                    Confirmation Dialog
                </DialogTitle>
                <DialogContent>
                    <Typography component="h2" style={{ color: '#ED7571' }}>
                        <b>
                            Are you sure that you want to Remove investors that
                            are added?
                        </b>
                    </Typography>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={12}>
                            <Formik
                                initialValues={{ confirm_deletion: null }}
                                onSubmit={(values, { setSubmitting }) => {
                                    setOptionPlan('no');
                                    let val = props.initialValues;
                                    val.investors = [];
                                    props.saveValues(val);
                                    setDisplayOptionConfirmationDialog(false);
                                }}
                            >
                                {({ isSubmitting }) => (
                                    <Container>
                                        <Form
                                            className={classes.form}
                                            noValidate
                                        >
                                            <DialogActions
                                                className={classes.actions}
                                            >
                                                <Button
                                                    color="primary"
                                                    onClick={
                                                        cancelOptionPlanChange
                                                    }
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                >
                                                    Confirm
                                                </Button>
                                            </DialogActions>
                                        </Form>
                                    </Container>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default EquityInformationShare;
