import { API_BASE_URL } from "../../config"
import request from "../../utilities/request"

const createReceivingAddress = (investorId, addressType, ampUserId = null) => {
    const payload = {
        investor_id: investorId,
        address_type: addressType
    };

    if (ampUserId) {
        payload.holder_id = ampUserId;
    }

    return request.post(`${API_BASE_URL}/blockchain/holder/addresses`, payload);
}

export default createReceivingAddress