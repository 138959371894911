import useSWR from "swr"
import { API_BASE_URL } from "../../config"
import fetcher from "../../utilities/fetcher"

const useUserInfo = (userId) => {
    const { data, error, mutate } = useSWR(() => userId ? `${API_BASE_URL}/blockchain/registered-users/${userId}` : null, fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    })

    return {
        userInfo: data || [],
        userInfoLoading: !error && !data,
        userInfoError: error,
        updateUserInfo: () => mutate()
    }
}

export default useUserInfo