/**
 * Transfer types.
 */

export const TRANSFER = 'transfer';
export const ISSUANCE = 'issuance';
export const RETIREMENT = 'retirement';

/**
 * Transfer status.
 */

export const PENDING = 'pending';
export const APPROVED = 'approved';
export const REJECTED = 'rejected';
