import React from 'react';
import { CSVLink } from 'react-csv';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import ExportIcon from '@mui/icons-material/GetApp';
import withStyles from '@mui/styles/withStyles';
import baseTableStyles from '../styles/baseTableStyles';
import Button from '../Button/Button';
import {
    NumberFormatter,
    PercentageFormatter,
    CurrencyFormatter,
    DateFormatter,
} from '../../utilities/formatters';

class BaseTable extends React.PureComponent {
    generateDisplayedRowsLabel = ({ from, to, count }) =>
        `${from}-${to} of ${count !== 9223372036854776000 ? count : 'many'}`;

    createSortHandler = (column) => (event) => {
        this.props.onChangeSort(event, column);
    };

    defaultColumnWidth = this.props.autoLayout ? null : 100;

    getColumnWidth = (width) => {
        return width
            ? isNaN(width)
                ? width
                : `${width}px`
            : this.defaultColumnWidth
            ? `${this.defaultColumnWidth}px`
            : 'auto';
    };

    generateFixedColumnStyles = (dataColumns) => {
        const defaultColumnWidth = this.defaultColumnWidth
            ? this.defaultColumnWidth
            : 100;
        const fixedColumnStyles = {};
        let marginLeft = 0;

        dataColumns.forEach((column, index) => {
            if (column.fixed) {
                fixedColumnStyles[column.id] = {
                    left: `${marginLeft}px`,
                    width: column.width
                        ? `${column.width}px`
                        : `${defaultColumnWidth}px`,
                };
                marginLeft +=
                    (column.width ? column.width : defaultColumnWidth) +
                    17 +
                    (index === 0 ? 16 : 0);
            }
        });

        return fixedColumnStyles;
    };

    getFormattedCellValue = (row, column, value) => {
        if (column.editable && !row.isTotalRow) {
            switch (column.editable.type) {
                case 'custom':
                    return column.editable.formatter({ row, column, value });
                default:
                    return value;
            }
        } else {
            let cellValue;
            switch (column.format.type) {
                case 'text':
                    cellValue = value;
                    break;
                case 'number':
                    cellValue = (
                        <NumberFormatter value={value} {...column.format} />
                    );
                    break;
                case 'percentage':
                    cellValue = (
                        <PercentageFormatter value={value} {...column.format} />
                    );
                    break;
                case 'currency':
                    cellValue = (
                        <CurrencyFormatter value={value} {...column.format} />
                    );
                    break;
                case 'date':
                    cellValue = (
                        <DateFormatter value={value} {...column.format} />
                    );
                    break;
                case 'custom':
                    cellValue = column.format.formatter({ row, column, value });
                    break;
                default:
                    cellValue = value;
                    break;
            }
            if (column.wrap && !row.isTotalRow) {
                switch (column.wrap.type) {
                    case 'custom':
                        return column.wrap.wrapper({
                            row,
                            column,
                            value,
                            cellValue,
                        });
                    default:
                        return cellValue;
                }
            } else {
                return cellValue;
            }
        }
    };

    getCSVData = (columns, rows, rowParser) => {
        const exportColumns = columns.filter((column) => !column.exportSkip);
        const CSVData = [[]];

        exportColumns.forEach((column) => {
            CSVData[0].push(column.label);
        });

        rows.forEach((row) => {
            const parsedRow = rowParser ? rowParser(row) : row;
            const addRow = [];

            exportColumns.forEach((column) => {
                const value = parsedRow[column.id];

                switch (column.format.type) {
                    case 'number':
                        addRow.push(
                            NumberFormatter({ value, ...column.format })
                        );
                        break;
                    case 'percentage':
                        addRow.push(
                            PercentageFormatter({ value, ...column.format })
                        );
                        break;
                    case 'currency':
                        addRow.push(
                            CurrencyFormatter({
                                value,
                                ...column.format,
                                raw: true,
                            })
                        );
                        break;
                    case 'date':
                        addRow.push(DateFormatter({ value, ...column.format }));
                        break;
                    default:
                        addRow.push(value ? value : '');
                        break;
                }
            });

            CSVData.push(addRow);
        });

        return CSVData;
    };

    render() {
        const {
            isLoading,
            loadingError,
            dataColumnGroups,
            dataColumns,
            dataRows,
            dataRowParser,
            dataTotals,
            displayTotals,
            totalCount,
            currentPage,
            rowsPerPage,
            rowsPerPageOptions,
            fixedRowsPerPage,
            sortColumn,
            sortDirection,
            searchValue,
            enableSearch,
            enableExport,
            selectedColumnGroup,
            onChangeColumnGroup,
            onChangePage,
            onChangeRowsPerPage,
            onChangeSearch,
            autoLayout,
            filtersComponent: FiltersComponent,
            actionsComponent: ActionsComponent,
            classes,
        } = this.props;

        const fixedColumnStyles = this.generateFixedColumnStyles(dataColumns);

        const CSVData = this.getCSVData(dataColumns, dataRows, dataRowParser);

        return (
            <div className={classes.componentRoot}>
                <Paper>
                    <Toolbar classes={{ root: classes.toolbarRoot }}>
                        <div>
                            {enableSearch && (
                                <TextField
                                    value={searchValue}
                                    placeholder="Search..."
                                    onChange={(event) =>
                                        onChangeSearch(event.target.value)
                                    }
                                    variant="outlined"
                                    size="small"
                                    InputProps={{
                                        classes: { root: classes.searchInput },
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Tooltip title="Search">
                                                    <SearchIcon fontSize="small" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    disabled={!searchValue}
                                                    onClick={() =>
                                                        onChangeSearch('')
                                                    }
                                                    size="large">
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                            {FiltersComponent ? <FiltersComponent /> : null}
                        </div>
                        <div>
                            {ActionsComponent ? <ActionsComponent /> : null}
                            {enableExport && (
                                <CSVLink
                                    data={CSVData}
                                    filename="export.csv"
                                    className={classes.exportButton}
                                >
                                    <Button preset="orange">
                                        <ExportIcon />
                                        Export
                                    </Button>
                                </CSVLink>
                            )}
                        </div>
                    </Toolbar>
                    <div
                        className={`${classes.tableContainer} ${
                            isLoading ? classes.tableContainerLoading : ''
                        }`}
                    >
                        {(!isLoading || dataRows.length > 0) && (
                            <div>
                                {dataColumnGroups && (
                                    <div>
                                        <Tabs
                                            value={selectedColumnGroup}
                                            onChange={onChangeColumnGroup}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            classes={{
                                                root: classes.tabsRoot,
                                                indicator:
                                                    classes.tabsIndicator,
                                            }}
                                        >
                                            {dataColumnGroups.map((group) => (
                                                <Tab
                                                    key={group.id}
                                                    value={group.id}
                                                    label={group.label}
                                                    classes={{
                                                        root: classes.tabRoot,
                                                        wrapper: `${
                                                            classes.tabWrapper
                                                        } ${
                                                            group.id ===
                                                            selectedColumnGroup
                                                                ? classes.tabSelected
                                                                : ''
                                                        }`,
                                                        labelContainer:
                                                            classes.tabLabelContainer,
                                                    }}
                                                />
                                            ))}
                                        </Tabs>
                                    </div>
                                )}
                                <div className={classes.tableWrapper}>
                                    <Table
                                        classes={{ root: classes.tableRoot }}
                                        style={{
                                            tableLayout: autoLayout
                                                ? 'fixed'
                                                : 'auto',
                                        }}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {dataColumns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        sortDirection={
                                                            sortColumn ===
                                                            (column.sortId
                                                                ? column.sortId
                                                                : column.id)
                                                                ? sortDirection
                                                                : false
                                                        }
                                                        align={
                                                            column.align
                                                                ? column.align
                                                                : 'center'
                                                        }
                                                        style={{
                                                            [autoLayout
                                                                ? 'width'
                                                                : 'minWidth']: this.getColumnWidth(
                                                                column.width
                                                            ),
                                                            ...fixedColumnStyles[
                                                                column.id
                                                            ],
                                                        }}
                                                        classes={{
                                                            root:
                                                                classes.tableHeadCellRoot,
                                                        }}
                                                        className={
                                                            column.fixed
                                                                ? `${classes.tableFixedCell} ${classes.tableFixedCellWebkit}`
                                                                : ''
                                                        }
                                                    >
                                                        {column.sortDisabled ? (
                                                            column.label
                                                        ) : (
                                                            <TableSortLabel
                                                                active={
                                                                    sortColumn ===
                                                                    (column.sortId
                                                                        ? column.sortId
                                                                        : column.id)
                                                                }
                                                                direction={
                                                                    sortDirection
                                                                }
                                                                onClick={this.createSortHandler(
                                                                    column.sortId
                                                                        ? column.sortId
                                                                        : column.id
                                                                )}
                                                                className={
                                                                    classes.tableSortLabel
                                                                }
                                                            >
                                                                <div>
                                                                    {
                                                                        column.label
                                                                    }
                                                                </div>
                                                            </TableSortLabel>
                                                        )}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {dataRows.map((row) => {
                                                const parsedRow = dataRowParser
                                                    ? dataRowParser(row)
                                                    : row;
                                                return (
                                                    <TableRow
                                                        key={parsedRow.id}
                                                    >
                                                        {dataColumns.map(
                                                            (column) => (
                                                                <TableCell
                                                                    key={
                                                                        column.id
                                                                    }
                                                                    align={
                                                                        column.align
                                                                            ? column.align
                                                                            : 'center'
                                                                    }
                                                                    style={{
                                                                        minWidth: this.getColumnWidth(
                                                                            column.width
                                                                        ),
                                                                        maxWidth: column.fit
                                                                            ? this.getColumnWidth(
                                                                                  column.width
                                                                              )
                                                                            : 'none',
                                                                        ...fixedColumnStyles[
                                                                            column
                                                                                .id
                                                                        ],
                                                                    }}
                                                                    classes={{
                                                                        root:
                                                                            classes.tableCellRoot,
                                                                    }}
                                                                    className={`${
                                                                        column.fixed
                                                                            ? classes.tableFixedCell
                                                                            : ''
                                                                    } ${
                                                                        column.fixed
                                                                            ? classes.tableFixedCellWebkit
                                                                            : ''
                                                                    } ${
                                                                        column.fit
                                                                            ? classes.tableFitCell
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    {this.getFormattedCellValue(
                                                                        parsedRow,
                                                                        column,
                                                                        parsedRow[
                                                                            column
                                                                                .id
                                                                        ]
                                                                    )}
                                                                </TableCell>
                                                            )
                                                        )}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                        {dataRows.length > 0 &&
                                            displayTotals &&
                                            dataTotals && (
                                                <TableFooter>
                                                    <TableRow>
                                                        {dataColumns.map(
                                                            (column) => (
                                                                <TableCell
                                                                    key={
                                                                        column.id
                                                                    }
                                                                    align={
                                                                        column.align
                                                                            ? column.align
                                                                            : 'center'
                                                                    }
                                                                    style={{
                                                                        minWidth: this.getColumnWidth(
                                                                            column.width
                                                                        ),
                                                                        ...fixedColumnStyles[
                                                                            column
                                                                                .id
                                                                        ],
                                                                    }}
                                                                    classes={{
                                                                        root:
                                                                            classes.tableFooterCellRoot,
                                                                    }}
                                                                    className={
                                                                        column.fixed
                                                                            ? `${classes.tableFixedCell} ${classes.tableFixedCellWebkit}`
                                                                            : ''
                                                                    }
                                                                >
                                                                    {column.totalLabel
                                                                        ? 'Total'
                                                                        : column.totalSkip
                                                                        ? ''
                                                                        : this.getFormattedCellValue(
                                                                              dataTotals,
                                                                              column,
                                                                              dataTotals[
                                                                                  column
                                                                                      .id
                                                                              ]
                                                                          )}
                                                                </TableCell>
                                                            )
                                                        )}
                                                    </TableRow>
                                                </TableFooter>
                                            )}
                                    </Table>
                                    {(dataRows.length < 1 || loadingError) && (
                                        <div
                                            className={
                                                classes.bodyMessageContainer
                                            }
                                        >
                                            <Typography
                                                className={
                                                    classes.bodyMessageText
                                                }
                                            >
                                                {loadingError
                                                    ? 'An error occurred loading data'
                                                    : 'No data available'}
                                            </Typography>
                                        </div>
                                    )}
                                </div>
                                <TablePagination
                                    component="div"
                                    count={totalCount}
                                    page={currentPage}
                                    rowsPerPage={rowsPerPage}
                                    rowsPerPageOptions={
                                        fixedRowsPerPage
                                            ? []
                                            : rowsPerPageOptions
                                    }
                                    labelDisplayedRows={
                                        this.generateDisplayedRowsLabel
                                    }
                                    onPageChange={onChangePage}
                                    onRowsPerPageChange={onChangeRowsPerPage}
                                    classes={{
                                        toolbar: classes.tablePaginationToolbar,
                                    }}
                                />
                            </div>
                        )}
                        {isLoading && (
                            <div>
                                <div className={classes.loadingContainer} />
                                <div className={classes.loadingContent}>
                                    <CircularProgress />
                                </div>
                            </div>
                        )}
                    </div>
                </Paper>
            </div>
        );
    }
}

export default withStyles(baseTableStyles)(BaseTable);
