import useSWR, { useSWRConfig } from "swr"
import { API_BASE_URL } from "../../config"
import fetcher from "../../utilities/fetcher"

const useThreads = () => {
    const { data, error } = useSWR(`${API_BASE_URL}/messages`, fetcher)
    const { mutate } = useSWRConfig()

    return {
        threads: data,
        threadsLoading: !error && !data,
        threadsError: error,
        updateThreads: () => {
            mutate(`${API_BASE_URL}/messages`)
        }
    }
}

export default useThreads