import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import PieChartIcon from '@mui/icons-material/PieChart';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';
import * as transferActions from '../../../store/transfer/transferActions';
import authStyles from '../authStyles';
import Humanize from 'humanize-plus';
import LockedTile from '../../../components/LockedTile';
import { SUBSCRIPTION_PRO } from '../../../constants/profile';

const Overview = (props) => {
    const classes = authStyles();
    const [chartData, setChartData] = useState(null);
    const [loaded, setLoaded] = useState(0);

    useEffect(() => props.transferActions.getSummary(), [
        props.transferActions,
    ]);

    if (props.summary && props.summary.chart && loaded === 0) {
        let categories = [];
        let pending_data = [];
        let approved_data = [];
        let approved = 0;
        let pending = 0;
        props.summary.chart.map((chart, i) => {
            if (!categories.includes(chart.class_category)) {
                categories.push(chart.class_category);
                let categoryArray = props.summary.chart.filter(
                    (item) => item.class_category === chart.class_category
                );

                categoryArray.map((category, i) => {
                    if (category.status === 'approved') {
                        approved_data.push(category.amount);
                        approved = 1;
                    } else if (category.status === 'pending') {
                        pending_data.push(category.amount);
                        pending = 1;
                    }
                    return 0;
                });

                if (!approved) {
                    approved_data.push(0);
                }

                if (!pending) {
                    pending_data.push(0);
                }

                approved = 0;
                pending = 0;
            }

            return 0;
        });

        /*const [chartData] = useState({
            series: [
                { name: 'Buy', data: [5000, 15000, 12000] },
                { name: 'Sell', data: [4300, 12700, 8430] },
            ],
            categories: [
                'AppleCommon_A_Founder',
                'AppleCommon_A_Employee',
                'Apple_Employee_Options',
            ],
        });*/

        setChartData({
            series: [
                { name: 'Approved', data: approved_data },
                { name: 'Pending', data: pending_data },
            ],
            categories: categories,
        });

        /*console.log(approved_data);
        console.log(pending_data);
        console.log(categories);*/
        setLoaded(1);
    }

    return (
        <LockedTile requiredPlan={SUBSCRIPTION_PRO}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} sm={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Grid container>
                            <Grid item xs={12} md={8} sm={12}>
                                <Typography className={classes.title}>
                                    Asset class categories with highest amounts
                                </Typography>

                                {
                                    <Chart
                                        options={{
                                            chart: {
                                                type: 'bar',
                                                height: 350,
                                            },
                                            plotOptions: {
                                                bar: {
                                                    horizontal: false,
                                                    columnWidth: '55%',
                                                    // endingShape: 'rounded'
                                                },
                                            },
                                            colors: [
                                                '#3E9CD9',
                                                /*'#216291'*/ '#4279a1',
                                            ],
                                            dataLabels: {
                                                enabled: false,
                                            },
                                            stroke: {
                                                show: true,
                                                width: 2,
                                                colors: ['transparent'],
                                            },
                                            xaxis: {
                                                categories:
                                                    chartData !== null
                                                        ? chartData.categories
                                                        : [],
                                            },
                                            yaxis: {
                                                title: {
                                                    text: 'Amount',
                                                },
                                            },
                                            fill: {
                                                opacity: 1,
                                            },
                                            tooltip: {
                                                y: {
                                                    formatter: function (val) {
                                                        return Humanize.formatNumber(
                                                            val,
                                                            2
                                                        );
                                                    },
                                                },
                                            },
                                        }}
                                        series={
                                            chartData !== null
                                                ? chartData.series
                                                : []
                                        }
                                        width="100%"
                                        type="bar"
                                        height={350}
                                    />
                                }
                            </Grid>

                            <Grid item xs={12} md={4} sm={12}>
                                <Typography className={classes.title}>
                                    Transfer overview
                                </Typography>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <QueryBuilderIcon
                                                className={classes.touchIcon}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    className={classes.listItem}
                                                >
                                                    Awaiting approval
                                                </Typography>
                                            }
                                            secondary={
                                                <b
                                                    className={
                                                        classes.listItemSecondary
                                                    }
                                                >
                                                    {props.summary &&
                                                    props.summary.count
                                                        ? props.summary.count
                                                              .pending
                                                        : 0}
                                                </b>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <ThumbUpIcon
                                                className={classes.touchIcon}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    className={classes.listItem}
                                                >
                                                    Approved
                                                </Typography>
                                            }
                                            secondary={
                                                <b
                                                    className={
                                                        classes.listItemSecondary
                                                    }
                                                >
                                                    {props.summary &&
                                                    props.summary.count
                                                        ? props.summary.count
                                                              .approved
                                                        : 0}
                                                </b>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <ThumbDownIcon
                                                className={classes.touchIcon}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    className={classes.listItem}
                                                >
                                                    Rejected
                                                </Typography>
                                            }
                                            secondary={
                                                <b
                                                    className={
                                                        classes.listItemSecondary
                                                    }
                                                >
                                                    {props.summary &&
                                                    props.summary.count
                                                        ? props.summary.count
                                                              .rejected
                                                        : 0}
                                                </b>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <PieChartIcon
                                                className={classes.touchIcon}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    className={classes.listItem}
                                                >
                                                    Authorized Assets
                                                </Typography>
                                            }
                                            secondary={
                                                <b
                                                    className={
                                                        classes.listItemSecondary
                                                    }
                                                >
                                                    {props.summary &&
                                                    props.summary.count ? (
                                                        <b>
                                                            {Humanize.formatNumber(
                                                                props.summary
                                                                    .count
                                                                    .authorized_shares
                                                            )}
                                                        </b>
                                                    ) : (
                                                        0
                                                    )}
                                                </b>
                                            }
                                        />
                                    </ListItem>

                                    <ListItem>
                                        <ListItemAvatar>
                                            <PieChartOutlineIcon
                                                className={classes.touchIcon}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    className={classes.listItem}
                                                >
                                                    Outstanding Assets
                                                </Typography>
                                            }
                                            secondary={
                                                <b
                                                    className={
                                                        classes.listItemSecondary
                                                    }
                                                >
                                                    {props.summary &&
                                                    props.summary.count ? (
                                                        <b>
                                                            {Humanize.formatNumber(
                                                                props.summary
                                                                    .count
                                                                    .shares
                                                            )}
                                                        </b>
                                                    ) : (
                                                        0
                                                    )}
                                                </b>
                                            }
                                        />
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Typography className={classes.title}>
                            Active listings on external marketplaces
                        </Typography>

                        <Grid container>
                            <Grid item xs={12} sm={4} md={4}>
                                <a href="https://merj.exchange/" target="blank">
                                    <img
                                        style={{ height: 70 }}
                                        src="https://cdn.icrypto.media/images/67c25b7a_527a_483d_af71_d84efb153a95.jpeg"
                                        alt="MERJ"
                                    />
                                </a>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <a
                                    href="https://www.dealsquare.io/"
                                    target="blank"
                                >
                                    <img
                                        style={{ height: 70 }}
                                        src="https://s3.amazonaws.com/brokerportal-production/2_dsneo_dsneo_20200623201625894.svg"
                                        alt="DealSquare"
                                    />
                                </a>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <a href="https://republic.co/" target="blank">
                                    <img
                                        style={{ height: 60 }}
                                        src="https://assets.republic.co/assets/logo/full/black/150x38/logo-211758c2027fc8268186d512a2119520a35358973648789a123d9fb6c3d5a37f.svg"
                                        alt="Republic"
                                    />
                                </a>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </LockedTile>
    );
};

const mapStateToProps = ({ profile, transfers }) => {
    return {
        profile: profile,
        summary: transfers.summary.data,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        transferActions: bindActionCreators(transferActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
