import React from 'react';
import { Formik } from 'formik';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import authStyles from '../authStyles';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

import InviteModule from '../../user/IssuerInvite/InviteModule';

const InviteTeamMembers = (props) => {
    const classes = authStyles();

    const nextStep = () => {
        props.nextStep();
    };
    return (
        <Formik
            id="step12"
            initialValues={props.initialValues}
            onSubmit={(values, { setSubmitting }) => {}}
        >
            {({ isSubmitting }) => (
                <div>
                    <Typography component="div" style={{ textAlign: 'center' }}>
                        <h2>Invite team members</h2>
                        <Typography component="p" variant="body1">
                            Congratulations on creating your cap table!
                        </Typography>
                        <Typography component="p" variant="body1">
                            Would you like to invite additional issuers / team
                            members to your account?
                        </Typography>
                        <Typography component="p" variant="body1">
                            You can also do this later, from your Dashboard.
                        </Typography>
                    </Typography>
                    <br />
                    <InviteModule
                        issuerEmail={props.initialValues.email}
                        issuerFirstName={props.initialValues.first_name}
                        issuerLastName={props.initialValues.last_name}
                        issuerCompanyName={props.initialValues.company_name}
                        signup={true}
                        successCallback={() => {
                            nextStep();
                        }}
                    />
                    <div
                        className={classes.actions}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            type="submit"
                            variant="outlined"
                            className={classes.secondaryButtonLabel}
                            color="secondary"
                            disabled={isSubmitting}
                            onClick={nextStep}
                        >
                            <DoubleArrowIcon />
                            {isSubmitting ? (
                                <CircularProgress size={30} />
                            ) : (
                                'SKIP'
                            )}
                        </Button>
                    </div>
                </div>
            )}
        </Formik>
    );
};

export default InviteTeamMembers;
