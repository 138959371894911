import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Dashboard from '../pages/investor/Dashboard/Dashboard';
import OfferingSubscriptions from '../pages/investor/OfferingSubscriptions/OfferingSubscriptions';
import ShareholderNotices from '../pages/investor/ShareholderNotices/ShareholderNotices';
import TransactionHistory from '../pages/investor/TransactionHistory/TransactionHistory';
import Events from '../pages/investor/Events/Events';
import Contracts from '../pages/investor/Contracts/Contracts';
import Holdings from '../pages/investor/Holdings/Holdings';
import Profile from '../pages/user/Profile/Profile';

const InvestorRoutes = () => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <Dashboard />
            </Route>
            <Route path={`${path}/offering-subscriptions`}>
                <OfferingSubscriptions />
            </Route>
            <Route path={`${path}/shareholder-notices`}>
                <ShareholderNotices />
            </Route>
            <Route path={`${path}/transaction-history`}>
                <TransactionHistory />
            </Route>
            <Route path={`${path}/events`}>
                <Events />
            </Route>
            <Route path={`${path}/contracts`}>
                <Contracts />
            </Route>
            <Route path={`${path}/holdings`}>
                <Holdings />
            </Route>
            <Route path={`${path}/profile`}>
                <Profile />
            </Route>
        </Switch>
    );
};

export default InvestorRoutes;
