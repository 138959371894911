import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import withStyles from '@mui/styles/withStyles';
import * as config from './../../../config';
import request from '../../../utilities/request';
import {
    isViewMode,
    stringToInt,
    isTALoggedAsIssuer,
    formatNumber,
} from '../../../utilities/utils';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Humanize from 'humanize-plus';
import * as Yup from 'yup';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import DataTable from '../../../components/DataTable/MuiDataTable';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HelpTooltip from '../../../components/HelpTooltip/HelpTooltip';
import { CheckboxWithLabel } from 'formik-mui';
import LockedTile from '../../../components/LockedTile';
import Divider from '@mui/material/Divider';
import { DateFormatter } from '../../../utilities/formatters';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import FormikAutocomplete from '../../../components/FormikAutocomplete';
import EditIcon from '@mui/icons-material/Edit';
import { Formik, Form, Field } from 'formik';
import Container from '@mui/material/Container';
import TextField from '../../../components/TextField/TextField';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// import MuiPhoneNumber from 'material-ui-phone-number';
import FormHelperText from '@mui/material/FormHelperText';
import _ from 'lodash';
import Tooltip from '@mui/material/Tooltip';
import ShareholderDialog from '../../../components/ShareholderDialog';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as capTableActions from '../../../store/capTable/capTableActions';
import * as categoryActions from '../../../store/category/categoryActions';
import * as shareholderActions from '../../../store/shareholder/shareholderActions';
import * as profileActions from '../../../store/profile/profileActions';
import * as registry from '../../../utilities/registry';
import { VESTING_PRO_RATAS } from '../../../constants/shareholders';
import FormikSelect from '../../../components/Formik/FormikSelect';
import authStyles from '../authStyles';
import { useHistory } from 'react-router-dom';
let shareholderInitialValues = {
    class: 'Common',
    class_description: '',
    class_amount: '',
    class_price: '',
    category_description: '',
    category_class_name: '',
    category_amount: '',
    category_price: '',
    excluding_option: false,
    item_title: 'class',
    entity_type: 'individual',
    first_name: '',
    last_name: '',
    company_name: '',
    country: 'US',
    ofac_id: null,
    email: '',
    shares: '',
};

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.common.white,
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
    },
    checked: {},
}))(Switch);


const EditInvestorInfo = (props) => {
    const [isShareholder, setIsShareholder] = useState(true);
    const [category, setCategory] = useState(null);
    const classes = authStyles();
    const [editShareholderData, setEditShareholderData] = useState([]);
    const [deleteShareholderData, setDeleteShareholderData] = useState([]);
    const [addDialog, setAddDialog] = useState(false);
    const [existingShareholderDialog, setExistingShareholderDialog] = useState(
        false
    );
    const [excludingOption, setExcludingOption] = useState(false);
    const [entityType, setEntityType] = useState('individual');
    const [
        openShareholdersEditDialog,
        setOpenShareholdersEditDialog,
    ] = useState(false);
    const [
        openShareholdersDeleteDialog,
        setOpenShareholdersDeleteDialog,
    ] = useState(false);
    const [classItems, setClassItems] = useState([]);
    const [investorClass, setInvestorClass] = useState(0);
    const [categoryItems, setCategoryItems] = useState([]);
    const [investorCategory, setInvestorCategory] = useState(0);
    const [phoneFlag, setPhoneFlag] = useState('us');

    const [infoDialogIsOpen, setInfoDialogIsOpen] = useState(false);
    const [shareCategory, setShareCategory] = useState(null);
    const [existingShareholder, setExistingShareholder] = useState(null);

    const [openShareholdersKycDialog, setOpenShareholdersKycDialog] = useState(
        false
    );
    const [kycShareholderData, setKycShareholderData] = useState([]);
    const [isDisabledSend, setIsDisabledSend] = useState(false);

    const [vestingDurationYears, setVestingDurationYears] = useState(null);
    const [vestingCliffYears, setVestingCliffYears] = useState(null);
    const [vestingProRata, setVestingProRata] = useState(null);

    const [duration, setDuration] = useState(null);
    const [cliff, setCliff] = useState(null);
    const [proRata, setProRata] = useState(null);
    const [citizenship, setCitizenship] = useState(
        registry.countries && registry.countries.length > 0
            ? registry.countries.filter((element) => {
                return element.id === 'US';
            })[0].id
            : null
    );

    const [fullyDilutedShares, setFullyDilutedShares] = useState(null);
    const [vestingShares, setVestingShares] = useState(null);
    const [amountsError, setAmountsError] = useState(false);
    const [emailError, setEmailError] = useState(false);

    const [isSAFE, setIsSAFE] = useState(false);

    const history = useHistory();

    const individualShareholderValidationSchema = Yup.object({
        first_name: Yup.string().when('entity_type', {
            is: 'individual',
            then: Yup.string().required(
                "Please enter the holder's first name."
            ),
        }),
        last_name: Yup.string().when('entity_type', {
            is: 'individual',
            then: Yup.string().required("Please enter the holder's last name."),
        }),
        country: Yup.string().when('entity_type', {
            is: 'individual',
            then: Yup.string().required(
                "Please enter the holder's citizenship."
            ),
        }),
        shares: Yup.string().required('Please enter the diluted assets'),
        email: Yup.string().required("Please enter the holder's email."),
    });

    const companyShareholderValidationSchema = Yup.object({
        company_name: Yup.string().when('entity_type', {
            is: 'company',
            then: Yup.string().required('Please enter the company name.'),
        }),
        shares: Yup.string().required('Please enter the diluted assets'),
        email: Yup.string().required("Please enter the holder's email."),
    });

    const openInfoDialog = (id) => {
        setInfoDialogIsOpen(true);
        props.capTableActions.getEntry(id);
    };

    const changeExcludingOption = (e) => {
        setExcludingOption(e.target.checked);
    };

    const changeEntityType = (e) => {
        if (e.target.value) {
            setEntityType(e.target.value);
        }
    };

    const parseNumber = (num) => {
        if (num !== null) {
            return Number(num.replace(/,/g, ''));
        }
        return 0;
    };

    useEffect(() => {
        async function fetchData() {
            const response = await request.get(
                `${config.API_BASE_URL}/cap-table`
            );

            for (var i = 0; i < response.data.length; i++) {
                classItems.unshift({
                    id: response.data[i].class_id,
                    class: response.data[i].class,
                    class_amount: response.data[i].class_amount,
                    class_description: response.data[i].class_description,
                    categories: response.data[i].categories,
                });

                setClassItems(classItems);
            }
        }

        fetchData();
    }, [classItems]);
    const { enqueueSnackbar } = useSnackbar();
    useEffect(props.capTableActions.getClassCategories, []);
    useEffect(props.shareholderActions.getShareholdersList, []);
    useEffect(() => {
        if (isShareholder) {
            props.capTableActions.getShareholders();
        }
    }, [props.capTableActions, isShareholder]);

    useEffect(() => {
        if (
            props.categories.data &&
            props.categories.data.length &&
            !category
        ) {
            setCategory(props.categories.data[0].category_id);
        }

        if (props.categories.data && props.categories.data.length > 0) {
            if (props.categories.data[0].class === 'safe') {
                setIsSAFE(true);
            }
        }

        if (props.categories && !isShareholder && category !== null) {
            props.capTableActions.getShareholdersByCategory(category);
        }
    }, [
        props.capTableActions,
        props.categories,
        isShareholder,
        category,
        isSAFE,
    ]);

    const handleChange = () => {
        setIsShareholder(!isShareholder);
    };
    const displayAddDialog = () => {
        setAddDialog(true);
        setShareCategory(
            props.categories.data && props.categories.data.length > 0
                ? props.categories.data[0].category_id
                : null
        );
    };

    const displayExistingShareholderDialog = () => {
        props.shareholderActions.getShareholdersList();
        if (props.shareholdersList.data.length > 0) {
            setExistingShareholder(props.shareholdersList.data[0].investor_id);
        }

        setExistingShareholderDialog(true);
        setShareCategory(
            props.categories.data && props.categories.data.length > 0
                ? props.categories.data[0].category_description
                : null
        );
    };
    const changePhoneFlag = (e) => {
        if (e.target.getAttribute('data-value') !== null) {
            setPhoneFlag(e.target.getAttribute('data-value').toLowerCase());
        }
    };
    const displayEditShareholderDialog = (data) => {
        if (!data['shares'].includes(',')) {
            data['shares'] = Humanize.formatNumber(data['shares']);
        }
        if (data['vesting_shares'] && !data['vesting_shares'].includes(',')) {
            data['vesting_shares'] = Humanize.formatNumber(
                data['vesting_shares']
            );
        }

        data['entity_type'] =
            data.first_name && data.last_name ? 'individual' : 'company';

        if (data.state !== null) {
            setPhoneFlag(data.state.toLowerCase());
        }
        let class_value = 0;
        let category_value = 0;

        let class_categories = [];

        classItems.map((c) => {
            if (c.id === data.share_class_id) {
                class_value = c.id;
                class_categories = c.categories;
            }
            return c.id === data.share_class_id;
        });

        class_categories.map((c) => {
            if (c.category_id === data.share_class_category_id) {
                category_value = c.category_id;
            }
            return c.category_id === data.share_class_category_id;
        });

        setCategoryItems(class_categories);
        setEntityType(data.entity_type);
        setInvestorClass(class_value);
        setInvestorCategory(category_value);
        setEditShareholderData(data);
        setOpenShareholdersEditDialog(true);
    };

    const loadClassCategories = (e) => {
        let class_id = e.target.getAttribute('data-value');

        let class_categories = [];
        let category_value = 0;

        classItems.map((c) => {
            if (c.id === parseInt(class_id)) {
                class_categories = c.categories;
                if (c.categories[0]) {
                    category_value = c.categories[0].category_id;
                }
            }
            return c.id === parseInt(class_id);
        });
        setInvestorCategory(category_value);
        setCategoryItems(class_categories);
    };

    const sendKycInvite = () => {
        setIsDisabledSend(true);
        request
            .post(`${config.API_BASE_URL}/kyc/invite`, {
                investor_id: kycShareholderData.investor_id,
            })
            .then((response) => {
                enqueueSnackbar('KYC invite sent successfully', {
                    variant: 'success',
                });
                closeKycDialog();
            })
            .catch((error) => {
                enqueueSnackbar('KYC invite was not sent.', {
                    variant: 'error',
                });
            })
            .finally(() => {
                setIsDisabledSend(false);
            });
    };

    const closeEditDialog = () => {
        setOpenShareholdersEditDialog(false);
        setOpenShareholdersDeleteDialog(false);
        setFullyDilutedShares(null);
        setVestingShares(null);
        setAmountsError(false);
    };

    const closeAddDialog = () => {
        setAddDialog(false);
        setFullyDilutedShares(null);
        setVestingShares(null);
        setAmountsError(false);
        setEmailError(false);
    };

    const closeExistingShareholderDialog = () => {
        setExistingShareholderDialog(false);
        setFullyDilutedShares(null);
        setVestingShares(null);

        setAmountsError(false);
    };

    const displayDeleteShareholderDialog = (data) => {
        setDeleteShareholderData(data);
        setOpenShareholdersDeleteDialog(true);
    };

    const displayKycShareholderDialog = (data) => {
        setKycShareholderData(data);
        setOpenShareholdersKycDialog(true);
    };

    const closeKycDialog = () => {
        setKycShareholderData(null);
        setOpenShareholdersKycDialog(false);
    };

    const buildDropdown = (number) => {
        let items = [];
        for (var i = 1; i <= number; i++) {
            items.push(
                <MenuItem key={i} value={i}>
                    {i}
                </MenuItem>
            );
        }

        return items;
    };

    const buildValuesDropdown = (values) => {
        let items = [];
        values.forEach((value) => {
            items.push(
                <MenuItem key={value} value={value}>
                    {value.charAt(0).toUpperCase() + value.slice(1)}
                </MenuItem>
            );
        });

        return items;
    };

    const handleExistingShareholderChange = (e) => {
        setExistingShareholder(e.target.value);
    };

    const handleFullyDilutedSharesChange = (e) => {
        setFullyDilutedShares(e.target.value);
        if (stringToInt(e.target.value) < stringToInt(vestingShares)) {
            setAmountsError(true);
        } else {
            setAmountsError(false);
        }
    };

    const handleVestingSharesChange = (e) => {
        setVestingShares(e.target.value);
        if (stringToInt(e.target.value) > stringToInt(fullyDilutedShares)) {
            setAmountsError(true);
        } else {
            setAmountsError(false);
        }
    };

    const newHolderSuccessCallback = () => {
        setFullyDilutedShares(null);
        setVestingShares(null);
        setAmountsError(false);
        if (props.categories.data && !isShareholder && category !== null) {
            props.capTableActions.getShareholdersByCategory(category);
        } else {
            props.capTableActions.getShareholders();
        }
        setProRata(null);
        setDuration(null);
        setCliff(null);
        setAddDialog(false);
        setEntityType('individual');
        setEmailError(false);
    };

    const handleEmailChange = (e) => {
        request
            .post(`${config.API_BASE_URL}/shareholder/email`, {
                email: e.target.value,
            })
            .then((response) => {
                if (response.data && response.data.id) {
                    setEmailError(true);
                    setExistingShareholder(response.data.investor_id);
                } else {
                    setEmailError(false);
                }
            })
            .catch((error) => { });
    };

    const useExistingHolder = () => {
        props.shareholderActions.getShareholdersList();
        closeAddDialog();
        setExistingShareholderDialog(true);
        setShareCategory(
            props.categories.data && props.categories.data.length > 0
                ? props.categories.data[0].category_description
                : null
        );
    };

    const handleShareCategoryChange = (id, data) => {
        setShareCategory(id);
        if (data && data.class && data.class === 'safe') {
            setIsSAFE(true);
            let shares = (data.amount_invested / data.valuation) * 100;
            let formatted_shares = shares
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

            setFullyDilutedShares(formatted_shares);
        } else {
            setIsSAFE(false);
            setFullyDilutedShares(null);
        }
    };

    return (
        <div>
            <ShareholderDialog
                dialogIsOpen={infoDialogIsOpen}
                setDialogIsOpen={setInfoDialogIsOpen}
            />{' '}
            <Grid container>
                <Grid item xs={12} sm={3} md={3}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} className={classes.viewByDiv}>
                            <Typography component="p" variant="overline">
                                View by
                            </Typography>
                            <Grid component="label" container className="flex-left"   style={{
                                    marginBottom: '2rem',
                                    marginTop: '1rem'
                                }}
                            >
                                <span>Shareholder &nbsp;</span>
                                <AntSwitch
                                    checked={!isShareholder}
                                    onChange={handleChange}
                                    disabled={
                                        !props.categories.data ||
                                        props.categories.data.length === 0
                                    }
                                    name="isShareholder"
                                />
                                &nbsp;
                                <span>Category</span>
                            </Grid> 
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className={classes.leftFloat}>
                           <Button
                                variant="text"
                                onClick={() => displayAddDialog()}
                                className={classes.buttonLabel}
                            >
                                Create New Holder
                            </Button>
                            <Button
                                variant="text"
                                onClick={() =>
                                    displayExistingShareholderDialog()
                                }
                                className={classes.buttonLabel}
                            >
                                Use Existing Holder
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>{' '}
                <Divider
                    orientation="vertical"
                    flexItem
                    fullWidth
                    style={{ marginRight: '2rem', width: '2rem' }}
                ></Divider>
                <Grid item xs={12} sm={8} md={8}>
                    {props.shareholders.loading && props.categories.loading ? (
                        <Grid container item xs={12} justifyContent="center">
                            <CircularProgress />
                        </Grid>
                    ) : !isShareholder &&
                        props.categories.data &&
                        props.categories.data.length > 0 ? (
                        <React.Fragment>
                            <Grid container>
                                <Grid item xs={12} style={{ marginBottom: 20 }}>
                                    <FormControl
                                        variant="outlined"
                                        className={classes.formControl}
                                    >
                                        <InputLabel id="demo-simple-select-outlined-label">
                                            Category
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={category}
                                            onChange={(e) =>
                                                setCategory(e.target.value)
                                            }
                                            label="Category"
                                        >
                                            {props.categories.data.map(
                                                (category) => (
                                                    <MenuItem
                                                        key={
                                                            category.category_id
                                                        }
                                                        value={
                                                            category.category_id
                                                        }
                                                    >
                                                        {
                                                            category.category_description
                                                        }
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <DataTable
                                    title={
                                        category &&
                                        'Holders in ' +
                                        _.find(props.categories.data, {
                                            category_id: category,
                                        }).category_description
                                    }
                                    data={
                                        props.shareholders.data
                                            ? props.shareholders.data
                                            : []
                                    }
                                    columns={[
                                        {
                                            label: 'Name',
                                            name: 'investor',
                                            options: {
                                                customBodyRenderLite: (
                                                    dataIndex
                                                ) => {
                                                    const row =
                                                        props.shareholders.data[
                                                        dataIndex
                                                        ];
                                                    return (
                                                        <span
                                                            className="hoverable"
                                                            onClick={() =>
                                                                openInfoDialog(
                                                                    row.ct_id
                                                                )
                                                            }
                                                        >
                                                            {row
                                                                ? row.investor
                                                                : ''}
                                                        </span>
                                                    );
                                                },
                                            },
                                        },
                                        { label: 'Class', name: 'share_class' },
                                        {
                                            label: 'Category',
                                            name: 'share_category',
                                        },
                                        {
                                            label: 'Assets',
                                            name: 'shares',
                                            options: {
                                                customBodyRender: (value) => (
                                                    <span>
                                                        {formatNumber(value)}
                                                    </span>
                                                ),
                                            },
                                        },
                                        {
                                            label: 'Assets %',
                                            name: 'shares_p',
                                            options: {
                                                customBodyRender: (value) => (
                                                    <span>
                                                        {Humanize.formatNumber(
                                                            value,
                                                            2
                                                        )}
                                                    </span>
                                                ),
                                            },
                                        },
                                        {
                                            label: 'OFAC ID',
                                            name: 'ofac_id',
                                        },
                                        {
                                            label: 'Wallet Address',
                                            name: 'wallet',
                                            options: {
                                                display: 'excluded',
                                                download: false,
                                                print: false,
                                            },
                                        },

                                        {
                                            label: 'Actions',
                                            name: 'edit',
                                            options: {
                                                download: false,
                                                print: false,
                                                customBodyRender: (value) => {
                                                    return (
                                                        <React.Fragment>
                                                            <Tooltip title="Edit">
                                                                <IconButton
                                                                    variant="contained"
                                                                    color="primary"
                                                                    size="small"
                                                                    className="m-r-5"
                                                                    onClick={() => {
                                                                        displayEditShareholderDialog(
                                                                            value
                                                                        );
                                                                    }}
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete">
                                                                <IconButton
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    size="small"
                                                                    className="m-r-5"
                                                                    onClick={() => {
                                                                        displayDeleteShareholderDialog(
                                                                            value
                                                                        );
                                                                    }}
                                                                >
                                                                    <DeleteForeverIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            {value.notes && (
                                                                <React.Fragment>
                                                                    <Tooltip
                                                                        title={
                                                                            value.notes
                                                                        }
                                                                    >
                                                                        <IconButton
                                                                            variant="contained"
                                                                            size="small"
                                                                            aria-label="view notes"
                                                                        >
                                                                            <CommentIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </React.Fragment>
                                                            )}

                                                            {value.kyc_status ===
                                                                'Approved' ? (
                                                                // Approved KYC
                                                                <Tooltip title="Approved KYC (Required PRO plan),">
                                                                    <span>
                                                                        <IconButton
                                                                            variant="contained"
                                                                            sx={{
                                                                                color:
                                                                                    'green',
                                                                            }}
                                                                            size="small"
                                                                            className="m-r-5"
                                                                            disabled
                                                                        >
                                                                            <PersonAddIcon />
                                                                        </IconButton>{' '}
                                                                    </span>
                                                                </Tooltip>
                                                            ) : (
                                                                <Tooltip title="Send KYC invite (Required PRO plan)">
                                                                    <span>
                                                                        <IconButton
                                                                            variant="contained"
                                                                            sx={{
                                                                                color:
                                                                                    'green',
                                                                            }}
                                                                            size="small"
                                                                            className="m-r-5"
                                                                            onClick={() => {
                                                                                displayKycShareholderDialog(
                                                                                    value
                                                                                );
                                                                            }}
                                                                            disabled
                                                                        >
                                                                            <PersonAddIcon />
                                                                        </IconButton>
                                                                    </span>
                                                                </Tooltip>
                                                            )}
                                                        </React.Fragment>
                                                    );
                                                },
                                            },
                                        },
                                    ]}
                                    shareSort={3}
                                />
                            </Grid>
                            <Grid item xs={12} className="m-t-10">
                                <small>
                                    * Click on a holder name to see more
                                    information.
                                </small>
                            </Grid>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Grid container item xs={12}>
                                {!isShareholder ? (
                                    <DataTable
                                        title={'Holders'}
                                        data={
                                            props.shareholders.data
                                                ? props.shareholders.data
                                                : []
                                        }
                                        columns={[
                                            { label: 'Name', name: 'investor' },
                                            {
                                                label: 'Class',
                                                name: 'share_class',
                                            },
                                            {
                                                label: 'Category',
                                                name: 'share_category',
                                            },
                                            {
                                                label: 'Assets',
                                                name: 'shares',
                                                options: {
                                                    customBodyRender: (
                                                        value
                                                    ) => (
                                                        <span>
                                                            {formatNumber(
                                                                value
                                                            )}
                                                        </span>
                                                    ),
                                                },
                                            },
                                            {
                                                label: 'Assets %',
                                                name: 'shares_p',
                                                options: {
                                                    customBodyRender: (
                                                        value
                                                    ) => (
                                                        <span>
                                                            {Humanize.formatNumber(
                                                                value,
                                                                2
                                                            )}
                                                        </span>
                                                    ),
                                                },
                                            },
                                            {
                                                label: 'Wallet Address',
                                                name: 'wallet',
                                                options: {
                                                    display: 'excluded',
                                                    download: false,
                                                    print: false,
                                                },
                                            },
                                            {
                                                label: 'Actions',
                                                name: 'edit',
                                                options: {
                                                    download: false,
                                                    print: false,
                                                    customBodyRender: (
                                                        value
                                                    ) => {
                                                        return (
                                                            <Button
                                                                color="primary"
                                                                variant="outlined"
                                                                className={
                                                                    classes.bttonLabel
                                                                }
                                                                size="small"
                                                                onClick={() => {
                                                                    displayEditShareholderDialog(
                                                                        value
                                                                    );
                                                                }}
                                                            >
                                                                <EditIcon />
                                                                Save
                                                            </Button>
                                                        );
                                                    },
                                                },
                                            },
                                        ]}
                                        shareSort={3}
                                    />
                                ) : (
                                    <DataTable
                                        title={'Holders'}
                                        data={
                                            props.shareholders.data
                                                ? props.shareholders.data
                                                : []
                                        }
                                        columns={[
                                            {
                                                label: 'Name',
                                                name: 'investor',
                                                options: {
                                                    customBodyRenderLite: (
                                                        dataIndex
                                                    ) => {
                                                        const row =
                                                            props.shareholders
                                                                .data[
                                                            dataIndex
                                                            ];
                                                        return (
                                                            <span
                                                                className="hoverable"
                                                                onClick={() =>
                                                                    openInfoDialog(
                                                                        row.ct_id
                                                                    )
                                                                }
                                                            >
                                                                {row &&
                                                                    row.investor
                                                                    ? row.investor
                                                                    : 'N/A'}
                                                            </span>
                                                        );
                                                    },
                                                },
                                            },
                                            {
                                                label: 'Class',
                                                name: 'share_class',
                                            },
                                            {
                                                label: 'Category',
                                                name: 'share_category',
                                            },
                                            {
                                                label: 'Assets',
                                                name: 'shares',
                                                options: {
                                                    customBodyRender: (
                                                        value
                                                    ) => (
                                                        <span>
                                                            {formatNumber(
                                                                value
                                                            )}
                                                        </span>
                                                    ),
                                                },
                                            },
                                            {
                                                label: 'Assets %',
                                                name: 'shares_p',
                                                options: {
                                                    customBodyRender: (
                                                        value
                                                    ) => (
                                                        <span>
                                                            {Humanize.formatNumber(
                                                                value,
                                                                2
                                                            )}
                                                        </span>
                                                    ),
                                                },
                                            },
                                            {
                                                label: 'Wallet Address',
                                                name: 'wallet',
                                                options: {
                                                    display: 'excluded',
                                                    download: false,
                                                    print: false,
                                                },
                                            },
                                            {
                                                label: 'Actions',
                                                name: 'edit',
                                                options: {
                                                    download: false,
                                                    print: false,
                                                    customBodyRender: (
                                                        value
                                                    ) => {
                                                        return (
                                                            <React.Fragment>
                                                                <Tooltip title="Edit">
                                                                    <IconButton
                                                                        variant="contained"
                                                                        color="primary"
                                                                        size="small"
                                                                        onClick={() => {
                                                                            displayEditShareholderDialog(
                                                                                value
                                                                            );
                                                                        }}
                                                                    >
                                                                        <EditIcon
                                                                            className="m-r-5"
                                                                            fontSize="small"
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Delete">
                                                                    <IconButton
                                                                        variant="contained"
                                                                        color="secondary"
                                                                        size="small"
                                                                        className="m-r-5"
                                                                        onClick={() => {
                                                                            displayDeleteShareholderDialog(
                                                                                value
                                                                            );
                                                                        }}
                                                                    >
                                                                        <DeleteForeverIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                {value &&
                                                                    value.notes && (
                                                                        <React.Fragment>
                                                                            <Tooltip
                                                                                title={
                                                                                    value.notes
                                                                                }
                                                                            >
                                                                                <IconButton
                                                                                    variant="contained"
                                                                                    size="small"
                                                                                    aria-label="view notes"
                                                                                >
                                                                                    <CommentIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </React.Fragment>
                                                                    )}
                                                            </React.Fragment>
                                                        );
                                                    },
                                                },
                                            },
                                        ]}
                                        shareSort={3}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} className="m-t-10">
                                <small>
                                    * Click on a holder name to see more
                                    information.
                                </small>
                            </Grid>
                        </React.Fragment>
                    )}
                    <br />
                </Grid>
            </Grid>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={addDialog}
                onBackdropClick={(e) => setAddDialog(false)}
            >
                <DialogTitle className={classes.dialogTitle}>
                    New Holder
                    <br />
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <Formik
                                initialValues={shareholderInitialValues}
                                validationSchema={
                                    entityType === 'individual'
                                        ? individualShareholderValidationSchema
                                        : companyShareholderValidationSchema
                                }
                                onSubmit={(values, { setSubmitting }) => {
                                    if (shareCategory === null) {
                                        enqueueSnackbar(
                                            'Please select category',
                                            {
                                                variant: 'error',
                                            }
                                        );
                                        setSubmitting(false);
                                    } else if (amountsError) {
                                        enqueueSnackbar(
                                            'Amount of Assets subject to Vesting should not exceed Fully diluted assets',
                                            {
                                                variant: 'error',
                                            }
                                        );
                                        setSubmitting(false);
                                    } else if (emailError) {
                                        enqueueSnackbar(
                                            'A holder with this email address already exists.',
                                            {
                                                variant: 'error',
                                            }
                                        );
                                        setSubmitting(false);
                                    } else {
                                        props.capTableActions.addInvestor(
                                            {
                                                entity_type: entityType,
                                                shareholder_first_name:
                                                    values['first_name'],
                                                shareholder_last_name:
                                                    values['last_name'],
                                                company_legal_name:
                                                    values['company_name'],
                                                shareholder_citizenship: citizenship,
                                                shareholder_company:
                                                    values['investor_company'],
                                                shareholder_position:
                                                    values['investor_position'],
                                                category: shareCategory,
                                                diluted_shares: fullyDilutedShares.replace(
                                                    /,/g,
                                                    ''
                                                ),
                                                options_exercise_price:
                                                    values[
                                                    'options_exercise_price'
                                                    ],
                                                duration: duration,
                                                vesting_shares: values[
                                                    'vesting_shares'
                                                ]
                                                    ? values[
                                                        'vesting_shares'
                                                    ].replace(/,/g, '')
                                                    : values['vesting_shares'],
                                                start_date:
                                                    values['start_date'],
                                                cliff: cliff,
                                                cliff_vest:
                                                    values['cliff_vest'],
                                                pro_rata: proRata,
                                                notes: values['notes'],
                                                email: values['email'],
                                                ofac_id: values['ofac_id']
                                                    ? values['ofac_id']
                                                    : null,
                                                acquired_date: values[
                                                    'acquired_date'
                                                ]
                                                    ? values['acquired_date']
                                                    : DateFormatter({
                                                        value: new Date(),
                                                        format: 'date_raw',
                                                    }),
                                                acquired_price: values[
                                                    'acquired_price'
                                                ]
                                                    ? values['acquired_price']
                                                    : null,
                                            },
                                            setSubmitting,
                                            newHolderSuccessCallback
                                        );
                                    }
                                }}
                            >
                                {({ isSubmitting }) => (
                                    <Form className={classes.form} noValidate>
                                        <Grid>
                                            {props.categories.data &&
                                                props.categories.data.length > 0 ? (
                                                <Grid>
                                                    <Field
                                                        component={FormikSelect}
                                                        name="category"
                                                        variant="outlined"
                                                        fullWidth
                                                        label="Select category"
                                                        value={
                                                            shareCategory !==
                                                                null
                                                                ? shareCategory
                                                                : props
                                                                    .categories
                                                                    .data[0]
                                                                    .category_id
                                                        }
                                                    >
                                                        {props.categories.data.map(
                                                            (row) => (
                                                                <Field
                                                                    component={
                                                                        MenuItem
                                                                    }
                                                                    key={
                                                                        row.category_description
                                                                    }
                                                                    value={
                                                                        row.category_id
                                                                    }
                                                                    onClick={() =>
                                                                        handleShareCategoryChange(
                                                                            row.category_id,
                                                                            row
                                                                        )
                                                                    }
                                                                    category={
                                                                        row
                                                                    }
                                                                    setShareCategory={
                                                                        setShareCategory
                                                                    }
                                                                    setIsSAFE={
                                                                        setIsSAFE
                                                                    }
                                                                    setFullyDilutedShares={
                                                                        setFullyDilutedShares
                                                                    }
                                                                >
                                                                    {
                                                                        row.category_description
                                                                    }
                                                                </Field>
                                                            )
                                                        )}
                                                    </Field>
                                                </Grid>
                                            ) : (
                                                <Grid
                                                    className={classes.redText}
                                                >
                                                    <h3>
                                                        You do not have any
                                                        categories created.
                                                        <span
                                                            onClick={() =>
                                                                history.push(
                                                                    '/issuer/issue/cap-table/class-info'
                                                                )
                                                            }
                                                            style={{
                                                                color:
                                                                    '#3E9CD9',
                                                                cursor:
                                                                    'pointer',
                                                            }}
                                                        >
                                                            {' '}
                                                            Create them here.
                                                        </span>
                                                    </h3>
                                                </Grid>
                                            )}
                                            <br />
                                            <Field
                                                component={FormikSelect}
                                                name="entity_type"
                                                variant="outlined"
                                                label="Type"
                                                fullWidth
                                                margin="outlined"
                                                onChange={changeEntityType}
                                                defaultValue={'individual'}
                                            >
                                                {buildValuesDropdown([
                                                    'individual',
                                                    'company',
                                                ])}
                                            </Field>
                                            <br />
                                            {entityType === 'company' ? (
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    label="Company Legal Name"
                                                    name="company_name"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            ) : (
                                                <Grid>
                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        label="First Name"
                                                        name="first_name"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        required
                                                    />
                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        label="Last Name"
                                                        name="last_name"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        required
                                                    />
                                                </Grid>
                                            )}
                                            <Field
                                                component={TextField}
                                                type="text"
                                                name="email"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                onKeyUp={(e) =>
                                                    handleEmailChange(e)
                                                }
                                                label={
                                                    <div>
                                                        Email
                                                        <HelpTooltip title="Pro and Enterprise will require you to provide an email address for each holder, for KYC/communication purposes" />
                                                    </div>
                                                }
                                                InputLabelProps={{
                                                    style: {
                                                        pointerEvents: 'auto',
                                                    },
                                                }}
                                            />
                                            {emailError && (
                                                <span
                                                    className={classes.redText}
                                                >
                                                    A holder with this email
                                                    address already exists. Do
                                                    you want to use the{' '}
                                                    <span
                                                        onClick={
                                                            useExistingHolder
                                                        }
                                                        style={{
                                                            color: '#3E9CD9',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        Existing Holder
                                                    </span>{' '}
                                                    option instead?
                                                </span>
                                            )}
                                            {entityType !== 'company' && (
                                                <Grid>
                                                    <Field
                                                        component={FormikSelect}
                                                        name="country"
                                                        variant="outlined"
                                                        label="Citizenship"
                                                        fullWidth
                                                        inputProps={{
                                                            id: 'country',
                                                        }}
                                                        required
                                                        onChange={(e) =>
                                                            setCitizenship(
                                                                e.target.value
                                                            )
                                                        }
                                                        value={citizenship}
                                                    >
                                                        {registry.countries.map(
                                                            (c) => (
                                                                <MenuItem
                                                                    key={c.name}
                                                                    value={c.id}
                                                                >
                                                                    {c.name}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Field>
                                                    <br />
                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        label="Holder company"
                                                        name="investor_company"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                    />
                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        label="Position"
                                                        name="investor_position"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            )}

                                            {props.profile &&
                                                isTALoggedAsIssuer(
                                                    props.profile
                                                ) && (
                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        name="ofac_id"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        label={
                                                            <div>
                                                                OFAC compliance
                                                                ID
                                                                <HelpTooltip title="either SSN, EIN/TIN, or passport number if not in US" />
                                                            </div>
                                                        }
                                                        InputLabelProps={{
                                                            style: {
                                                                pointerEvents:
                                                                    'auto',
                                                            },
                                                        }}
                                                    />
                                                )}
                                            <Field
                                                component={TextField}
                                                type="text"
                                                name="shares"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                numeric={1}
                                                label={
                                                    <div>
                                                        Fully diluted assets
                                                        <HelpTooltip title="Fully diluted refers to all the assets of a company that have been issued, plus all assets which are the subject of options, warrants, or other contractual rights to be issued in the future." />
                                                    </div>
                                                }
                                                InputLabelProps={{
                                                    style: {
                                                        pointerEvents: 'auto',
                                                    },
                                                }}
                                                onKeyUp={(e) =>
                                                    handleFullyDilutedSharesChange(
                                                        e
                                                    )
                                                }
                                                disabled={isSAFE}
                                            />
                                            {amountsError && (
                                                <span
                                                    className={
                                                        classes.errorMessage
                                                    }
                                                >
                                                    *Amount of Assets subject to
                                                    Vesting should not exceed
                                                    Fully diluted assets
                                                </span>
                                            )}
                                            <Field
                                                component={TextField}
                                                type="number"
                                                label="Options exercise price"
                                                name="options_exercise_price"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                            />
                                            <Field
                                                component={TextField}
                                                type="date"
                                                label={'Acquired Date'}
                                                name="acquired_date"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                defaultValue={DateFormatter({
                                                    value: new Date(),
                                                    format: 'date_raw',
                                                })}
                                            />
                                            <Field
                                                component={TextField}
                                                name="acquired_price"
                                                type="text"
                                                numeric={1}
                                                label="Acquired Price Per Asset"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                            />
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Notes"
                                                name="notes"
                                                variant="outlined"
                                                margin="normal"
                                                multiline
                                                rows={4}
                                                helperText="Add some additional information about this investor."
                                                fullWidth
                                            />
                                            <Field
                                                component={CheckboxWithLabel}
                                                type="checkbox"
                                                name="excluding_option"
                                                onClick={changeExcludingOption}
                                                Label={{
                                                    label: (
                                                        <div
                                                            className={
                                                                classes.checkbox
                                                            }
                                                        >
                                                            Vesting{' '}
                                                            <HelpTooltip
                                                                title={
                                                                    'Vesting of assets restricts a investor to receive the full asset ownership over a set period of time.'
                                                                }
                                                            />
                                                        </div>
                                                    ),
                                                }}
                                                checked={excludingOption}
                                                required
                                            />
                                            {excludingOption === true ? (
                                                <Grid>
                                                    <InputLabel
                                                        className={
                                                            classes.inputLabel
                                                        }
                                                        htmlFor="vesting_shares"
                                                    >
                                                        Number of Assets subject
                                                        to Vesting{' '}
                                                        <HelpTooltip title="Enter 0 if all assets are fully vested" />
                                                    </InputLabel>{' '}
                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        name="vesting_shares"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        placeholder={'500,000'}
                                                        numeric={1}
                                                        onKeyUp={(e) =>
                                                            handleVestingSharesChange(
                                                                e
                                                            )
                                                        }
                                                    />
                                                    {amountsError && (
                                                        <span
                                                            className={
                                                                classes.errorMessage
                                                            }
                                                        >
                                                            *Amount of Assets
                                                            subject to Vesting
                                                            should not exceed
                                                            Fully diluted assets
                                                        </span>
                                                    )}
                                                    <br />
                                                    <Field
                                                        component={TextField}
                                                        type="date"
                                                        label={'Start Date'}
                                                        name="start_date"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                    />
                                                    <br />
                                                    <InputLabel
                                                        className={
                                                            classes.inputLabel
                                                        }
                                                        htmlFor="duration"
                                                    >
                                                        Duration (years)
                                                        <HelpTooltip title="How long is the vesting period for these assets? This is typically around 3-4 years but varies per company." />
                                                    </InputLabel>
                                                    <Field
                                                        component={FormikSelect}
                                                        name="duration"
                                                        variant="outlined"
                                                        fullWidth
                                                        label="Duration (years)"
                                                        onChange={(e) =>
                                                            setDuration(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        {buildDropdown(7)}
                                                    </Field>
                                                    <br />
                                                    <InputLabel
                                                        className={
                                                            classes.inputLabel
                                                        }
                                                        htmlFor="cliff"
                                                    >
                                                        Cliff (years)
                                                        <HelpTooltip title="A cliff is a period in which the option holder cannot access any assets. Typically a one year cliff is put in place to incentivize an employee to remain a full year before leaving with company assets." />
                                                    </InputLabel>
                                                    <Field
                                                        component={FormikSelect}
                                                        name="cliff"
                                                        variant="outlined"
                                                        label="Cliff"
                                                        fullWidth
                                                        onChange={(e) =>
                                                            setCliff(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        {buildDropdown(3)}
                                                    </Field>
                                                    <br />
                                                    <InputLabel
                                                        className={
                                                            classes.inputLabel
                                                        }
                                                        htmlFor="cliff_vest"
                                                    >
                                                        Cliff Vesting (%)
                                                        <HelpTooltip title="This is typically cliff divided by duration however some companies may choose to accelerate beyond that." />
                                                    </InputLabel>
                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        percentage={1}
                                                        name="cliff_vest"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        placeholder={'50'}
                                                    ></Field>
                                                    <br />
                                                    <InputLabel
                                                        className={
                                                            classes.inputLabel
                                                        }
                                                        htmlFor="pro_rata"
                                                    >
                                                        Frequency
                                                        <HelpTooltip title="The frequency of vesting. Assets can vest on a proportional basis daily, weekly, monthly, etc." />
                                                    </InputLabel>
                                                    <Field
                                                        component={FormikSelect}
                                                        name="pro_rata"
                                                        variant="outlined"
                                                        label="Frequency"
                                                        margin="normal"
                                                        fullWidth
                                                        onChange={(e) =>
                                                            setProRata(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        {buildValuesDropdown(
                                                            VESTING_PRO_RATAS
                                                        )}
                                                    </Field>
                                                    <br />
                                                </Grid>
                                            ) : null}
                                        </Grid>
                                        <DialogActions
                                            className={classes.actions}
                                        >
                                            <Button
                                                color="secondary"
                                                variant="outlined"
                                                className={
                                                    classes.secondaryButtonLabel
                                                }
                                                onClick={() => {
                                                    closeAddDialog();
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                variant="outlined"
                                                className={classes.buttonLabel}
                                                disabled={isSubmitting}
                                            >
                                                {!isSubmitting ? (
                                                    <span>Save</span>
                                                ) : (
                                                    <CircularProgress
                                                        size={24}
                                                    />
                                                )}
                                            </Button>
                                        </DialogActions>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={existingShareholderDialog}
                onBackdropClick={(e) => setExistingShareholderDialog(false)}
            >
                <DialogTitle className={classes.dialogTitle}>
                    Existing Holder
                    <br />
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <Formik
                                initialValues={shareholderInitialValues}
                                /*validationSchema={
                                    entityType === 'individual'
                                        ? individualExistingShareholderValidationSchema
                                        : companyExistingShareholderValidationSchema
                                }*/
                                onSubmit={(values, { setSubmitting }) => {
                                    if (shareCategory === null) {
                                        enqueueSnackbar(
                                            'Please select category',
                                            {
                                                variant: 'error',
                                            }
                                        );
                                        setSubmitting(false);
                                    } else if (amountsError) {
                                        enqueueSnackbar(
                                            'Amount of Assets subject to Vesting should not exceed Fully diluted assets',
                                            {
                                                variant: 'error',
                                            }
                                        );
                                        setSubmitting(false);
                                    } else {
                                        props.capTableActions.addInvestor(
                                            {
                                                existing_shareholder: true,
                                                investor_id: existingShareholder,
                                                category: shareCategory,
                                                diluted_shares: values[
                                                    'shares'
                                                ].replace(/,/g, ''),
                                                options_exercise_price:
                                                    values[
                                                    'options_exercise_price'
                                                    ],
                                                duration: duration,
                                                vesting_shares: values[
                                                    'vesting_shares'
                                                ]
                                                    ? values[
                                                        'vesting_shares'
                                                    ].replace(/,/g, '')
                                                    : values['vesting_shares'],
                                                start_date:
                                                    values['start_date'],
                                                cliff: cliff,
                                                cliff_vest:
                                                    values['cliff_vest'],
                                                pro_rata: proRata,
                                                notes: values['notes'],
                                                acquired_date: values[
                                                    'acquired_date'
                                                ]
                                                    ? values['acquired_date']
                                                    : DateFormatter({
                                                        value: new Date(),
                                                        format: 'date_raw',
                                                    }),
                                                acquired_price: values[
                                                    'acquired_price'
                                                ]
                                                    ? values['acquired_price']
                                                    : null,
                                            },
                                            setSubmitting
                                        );

                                        if (
                                            props.categories.data &&
                                            !isShareholder &&
                                            category !== null
                                        ) {
                                            props.capTableActions.getShareholdersByCategory(
                                                category
                                            );
                                        } else {
                                            props.capTableActions.getShareholders();
                                        }
                                        setExistingShareholderDialog(false);
                                        setProRata(null);
                                        setDuration(null);
                                        setCliff(null);
                                    }
                                }}
                            >
                                {({ isSubmitting }) => (
                                    <Form className={classes.form} noValidate>
                                        <Grid>
                                            {props.shareholders.data &&
                                                props.shareholders.data.length >
                                                0 ? (
                                                <Grid>
                                                    <InputLabel
                                                        className={
                                                            classes.label
                                                        }
                                                    >
                                                        Select Holder
                                                    </InputLabel>
                                                    <FormControl
                                                        variant="outlined"
                                                        className={
                                                            classes.formControl
                                                        }
                                                        fullWidth
                                                    >
                                                        <Select
                                                            value={
                                                                existingShareholder
                                                            }
                                                            onChange={(e) =>
                                                                handleExistingShareholderChange(
                                                                    e
                                                                )
                                                            }
                                                        >
                                                            {props.shareholdersList.data.map(
                                                                (row) => (
                                                                    <MenuItem
                                                                        key={
                                                                            row.investor
                                                                        }
                                                                        value={
                                                                            row.investor_id
                                                                        }
                                                                    >
                                                                        {
                                                                            row.investor
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            ) : (
                                                <Grid style={{ color: 'red' }}>
                                                    You do not have any holders
                                                </Grid>
                                            )}
                                            {props.categories.data &&
                                                props.categories.data.length > 0 ? (
                                                <Grid>
                                                    <Field
                                                        component={FormikSelect}
                                                        name="category"
                                                        variant="outlined"
                                                        fullWidth
                                                        label="Select category"
                                                        value={
                                                            shareCategory !==
                                                                null
                                                                ? shareCategory
                                                                : props
                                                                    .categories
                                                                    .data[0]
                                                                    .category_id
                                                        }
                                                    >
                                                        {props.categories.data.map(
                                                            (row) => (
                                                                <Field
                                                                    component={
                                                                        MenuItem
                                                                    }
                                                                    key={
                                                                        row.category_description
                                                                    }
                                                                    value={
                                                                        row.category_id
                                                                    }
                                                                    onClick={() =>
                                                                        handleShareCategoryChange(
                                                                            row.category_id,
                                                                            row
                                                                        )
                                                                    }
                                                                    category={
                                                                        row
                                                                    }
                                                                    setShareCategory={
                                                                        setShareCategory
                                                                    }
                                                                    setIsSAFE={
                                                                        setIsSAFE
                                                                    }
                                                                    setFullyDilutedShares={
                                                                        setFullyDilutedShares
                                                                    }
                                                                >
                                                                    {
                                                                        row.category_description
                                                                    }
                                                                </Field>
                                                            )
                                                        )}
                                                    </Field>
                                                </Grid>
                                            ) : (
                                                <Grid
                                                    className={classes.redText}
                                                >
                                                    <h3>
                                                        You do not have any
                                                        categories created.
                                                        <span
                                                            onClick={() =>
                                                                history.push(
                                                                    '/issuer/issue/cap-table/class-info'
                                                                )
                                                            }
                                                            style={{
                                                                color:
                                                                    '#3E9CD9',
                                                                cursor:
                                                                    'pointer',
                                                            }}
                                                        >
                                                            {' '}
                                                            Create them here.
                                                        </span>
                                                    </h3>
                                                </Grid>
                                            )}
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Fully diluted assets"
                                                name="shares"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                numeric={1}
                                                required
                                                onKeyUp={(e) =>
                                                    handleFullyDilutedSharesChange(
                                                        e
                                                    )
                                                }
                                                InputLabelProps={{
                                                    style: {
                                                        pointerEvents: 'auto',
                                                    },
                                                }}
                                                disabled={isSAFE}
                                            />
                                            <Field
                                                component={TextField}
                                                type="number"
                                                label="Options exercise price"
                                                name="options_exercise_price"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                            />
                                            <Field
                                                component={TextField}
                                                type="date"
                                                label={'Acquired Date'}
                                                name="acquired_date"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                defaultValue={DateFormatter({
                                                    value: new Date(),
                                                    format: 'date_raw',
                                                })}
                                            />
                                            <Field
                                                component={TextField}
                                                name="acquired_price"
                                                type="text"
                                                numeric={1}
                                                label="Acquired Price Per Asset"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                            />
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Notes"
                                                name="notes"
                                                variant="outlined"
                                                margin="normal"
                                                multiline
                                                rows={4}
                                                helperText="Add some additional information about this investor."
                                                fullWidth
                                            />
                                            <Field
                                                component={CheckboxWithLabel}
                                                type="checkbox"
                                                name="excluding_option"
                                                onClick={changeExcludingOption}
                                                Label={{
                                                    label: (
                                                        <div
                                                            className={
                                                                classes.checkbox
                                                            }
                                                        >
                                                            Vesting{' '}
                                                            <HelpTooltip
                                                                title={
                                                                    'Vesting of assets restricts a investor to receive the full asset ownership over a set period of time.'
                                                                }
                                                            />
                                                        </div>
                                                    ),
                                                }}
                                                checked={excludingOption}
                                                required
                                            />
                                            {excludingOption === true ? (
                                                <Grid>
                                                    <InputLabel
                                                        className={
                                                            classes.inputLabel
                                                        }
                                                        htmlFor="vesting_shares"
                                                    >
                                                        Number of Assets subject
                                                        to Vesting{' '}
                                                        <HelpTooltip title="Enter 0 if all assets are fully vested" />
                                                    </InputLabel>{' '}
                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        name="vesting_shares"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        placeholder={'500,000'}
                                                        numeric={1}
                                                        onKeyUp={(e) =>
                                                            handleVestingSharesChange(
                                                                e
                                                            )
                                                        }
                                                    />
                                                    {amountsError && (
                                                        <span
                                                            className={
                                                                classes.errorMessage
                                                            }
                                                        >
                                                            *Amount of Assets
                                                            subject to Vesting
                                                            should not exceed
                                                            Fully diluted assets
                                                        </span>
                                                    )}
                                                    <br />
                                                    <Field
                                                        component={TextField}
                                                        type="date"
                                                        label={'Start Date'}
                                                        name="start_date"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                    />
                                                    <InputLabel
                                                        className={
                                                            classes.inputLabel
                                                        }
                                                        htmlFor="duration"
                                                    >
                                                        Duration (years)
                                                        <HelpTooltip title="How long is the vesting period for these assets? This is typically around 3-4 years but varies per company." />
                                                    </InputLabel>
                                                    <Field
                                                        component={FormikSelect}
                                                        name="duration"
                                                        variant="outlined"
                                                        fullWidth
                                                        label="Duration (years)"
                                                        onChange={(e) =>
                                                            setDuration(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        {buildDropdown(7)}
                                                    </Field>
                                                    <br />
                                                    <InputLabel
                                                        className={
                                                            classes.inputLabel
                                                        }
                                                        htmlFor="cliff"
                                                    >
                                                        Cliff (years)
                                                        <HelpTooltip title="A cliff is a period in which the option holder cannot access any assets. Typically a one year cliff is put in place to incentivize an employee to remain a full year before leaving with company assets." />
                                                    </InputLabel>
                                                    <Field
                                                        component={FormikSelect}
                                                        name="cliff"
                                                        variant="outlined"
                                                        label="Cliff"
                                                        fullWidth
                                                        onChange={(e) =>
                                                            setCliff(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        {buildDropdown(3)}
                                                    </Field>
                                                    <br />
                                                    <InputLabel
                                                        className={
                                                            classes.inputLabel
                                                        }
                                                        htmlFor="cliff_vest"
                                                    >
                                                        Cliff Vesting (%)
                                                        <HelpTooltip title="This is typically cliff divided by duration however some companies may choose to accelerate beyond that." />
                                                    </InputLabel>
                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        percentage={1}
                                                        name="cliff_vest"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        placeholder={'50'}
                                                    ></Field>
                                                    <br />
                                                    <InputLabel
                                                        className={
                                                            classes.inputLabel
                                                        }
                                                        htmlFor="pro_rata"
                                                    >
                                                        Frequency
                                                        <HelpTooltip title="The frequency of vesting. Assets can vest on a proportional basis daily, weekly, monthly, etc." />
                                                    </InputLabel>
                                                    <Field
                                                        component={FormikSelect}
                                                        name="pro_rata"
                                                        variant="outlined"
                                                        label="Frequency"
                                                        margin="normal"
                                                        fullWidth
                                                        onChange={(e) =>
                                                            setProRata(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        {buildValuesDropdown(
                                                            VESTING_PRO_RATAS
                                                        )}
                                                    </Field>
                                                    <br />
                                                </Grid>
                                            ) : null}
                                        </Grid>
                                        <DialogActions
                                            className={classes.actions}
                                        >
                                            <Button
                                                color="secondary"
                                                variant="outlined"
                                                className={
                                                    classes.secondaryButtonLabel
                                                }
                                                onClick={() => {
                                                    closeExistingShareholderDialog();
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="submit"
                                                variant="outlined"
                                                className={classes.buttonLabel}
                                                color="primary"
                                                disabled={
                                                    isSubmitting ||
                                                    (props.shareholders.data &&
                                                        props.shareholders.data
                                                            .length === 0)
                                                }
                                            >
                                                {!isSubmitting ? (
                                                    <span>Save</span>
                                                ) : (
                                                    <CircularProgress
                                                        size={24}
                                                    />
                                                )}
                                            </Button>
                                        </DialogActions>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <br />
            <Dialog maxWidth="sm" fullWidth open={openShareholdersEditDialog}>
                <DialogTitle className={classes.dialogTitle}>
                    Edit Holder
                    <IconButton
                        className={classes.closeButton}
                        onClick={closeEditDialog}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={12}>
                            <Formik
                                initialValues={editShareholderData}
                                validationSchema={
                                    editShareholderData.entity_type ===
                                        'individual'
                                        ? individualShareholderValidationSchema
                                        : companyShareholderValidationSchema
                                }
                                onSubmit={(values, { setSubmitting }) => {
                                    values['shares'] = values['shares'].replace(
                                        /,/g,
                                        ''
                                    );
                                    if (values['vesting_shares'] !== null) {
                                        values['vesting_shares'] = values[
                                            'vesting_shares'
                                        ].replace(/,/g, '');
                                    }

                                    values['vesting_pro_rata'] = vestingProRata;
                                    values[
                                        'vesting_duration_years'
                                    ] = vestingDurationYears;
                                    values[
                                        'vesting_cliff_years'
                                    ] = vestingCliffYears;
                                    values['share_class_id'] = investorClass;
                                    values[
                                        'share_class_category_id'
                                    ] = investorCategory;

                                    if (amountsError) {
                                        enqueueSnackbar(
                                            'Amount of Assets subject to Vesting should not exceed Fully diluted assets',
                                            {
                                                variant: 'error',
                                            }
                                        );
                                        setSubmitting(false);
                                    } else if (emailError) {
                                        enqueueSnackbar(
                                            'A holder with this email address already exists.',
                                            {
                                                variant: 'error',
                                            }
                                        );
                                        setSubmitting(false);
                                    } else if (
                                        values['type'] === 'shareholder'
                                    ) {
                                        request
                                            .patch(
                                                `${config.API_BASE_URL}/shareholders/edit`,
                                                values
                                            )
                                            .then((response) => {
                                                enqueueSnackbar(
                                                    'Holder has been updated successfully',
                                                    {
                                                        variant: 'success',
                                                    }
                                                );
                                                setOpenShareholdersEditDialog(
                                                    false
                                                );
                                                setFullyDilutedShares(null);
                                                setVestingShares(null);
                                                setAmountsError(false);
                                                props.capTableActions.updateShareholder(
                                                    values
                                                );
                                                props.capTableActions.getShareholders();
                                            })
                                            .catch((error) => {
                                                if (
                                                    error.response.data.message
                                                ) {
                                                    if (
                                                        error.response.data.message.includes(
                                                            'The total amount of assets allocated to a holder cannot exceed the total authorized assets within a class'
                                                        )
                                                    ) {
                                                        enqueueSnackbar(
                                                            error.response.data
                                                                .message,
                                                            {
                                                                variant:
                                                                    'error',
                                                            }
                                                        );
                                                    } else if (
                                                        error.response.data.message.includes(
                                                            'The email has already been taken'
                                                        )
                                                    ) {
                                                        enqueueSnackbar(
                                                            error.response.data
                                                                .message,
                                                            {
                                                                variant:
                                                                    'error',
                                                            }
                                                        );
                                                    }
                                                } else {
                                                    enqueueSnackbar(
                                                        'Error occured while editing holder',
                                                        {
                                                            variant: 'error',
                                                        }
                                                    );
                                                }

                                                setSubmitting(false);
                                            });
                                    } else {
                                        request
                                            .patch(
                                                `${config.API_BASE_URL}/shareholders/category/${category}/edit`,
                                                values
                                            )
                                            .then((response) => {
                                                if (isShareholder) {
                                                    // props.capTableActions.getShareholders();
                                                }

                                                if (
                                                    props.categories.data &&
                                                    props.categories.data
                                                        .length &&
                                                    !category
                                                ) {
                                                    setCategory(
                                                        props.categories.data[0]
                                                            .category_id
                                                    );
                                                }

                                                if (
                                                    props.categories.data &&
                                                    !isShareholder
                                                ) {
                                                    props.capTableActions.updateShareholder(
                                                        values,
                                                        category
                                                    );
                                                    props.capTableActions.getShareholdersByCategory(
                                                        category,
                                                        values
                                                    );
                                                }

                                                enqueueSnackbar(
                                                    'Holder has been updated successfully',
                                                    {
                                                        variant: 'success',
                                                    }
                                                );
                                                setOpenShareholdersEditDialog(
                                                    false
                                                );
                                                setFullyDilutedShares(null);
                                                setVestingShares(null);
                                                setAmountsError(false);
                                            })
                                            .catch(() => { });
                                    }
                                }}
                            >
                                {({ errors, isSubmitting, values }) => (
                                    <Container>
                                        <Form
                                            className={classes.form}
                                            noValidate
                                        >
                                            <LockedTile
                                                lock={isViewMode(props.profile)}
                                            >
                                                <Grid item xs={12}>
                                                    <Field
                                                        component={FormikSelect}
                                                        name="share_class_id"
                                                        variant="outlined"
                                                        label="Class"
                                                        fullWidth
                                                        inputProps={{
                                                            id:
                                                                'share_class_id',
                                                        }}
                                                        required
                                                        defaultValue={
                                                            investorClass
                                                        }
                                                        onChange={(e) =>
                                                            setInvestorClass(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        {classItems.map((c) => (
                                                            <MenuItem
                                                                key={c.id}
                                                                value={c.id}
                                                                onClick={
                                                                    loadClassCategories
                                                                }
                                                            >
                                                                {
                                                                    c.class_description
                                                                }
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Field
                                                        component={FormikSelect}
                                                        name="share_class_category_id"
                                                        variant="outlined"
                                                        label="Category"
                                                        inputProps={{
                                                            id:
                                                                'share_class_category_id',
                                                        }}
                                                        fullWidth
                                                        required
                                                        defaultValue={
                                                            investorCategory
                                                        }
                                                        onChange={(e) =>
                                                            setInvestorCategory(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        {categoryItems.map(
                                                            (c) => (
                                                                <MenuItem
                                                                    key={
                                                                        c.category_id
                                                                    }
                                                                    value={
                                                                        c.category_id
                                                                    }
                                                                >
                                                                    {
                                                                        c.category_description
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Field>
                                                </Grid>
                                            </LockedTile>
                                            <Field
                                                name="entity_type"
                                                type="hidden"
                                            />
                                            {!editShareholderData.first_name &&
                                                !editShareholderData.last_name &&
                                                !editShareholderData.company_name && (
                                                    <Field
                                                        component={TextField}
                                                        name="investor"
                                                        type="text"
                                                        label="Investor"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                    />
                                                )}
                                            {editShareholderData.first_name && (
                                                <Field
                                                    component={TextField}
                                                    name="first_name"
                                                    type="text"
                                                    label="First Name"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            )}
                                            {editShareholderData.last_name && (
                                                <Field
                                                    component={TextField}
                                                    name="last_name"
                                                    type="text"
                                                    label="Last Name"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            )}
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Email"
                                                name="email"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                required
                                            />
                                            <Field
                                                component={TextField}
                                                name="company_name"
                                                type="text"
                                                label="Company name"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                            />

                                            {props.profile &&
                                                isTALoggedAsIssuer(
                                                    props.profile
                                                ) && (
                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        label="OFAC compliance ID"
                                                        name="ofac_id"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        required
                                                    />
                                                )}
                                            {editShareholderData.entity_type ===
                                                'individual' && (
                                                    <Field
                                                        component={TextField}
                                                        name="position"
                                                        type="text"
                                                        label="Company position"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                    />
                                                )}

                                            <Grid item xs={12}>
                                                <Field
                                                    component={
                                                        FormikAutocomplete
                                                    }
                                                    name="country"
                                                    variant="outlined"
                                                    label="Country of Incorporation *"
                                                    fullWidth
                                                    margin="normal"
                                                    inputProps={{
                                                        id: 'country',
                                                    }}
                                                    onClick={changePhoneFlag}
                                                    optionKey="name"
                                                    dataKey="id"
                                                    required
                                                    options={registry.countries}
                                                />
                                            </Grid>
                                            <Field
                                                component={TextField}
                                                name="city"
                                                type="text"
                                                label="City"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                            />
                                            <Field
                                                component={TextField}
                                                name="address"
                                                type="text"
                                                label="Address"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                            />
                                            <Field
                                                component={TextField}
                                                name="phone"
                                                type="text"
                                                label="Phone"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                defaultCountry={
                                                    phoneFlag ? phoneFlag : 'us'
                                                }
                                            />
                                            <LockedTile
                                                lock={isViewMode(props.profile)}
                                            >
                                                <Field
                                                    component={TextField}
                                                    name="shares"
                                                    type="text"
                                                    label="Assets"
                                                    variant="outlined"
                                                    margin="normal"
                                                    numeric={1}
                                                    fullWidth
                                                    required
                                                    disabled={isViewMode(
                                                        props.profile
                                                    )}
                                                />
                                                <Field
                                                    component={TextField}
                                                    name="options_exercise_price"
                                                    type="number"
                                                    label="Options exercise price"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                />
                                                <Field
                                                    component={TextField}
                                                    type="date"
                                                    label={'Acquired Date'}
                                                    name="acquired_date"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                />
                                                <Field
                                                    component={TextField}
                                                    name="acquired_price"
                                                    type="text"
                                                    numeric={1}
                                                    label="Acquired Price Per Asset"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            </LockedTile>
                                            <hr />
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Notes"
                                                name="notes"
                                                variant="outlined"
                                                margin="normal"
                                                multiline
                                                rows={4}
                                                helperText="Add some additional information about this investor."
                                                fullWidth
                                            />
                                            <hr />
                                            <LockedTile
                                                lock={isViewMode(props.profile)}
                                            >
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    name="vesting_shares"
                                                    label="Vesting Assets"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    placeholder={'500,000'}
                                                    numeric={1}
                                                />
                                                {parseNumber(
                                                    values['vesting_shares']
                                                ) >
                                                    parseNumber(
                                                        values['shares']
                                                    ) && (
                                                        <FormHelperText error>
                                                            The amount of vesting
                                                            assets cannot be greater
                                                            than total assets.
                                                        </FormHelperText>
                                                    )}
                                                <Field
                                                    component={TextField}
                                                    type="date"
                                                    label={'Vesting Start Date'}
                                                    name="vesting_start_date"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                />
                                                <Grid item xs={12}>
                                                    <Field
                                                        component={FormikSelect}
                                                        name="vesting_duration_years"
                                                        variant="outlined"
                                                        label="Duration (years)"
                                                        fullWidth
                                                        defaultValue={
                                                            editShareholderData.vesting_duration_years
                                                                ? editShareholderData.vesting_duration_years
                                                                : null
                                                        }
                                                        onChange={(e) =>
                                                            setVestingDurationYears(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        {buildDropdown(7)}
                                                    </Field>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Field
                                                        component={FormikSelect}
                                                        name="vesting_cliff_years"
                                                        variant="outlined"
                                                        label="Cliff (years)"
                                                        fullWidth
                                                        onChange={(e) =>
                                                            setVestingCliffYears(
                                                                e.target.value
                                                            )
                                                        }
                                                        defaultValue={
                                                            editShareholderData.vesting_cliff_years
                                                                ? editShareholderData.vesting_cliff_years
                                                                : null
                                                        }
                                                    >
                                                        {buildDropdown(3)}
                                                    </Field>
                                                </Grid>
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    percentage={1}
                                                    label="Cliff Vesting (%)"
                                                    name="vesting_cliff_p"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                ></Field>
                                                <Field
                                                    component={FormikSelect}
                                                    name="vesting_pro_rata"
                                                    variant="outlined"
                                                    label="Frequency"
                                                    margin="normal"
                                                    fullWidth
                                                    onChange={(e) =>
                                                        setVestingProRata(
                                                            e.target.value
                                                        )
                                                    }
                                                    defaultValue={
                                                        editShareholderData.vesting_pro_rata
                                                            ? editShareholderData.vesting_pro_rata
                                                            : null
                                                    }
                                                >
                                                    {buildValuesDropdown(
                                                        VESTING_PRO_RATAS
                                                    )}
                                                </Field>
                                            </LockedTile>

                                            <DialogActions
                                                className={classes.actions}
                                            >
                                                <Button
                                                    color="secondary"
                                                    variant="outlined"
                                                    className={
                                                        classes.secondaryButtonLabel
                                                    }
                                                    onClick={() => {
                                                        closeEditDialog();
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    variant="outlined"
                                                    className={
                                                        classes.buttonLabel
                                                    }
                                                    color="primary"
                                                    disabled={
                                                        parseNumber(
                                                            values[
                                                            'vesting_shares'
                                                            ]
                                                        ) >
                                                        parseNumber(
                                                            values['shares']
                                                        )
                                                    }
                                                >
                                                    Save
                                                </Button>
                                            </DialogActions>
                                        </Form>
                                    </Container>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            {/* Delete shareholder dialog */}
            <Dialog maxWidth="sm" fullWidth open={openShareholdersDeleteDialog}>
                <DialogTitle className={classes.dialogTitle}>
                    Delete Holder
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete the holder?
                    </Typography>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={12}>
                            <Formik
                                initialValues={deleteShareholderData}
                                onSubmit={(values, { setSubmitting }) => {
                                    values['shares'] = values['shares'].replace(
                                        /,/g,
                                        ''
                                    );
                                    request
                                        .delete(
                                            `${config.API_BASE_URL}/shareholders/category/${category}/delete`,
                                            { data: values }
                                        )
                                        .then((response) => {
                                            if (isShareholder) {
                                                props.capTableActions.getShareholders();
                                            }

                                            if (
                                                props.categories.data &&
                                                props.categories.data.length &&
                                                !category
                                            ) {
                                                setCategory(
                                                    props.categories.data[0]
                                                        .category_id
                                                );
                                            }

                                            if (
                                                props.categories.data &&
                                                !isShareholder
                                            ) {
                                                props.capTableActions.getShareholdersByCategory(
                                                    category
                                                );
                                            }

                                            props.profileActions.getProfile();

                                            enqueueSnackbar(
                                                'Holder has been deleted successfully',
                                                {
                                                    variant: 'success',
                                                }
                                            );
                                            setOpenShareholdersDeleteDialog(
                                                false
                                            );
                                        })
                                        .catch((error) => {
                                            enqueueSnackbar(
                                                'Error occured while trying to delete holder.',
                                                {
                                                    variant: 'error',
                                                }
                                            );
                                            setOpenShareholdersDeleteDialog(
                                                false
                                            );
                                        });
                                    
                                }}
                            >
                                {({ isSubmitting }) => (
                                    <Container>
                                        <Form
                                            className={classes.form}
                                            noValidate
                                        >
                                            <DialogActions
                                                className={classes.actions}
                                            >
                                                <Button
                                                    color="secondary"
                                                    variant="outlined"
                                                    className={
                                                        classes.secondaryButtonLabel
                                                    }
                                                    onClick={() => {
                                                        closeEditDialog();
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    variant="outlined"
                                                    className={
                                                        classes.buttonLabel
                                                    }
                                                    clor="primary"
                                                >
                                                    Delete
                                                </Button>
                                            </DialogActions>
                                        </Form>
                                    </Container>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            {/* Invite to KYC */}
            {/* Delete shareholder dialog */}
            <Dialog maxWidth="sm" fullWidth open={openShareholdersKycDialog}>
                <DialogTitle className={classes.dialogTitle}>
                    Invite to KYC
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        Do you want to invite this holder to finsih their KYC
                        process?
                    </Typography>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={12}>
                            <DialogActions className={classes.actions}>
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    className={classes.secondaryButtonLabel}
                                    onClick={() => closeKycDialog()}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    variant="outlined"
                                    className={classes.buttonLabel}
                                    color="primary"
                                    disabled={isDisabledSend}
                                    onClick={() => sendKycInvite()}
                                >
                                    {isDisabledSend ? (
                                        <div className="flex-center">
                                            <CircularProgress size={24} />
                                            &nbsp; Sending...
                                        </div>
                                    ) : (
                                        'Send Invite'
                                    )}
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};

const mapStateToProps = ({
    profile,
    capTable,
    categories,
    shareholders,
    investorInvites,
}) => {
    return {
        profile: profile,
        shareholders: capTable.shareholders,
        categories: capTable.categories,
        shareholdersList: shareholders,
        investorInvites: investorInvites,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        capTableActions: bindActionCreators(capTableActions, dispatch),
        categoryActions: bindActionCreators(categoryActions, dispatch),
        profileActions: bindActionCreators(profileActions, dispatch),
        shareholderActions: bindActionCreators(shareholderActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditInvestorInfo);
