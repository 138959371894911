const infoDialogStyles = (theme) => ({
    action: {
        marginTop: '1.5rem',
        alignItems: 'center',
    },
    blue: {
        color: '#3E9CD9 !important'
    }
})

export default infoDialogStyles