import useSWR from "swr"
import { API_BASE_URL } from "../config"
import fetcher from "../utilities/fetcher"

const useShareholdersByCategory = (categoryId) => {
    const { data, error } = useSWR(() => categoryId ? `${API_BASE_URL}/shareholders/category/${categoryId}` : null, fetcher)

    return {
        shareholders: data || [],
        shareholdersLoading: categoryId && !error && !data,
        shareholdersError: error
    }
}

export default useShareholdersByCategory