import * as dialogTypes from './dialogTypes'

const initialState = {
    open: false,
    title: null,
    message: null,
    success: null,
    cancelText: 'Cancel',
    confirmText: 'Add',
    loading: false,
}

const dialogReducer = (state = initialState, action) => {
    switch (action.type) {
        /* Open dialog */
        case dialogTypes.OPEN_DIALOG_SUCCESS: {
            return {
                ...state,
                ...action.payload
            }
        }
        /* Close dialog */
        case dialogTypes.CLOSE_DIALOG_SUCCESS: {
            return initialState
        }
        /* Set dialog loading */
        case dialogTypes.SET_DIALOG_LOADING: {
            return {
                ...state,
                loading: action.payload
            }
        }
        default:
            return state
    }
}

export default dialogReducer