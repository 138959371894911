import { API_BASE_URL } from "../../config"
import request from "../../utilities/request"

const sendAsset = (holderId, assetId, address, amount) => request.post(`${API_BASE_URL}/blockchain/holder/send-asset`, {
    holder_id: holderId,
    asset_id: assetId,
    address,
    amount
})

export default sendAsset