import Tab from '@mui/material/Tab';

import { styled } from '@mui/material/styles';

const StyledMainTab = styled(Tab, {
    shouldForwardProp: (prop) => prop !== 'isVerticalTab'
})(({ isVerticalTab }) => ({
    textTransform: 'capitalize', 
    fontSize: 14, 
    minWidth: isVerticalTab ? 200 : "inherit",
    paddingLeft: isVerticalTab ? 10 : 'initial',
    alignItems: isVerticalTab ? 'flex-start': 'inherit',
    '&.Mui-selected': {
        fontWeight: 700,
        color: "#3E9CD9 !important"
    },
}))

export default StyledMainTab