import useSWR from "swr"
import { API_BASE_URL } from "../config"
import fetcher from "../utilities/fetcher"

const useShareClasses = () => {
    const { data, error } = useSWR(`${API_BASE_URL}/share-classes`, fetcher)

    return {
        shareClasses: data,
        shareClassesLoading: !error && !data,
        ishareClassesError: error,
        defaultShareClass: data ? data[0] : []
    }
}

export default useShareClasses