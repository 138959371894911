import React from 'react';
import { TableCell } from '@mui/material';
import { omit } from 'lodash';

export default function BaseTableCell(props) {
    const { col, item, children } = props;

    const customProps = omit(props, ['col', 'item', 'children']);
    return (
        <TableCell
            {...customProps}
            {...col.colProps}
            {...item.rowProps}
            {...(item.cellProps && (item.cellProps[col.field] || {}))}
        >
            {item.hiddenFields
                ? !item.hiddenFields.includes(col.field) && children
                : children}
        </TableCell>
    );
}
