import useSWR from "swr"
import { API_BASE_URL } from "../../config"
import fetcher from "../../utilities/fetcher"

const useAssetsOutsideCategory = (categoryId) => {
    const { data, error, mutate } = useSWR(() => categoryId ? `${API_BASE_URL}/blockchain/assetsOutsideCategory/${categoryId}` : null, fetcher)

    return {
        assetsOutsideCategory: data || [],
        assetsOutsideCategoryLoading: !error && !data,
        assetsOutsideCategoryError: error,
        updateAssetsOutsideCategory: () => mutate()
    }
}

export default useAssetsOutsideCategory