import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as config from './../../../config';
import request from '../../../utilities/request';
import { useSnackbar } from 'notistack';
import authStyles from '../authStyles';
import * as kycActions from '../../../store/kyc/kycActions';
import DataTable from '../../../components/DataTable/MuiDataTable';
import CircularProgress from '@mui/material/CircularProgress';
//import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
//import AddIcon from '@mui/icons-material/Add';
import Overview from './Overview';
import { SignalCellularNullOutlined } from '@mui/icons-material';
import useKycShareholders from '../../../api/useKycShareholders';
import LockedTile from '../../../components/LockedTile';
import { SUBSCRIPTION_PRO } from '../../../constants/profile';
const KycPage = (props) => {
    const classes = authStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [isDisabledSend, setIsDisabledSend] = useState(false);
    const [shareholderIndex, setShareholderIndex] = useState(
        SignalCellularNullOutlined
    );

    const { shareholders, shareholdersLoading } = useKycShareholders();

    const sendkycInvite = (investor_id, idx) => {
        setIsDisabledSend(true);
        setShareholderIndex(idx);
        request
            .post(`${config.API_BASE_URL}/kyc/invite`, {
                investor_id: investor_id,
            })
            .then((response) => {
                enqueueSnackbar('KYC invite sent successfully', {
                    variant: 'success',
                });

                setIsDisabledSend(false);
                //props.kycActions.getKyc();
            })
            .catch((error) => {
                setIsDisabledSend(false);
            })
            .finally(() => {
                setShareholderIndex(null);
            });
    };

    return (
        <LockedTile requiredPlan={SUBSCRIPTION_PRO}>
            <div>
                <Overview />
                <br />
                <br />

                <Grid container spacing={2}>
                    {shareholdersLoading ? (
                        <Grid container item xs={12} justifyContent="center">
                            <CircularProgress />
                        </Grid>
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Divider textAlign="right">
                                    <Button
                                        variant="outlined"
                                        onClick={() =>
                                            sendkycInvite('all', 'all')
                                        }
                                        disabled={shareholderIndex === 'all'}
                                    >
                                        {shareholderIndex === 'all'
                                            ? 'Sending...'
                                            : 'Invite All'}
                                    </Button>
                                    {/*<Fab
                                    variant="extended"
                                    onClick={() => sendkycInvite('all', 'all')}
                                    disabled={shareholderIndex === 'all'}
                                >
                                    <AddIcon />
                                    {shareholderIndex === 'all'
                                        ? 'Sending...'
                                        : 'Invite All'}
                                </Fab>*/}
                                </Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <DataTable
                                    title={'Shareholder KYC Status'}
                                    data={
                                        shareholders && shareholders.length > 0
                                            ? shareholders
                                            : []
                                    }
                                    columns={[
                                        {
                                            label: 'Shareholder',
                                            name: 'investor',
                                        },
                                        {
                                            label: 'Company',
                                            name: 'company_name',
                                        },
                                        {
                                            label: 'Kyc Status',
                                            name: 'kyc_status',
                                        },
                                        {
                                            label: 'Subscription Agreement',
                                            name: 'subscription_agreement',
                                            options: {
                                                customBodyRender: (value) => (
                                                    <span>
                                                        {value === 'Y'
                                                            ? 'Yes'
                                                            : 'No'}
                                                    </span>
                                                ),
                                            },
                                        },

                                        {
                                            label: 'Invite',
                                            name: 'investor_id',
                                            options: {
                                                customBodyRenderLite: (
                                                    dataIndex
                                                ) => {
                                                    const row =
                                                        shareholders[dataIndex];
                                                    return (
                                                        <Button
                                                            type="button"
                                                            variant="text"
                                                            color="primary"
                                                            className={
                                                                classes.alignCenter
                                                            }
                                                            style={{
                                                                textTransform:
                                                                    'none',
                                                            }}
                                                            onClick={() =>
                                                                sendkycInvite(
                                                                    row[
                                                                        'investor_id'
                                                                    ],
                                                                    dataIndex
                                                                )
                                                            }
                                                            disabled={
                                                                row[
                                                                    'kyc_status'
                                                                ] ===
                                                                    'Approved' ||
                                                                (isDisabledSend &&
                                                                    (shareholderIndex ===
                                                                        dataIndex ||
                                                                        shareholderIndex ===
                                                                            'all'))
                                                            }
                                                        >
                                                            {isDisabledSend &&
                                                            shareholderIndex ===
                                                                dataIndex ? (
                                                                <div className="flex-center">
                                                                    <CircularProgress
                                                                        size={
                                                                            24
                                                                        }
                                                                    />
                                                                    &nbsp;
                                                                    Sending...
                                                                </div>
                                                            ) : (
                                                                'Send KYC link'
                                                            )}
                                                        </Button>
                                                    );
                                                },
                                            },
                                        },
                                    ]}
                                    shareSort={2}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </div>
        </LockedTile>
    );
};

const mapStateToProps = ({ kyc, profile }) => {
    return {
        kyc: kyc,
        profile: profile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        kycActions: bindActionCreators(kycActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(KycPage);
