import React from 'react'

import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import containerStyles from '../styles/containerStyles';

const BoxWithLogo = ({ children, title, logo, altTitle }) => {
    const classes = containerStyles();
    
    return (
        <Container className={classes.boxContainer}>
            <br />
            <Paper className={classes.paper}>
                <img
                    src={logo}
                    className={classes.logo}
                    alt={altTitle ? altTitle : "DIGTL Dashboard"}
                />
            </Paper>
            <Paper className={classes.paper}>
                <Container maxWidth="xs">
                    <Typography component="div">
                        <h2>{title}</h2>
                    </Typography>

                    {/* Content */}
                    {children}
                </Container>
            </Paper>
        </Container>
    )
}

export default BoxWithLogo
