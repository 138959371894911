import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { connect } from 'react-redux';
import republic from '../../../assets/img/republic.png';
import frontfundr from '../../../assets/img/frontfundr.png';
import dslg from '../../../assets/img/dslg.png';
import advisory from '../../../assets/img/techemy-advisory.png';
import fort_capital_solutions from '../../../assets/img/fort_capital_solutions.png';
import entoro from '../../../assets/img/entoro-logo.png';
import acuant from '../../../assets/img/acuant.png';
import prime_trust from '../../../assets/img/prime_trust.png';
import signature_bank from '../../../assets/img/signature_bank.png';
import nem from '../../../assets/img/nem.png';
import liquid from '../../../assets/img/liquid.png';
import transfer from '../../../assets/img/1transfer.png';
import stm from '../../../assets/img/stm.png';
import fundraiseStyles from '../../../components/styles/fundraiseStyles';
import LockedTile from '../../../components/LockedTile';

const VerifiedPartners = (props) => {
    const crowfunding_brokers = [
        {
            type: 'Crowdfunding',
            partners: [
                {
                    name: 'republic',
                    src: republic,
                    website: 'https://republic.co/',
                },
                {
                    name: 'frontfundr',
                    src: frontfundr,
                    website: 'https://www.frontfundr.com/',
                },
            ],
        },
        {
            type: 'Broker / Dealer',
            partners: [
                {
                    name: 'entoro',
                    src: entoro,
                    website: 'https://www.entoro.com/',
                },
                {
                    name: 'fortcapital',
                    src: fort_capital_solutions,
                    website: 'https://www.fortcapital.co.uk/',
                },
            ],
        },
        /*{
            type: 'Legal',
            partners: [
                {
                    name: 'dslg',
                    src: dslg,
                    website: 'https://dslg.co/',
                },
            ],
        },*/
    ];

    let accreditations = [
        {
            type: 'KYC / Accreditation',
            partners: [
                {
                    name: 'acuant',
                    src: acuant,
                    website: 'https://www.acuant.com/',
                },
                {
                    name: '1transfer',
                    src: transfer,
                    website: 'https://www.1transfer.io/',
                },
            ],
        },
    ];

    let blockchains = [
        {
            type: 'Blockchain',
            partners: [
                {
                    name: 'liquid',
                    src: liquid,
                    website: 'https://www.liquid.com/',
                },
                {
                    name: 'nem',
                    src: nem,
                    website: 'https://nem.io/',
                },
            ],
        },
    ];

    let advisories = [
        {
            type: 'Advisory',
            partners: [
                {
                    name: 'security token market',
                    src: stm,
                    website: 'https://stomarket.com/',
                },
                {
                    name: 'DSLG',
                    src: dslg,
                    website: 'https://www.digitalsecuritiesgroup.com/',
                },
                {
                    name: 'techemy',
                    src: advisory,
                    website: 'https://techemyadvisory.co/',
                },
            ],
        },
    ];

    let payments = [
        {
            type: 'Payments / Custody',
            partners: [
                {
                    name: 'signaturebank',
                    src: signature_bank,
                    website: 'https://www.signatureny.com/',
                },
                {
                    name: 'primetrust',
                    src: prime_trust,
                    website: 'https://www.primetrust.com/',
                },
            ],
        },
    ];

    const classes = fundraiseStyles();

    return (
        <LockedTile>
            <Grid
                container
                spacing={2}
            >
                {crowfunding_brokers.map((crowfunding_broker, i) => (
                    <Grid item xs={12} sm={6} md={6} key={i}>
                        <Paper
                            elevation={3}
                            className={classes.paper}
                            style={{ minHeight: '15.8rem' }}
                        >
                            <Typography className={classes.title}>
                                {crowfunding_broker.type}
                            </Typography>
                            <br />
                            <Grid container className={classes.container}>
                                {crowfunding_broker.partners.map((partner, j) => (
                                    <Grid xs={12} sm={6} md={6} key={j}>
                                        <img
                                            key={j}
                                            src={partner.src}
                                            title={partner.website}
                                            className={classes.cursor}
                                            //width={250}
                                            style={{ width: '80%', height: '50%' }}
                                            alt=""
                                            onClick={() =>
                                                window.open(
                                                    partner.website,
                                                    '_blank'
                                                )
                                            }
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Paper>
                    </Grid>
                ))}

                {payments.map((payment, i) => (
                    <Grid item xs={12} sm={8} md={8} key={i}>
                        <Paper
                            elevation={3}
                            className={classes.paper}
                            style={{ minHeight: '15.8rem' }}
                        >
                            <Typography className={classes.title}>
                                {payment.type}
                            </Typography>
                            <br />
                            <Grid container className={classes.container}>
                                {payment.partners.map((partner, j) => (
                                    <Grid xs={12} sm={6} md={6} key={j}>
                                        <img
                                            key={j}
                                            src={partner.src}
                                            title={partner.website}
                                            className={classes.cursor}
                                            //width={250}
                                            style={{ width: '80%', height: '50%' }}
                                            alt=""
                                            onClick={() =>
                                                window.open(
                                                    partner.website,
                                                    '_blank'
                                                )
                                            }
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Paper>
                    </Grid>
                ))}

                {blockchains.map((blockchain, i) => (
                    <Grid item xs={12} sm={4} md={4} key={i}>
                        <Paper
                            elevation={3}
                            className={classes.paper}
                            style={{ minHeight: '15.8rem' }}
                        >
                            <Typography className={classes.title}>
                                {blockchain.type}
                            </Typography>
                            <br />
                            <Grid container className={classes.container}>
                                {blockchain.partners.map((partner, j) => (
                                    <Grid xs={12} sm={6} md={6} key={j}>
                                        <img
                                            key={j}
                                            src={partner.src}
                                            title={partner.website}
                                            className={classes.cursor}
                                            //width={250}

                                            style={{
                                                width: '80%',
                                                height: '50%',
                                            }}
                                            alt=""
                                            onClick={() =>
                                                window.open(
                                                    partner.website,
                                                    '_blank'
                                                )
                                            }
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Paper>
                    </Grid>
                ))}

                {accreditations.map((accreditation, i) => (
                    <Grid item xs={12} sm={4} md={4} key={i}>
                        <Paper
                            elevation={3}
                            className={classes.paper}
                            style={{ minHeight: '17.5rem' }}
                        >
                            <Typography className={classes.title}>
                                {accreditation.type}
                            </Typography>
                            <br />
                            <Grid container className={classes.container}>
                                {accreditation.partners.map((partner, j) => (
                                    <Grid
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        key={j}
                                        className={
                                            partner.type === 'KYC / Accreditation'
                                                ? classes.marginTop
                                                : undefined
                                        }
                                    >
                                        <img
                                            key={j}
                                            src={partner.src}
                                            title={partner.website}
                                            className={classes.cursor}
                                            //width={250}
                                            style={{ width: '80%', height: '50%' }}
                                            alt=""
                                            onClick={() =>
                                                window.open(
                                                    partner.website,
                                                    '_blank'
                                                )
                                            }
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Paper>
                    </Grid>
                ))}

                {advisories.map((advisory, i) => (
                    <Grid item xs={12} sm={8} md={8} key={i}>
                        <Paper
                            elevation={3}
                            className={classes.paper}
                            style={{ minHeight: '15.8rem' }}
                        >
                            <Typography className={classes.title}>
                                {advisory.type}
                            </Typography>
                            <br />
                            <Grid container className={classes.container}>
                                {advisory.partners.map((partner, j) => (
                                    <Grid xs={12} sm={4} md={4} key={j}>
                                        <img
                                            key={j}
                                            src={partner.src}
                                            title={partner.website}
                                            className={classes.cursor}
                                            //width={250}
                                            style={{ width: '80%', height: '50%' }}
                                            alt=""
                                            onClick={() =>
                                                window.open(
                                                    partner.website,
                                                    '_blank'
                                                )
                                            }
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </LockedTile>
    );
};

const mapStateToProps = ({ profile }) => {
    return {
        profile: profile,
    };
};

export default connect(mapStateToProps)(VerifiedPartners);
