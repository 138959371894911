import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import React from 'react'

import registerAsset from '../../../../api/blockchain/registerAsset'

import SubmitButton from '../../../../components/Form/SubmitButton'
import { errorParser } from '../../../../components/LiquidForm/utils/errorParser'
import { snackbar } from '../../../../utilities/snackbarUtils'

const RegisterAssetDialog = ({ open, handleClose, asset, updateAssets }) => {
    const [loading, setLoading] = React.useState(false)

    const generateProofFile = () => {
        const element = document.createElement("a");
        const file = new Blob([`Authorize linking the domain name ${asset?.domain} to the Liquid asset ${asset?.assetId}`], { type: 'application/octet-stream' });
        element.href = URL.createObjectURL(file);
        element.download = `liquid-asset-proof-${asset?.assetId}`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    const onSubmit = () => {
        setLoading(true)
        registerAsset(asset?.assetUuid).then(response => {
            snackbar.success('Succesfully registered the asset.');
            handleClose()
            updateAssets()
        }).catch((error) => {
            snackbar.error(errorParser(error));
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
            <DialogTitle>Register your asset ({asset?.name})</DialogTitle>
            <DialogContent>
                <Typography variant='body2' as='p'>
                    If an asset is <b>registered</b>, its exact name, ticker, domain and other details are visible on the blockchain.
                    <br />
                    To register your asset, follow these steps:
                    <br />
                    <br />
                </Typography>
                <Typography variant='body2' as='p'>
                    1. Create a file named <b>liquid-asset-proof-{asset?.assetId}</b> with no file extension.
                </Typography>
                <br />
                <Typography variant='body2' as='p'>
                    2. Add the following contents to the file and save it:
                    <br />
                    <blockquote>
                        Authorize linking the domain name <b>{asset?.domain}</b> to the Liquid asset <b>{asset?.assetId}</b>
                    </blockquote>
                    Alternatively, download the generated <b>proof file</b> below.
                </Typography>
                <br />
                <Typography variant='body2' as='p'>
                    3. Place the file on your server/hosting so it is accessible via the following link:
                    <br />
                    <a
                        style={{ textDecoration: 'none', color: '#3E9CD9' }}
                        href={`https://${asset?.domain}/.well-known/liquid-asset-proof-${asset?.assetId}`}
                        target='_blank'
                        rel="noreferrer"
                    >
                        https://{asset?.domain}/.well-known/liquid-asset-proof-{asset?.assetId}
                    </a>
                </Typography>
                <br />
                <Typography variant='body2' as='p'>
                    4. After you have placed the file, click on the <b>Register</b> button to register the asset.
                    <br />
                    Any error will likely be because the proof file cannot be read correctly (either missing or not containing the correct data).
                </Typography>
                <br />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant='contained' color='secondary' onClick={generateProofFile}>Download proof file</Button>
                <SubmitButton
                    text='Register'
                    onClick={onSubmit}
                    disabled={loading}
                    loading={loading}
                />
            </DialogActions>
        </Dialog>
    )
}

export default RegisterAssetDialog