import { FormControlLabel, Checkbox as MUIChecbox } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

const Checkbox = (props) => {
    return (
        <FormControlLabel
            control={
                <Controller
                    name={props.name}
                    control={props.control}
                    disabled={props.disabled}
                    render={({ field: props }) => (
                        <MUIChecbox
                            {...props}
                            checked={!!props.value}
                            disabled={props.disabled}
                            defaultChecked={props.defaultChecked}
                            onChange={(e) => props.onChange(e.target.checked)}
                        />
                    )}
                />
            }
            label={props.label}
        />
    )
}

export default Checkbox