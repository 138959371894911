import makeStyles from '@mui/styles/makeStyles';

const formPanelRowStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        padding: '0',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    titleColumn: {
        flexBasis: '20%',
        padding: '1rem',
    },
    titleContent: {
        fontSize: '1rem',
    },
    titleRow: {
        width: '100%',
    },
    contentColumn: {
        flexBasis: '50%',
        minWidth: '0',
        padding: '1rem',
    },
    descriptionColumn: {
        flexBasis: '30%',
        padding: '1rem',
        borderLeft: '1px dotted #DDD',
        [theme.breakpoints.down('sm')]: {
            border: 'none',
            padding: '0 1rem',
        },
    },
    descriptionContent: {
        //  backgroundColor: '#F7F7F7',
        color: '#555',
        fontSize: '0.8rem',
        borderRadius: '4px',
        padding: '0.5rem',
    },
    smallScreenWidth: {
        width: '65%',
    },
}));

export default formPanelRowStyles;
