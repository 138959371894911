import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from './Drawer';
import useStyles from './styles/subMenuStyles';

import StyledTabs from './Tabs/StyledTabs';
import StyledSideTab from './Tabs/StyledSideTab';

import { NavLink } from 'react-router-dom';
function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const SubMenu = (props) => {
    const { orientation, tabs, hasDrawer, title } = props;

    const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const isVerticalTab = orientation === 'vertical' && mdUp;

    const classes = useStyles();

    return (
        <>
            {(isVerticalTab ||
                (orientation !== 'vertical' && (mdUp || !hasDrawer))) && (
                <StyledTabs
                    orientation={orientation || 'horizontal'}
                    scrollButtons="auto"
                    value={props.value}
                    onChange={props.onChange}
                    variant="scrollable"
                    aria-label="simple tabs example"
                    className={isVerticalTab ? classes.tabs : ''}
                    style={{
                        backgroundColor: '#fff',
                        color: '#000',
                    }}
                    TabIndicatorProps={{
                        style: {
                            background: isVerticalTab
                                ? '#3E9CD9'
                                : 'transparent',
                        },
                    }}
                >
                    {/* Render the tabs */}
                    {tabs &&
                        tabs.map(
                            (tab, i) =>
                                tab.label &&
                                tab.display && ( // don't render empty labels
                                    <StyledSideTab
                                        key={i}
                                        isVerticalTab={isVerticalTab}
                                        label={
                                            <span
                                                style={{
                                                    color:
                                                        tab.disabled &&
                                                        '#9EBEDD',
                                                }}
                                            >
                                                {tab.label}
                                            </span>
                                        }
                                        {...a11yProps(i)} // tab.id
                                        to={tab.path}
                                        component={NavLink}
                                        //disabled={tab.disabled}
                                    />
                                )
                        )}
                </StyledTabs>
            )}
            {!mdUp && (orientation === 'vertical' || hasDrawer) && (
                <Drawer title={title} selected={props.value} tabs={tabs} />
            )}
        </>
    );
};

SubMenu.propTypes = {
    orientation: PropTypes.string,
    tabs: PropTypes.array.isRequired,
    hasDrawer: PropTypes.bool,
    title: PropTypes.string,
};

export default SubMenu;
