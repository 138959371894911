import React from 'react';

import Paper from '@mui/material/Paper';

const StyledPaper = (props) => {

    const { 
        zoom, padding, children, 
        marginTop, marginBottom, className
    } = props

    let customClassName = 'box '
    if (zoom) {
        customClassName += 'zoom'
    }

    if (className) {
        customClassName += ` ${className}`
    }

    let additionalPadding = (padding) ? padding : 0

    return (
        <Paper
            variant="outlined"
            className={`${customClassName}`}
            style={{
                position: 'relative',
                borderRadius: 15,
                padding: 10 + additionalPadding,
                paddingBottom: 20,
                height: '100%',
                marginTop: marginTop || 'initial',
                marginBottom: marginBottom || 'initial',
            }}
        >
            {children}
        </Paper>
    )
}

export default StyledPaper