import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import profileReducer from './profile/profileReducer';
import capTableReducer from './capTable/capTableReducer';
import categoryReducer from './category/categoryReducer';
import documentReducer from './document/documentReducer';
import sponsorAdvisorUpdateReducer from './sponsorAdvisorUpdate/sponsorAdvisorUpdateReducer';
import disclosureRequirementReducer from './disclosureRequirement/disclosureRequirementReducer';
import listTemplateReducer from './template/listTemplateReducer';
import listingDetailReducer from './listingDetail/listingDetailReducer';
import exchangeReducer from './exchange/exchangeReducer';
import adminReducer from './admin/adminReducer';
import fundingReducer from './funding/fundingReducer';
import authReducer from './auth/authReducer';
import dialogReducer from './dialog/dialogReducer';
import drawerReducer from './drawer/drawerReducer';
import investorDocumentReducer from './investorDocuments/investorDocumentReducer';
import investorNotificationReducer from './investorNotification/investorNotificationReducer';
import blockchainReducer from './blockchain/blockchainReducer';
import offeringReducer from './interimOffering/offeringReducer';
import transferReducer from './transfer/transferReducer';
import eventReducer from './event/eventReducer';
import subscriptionAgreementReducer from './subscriptionAgreements/subscriptionAgreementReducer';
import investorInviteReducer from './investorInvite/investorInviteReducer';
import snippetReducer from './snippet/snippetReducer';
import shareholderReducer from './shareholder/shareholderReducer';
import kycReducer from './kyc/kycReducer';
import paymentReducer from './payment/paymentReducer';
import issuerInviteReducer from './issuerInvite/issuerInviteReducer';
import transferAgentReducer from './transferAgent/transferAgentReducer';
// import liquidBlockstreamReducer from './liquidBlocksteam/liquidBlockstreamReducer';
import assetIssuanceReducer from './assetIssuance/assetIssuanceReducer';


const rootReducer = combineReducers({
    profile: profileReducer,
    capTable: capTableReducer,
    categories: categoryReducer,
    documents: documentReducer,
    sponsorAdvisorUpdates: sponsorAdvisorUpdateReducer,
    disclosureRequirements: disclosureRequirementReducer,
    listTemplates: listTemplateReducer,
    listingDetails: listingDetailReducer,
    exchanges: exchangeReducer,
    admin: adminReducer,
    auth: authReducer,
    dialog: dialogReducer,
    drawer: drawerReducer,
    funding: fundingReducer,
    investorDocuments: investorDocumentReducer,
    investorNotifications: investorNotificationReducer,
    transfers: transferReducer,
    blockchain: blockchainReducer,
    offerings: offeringReducer,
    events: eventReducer,
    subscriptionAgreements: subscriptionAgreementReducer,
    investorInvites: investorInviteReducer,
    snippets: snippetReducer,
    shareholders: shareholderReducer,
    kyc: kycReducer,
    payment: paymentReducer,
    issuerInvite: issuerInviteReducer,
    transferAgent: transferAgentReducer,
    // liquidBlockstream: liquidBlockstreamReducer,
    assetIssuance: assetIssuanceReducer,
});

const composeEnhancers =
    (process.env.NODE_ENV !== 'production' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

const configureStore = () =>
    createStore(
        rootReducer,
        undefined,
        composeEnhancers(applyMiddleware(thunk))
    );

export default configureStore;
