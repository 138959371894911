import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import authStyles from '../authStyles';
import listTemplateStyles from '../../../components/styles/listTemplateStyles';
import { useSnackbar } from 'notistack';
import * as config from './../../../config';
import request from '../../../utilities/request';
import Button from '@mui/material/Button';
import FormikSelect from '../../../components/Formik/FormikSelect';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import * as listingDetailActions from '../../../store/listingDetail/listingDetailActions';
import * as exchangeActions from '../../../store/exchange/exchangeActions';
import { DateFormatter } from '../../../utilities/formatters';
import TextField from '../../../components/TextField/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';

import FormikDatePicker from '../../../components/Formik/FormikDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import LockedTile from '../../../components/LockedTile';
import { SUBSCRIPTION_ENTERPRISE } from '../../../constants/profile';

let initialValues = {};
const ListingDetails = (props) => {
    const classes = authStyles();
    const listClasses = listTemplateStyles();
    const { enqueueSnackbar } = useSnackbar();
    let demo;
    let listing;
    let vEmail;
    try {
        demo = props.profile.demo;
        vEmail = localStorage
            .getItem('user-email')
            .includes('veronica@curzioresearch.com');
        listing = props.profile.scope === 'list';
    } catch (e) {
        demo = null;
        listing = null;
        vEmail = null;
    }

    const [listingDetailsDialog, setListingDetailsDialog] = useState(false);
    const [exchange, setExchange] = useState(1);
    const [securityType, setSecurityType] = useState('equity');
    const [key, setKey] = useState(0);
    const [
        listingDetailsInitialValues,
        setListingDetailsInitialValues,
    ] = useState({});

    const [listingDate, setListingDate] = useState(new Date());
    const [listingData, setListingData] = useState();

    useEffect(props.listingDetailActions.getListingDetails, []);
    useEffect(props.exchangeActions.getExchanges, []);
    useEffect(() => {
        if (props.listingDetails.data) {
            setListingData(true);
            setListingDetailsInitialValues(props.listingDetails.data);

            if (
                props.listingDetails.data.security_type !== null &&
                props.listingDetails.data.security_type !== undefined
            ) {
                setSecurityType(props.listingDetails.data.security_type);
            }

            if (
                props.listingDetails.data.exchange_id !== null &&
                props.listingDetails.data.exchange_id !== undefined
            ) {
                setExchange(props.listingDetails.data.exchange_id);
            }

            /*async function fetchData() {
                const response = await request.get(
                    `${config.API_BASE_URL}/exchanges`
                );

                setExchanges(response.data);
            }

            fetchData();*/
        }
    }, [props.listingDetails.data]);

    const displayListingDetailsDialog = () => {
        if (
            listingDetailsInitialValues.listing_date !== null &&
            listingDetailsInitialValues.listing_date !== undefined
        ) {
            setListingDate(listingDetailsInitialValues.listing_date);
        }

        setListingDetailsDialog(true);
        setKey(key + 1);
    };

    const closeDialog = () => {
        setKey(key + 1);
        setListingDetailsDialog(false);
    };

    const handleDateChange = (date, type) => {
        let newDate = DateFormatter({
            value: date,
            format: 'date',
        });

        switch (type) {
            case 'listing_date':
                setListingDate(newDate);
                break;
            default:
                setListingDate(newDate);
                break;
        }
    };

    const changeExchange = (e) => {
        setExchange(e.target.getAttribute('data-value'));
    };

    const changeSecurityType = (e) => {
        setSecurityType(e.target.getAttribute('data-value'));
    };

    return (
        <LockedTile requiredPlan={SUBSCRIPTION_ENTERPRISE}>
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {}}
            >
                <Form>
                    <Typography component="div" style={{ textAlign: 'right' }}>
                        <h3>
                            Listing Status:{' '}
                            <b style={{ color: '#3E9CD9' }}>Active</b>
                        </h3>
                    </Typography>
                    {(demo || listing) && listingData ? (
                        <div>
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={3} md={3}>
                                    {vEmail ? (
                                        <Field
                                            component={FormikSelect}
                                            name="exchange_id"
                                            variant="outlined"
                                            fullWidth
                                            label="Exchange"
                                            defaultValue="merj"
                                        >
                                            <MenuItem key={'merj'} value={'merj'}>
                                                MERJ
                                            </MenuItem>
                                        </Field>
                                    ) : (
                                        <Field
                                            component={FormikSelect}
                                            name="exchange_id"
                                            variant="outlined"
                                            fullWidth
                                            label="Exchange"
                                            defaultValue={exchange}
                                        >
                                            {props.exchanges &&
                                                props.exchanges.data &&
                                                props.exchanges.data.map(
                                                    (exchange) => (
                                                        <MenuItem
                                                            key={exchange.id}
                                                            value={exchange.id}
                                                            onClick={changeExchange}
                                                        >
                                                            {exchange.name}
                                                        </MenuItem>
                                                    )
                                                )}
                                        </Field>
                                    )}
                                </Grid>
                                <Grid item xs={6} sm={3} md={3}>
                                    {vEmail ? (
                                        <Field
                                            component={FormikSelect}
                                            name="security_type"
                                            variant="outlined"
                                            label="Security Type"
                                            fullWidth
                                            defaultValue={securityType}
                                        >
                                            <MenuItem
                                                key={'equity'}
                                                value={'equity'}
                                                onClick={changeSecurityType}
                                            >
                                                {'Equity'}
                                            </MenuItem>
                                        </Field>
                                    ) : (
                                        <Field
                                            component={FormikSelect}
                                            name="security_type"
                                            variant="outlined"
                                            fullWidth
                                            label="Security type"
                                            defaultValue={securityType}
                                        >
                                            <MenuItem
                                                key={'equity'}
                                                value={'equity'}
                                                onClick={changeSecurityType}
                                            >
                                                {'Equity'}
                                            </MenuItem>
                                            <MenuItem
                                                key={'debt'}
                                                value={'debt'}
                                                onClick={changeSecurityType}
                                            >
                                                {'Debt'}
                                            </MenuItem>
                                            <MenuItem
                                                key={'derivative'}
                                                value={'derivative'}
                                                onClick={changeSecurityType}
                                            >
                                                {'Derivative'}
                                            </MenuItem>
                                        </Field>
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    sm={6}
                                    md={6}
                                    className={classes.alignEnd}
                                >
                                    <Button
                                        component="label"
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() =>
                                            displayListingDetailsDialog()
                                        }
                                    >
                                        <b>Edit</b>
                                    </Button>
                                </Grid>
                            </Grid>

                            <br />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Paper elevation={3} className={classes.paper}>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                className={
                                                    listClasses.listingDetailsTitle
                                                }
                                            >
                                                Ticker
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                className={
                                                    listClasses.listingDetailsValue
                                                }
                                            >
                                                {demo || listing
                                                    ? listingDetailsInitialValues.ticker
                                                        ? listingDetailsInitialValues.ticker
                                                        : ''
                                                    : 'No data'}
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                className={
                                                    listClasses.listingDetailsTitle
                                                }
                                            >
                                                Lisitng Advisor
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                className={
                                                    listClasses.listingDetailsValue
                                                }
                                            >
                                                {demo || listing ? (
                                                    <span>
                                                        {
                                                            listingDetailsInitialValues.sponsor_advisor
                                                        }
                                                        <br />

                                                        {listingDetailsInitialValues.sponsor_advisor_contact ? (
                                                            <span>
                                                                {' '}
                                                                (Contact:{' '}
                                                                {
                                                                    listingDetailsInitialValues.sponsor_advisor_contact
                                                                }
                                                                )
                                                            </span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </span>
                                                ) : (
                                                    'No data'
                                                )}
                                            </Grid>
                                        </Grid>

                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                className={
                                                    listClasses.listingDetailsTitle
                                                }
                                            >
                                                Contact Email
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                className={
                                                    listClasses.listingDetailsValue
                                                }
                                            >
                                                {demo || listing
                                                    ? listingDetailsInitialValues.contact_email
                                                        ? listingDetailsInitialValues.contact_email
                                                        : ''
                                                    : 'No data'}
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                className={
                                                    listClasses.listingDetailsTitle
                                                }
                                            >
                                                Key Contact
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                className={
                                                    listClasses.listingDetailsValue
                                                }
                                            >
                                                {demo || listing
                                                    ? listingDetailsInitialValues.key_contact
                                                        ? listingDetailsInitialValues.key_contact
                                                        : ''
                                                    : 'No data'}
                                            </Grid>
                                        </Grid>

                                        {/*    <ListElement>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <LabelIcon
                                                        fontSize="large"
                                                        style={{ color: '#3E9CD9' }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="p"
                                                            style={{
                                                                color: '#3E9CD9',
                                                            }}
                                                        >
                                                            Ticker
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <b
                                                                style={{
                                                                    fontSize:
                                                                        '1rem',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                {demo || listing
                                                                    ? listingDetailsInitialValues.ticker
                                                                        ? listingDetailsInitialValues.ticker
                                                                        : ''
                                                                    : 'No data'}
                                                            </span>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        </ListElement>

                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                textAlign: 'left',
                                            }}
                                        >
                                            <ListElement>
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <ContactsIcon
                                                            fontSize="large"
                                                            style={{
                                                                color: '#3E9CD9',
                                                            }}
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                variant="body2"
                                                                component="p"
                                                                style={{
                                                                    color:
                                                                        '#3E9CD9',
                                                                }}
                                                            >
                                                                Listing Advisor:
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <React.Fragment>
                                                                <b
                                                                    style={{
                                                                        fontSize:
                                                                            '1rem',
                                                                        color:
                                                                            'black',
                                                                    }}
                                                                >
                                                                    {demo ||
                                                                    listing ? (
                                                                        <span>
                                                                            {
                                                                                listingDetailsInitialValues.sponsor_advisor
                                                                            }
                                                                            <br />

                                                                            {listingDetailsInitialValues.sponsor_advisor_contact ? (
                                                                                <span>
                                                                                    {' '}
                                                                                    (Contact:{' '}
                                                                                    {
                                                                                        listingDetailsInitialValues.sponsor_advisor_contact
                                                                                    }

                                                                                    )
                                                                                </span>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </span>
                                                                    ) : (
                                                                        'No data'
                                                                    )}
                                                                </span>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItem>
                                            </ListElement>

                                            <ListElement>
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <PersonIcon
                                                            fontSize="large"
                                                            style={{
                                                                color: '#3E9CD9',
                                                            }}
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                variant="body2"
                                                                component="p"
                                                                style={{
                                                                    color:
                                                                        '#3E9CD9',
                                                                }}
                                                            >
                                                                Key Contact:
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <React.Fragment>
                                                                <b
                                                                    style={{
                                                                        fontSize:
                                                                            '1rem',
                                                                        color:
                                                                            'black',
                                                                    }}
                                                                >
                                                                    {demo || listing
                                                                        ? listingDetailsInitialValues.key_contact
                                                                            ? listingDetailsInitialValues.key_contact
                                                                            : ''
                                                                        : 'No data'}
                                                                </span>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItem>
                                            </ListElement>

                                            <ListElement>
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <DraftsIcon
                                                            fontSize="large"
                                                            style={{
                                                                color: '#3E9CD9',
                                                            }}
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                variant="body2"
                                                                component="p"
                                                                style={{
                                                                    color:
                                                                        '#3E9CD9',
                                                                }}
                                                            >
                                                                Contact email:
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <React.Fragment>
                                                                <b
                                                                    style={{
                                                                        fontSize:
                                                                            '1rem',
                                                                        color:
                                                                            'black',
                                                                    }}
                                                                >
                                                                    {demo || listing
                                                                        ? listingDetailsInitialValues.contact_email
                                                                            ? listingDetailsInitialValues.contact_email
                                                                            : ''
                                                                        : 'No data'}
                                                                </span>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItem>
                                            </ListElement>
                                        </Grid>*/}
                                    </Paper>
                                </Grid>{' '}
                                <Grid item xs={12} sm={6} md={6}>
                                    <Paper elevation={3} className={classes.paper}>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                className={
                                                    listClasses.listingDetailsTitle
                                                }
                                            >
                                                Date of Listing{' '}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                className={
                                                    listClasses.listingDetailsValue
                                                }
                                            >
                                                {demo || listing ? (
                                                    <span>
                                                        {listingDetailsInitialValues.listing_date
                                                            ? DateFormatter({
                                                                value:
                                                                    listingDetailsInitialValues.listing_date,
                                                                format: 'date',
                                                            })
                                                            : ''}
                                                    </span>
                                                ) : (
                                                    'No data'
                                                )}
                                            </Grid>
                                        </Grid>

                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                className={
                                                    listClasses.listingDetailsTitle
                                                }
                                            >
                                                Type
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                className={
                                                    listClasses.listingDetailsValue
                                                }
                                            >
                                                {demo || listing ? (
                                                    <span>
                                                        {listingDetailsInitialValues.security_type
                                                            ? listingDetailsInitialValues.security_type
                                                                .charAt(0)
                                                                .toUpperCase() +
                                                            listingDetailsInitialValues.security_type.slice(
                                                                1
                                                            )
                                                            : ''}
                                                    </span>
                                                ) : (
                                                    'No data'
                                                )}
                                            </Grid>
                                        </Grid>

                                        <Grid container>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                className={
                                                    listClasses.listingDetailsTitle
                                                }
                                            >
                                                Company Registered Address
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                className={
                                                    listClasses.listingDetailsValue
                                                }
                                                style={{ textAlign: 'right' }}
                                            >
                                                {demo || listing ? (
                                                    <span>
                                                        {listingDetailsInitialValues.company_registered_address
                                                            ? listingDetailsInitialValues.company_registered_address
                                                            : ''}
                                                    </span>
                                                ) : (
                                                    'No data'
                                                )}
                                            </Grid>
                                        </Grid>

                                        <Grid container>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                className={
                                                    listClasses.listingDetailsTitle
                                                }
                                            >
                                                ISIN
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                className={
                                                    listClasses.listingDetailsValue
                                                }
                                            >
                                                {demo || listing ? (
                                                    <span>
                                                        {listingDetailsInitialValues.isin
                                                            ? listingDetailsInitialValues.isin
                                                            : ''}
                                                    </span>
                                                ) : (
                                                    'No data'
                                                )}
                                            </Grid>
                                        </Grid>

                                        <Grid container>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                className={
                                                    listClasses.listingDetailsTitle
                                                }
                                            >
                                                Legal Advisor
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                className={
                                                    listClasses.listingDetailsValue
                                                }
                                            >
                                                {demo || listing ? (
                                                    <span>
                                                        {listingDetailsInitialValues.legal_advisor
                                                            ? listingDetailsInitialValues.legal_advisor
                                                            : ''}
                                                        <br />
                                                        {listingDetailsInitialValues.legal_advisor_contact ? (
                                                            <span>
                                                                (Contact:{' '}
                                                                {
                                                                    listingDetailsInitialValues.legal_advisor_contact
                                                                }
                                                                )
                                                            </span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </span>
                                                ) : (
                                                    'No data'
                                                )}
                                            </Grid>
                                        </Grid>

                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                className={
                                                    listClasses.listingDetailsTitle
                                                }
                                            >
                                                Transfer Agent and Technology
                                                Platform
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                className={
                                                    listClasses.listingDetailsValue
                                                }
                                            >
                                                {demo || listing ? (
                                                    <span>
                                                        {listingDetailsInitialValues.transfer_agent
                                                            ? listingDetailsInitialValues.transfer_agent
                                                            : ''}
                                                        <br />
                                                        {listingDetailsInitialValues.transfer_agent_contact ? (
                                                            <span>
                                                                (Contact:{' '}
                                                                {
                                                                    listingDetailsInitialValues.transfer_agent_contact
                                                                }
                                                                )
                                                            </span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </span>
                                                ) : (
                                                    'No data'
                                                )}
                                            </Grid>
                                        </Grid>

                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                className={
                                                    listClasses.listingDetailsTitle
                                                }
                                            >
                                                Tax Accountant
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                className={
                                                    listClasses.listingDetailsValue
                                                }
                                            >
                                                {demo || listing ? (
                                                    <span>
                                                        {listingDetailsInitialValues.tax_accountant
                                                            ? listingDetailsInitialValues.tax_accountant
                                                            : ''}
                                                        <br />
                                                        {listingDetailsInitialValues.tax_accountant_contact ? (
                                                            <span>
                                                                (Contact:{' '}
                                                                {
                                                                    listingDetailsInitialValues.tax_accountant_contact
                                                                }
                                                                )
                                                            </span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </span>
                                                ) : (
                                                    'No data'
                                                )}
                                            </Grid>
                                        </Grid>
                                        {/*<ListElement>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <TodayIcon
                                                        fontSize="large"
                                                        style={{ color: '#3E9CD9' }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="p"
                                                            style={{
                                                                color: '#3E9CD9',
                                                            }}
                                                        >
                                                            Date of Listing
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <b
                                                                style={{
                                                                    fontSize:
                                                                        '1rem',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                {demo || listing ? (
                                                                    <span>
                                                                        {listingDetailsInitialValues.listing_date
                                                                            ? DateFormatter(
                                                                                {
                                                                                    value:
                                                                                        listingDetailsInitialValues.listing_date,
                                                                                    format:
                                                                                        'date',
                                                                                }
                                                                            )
                                                                            : ''}
                                                                    </span>
                                                                ) : (
                                                                    'No data'
                                                                )}
                                                            </span>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <CategoryIcon
                                                        fontSize="large"
                                                        style={{ color: '#3E9CD9' }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="p"
                                                            style={{
                                                                color: '#3E9CD9',
                                                            }}
                                                        >
                                                            Type:
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <b
                                                                style={{
                                                                    fontSize:
                                                                        '1rem',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                {demo || listing ? (
                                                                    <span>
                                                                        {listingDetailsInitialValues.security_type
                                                                            ? listingDetailsInitialValues.security_type
                                                                                .charAt(
                                                                                    0
                                                                                )
                                                                                .toUpperCase() +
                                                                            listingDetailsInitialValues.security_type.slice(
                                                                                1
                                                                            )
                                                                            : ''}
                                                                    </span>
                                                                ) : (
                                                                    'No data'
                                                                )}
                                                            </span>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <BusinessIcon
                                                        fontSize="large"
                                                        style={{ color: '#3E9CD9' }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="p"
                                                            style={{
                                                                color: '#3E9CD9',
                                                            }}
                                                        >
                                                            Company Registered
                                                            Address:
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <b
                                                                style={{
                                                                    fontSize:
                                                                        '1rem',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                {demo || listing ? (
                                                                    <span>
                                                                        {listingDetailsInitialValues.company_registered_address
                                                                            ? listingDetailsInitialValues.company_registered_address
                                                                            : ''}
                                                                    </span>
                                                                ) : (
                                                                    'No data'
                                                                )}
                                                            </span>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>

                                            <ListItem>
                                                <ListItemAvatar>
                                                    <VerifiedUserIcon
                                                        fontSize="large"
                                                        style={{ color: '#3E9CD9' }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="p"
                                                            style={{
                                                                color: '#3E9CD9',
                                                            }}
                                                        >
                                                            ISIN
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <b
                                                                style={{
                                                                    fontSize:
                                                                        '1rem',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                {demo || listing ? (
                                                                    <span>
                                                                        {listingDetailsInitialValues.isin
                                                                            ? listingDetailsInitialValues.isin
                                                                            : ''}
                                                                    </span>
                                                                ) : (
                                                                    'No data'
                                                                )}
                                                            </span>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>

                                            <ListItem>
                                                <ListItemAvatar>
                                                    <GavelIcon
                                                        fontSize="large"
                                                        style={{ color: '#3E9CD9' }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="p"
                                                            style={{
                                                                color: '#3E9CD9',
                                                            }}
                                                        >
                                                            Legal Advisor:
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <b
                                                                style={{
                                                                    fontSize:
                                                                        '1rem',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                {demo || listing ? (
                                                                    <span>
                                                                        {listingDetailsInitialValues.legal_advisor
                                                                            ? listingDetailsInitialValues.legal_advisor
                                                                            : ''}
                                                                        <br />
                                                                        {listingDetailsInitialValues.legal_advisor_contact ? (
                                                                            <span>
                                                                                (Contact:{' '}
                                                                                {
                                                                                    listingDetailsInitialValues.legal_advisor_contact
                                                                                }
                                                                                )
                                                                            </span>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </span>
                                                                ) : (
                                                                    'No data'
                                                                )}
                                                            </span>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>

                                            <ListItem>
                                                <ListItemAvatar>
                                                    <TransferWithinAStationIcon
                                                        fontSize="large"
                                                        style={{ color: '#3E9CD9' }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="p"
                                                            style={{
                                                                color: '#3E9CD9',
                                                            }}
                                                        >
                                                            Transfer Agent and
                                                            Technology Platform:
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <b
                                                                style={{
                                                                    fontSize:
                                                                        '1rem',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                {demo || listing ? (
                                                                    <span>
                                                                        {listingDetailsInitialValues.transfer_agent
                                                                            ? listingDetailsInitialValues.transfer_agent
                                                                            : ''}
                                                                        <br />
                                                                        {listingDetailsInitialValues.transfer_agent_contact ? (
                                                                            <span>
                                                                                (Contact:{' '}
                                                                                {
                                                                                    listingDetailsInitialValues.transfer_agent_contact
                                                                                }
                                                                                )
                                                                            </span>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </span>
                                                                ) : (
                                                                    'No data'
                                                                )}
                                                            </span>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>

                                            <ListItem>
                                                <ListItemAvatar>
                                                    <SupervisorAccountIcon
                                                        fontSize="large"
                                                        style={{ color: '#3E9CD9' }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="p"
                                                            style={{
                                                                color: '#3E9CD9',
                                                            }}
                                                        >
                                                            Tax accountant:
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <b
                                                                style={{
                                                                    fontSize:
                                                                        '1rem',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                {demo || listing ? (
                                                                    <span>
                                                                        {listingDetailsInitialValues.tax_accountant
                                                                            ? listingDetailsInitialValues.tax_accountant
                                                                            : ''}
                                                                        <br />
                                                                        {listingDetailsInitialValues.tax_accountant_contact ? (
                                                                            <span>
                                                                                (Contact:{' '}
                                                                                {
                                                                                    listingDetailsInitialValues.tax_accountant_contact
                                                                                }
                                                                                )
                                                                            </span>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </span>
                                                                ) : (
                                                                    'No data'
                                                                )}
                                                            </span>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        </ListElement>*/}
                                    </Paper>
                                </Grid>
                            </Grid>
                        </div>
                    ) : demo || listing ? (
                        <Grid container item xs={12} justifyContent="center">
                            <CircularProgress />
                        </Grid>
                    ) : null}

                    <Dialog
                        //maxWidth="lg"
                        fullWidth
                        open={listingDetailsDialog}
                        onBackdropClick={(e) => setListingDetailsDialog(false)}
                    >
                        <DialogTitle className={classes.dialogTitle}>
                            Listing Details
                        </DialogTitle>
                        <DialogContent>
                            <Grid container justifyContent="center">
                                <Grid item xs={12} md={12}>
                                    <Formik
                                        /*  validationSchema={
                                            listingDetailsValidationSchema
                                        }*/
                                        initialValues={listingDetailsInitialValues}
                                        onSubmit={(
                                            values,
                                            { setSubmitting, resetForm }
                                        ) => {
                                            values['listing_date'] = listingDate;
                                            values['exchange_id'] = exchange;
                                            values['security_type'] = securityType;
                                            console.log(values);
                                            request
                                                .post(
                                                    `${config.API_BASE_URL}/listing-details/edit`,
                                                    values
                                                )
                                                .then((response) => {
                                                    enqueueSnackbar(
                                                        'Information has been updated successfully',
                                                        {
                                                            variant: 'success',
                                                        }
                                                    );
                                                    props.listingDetailActions.getListingDetails();
                                                    setListingDetailsDialog(false);
                                                })
                                                .catch(() => {});
                                        }}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form
                                                className={classes.form}
                                                noValidate
                                            >
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    name="ticker"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    label="Ticker"
                                                ></Field>
                                                <br /> <br />
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    name="sponsor_advisor"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    label="Listing Advisor"
                                                ></Field>
                                                <br /> <br />
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    name="sponsor_advisor_contact"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    label="Listing Advisor Contact"
                                                    placeholder="Name email@adress.com"
                                                ></Field>
                                                <br /> <br />
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    name="key_contact"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    label="Key Contact"
                                                ></Field>
                                                <br /> <br />
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    name="contact_email"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    label="Contact Email"
                                                ></Field>
                                                <br /> <br />
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <Field
                                                        component={FormikDatePicker}
                                                        name="listing_date"
                                                        value={listingDate}
                                                        label="Listing Date"
                                                        onChange={(date) =>
                                                            handleDateChange(
                                                                date,
                                                                'listing_date'
                                                            )
                                                        }
                                                        format="MM/dd/yyyy"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </LocalizationProvider>
                                                <br /> <br />
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    name="legal_advisor"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    label="Legal Advisor"
                                                ></Field>
                                                <br /> <br />
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    name="legal_advisor_contact"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    label="Legal Advisor Contact"
                                                    placeholder="Name email@adress.com"
                                                ></Field>
                                                <br /> <br />
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    name="transfer_agent"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    label="Transfer Agent and Technology Platform"
                                                ></Field>
                                                <br /> <br />
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    name="transfer_agent_contact"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    label="Transfer Agent and Technology Platform Contact"
                                                    placeholder="Name email@adress.com"
                                                ></Field>
                                                <br /> <br />
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    name="tax_accountant"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    label="Tax Accountant"
                                                ></Field>
                                                <br /> <br />
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    name="tax_accountant_contact"
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    label="Tax Accountant Contact"
                                                    placeholder="Name email@adress.com"
                                                ></Field>
                                                <br /> <br />
                                                <DialogActions
                                                    style={{
                                                        marginTop: '1.5rem',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Button
                                                        color="secondary"
                                                        variant="outlined"
                                                        onClick={() => {
                                                            closeDialog();
                                                        }}
                                                    >
                                                        <b>Cancel</b>
                                                    </Button>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={isSubmitting}
                                                    >
                                                        <b>Save</b>
                                                    </Button>
                                                </DialogActions>
                                            </Form>
                                        )}
                                    </Formik>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </Form>
            </Formik>
        </LockedTile>
    );
};

const mapStateToProps = ({ profile, listingDetails, exchanges }) => {
    return {
        profile: profile,
        listingDetails: listingDetails,
        exchanges: exchanges,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        listingDetailActions: bindActionCreators(
            listingDetailActions,
            dispatch
        ),
        exchangeActions: bindActionCreators(exchangeActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingDetails);
