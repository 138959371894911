import useSWR from "swr"
import { API_BASE_URL } from "../../config"
import fetcher from "../../utilities/fetcher"

const useHoldersOutsideCategory = (categoryId) => {
    const { data, error, mutate } = useSWR(() => categoryId ? `${API_BASE_URL}/blockchain/holdersOutsideCategory/${categoryId}` : null, fetcher)

    return {
        holdersOutsideCategory: data || [],
        holdersOutsideCategoryLoading: !error && !data,
        holdersOutsideCategoryError: error,
        updateHoldersOutsideCategory: () => mutate()
    }
}

export default useHoldersOutsideCategory