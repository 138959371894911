import React from 'react'
import { Button, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { connect, useDispatch } from 'react-redux';
import find from 'lodash/find'

import AddIcon from '@mui/icons-material/Add';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ReorderIcon from '@mui/icons-material/Reorder';
import SendIcon from '@mui/icons-material/Send';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

import useAmpAssets from '../../../api/blockchain/useAmpAssets';
import { DISTRIBUTE_ASSETS_STEP, ISSUE_ASSET_STEP } from '../../../constants/blockchain';
import { BLOCKSTREAM_EXPLORER_URL } from '../../../config';
import { setIssuanceStep } from '../../../store/assetIssuance/assetIssuanceActions';

import ActionDropdown from './Utils/ActionDropdown';
import RegisterAssetDialog from './Assets/RegisterAssetDialog';
import ReissueDialog from './Assets/ReissueDialog';
import BurnDialog from './Assets/BurnDialog';
import BCTable from './Utils/BCTable';
import AssetInfoDialog from './Assets/AssetInfoDialog';
import IssueDialog from './Assets/IssueDialog';
import SendDialog from './Assets/SendDialog';
import DataLoading from './Utils/DataLoading';
import NoData from './Utils/NoData';
import DataError from './Utils/DataError';

const Assets = ({ issuanceStep, issuanceItem }) => {
    const [openAssetInfo, setOpenAssetInfo] = React.useState(false);
    const [openIssue, setOpenIssue] = React.useState(false);
    const [openSend, setOpenSend] = React.useState(false);
    const [openRegister, setOpenRegister] = React.useState(false);
    const [openReissue, setOpenReissue] = React.useState(false);
    const [openBurn, setOpenBurn] = React.useState(false);

    const [activeAsset, setActiveAsset] = React.useState(null);

    const { ampAssets, ampAssetsLoading, ampAssetsError, ampAssetsValidating, updateAmpAssets } = useAmpAssets()
    const dispatch = useDispatch()

    React.useEffect(() => {
        if (issuanceStep === ISSUE_ASSET_STEP) {
            handleOpenIssue()
        }
    }, [issuanceStep])

    // Open the "Send" dialog for an asset to facilitiate sending
    React.useEffect(() => {
        if (issuanceStep === DISTRIBUTE_ASSETS_STEP && issuanceItem) {
            handleOpenSend(find(ampAssets, {
                name: issuanceItem
            }))
        }
    }, [issuanceStep, issuanceItem])

    const handleOpenAssetInfo = (asset) => {
        setOpenAssetInfo(true);
        setActiveAsset(asset)
    };

    const handleCloseAssetInfo = () => {
        setOpenAssetInfo(false);
        setActiveAsset(null)
    };

    const handleOpenIssue = () => {
        setOpenIssue(true);
    };

    const handleCloseIssue = () => {
        setOpenIssue(false);
    };

    const handleOpenSend = (asset) => {
        setOpenSend(true);
        setActiveAsset(asset)
    };

    const handleCloseSend = () => {
        setOpenSend(false);
        setActiveAsset(null);
        dispatch(setIssuanceStep({ item: null }))
    };

    const handleOpenRegister = (asset) => {
        setOpenRegister(true);
        setActiveAsset(asset)
    };

    const handleCloseRegister = () => {
        setOpenRegister(false);
        setActiveAsset(null);
    };

    const handleOpenReissue = (asset) => {
        setOpenReissue(true);
        setActiveAsset(asset)
    };

    const handleCloseReissue = () => {
        setOpenReissue(false);
        if (!openAssetInfo) {
            setActiveAsset(null);
        }
    };

    const handleOpenBurn = (asset) => {
        setOpenBurn(true);
        setActiveAsset(asset)
    };

    const handleCloseBurn = () => {
        setOpenBurn(false);
        if (!openAssetInfo) {
            setActiveAsset(null);
        }
    }

    const buttonsAssets = (row) => <>
        <Button variant="contained" size="small" startIcon={<ReorderIcon />}
            sx={{ marginRight: '10px;' }}
            onClick={() => handleOpenAssetInfo(row)}
        >
            Details
        </Button>
        <Button variant="contained" color="secondary" size="small" startIcon={<SendIcon />}
            sx={{ marginRight: '10px;' }}
            onClick={() => handleOpenSend(row)}
        >
            Send
        </Button>
        <IconButton variant="outlined" size="small"
            sx={{ marginRight: '10px;' }}
            onClick={() => window.open(
                `${BLOCKSTREAM_EXPLORER_URL}/asset/${row.assetId}`,
                '_blank'
            )}
        // onClick={handleOpenTransactions}
        >
            <Tooltip title="View on-chain">
                <ViewModuleIcon />
            </Tooltip>
        </IconButton>
        <Tooltip title="More operations">
            <ActionDropdown items={[
                {
                    text: 'Register', icon: <VpnKeyIcon />,
                    tooltip: 'Prove your ownership of the asset by placing a special file on your domain',
                    onClick: () => handleOpenRegister(row)
                },
                {
                    text: 'Reissue', icon: <AddCircleOutlineIcon />,
                    onClick: () => handleOpenReissue(row)
                },
                {
                    text: 'Burn', icon: <RemoveCircleOutlineIcon />,
                    onClick: () => handleOpenBurn(row)
                },
            ].filter((item, i) => { // hide "Register" button from registered assets
                if (row.isRegistered) {
                    if (i > 0) {
                        return item;
                    }
                    return null;
                }
                return item;
            })} />
        </Tooltip>
    </>

    return (
        <>
            <Typography variant="h6">
                My assets
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent='space-between' gap={0} sx={{ color: 'gray' }}>
                <div style={{ display: 'flex' }}>
                    <Typography variant='body2'>
                        Manage your digital assets and securities.
                    </Typography>
                </div>
                <Button variant="contained" startIcon={<AddIcon />} sx={{ float: 'right' }}
                    onClick={handleOpenIssue}
                >
                    Issue
                </Button>
            </Stack>
            <br />
            {/* AMP asset data loading */}
            <DataLoading 
                loading={ampAssetsLoading}
                text='Loading your assets...'
            />

            {/* AMP asset data loaded */}
            {
                (!ampAssetsLoading && ampAssets) &&
                <BCTable
                    columns={[
                        { name: 'name', label: 'Asset', bold: true },
                        { name: 'ticker', label: 'Ticker', bold: true },
                        { name: 'amount', label: 'Circulating amount', type: 'amount' },
                        {
                            name: 'actions', label: 'Actions', type: 'actions', render: (row) => (
                                buttonsAssets(row)
                            )
                        },
                    ]}
                    data={ampAssets.filter(item => !['03a6c32f2f01aa23f2807b6e4ae95052b4506a65c0eb8db80fc870884467dc16', 
                                                    'ebf1f1befa6c9f5300ef76486bbd8664958508b05413e8bc0dbc7cbcc85f124c', 
                                                    '9794c72d64274d01cb2d2befa1dec4267d58ef7333625764be62bd35ac392acc'].includes(item.assetId))}
                />
            }
            <br />

            {/* No data */}
            <NoData 
                show={!ampAssetsValidating && !ampAssetsError && ampAssets.length === 0}
                text='You have no issued assets.'
            />

            {/* AMP asset error */}
            <DataError 
                show={ampAssetsError && !ampAssets}
                text='Could not load assets due to error. Please try again.'
            />

            {
                openAssetInfo &&
                <AssetInfoDialog
                    open={openAssetInfo} handleClose={handleCloseAssetInfo} updateAssets={updateAmpAssets}
                    asset={activeAsset} reissue={handleOpenReissue} burn={handleOpenBurn}
                />
            }
            {
                openIssue &&
                <IssueDialog open={openIssue} handleClose={handleCloseIssue} updateAssets={updateAmpAssets} />
            }
            {
                openSend &&
                <SendDialog open={openSend} handleClose={handleCloseSend} asset={activeAsset} />
            }
            {
                openRegister &&
                <RegisterAssetDialog open={openRegister} handleClose={handleCloseRegister} asset={activeAsset} updateAssets={updateAmpAssets} />
            }
            {
                openReissue &&
                <ReissueDialog open={openReissue} handleClose={handleCloseReissue} asset={activeAsset} updateAssets={updateAmpAssets} />
            }
            {
                openBurn &&
                <BurnDialog open={openBurn} handleClose={handleCloseBurn} asset={activeAsset} updateAssets={updateAmpAssets} />
            }
        </>
    )
}

const mapStateToProps = ({ assetIssuance }) => {
    return {
        issuanceStep: assetIssuance.step,
        issuanceItem: assetIssuance.item,
    };
};

export default connect(mapStateToProps, null)(Assets)