import useSWR from "swr"
import { API_BASE_URL } from "../../config"
import fetcher from "../../utilities/fetcher"

const useAssetsByCategory = (categoryId) => {
    const { data, error, mutate } = useSWR(() => categoryId ? `${API_BASE_URL}/blockchain/assetsByCategory/${categoryId}` : null, fetcher)

    return {
        assetsByCategory: data || [],
        assetsByCategoryLoading: !error && !data,
        assetsByCategoryError: error,
        updateAssetsByCategory: () => mutate()
    }
}

export default useAssetsByCategory