import * as drawerTypes from './drawerTypes'

const initialState = {
    open: false,
}

const drawerReducer = (state = initialState, action) => {
    switch (action.type) {
        /* Toggle drawer */
        case drawerTypes.TOGGLE_DRAWER_SUCCESS: {
            return {
                ...state,
                open: action.payload
            }
        }
        default:
            return state
    }
}

export default drawerReducer