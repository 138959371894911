import React from "react"

import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Tooltip, Typography } from '@mui/material'

import ViewModuleIcon from '@mui/icons-material/ViewModule';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import useAssetOwners from '../../../../api/blockchain/useAssetOwners';
import useAssetTransactions from '../../../../api/blockchain/useAssetTransactions';
import authorizeAsset from '../../../../api/blockchain/authorizeAsset';

import moment from 'moment';
import HelpTooltip from "../../../../components/HelpTooltip/HelpTooltip";
import { BLOCKSTREAM_EXPLORER_URL } from '../../../../config';
import { errorParser } from '../../../../components/LiquidForm/utils/errorParser';
import { snackbar } from '../../../../utilities/snackbarUtils'

import BCTable from '../Utils/BCTable';
import DataLoading from "../Utils/DataLoading";
import NoData from "../Utils/NoData";
import DataError from "../Utils/DataError";
import WalletAddressButtons from "../Wallets/WalletAddressButtons";
import burnTreasuryAddress from "../../../../api/blockchain/burnTreasuryAddress";
import { RemoveRedEyeOutlined } from "@mui/icons-material";

const AssetInfoDialog = ({ asset, open, handleClose, updateAssets, reissue, burn }) => {
    const [loading, setLoading] = React.useState(false)
    const [showBurnAddress, setShowBurnAddress] = React.useState(false)

    const { assetOwners, assetOwnersError, assetOwnersLoading, assetOwnersValidating } = useAssetOwners(asset?.assetUuid)
    const { assetTransactions, assetTransactionsError, assetTransactionsLoading, assetTransactionsValidating } = useAssetTransactions(asset?.assetUuid)

    const onSubmit = () => {
        setLoading(true)
        authorizeAsset(asset?.assetUuid).then(response => {
            snackbar.success('Succesfully authorized the asset.');
            updateAssets()
        }).catch((error) => {
            snackbar.error(errorParser(error));
        }).finally(() => {
            setLoading(false)
        })
    }

    const burnFromTreasury = (address) => {
        setLoading(true);
        burnTreasuryAddress({
            address
        }).then(response => {
            snackbar.success('Succesfully triggered burn routine.');
        }).catch((error) => {
            snackbar.error(errorParser(error));
        }).finally(() => {
            setLoading(false)
        });
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth='xl'>
            <DialogTitle>Asset Details</DialogTitle>
            <DialogContent>
                <Stack direction="row" alignItems="center" justifyContent='space-between' gap={12}>
                    <div>
                        <Typography variant='body1' as='h5'>
                            {asset?.name}
                        </Typography>
                        <Typography variant='body1' as='h5'>
                            <b>{asset?.ticker}</b>
                        </Typography>
                        <Typography variant='caption' as='p' sx={{ color: 'gray' }}>
                            Issued on {moment(asset?.created_at).format("MMM DD, YYYY")}
                        </Typography>
                    </div>
                    <div>
                        <Button variant="contained" size='small' startIcon={<AddCircleOutlineIcon />}
                            sx={{ marginRight: '10px;' }}
                            onClick={() => reissue(asset)}
                        >
                            Reissue
                        </Button>
                        <Button variant="outlined" size='small' startIcon={<RemoveCircleOutlineIcon />}
                            onClick={() => burn(asset)}
                        >
                            Burn
                        </Button>
                    </div>
                </Stack>
                <br />
                {
                    showBurnAddress && (
                        <>
                            <Typography variant='body1' as='h5'>
                                Burn Addresses
                            </Typography>
                            <NoData
                                show={!asset.treasury_addresses?.length}
                                text='There are not any burn addresses for this asset.'
                            />
                            {
                                asset.treasury_addresses.map(treasury => (
                                    <>
                                        <Stack direction="row" alignItems="center" justifyContent='space-between' gap={1}>
                                            <div>
                                                <Typography variant='caption' as='p' sx={{ color: 'gray' }}>
                                                    Address
                                                </Typography>
                                                <Typography variant='body2' as='p' sx={{ marginBottom: '10px;' }}>
                                                    {treasury.address}
                                                </Typography>
                                            </div>
                                            <WalletAddressButtons address={treasury?.address} />
                                        </Stack>
                                        <Button variant="outlined" size='small' disabled={loading} startIcon={<RemoveCircleOutlineIcon />}
                                            onClick={() => burnFromTreasury(treasury.address)}
                                        >
                                            Burn from treasury
                                        </Button>
                                    </>
                                ))
                            }
                        </>
                    )
                }
                {
                    !showBurnAddress && (
                        <Button variant="outlined" size='small' startIcon={<RemoveRedEyeOutlined />}
                            onClick={() => setShowBurnAddress(true)}
                        >
                            Show Burn Address
                        </Button>
                    )
                }
                <br />
                <Typography variant='body1' as='h5'>
                    Asset Details
                </Typography>

                <Typography variant='body2' sx={{ color: 'gray' }}>
                    Asset ID
                </Typography>
                <Typography variant='body1' sx={{ marginBottom: '10px', }}>
                    <a
                        href={`${BLOCKSTREAM_EXPLORER_URL}/asset/${asset?.assetId}`}
                        target='_blank'
                        rel='noreferrer'
                        style={{ textDecoration: 'none', color: '#3E9CD9' }}
                    >
                        {asset?.assetId}
                    </a>
                </Typography>

                <Stack direction="row" alignItems="center" gap={2}>
                    <div>
                        <Typography variant='body2' sx={{ color: 'gray' }} >
                            Domain
                        </Typography>
                        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
                            <a href={asset?.domain} target='_blank' rel='noreferrer' style={{ textDecoration: 'none', color: '#3E9CD9' }}>{asset?.domain}</a>
                        </Typography>
                    </div>
                    {
                        <Tooltip title={<span>
                            Assets have to be <b>authorized</b> in order to perform distributions and transfers. <br />
                            Authorization should happen automatically <b>5 minutes</b> after the asset is issued. <br />
                            If it does not, click on this badge to manually retry authorization.
                        </span>} onClick={asset?.isAuthorized ? null : onSubmit} >
                            {
                                asset?.isAuthorized ?
                                    <Chip disabled={loading} color='success' label='authorized' /> :
                                    <Chip disabled={loading} color='danger' label='unauthorized' />
                            }
                        </Tooltip>
                    }
                    {
                        <Tooltip title={<span>
                            If an asset is <b>registered</b>, its exact name, ticker, domain and other details are visible on the blockchain. <br />
                            Unregistered assets are still perfectly usable, but their details are not as visible on the blockchain.
                        </span>} >
                            {
                                asset?.isRegistered ?
                                    <Chip color='success' label='registered' /> :
                                    <Chip color='danger' label='unregistered' />
                            }
                        </Tooltip>
                    }
                </Stack>
                <br />
                <Typography variant='body1' as='h5'>
                    Asset ownership
                    <HelpTooltip title={<>This table shows the <b>confirmed blockchain balances</b> of the holders.</>} />
                </Typography>
                <div style={{ display: 'flex', color: 'gray' }}>
                    <Typography variant='body2'>
                        View all owners of the given asset
                    </Typography>
                </div>
                {/* Asset owners data loading */}
                <DataLoading
                    sx={{ marginTop: '10px' }}
                    loading={assetOwnersLoading}
                    text='Loading asset holders...'
                />

                {/* Wallet data loaded */}
                {
                    (!assetOwnersLoading && assetOwners) &&
                    <BCTable
                        sx={{ marginTop: '10px;' }}
                        columns={[
                            { name: 'holder', label: 'Owner', bold: true, extraInfoColumn: 'type' },
                            { name: 'balance', label: `Balance (${asset.ticker})`, type: 'amount' }
                        ]}
                        data={assetOwners}
                    />
                }
                <br />
                {/* No data */}
                <NoData
                    show={!assetOwnersValidating && !assetOwnersError && assetOwners.length === 0}
                    text='There are no holders of this asset.'
                />

                {/* AMP asset error */}
                <DataError
                    show={assetOwnersError && (!assetOwners || assetOwners.length === 0)}
                    text='Could not load asset holders due to error. Please try again.'
                />

                <Typography variant='body1' as='h5'>
                    Transactions
                </Typography>
                <div style={{ display: 'flex', color: 'gray' }}>
                    <Typography variant='body2'>
                        View all transactions of the given asset
                    </Typography>
                </div>
                {/* Asset owners data loading */}
                <DataLoading
                    sx={{ marginTop: '10px' }}
                    loading={assetTransactionsLoading}
                    text='Loading asset transactions...'
                />

                {/* Wallet data loaded */}
                {
                    (!assetTransactionsLoading && assetTransactions) &&
                    <BCTable
                        sx={{ marginTop: '10px' }}
                        columns={[
                            { name: 'description', label: 'Description', bold: true, extraInfoColumn: 'extra' },
                            { name: 'parsedAmount', label: 'Amount', type: 'amount' },
                            { name: 'from', label: 'From' },
                            { name: 'to', label: 'To' },
                            { name: 'datetime', label: 'Date', type: 'date', format: 'MMM DD, YYYY' },
                            {
                                name: 'actions', label: 'Actions', type: 'actions', render: (row) => (
                                    <IconButton variant="outlined" size="small"
                                        sx={{ marginRight: '10px;' }}
                                        onClick={() => window.open(
                                            `${BLOCKSTREAM_EXPLORER_URL}/tx/${row.txid}#blinded=${row.amount},${asset?.assetId},${row.amountBlinder},${row.assetBlinder}`,
                                            '_blank'
                                        )}
                                    >
                                        <Tooltip title="View on-chain">
                                            <ViewModuleIcon />
                                        </Tooltip>
                                    </IconButton>
                                )
                            }
                        ]}
                        data={assetTransactions}
                    />
                }
                <br />
                {/* No data */}
                <NoData
                    show={!assetTransactionsValidating && !assetTransactionsError && assetTransactions.length === 0}
                    text='There are no transactions in this asset.'
                />

                {/* AMP asset error */}
                <DataError
                    show={assetTransactionsError && (!assetTransactions || assetOwnersError.length === 0)}
                    text='Could not load asset transactions due to error. Please try again.'
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>

        </Dialog>
    )
}

export default AssetInfoDialog