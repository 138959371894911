import makeStyles from '@mui/styles/makeStyles';

const buttonStyles = makeStyles((theme) => ({
    button: {
        '& .MuiSvgIcon-root': {
            fontSize: '1.2rem',
            marginRight: '0.35rem',
        },
    },
    blue: {
        backgroundColor: '#2196F3',
        color: '#FFF',
        '&:hover': {
            backgroundColor: '#1E88E5',
        },
    },
    green: {
        backgroundColor: '#4caf50',
        color: '#FFF',
        '&:hover': {
            backgroundColor: '#43a047',
        },
    },
    orange: {
        backgroundColor: '#FF9800',
        color: '#FFF',
        '&:hover': {
            backgroundColor: '#FB8C00',
        },
    },
    red: {
        backgroundColor: '#F44336',
        color: '#FFF',
        '&:hover': {
            backgroundColor: '#E53935',
        },
    },
    purple: {
        backgroundColor: '#9c27b0',
        color: '#FFF',
        '&:hover': {
            backgroundColor: '#6d1b7b',
        },
    },
}));

export default buttonStyles;
