import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import authStyles from '../authStyles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as config from './../../../config';
import request from '../../../utilities/request';
import { CheckboxWithLabel } from 'formik-mui';
import Button from '@mui/material/Button';
import LockedTile from '../../../components/LockedTile';
import Paper from '@mui/material/Paper';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import TextField from '../../../components/TextField/TextField';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useSnackbar } from 'notistack';
import * as investorDocumentActions from '../../../store/investorDocuments/investorDocumentActions';
import * as snippetActions from '../../../store/snippet/snippetActions';
import investorDocumentStyles from '../../../components/styles/investorDocumentStyles';
import investorStyles from '../../../components/styles/investorStyles';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import { Editor } from '@tinymce/tinymce-react';
import {
    validateFileExtension,
    allowedFiles,
    allowedFilesMessage,
} from '../../../utilities/utils';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import { SUBSCRIPTION_PRO } from '../../../constants/profile';
let initialValues = {};

let snippetValues = {
    title: '',
    body: '',
};

const validationSchema = Yup.object({
    title: Yup.string().required('Please enter snippet title.'),
});

const formData = new FormData();
formData.append('document', '');
const UploadDocuments = (props) => {
    const classes = authStyles();
    const investorDocumentClasses = investorDocumentStyles();
    const investorClasses = investorStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    let [refreshForm /*, setRefreshForm*/] = useState(0);
    const [addSnippetDialog, setAddSnippetDialog] = useState(false);
    const [snippetDialog, setSnippetDialog] = useState(false);
    const [deleteSnippetDialog, setDeleteSnippetDialog] = useState(false);
    const [deleteSnippetId, setDeleteSnippetId] = useState(0);

    let [snippetData, setSnippetData] = useState([]);

    //const [investorDocuments, setInvestorDocuments] = useState([]);
    const [newDocument, setnewDocument] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [disableUpload, setDisableUpload] = useState(true);

    const [body, setBody] = useState('');
    const [key, setKey] = useState(0);

    useEffect(props.investorDocumentActions.getInvestorDocuments, []);
    useEffect(props.snippetActions.getSnippets, []);

    /*useEffect(() => {
        if (
            props.investorDocuments.data &&
            props.investorDocuments.data.data.length
        ) {
            setInvestorDocuments(props.investorDocuments.data.data);
            props.investorDocuments.data.data.forEach((item) => {
                initialValues[item.id] = item.show === 1 ? true : false;
            });
        }
    }, [props.investorDocuments.data]);*/

    /*let demo;
    try {
        demo = props.profile.demo;
    } catch (e) {
        demo = null;
    }*/

    const deleteSnippet = (key) => {
        request
            .delete(`${config.API_BASE_URL}/snippets/${key}`)
            .then((response) => {
                enqueueSnackbar('Snippet has been deleted successfully', {
                    variant: 'success',
                });
                setDeleteSnippetDialog(false);
                props.snippetActions.getSnippets();
            })
            .catch(() => {
                enqueueSnackbar('Error occured while deleting snippet', {
                    variant: 'error',
                });
            });
    };

    const closeAddSnippetDialog = () => {
        setAddSnippetDialog(false);
    };

    const displayAddSnippetDialog = () => {
        setAddSnippetDialog(true);
    };

    const closeSnippetDialog = () => {
        setSnippetDialog(false);
        setDeleteSnippetDialog(false);
    };

    const displayDeleteSnippetDialog = (snippetId) => {
        setDeleteSnippetDialog(true);
        setDeleteSnippetId(snippetId);
    };

    /*const closeDeleteSnippetDialog = () => {
        setDeleteSnippetDialog(false);
    };*/

    const displaySnippetDialog = (snippet) => {
        setSnippetData(snippet);
        setSnippetDialog(true);
    };

    const uploadFile = (file) => {
        if (!validateFileExtension(file)) {
            enqueueSnackbar(allowedFilesMessage, {
                variant: 'error',
            });
            setTimeout(function () {
                closeSnackbar();
            }, 3500);
        } else {
            formData.set('document', file, file.name);
            setnewDocument(file.name);
            setDisableUpload(false);
        }
    };

    const uploadDocument = () => {
        setIsUploading(true);
        setDisableUpload(true);
        request
            .post(`${config.API_BASE_URL}/investors/document`, formData)
            .then((response) => {
                setIsUploading(false);
                enqueueSnackbar('The new document has been uploaded', {
                    variant: 'success',
                });
                props.investorDocumentActions.getInvestorDocuments();
            })
            .catch(() => {
                setIsUploading(false);
                enqueueSnackbar(
                    'An error occurred while saving the new document',
                    {
                        variant: 'error',
                    }
                );
            })
            .finally(() => {
                setnewDocument('');
            });
    };

    const updateDocumentShow = (e) => {
        let data = {
            id: parseInt(e.target.name),
            show: e.target.value === '1' || e.target.value === 1 ? false : true,
        };

        request
            .post(`${config.API_BASE_URL}/investors/document/edit`, data)
            .then(() => {
                enqueueSnackbar('The change has been saved', {
                    variant: 'success',
                });
                props.investorDocumentActions.getInvestorDocuments();
            })
            .catch(() => {
                enqueueSnackbar('An error occurred while saving the changes', {
                    variant: 'error',
                });
            });
    };

    const handleEditorChange = (content) => {
        setBody(content);
    };

    return (
        <LockedTile requiredPlan={SUBSCRIPTION_PRO}>
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {}}
                key={refreshForm}
            >
                <Form noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                            <Paper elevation={3} className={classes.paper}>
                                <Typography className={classes.title}>
                                    Investor Documents
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField
                                            id="standard-basic"
                                            variant="standard"
                                            value={newDocument}
                                            placeholder=""
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={5} md={5}>
                                        <ButtonGroup>
                                            <Button
                                                component="label"
                                                variant="contained"
                                                color="primary"
                                                className={classes.browseButton}
                                            >
                                                Browse
                                                <input
                                                    onChange={(e) => {
                                                        uploadFile(
                                                            e.target.files[0]
                                                        );
                                                    }}
                                                    style={{ display: 'none' }}
                                                    type="file"
                                                    name="new_logo"
                                                    accept={allowedFiles.join(
                                                        ','
                                                    )}
                                                />
                                            </Button>{' '}
                                            <Button
                                                component="label"
                                                variant="contained"
                                                disabled={
                                                    newDocument === '' ||
                                                    disableUpload
                                                }
                                                className={classes.uploadButton}
                                                type="submit"
                                                onClick={() => uploadDocument()}
                                            >
                                                {isUploading ? (
                                                    <CircularProgress
                                                        size={24}
                                                        style={{
                                                            color: '#fff',
                                                        }}
                                                        className={
                                                            classes.buttonProgress
                                                        }
                                                    />
                                                ) : (
                                                    'Upload'
                                                )}
                                            </Button>
                                        </ButtonGroup>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid>
                                    {props.investorDocuments &&
                                    props.investorDocuments.loading ? (
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                        >
                                            <CircularProgress />
                                        </Grid>
                                    ) : (
                                        <Grid>
                                            {props.investorDocuments &&
                                            props.investorDocuments.data &&
                                            props.investorDocuments.data.data
                                                .length > 0 ? (
                                                <Grid
                                                    style={{
                                                        marginTop: '1rem',
                                                    }}
                                                >
                                                    {props.investorDocuments.data.data.map(
                                                        (
                                                            investor_document,
                                                            i
                                                        ) => (
                                                            <Grid
                                                                container
                                                                key={i}
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={9}
                                                                    md={9}
                                                                    className={
                                                                        classes.documentTitle
                                                                    }
                                                                >
                                                                    <a
                                                                        href={
                                                                            investor_document.document
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                        className={
                                                                            classes.link
                                                                        }
                                                                    >
                                                                        {
                                                                            investor_document.title
                                                                        }
                                                                    </a>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={3}
                                                                    md={3}
                                                                >
                                                                    <Field
                                                                        component={
                                                                            CheckboxWithLabel
                                                                        }
                                                                        type="checkbox"
                                                                        name={
                                                                            investor_document.id
                                                                        }
                                                                        value={
                                                                            investor_document.show
                                                                        }
                                                                        checked={
                                                                            investor_document.show
                                                                        }
                                                                        Label={{
                                                                            label: (
                                                                                <div
                                                                                    className={
                                                                                        classes.checkboxDescription
                                                                                    }
                                                                                >
                                                                                    Show
                                                                                </div>
                                                                            ),
                                                                        }}
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            updateDocumentShow(
                                                                                e
                                                                            )
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    )}
                                                </Grid>
                                            ) : (
                                                <Grid
                                                    className={
                                                        classes.gridNoData
                                                    }
                                                >
                                                    No data
                                                </Grid>
                                            )}
                                        </Grid>
                                    )}
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Paper elevation={3} className={classes.paper}>
                                <Typography className={classes.title}>
                                    Snippets
                                </Typography>
                                {!props.snippets.data ? (
                                    <CircularProgress
                                        size={24}
                                        className={classes.buttonProgress}
                                    />
                                ) : props.snippets.data.length > 0 ? (
                                    props.snippets.data.map((snippet, i) => (
                                        <Grid
                                            key={i}
                                            container
                                            spacing={1}
                                            className="flex-left"
                                        >
                                            <Grid
                                                item
                                                xs={6}
                                                className={
                                                    investorClasses.snippetTitle
                                                }
                                            >
                                                {snippet.title}
                                            </Grid>

                                            <Grid
                                                item
                                                xs={3}
                                                className={
                                                    investorDocumentClasses.gridLeft
                                                }
                                            >
                                                <IconButton
                                                    style={{
                                                        fontSize: '.8rem',
                                                    }}
                                                    onClick={() =>
                                                        displaySnippetDialog(
                                                            snippet
                                                        )
                                                    }
                                                    size="large"
                                                >
                                                    <OpenInNewIcon /> View
                                                </IconButton>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={3}
                                                className={
                                                    investorDocumentClasses.gridRight
                                                }
                                            >
                                                <IconButton
                                                    style={{
                                                        fontSize: '.8rem',
                                                    }}
                                                    onClick={() =>
                                                        displayDeleteSnippetDialog(
                                                            snippet.id
                                                        )
                                                    }
                                                    size="large"
                                                >
                                                    <DeleteForeverIcon /> Delete
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    ))
                                ) : (
                                    <Grid
                                        style={{
                                            textAlign: 'center',
                                            fontSize: '1.1rem',
                                        }}
                                    >
                                        No data
                                    </Grid>
                                )}

                                <br />
                                <div className={classes.alignEnd}>
                                    <Button
                                        type="label"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            displayAddSnippetDialog();
                                        }}
                                    >
                                        <b>Add Snippet</b>
                                    </Button>
                                </div>
                            </Paper>
                        </Grid>{' '}
                    </Grid>
                </Form>
            </Formik>
            <Dialog maxWidth="sm" fullWidth open={addSnippetDialog}>
                <DialogTitle className={classes.dialogTitle}>
                    New Snippet
                </DialogTitle>
                <DialogContent>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={12}>
                            <Formik
                                initialValues={snippetValues}
                                validationSchema={validationSchema}
                                key={key}
                                onSubmit={(values, { setSubmitting }) => {
                                    closeSnackbar();
                                    if (body === '' || body === null) {
                                        enqueueSnackbar(
                                            'Please fill in required fields',
                                            {
                                                variant: 'error',
                                            }
                                        );
                                        setKey(key + 1);
                                    } else {
                                        values['body'] = body;

                                        request
                                            .post(
                                                `${config.API_BASE_URL}/snippets`,
                                                values
                                            )
                                            .then((response) => {
                                                enqueueSnackbar(
                                                    'Snippet has been updated successfully',
                                                    {
                                                        variant: 'success',
                                                    }
                                                );
                                                setAddSnippetDialog(false);
                                                setBody(null);
                                                props.snippetActions.getSnippets();
                                            })
                                            .catch(() => {});
                                    }
                                }}
                            >
                                {({ isSubmitting }) => (
                                    <Container>
                                        <Form
                                            className={classes.form}
                                            noValidate
                                        >
                                            <Field
                                                component={TextField}
                                                name="title"
                                                type="text"
                                                label="Title"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                required
                                            />

                                            <Grid container>
                                                <Grid container>
                                                    <InputLabel
                                                        className={
                                                            classes.label
                                                        }
                                                        htmlFor="body"
                                                    >
                                                        Body
                                                    </InputLabel>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                >
                                                    <Editor
                                                        apiKey="uf9matrx4ivq9fcl3zpum8qtj7b4bb79w95bmuwn5d2hkhyi"
                                                        initialValue="<p></p>"
                                                        name="body"
                                                        init={{
                                                            height: 250,
                                                            menubar: false,
                                                            plugins: [
                                                                'advlist autolink lists link image charmap print preview anchor',
                                                                'searchreplace visualblocks code fullscreen',
                                                                'insertdatetime media table paste code help wordcount',
                                                            ],
                                                            toolbar:
                                                                'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                                                        }}
                                                        onEditorChange={
                                                            handleEditorChange
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                            <br />
                                            <DialogActions>
                                                <Button
                                                    color="secondary"
                                                    variant="outlined"
                                                    onClick={() => {
                                                        closeAddSnippetDialog();
                                                    }}
                                                >
                                                    <b>Cancel</b>
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                >
                                                    {!isSubmitting ? (
                                                        <div className="flex-left">
                                                            <b>Save</b>
                                                        </div>
                                                    ) : (
                                                        <CircularProgress
                                                            size={24}
                                                        />
                                                    )}
                                                </Button>
                                            </DialogActions>
                                        </Form>
                                    </Container>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog maxWidth="sm" fullWidth open={snippetDialog}>
                <DialogTitle
                    id="responsive-dialog-title"
                    className={classes.dialogTitle}
                >
                    {'Snippet: ' + snippetData.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography
                            component="div"
                            dangerouslySetInnerHTML={{
                                __html: snippetData.body,
                            }}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    className={investorDocumentClasses.dialogActions}
                >
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => {
                            closeSnippetDialog();
                        }}
                    >
                        <b>Close</b>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog maxWidth="sm" fullWidth open={deleteSnippetDialog}>
                <DialogTitle
                    id="responsive-dialog-title"
                    className={classes.dialogTitle}
                >
                    {'Are you sure you want to delete this snippet?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You will not be able to discard this action
                    </DialogContentText>
                </DialogContent>

                <DialogActions
                    className={investorDocumentClasses.dialogActions}
                >
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => {
                            closeSnippetDialog();
                        }}
                    >
                        <b>Cancel</b>
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            deleteSnippet(deleteSnippetId);
                        }}
                    >
                        <b>Delete</b>
                    </Button>
                </DialogActions>
            </Dialog>
        </LockedTile>
    );
};

const mapStateToProps = ({ profile, investorDocuments, snippets }) => {
    return {
        profile: profile,
        investorDocuments: investorDocuments,
        snippets: snippets,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        investorDocumentActions: bindActionCreators(
            investorDocumentActions,
            dispatch
        ),
        snippetActions: bindActionCreators(snippetActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocuments);
