import React from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import ReceiveIcon from '@mui/icons-material/CallReceived';

import moment from 'moment';

import BCTable from '../Utils/BCTable';
import NoData from '../Utils/NoData';
import CreateNewAccountDialog from './CreateNewAccountDialog';
import ImportAccountDialog from './ImportAccountDialog';
import { snackbar } from '../../../../utilities/snackbarUtils';
import CreateSubaccountDialog from './CreateSubaccountDialog';
import SubaccountAssetsDialog from './SubaccountAssetsDialog';
import useLBTCBalance from '../../../../api/blockchain/useLBTCBalance';
import { useDidMountEffect } from '../../../../utilities/customHooks';
import ReceiveAssetsDialog from './ReceiveAssetsDialog';
import SendAssetsDialog from './SendAssetsDialog';
import Humanize from 'humanize-plus';
import SubaccountTransactionsDialog from './SubaccountTransactionsDialog';
import CreateOrImportSubaccountDialog, { CreateOrImportAction } from './CreateOrImportSubaccountDialog';

// LBTC balance component
const LBTCBalance = ({ investor_id, holder_id, refresh }) => {
    const { lbtcBalance, lbtcBalanceValidating, lbtcBalanceError, updateLBTCBalance } = useLBTCBalance(investor_id, holder_id);

    useDidMountEffect(() => {
        if (refresh) {
            updateLBTCBalance()
        }
    }, [refresh])

    if (lbtcBalanceValidating) {
        return <CircularProgress size={12} />
    }

    if (lbtcBalanceError) {
        return <Typography variant='caption'>Could not load.</Typography>
    }

    return <span>{Humanize.formatNumber(lbtcBalance?.balance, 8)}</span>
}

const AccountInfoDialog = ({ user, open, handleClose }) => {
    const [openNewAccount, setOpenNewAccount] = React.useState(false);
    const [openImportAccount, setOpenImportAccount] = React.useState(false);
    
    const [openCreateSubaccount, setOpenCreateSubaccount] = React.useState(false);
    const [openCreateOrImportSubaccount, setOpenCreateOrImportSubaccount] = React.useState(false);
    const [openSubaccountAssets, setOpenSubaccountAssets] = React.useState(false);

    const [openTransactions, setOpenTransactions] = React.useState(false);

    const [openReceiveAssets, setOpenReceiveAssets] = React.useState(false);
    const [openSendAssets, setOpenSendAssets] = React.useState(false);

    const [ subaccounts, setSubaccounts ] = React.useState([]);
    const [ subaccount, setSubaccount ] = React.useState(null);

    React.useEffect(() => {
        for (let i = 0; i < user?.subaccounts?.length; i++) {
            user.subaccounts[i].balance = <LBTCBalance refresh={false} investor_id={user.id} holder_id={user.subaccounts[i].amp_user_id} />
        }
        setSubaccounts(user?.subaccounts || []);
    }, [user]);

    const handleCloseNewAccount = () => {
        setOpenNewAccount(false);
    };

    const handleCloseImportAccount = () => {
        setOpenImportAccount(false);
    };

    const handleCloseCreateOrImportSubaccount = (action) => {

        setOpenCreateOrImportSubaccount(false);

        if (action === CreateOrImportAction.CREATE) {
            setOpenCreateSubaccount(true);
        } else if (action === CreateOrImportAction.IMPORT) {
            handleClose();
        }
    }

    const handleCloseCreateSubaccount = () => {
        setOpenCreateSubaccount(false);
    };

    const handleCloseSubaccountAssets = () => {
        setOpenSubaccountAssets(false);
        setSubaccount(null);
    };

    const handleCloseTransactions = () => {
        setOpenTransactions(false);
        setSubaccount(null);
    };

    const seeTransactions = (subaccount) => {
        setSubaccount(subaccount);
        setOpenTransactions(true);
    }
    
    const copyGAID = (gaid) => {
        navigator.clipboard.writeText(gaid)
        snackbar.success('Copied gaid to clipboard.');
    }

    const seeSubaccountAssets = (subaccount) => {
        setSubaccount(subaccount);
        setOpenSubaccountAssets(true);
    }

    const handleOpenReceiveAssets = (subaccount) => {
        setSubaccount(subaccount);
        setOpenReceiveAssets(true);
    }

    const handleOpenSendAssets = (subaccount) => {
        setSubaccount(subaccount);
        setOpenSendAssets(true);
    }

    const handleCloseReceiveAssets = () => {
        setOpenReceiveAssets(false);
    }

    const handleCloseSendAssets = () => {
        setOpenSendAssets(false);
    }

    const onAdd = () => {
        if (subaccounts.length) {
            return setOpenCreateSubaccount(true);
        }

        return setOpenCreateOrImportSubaccount(true);
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="xl">
                <DialogTitle>Account Details</DialogTitle>
                <DialogContent>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        gap={12}
                    >
                        <div>
                            <Typography variant="body1" as="h5">
                                {user?.holder}
                            </Typography>
                            <Typography
                                variant="caption"
                                as="p"
                                sx={{ color: 'gray' }}
                            >
                                Holder since{' '}
                                {moment(user?.created_at).format('MMM DD, YYYY')}
                                {user?.registered_at && (
                                    <>
                                        <br />
                                        Registered since{' '}
                                        {moment(user?.registered_at).format(
                                            'MMM DD, YYYY'
                                        )}
                                    </>
                                )}
                            </Typography>
                        </div>
                    </Stack>
                    <br />
                    <>
                        <Stack 
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            gap={12}
                        >
                            <Typography
                                variant="body1"
                                as="h5"
                                sx={{ marginBottom: '10px' }}
                            >
                                Sub-accounts
                            </Typography>
                            <Button onClick={onAdd} disabled={user.is_imported}>
                                <AddIcon />
                            </Button>
                        </Stack>
                        {
                            subaccounts.length !== 0 && (
                                <BCTable
                                    columns={[
                                        {
                                            name: 'account_name',
                                            label: 'Account name',
                                            bold: true,
                                        },
                                        {
                                            name: 'balance',
                                            label: 'Balance (L-BTC)',
                                        },
                                        {
                                            name: 'actions',
                                            label: '',
                                            type: 'actions',
                                            render: (subaccount) => (
                                                <>
                                                    <Button variant="contained" size="small" startIcon={<SendIcon />}
                                                        onClick={() => handleOpenSendAssets(subaccount)}
                                                        sx={{marginRight: '10px'}}
                                                        disabled={subaccount.is_imported}
                                                    >
                                                        Send
                                                    </Button>
                                                    <Button variant="contained" size="small" startIcon={<ReceiveIcon />}
                                                        onClick={() => handleOpenReceiveAssets(subaccount)}
                                                        disabled={subaccount.is_imported}
                                                    >
                                                        Receive
                                                    </Button>
                                                    <Tooltip title='Transactions'>
                                                        <IconButton onClick={() => seeTransactions(subaccount)}>
                                                            <ReceiptLongIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title='Copy gaid'>
                                                        <IconButton onClick={() => copyGAID(subaccount.gaid)}>
                                                            <ContentCopyIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title='See assets'>
                                                        <IconButton onClick={() => seeSubaccountAssets(subaccount)}>
                                                            <WebAssetIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                            )
                                        }
                                    ]}
                                    data={user?.subaccounts}
                                />
                            )
                        }
                        <NoData
                            show={user?.subaccounts.length === 0}
                            text="This holder has no subaccounts."
                        />
                    </>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>

                {openNewAccount && (
                    <CreateNewAccountDialog
                        open={openNewAccount}
                        handleClose={handleCloseNewAccount}
                    />
                )}
                {openImportAccount && (
                    <ImportAccountDialog
                        open={openImportAccount}
                        handleClose={handleCloseImportAccount}
                    />
                )}
                {
                    openCreateOrImportSubaccount && (
                        <CreateOrImportSubaccountDialog 
                            open={openCreateOrImportSubaccount}
                            handleClose={handleCloseCreateOrImportSubaccount}
                            holder={user} 
                        />
                    )
                    
                }
                {
                    openCreateSubaccount && (
                        <CreateSubaccountDialog 
                            open={openCreateSubaccount}
                            handleClose={handleCloseCreateSubaccount}
                            holder={user} 
                        />
                    )
                    
                }
                {
                    openSubaccountAssets && (
                        <SubaccountAssetsDialog 
                            open={openSubaccountAssets}
                            handleClose={handleCloseSubaccountAssets}
                            subaccount={subaccount} 
                        />
                    )
                    
                }
            </Dialog>
            {
                openReceiveAssets &&
                <ReceiveAssetsDialog open={openReceiveAssets} handleClose={handleCloseReceiveAssets} user={user} acc_amp_user_id={subaccount.amp_user_id} />
            }
            {
                openSendAssets &&
                <SendAssetsDialog open={openSendAssets} handleClose={handleCloseSendAssets} user={user} acc_amp_user_id={subaccount.amp_user_id} />
            }
            {
                openTransactions &&
                <SubaccountTransactionsDialog open={openTransactions} handleClose={handleCloseTransactions} subaccount={subaccount} />
            }
        </>
    );
};

export default AccountInfoDialog;
