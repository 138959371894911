import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const useStyles = makeStyles({
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
});

const DotsStepper = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        if (
            activeStep === 0 &&
            (props.initialValues.first_name === '' ||
                props.initialValues.last_name === '' ||
                props.initialValues.email === '')
        ) {
            setDisableButton(true);
        } else if (
            activeStep === 1 &&
            (props.initialValues.password === '' ||
                props.initialValues.confirm_password === '' ||
                props.initialValues.human === '')
        ) {
            setDisableButton(true);
        } else if (
            activeStep === 3 &&
            (props.initialValues.company_name === '' ||
                props.initialValues.legal_entity_name === '' ||
                props.initialValues.registered_country === '')
        ) {
            setDisableButton(true);
        } else if (
            activeStep === 11 &&
            (props.initialValues.first_name === '' ||
                props.initialValues.last_name === '' ||
                props.initialValues.email === '' ||
                props.initialValues.password === '' ||
                props.initialValues.confirm_password === '' ||
                props.initialValues.company_name === '' ||
                props.initialValues.legal_entity_name === '' ||
                props.initialValues.registered_country === '')
        ) {
            setDisableButton(true);
        } else {
            setDisableButton(false);
        }
    }, [
        props.initialValues.first_name,
        props.initialValues.last_name,
        props.initialValues.email,
        props.initialValues.password,
        props.initialValues.confirm_password,
        props.initialValues.human,
        props.initialValues.company_name,
        props.initialValues.legal_entity_name,
        props.initialValues.registered_country,
        activeStep,
    ]);

    const handleNext = () => {
        if (
            (props.initialValues.option_plan === 'no' && activeStep === 7) ||
            (props.initialValues.track_different_categories === 'no' &&
                activeStep === 5)
        ) {
            props.skipNextPage();
            setActiveStep((prevActiveStep) => prevActiveStep + 2);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            props.nextStep();
        }
    };

    const handleBack = () => {
        if (
            (props.initialValues.option_plan === 'no' && activeStep === 9) ||
            (props.initialValues.track_different_categories === 'no' &&
                activeStep === 7)
        ) {
            setActiveStep((prevActiveStep) => prevActiveStep - 2);
            props.setCustomStep(activeStep - 2);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
            props.previousStep();
        }
    };

    useEffect(() => {
        setActiveStep(props.step);
    }, [props.step]);

    return (
        <MobileStepper
            style={{
                backgroundColor: 'white',
            }}
            variant="dots"
            steps={14}
            position="static"
            activeStep={activeStep}
            className={classes.root}
            nextButton={
                <Button
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === 12 || disableButton}
                >
                    {theme.direction === 'rtl' ? (
                        <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                    )}
                </Button>
            }
            backButton={
                <Button
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                >
                    {theme.direction === 'rtl' ? (
                        <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                    )}
                </Button>
            }
        />
    );
};

export default DotsStepper;
