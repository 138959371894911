import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import authStyles from '../authStyles';
import LockedTile from '../../../components/LockedTile';
import { SUBSCRIPTION_PRO } from '../../../constants/profile';

const External = (props) => {
    const classes = authStyles();

    let externals = [
        {
            href: 'https://merj.exchange/',
            src:
                'https://cdn.icrypto.media/images/67c25b7a_527a_483d_af71_d84efb153a95.jpeg',
            alt: 'MERJ',
        },
        {
            href: 'https://www.dealsquare.io/',
            src:
                'https://s3.amazonaws.com/brokerportal-production/2_dsneo_dsneo_20200623201625894.svg',
            alt: 'DealSquare',
        },
        {
            href: 'https://republic.co/',
            src:
                'https://assets.republic.co/assets/logo/full/black/150x38/logo-211758c2027fc8268186d512a2119520a35358973648789a123d9fb6c3d5a37f.svg',
            alt: 'Republic',
        },
    ];
    return (
        <LockedTile requiredPlan={SUBSCRIPTION_PRO}>
            <Grid
                container
                spacing={2}
            >
                <Grid item xs={12}>
                    <Typography component="div" style={{ textAlign: 'right' }}>
                        <h3>
                            Listing Status:{' '}
                            <b style={{ color: '#3E9CD9' }}>Active</b>
                        </h3>
                    </Typography>
                </Grid>

                <Typography
                    className={classes.dialogTitle}
                    style={{ marginBottom: '0.5rem' }}
                >
                    External Transfers:
                </Typography>

                <Grid container spacing={2}>
                    {externals.map((external, i) => (
                        <Grid item xs={12} sm={4} md={4}>
                            <Paper elevation={3} className={classes.paper}>
                                <Grid className={classes.alignCenter}>
                                    <a
                                        href={external.href}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            style={{ height: 70 }}
                                            src={external.src}
                                            alt={external.alt}
                                        />
                                    </a>
                                </Grid>
                                <Grid className={classes.alignCenter}>
                                    <a
                                        href={external.href}
                                        target="_blank"
                                        className={classes.linkNoneDecoration}
                                        rel="noopener noreferrer"
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{ marginTop: '1.5rem' }}
                                        >
                                            Activate Listing
                                        </Button>
                                    </a>
                                </Grid>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </LockedTile>
    );
};
const mapStateToProps = ({ profile }) => {
    return {
        profile: profile,
    };
};

export default connect(mapStateToProps)(External);
