import request from '../../utilities/request';
import * as config from '../../config';
import * as investorDocumentTypes from './investorDocumentTypes';

export const getInvestorDocumentsSuccess = (summary) => {
    return {
        type: investorDocumentTypes.GET_INVESTOR_DOCUMENTS_SUCCESS,
        payload: summary,
    };
};

export const setInvestorDocumentsLoading = (loading) => {
    return {
        type: investorDocumentTypes.SET_INVESTOR_DOCUMENTS_LOADING,
        payload: loading,
    };
};

export const getInvestorDocuments = () => {
    return (dispatch) => {
        dispatch(setInvestorDocumentsLoading(true));
        request
            .get(`${config.API_BASE_URL}/investors/documents`)
            .then((res) => {
                dispatch(getInvestorDocumentsSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setInvestorDocumentsLoading(false));
            });
    };
};
