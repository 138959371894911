import request from '../../utilities/request';
import * as config from '../../config';
import * as blockchainTypes from './blockchainTypes';
import { snackbar } from '../../utilities/snackbarUtils';
import { parseError } from '../../utilities/utils';

export const issueOnBlockchainSuccess = (data) => {
    return {
        type: blockchainTypes.ISSUE_ON_BLOCKCHAIN_SUCCESS,
        payload: data,
    };
};

export const setBlockchainLoading = (loading) => {
    return {
        type: blockchainTypes.SET_BLOCKCHAIN_LOADING,
        payload: loading,
    };
};

export const issueOnBlockchain = (data, setSubmitting, history, reset) => {

    return (dispatch) => {
        setSubmitting(true);
        dispatch(setBlockchainLoading(true));
        request
            .post(`${config.API_BASE_URL}/issue-token`, data)
            .then((res) => {
                snackbar.success('Equity issued on blockchain.');
                dispatch(issueOnBlockchainSuccess(data));
                reset()
                history.push('category-info')
            })
            .catch((error) => {
                let errorMessage = parseError(error);
                snackbar.error(errorMessage);
                console.log(error);
            })
            .finally(() => {
                setSubmitting(false);
                dispatch(setBlockchainLoading(false));
            });
    };
};
