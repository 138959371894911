import useSWR from "swr"
import { API_BASE_URL } from "../config"
import fetcher from "../utilities/fetcher"

const useShareCategories = (classId) => {
    const { data, error } = useSWR(() => classId ? `${API_BASE_URL}/share-classes/categories/${classId}` : null, fetcher)

    return {
        shareCategories: data || [],
        shareCategoriesLoading: classId && !error && !data,
        shareCategoriesError: error
    }
}

export default useShareCategories