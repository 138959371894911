import React from 'react';
import Tooltip from '@mui/material/Tooltip';
//import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import helpTooltipStyles from '../styles/helpTooltipStyles';

const HelpTooltip = ({ title, placement, ...props }) => {
    const classes = helpTooltipStyles(props);

    return (
        <Tooltip
            title={title}
            placement={placement ? placement : 'right'}
            arrow
            classes={{ tooltip: classes.customToolTip }}
        >
            <span className="tool-tip-ins">
                <InfoIcon
                    style={{
                        height: '15px',
                        marginRight: props.marginRight
                            ? props.marginRight
                            : '5px',
                        marginLeft: props.marginLeft ? props.marginLeft : null,
                        color: props.color ? props.color : '#3E9CD9',
                        verticalAlign: 'middle',
                    }}
                />
            </span>
        </Tooltip>
    );
};

export default HelpTooltip;
