import React, { useState } from 'react';
import MuiTextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { TextField as FormikTextField } from 'formik-mui';
//import Humanize from 'humanize-plus';
import withStyles from '@mui/styles/withStyles';
import textFieldStyles from '../styles/textFieldStyles';

const isNumeric = (n) => {
    return !isNaN(parseFloat(n));
};
/*
const countCommas = (value) => {
    if (value) {
        return value.split(',').length - 1;
    }
    return 0;
};
*/

const BaseField = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderBottomColor: 'transparent',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
})(FormikTextField);

const TextField = (props) => {
    const {
        InputLabelProps = {},
        field,
        variant,
        endAdornment,
        startAdornment,
        displayOnly,
        ...rest
    } = props;

    const defComponent = () => {
        if (displayOnly) {
            return BaseField;
        } else {
            return props.form ? FormikTextField : MuiTextField;
        }
    };

    const Component = defComponent();
    const classes = textFieldStyles();
    const [decimalLengthError, setDecimalLengthError] = useState(false);
    const [percentageError, setPercentageError] = useState(false);

    /* Handle numeric updates */
    if (props.numeric && field) {
        field.onChange = (e) => {
            if (!isNumeric(e.target.value)) {
                props.form.setFieldValue(field.name, '');
            } else {
                const regex = /[^0-9.]+/g;
                let value = e.target.value.replace(regex, '');
                value = value.split('.');
                let first_part = parseInt(value[0]).toLocaleString('en-US');
                let decimal = value[1];
                if (decimal !== undefined) {
                    if (props.blockchain) {
                        if (decimal.length > 8) {
                            decimal = decimal.slice(0, 8);
                            setDecimalLengthError(true);
                        } else {
                            setDecimalLengthError(false);
                        }
                    } else {
                        if (decimal.length > 2) {
                            decimal = decimal.slice(0, 2);
                            setDecimalLengthError(true);
                        } else {
                            setDecimalLengthError(false);
                        }
                    }

                    first_part += '.' + decimal;
                }
                props.form.setFieldValue(field.name, first_part);
            }

            /*const caret = e.target.selectionStart;
            const element = e.target;

            var num = +e.target.value.replace(/,/g, '');
            if (isNumeric(num)) {
                let ts = Humanize.formatNumber(num);
                let a = 0;

                if (countCommas(field.value) < countCommas(ts)) {
                    a += 1;
                }

                if (countCommas(field.value) > countCommas(ts)) {
                    a -= 1;
                }

                window.requestAnimationFrame(() => {
                    element.selectionStart = caret + a;
                    element.selectionEnd = caret + a;
                });
                props.form.setFieldValue(field.name, ts);
            }*/
        };
    }

    if (props.percentage && field) {
        field.onChange = (e) => {
            if (!isNumeric(e.target.value)) {
                props.form.setFieldValue(field.name, '');
                setPercentageError(true);
            } else {
                let value = e.target.value;
                if (parseInt(value) < 0 || parseInt(value) > 100) {
                    setPercentageError(true);
                } else {
                    setPercentageError(false);
                    props.form.setFieldValue(field.name, parseInt(value));
                }
            }
        };
    }

    return (
        <div>
            <Component
                InputLabelProps={{ shrink: true, ...InputLabelProps }}
                variant={props.variant ? props.variant : 'outlined'}
                field={field}
                InputProps={{
                    endAdornment: endAdornment ? (
                        <InputAdornment position="end">
                            {endAdornment}
                        </InputAdornment>
                    ) : null,
                    startAdornment: startAdornment ? (
                        <InputAdornment position="start">
                            {startAdornment}
                        </InputAdornment>
                    ) : null,
                }}
                {...rest}
            />
            {decimalLengthError && (
                <span className={classes.errorMessage}>
                    *Only up to {props.blockchain ? '8' : '2'} decimal places
                    are allowed.
                </span>
            )}
            {percentageError && (
                <span className={classes.errorMessage}>
                    *Only values between 0 and 100 are allowed.
                </span>
            )}
        </div>
    );
};

export default TextField;
