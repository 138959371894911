import request from '../../utilities/request';
import * as config from '../../config';
import * as exchangeTypes from './exchangeTypes';

export const getExchangesSuccess = (summary) => {
    return {
        type: exchangeTypes.GET_EXCHANGES_SUCCESS,
        payload: summary,
    };
};

export const setExchangesLoading = (loading) => {
    return {
        type: exchangeTypes.SET_EXCHANGES_LOADING,
        payload: loading,
    };
};

export const getExchanges = () => {
    return (dispatch) => {
        dispatch(setExchangesLoading(true));
        request
            .get(`${config.API_BASE_URL}/exchanges`)
            .then((res) => {
                dispatch(getExchangesSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setExchangesLoading(false));
            });
    };
};
