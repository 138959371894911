import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

import { assets } from '../../api';
import { snackbar } from '../../../../utilities/snackbarUtils';
import { errorParser } from '../../utils/errorParser';
import TableComponent from '../Table';

const columns = [
    { headerName: 'Id', field: 'assignmentId' },
    { headerName: 'User Id', field: 'registeredUserId' },
    {
        headerName: 'Amount', field: 'amount',
        cellType: 'truncated',
        useTooltip: true,
        colProps: {
            width: '10%'
        }
    },
    { headerName: 'Receiving Address', field: 'receivingAddress' },
    {
        headerName: 'Distribution Uuid', field: 'distributionUuid',
        cellType: 'truncated',
        useTooltip: true,
        colProps: {
            width: '10%'
        }
    },
    { headerName: 'Distributable', field: 'readyForDistribution' },
    { headerName: 'Vesting Datetime', field: 'vestingDatetime' },
    { headerName: 'Vesting Timestamp', field: 'vestingTimestamp' },
    { headerName: 'Vested', field: 'hasVested' },
    { headerName: 'Distributed', field: 'isDistributed' },
    { headerName: 'Creator', field: 'creator' },
];

const Assignments = ({ assetUuid }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        getAssignments();
    }, [assetUuid]);

    const getAssignments = async () => {
        try {
            const res = (await assets.getAssignments(assetUuid)).data;
            setData(res);
        } catch (err) {
            snackbar.error(errorParser(err));
        }
    };

    return (
        <Box mb={2}>
            <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="body1" textAlign="center">
                    Assignments
                </Typography>
                {!!data.length && (
                    <Box my={2}>
                        <TableComponent columns={columns} items={data} />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default Assignments;
