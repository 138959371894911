import {
    format as formatDate,
    parseISO as parseDate,
    toDate as convertDate,
    isValid as checkDate,
} from 'date-fns';

export const NumberFormatter = (props) => {
    const {
        value,
        decimalPlaces,
        disableGrouping,
        prefix,
        suffix,
        raw,
    } = props;

    const formatterOptions = {};

    if (!raw) {
        formatterOptions.minimumFractionDigits = decimalPlaces
            ? decimalPlaces
            : 0;
        formatterOptions.maximumFractionDigits = decimalPlaces
            ? decimalPlaces
            : 0;
        formatterOptions.useGrouping = !disableGrouping;
    }

    const formatter = new Intl.NumberFormat('en-US', formatterOptions);

    let formattedValue = formatter.format(value ? value : 0);

    if (decimalPlaces > 0 && value > 0) {
        const formattedZero = formatter.format(0);

        if (formattedValue === formattedZero) {
            formattedValue = `< ${formattedValue.slice(0, -1)}1`;
        }
    }

    return (prefix ? prefix : '') + formattedValue + (suffix ? suffix : '');
};

export const PercentageFormatter = (props) => {
    const { value } = props;

    if (typeof value !== 'undefined' && value !== null && value !== '-') {
        return NumberFormatter({
            value: parseFloat(value),
            decimalPlaces: 2,
            suffix: '%',
        });
    }

    return '-';
};

export const CurrencyFormatter = (props) => {
    const {
        value = null,
        currency,
        parsed,
        decimalPlaces,
        disableGrouping,
        disableUnit,
        raw,
    } = props;

    const suffix =
        disableUnit || raw || !currency ? '' : ` ${currency.toUpperCase()}`;
    const parsedValue = parsed
        ? parseFloat(value)
        : parseFloat(value) / 1000000;
    const parsedDecimalPlaces =
        decimalPlaces > 0 ? decimalPlaces : currency === 'bch' ? 8 : 2;

    return isNaN(parsedValue)
        ? value
        : NumberFormatter({
              value: parsedValue,
              decimalPlaces: parsedDecimalPlaces,
              disableGrouping,
              suffix,
              raw,
          });
};

export const DateFormatter = (props) => {
    const { value, format } = props;
    const formats = {
        date: 'MM/dd/yyyy',
        date_raw: 'yyyy-MM-dd',
        date_time: 'MM/dd/yyyy HH:mm',
        year: 'yyyy',
        time: 'HH:mm',
    };
    const rawFormat = formats[format] ? formats[format] : format;
    const date = value
        ? typeof value === 'string'
            ? parseDate(value)
            : convertDate(value)
        : null;

    return checkDate(date) ? formatDate(date, rawFormat) : 'N/A';
};
