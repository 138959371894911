import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import IHQLogoImage from '../../../assets/img/new_logo_digtl.png';

import LoginForm from './LoginForm';
import TwoFactorAuth from './TwoFactorAuth';
import authStyles from '../authStyles';

import BoxWithLogo from '../../../components/Containers/BoxWithLogo';
import { checkStripeReferrer } from '../../../utilities/payments';

const Login = (props) => {
    const { auth, isAdmin } = props;
    const classes = authStyles();
    const title = !isAdmin ? 'Login' : 'Administrative login'

    useEffect(() => {
        checkStripeReferrer()
    }, []);

    return (
        <BoxWithLogo logo={IHQLogoImage} title={title}>
            {!auth.login_hash ? ( // awaiting OTP auth
                <LoginForm isAdmin={isAdmin} classes={classes} />
            ) : (
                <TwoFactorAuth />
            )}
        </BoxWithLogo>
    );
};

const mapStateToProps = ({ auth }) => {
    return {
        auth,
    };
};

export default connect(mapStateToProps, null)(Login);
