import * as blockchainTypes from './blockchainTypes';

const initialState = {
    blockchain: {
        data: null,
        loading: true,
    },
}

const blockchainReducer = (state = initialState, action) => {
    switch (action.type) {
        case blockchainTypes.ISSUE_ON_BLOCKCHAIN_SUCCESS:
            return state
        case blockchainTypes.SET_BLOCKCHAIN_LOADING:
            return {
                ...state,
                blockchain: {
                    ...state.blockchain,
                    loading: action.payload,
                },
            };
        default:
            return state
    }
}

export default blockchainReducer;
