import request from '../../utilities/request';
import * as config from '../../config';
import * as transferAgentTypes from './transferAgentTypes';

import {
    getAccessToken,
    setAccessToken,
    setRefreshToken,
} from '../../utilities/auth';
import {
    setStorageItem,
    getStorageItem,
    clearStorageItem,
} from '../../utilities/storage';

import { getProfile, clearProfile } from '../profile/profileActions';

/**
 * Fetch a list of all issuers.
 */
export const getIssuersSuccess = (issuers) => {
    return {
        type: transferAgentTypes.GET_ISSUERS_SUCCESS,
        payload: issuers,
    };
};

export const setIssuersLoading = (loading) => {
    return {
        type: transferAgentTypes.SET_ISSUERS_LOADING,
        payload: loading,
    };
};

export const getIssuers = (page = 1, search = '') => {
    return (dispatch) => {
        dispatch(setIssuersLoading(true));
        request
            .get(
                `${config.API_BASE_URL}/transfer-agent/issuers?page=${page}&search=${search}`
            )
            .then((res) => {
                dispatch(getIssuersSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setIssuersLoading(false));
            });
    };
};

export const setLoginAsLoading = (loading) => {
    return {
        type: transferAgentTypes.SET_LOGIN_AS_LOADING,
        payload: loading,
    };
};

/**
 * Log in as a different user
 */
export const loginAs = (email, closeSnackbar, enqueueSnackbar, history) => {
    return (dispatch) => {
        closeSnackbar();
        dispatch(setLoginAsLoading(true));

        setStorageItem('ta_token', getAccessToken());

        request
            .post(`${config.API_BASE_URL}/transfer-agent/login-as`, {
                email: email,
            })
            .then((response) => {
                const { token, managed_by_current_ta } = response.data;
                setAccessToken(token);
                setRefreshToken(token);
                //  setAccessToken(access_token);
                //  setRefreshToken(refresh_token);
                localStorage.setItem('user-email', email);
                localStorage.setItem(
                    'managed_by_current_ta',
                    managed_by_current_ta
                );
                // localStorage.setItem('company-logo', companyLogo);

                dispatch(getProfile());

                history.replace('/issuer/issue'); // quick hack for now
            })
            .catch(() => {
                enqueueSnackbar('You cannot log in as this user.', {
                    variant: 'error',
                });
            })
            .finally(() => {
                dispatch(setLoginAsLoading(false));
            });
    };
};

/**
 * Go back to transfer agent account.
 */
export const switchToTransferAgent = (history) => {
    return (dispatch) => {
        /* Replace user data with transfer agent data */
        const token = getStorageItem('ta_token');
        clearStorageItem('ta_token');
        setAccessToken(token);
        setRefreshToken(token);

        clearProfile();
        dispatch(getProfile());

        history.replace({ pathname: '/transfer-agent/' });
    };
};

/*Fetch all transfer agents*/
export const getTAListSuccess = (data) => {
    return {
        type: transferAgentTypes.GET_TRANSFER_AGENTS_LIST_SUCCESS,
        payload: data,
    };
};

export const setTAListLoading = (loading) => {
    return {
        type: transferAgentTypes.SET_TRANSFER_AGENTS_LIST_LOADING,
        payload: loading,
    };
};

export const getTaList = () => {
    return (dispatch) => {
        dispatch(setTAListLoading(true));
        request
            .get(`${config.API_BASE_URL}/transfer-agents/list`)
            .then((res) => {
                dispatch(getTAListSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setTAListLoading(false));
            });
    };
};

/*Fetch all transfer agent partnership requests*/
export const getTARequestsSuccess = (data) => {
    return {
        type: transferAgentTypes.GET_TRANSFER_AGENTS_REQUESTS_SUCCESS,
        payload: data,
    };
};

export const setTARequestsLoading = (loading) => {
    return {
        type: transferAgentTypes.SET_TRANSFER_AGENTS_REQUESTS_LOADING,
        payload: loading,
    };
};

export const getPartnershipRequests = () => {
    return (dispatch) => {
        dispatch(setTARequestsLoading(true));
        request
            .get(`${config.API_BASE_URL}/transfer-agents/partnerships`)
            .then((res) => {
                dispatch(getTARequestsSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setTARequestsLoading(false));
            });
    };
};

export const getTATransfersSuccess = (transfers) => {
    return {
        type: transferAgentTypes.GET_TA_TRANSFERS_SUCCESS,
        payload: transfers,
    };
};

export const setTATransfersLoading = (loading) => {
    return {
        type: transferAgentTypes.SET_TA_TRANSFERS_LOADING,
        payload: loading,
    };
};

export const listTATransfers = (status = null) => {
    return (dispatch) => {
        dispatch(setTATransfersLoading(true));
        request
            .get(
                `${config.API_BASE_URL}/transfer-agents/transfers?status=` +
                    status
            )
            .then((res) => {
                dispatch(getTATransfersSuccess(res));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setTATransfersLoading(false));
            });
    };
};

/* LIst TA users */
export const getAccountUsersSuccess = (account_users) => {
    return {
        type: transferAgentTypes.GET_ACCOUNT_USERS_SUCCESS,
        payload: account_users,
    };
};

export const setAccountUsersLoading = (loading) => {
    return {
        type: transferAgentTypes.SET_ACCOUNT_USERS_LOADING,
        payload: loading,
    };
};

export const listAccountUsers = () => {
    return (dispatch) => {
        dispatch(setAccountUsersLoading(true));
        request
            .get(`${config.API_BASE_URL}/transfer-agent/account-users`)
            .then((res) => {
                dispatch(getAccountUsersSuccess(res));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setAccountUsersLoading(false));
            });
    };
};

/* LIst TA users */
export const getIssuerDocumentsSuccess = (documents) => {
    return {
        type: transferAgentTypes.GET_ISSUER_DOCUMENTS_SUCCESS,
        payload: documents,
    };
};

export const setIssuerDocumentsLoading = (loading) => {
    return {
        type: transferAgentTypes.SET_ISSUER_DOCUMENTS_LOADING,
        payload: loading,
    };
};

export const listIssuerDocuments = (issuerId) => {
    return (dispatch) => {
        dispatch(setIssuerDocumentsLoading(true));
        request
            .get(
                `${config.API_BASE_URL}/transfer-agent/issuer/${issuerId}/documents`
            )
            .then((res) => {
                dispatch(getIssuerDocumentsSuccess(res));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setIssuerDocumentsLoading(false));
            });
    };
};
