import useSWR from "swr"
import { API_BASE_URL } from "../../config"
import fetcher from "../../utilities/fetcher"

const useHolderTransactions = (holderId, assetUuid) => {
    const { data, error, mutate } = useSWR(() => assetUuid ? `${API_BASE_URL}/blockchain/holderTransactions/${holderId}/${assetUuid}` : null, fetcher)

    return {
        holderTransactions: data || [],
        holderTransactionsLoading: !error && !data,
        holderTransactionsError: error,
        updateTransactionsAssets: () => mutate()
    }
}

export default useHolderTransactions