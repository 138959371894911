import makeStyles from '@mui/styles/makeStyles';

const fundraiseStyles = makeStyles((theme) => ({
    floatRight: {
        float: 'right',
    },
    paper: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
        borderRadius: '20px',
        padding: '2rem',
    },
    overviewPaper: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
        borderRadius: '20px',
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    grid: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'left',
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    cursor: {
        cursor: 'pointer',
    },
    fontSize: {
        fontSize: '0.8rem',
    },
    fontSize1: {
        fontSize: '1rem',
    },
    fontSize11: {
        fontSize: '1.1rem',
    },
    fontSize5: {
        fontSize: 5,
    },
    accordianWidth: {
        width: '100%',
    },
    contactDialogButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 15,
        width: '95%',
        textTransform: 'none',
        backgroundColor: '#e0e0e0',
    },
    assessFundabilitySpan: {
        textTransform: 'none',
        fontSize: '1.1rem',
    },
    italicFont: {
        fontStyle: 'italic',
    },
    tableContainer: { marginTop: '1rem' },

    styleTableCell: {
        color: 'white',
        textAlign: 'center',
        fontSize: '1.1rem',
    },
    styleTableCellColor: {
        textAlign: 'center',
        fontFamily: 'Saira',
        fontStyle: 'normal',
        fontWeight: 550,
        fontSize: '18px',
        lineHeight: '28px',
        color: '#333333',
        width: '25%',
        padding: '0.3rem',
    },
    styleTableCellText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '21px',
        alignItems: 'center',
        color: '#7D8696',
        width: '25%',
        paddingLeft: '0.4rem',
        paddingRight: '0.4rem',
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
    },
    imgMarginRight: {
        marginRight: '0.5rem',
    },
    gridMarginBottom: {
        marginBottom: '0.3rem',
    },
    listMarginTop: {
        marginTop: 5,
    },
    typographyMarginBottom: {
        marginBottom: '0.1rem',
    },
    typographyFont: {
        fontSize: '1.1rem',
        color: 'black',
    },
    campaignButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        //padding: '0.75rem 1.5rem',
        marginRight: '1rem',
        marginBottom: '1rem',
        //minWidth: '7rem',
        fontSize: '0.875rem',
        textTransform: 'none',
    },
    campaignDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        marginTop: '1.5rem',
    },
    rightFloat: {
        float: 'right',
    },
    iconColor: {
        color: '#3E9CD9',
        width: '18.91px',
        height: '18.91px',
    },
    dialogTitle: {
        textAlign: 'left',
        fontFamily: 'Saira',
        fontStyle: 'normal',
        fontWeight: 550,
        fontSize: '18px',
        lineHeight: '28px',
        color: '#333333',
        paddingBottom: '0.5rem',
    },
    linkStyle: {
        textDecoration: 'none',
        color: 'black',
    },
    dialogActions: {
        marginTop: '5rem',
        alignItems: 'center',
    },
    listMarginTopN: {
        marginTop: -25,
    },
    gridNoData: {
        textAlign: 'center',
        fontSize: '1.1rem',
    },
    hide: {
        display: 'none',
    },
    show: {
        show: 'block',
    },
    borderBottomNone: {
        borderBottom: 'none',
    },
    title: {
        fontFamily: 'Saira',
        fontStyle: 'normal',
        fontWeight: 550,
        fontSize: '18px',
        lineHeight: '28px',
        color: '#333333',
        paddingBottom: '0.5rem',
    },
    subtitle: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '21px',
        display: 'flex',
        alignItems: 'center',
        color: '#333333',
    },
    subtitleContent: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '21px',
        display: 'flex',
        alignItems: 'center',
        color: '#7D8696',
    },
    primaryDescription: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '21px',
        display: 'flex',
        alignItems: 'center',
        color: '#333333',
        marginBottom: '0.2rem',
    },
    secondaryDescription: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '550',
        fontSize: '18px',
        lineHeight: '21px',
        display: 'flex',
        alignItems: 'center',
        color: '#3E9CD9',
    },
    fullWidth: {
        width: '100%',
    },
    tableHeadTitle: {
        fontFamily: 'Saira',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '22px',
        color: '#7D8696',
        textAlign: 'center',
    },

    deleteIcon: {
        fontFamily: 'Saira',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '25px',
        display: 'flex',
        alignItems: 'center',
        color: '#7D8696',
    },
    documentTitle: {
        fontFamily: 'Saira',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '17px',
        lineHeight: '25px',
        display: 'flex',
        alignItems: 'center',
        color: '#3E9CD9',
    },
    checkboxDescription: {
        fontDamily: 'Saira',
        fontFamily: 'Saira',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        color: '#7D8696',
    },
    uploadButton: {
        border: '1px solid #333333',
        boxSizing: 'border-box',
        borderRadius: '20px',
    },
    browseButton: {
        background: '#3E9CD9',
        borderRadius: '20px',
    },
    republicImage: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        cursor: 'pointer',
    },
}));

export default fundraiseStyles;
