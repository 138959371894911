import React from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PieChartIcon from '@mui/icons-material/PieChart';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ScheduleIcon from '@mui/icons-material/Schedule';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import NoteIcon from '@mui/icons-material/Note';
//import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { connect } from 'react-redux';
import investorStyles from './styles/investorStyles';
import { formatNumber } from '../utilities/utils';
//import { NEXUS_BROWSER } from '../constants/blockchain';

const ShareholderDialog = (props) => {
    const investorClasses = investorStyles();
    const { shareholder, dialogIsOpen, setDialogIsOpen } = props;

    const closeDialog = () => {
        setDialogIsOpen(false);
    };

    return (
        <Dialog
            maxWidth="sm"
            fullWidth
            open={dialogIsOpen}
            onBackdropClick={(e) => setDialogIsOpen(false)}
        >
            <DialogTitle className={investorClasses.dialogTitle}>
                Holder Information
            </DialogTitle>
            <DialogContent>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        {shareholder.loading ? (
                            <Grid
                                container
                                item
                                xs={12}
                                justifyContent="center"
                            >
                                <CircularProgress />
                            </Grid>
                        ) : (
                            <List component="nav">
                                <ListItem>
                                    <ListItemAvatar>
                                        <PersonIcon
                                            fontSize="large"
                                            style={{ color: '#3E9CD9' }}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body2">
                                                <b>Holder</b>
                                            </Typography>
                                        }
                                        secondary={shareholder.data.investor}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <MailOutlineIcon
                                            fontSize="large"
                                            style={{ color: '#3E9CD9' }}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body2">
                                                <b>Email</b>
                                            </Typography>
                                        }
                                        secondary={shareholder.data.email}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <LocationCityIcon
                                            fontSize="large"
                                            style={{ color: '#3E9CD9' }}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body2">
                                                <b>
                                                    Holder residence (address,
                                                    city, state, country)
                                                </b>
                                            </Typography>
                                        }
                                        secondary={`${
                                            shareholder.data.address || 'N/A'
                                        }, ${shareholder.data.city || 'N/A'}, ${
                                            shareholder.data.state || 'N/A'
                                        }, ${
                                            shareholder.data.country || 'N/A'
                                        }`}
                                    />
                                </ListItem>
                                {
                                    // For company-level holders, no need to show company and position again.
                                    shareholder.data.company_name !==
                                        shareholder.data.investor && (
                                        <ListItem>
                                            <ListItemAvatar>
                                                <BusinessIcon
                                                    fontSize="large"
                                                    style={{ color: '#3E9CD9' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="body2">
                                                        <b>
                                                            Holder company and
                                                            position
                                                        </b>
                                                    </Typography>
                                                }
                                                secondary={`${
                                                    shareholder.data
                                                        .company_name || 'N/A'
                                                } — ${
                                                    shareholder.data.position ||
                                                    'N/A'
                                                }`}
                                            />
                                        </ListItem>
                                    )
                                }
                                {/*    {
                                    // NEXUS wallet
                                    shareholder.data.nexus_address && (
                                        <ListItem>
                                            <ListItemAvatar>
                                                <AccountBalanceWalletIcon
                                                    fontSize="large"
                                                    style={{ color: '#3E9CD9' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="body2">
                                                        <b>NEXUS wallet</b>
                                                    </Typography>
                                                }
                                                secondary={
                                                    shareholder.data
                                                        .nexus_address ? (
                                                        <a
                                                            className="no-decoration link"
                                                            href={`${NEXUS_BROWSER}/addressDetail?address=${shareholder.data.nexus_address}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {
                                                                shareholder.data
                                                                    .nexus_address
                                                            }
                                                        </a>
                                                    ) : (
                                                        'N/A'
                                                    )
                                                }
                                            />
                                        </ListItem>
                                    )
                                }*/}
                                <ListItem>
                                    <ListItemAvatar>
                                        <AssessmentIcon
                                            fontSize="large"
                                            style={{ color: '#3E9CD9' }}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body2">
                                                <b>Asset class / category</b>
                                            </Typography>
                                        }
                                        secondary={`${shareholder.data.share_class} / ${shareholder.data.share_category}`}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <PieChartIcon
                                            fontSize="large"
                                            style={{ color: '#3E9CD9' }}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body2">
                                                <b>Fully diluted assets</b>
                                            </Typography>
                                        }
                                        secondary={formatNumber(
                                            shareholder.data.shares
                                        )}
                                    />
                                </ListItem>
                                {shareholder.data && shareholder.data.notes && (
                                    <React.Fragment>
                                        <Divider variant="middle" />
                                        <ListItem>
                                            <ListItemAvatar>
                                                <NoteIcon
                                                    fontSize="large"
                                                    style={{ color: '#3E9CD9' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="body2">
                                                        <b>Notes</b>
                                                    </Typography>
                                                }
                                                secondary={
                                                    shareholder.data.notes
                                                }
                                            />
                                        </ListItem>
                                    </React.Fragment>
                                )}
                                {
                                    // Options
                                    shareholder.data &&
                                        shareholder.data
                                            .options_exercise_price && (
                                            <React.Fragment>
                                                <Divider variant="middle" />
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <PieChartIcon
                                                            fontSize="large"
                                                            style={{
                                                                color:
                                                                    '#3E9CD9',
                                                            }}
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body2">
                                                                <b>
                                                                    Options
                                                                    exercise
                                                                    price
                                                                </b>
                                                            </Typography>
                                                        }
                                                        secondary={formatNumber(
                                                            shareholder.data
                                                                .options_exercise_price
                                                        )}
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <PieChartIcon
                                                            fontSize="large"
                                                            style={{
                                                                color:
                                                                    '#3E9CD9',
                                                            }}
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body2">
                                                                <b>
                                                                    Options
                                                                    grant date /
                                                                    expiration
                                                                    year
                                                                </b>
                                                            </Typography>
                                                        }
                                                        secondary={`${
                                                            shareholder.data
                                                                .options_grant_date ||
                                                            'N/A'
                                                        } / ${
                                                            shareholder.data
                                                                .options_expiration_year ||
                                                            'N/A'
                                                        }`}
                                                    />
                                                </ListItem>
                                            </React.Fragment>
                                        )
                                }
                                {shareholder.data &&
                                    shareholder.data.vesting_start_date && (
                                        <React.Fragment>
                                            <Divider variant="middle" />
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <PieChartIcon
                                                        fontSize="large"
                                                        style={{
                                                            color: '#3E9CD9',
                                                        }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="body2">
                                                            <b>
                                                                Vesting assets
                                                            </b>
                                                        </Typography>
                                                    }
                                                    secondary={formatNumber(
                                                        shareholder.data
                                                            .vesting_shares
                                                    )}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <CalendarTodayIcon
                                                        fontSize="large"
                                                        style={{
                                                            color: '#3E9CD9',
                                                        }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="body2">
                                                            <b>
                                                                Vesting start
                                                                date
                                                            </b>
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        shareholder.data
                                                            .vesting_start_date
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <ScheduleIcon
                                                        fontSize="large"
                                                        style={{
                                                            color: '#3E9CD9',
                                                        }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="body2">
                                                            <b>
                                                                Vesting duration
                                                                / cliff / cliff
                                                                %
                                                            </b>
                                                        </Typography>
                                                    }
                                                    secondary={`${
                                                        shareholder.data
                                                            .vesting_duration_years
                                                    } ${
                                                        shareholder.data
                                                            .vesting_duration_years ===
                                                        1
                                                            ? ' year'
                                                            : ' years'
                                                    } / ${
                                                        shareholder.data
                                                            .vesting_cliff_years
                                                    } ${
                                                        shareholder.data
                                                            .vesting_cliff_years ===
                                                        1
                                                            ? ' year'
                                                            : ' years'
                                                    } / ${
                                                        shareholder.data
                                                            .vesting_cliff_p
                                                    }%`}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <TrendingUpIcon
                                                        fontSize="large"
                                                        style={{
                                                            color: '#3E9CD9',
                                                        }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="body2">
                                                            <b>
                                                                Vesting pro rata
                                                            </b>
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        shareholder.data
                                                            .vesting_pro_rata
                                                    }
                                                />
                                            </ListItem>
                                        </React.Fragment>
                                    )}
                            </List>
                        )}
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <DialogActions
                            style={{
                                marginTop: '1.5rem',
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => {
                                    closeDialog();
                                }}
                            >
                                <b>Close</b>
                            </Button>
                        </DialogActions>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

const mapStateToProps = ({ capTable }) => {
    return {
        shareholder: capTable.capTableEntry,
    };
};

export default connect(mapStateToProps, null)(ShareholderDialog);
