import * as dialogTypes from './dialogTypes'

/**
 * ACTIONS
 */

export const openDialogSuccess = (data) => {
    return {
        type: dialogTypes.OPEN_DIALOG_SUCCESS,
        payload: data
    }
}

export const closeDialogSuccess = () => {
    return {
        type: dialogTypes.CLOSE_DIALOG_SUCCESS
    }
}

export const setDialogLoading = (loading) => {
    return {
        type: dialogTypes.SET_DIALOG_LOADING,
        payload: loading
    }
}

/**
 * ACTION CREATORS
 */

export const openDialog = (title, message, successCallback, cancelText = null, confirmText = null) => {
    return (dispatch) => {
        dispatch(openDialogSuccess({
            open: true,
            title, message, 
            success: successCallback,
            cancelText, confirmText
        }))
    }
}

export const closeDialog = () => {
    return (dispatch) => {
        dispatch(closeDialogSuccess())
    }
}


export const loadDialog = (loading) => {
    return (dispatch) => {
        dispatch(setDialogLoading(loading))
    }
}