import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
} from '@mui/material';

import useHolderAddresses from '../../../../api/blockchain/useHolderAddresses';

import ReceiveIcon from '@mui/icons-material/CallReceived';

import DataLoading from '../Utils/DataLoading';
import WalletAddressButtons from '../Wallets/WalletAddressButtons';
import createReceivingAddress from '../../../../api/blockchain/createReceivingAddress';
import { snackbar } from '../../../../utilities/snackbarUtils';
import { errorParser } from '../../../../components/LiquidForm/utils/errorParser';

const ReceiveAssetsDialog = ({ user, open, handleClose, acc_amp_user_id }) => {
    
    const { holderAddresses, holderAddressesLoading, updateHolderAddresses } = useHolderAddresses(
        user?.id
    );

    const [ addresses, setAddresses ] = useState([]);
    
    const [ refreshingAddresses, setRefreshingAddresses ] = useState(false);
    const [ creatingAddress, setCreatingAddress ] = useState(false);

    useEffect(() => {
        
        let addresses = holderAddresses.slice();

        if (acc_amp_user_id) {
            addresses = addresses.filter(address => address.amp_user_id === acc_amp_user_id);
        }

        setAddresses(addresses);

    }, [holderAddresses]);

    useEffect(() => {
        if (!acc_amp_user_id) createBatchLBTCAddresses();
        else createLBTCAddress(acc_amp_user_id);
    }, []);

    const createBatchLBTCAddresses = async () => {
        setRefreshingAddresses(true);
        return createReceivingAddress(user?.id, 'lbtc')
        .finally(() => {
            updateHolderAddresses();
            setRefreshingAddresses(false);
        });
    }

    const createLBTCAddress = async (amp_user_id) => {
        setCreatingAddress(true);
        return createReceivingAddress(user?.id, 'lbtc', amp_user_id).then(_ => {
            snackbar.success('The L-BTC receiving address has been successfully created.');
            updateHolderAddresses();
        }).catch((error) => {
            snackbar.error(errorParser(error));
        }).finally(() => {
            setCreatingAddress(false);
        });
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xl">
            <DialogTitle>
                <Stack 
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={6}
                >
                    <label style={{ display: 'flex', alignItems: 'center'}}>
                        <ReceiveIcon />
                        Receive
                    </label>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <DataLoading
                    loading={!refreshingAddresses && holderAddressesLoading}
                    text="Loading holder address..."
                />
                <DataLoading
                    loading={creatingAddress || refreshingAddresses}
                    text={`"Creating address${refreshingAddresses && !acc_amp_user_id ? 'es' : ''}..."`}
                />
                {!holderAddressesLoading &&
                    !creatingAddress && !refreshingAddresses &&
                    addresses?.length !== 0 && (
                        <>
                            <Typography variant="body1" as="h5">
                                Address { acc_amp_user_id ? '' : 'es'}
                            </Typography>
                            {
                                addresses.map((holderAddress, index) => (
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        gap={6}
                                        sx={{marginTop: '8px'}}
                                    >
                                        <Stack
                                            direction="column"
                                        >
                                            <Typography variant="body1">
                                                {holderAddress.address}
                                            </Typography>
                                        </Stack>
                                        <WalletAddressButtons
                                            address={holderAddress.address}
                                            onCreateAddress={() => createLBTCAddress(holderAddress.amp_user_id) }
                                        />
                                    </Stack>
                                ))
                            }
                            <br />
                        </>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ReceiveAssetsDialog;
