import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
//import { Link } from 'react-router-dom';
import useStyles from './styles/footerStyles';
import * as Config from '../config';

const Footer = (props) => {
    const { hideRelease = false } = props;
    const classes = useStyles();

    return (
        <footer>
            <Grid container spacing={2} className="footer-content">
                {/* <Grid item xs={12}>
                    <Divider />
                </Grid> */}
                <Grid item xs={12} sm={4} md={4}>
                    {!hideRelease && (
                        <React.Fragment>
                            <Typography variant="caption">
                                Release version: {Config.RELEASE}
                            </Typography>
                            <br />
                        </React.Fragment>
                    )}
                    {/*<Typography variant="caption">
                        <span className={classes.active}>•&nbsp;</span>
                        <em>All Systems Operational</em>
                    </Typography>*/}
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    className={classes.centerText}
                    style={{ margin: 'auto' }}
                >
                    {/*    <span className={`${classes.active} invisible`}>
                        •&nbsp;
                    </span>
                    <Typography variant="caption">
                        <Link
                            to="/issuer/issue/fundraise/verified-partners"
                            className="no-decoration link"
                        >
                            Verified Partners
                        </Link>
                    </Typography>
                    &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                    <Typography variant="caption">
                        <Link
                            to="/issuer/issue/fundraise/funding-options"
                            className="no-decoration link"
                        >
                            Funding options
                        </Link>
                    </Typography>*/}
                </Grid>
                <Grid item xs={12} sm={4} md={4}></Grid>

                <Grid item xs={12}>
                    <br />
                </Grid>
                <Grid item xs={12} className="copyright">
                    <Typography variant="overline" component="p">
                        <b>Copyright © DIGTL 2024</b>
                    </Typography>
                </Grid>
            </Grid>
        </footer>
    );
};

Footer.propTypes = {
    hideRelease: PropTypes.bool,
};

export default Footer;
