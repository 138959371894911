import request from '../../utilities/request';
import * as config from '../../config';
import * as offeringTypes from './offeringTypes';

export const getOfferingsSuccess = (summary) => {
    return {
        type: offeringTypes.GET_OFFERINGS_SUCCESS,
        payload: summary,
    };
};

export const setOfferingsLoading = (loading) => {
    return {
        type: offeringTypes.SET_OFFERINGS_LOADING,
        payload: loading,
    };
};

export const getOfferings = () => {
    return (dispatch) => {
        dispatch(setOfferingsLoading(true));
        request
            .get(`${config.API_BASE_URL}/offerings/interim`)
            .then((res) => {
                dispatch(getOfferingsSuccess(res));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setOfferingsLoading(false));
            });
    };
};

export const getIssuerOfferings = () => {
    return (dispatch) => {
        dispatch(setOfferingsLoading(true));
        request
            .get(`${config.API_BASE_URL}/offerings/interim?user=issuer`)
            .then((res) => {
                dispatch(getOfferingsSuccess(res));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setOfferingsLoading(false));
            });
    };
};
