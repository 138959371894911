import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Input from '../../../../components/Form/Input';
import { snackbar } from '../../../../utilities/snackbarUtils'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { numberRegMatch } from '../../../../constants/blockchain';
import SubmitButton from '../../../../components/Form/SubmitButton';
import reissueAsset from '../../../../api/blockchain/reissueAsset';
import { errorParser } from '../../../../components/LiquidForm/utils/errorParser';

const ReissueDialog = (props) => {
    const schema = yup.object({
        amount: yup
            .string()
            .required('Please enter the amount to reissue.')
            .matches(
                numberRegMatch,
                'Please enter a valid amount.'
            )
            .nullable()
    });

    const defaultValues = {
        amount: ''
    }

    const { handleSubmit, control, reset, formState: { isSubmitting, errors }, } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    const onSubmit = async (data) => {
        // console.log(data)
        await reissueAsset({ asset_uuid: props.asset?.assetUuid, ...data }).then(response => {
            snackbar.success('Asset reissuance started. It should complete in ~2 minutes.');
            reset(defaultValues);
            props.handleClose()
            props.updateAssets()
        }).catch((error) => {
            snackbar.error(errorParser(error));
        })
    };

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Reissue {props.asset?.name} ({props.asset?.ticker})</DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', color: 'gray' }}>
                        <Typography variant='body2'>
                            Mint new asset amounts
                        </Typography>
                    </div>
                    <br />
                    <Input
                        name='amount'
                        error={errors?.amount}
                        control={control}
                        label='Amount'
                        placeholder='0'
                        numeric
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose}>Cancel</Button>
                    <SubmitButton 
                        text='Reissue'
                        disabled={isSubmitting}
                        loading={isSubmitting}
                    />
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default ReissueDialog