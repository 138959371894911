import * as liquidTypes from './liquidBlockstreamTypes';
import { snackbar } from '../../utilities/snackbarUtils';
import {
    greenWallet,
    managers,
    wallet,
    assets,
    category,
} from '../../components/LiquidForm/api';
import { errorParser } from '../../components/LiquidForm/utils/errorParser';

const getCategoriesSuccess = (data) => {
    return {
        type: liquidTypes.LIQUID_NETWORK_GET_CATEGORIES_SUCCESS,
        data: data,
    };
};

const getGreenWalletsSuccess = (greenWallets) => {
    return {
        type: liquidTypes.LIQUID_NETWORK_GET_GREEN_WALLETS_SUCCESS,
        greenWallets,
    };
};
const getAssetsSuccess = (data) => {
    return {
        type: liquidTypes.LIQUID_NETWORK_GET_ASSETS_SUCCESS,
        data: data,
    };
};
const getWalletsSuccess = (data) => {
    return {
        type: liquidTypes.LIQUID_NETWORK_GET_WALLETS_SUCCESS,
        data: data.wallets,
    };
};
const getManagersSuccess = (managers) => {
    return {
        type: liquidTypes.LIQUID_NETWORK_GET_MANAGERS_SUCCESS,
        data: managers,
    };
};
const setLiquidNetworkLoading = (loading) => {
    return {
        type: liquidTypes.LIQUID_NETWORK_SET_LOADING,
        payload: loading,
    };
};

export const getCategories = () => {
    return (dispatch) => {
        dispatch(setLiquidNetworkLoading(true));
        category
            .categories()
            .then((res) => dispatch(getCategoriesSuccess(res.data)))
            .catch((err) => snackbar.error(errorParser(err)))
            .finally(() => dispatch(setLiquidNetworkLoading(false)));
    };
};

export const getAssets = () => {
    return (dispatch) => {
        dispatch(setLiquidNetworkLoading(true));
        assets
            .get()
            .then((res) => dispatch(getAssetsSuccess(res.data)))
            .catch((err) => snackbar.error(errorParser(err)))
            .finally(() => dispatch(setLiquidNetworkLoading(false)));
    };
};

export const getWallets = () => {
    return (dispatch) => {
        dispatch(setLiquidNetworkLoading(true));
        wallet
            .getWallets()
            .then((res) => dispatch(getWalletsSuccess(res.data)))
            .catch((err) => snackbar.error(errorParser(err)))
            .finally(() => dispatch(setLiquidNetworkLoading(false)));
    };
};
export const getManagers = () => {
    return (dispatch) => {
        dispatch(setLiquidNetworkLoading(true));
        managers
            .get()
            .then((res) => dispatch(getManagersSuccess(res.data)))
            .catch((err) => snackbar.error(errorParser(err)))
            .finally(() => dispatch(setLiquidNetworkLoading(false)));
    };
};
export const getGreenWallets = () => {
    return (dispatch) => {
        dispatch(setLiquidNetworkLoading(true));
        greenWallet
            .getWallets()
            .then((res) => dispatch(getGreenWalletsSuccess(res.data)))
            .catch((err) => snackbar.error(errorParser(err)))
            .finally(() => dispatch(setLiquidNetworkLoading(false)));
    };
};
