import React, { useState } from 'react';
import {
    Switch,
    Route,
    NavLink,
    Redirect,
    useRouteMatch,
    useLocation,
} from 'react-router-dom';

import Container from '@mui/material/Container';

import Header from '../components/Header/Header';
import Footer from '../components/Footer';
import StyledTabs from '../components/Tabs/StyledTabs';
import StyledMainTab from '../components/Tabs/StyledMainTab';

import IssueRoutesContainer from './issuer/IssueRoutesContainer';
import ListRoutesContainer from './issuer/ListRoutesContainer';
import * as routes from './issuer/routeList';

import { connect } from 'react-redux';
import Loading from 'react-fullscreen-loading';
import Chat from '../pages/user/Messaging/Chat';
import { SUBSCRIPTION_ENTERPRISE } from '../constants/profile';
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const IssuerRoutes = (props) => {
    let { path } = useRouteMatch();
    const location = useLocation();

    const getActiveTab = () => {
        let pathName = location.pathname;
        if (routes.DASHBOARD_ROUTES.includes(pathName)) {
            return 0;
        } else if (
            routes.CAP_TABLE_ROUTES.concat(
                routes.FUNDRAISE_ROUTES,
                routes.TRANSFERS_ROUTES,
                routes.ISSUE_ROUTES
            ).includes(pathName)
        ) {
            return 1;
        } else if (
            routes.LIST_ROUTES.concat(routes.TRADE_ROUTES).includes(pathName)
        ) {
            return 2;
        } else {
            return 0;
        }
    };

    const [value, setValue] = useState(getActiveTab());
    return (
        <>
            <Header
                type="investor"
                companyLogo={localStorage.getItem('company-logo')}
            />
            <Container
                className="main-container"
                sx={{
                    '@media (min-width: 1200px)': {
                        maxWidth: '1600px',
                        //minWidth: '1550px',
                    },
                }}
            >
                <Loading
                    loading={!props.profile.preferred_tabs}
                    background="#fff"
                    loaderColor="#3E9CD9"
                />
                {props.profile.preferred_tabs && (
                    <main>
                        {props.profile &&
                            props.profile.subscription_model &&
                            props.profile.subscription_model ===
                                SUBSCRIPTION_ENTERPRISE && (
                                <StyledTabs
                                    scrollButtons="auto"
                                    value={value}
                                    onChange={(e, v) => setValue(v)}
                                    variant="scrollable"
                                    aria-label="simple tabs example"
                                    style={{
                                        backgroundColor: '#fff',
                                        color: '#000',
                                    }}
                                    TabIndicatorProps={{
                                        style: { background: '#e0e0e0' },
                                    }}
                                >
                                    {/* <Tab label="Setup Wizard" {...a11yProps(1)} /> */}
                                    {/*{(props.profile.preferred_tabs.captable ||
                                props.profile.preferred_tabs.investors ||
                                props.profile.preferred_tabs.fundraise ||
                                props.profile.preferred_tabs.transfers ||
                                props.profile.preferred_tabs.transfers) && (
                                <StyledMainTab
                                    value={1}
                                    label={<b>Issue</b>}
                                    {...a11yProps(2)}
                                    to={`${path}/issue/`}
                                    component={NavLink}
                                />
                            )}*/}
                                    {props.profile &&
                                        props.profile.subscription_model &&
                                        props.profile.subscription_model ===
                                            SUBSCRIPTION_ENTERPRISE && (
                                            <StyledMainTab
                                                value={1}
                                                label={<b>Issue</b>}
                                                {...a11yProps(2)}
                                                to={`${path}/issue/`}
                                                component={NavLink}
                                            />
                                        )}
                                    {props.profile &&
                                        props.profile.subscription_model &&
                                        props.profile.subscription_model ===
                                            SUBSCRIPTION_ENTERPRISE && (
                                            <StyledMainTab
                                                value={2}
                                                label={
                                                    value === 2 ? (
                                                        <b>List</b>
                                                    ) : (
                                                        'List'
                                                    )
                                                }
                                                to={`${path}/list/`}
                                                component={NavLink}
                                                {...a11yProps(3)}
                                            />
                                        )}
                                </StyledTabs>
                            )}
                        <Switch>
                        <Route exact path={`${path}/`} >
                            <Redirect to={`${path}/issue`} />
                        </Route>
                            <Route
                                path={`${path}/issue`}
                                component={IssueRoutesContainer}
                            />

                            <Route
                                path={`${path}/list`}
                                component={ListRoutesContainer}
                            />
                            <Route path={`${path}/messages`} component={Chat} />
                            <Route
                                path={path}
                                component={IssueRoutesContainer}
                            />
                        </Switch>
                    </main>
                )}
                <Footer hideRelease={true} />
            </Container>
        </>
    );
};

const mapStateToProps = ({ profile }) => {
    return {
        profile: profile,
    };
};

export default connect(mapStateToProps, null)(IssuerRoutes);
