import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as adminActions from '../../../store/admin/adminActions';
import { SCOPE_SUPER_ADMIN } from '../../../constants/profile';

import Grid from '@mui/material/Grid';

import DataTable from '../../../components/DataTable/MuiDataTable';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import AddNewTransferAgent from './AddNewTransferAgent';
import DeleteTransferAgent from './DeleteTransferAgent';
import UpdateAllowedIssuers from './UpdateAllowedIssuers';
import moment from 'moment';

const TransferAgents = (props) => {
    const { transferAgents, adminActions, profile } = props;

    /* Load transfer agent list */
    useEffect(adminActions.getTransferAgents, []);

    return (
        <div>
            <Grid container spacing={2}>
                {/* Transfer agent management (limited to superadmin) */}
                {profile.scope === SCOPE_SUPER_ADMIN && (
                    <React.Fragment>
                        <AddNewTransferAgent />
                        <ConfirmationDialog />{' '}
                        {/* confirm delete, update, etc. */}
                    </React.Fragment>
                )}
                {/* List Transfer agents */}
                <Grid item xs={12}>
                    <DataTable
                        title="Transfer agents"
                        data={transferAgents.data}
                        columns={[
                            { label: 'Name', name: 'name' },
                            { label: 'Email', name: 'email' },
                            {
                                label: 'Added at',
                                name: 'created_at',
                                options: {
                                    customBodyRender: (value) => (
                                        <span>
                                            {moment(value).format(
                                                'DD/MM/YYYY hh:mm:ss'
                                            )}
                                        </span>
                                    ),
                                },
                            },
                            {
                                label: 'Actions',
                                name: 'id',
                                options: {
                                    display:
                                        profile.scope === SCOPE_SUPER_ADMIN
                                            ? true
                                            : 'excluded',
                                    customBodyRender: (value) => (
                                        <>
                                            <UpdateAllowedIssuers id={value} />
                                            <DeleteTransferAgent id={value} />
                                        </>
                                    ),
                                },
                            },
                        ]}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = ({ admin, profile }) => {
    return {
        transferAgents: admin.transferAgents,
        profile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        adminActions: bindActionCreators(adminActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferAgents);
