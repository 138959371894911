import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'

import createAmpCategory from '../../../../api/blockchain/createAmpCategory';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Input from '../../../../components/Form/Input';
import SubmitButton from '../../../../components/Form/SubmitButton';

import { snackbar } from '../../../../utilities/snackbarUtils'
import { errorParser } from '../../../../components/LiquidForm/utils/errorParser';


const CreateCategoryDialog = ({ open, handleClose, updateCategories }) => {
    const schema = yup.object({
        name: yup.string().required('Category name is required.'),
        description: yup.string().required('Category description is required.'),
    });

    const defaultValues = {
        name: '',
        description: '',
    }

    const { handleSubmit, control, reset, formState: { isSubmitting, errors }, } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    const onSubmit = async (data) => {
        console.log(data)
        await createAmpCategory(data).then(response => {
            snackbar.success('Whitelist category successfully created.');
            reset(defaultValues);
            handleClose()
            updateCategories()
        }).catch((error) => {
            snackbar.error(errorParser(error));
        })
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Create a whitelist</DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <div style={{ display: 'flex', color: 'gray' }}>
                        <Typography variant='body2'>
                            Create a new whitelist that will be used to <b>allow</b> holder transfers and distributions.
                        </Typography>
                    </div>
                    <Typography variant='body2' as='p' sx={{ color: 'gray', marginTop: '10px' }}>
                        You will later need to <b>manually</b> add holders in order to <b>whitelist</b> them.
                    </Typography>
                    <br />
                    <Input
                        autoFocus
                        name="name"
                        label="Name"
                        type="text"
                        fullWidth
                        error={errors?.name}
                        control={control}
                        variant="outlined"
                        placeholder="Whitelist name..."
                    />
                    <br />
                    <br />
                    <Input
                        autoFocus
                        name="description"
                        label="Description"
                        type="text"
                        fullWidth
                        error={errors?.description}
                        control={control}
                        variant="outlined"
                        placeholder="Whitelist description..."
                    />
                    {/* <br />
                    <br />
                    <Divider>OR</Divider>
                    <br />
                    <div style={{ display: 'flex', color: 'gray', alignItems: 'center' }}>
                        <Typography variant='body2'>
                            Choose an existing class/category from your cap table.
                        </Typography>
                    </div>
                    <Typography variant='body2' as='p' sx={{ color: 'gray', marginTop: '10px' }}>
                        All holders in it will be <b>automatically whitelisted</b> for the specified category.
                        <br />
                    </Typography>
                    <br />
                    <Autocomplete
                        id="combo-box-demo"
                        options={[
                            { label: 'Preferred', id: 1 },
                            { label: 'Common 1', id: 2 },
                            { label: 'Common 2', id: 3 },
                        ]}
                        multiple
                        fullWidth
                        sx={{ marginTop: '10px;', marginBottom: '20px', float: 'right' }}
                        renderInput={(params) => <TextField {...params} label="Classes/categories" />}
                    />
                    <Typography variant='body2' as='p' sx={{ color: 'gray', marginTop: '10px' }}>
                        <b>Note:</b> Automatic whitelisting will take some time, and you will be notified via email once it is done.
                    </Typography> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <SubmitButton 
                        text='Create'
                        disabled={isSubmitting}
                        loading={isSubmitting}
                    />
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default CreateCategoryDialog