import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import authStyles from '../authStyles';
import * as disclosureRequirementActions from '../../../store/disclosureRequirement/disclosureRequirementActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import FormikSelect from '../../../components/Formik/FormikSelect';
import MenuItem from '@mui/material/MenuItem';
import ContactsIcon from '@mui/icons-material/Contacts';
import DraftsIcon from '@mui/icons-material/Drafts';
import PersonIcon from '@mui/icons-material/Person';
import LabelIcon from '@mui/icons-material/Label';
import ListElement from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import TodayIcon from '@mui/icons-material/Today';
import EventNoteIcon from '@mui/icons-material/EventNote';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import GavelIcon from '@mui/icons-material/Gavel';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { NavLink } from 'react-router-dom';
import EditDisclosureRequirementsDialog from './EditDisclosureRequirementsDialog';
import CircularProgress from '@mui/material/CircularProgress';
import LockedTile from '../../../components/LockedTile';
import { SUBSCRIPTION_ENTERPRISE } from '../../../constants/profile';
let initialValues = {};

const List = (props) => {
    const classes = authStyles();
    let demo, no_listing;

    try {
        demo = props.profile.demo || props.profile.scope === 'list';
        no_listing = localStorage.getItem('user-email').includes('no-listing'); //!props.profile.assessment_submission_completed;
    } catch (e) {
        demo = null;
        no_listing = null;
    }

    const [editDialog, setEditDialog] = useState(false);
    const [editData, setEditData] = useState([]);
    const [key, setKey] = useState(0);
    const [
        disclosureRequirementData,
        setDisclosureRequirementData,
    ] = useState();

    useEffect(props.disclosureRequirementActions.getDisclosureRequirements, []);

    useEffect(() => {
        if (
            props.disclosureRequirements.data &&
            props.disclosureRequirements.data.data.length
        ) {
            setDisclosureRequirementData(
                props.disclosureRequirements.data.data[0]
            );
        }
    }, [props.disclosureRequirements.data]);

    const displayNextBoardMeetingDialog = () => {
        let data = {
            item_value:
                disclosureRequirementData !== undefined
                    ? disclosureRequirementData.next_board_meeting
                    : new Date(),
            item_to_update: 'next_board_meeting',
        };

        setEditData(data);
        setEditDialog(true);
        setKey(key + 1);
    };
    let upcomings = [
        {
            title: 'Next dividend date due soon',
        } /*,
        { title: 'Expected dates for key events due' },*/,
    ];

    let benefits = [
        {
            title:
                'Trade at a fraction of the cost of most liquidity solutions',
        },
        {
            title:
                'List numerous (5+) issuances at significant discounts through a ‘Master Program',
        },
        {
            title:
                'Access a network of global exchanges to leverage regional strategies',
        },
        { title: 'Access global capital, including US retail holders' },
        { title: 'Unlock a potential liquidity premium on your assets' },
        {
            title:
                'No requirement for audited financials for earlier stage issuers',
        },
        {
            title:
                'List any type of security: equities, debt, derivatives, real estate, funds, stablecoins, art/collectibles',
        },
    ];

    let tracks = [
        { title: 'Stay compliant' },
        { title: 'Meet ongoing obligations' },
        { title: 'Search the rules & find templates' },
        { title: 'Ensure timeliness of filings' },
    ];

    let trades = [
        { title: 'Monitor trading activity' },
        { title: 'Access to market making' },
        { title: 'Access to investor onboarding' },
        { title: 'Access to advisory/IR support' },
    ];

    const redirectToListAssessment = () => {
        window.open('https://assess.digtl.co/', '_blank');
    };

    return (
        <LockedTile requiredPlan={SUBSCRIPTION_ENTERPRISE}>
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {}}
            >
                <Form noValidate>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={6} justify="flex-start">
                            <Button
                                component="button"
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={() => redirectToListAssessment()}
                            >
                                <b>ASSESS YOUR READINESS TO LIST</b>
                            </Button>
                            <Typography className={classes.assessmentSubtitle}>
                                Get your score and talk with our team
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} justify="flex-end">
                            <Typography
                                component="div"
                                style={{ textAlign: 'right' }}
                            >
                                <h3>
                                    Listing Status:{' '}
                                    {demo && (
                                        <b style={{ color: '#3E9CD9' }}>
                                            Active
                                        </b>
                                    )}
                                    {no_listing && (
                                        <b style={{ color: '#3E9CD9' }}>
                                            No-Listing
                                        </b>
                                    )}
                                </h3>
                            </Typography>
                        </Grid>
                    </Grid>

                    <br />
                    {no_listing && (
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography>
                                        <h3 style={{ color: '#3E9CD9' }}>
                                            Benefits to listing on a stock
                                            exchange
                                        </h3>
                                    </Typography>
                                    <ListElement>
                                        {benefits.map((benefit, i) => (
                                            <ListItem key={i}>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="p"
                                                            style={{
                                                                fontSize:
                                                                    '1rem',
                                                            }}
                                                        >
                                                            {benefit.title}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                    </ListElement>
                                </Paper>{' '}
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                style={{ textAlign: 'center' }}
                            >
                                <Typography>
                                    <h3 style={{ color: '#3E9CD9' }}>
                                        Once listed on an exchange use DIGTL
                                        listing dashboard to:
                                    </h3>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography>
                                        <h3 style={{ color: '#3E9CD9' }}>
                                            Track
                                        </h3>
                                    </Typography>
                                    <ListElement>
                                        {tracks.map((track, i) => (
                                            <ListItem key={i}>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="p"
                                                            style={{
                                                                fontSize:
                                                                    '1rem',
                                                            }}
                                                        >
                                                            {track.title}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                    </ListElement>
                                </Paper>{' '}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography>
                                        <h3 style={{ color: '#3E9CD9' }}>
                                            Trade
                                        </h3>
                                    </Typography>
                                    <ListElement>
                                        {trades.map((trade, i) => (
                                            <ListItem key={i}>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="p"
                                                            style={{
                                                                fontSize:
                                                                    '1rem',
                                                            }}
                                                        >
                                                            {trade.title}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                    </ListElement>
                                </Paper>
                            </Grid>{' '}
                            <Grid item xs={12} sm={12} md={12}>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography>
                                        <h3
                                            style={{
                                                color: '#3E9CD9',
                                                textAlign: 'center',
                                            }}
                                        >
                                            View our case studies and what
                                            clients are saying{' '}
                                            <a
                                                href="https://drive.google.com/file/d/1n2SIUKs4LgK0nMP3OoZK3OcE_gJKfeVx/view"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                here
                                            </a>
                                        </h3>
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        style={{ textAlign: 'center' }}
                                    >
                                        <b
                                            style={{
                                                color: '#3E9CD9',
                                                fontSize: '1.1rem',
                                                marginRight: '4.1rem',
                                            }}
                                        >
                                            <Button
                                                type="button"
                                                variant="contained"
                                                color="primary"
                                                style={{
                                                    textTransform: 'none',
                                                }}
                                                size="large"
                                                onClick={() =>
                                                    window.open(
                                                        'https://ihq.digtl.co/list_assessment.php',
                                                        '_blank'
                                                    )
                                                }
                                            >
                                                Take the assessment <br />& then
                                                talk to our team
                                            </Button>
                                        </b>
                                    </Typography>
                                </Paper>
                            </Grid>{' '}
                        </Grid>
                    )}

                    {demo && disclosureRequirementData !== undefined ? (
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={8} md={8}>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography
                                        className={classes.title}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <ErrorOutlineIcon
                                            style={{
                                                color: '#ED7571',
                                                verticalAlign: 'middle',
                                            }}
                                        />
                                        Requires attention (
                                        {props.disclosureRequirements.data &&
                                            props.disclosureRequirements.data.data.filter(
                                                (element) => {
                                                    return (
                                                        element.status ===
                                                        'OPEN'
                                                    );
                                                }
                                            ).length}
                                        )
                                    </Typography>

                                    <ListElement>
                                        {props.disclosureRequirements.data &&
                                            props.disclosureRequirements.data.data
                                                .filter((element) => {
                                                    return (
                                                        element.status ===
                                                        'OPEN'
                                                    );
                                                })
                                                .map(
                                                    (attention, i) =>
                                                        attention.status ===
                                                            'OPEN' && (
                                                            <ListItem key={i}>
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            className={
                                                                                classes.listItem
                                                                            }
                                                                        >
                                                                            {
                                                                                attention.title
                                                                            }
                                                                        </Typography>
                                                                    }
                                                                />
                                                                <div
                                                                    style={{
                                                                        color:
                                                                            '#ED7571',
                                                                        fontSize:
                                                                            '1rem',
                                                                    }}
                                                                >
                                                                    OPEN
                                                                </div>
                                                            </ListItem>
                                                        )
                                                )}
                                    </ListElement>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography className={classes.title}>
                                        Knowledge Database
                                    </Typography>
                                    <ListElement>
                                        <ListItem
                                            className={classes.listOverviewLink}
                                            onClick={() =>
                                                window.open(
                                                    'https://merj-files.s3-eu-west-1.amazonaws.com/MERJ+Listing+Rules.pdf',
                                                    '_blank'
                                                )
                                            }
                                        >
                                            <ListItemAvatar>
                                                <GavelIcon
                                                    fontSize="large"
                                                    style={{ color: '#3E9CD9' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        variant="body2"
                                                        component="p"
                                                        className={
                                                            classes.listItemLarge
                                                        }
                                                    >
                                                        Listing Rules
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                        <ListItem
                                            className={classes.listOverviewLink}
                                            onClick={() =>
                                                window.open(
                                                    'https://merj-files.s3-eu-west-1.amazonaws.com/Directive+on+Regular+Reporting+Obligations.pdf',
                                                    '_blank'
                                                )
                                            }
                                        >
                                            <ListItemAvatar>
                                                <AssessmentIcon
                                                    fontSize="large"
                                                    style={{ color: '#3E9CD9' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        variant="body2"
                                                        component="p"
                                                        className={
                                                            classes.listItemLarge
                                                        }
                                                    >
                                                        Reporting Obligations
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                        <ListItem
                                            className={classes.listOverviewLink}
                                            onClick={() =>
                                                window.open(
                                                    'https://s3.eu-west-1.amazonaws.com/merj.exchange/f403a3e77c414444a523322e273928e1.pdf',
                                                    '_blank'
                                                )
                                            }
                                        >
                                            <ListItemAvatar>
                                                <ScheduleIcon
                                                    fontSize="large"
                                                    style={{ color: '#3E9CD9' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        variant="body2"
                                                        component="p"
                                                        className={
                                                            classes.listItemLarge
                                                        }
                                                    >
                                                        Listing Schedules
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </ListElement>
                                </Paper>{' '}
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography className={classes.title}>
                                        Upcoming dates or events:
                                    </Typography>
                                    <ListElement disableGutters>
                                        {upcomings.map((upcoming, i) => (
                                            <ListItem key={i}>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="p"
                                                            className={
                                                                classes.listItem
                                                            }
                                                        >
                                                            {upcoming.title}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                    </ListElement>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={9} md={9}>
                                <Paper elevation={3} className={classes.paper}>
                                    <Grid container>
                                        <Grid item xs={12} sm={8} md={8}>
                                            <Typography>
                                                Complete Listing Advisor Current
                                                Report{' '}
                                                {props.disclosureRequirements
                                                    .data
                                                    .sponsor_advisor_updated ? (
                                                    '(Complete)'
                                                ) : (
                                                    <span
                                                        style={{
                                                            color: '#ED7571',
                                                        }}
                                                    >
                                                        OVERDUE
                                                    </span>
                                                )}
                                                :
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4}>
                                            <Button
                                                component={NavLink}
                                                to={
                                                    '/issuer/list/list/sponsor-advisor-updates'
                                                }
                                                variant="contained"
                                                color="primary"
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <b>Update</b>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <br />
                                    <Grid container>
                                        <Grid item xs={12} sm={8} md={8}>
                                            <Typography>
                                                Next Board Meeting Date to alert
                                                Sponsor Advisor:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4}>
                                            <Button
                                                component="label"
                                                variant="contained"
                                                color="primary"
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                                onClick={() =>
                                                    displayNextBoardMeetingDialog()
                                                }
                                            >
                                                <b>Provide date</b>
                                            </Button>
                                        </Grid>{' '}
                                    </Grid>
                                </Paper>
                            </Grid>{' '}
                        </Grid>
                    ) : demo ? (
                        <Grid container item xs={12} justifyContent="center">
                            <CircularProgress />
                        </Grid>
                    ) : null}

                    {!demo && !no_listing && (
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6}>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography component="div">
                                        <h3 style={{ color: '#3E9CD9' }}>
                                            Important Dates
                                        </h3>

                                        <ListElement>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <EventNoteIcon
                                                        fontSize="large"
                                                        style={{
                                                            color: '#3E9CD9',
                                                        }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="p"
                                                            style={{
                                                                color:
                                                                    '#3E9CD9',
                                                            }}
                                                        >
                                                            Annual General
                                                            Meeting
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <b
                                                                style={{
                                                                    fontSize:
                                                                        '1.1rem',
                                                                    color:
                                                                        'black',
                                                                }}
                                                            >
                                                                {demo
                                                                    ? '10/1/2020'
                                                                    : 'No data'}
                                                            </b>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        </ListElement>
                                        <ListElement>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <InsertInvitationIcon
                                                        fontSize="large"
                                                        style={{
                                                            color: '#3E9CD9',
                                                        }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="p"
                                                            style={{
                                                                color:
                                                                    '#3E9CD9',
                                                            }}
                                                        >
                                                            Next Board Meeting
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <b
                                                                style={{
                                                                    fontSize:
                                                                        '1.1rem',
                                                                    color:
                                                                        'black',
                                                                }}
                                                            >
                                                                {demo
                                                                    ? '11/12/2020'
                                                                    : 'No data'}
                                                            </b>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        </ListElement>
                                        <ListElement>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <CreditCardIcon
                                                        fontSize="large"
                                                        style={{
                                                            color: '#3E9CD9',
                                                        }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="p"
                                                            style={{
                                                                color:
                                                                    '#3E9CD9',
                                                            }}
                                                        >
                                                            Next Financial
                                                            Update
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <b
                                                                style={{
                                                                    fontSize:
                                                                        '1.1rem',
                                                                    color:
                                                                        'black',
                                                                }}
                                                            >
                                                                {demo
                                                                    ? '3/31/2021'
                                                                    : 'No data'}
                                                            </b>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        </ListElement>
                                        <ListElement>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <TodayIcon
                                                        fontSize="large"
                                                        style={{
                                                            color: '#3E9CD9',
                                                        }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="p"
                                                            style={{
                                                                color:
                                                                    '#3E9CD9',
                                                            }}
                                                        >
                                                            Last update to
                                                            Sponsor Advisor
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <b
                                                                style={{
                                                                    fontSize:
                                                                        '1.1rem',
                                                                    color:
                                                                        'black',
                                                                }}
                                                            >
                                                                {demo
                                                                    ? '3/31/2021'
                                                                    : 'No data'}
                                                            </b>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        </ListElement>
                                    </Typography>
                                </Paper>
                            </Grid>{' '}
                            <Grid item xs={12} sm={6} md={6}>
                                <Paper elevation={3} className={classes.paper}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6} sm={5} md={5}>
                                            <Field
                                                component={FormikSelect}
                                                name="equity"
                                                variant="outlined"
                                                fullWidth
                                                label="Exchange"
                                                defaultValue="merj"
                                            >
                                                <MenuItem
                                                    key={'merj'}
                                                    value={'merj'}
                                                >
                                                    MERJ
                                                </MenuItem>
                                                <MenuItem
                                                    key={'fusang'}
                                                    value={'fusang'}
                                                >
                                                    Fusang
                                                </MenuItem>
                                                <MenuItem
                                                    key={'gsx'}
                                                    value={'gsx'}
                                                >
                                                    GSX
                                                </MenuItem>
                                            </Field>
                                        </Grid>
                                        <Grid item xs={6} sm={1} md={1}></Grid>
                                        <Grid item xs={6} sm={5} md={5}>
                                            <Grid container>
                                                <Field
                                                    component={FormikSelect}
                                                    name="security_type"
                                                    variant="outlined"
                                                    label="Security Type"
                                                    fullWidth
                                                    defaultValue="equity"
                                                >
                                                    <MenuItem
                                                        key={'equity'}
                                                        value={'equity'}
                                                    >
                                                        {'Equity'}
                                                    </MenuItem>
                                                    <MenuItem
                                                        key={'debt'}
                                                        value={'debt'}
                                                    >
                                                        {'Debt'}
                                                    </MenuItem>
                                                    <MenuItem
                                                        key={'derivative'}
                                                        value={'derivative'}
                                                    >
                                                        {'Derivative'}
                                                    </MenuItem>
                                                </Field>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <ListElement>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <LabelIcon
                                                    fontSize="large"
                                                    style={{ color: '#3E9CD9' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        variant="body2"
                                                        component="p"
                                                        style={{
                                                            color: '#3E9CD9',
                                                        }}
                                                    >
                                                        Ticker
                                                    </Typography>
                                                }
                                                secondary={
                                                    <React.Fragment>
                                                        <b
                                                            style={{
                                                                fontSize:
                                                                    '1.1rem',
                                                                color: 'black',
                                                            }}
                                                        >
                                                            {demo
                                                                ? 'CEO'
                                                                : 'No data'}
                                                        </b>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                    </ListElement>

                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            textAlign: 'left',
                                        }}
                                    >
                                        <ListElement>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <ContactsIcon
                                                        fontSize="large"
                                                        style={{
                                                            color: '#3E9CD9',
                                                        }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="p"
                                                            style={{
                                                                color:
                                                                    '#3E9CD9',
                                                            }}
                                                        >
                                                            Listing Advisor:
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <b
                                                                style={{
                                                                    fontSize:
                                                                        '1.1rem',
                                                                    color:
                                                                        'black',
                                                                }}
                                                            >
                                                                {demo
                                                                    ? 'DIGTL'
                                                                    : 'No data'}
                                                            </b>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        </ListElement>

                                        <ListElement>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <PersonIcon
                                                        fontSize="large"
                                                        style={{
                                                            color: '#3E9CD9',
                                                        }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="p"
                                                            style={{
                                                                color:
                                                                    '#3E9CD9',
                                                            }}
                                                        >
                                                            Key Contact:
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <b
                                                                style={{
                                                                    fontSize:
                                                                        '1.1rem',
                                                                    color:
                                                                        'black',
                                                                }}
                                                            >
                                                                {demo
                                                                    ? 'Kyle Fry - President'
                                                                    : 'No data'}
                                                            </b>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        </ListElement>

                                        <ListElement>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <DraftsIcon
                                                        fontSize="large"
                                                        style={{
                                                            color: '#3E9CD9',
                                                        }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="p"
                                                            style={{
                                                                color:
                                                                    '#3E9CD9',
                                                            }}
                                                        >
                                                            Contact email:
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <b
                                                                style={{
                                                                    fontSize:
                                                                        '1.1rem',
                                                                    color:
                                                                        'black',
                                                                }}
                                                            >
                                                                {demo
                                                                    ? 'kyle@digitalmarkets.ai'
                                                                    : 'No data'}
                                                            </b>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        </ListElement>
                                    </Grid>
                                </Paper>
                            </Grid>{' '}
                        </Grid>
                    )}
                    <EditDisclosureRequirementsDialog
                        open={editDialog}
                        data={editData}
                        dialogTitle={'Next Board Meeting Date'}
                        fieldLabel={'Select next board meeting date'}
                        inputType={'date'}
                        key={key}
                    />
                </Form>
            </Formik>
        </LockedTile>
    );
};

const mapStateToProps = ({ disclosureRequirements, profile }) => {
    return {
        disclosureRequirements: disclosureRequirements,
        profile: profile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        disclosureRequirementActions: bindActionCreators(
            disclosureRequirementActions,
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
