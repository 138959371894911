import React, { useState, useEffect } from 'react'
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup';
import axios from 'axios';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import BoxWithLogo from '../../../components/Containers/BoxWithLogo'
import IHQLogoImage from '../../../assets/img/new_logo_digtl.png';
import PasswordField from '../../../components/PasswordField';
import TextField from '../../../components/TextField/TextField';

import authStyles from '../authStyles';
import * as config from './../../../config';
import { snackbar } from '../../../utilities/snackbarUtils';
import { handleLogin } from '../../../utilities/login';

const ContainerWrapper = ({ children, isDone }) => {
    const classes = authStyles();

    return (
        <>
            <BoxWithLogo logo={IHQLogoImage} title={"Join DIGTL"}>
                {children}
                {
                    !isDone && 
                    <Typography variant="body1" className="centered">
                        <Link className={classes.link} component={RouterLink} to='/login'>
                            Back to Login
                        </Link>
                    </Typography>
                }
            </BoxWithLogo>
        </>
    )
}

let initialValues = {
    password: '', 
    password_confirmation: '',
};

const validationSchema = Yup.object({
    first_name: Yup.string().required('Please enter your first name'),
    last_name: Yup.string().required('Please enter your last name'),
    email: Yup.string()
                .email('Please enter a valid email address')
                .required('Please enter your email address'),
    password: Yup.string()
        .required('Please enter your password.')
        //  .min(8, 'Password is too short - must containt at least 8 characters.')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,

            'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, number and one symbol.'
        ),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match.')
        .required('Please confirm your password.'),
});

const CreateNewAccount = (props) => {
    const classes = authStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { hash } = useParams()

    const [isValidating, setIsValidating] = useState(true)
    const [isValid, setIsValid] = useState(false)
    
    const [issuer, setIssuer] = useState('')

    useEffect(() => {
        axios.get(`${config.API_BASE_URL}/check-issuer-invite?token=${hash}`)
            .then(response => {
                const { first_name, last_name, email, issuer } = response.data
                initialValues['first_name'] = first_name
                initialValues['last_name'] = last_name
                initialValues['email'] = email
                setIssuer(issuer)
                setIsValid(true)
            }).catch(error => {
                snackbar.error('Your invitation link is invalid or expired.')
            }).finally(() => {
                setIsValidating(false)
            })
    }, [hash])

    if (isValidating) {
        return (
            <ContainerWrapper>
                <Grid
                    container
                    alignContent="center"
                    alignItems="center"
                >
                    <CircularProgress style={{ marginRight: 10 }} />
                    <b>Checking invite link...</b>
                </Grid>
            </ContainerWrapper>
        )
    } else {
        return isValid ? (
            <ContainerWrapper>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={
                            (values, { setSubmitting }) => {
                                const accountData = {
                                    ...values,
                                    token: hash
                                }
                                axios
                                    .post(`${config.API_BASE_URL}/create-issuer-account`, accountData)
                                    .then(() => {
                                        handleLogin(values['email'], values['password'], history, dispatch)
                                    })
                                    .catch(() => {
                                        snackbar.error('There has been an error. Please try again.')
                                    }).finally(() => {
                                        setSubmitting(false);
                                    })
                        }}
                    >
                    {({ isSubmitting }) => (                        
                        <Form>
                            <Typography variant="body1" className="m-b-10">
                                Join DIGTL as an issuer at <b>{issuer}</b>
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Field
                                        component={TextField}
                                        type="email"
                                        label="Email"
                                        name="email"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="First name"
                                        name="first_name"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />                                  
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Last name"
                                        name="last_name"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />                      
                                </Grid>
                                <Grid item xs={12}>
                                    <PasswordField
                                        name="password"
                                        label="Password"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <PasswordField
                                        name="password_confirmation"
                                        label="Confirm Password"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.actions}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            disabled={isSubmitting}
                                        >
                                            {!isSubmitting ? (
                                                <span>Create Account</span>
                                            ) : (
                                                <CircularProgress size={30} />
                                            )}
                                        </Button>
                                    </div>
                                    </Grid>
                            </Grid>
                        </Form>
                    )}
                    </Formik>
            </ContainerWrapper>
        ) : (
            <ContainerWrapper>
                <h3>Your invitation link is invalid or expired.</h3>
            </ContainerWrapper>
        )
    }
}

export default CreateNewAccount
