import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import authStyles from '../authStyles';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import StyledProgress from './StyledProgress';
import withStyles from '@mui/styles/withStyles';
import request from '../../../utilities/request';
import * as config from './../../../config';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Container from '@mui/material/Container';
import Humanize from 'humanize-plus';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import fundraiseStyles from '../../../components/styles/fundraiseStyles';
import sta from '../../../assets/img/sta.png';
import merj from '../../../assets/img/merj.png';
import gsx from '../../../assets/img/gsx.png';
import fusang from '../../../assets/img/fusang.png';
import techemy from '../../../assets/img/techemy.png';
import acceleprise from '../../../assets/img/acceleprise.png';
import republic from '../../../assets/img/republic.png';
import front_fundr from '../../../assets/img/frontfundr.png';
import seeders from '../../../assets/img/seeders.png';
import ihq from '../../../assets/img/ihq.png';
import plaza from '../../../assets/img/plaza.jpg';
import cambridge_wilkson from '../../../assets/img/cambridge_wilkson.jpg';
import castle from '../../../assets/img/castle.jpg';
import fund_america from '../../../assets/img/fund_america.png';
import Chart from 'react-apexcharts';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import PeopleIcon from '@mui/icons-material/People';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DateIcon from '@mui/icons-material/DateRange';
import OfferingIcon from '@mui/icons-material/LocalOffer';
import PieChartIcon from '@mui/icons-material/PieChart';
import { DateFormatter } from '../../../utilities/formatters';
import Offering from './issuer/Offering';
import Investment from './investor/Investment';
import AddIcon from '@mui/icons-material/Add';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import LockedTile from '../../../components/LockedTile';
import { SUBSCRIPTION_ENTERPRISE } from '../../../constants/profile';

const StyledTableCell = withStyles((theme) => ({
    head: {
        color: 'black',
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        height: '50px',
        '&:nth-of-type(odd)': {
            height: '50px',
        },
    },
}))(TableRow);

function createData(
    alternative,
    short_description,
    typical_cost_structure,
    verified_partners
) {
    return {
        alternative,
        short_description,
        typical_cost_structure,
        verified_partners,
    };
}

const rows = [
    createData(
        'Advisory /\nConsulting',
        'In-depth analysis of potential fundraising options and liquidity solutions',
        'Monthly retainer (not success-based)\n(estimate $5-10K per month)',
        [sta, techemy]
    ),
    createData(
        'Self-managed',
        'Use whitelabeling service to build custom website for your raise. Integrated investor onboarding with KYC/AML, funding and documents support.  That can be used in conjunction with you looking for Venture Capital funding as well.',
        'One-time up front and additional monthly costs during campaign. KYC/AML and investor accreditation charged as they occur.\n(estimate $5-15K total)',
        [ihq, fund_america]
    ),
    createData(
        'Venture capital /\nAngel investing',
        'Need to match stage of your company with stage of the fund/individual. Typically low odds to receive funding this route.  Will require deep diligence and potentially board seats.',
        'Estimate low cost up front, though typically most favorably terms for VC, board seats\n(estimate mostly time against low success rates)',
        [plaza, acceleprise]
    ),
    createData(
        'Crowdfunding',
        'Access retail holders. Typically based on where issuer is incorporated. Fundraising limits exist.',
        '$0 to minimal upfront direct cash cost. % of raise in cash and equity\n(up to 5% cash and 5% equity)',
        [republic, seeders, front_fundr]
    ),
    createData(
        'Investment bank /\nbroker-dealer',
        'Typically for later stage/post-revenue. Helps to have already done an earlier seed round. Receive significant support on preparation and your story/marketing materials by investment bankers',
        'Monthly retainer, % of successful raise and maybe equity/warrants\n(estimate $5-10K monthly / 5-10% of raise amount / potential 5-10% in “warrants” of raise amount)',
        [castle, cambridge_wilkson]
    ),
    createData(
        'Primary offering\non stock exchange',
        'Access to global holders and support secondary trading. Requires infrastructure to support ongoing disclosure requirements, which are significantly less onerous than typical public company reporting',
        'One-time up front and additional on going listing costs.\n(estimate $50-100K by listing + minimal ongoing)',
        [merj, gsx, fusang]
    ),
];

let initialValues = {};
const Overview = (props) => {
    const classes = authStyles();
    const fundraiseClasses = fundraiseStyles();
    const [openContactDialog, setOpenContactDialog] = useState(false);
    const [tablePanel, setTablePanel] = useState(false);
    const [lineChartData, setLineChartData] = useState([]);
    const [pieChartData, setPieChartData] = useState([]);
    const [companyData, setCompanyData] = useState([]);
    const [offeringDialogOpen, setOfferingDialogOpen] = useState(false);
    const [investmentDialogOpen, setInvestmentDialogOpen] = useState(false);
    const [key, setKey] = useState(0);
    const [investmentKey, setInvestmentKey] = useState(0);
    
    useEffect(() => {
        async function fetchData() {
            const response = await request.get(
                `${config.API_BASE_URL}/offerings/overview`
            );
            if (response.data.data) {
                setLineChartData(response.data.data.line_chart_data);
                setPieChartData(response.data.data.pie_chart_data);
                setCompanyData(response.data.data.data);
            }
        }

        fetchData();
    }, []);
    console.log(lineChartData  )


    const closeContactDialog = () => {
        setOpenContactDialog(false);
    };

    const displayContactDialog = () => {
        setOpenContactDialog(true);
    };

    const handleAccordionChange = () => {
        setTablePanel(!tablePanel);
    };

    const displayOfferingDialog = () => {
        setOfferingDialogOpen(true);
        setKey(key + 1);
    };

    const displayInvestmentDialog = () => {
        setInvestmentDialogOpen(true);
        setInvestmentKey(key + 1);
    };

    return (
        <LockedTile requiredPlan={SUBSCRIPTION_ENTERPRISE}>
            <Formik onSubmit={(values, { setSubmitting }) => {}}>
                <Form noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                                <div>
                                    <div className={classes.alignEnd}>
                                        <Button
                                            component="label"
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            className={classes.alignCenter}
                                            style={{ marginRight: '1rem' }}
                                            onClick={() =>
                                                displayOfferingDialog()
                                            }
                                        >
                                            <AddIcon />
                                            Create Campaign
                                        </Button>
                                        <Button
                                            component="label"
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            onClick={() =>
                                                displayInvestmentDialog()
                                            }
                                        >
                                            <AddIcon />
                                            Create "Invest Now" Button
                                        </Button>
                                    </div>
                                    <br />
                                </div>

                            <Paper
                                elevation={3}
                                className={fundraiseClasses.overviewPaper}
                            >
                                <Accordion expanded={tablePanel}>
                                    <AccordionSummary>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={8}
                                                md={8}
                                                style={{ marginTop: '1rem' }}
                                            >
                                                <Typography
                                                    className={
                                                        fundraiseClasses.title
                                                    }
                                                >
                                                    Show Fundraising
                                                    Alternatives
                                                </Typography>

                                                <Typography
                                                    className={
                                                        fundraiseClasses.subtitleContent
                                                    }
                                                >
                                                    See what it takes to Raise
                                                    Money
                                                </Typography>
                                                <br />
                                                <Typography
                                                    className={
                                                        fundraiseClasses.subtitleContent
                                                    }
                                                >
                                                    Below is a relatively
                                                    exhaustive list of
                                                    fundraising alternatives
                                                    before your big IPO. Note:
                                                    in most instances you can
                                                    combine one or more options
                                                    to increase your chances of
                                                    fundraising success and
                                                    speed up the process. This
                                                    may require legal,
                                                    accounting and marketing or
                                                    other services support.
                                                    Advisory services may help
                                                    identify your need.
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={4}
                                                md={4}
                                                style={{
                                                    marginTop: '1rem',
                                                }}
                                            >
                                                <Grid
                                                    container
                                                    justify="flex-end"
                                                >
                                                    <Button
                                                        component="button"
                                                        variant="contained"
                                                        color="primary"
                                                        size="large"
                                                        onClick={() => {
                                                            displayContactDialog();
                                                        }}
                                                    >
                                                        <b>
                                                            ASSESS YOUR
                                                            FUNDABILITY
                                                        </b>
                                                    </Button>
                                                </Grid>
                                                <Typography
                                                    className={
                                                        classes.assessmentSubtitle
                                                    }
                                                >
                                                    Get your score and talk to
                                                    our team.
                                                </Typography>
                                            </Grid>
                                            <br />
                                            <hr
                                                style={{
                                                    border: '1px solid #CBCFD4',
                                                    width: '100%',
                                                }}
                                            />
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                style={{
                                                    marginTop: '1rem',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <ExpandMoreIcon
                                                    onClick={() =>
                                                        handleAccordionChange()
                                                    }
                                                    fontSize="large"
                                                    className={`ihq-blue
                                                        ${
                                                            !tablePanel
                                                                ? fundraiseClasses.show
                                                                : fundraiseClasses.hide
                                                        }`}
                                                />
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <TableContainer
                                        className={
                                            fundraiseClasses.tableContainer
                                        }
                                    >
                                        <Table
                                            className={classes.table}
                                            aria-label="customized table"
                                        >
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <StyledTableRow
                                                        key={row.alternative}
                                                    >
                                                        <StyledTableCell
                                                            component="th"
                                                            scope="row"
                                                            className={`${
                                                                fundraiseClasses.styleTableCellColor
                                                            }
                                                                ${
                                                                    row.alternative ===
                                                                    'Primary offering\non stock exchange'
                                                                        ? fundraiseClasses.borderBottomNone
                                                                        : null
                                                                }`}
                                                        >
                                                            <b>
                                                                {
                                                                    row.alternative
                                                                }
                                                            </b>
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            className={
                                                                fundraiseClasses.styleTableCellText
                                                            }
                                                        >
                                                            {
                                                                row.short_description
                                                            }
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            className={
                                                                fundraiseClasses.styleTableCellText
                                                            }
                                                        >
                                                            {
                                                                row.typical_cost_structure
                                                            }
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            {row.verified_partners.map(
                                                                (partner) => (
                                                                    <img
                                                                        className={
                                                                            fundraiseClasses.imgMarginRight
                                                                        }
                                                                        src={
                                                                            partner
                                                                        }
                                                                        width={
                                                                            80
                                                                        }
                                                                        alt=""
                                                                        key={
                                                                            partner
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        style={{
                                            marginTop: '1rem',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <ExpandLessIcon
                                            fontSize="large"
                                            className={`ihq-blue
                                            ${
                                                !tablePanel
                                                    ? fundraiseClasses.hide
                                                    : fundraiseClasses.show
                                            }`}
                                            onClick={() =>
                                                handleAccordionChange()
                                            }
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </Grid>
                                </Accordion>
                            </Paper>
                        </Grid>
                    </Grid>
                    <br />

                    <LockedTile>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={5} md={5}>
                                <Paper
                                    elevation={3}
                                    style={{ minHeight: '40rem' }}
                                    className={fundraiseClasses.paper}
                                >
                                    <Typography
                                        className={fundraiseClasses.title}
                                    >
                                        Offering Summary
                                    </Typography>

                                    <Grid
                                        container
                                        className={
                                            fundraiseClasses.gridMarginBottom
                                        }
                                    >
                                        <Grid
                                            item
                                            xs={5}
                                            className={
                                                fundraiseClasses.subtitle
                                            }
                                        >
                                            <b>Company Name</b>
                                        </Grid>
                                        <Grid item xs={7}>
                                            {companyData?.company_name}
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        className={
                                            fundraiseClasses.gridMarginBottom
                                        }
                                    >
                                        <Grid
                                            item
                                            xs={5}
                                            className={
                                                fundraiseClasses.subtitle
                                            }
                                        >
                                            <b>Company Description</b>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={7}
                                            className={
                                                fundraiseClasses.subtitleContent
                                            }
                                        >
                                            {
                                                companyData?.company_short_description
                                            }{' '}
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        className={
                                            fundraiseClasses.gridMarginBottom
                                        }
                                    >
                                        <Grid
                                            item
                                            xs={5}
                                            className={
                                                fundraiseClasses.subtitle
                                            }
                                        >
                                            <b>Company Website</b>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={7}
                                            className={
                                                fundraiseClasses.subtitleContent
                                            }
                                        >
                                            {companyData?.company_website}
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        className={
                                            fundraiseClasses.gridMarginBottom
                                        }
                                    >
                                        <Grid
                                            item
                                            xs={5}
                                            className={
                                                fundraiseClasses.subtitle
                                            }
                                        >
                                            <b>Company Location</b>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={7}
                                            className={
                                                fundraiseClasses.subtitleContent
                                            }
                                        >
                                            {companyData?.company_location}
                                        </Grid>
                                    </Grid>

                                    <React.Fragment>
                                        <List
                                            className={
                                                fundraiseClasses.listMarginTop
                                            }
                                            component="div"
                                        >
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <OfferingIcon
                                                        fontSize="large"
                                                        className={ fundraiseClasses.iconColor }
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="div"
                                                            className={ fundraiseClasses.primaryDescription }
                                                        >
                                                            Offering Status %
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <Typography component="div">
                                                            <b
                                                                className={
                                                                    fundraiseClasses.secondaryDescription
                                                                }
                                                            >
                                                                    <span
                                                                        className={
                                                                            fundraiseClasses.fullWidth
                                                                        }
                                                                    >
                                                                        <StyledProgress value={(companyData?.raised_amount/companyData?.target_amount) * 100}/>
                                                                    </span>
                                                              
                                                            </b>
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <PieChartIcon
                                                        fontSize="large"
                                                        className={ fundraiseClasses.iconColor }
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    component="div"
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="div"
                                                            className={
                                                                fundraiseClasses.primaryDescription
                                                            }
                                                        >
                                                            Soft Cap: Met / Not
                                                            Met
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <b
                                                            className={
                                                                fundraiseClasses.secondaryDescription
                                                            }
                                                        >
                                                            {companyData?.raised_amount} / {companyData?.target_amount}
                                                        </b>
                                                    }
                                                />
                                            </ListItem>
                                        </List>
                                    </React.Fragment>

                                    <React.Fragment>
                                        <List
                                            className={
                                                fundraiseClasses.listMarginTopN
                                            }
                                        >
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <DateIcon
                                                        fontSize="large"
                                                        className={fundraiseClasses.iconColor}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="p"
                                                            className={
                                                                fundraiseClasses.primaryDescription
                                                            }
                                                        >
                                                            Start Date / End
                                                            Date
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <b
                                                            className={
                                                                fundraiseClasses.secondaryDescription
                                                            }
                                                        >
                                                            {companyData?.start_date &&
                                                                DateFormatter(
                                                                    {
                                                                        value:
                                                                            companyData?.start_date,
                                                                        format:
                                                                            'date',
                                                                    }
                                                            )}
                                                            <br/>
                                                            {companyData?.end_date &&
                                                                DateFormatter(
                                                                    {
                                                                        value:
                                                                            companyData?.end_date,
                                                                        format:
                                                                            'date',
                                                                    }
                                                            )}
                                                        </b>
                                                    }
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <PeopleIcon
                                                        fontSize="large"
                                                        className={ fundraiseClasses.iconColor }
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="p"
                                                            className={
                                                                fundraiseClasses.primaryDescription
                                                            }
                                                        >
                                                            Total holders
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <b
                                                                className={
                                                                    fundraiseClasses.secondaryDescription
                                                                }
                                                            >
                                                            {companyData?.total_holders}
                                                            </b>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        </List>
                                    </React.Fragment>
                                </Paper>
                            </Grid>

                            <Grid item xs={12} sm={7} md={7}>
                                <Paper
                                    elevation={3}
                                    className={fundraiseClasses.paper}
                                    style={{ minHeight: '40rem' }}
                                >
                                    <Typography
                                        className={fundraiseClasses.title}
                                    >
                                        Fundraising
                                    </Typography>
                                    {pieChartData && pieChartData.labels?.length ? 
                                            (<Chart
                                                options={{
                                                    labels: pieChartData.labels,
                                                    colors: [
                                                        '#266086',
                                                        '#3488BE',
                                                        '#3E9CD9',
                                                    ],
                                                    plotOptions: {
                                                        pie: {
                                                            donut: {
                                                                labels: {
                                                                    show: true,
                                                                    value: {
                                                                        show: true,
                                                                    },
                                                                    total: {
                                                                        show: false,
                                                                    },
                                                                },
                                                            },
                                                        },
                                                    },
                                                    tooltip: {
                                                        y: {
                                                            formatter: function (
                                                                val
                                                            ) {
                                                                return Humanize.formatNumber(
                                                                    val,
                                                                    2
                                                                );
                                                            },
                                                        },
                                                    },
                                                }}
                                                series={pieChartData.series}
                                                width="100%"
                                                type="donut"
                                                height={250}/>): null
                                    }
                                    {lineChartData && lineChartData.series?.length && lineChartData.categories?.length &&
                                            <Chart
                                                options={{
                                                    chart: {
                                                        type: 'bar',
                                                        height: 300,
                                                    },
                                                    legend: {
                                                        display: false,
                                                    },
                                                    plotOptions: {
                                                        bar: {
                                                            horizontal: false,
                                                            columnWidth: '55%',
                                                            // endingShape: 'rounded'
                                                        },
                                                    },
                                                    colors: [
                                                        '#266086',
                                                        '#3488BE',
                                                        '#3E9CD9',
                                                    ],
                                                    dataLabels: {
                                                        enabled: false,
                                                    },
                                                    stroke: {
                                                        show: true,
                                                        width: 2,
                                                        colors: ['transparent'],
                                                    },
                                                    xaxis: {
                                                        categories: lineChartData.categories?.length
                                                            ? lineChartData.categories
                                                            : [],
                                                    },
                                                    yaxis: {
                                                        title: {
                                                            text: 'Fundraising',
                                                        },
                                                    },
                                                    fill: {
                                                        opacity: 1,
                                                    },
                                                    tooltip: {
                                                        y: {
                                                            formatter: function (
                                                                val
                                                            ) {
                                                                return Humanize.formatNumber(
                                                                    val,
                                                                    2
                                                                );
                                                            },
                                                        },
                                                    },
                                                }}
                                                series={
                                                    lineChartData.series
                                                        ? lineChartData.series
                                                        : []
                                                }
                                                width="100%"
                                                type="bar"
                                                height={250}
                                            />
                                        }
                                </Paper>
                            </Grid>
                        </Grid>
                    </LockedTile>
                    <div className={fundraiseClasses.campaignDiv}>
                        <Button
                            component="label"
                            variant="contained"
                            color="primary"
                            className={fundraiseClasses.campaignButton}
                            disabled={true}
                        >
                            Visit Campaign Page
                        </Button>
                    </div>
                    <Dialog
                        fullWidth
                        open={openContactDialog}
                        onBackdropClick={() => closeContactDialog()}
                    >
                        <DialogTitle>
                            <Typography
                                className={fundraiseClasses.dialogTitle}
                            >
                                ASSESS YOUR FUNDABILITY
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container justify="left">
                                <Typography>
                                    Please use the &nbsp;
                                    <a
                                        target="_blank"
                                        href="https://digtl.co/contact"
                                        rel="noopener noreferrer"
                                        className={fundraiseClasses.linkStyle}
                                    >
                                        <b>Contact form</b>
                                    </a>
                                    &nbsp;to Upgrade your current subscription
                                    plan{' '}
                                </Typography>
                                <Grid item xs={12} md={12}>
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={(
                                            values,
                                            { setSubmitting }
                                        ) => {}}
                                    >
                                        {({ isSubmitting }) => (
                                            <Container>
                                                <Form
                                                    className={classes.form}
                                                    noValidate
                                                >
                                                    <DialogActions
                                                        className={
                                                            fundraiseClasses.dialogActions
                                                        }
                                                    >
                                                        <Button
                                                            color="secondary"
                                                            variant="outlined"
                                                            onClick={() => {
                                                                closeContactDialog();
                                                            }}
                                                        >
                                                            <b>Close</b>
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => {
                                                                window.open(
                                                                    'https://digtl.co/contact',
                                                                    '_blank'
                                                                );
                                                            }}
                                                        >
                                                            <b>Contact Form</b>
                                                        </Button>
                                                    </DialogActions>
                                                </Form>
                                            </Container>
                                        )}
                                    </Formik>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                    <Offering open={offeringDialogOpen} key={key} />
                    <Investment
                        open={investmentDialogOpen}
                        key={investmentKey}
                    />
                </Form>
            </Formik>
        </LockedTile>
    );
};

const mapStateToProps = ({ profile }) => {
    return {
        profile: profile,
    };
};

export default connect(mapStateToProps)(Overview);
