import React, { useState /*, useEffect*/ } from 'react';
//import authStyles from '../../authStyles';
import { useSnackbar } from 'notistack';
import { Field } from 'formik';
import OfferingName from './OfferingName';
import Issuer from './Issuer';
import AssociatedPersons from './AssociatedPersons';
import SecStatement from './SecStatement';
import SecurityDetails from './SecurityDetails';
import OfferingDetails from './OfferingDetails';
import SubscriptionAgreementTemplate from './SubscriptionAgreementTemplate';
import Review from './Review';
import Complete from './Complete';
import EscrowAgreement from './EscrowAgreement';
import TechServiceAgreement from './TechServiceAgreement';
import SignRTAAgreement from './SignRTAAgreement';
import Buttons from './Buttons';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    MenuItem,
} from '@mui/material';
import FormikSelect from '../../../../components/Formik/FormikSelect';
import fundraiseOfferingStyles from '../../../../components/styles/fundraiseOfferingStyles';

let initialValues = {
    birthDateValues: [],
    countryValues: [],
    aPersonTypes: [],
    aPersonTitles: [],
    offering_name: '',
    issuer_name: '',
    country: '',
    issuer_type: 'LLC',
    ein: '',
    incorporation_state: '',
    year_founded: '',
    contact_name: '',
    title: '',
    email: '',
    phone_number: '',
    street_address: '',
    apartment: '',
    city: '',
    state: '',
    zip_code: '',
    fast_symbol: '',
    sec_statement: 'no',
    sec_statement_description: '',
    automated_email_address: '',
    send_emails: false,
    logo: '',
    security_details_type: 'DEBT',
    security_details_name: '',
    interest_rate: '',
    maturity_date: '',
    security_details_description: '',
    regulatory_exemption: '506(c)',
    registered_transfer_agent: 'NONE',
    investor_transfer_restrictions: false,
    cusip: '',
    is_dtc_eligible: false,
    is_traded_on_exchange: false,
    registered_transfer_agent_description: '',
    broker_dealer_service: false,
    offering_expiration_date: '',
    target_amount: '',
    break_escrow_minimum_raise: '',
    maximum_raise_amount: '',
    minimum_investor_amount_allowed: '',
    investment_increment: '',
    maxmum_investor_amount_allowed: '',
    accept_fund_methods: false,
    ppm_file: '',
    fill_investment_by_advisor: false,
    manually_enter_promo_codes: false,
    custom_disclaimer_required: false,
    subscription_agreement_template: '',
    subscription_agreement_representative_name: '',
    subscription_agreement_representative_title: '',
    subscription_agreement_representative_email: '',
    subscription_agreement_signature: '',
    is_reviewed: false,
    acknowledge_information: false,
    escrow_agreement_name: '',
    escrow_agreement_title: '',
    tech_service_agreement_name: '',
    tech_service_agreement_title: '',
    rta_agreement_name: '',
    rta_agreement_title: '',
    associated_persons: [],
    button_color: '#0000FF',
    button_size: 'small',
    button_class: '',
    accredited: false,
    non_accredited: false,
    us: false,
    non_us: false,
    ach: false,
    non_us_bank_transfer: false,
    check: false,
    wire: false,
};

const formData = new FormData();
formData.append('logo', '');
formData.append('ppm_file', '');

const Offering = (props) => {
    //const classes = authStyles();
    const offeringClasses = fundraiseOfferingStyles();
    const { open } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [dialogIsOpen, setDialogIsOpen] = useState(open);

    const [step, setStep] = useState(0);
    const [key, setKey] = useState(0);

    let steps = [
        { name: 'Offering Name', step: 0 },
        { name: 'Issuer Information', step: 1 },
        { name: 'Associated Persons', step: 2 },
        { name: 'SEC Statements', step: 3 },
        { name: 'Security Details', step: 4 },
        { name: 'Offering Information', step: 5 },
        { name: 'Subscription Agreement Template', step: 6 },
        { name: 'Review Your Offering', step: 7 },
        { name: 'Confirm and Submit', step: 8 },
        { name: 'Sign Escrow Agreement', step: 9 },
        { name: 'Sign Tech Service Agreement', step: 10 },
        { name: 'Sign RTA Agreement', step: 11 },
        { name: 'Your Buttons', step: 12 },
    ];

    const saveValues = (field_value) => {
        return function () {
            initialValues = Object.assign({}, initialValues, field_value);
        }.bind()();
    };

    const nextStep = () => {
        setStep(step + 1);
    };

    const previousStep = () => {
        setStep(step - 1);
    };

    const changeKey = () => {
        setKey(key + 1);
    };

    const closeDialog = () => {
        setStep(0);

        for (var x in initialValues) {
            initialValues[x] = '';
        }
        saveValues(initialValues);
        setDialogIsOpen(false);
        setKey(key + 1);
    };

    const changeStep = (e) => {
        if (e.target.value !== undefined) {
            if (e.target.value === 8 && initialValues.is_reviewed === false) {
                enqueueSnackbar('Please check review step to continue', {
                    variant: 'error',
                });
            } else if (
                e.target.value > 8 &&
                (initialValues.is_reviewed === false ||
                    initialValues.acknowledge_information === false)
            ) {
                enqueueSnackbar(
                    'Please check review and complete steps to continue',
                    {
                        variant: 'error',
                    }
                );
            } else {
                setStep(e.target.value);
            }
        }
    };

    const showStep = () => {
        switch (step) {
            case 0:
                return (
                    <OfferingName
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        closeDialog={closeDialog}
                        changeKey={changeKey}
                    />
                );
            case 1:
                return (
                    <Issuer
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        closeDialog={closeDialog}
                        changeKey={changeKey}
                    />
                );
            case 2:
                return (
                    <AssociatedPersons
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        closeDialog={closeDialog}
                        changeKey={changeKey}
                    />
                );
            case 3:
                return (
                    <SecStatement
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        closeDialog={closeDialog}
                        changeKey={changeKey}
                    />
                );
            case 4:
                return (
                    <SecurityDetails
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        closeDialog={closeDialog}
                        formData={formData}
                        changeKey={changeKey}
                    />
                );
            case 5:
                return (
                    <OfferingDetails
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        closeDialog={closeDialog}
                        changeKey={changeKey}
                        formData={formData}
                    />
                );
            case 6:
                return (
                    <SubscriptionAgreementTemplate
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        closeDialog={closeDialog}
                        changeKey={changeKey}
                    />
                );
            case 7:
                return (
                    <Review
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        closeDialog={closeDialog}
                        changeKey={changeKey}
                    />
                );
            case 8:
                return (
                    <Complete
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        closeDialog={closeDialog}
                        changeKey={changeKey}
                    />
                );
            case 9:
                return (
                    <EscrowAgreement
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        closeDialog={closeDialog}
                        changeKey={changeKey}
                    />
                );
            case 10:
                return (
                    <TechServiceAgreement
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        closeDialog={closeDialog}
                        changeKey={changeKey}
                    />
                );
            case 11:
                return (
                    <SignRTAAgreement
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        closeDialog={closeDialog}
                        changeKey={changeKey}
                    />
                );
            case 12:
                return (
                    <Buttons
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        closeDialog={closeDialog}
                        formData={formData}
                        changeKey={changeKey}
                        resetValues={initialValues}
                    />
                );
            default:
                return (
                    <OfferingName
                        initialValues={initialValues}
                        defaultValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        closeDialog={closeDialog}
                        changeKey={changeKey}
                    />
                );
        }
    };
    return (
        <Dialog
            fullWidth
            maxWidth="lg"
            open={dialogIsOpen}
            onBackdropClick={(e) => setDialogIsOpen(false)}
        >
            <DialogTitle className={offeringClasses.title}>
                Create New Offering
            </DialogTitle>
            <DialogContent>
                <Grid container key={key}>
                    <Grid item xs={3}>
                        <Field
                            component={FormikSelect}
                            name="step"
                            variant="outlined"
                            label="Select your step"
                            required
                            fullWidth
                            defaultValue={step ? step : 0}
                            onClick={changeStep}
                        >
                            {steps.map((step) => (
                                <MenuItem key={step.step} value={step.step}>
                                    {step.name}
                                </MenuItem>
                            ))}
                        </Field>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item xs={12} md={12}>
                        {showStep()}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
export default Offering;
