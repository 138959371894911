import { makeStyles } from '@mui/styles';

export const modalStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'white',
        borderRadius: '12px',
        padding: '50px',
    },
    list: {
        columns: 5,
        listStylePosition: 'inside',
    },
}));