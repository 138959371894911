import * as eventTypes from './eventTypes';

const initialState = {
    recent_events: {
        data: null,
        loading: true,
    },
    events: {
        data: null,
        loading: true,
    },
};

const eventReducer = (state = initialState, action) => {
    switch (action.type) {
        case eventTypes.GET_RECENT_EVENTS_SUCCESS:
            return {
                ...state,
                recent_events: {
                    ...state.recent_events,
                    data: action.payload,
                },
            };
        case eventTypes.SET_RECENT_EVENTS_LOADING:
            return {
                ...state,
                recent_events: {
                    ...state.recent_events,
                    loading: action.payload,
                },
            };

        default:
            return state;
    }
};

export default eventReducer;
