import React from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import authStyles from '../authStyles';
import TextField from '../../../components/TextField/TextField';
import * as config from './../../../config';
import { snackbar } from '../../../utilities/snackbarUtils';

import IHQLogoImage from '../../../assets/img/new_logo_digtl.png';
import BoxWithLogo from '../../../components/Containers/BoxWithLogo';

const initialValues = {
    email: '',
};

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Please enter a valid email address')
        .required('Please enter a valid email address'),
});

const Login = () => {
    const classes = authStyles();
    const history = useHistory();

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                axios
                    .post(
                        `${config.API_BASE_URL}/request-password-reset`,
                        values
                    )
                    .then(() => {
                        snackbar.success(
                            'Password reset link has been sent to your email address.'
                        );
                        history.replace({ pathname: '/login' });
                    })
                    .catch(() => {
                        snackbar.error('Please try again.');
                        setSubmitting(false);
                    });
            }}
        >
            {({ isSubmitting }) => (
                <BoxWithLogo logo={IHQLogoImage} title={'Reset Your Password'}>
                    <Typography component="div">
                        <span className={classes.note}>
                            To reset the password, enter your email address
                            below and press "Reset My Password".
                        </span>
                    </Typography>
                    <br />
                    <Typography component="div">
                        <span className={classes.note}>
                            You should receive an email with further
                            instructions. In case you cannot see the email,
                            please check your Spam folder.
                        </span>
                    </Typography>
                    <Form className={classes.form} noValidate>
                        <Field
                            component={TextField}
                            name="email"
                            type="email"
                            label="Email"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            required
                        />
                        <div className={classes.actions}>
                            <Button
                                type="submit"
                                color="primary"
                                variant="outlined"
                                className={classes.buttonLabel}
                                disabled={isSubmitting}
                            >
                                {!isSubmitting ? (
                                    <span>Reset My Password</span>
                                ) : (
                                    <CircularProgress size={30} />
                                )}
                            </Button>
                        </div>
                    </Form>
                    <Typography
                        component={Link}
                        to="/login"
                        className={classes.link}
                    >
                        Back to Login
                    </Typography>
                </BoxWithLogo>
            )}
        </Formik>
    );
};

export default Login;
