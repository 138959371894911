import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as adminActions from '../../../store/admin/adminActions';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import PersonAddIcon from '@mui/icons-material/PersonAdd';

import TextField from '../../../components/TextField/TextField';
import PasswordField from '../../../components/PasswordField';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

/* Transfer agent form validation */
const validationSchema = Yup.object({
    name: Yup.string().required('Please enter the account name.'),
    email: Yup.string()
        .email()
        .required('Please enter the account email address.'),
    password: Yup.string()
        .required('Please enter the account password.')
        //  .min(8, 'Password is too short - must containt at least 8 characters.')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,

            'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, number and one symbol.'
        ),
});

/* Initial form values */
const initialValues = {
    email: '',
    name: '',
    password: '',
};

const AddNewTransferAgent = (props) => {
    const { transferAgents, adminActions } = props;

    /**
     * Dialog show and hide.
     */
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (resetForm, initial) => {
        resetForm(initial);
        setOpen(false);
    };

    return (
        <Grid item xs={12}>
            <Button
                className="float-right"
                color="primary"
                variant="contained"
                startIcon={<PersonAddIcon />}
                onClick={handleClickOpen}
            >
                New
            </Button>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    adminActions.createTransferAgent(
                        values,
                        setSubmitting,
                        resetForm,
                        initialValues,
                        handleClose
                    );
                }}
            >
                {({ resetForm, setFieldValue, submitForm }) => (
                    <Form noValidate autoComplete="off">
                        <Dialog
                            open={open}
                            onClose={() =>
                                handleClose(resetForm, initialValues)
                            }
                            aria-labelledby="add-admin-dialog"
                        >
                            <DialogTitle id="add-admin-dialog">
                                Add a new transfer agent
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Enter transfer agent account details
                                </DialogContentText>

                                <Field
                                    component={TextField}
                                    name="name"
                                    label="Full name"
                                    type="text"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    required
                                />
                                <Field
                                    component={TextField}
                                    name="email"
                                    label="Email address"
                                    type="email"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    required
                                />
                                <PasswordField
                                    name="password"
                                    label="Password"
                                    setValue={setFieldValue}
                                    disableAutocomplete={true}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() =>
                                        handleClose(resetForm, initialValues)
                                    }
                                    variant="contained"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={submitForm}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={transferAgents.loading}
                                >
                                    {transferAgents.loading ? (
                                        <CircularProgress size={24} />
                                    ) : (
                                        'Add'
                                    )}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Form>
                )}
            </Formik>
        </Grid>
    );
};

const mapStateToProps = ({ admin }) => {
    return {
        transferAgents: admin.transferAgents,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        adminActions: bindActionCreators(adminActions, dispatch),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddNewTransferAgent);
