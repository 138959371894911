import useSWR from "swr"
import { API_BASE_URL } from "../../config"
import fetcher from "../../utilities/fetcher"

const useHoldersByCategory = (categoryId) => {
    const { data, error, mutate } = useSWR(() => categoryId ? `${API_BASE_URL}/blockchain/holdersByCategory/${categoryId}` : null, fetcher)

    return {
        holdersByCategory: data || [],
        holdersByCategoryLoading: !error && !data,
        holdersByCategoryError: error,
        updateHoldersByCategory: () => mutate()
    }
}

export default useHoldersByCategory