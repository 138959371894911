import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material'

import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

import { snackbar } from '../../../../utilities/snackbarUtils'
import QRCode from 'qrcode.react';

const AddressQRDialog = ({ open, handleClose, address }) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Address QR code</DialogTitle>
            <DialogContent>
                <QRCode
                    size={250}
                    value={address}
                    className="m-t-10"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

const WalletAddressButtons = ({ address, onCreateAddress }) => {
    const [openQr, setOpenQr] = React.useState(false)

    const handleOpenQr = () => {
        setOpenQr(true)
    }

    const handleCloseQr = () => {
        setOpenQr(false)
    }

    const copyAddress = (address) => {
        navigator.clipboard.writeText(address)
        snackbar.success('Copied address to clipboard.');
    }

    return (
        <div>
            {
                onCreateAddress && (
                    <Tooltip title='Create address'>
                        <IconButton onClick={onCreateAddress}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                )
            }
            <Tooltip title='Copy address'>
                <IconButton onClick={() => copyAddress(address)}>
                    <ContentCopyIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title='Open QR code'>
                <IconButton onClick={handleOpenQr}>
                    <QrCodeScannerIcon />
                </IconButton>
            </Tooltip>

            <AddressQRDialog open={openQr} handleClose={handleCloseQr} address={address} />
        </div>
    )
}

export default WalletAddressButtons