import React from 'react';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import LockedTile from '../../../components/LockedTile';
import { SUBSCRIPTION_ENTERPRISE } from '../../../constants/profile';
import CandlestickChart from '../../../components/CandlestickChart';

const Overview = (props) => {
    let demo;
    try {
        demo =
            localStorage.getItem('user-email').includes('ihqdemo') ||
            props.profile.scope === 'list';
    } catch (e) {
        demo = null;
    }

    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
        checkedC: true,
        checkedD: true,
        checkedF: false,
        checkedG: true,
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (
        <LockedTile requiredPlan={SUBSCRIPTION_ENTERPRISE}>
            <Grid
                container
                spacing={2}
            >
                <Grid item xs={12} sm={6}>
                    <Typography variant="overline">
                        <b style={{ fontSize: '0.80rem' }}>Ticker:</b>
                    </Typography>
                    {demo ? (
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.checkedA}
                                        onChange={handleChange}
                                        name="checkedA"
                                        color="primary"
                                    />
                                }
                                label="BMN1"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.checkedB}
                                        onChange={handleChange}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="INSDR"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.checkedC}
                                        onChange={handleChange}
                                        name="checkedC"
                                        color="primary"
                                    />
                                }
                                label="MERJ-S"
                            />
                        </FormGroup>
                    ) : (
                        <Grid style={{ fontSize: '1.1rem' }}>No data</Grid>
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="overline">
                        <b style={{ fontSize: '0.80rem' }}>Exchange:</b>
                    </Typography>
                    {demo ? (
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.checkedG}
                                        onChange={handleChange}
                                        name="checkedG"
                                        color="primary"
                                    />
                                }
                                label="MERJ"
                            />
                        </FormGroup>
                    ) : (
                        <Grid style={{ fontSize: '1.1rem' }}>No data</Grid>
                    )}
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 20 }}>
                    {demo && state.checkedA && state.checkedG && (
                        <Grid item xs={12} md={4}>
                            <CandlestickChart />
                        </Grid>
                    )}
                    {demo && state.checkedB && state.checkedG && (
                        <Grid item xs={12} md={4}>
                            <CandlestickChart currency={'INSDR'} currencyName={'INSDR (MERJ)'} />
                        </Grid>
                    )}
                    {demo && state.checkedC && state.checkedG && (
                        <Grid item xs={12} md={4}>
                            <CandlestickChart currency={'MERJ-S'} currencyName={'MERJ-S (MERJ)'} />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </LockedTile>
    );
};

const mapStateToProps = ({ profile }) => {
    return {
        profile: profile,
    };
};

export default connect(mapStateToProps, null)(Overview);
