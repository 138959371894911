import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import authStyles from '../authStyles';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '../../../components/TextField/TextField';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormikSelect from '../../../components/Formik/FormikSelect';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { CheckboxWithLabel } from 'formik-mui';
import HelpTooltip from '../../../components/HelpTooltip/HelpTooltip';
import FormPanel from '../../../components/FormPanel/FormPanel';
import FormPanelRow from '../../../components/FormPanelRow/FormPanelRow';
import withStyles from '@mui/styles/withStyles';
import * as Yup from 'yup';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormHelperText from '@mui/material/FormHelperText';
import * as registry from '../../../utilities/registry';
import { stringToInt } from '../../../utilities/utils';
import { VESTING_PRO_RATAS } from '../../../constants/shareholders';
import _ from 'lodash';
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#F5F5F5',
        color: 'black',
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#D6E8F6',
            height: '50px',
        },
    },
}))(TableRow);
const validationSchema = Yup.object({
    //  category_description: Yup.string().required('Please select category'),
    investor_total_number_of_fully_diluted_shares: Yup.string().required(
        'Please provide number of assets'
    ),
});
const CapitalizationTableOptions = (props) => {
    const classes = authStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [entityType, setEntityType] = useState('company');
    const [expandPanel1, setExpandPanel1] = useState(true);
    const [expandPanel2, setExpandPanel2] = useState(true);
    let [editRecord, setEditRecord] = useState([]);
    let [isEditable, setIsEditable] = useState(false);
    let [id, setId] = useState(0);
    let [refreshForm, setRefreshForm] = useState(0);
    const [duration, setDuration] = useState(null);
    const [cliff, setCliff] = useState(null);
    const [proRata, setProRata] = useState(null);
    const [categoryDescription, setCategoryDescription] = useState(null);
    const [investors, setShareholdres] = useState(
        props.initialValues.investors
    );
    const [excludingOption, setExcludingOption] = useState(
        props.initialValues.excluding_option
    );

    /*const [selectedCategory, setSelectedCategory] = useState(
        props.initialValues.categories.length > 0
            ? props.initialValues.categories[0]
            : null
    );*/
    const [fullyDilutedShares, setFullyDilutedShares] = useState(null);
    const [vestingShares, setVestingShares] = useState(null);
    const [amountsError, setAmountsError] = useState(false);
    let [tempHolders] = useState([]);

    const nextStep = (type = null) => {
        if (type === 'skip') {
            props.initialValues.investors.forEach((investor, index, object) => {
                tempHolders.forEach((temp) => {
                    if (_.isEqual(temp, investor)) {
                        object.splice(index, 1);
                    }
                });
            });
        }
        props.saveValues(props.initialValues);
        if (props.initialValues.option_plan === 'no') {
            props.skipNextPage();
        } else if (props.initialValues.option_plan === 'yes') {
            props.nextStep();
        }
    };

    const changeEntityType = (e) => {
        if (e.target.value) {
            setEntityType(e.target.value);
        }
    };

    const changeExcludingOption = (e) => {
        setExcludingOption(e.target.checked);
    };

    const buildDropdown = (number) => {
        let items = [];
        for (var i = 1; i <= number; i++) {
            items.push(
                <MenuItem key={i} value={i}>
                    {i}
                </MenuItem>
            );
        }

        return items;
    };

    const buildValuesDropdown = (values) => {
        let items = [];
        values.forEach((value) => {
            items.push(
                <MenuItem key={value} value={value}>
                    {value.charAt(0).toUpperCase() + value.slice(1)}
                </MenuItem>
            );
        });

        return items;
    };

    const handleExpansionPanelChange = (panel) => {
        if (panel === 1) {
            expandPanel1 === true
                ? setExpandPanel1(false)
                : setExpandPanel1(true);
        } else {
            expandPanel2 === true
                ? setExpandPanel2(false)
                : setExpandPanel2(true);
        }
    };

    const loadEditableCapTableRecord = (id) => {
        let investor = props.initialValues.investors.filter((element) => {
            return element.id === id;
        })[0];

        setEditRecord(investor);
        setIsEditable(true);

        refreshForm++;
        setRefreshForm(refreshForm);
    };

    const editCapTableRecord = (id, values) => {
        let recordToEdit = investors.filter((element) => {
            return element.id === id;
        })[0];

        recordToEdit.category_description = values.category_description;
        recordToEdit.entity_type = values.entity_type;
        recordToEdit.company_legal_name = values.company_legal_name;
        recordToEdit.investor_first_name = values.investor_first_name;
        recordToEdit.investor_last_name = values.investor_last_name;
        recordToEdit.investor_total_number_of_fully_diluted_shares =
            values.investor_total_number_of_fully_diluted_shares;
        recordToEdit.investor_citizenship = values.investor_citizenship;
        recordToEdit.investor_company = values.investor_company;
        recordToEdit.investor_position = values.investor_position;
        recordToEdit.investor_ofac_id = values.investor_ofac_id;
        recordToEdit.investor_options_exercise_price =
            values.investor_options_exercise_price;
        recordToEdit.investor_notes = values.investor_notes;
        recordToEdit.excluding_option =
            values.excluding_option === true ? values.excluding_option : false;
        recordToEdit.duration = values.duration;
        recordToEdit.vesting_shares = values.vesting_shares;
        recordToEdit.start_date = values.start_date;
        recordToEdit.cliff = values.cliff;
        recordToEdit.cliff_vest = values.cliff_vest;
        recordToEdit.pro_rata = values.pro_rata;
        recordToEdit.expiration_year = values.expiration_year;
        recordToEdit.exercise_price = values.exercise_price;
        recordToEdit.investor_email = values.investor_email;
        recordToEdit.id = values.id;

        let investor = investors.filter((element) => {
            return element.id !== id;
        });

        investor.unshift(recordToEdit);

        setShareholdres(investor);
        props.initialValues.investors = investor;
        setIsEditable(false);
    };

    const cancelEdit = () => {
        setIsEditable(false);
        refreshForm++;
        setRefreshForm(refreshForm);
    };

    let removeCapTableRecord = (id) => {
        let investor = props.initialValues.investors.filter((element) => {
            return element.id !== id;
        });
        setShareholdres(investor);
        props.initialValues.investors = investor;
    };

    const handleVestingSharesChange = (e) => {
        setVestingShares(e.target.value);
        if (stringToInt(e.target.value) > stringToInt(fullyDilutedShares)) {
            setAmountsError(true);
        } else {
            setAmountsError(false);
        }
    };

    const handleFullyDilutedSharesChange = (e) => {
        setFullyDilutedShares(e.target.value);
        if (stringToInt(e.target.value) < stringToInt(vestingShares)) {
            setAmountsError(true);
        } else {
            setAmountsError(false);
        }
    };

    const handleCategoryChange = (category_element) => {
        setCategoryDescription(category_element.category_description);
        //setSelectedCategory(category_element);
    };

    return (
        <div id="step9">
            <Formik
                key={refreshForm}
                initialValues={isEditable ? editRecord : props.initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm, setSubmitting }) => {
                    if (isEditable) {
                        editCapTableRecord(values.id, values);
                        refreshForm++;
                        setRefreshForm(refreshForm);
                        setIsEditable(false);
                    } else {
                        if (amountsError) {
                            enqueueSnackbar(
                                'Amount of Assets subject to Vesting should not exceed Fully diluted assets',
                                {
                                    variant: 'error',
                                }
                            );
                            setSubmitting(false);
                        } else {
                            let excluding =
                                props.initialValues.excluding_option;
                            tempHolders.unshift({
                                category_description: categoryDescription,
                                entity_type: values['entity_type'],
                                company_legal_name:
                                    values['company_legal_name'],
                                investor_first_name:
                                    values['investor_first_name'],
                                investor_last_name:
                                    values['investor_last_name'],
                                investor_email: values['investor_email'],
                                investor_citizenship:
                                    values['investor_citizenship'],
                                investor_company: values['investor_company'],
                                investor_position: values['investor_position'],
                                investor_ofac_id: values['investor_ofac_id']
                                    ? values['investor_ofac_id']
                                    : null,
                                investor_options_exercise_price:
                                    values['investor_options_exercise_price'],
                                investor_notes: values['investor_notes'],
                                investor_total_number_of_fully_diluted_shares:
                                    values[
                                        'investor_total_number_of_fully_diluted_shares'
                                    ],
                                excluding_option:
                                    values['excluding_option'] === true
                                        ? values['excluding_option']
                                        : false,
                                duration:
                                    duration !== undefined ? duration : null,
                                vesting_shares:
                                    values['vesting_shares'] !== undefined
                                        ? values['vesting_shares']
                                        : '',
                                start_date:
                                    values['start_date'] !== undefined
                                        ? values['start_date']
                                        : '',
                                cliff: cliff !== undefined ? cliff : null,
                                cliff_vest:
                                    values['cliff_vest'] !== undefined
                                        ? values['cliff_vest']
                                        : null,
                                pro_rata: proRata,
                                id: id,
                                expiration_year: values['expiration_year'],
                                exercise_price: values['exercise_price'],
                            });
                            props.initialValues.investors.unshift({
                                category_description: categoryDescription,
                                entity_type: values['entity_type'],
                                company_legal_name:
                                    values['company_legal_name'],
                                investor_first_name:
                                    values['investor_first_name'],
                                investor_last_name:
                                    values['investor_last_name'],
                                investor_email: values['investor_email'],
                                investor_citizenship:
                                    values['investor_citizenship'],
                                investor_company: values['investor_company'],
                                investor_position: values['investor_position'],
                                investor_ofac_id: values['investor_ofac_id']
                                    ? values['investor_ofac_id']
                                    : null,
                                investor_options_exercise_price:
                                    values['investor_options_exercise_price'],
                                investor_notes: values['investor_notes'],
                                investor_total_number_of_fully_diluted_shares:
                                    values[
                                        'investor_total_number_of_fully_diluted_shares'
                                    ],
                                excluding_option:
                                    values['excluding_option'] === true
                                        ? values['excluding_option']
                                        : false,
                                duration:
                                    duration !== undefined ? duration : null,
                                vesting_shares:
                                    values['vesting_shares'] !== undefined
                                        ? values['vesting_shares']
                                        : '',
                                start_date:
                                    values['start_date'] !== undefined
                                        ? values['start_date']
                                        : '',
                                cliff: cliff !== undefined ? cliff : null,
                                cliff_vest:
                                    values['cliff_vest'] !== undefined
                                        ? values['cliff_vest']
                                        : null,
                                pro_rata: proRata,
                                id: id,
                                expiration_year: values['expiration_year'],
                                exercise_price: values['exercise_price'],
                            });
                            id++;
                            setId(id);
                            refreshForm++;
                            setRefreshForm(refreshForm);
                            setShareholdres(props.initialValues.investors);
                            setFullyDilutedShares(null);
                            setVestingShares(null);
                            setAmountsError(false);

                            values['excluding_option'] = excluding;
                            props.saveValues(values);
                            delete props.initialValues['category'];
                            delete props.initialValues['company_legal_name'];
                            delete props.initialValues['investor_first_name'];
                            delete props.initialValues['investor_last_name'];
                            delete props.initialValues['investor_citizenship'];
                            delete props.initialValues['investor_company'];
                            delete props.initialValues['investor_position'];
                            delete props.initialValues['investor_company'];
                            delete props.initialValues['investor_ofac_id'];
                            delete props.initialValues[
                                'investor_options_exercise_price'
                            ];
                            delete props.initialValues['investor_notes'];
                            delete props.initialValues[
                                'investor_total_number_of_fully_diluted_shares'
                            ];
                            delete props.initialValues['duration'];
                            delete props.initialValues['vesting_shares'];
                            delete props.initialValues['start_date'];
                            delete props.initialValues['cliff'];
                            delete props.initialValues['cliff_vest'];
                            delete props.initialValues['pro_rata'];
                            delete props.initialValues['id'];
                            delete props.initialValues['investor_email'];
                            //delete props.initialValues['investor_citizenship'];
                            resetForm({ values: '' });
                            //  nextStep();
                        }
                    }
                }}
            >
                <Paper className={classes.paper}>
                    <Typography component="div" style={{ textAlign: 'center' }}>
                        <h2>Cap Table — Options</h2>
                        <h3>Enter your Investor Options Information</h3>
                    </Typography>
                    <Container>
                        <Form className={classes.form} noValidate>
                            <Accordion
                                style={{ width: '100%' }}
                                expanded={expandPanel1}
                                onChange={() => handleExpansionPanelChange(1)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography>Add new record</Typography>
                                </AccordionSummary>
                                <FormPanel>
                                    {props.initialValues.categories.length >
                                    0 ? (
                                        <FormPanelRow>
                                            <Field
                                                component={FormikSelect}
                                                name="category_description"
                                                variant="outlined"
                                                label="Category"
                                                fullWidth
                                                required
                                                defaultValue={
                                                    props.initialValues
                                                        .categories.length > 0
                                                        ? props.initialValues
                                                              .categories[0]
                                                              .category_description
                                                        : null
                                                }
                                            >
                                                {props.initialValues.categories.map(
                                                    (category) => (
                                                        <MenuItem
                                                            onClick={(e) =>
                                                                handleCategoryChange(
                                                                    category
                                                                )
                                                            }
                                                            key={
                                                                category.category_description
                                                            }
                                                            value={
                                                                category.category_description
                                                            }
                                                        >
                                                            {
                                                                category.category_description
                                                            }
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Field>
                                            <FormHelperText error>
                                                <ErrorMessage name="category_description" />
                                            </FormHelperText>
                                        </FormPanelRow>
                                    ) : null}

                                    <FormPanelRow>
                                        <Field
                                            component={FormikSelect}
                                            name="entity_type"
                                            variant="outlined"
                                            label="Type"
                                            fullWidth
                                            margin="outlined"
                                            onChange={changeEntityType}
                                            defaultValue={'company'}
                                        >
                                            {buildValuesDropdown([
                                                'company',
                                                'individual',
                                            ])}
                                        </Field>
                                    </FormPanelRow>
                                    {entityType === 'company' ? (
                                        <FormPanelRow>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Company Legal Name"
                                                name="company_legal_name"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                            />
                                        </FormPanelRow>
                                    ) : (
                                        <Grid>
                                            <FormPanelRow>
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    label="First Name"
                                                    name="investor_first_name"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    required
                                                />
                                            </FormPanelRow>
                                            <FormPanelRow>
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    label="Last Name"
                                                    name="investor_last_name"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            </FormPanelRow>
                                            <FormPanelRow>
                                                <Field
                                                    component={FormikSelect}
                                                    name="investor_citizenship"
                                                    variant="outlined"
                                                    label="Citizenship"
                                                    fullWidth
                                                    inputProps={{
                                                        id:
                                                            'investor_citizenship',
                                                    }}
                                                    required
                                                    defaultValue={
                                                        registry.countries[0]
                                                            .name
                                                    }
                                                >
                                                    {registry.countries.map(
                                                        (c) => (
                                                            <MenuItem
                                                                key={c.name}
                                                                value={c.name}
                                                            >
                                                                {c.name}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Field>
                                            </FormPanelRow>
                                            <FormHelperText error>
                                                <ErrorMessage name="citizenship" />
                                            </FormHelperText>
                                            <FormPanelRow>
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    label="Holder company"
                                                    name="investor_company"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            </FormPanelRow>
                                            <FormPanelRow>
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    label="Position"
                                                    name="investor_position"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            </FormPanelRow>
                                        </Grid>
                                    )}
                                    <FormPanelRow>
                                        <Field
                                            component={TextField}
                                            type="text"
                                            label="Email"
                                            name="investor_email"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            required
                                        />
                                    </FormPanelRow>
                                    {/*<FormPanelRow>
                                        <Field
                                            component={TextField}
                                            type="text"
                                            name="investor_ofac_id"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label={
                                                <div>
                                                    OFAC compliance ID
                                                    <HelpTooltip title="either SSN, EIN/TIN, or passport number if not in US" />
                                                </div>
                                            }
                                            InputLabelProps={{
                                                style: {
                                                    pointerEvents: 'auto',
                                                },
                                            }}
                                        />
                                    </FormPanelRow>*/}
                                    <FormPanelRow>
                                        <Field
                                            component={TextField}
                                            type="text"
                                            label="Fully diluted assets"
                                            name="investor_total_number_of_fully_diluted_shares"
                                            variant="outlined"
                                            margin="normal"
                                            numeric={1}
                                            fullWidth
                                            onKeyUp={(e) =>
                                                handleFullyDilutedSharesChange(
                                                    e
                                                )
                                            }
                                        />
                                        {amountsError && (
                                            <span
                                                className={classes.errorMessage}
                                            >
                                                *Amount of Assets subject to
                                                Vesting should not exceed Fully
                                                diluted assets
                                            </span>
                                        )}
                                    </FormPanelRow>
                                    <FormPanelRow>
                                        <Field
                                            component={TextField}
                                            type="number"
                                            label="Options exercise price"
                                            name="investor_options_exercise_price"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                        />
                                    </FormPanelRow>
                                    <FormPanelRow>
                                        <Field
                                            component={TextField}
                                            type="text"
                                            label="Notes"
                                            name="investor_notes"
                                            variant="outlined"
                                            margin="normal"
                                            multiline
                                            rows={4}
                                            helperText="Add some additional information about this investor."
                                            fullWidth
                                        />
                                    </FormPanelRow>

                                    {props.initialValues.option_plan ===
                                    'yes' ? (
                                        <FormPanelRow>
                                            <Field
                                                component={CheckboxWithLabel}
                                                type="checkbox"
                                                name="excluding_option"
                                                onClick={changeExcludingOption}
                                                Label={{
                                                    label: (
                                                        <div
                                                            className={
                                                                classes.checkbox
                                                            }
                                                        >
                                                            Vesting{' '}
                                                            <HelpTooltip
                                                                title={
                                                                    'Vesting of assets restricts a investor to receive the full asset ownership over a set period of time.'
                                                                }
                                                            />
                                                        </div>
                                                    ),
                                                }}
                                                checked={excludingOption}
                                                required
                                            />
                                        </FormPanelRow>
                                    ) : null}
                                    {excludingOption === true ? (
                                        <Grid>
                                            <FormPanelRow>
                                                <InputLabel
                                                    style={{
                                                        color: '#666',
                                                        fontSize: '0.8rem',
                                                        marginBottom: '0.3rem',
                                                    }}
                                                    htmlFor="exercise_price"
                                                >
                                                    Exercise Price
                                                    <HelpTooltip title="The exercise price of an option is a fixed price at which the owner of the option can buy or sell the underlying assets" />
                                                </InputLabel>
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    name="exercise_price"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            </FormPanelRow>
                                            <FormPanelRow>
                                                <InputLabel
                                                    style={{
                                                        color: '#666',
                                                        fontSize: '0.8rem',
                                                        marginBottom: '0.3rem',
                                                    }}
                                                    htmlFor="expiration_year"
                                                >
                                                    Option Expiration Year
                                                    <HelpTooltip title="The number of years that the option remains exercisable. If the option holder does not exercise his/her options in this time frame, they lose the right to purchase assets under that option agreement." />
                                                </InputLabel>
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    name="expiration_year"
                                                    variant="outlined"
                                                    margin="normal"
                                                    placeholder={'2025'}
                                                    fullWidth
                                                />
                                            </FormPanelRow>
                                            <FormPanelRow>
                                                <InputLabel
                                                    style={{
                                                        color: '#666',
                                                        fontSize: '0.8rem',
                                                        marginBottom: '0.3rem',
                                                    }}
                                                    htmlFor="vesting_shares"
                                                >
                                                    Number of assets subject to
                                                    vesting{' '}
                                                    <HelpTooltip title="Enter 0 if all assets are fully vested" />
                                                </InputLabel>
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    name="vesting_shares"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    placeholder={'500,000'}
                                                    numeric={1}
                                                    onKeyUp={(e) =>
                                                        handleVestingSharesChange(
                                                            e
                                                        )
                                                    }
                                                />
                                                {amountsError && (
                                                    <span
                                                        className={
                                                            classes.errorMessage
                                                        }
                                                    >
                                                        *Amount of Assets
                                                        subject to Vesting
                                                        should not exceed Fully
                                                        diluted assets
                                                    </span>
                                                )}
                                            </FormPanelRow>
                                            <FormPanelRow>
                                                <Field
                                                    component={TextField}
                                                    type="date"
                                                    label={'Start Date'}
                                                    name="start_date"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            </FormPanelRow>
                                            <FormPanelRow>
                                                <InputLabel
                                                    style={{
                                                        color: '#666',
                                                        fontSize: '0.8rem',
                                                    }}
                                                    htmlFor="duration"
                                                >
                                                    Duration (years)
                                                    <HelpTooltip title="How long is the vesting period for these assets? This is typically around 3-4 years but varies per company." />
                                                </InputLabel>
                                                <Field
                                                    component={FormikSelect}
                                                    name="duration"
                                                    variant="outlined"
                                                    fullWidth
                                                    onChange={(e) =>
                                                        setDuration(
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    {buildDropdown(7)}
                                                </Field>
                                            </FormPanelRow>
                                            <FormPanelRow>
                                                <InputLabel
                                                    style={{
                                                        color: '#666',
                                                        fontSize: '0.8rem',
                                                    }}
                                                    htmlFor="cliff"
                                                >
                                                    Cliff (years)
                                                    <HelpTooltip title="A cliff is a period in which the option holder cannot access any assets. Typically a one year cliff is put in place to incentivize an employee to remain a full year before leaving with company assets." />
                                                </InputLabel>
                                                <Field
                                                    component={FormikSelect}
                                                    name="cliff"
                                                    variant="outlined"
                                                    fullWidth
                                                    onChange={(e) =>
                                                        setCliff(e.target.value)
                                                    }
                                                >
                                                    {buildDropdown(3)}
                                                </Field>
                                            </FormPanelRow>
                                            <FormPanelRow>
                                                <InputLabel
                                                    style={{
                                                        color: '#666',
                                                        fontSize: '0.8rem',
                                                        marginBottom: '0.3rem',
                                                    }}
                                                    htmlFor="cliff_vest"
                                                >
                                                    Cliff Vesting (%)
                                                    <HelpTooltip title="This is typically cliff divided by duration however some companies may choose to accelerate beyond that." />
                                                </InputLabel>
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    percentage={1}
                                                    name="cliff_vest"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    placeholder={'50'}
                                                ></Field>
                                            </FormPanelRow>
                                            <FormPanelRow>
                                                <InputLabel
                                                    style={{
                                                        color: '#666',
                                                        fontSize: '0.8rem',
                                                        marginBottom: '0.3rem',
                                                    }}
                                                    htmlFor="cliff_vest"
                                                >
                                                    Frequency
                                                    <HelpTooltip title="The frequency of vesting. Assets can vest on a proportional basis daily, weekly, monthly, etc." />
                                                </InputLabel>
                                                <Field
                                                    component={FormikSelect}
                                                    name="pro_rata"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    onChange={(e) =>
                                                        setProRata(
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    {buildValuesDropdown(
                                                        VESTING_PRO_RATAS
                                                    )}
                                                </Field>
                                            </FormPanelRow>
                                        </Grid>
                                    ) : null}

                                    <FormPanelRow>
                                        {isEditable === true ? (
                                            <ButtonGroup
                                                aria-label="outlined primary button group"
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Button
                                                    type="submit"
                                                    variant="outlined"
                                                    className={
                                                        classes.buttonLabel
                                                    }
                                                    color="primary"
                                                >
                                                    EDIT
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    className={
                                                        classes.secondaryButtonLabel
                                                    }
                                                    color="secondary"
                                                    onClick={cancelEdit}
                                                >
                                                    CANCEL
                                                </Button>
                                            </ButtonGroup>
                                        ) : (
                                            <ButtonGroup
                                                aria-label="outlined primary button group"
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Button
                                                    type="submit"
                                                    variant="outlined"
                                                    className={
                                                        classes.buttonLabel
                                                    }
                                                    color="primary"
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    ADD
                                                </Button>
                                            </ButtonGroup>
                                        )}
                                    </FormPanelRow>
                                </FormPanel>
                            </Accordion>
                        </Form>
                        <hr />
                        <Form className={classes.form} noValidate>
                            <Accordion
                                style={{ width: '100%' }}
                                expanded={expandPanel2}
                                onChange={() => handleExpansionPanelChange(2)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography>Saved Items</Typography>
                                </AccordionSummary>
                                <FormPanel>
                                    <TableContainer>
                                        <Table
                                            className={classes.table}
                                            aria-label="customized table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="left">
                                                        Category
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        Company Legal Name
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        First Name
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        Last Name
                                                    </StyledTableCell>

                                                    <StyledTableCell align="left">
                                                        Fully Diluted <br />
                                                        Number of Assets
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        Number of Assets
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        Start Date
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        Duration / Cliff / Vest
                                                        %
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        Remove
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        Edit
                                                    </StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {investors.map((row) => (
                                                    <StyledTableRow
                                                        key={row.id}
                                                    >
                                                        <StyledTableCell align="left">
                                                            {
                                                                row.category_description
                                                            }
                                                        </StyledTableCell>

                                                        <StyledTableCell align="left">
                                                            {
                                                                row.company_legal_name
                                                            }
                                                        </StyledTableCell>

                                                        <StyledTableCell align="left">
                                                            {
                                                                row.investor_first_name
                                                            }
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {
                                                                row.investor_last_name
                                                            }
                                                        </StyledTableCell>

                                                        <StyledTableCell align="left">
                                                            {
                                                                row.investor_total_number_of_fully_diluted_shares
                                                            }
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {row.vesting_shares}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {row.start_date}
                                                        </StyledTableCell>

                                                        {row.duration !== '' &&
                                                        row.excluding_option ===
                                                            true ? (
                                                            <StyledTableCell align="left">
                                                                {row.duration +
                                                                    ' / ' +
                                                                    row.cliff +
                                                                    ' / ' +
                                                                    row.cliff_vest}
                                                            </StyledTableCell>
                                                        ) : (
                                                            <StyledTableCell></StyledTableCell>
                                                        )}

                                                        <StyledTableCell
                                                            align="left"
                                                            style={{
                                                                justifyContent:
                                                                    'center',
                                                                alignItems:
                                                                    'center',
                                                            }}
                                                        >
                                                            <Button
                                                                variant="outlined"
                                                                className={
                                                                    classes.buttonLabel
                                                                }
                                                                color="primary"
                                                                onClick={() =>
                                                                    removeCapTableRecord(
                                                                        row.id
                                                                    )
                                                                }
                                                            >
                                                                <DeleteForeverIcon />
                                                            </Button>
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            <Button
                                                                variant="outlined"
                                                                className={
                                                                    classes.buttonLabel
                                                                }
                                                                color="primary"
                                                                onClick={() =>
                                                                    loadEditableCapTableRecord(
                                                                        row.id
                                                                    )
                                                                }
                                                            >
                                                                <EditIcon />
                                                            </Button>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </FormPanel>
                            </Accordion>

                            <div className={classes.actions}>
                                <ButtonGroup
                                    aria-label="outlined primary button group"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        className={classes.secondaryButtonLabel}
                                        color="secondary"
                                        onClick={() => props.previousStep()}
                                        style={{ margin: 'inherit' }}
                                    >
                                        <ArrowBackIosIcon /> BACK
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        className={classes.buttonLabel}
                                        color="primary"
                                        style={{
                                            margin: 'auto',
                                        }}
                                        onClick={nextStep}
                                    >
                                        CONTINUE
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        className={classes.secondaryButtonLabel}
                                        color="secondary"
                                        onClick={() => nextStep('skip')}
                                        style={{ margin: 'inherit' }}
                                    >
                                        SKIP
                                        <DoubleArrowIcon />
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </Form>
                    </Container>
                </Paper>
            </Formik>
        </div>
    );
};

export default CapitalizationTableOptions;
