import React, { useState } from 'react';
import {
    Switch,
    Route,
    useRouteMatch,
    useLocation,
    NavLink,
} from 'react-router-dom';
import issuerRouteStyles from './issuerRouteStyles';
import { connect } from 'react-redux';
import { isFeatureLocked } from '../../utilities/utils';
import { SUBSCRIPTION_BASIC, SUBSCRIPTION_PRO } from '../../constants/profile';
import TabPanel from '../../components/TabPanel';
import SubMenu from '../../components/SubMenu';
import { isTALoggedAsIssuer } from '../../utilities/utils';
import UploadDocuments from '../../pages/issuer/Dashboard/UploadDocuments';
import CapTableList from '../../pages/issuer/Dashboard/CapTableList';
import BlockchainScreen from '../../pages/issuer/Blockchain/BlockchainScreen';
import EditInvestorInfo from '../../pages/issuer/Investors/EditInvestorInfo';
import ManageClassInfo from '../../pages/issuer/Dashboard/ManageClassInfo';
import SetupWizard from '../../pages/issuer/Dashboard/SetupWizard';
import TAPartners from '../../pages/issuer/Transfer/TAPartners';
import KycPage from '../../pages/issuer/Investors/KycPage';
import Container from '@mui/material/Container';
import Communications from '../../pages/issuer/Investors/Communications';
//import Scenarios from '../../pages/issuer/Investors/Scenarios';
import Divider from '@mui/material/Divider';
import MuiAlert from '@mui/material/Alert';
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const CapTableRoutes = (props) => {
    const classes = issuerRouteStyles();
    let { path } = useRouteMatch();
    const location = useLocation();
    let current_screen = location.pathname.split('/');
    let isWizard = current_screen[current_screen.length - 1] === 'setup-wizard';
 
    let tabs = [
        {
            label: 'Cap Table',
            path: `${path}`,
            display:
                props.profile && props.profile.preferred_tabs
                    ? props.profile.preferred_tabs.captable
                    : false,
        },
        /*{
            label: 'Add New',
            path: `${path}/new`,
        },*/

        {
            label: 'Manage Classes',
            path: `${path}/class-info`,
            display:
                props.profile && props.profile.preferred_tabs
                    ? props.profile.preferred_tabs.classes
                    : false,
        },
        /*{
            label: 'Manage Categories',
            path: `${path}/category-info`,
        },*/
        {
            label: 'Manage Holders',
            path: `${path}/holder-info`,
            display:
                props.profile && props.profile.preferred_tabs
                    ? props.profile.preferred_tabs.holders
                    : false,
        },
        {
            label: 'KYC',
            path: `${path}/kyc`,
            display:
                props.profile && props.profile.preferred_tabs
                    ? props.profile.preferred_tabs.kyc
                    : false,
            disabled: isFeatureLocked(
                SUBSCRIPTION_PRO,
                props.profile && props.profile.subscription_model
                    ? props.profile.subscription_model
                    : SUBSCRIPTION_BASIC
            ),
        },
        {
            label: 'Blockchain',
            path: `${path}/blockchain`,
            disabled: isFeatureLocked(
                SUBSCRIPTION_PRO,
                props.profile && props.profile.subscription_model
                    ? props.profile.subscription_model
                    : SUBSCRIPTION_BASIC
            ),
            display:
                props.profile && props.profile.preferred_tabs
                    ? props.profile.preferred_tabs.blockchain
                    : false,
        },
        {
            label: 'Transfer Agent',
            path: `${path}/transfer-agents`,
            disabled: isFeatureLocked(
                SUBSCRIPTION_PRO,
                props.profile && props.profile.subscription_model
                    ? props.profile.subscription_model
                    : SUBSCRIPTION_BASIC
            ),
            display:
                props.profile && props.profile.preferred_tabs
                    ? props.profile.preferred_tabs.transferagent
                    : false,
        },
        /*    {
            label: 'Scenarios',
            path: `${path}/scenarios`,
        },*/
        {
            label: 'Events',
            path: `${path}/events`,
            disabled: isFeatureLocked(
                SUBSCRIPTION_PRO,
                props.profile && props.profile.subscription_model
                    ? props.profile.subscription_model
                    : SUBSCRIPTION_BASIC
            ),
            display:
                props.profile && props.profile.preferred_tabs
                    ? props.profile.preferred_tabs.events
                    : false,
        },
            /*{
                label: 'Registered Interests',
                path: `${path}/registered-interests`,
            }*/
    ];

    if (props.profile && isTALoggedAsIssuer(props.profile)) {
        tabs = tabs.filter(function(tab) {
        return tab.label !== 'Transfer Agent';
        });
    }
    
    const getActiveTab = () => {
        const preferred_tabs = tabs.filter(tab => tab.display).map(tab => tab.path);
        return Math.max(preferred_tabs.indexOf(location.pathname), 0)
    }; 

    const [value, setValue] = useState(getActiveTab());

    return (
        <Container>
            <SubMenu
                value={value}
                onChange={(e, v) => setValue(v)}
                tabs={tabs}
                title="Cap Table"
            />
            <Divider
                orientation="horizontal"
                fullWidth
                className={classes.divider}
            ></Divider>
            {!props.profile.has_cap_table &&
                props.profile.scope !== 'list' &&
                !isWizard && (
                    <React.Fragment>
                        <br />
                        <Alert
                            severity="warning"
                            style={{
                                borderRadius: 15,
                                opacity: '80%',
                                fontSize: '1rem',
                            }}
                        >
                            Cap Table has not been detected. You can upload it
                            from the{' '}
                            <b>
                                <NavLink
                                    to={`${path}/setup-wizard`}
                                    style={{
                                        textDecoration: 'underline',
                                        fontSize: '1rem',
                                        color: 'white',
                                    }}
                                >
                                    Setup Wizard tab.{' '}
                                </NavLink>
                            </b>
                        </Alert>
                        <br />
                    </React.Fragment>
                )}

            <TabPanel>
                <Switch>
                    <Route exact path={`${path}/`} component={CapTableList} />
                    <Route
                        exact
                        path={`${path}/class-info`}
                        component={ManageClassInfo}
                        disabled
                    />

                    <Route
                        exact
                        path={`${path}/holder-info`}
                        component={EditInvestorInfo}
                    />
                    <Route exact path={`${path}/kyc`} component={KycPage} />

                    <Route
                        exact
                        path={`${path}/documents`}
                        component={UploadDocuments}
                    />
                    <Route
                        exact
                        path={`${path}/blockchain`}
                        component={BlockchainScreen}
                    />
                    <Route
                        exact
                        path={`${path}/transfer-agents`}
                        component={TAPartners}
                    />

                    {/*    <Route
                        exact
                        path={`${path}/scenarios`}
                        component={Scenarios}
                    />*/}
                    <Route
                        exact
                        path={`${path}/events`}
                        component={Communications}
                    />
                    <Route
                        exact
                        path={`${path}/setup-wizard`}
                        component={SetupWizard}
                    />

                    {/*<Route
                        exact
                        path={`${path}/registered-interests`}
                        component={RegisteredInterests}
                    />*/}
                </Switch>
            </TabPanel>
        </Container>
    );
};

const mapStateToProps = ({ profile }) => {
    return {
        preferredTabs: profile.preferred_tabs,
        profile: profile
    };
};

export default connect(mapStateToProps, null)(CapTableRoutes);
