import React from 'react';
import clsx from 'clsx';
import MuiButton from '@mui/material/Button';
import buttonStyles from '../styles/buttonStyles';

const Button = (props) => {
    const classes = buttonStyles();
    const { children, preset, className, ...rest } = props;
    const colorClass = preset ? classes[preset] : null;

    return (
        <MuiButton
            variant="contained"
            className={clsx(classes.button, colorClass, className)}
            {...rest}
        >
            {children}
        </MuiButton>
    );
};

export default Button;
