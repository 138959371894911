import Tab from '@mui/material/Tab';

import { styled } from '@mui/material/styles';

const StyledMainTab = styled(Tab)(({ isVerticalTab }) => ({
    textTransform: 'capitalize', 
    fontSize: '16px', 
    minWidth: '30px'
}))

export default StyledMainTab