import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    Box,
    Modal,
    Grid,
    Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import TextField from '../../TextField/TextField';
import StyledPaper from '../../StyledPaper';
import {
    IssuingToken,
    TransferForm,
    Assignments,
    Balance,
    Activities,
    ReissueAsset,
} from '../components/Assets';
import { assets as assetsAPI, wallet} from '../api';
import { modalStyles } from '../../styles/modalStyles';
import { snackbar } from '../../../utilities/snackbarUtils';
import { errorParser } from '../utils/errorParser';
import * as liquidBlocksreamActions from '../../../store/liquidBlocksteam/liquidBlockstreamActions';
import CircularLoader from '../../CircularLoader';
import TableComponent from '../components/Table';
import * as Yup from 'yup';
import FormikAutocomplete from '../../FormikAutocomplete';

const DISTRIBUTION_TASKS_KEY = 'distribution-tasks';
const DISTRIBUTION_TASKS_INIT_VALUE = [];

const initialCreteAssignmentFormValues = {
    registeredUserId: '',
    amount: '',
    readyForDistribution: true,
    vestingTimestamp: '',
};
const initialDistribute = {
    walletName: '',
};
const initialUpdateValues = {
    issuerAuthorizationEndpoint: '',
};

const creteAssignmentFormValidationSchema = Yup.object({
    amount: Yup.string()
        .required('Please enter the total amount')
        .matches(
            /^(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)$/,
            'Please enter a valid amount.'
        )
        .notOneOf(['0'], 'The amount cannot be 0.'),
    registeredUserId: Yup.number().required('Please enter Registered User ID'),
});
const distributeValidationSchema = Yup.object({
    walletName: Yup.string().required('Please select Wallet.'),
});
const updateValidationSchema = Yup.object({
    issuerAuthorizationEndpoint: Yup.string().required(
        'Please fill this field.'
    ),
});

const tableCell = [
    {
        headerName: 'Name',
        field: 'name',
        cellType: 'truncated',
        useTooltip: true,
        colProps: {
            width: '10%',
        },
    },
    {
        headerName: 'Domain',
        field: 'domain',
        cellType: 'truncated',
        useTooltip: true,
        colProps: {
            width: '10%',
        },
    },
    { headerName: 'Authorized', field: 'isAuthorized' },
    { headerName: 'Requirements', field: 'requirements' },
    { headerName: 'Ticker Name', field: 'ticker' },
    { headerName: 'Issuer', field: 'issuer' },
    {
        field: 'assetId',
        headerName: 'Asset Id',
        cellType: 'truncated',
        useTooltip: true,
        colProps: {
            width: '10%',
        },
    },
    {
        headerName: 'Asset User Id',
        field: 'assetUuid',
        cellType: 'truncated',
        useTooltip: true,
        colProps: {
            width: '10%',
        },
    },
    {
        headerName: 'Public Key',
        field: 'pubkey',
        cellType: 'truncated',
        useTooltip: true,
        colProps: {
            width: '10%',
        },
    },
    {
        headerName: 'Reissuance Token Id',
        field: 'reissuanceTokenId',
        cellType: 'truncated',
        useTooltip: true,
        colProps: {
            width: '10%',
        },
    },
];

const Assets = (props) => {
    const { assets, wallets, loading } = useSelector(
        (state) => state.liquidBlockstream
    );
    const [showCreateAssignmentModal, setShowCreateAssignmentModal] = useState(
        false
    );
    const [showDistributeModal, setShowDistributeModal] = useState(false);
    const [updateAssetModal, setUpdateAssetModal] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState();

    const dispatch = useDispatch();
    const modalClasses = modalStyles();

    useEffect(() => {
        !assets.length && dispatch(liquidBlocksreamActions.getAssets());
        !wallets.length && dispatch(liquidBlocksreamActions.getWallets());
    }, []);

    const getDistributionTasks = () => {
        const distributionTasks = localStorage.getItem(DISTRIBUTION_TASKS_KEY);

        if (!distributionTasks) {
            localStorage.setItem(
                DISTRIBUTION_TASKS_KEY,
                JSON.stringify(DISTRIBUTION_TASKS_INIT_VALUE)
            );
            return DISTRIBUTION_TASKS_INIT_VALUE;
        }

        return JSON.parse(distributionTasks);
    };

    const checkDistributionTaskStatus = (assetId) => {
        const distributionTasks = getDistributionTasks();
        return distributionTasks.find((task) => task.assetId === assetId);
    };

    const addDistributionTask = (assetId, distributionTaskId) => {
        const distributionTasks = getDistributionTasks();
        distributionTasks.push({
            assetId: assetId,
            distributionTaskId: distributionTaskId,
        });

        localStorage.setItem(
            DISTRIBUTION_TASKS_KEY,
            JSON.stringify(distributionTasks)
        );
    };

    const removeDistributionTask = (assetId) => {
        let distributionTasks = getDistributionTasks();

        if (!distributionTasks) return;

        distributionTasks = distributionTasks.filter(
            (task) => task.assetId !== assetId
        );

        localStorage.setItem(
            DISTRIBUTION_TASKS_KEY,
            JSON.stringify(distributionTasks)
        );
    };

    const registerAsset = async (itemId, item) => {
        snackbar.info('Registering the asset...');

        try {
            await assetsAPI.register(item.assetUuid);
            snackbar.success('The asset has been registered successfully!');
        } catch (err) {
            snackbar.error(errorParser(err));
        }
    };

    const authorizeAsset = async (itemId, item) => {
        snackbar.info('Authorizing the asset...');

        try {
            await assetsAPI.authorize(item.assetUuid, item);
            snackbar.success('The asset has been authorized successfully!');
        } catch (err) {
            snackbar.error(errorParser(err));
        }
    };

    const distributeAssets = async (itemId, item) => {
        setShowDistributeModal(item);
    };

    const cancelAssetDistribution = async (itemId, item) => {
        snackbar.info('Trying to cancel asset distribution...');

        const errorNoAssignments = 'There are no assignments to cancel';
        try {
            const assignments = (await assetsAPI.getAssignments(item.assetUuid)).data;

            if (assignments.length <= 0) {
                return cancelAssetDistributionError(errorNoAssignments);
            }

            let isAssignmentsToCancel = false;
            assignments.forEach(assignment => {
                if (assignment.distributionUuid && assignment.distributionUuid !== '' && !assignment.isDistributed) {
                    isAssignmentsToCancel = true;

                    try {
                        assetsAPI.cancelDistribution(item.assetUuid, assignment.distributionUuid);
                    } catch (cancelError) {
                        snackbar.error(errorParser(cancelError));
                    }
                }
            });

            if (isAssignmentsToCancel) {
                snackbar.success('Asset has been cancelled');
            } else {
                snackbar.error(errorNoAssignments);
            }
        } catch (err) {
            snackbar.error(errorParser(err));
        }
    };

    const cancelAssetDistributionError = (error) => {
        snackbar.error(error);
    }

    const distributeAssetRequest = async (data, setSubmitting, resetForm) => {
        const item = showDistributeModal;
        snackbar.info('Initializing the asset distribution...');

        try {
            const response = await assetsAPI.distribute(
                item.assetUuid,
                data.walletName
            );
            snackbar.info(`Your distribution request was sent`);
            addDistributionTask(item.assetUuid, response.data.taskId);
            setShowDistributeModal(false);
            resetForm();
        } catch (err) {
            snackbar.error(errorParser(err));
        }
        setSubmitting(false);
    };

    const checkDistributionTasks = async (itemId, item) => {
        const distributionTasks = getDistributionTasks();

        // exit if no saved tasks found
        if (!distributionTasks.length) {
            snackbar.info(
                'No distribution tasks running for any asset at the moment'
            );
            return;
        }

        const thisAssetRunningDistributionTask = checkDistributionTaskStatus(
            item.assetUuid
        );

        // exit if no tasks found for this asset
        if (!thisAssetRunningDistributionTask) {
            snackbar.info('No distribution tasks found for this asset');
            return;
        }

        // otherwise get the task status
        try {
            const response = await wallet.getTaskStatus(
                thisAssetRunningDistributionTask.distributionTaskId
            );
            snackbar.info(`The distribution task status is ${response.status}`);

            // @todo replace with the actual status text
            if (response.status === 'done')
                removeDistributionTask(item.assetUuid);
        } catch (err) {
            snackbar.error(errorParser(err));
        }
    };

    const createAssignment = (itemId, Item) => {
        setShowCreateAssignmentModal(Item);
    };

    const sendAssignments = async (data, setSubmitting, resetForm) => {
        const item = showCreateAssignmentModal;
        snackbar.info('Creating the assignment...');

        try {
            await assetsAPI.sendAssignments(item.assetUuid, data);
            resetForm();
            setShowCreateAssignmentModal(false);
            snackbar.success('Assignment created successfully!');
            setShowCreateAssignmentModal(false);
        } catch (err) {
            snackbar.error(errorParser(err));
        } finally {
            setSubmitting(false);
        }
    };

    const updateAsset = async (data, setSubmitting, resetForm) => {
        const item = updateAssetModal;
        try {
            await assetsAPI.updateAsset(item.assetUuid, data);
            resetForm();
            setUpdateAssetModal(false);
        } catch (err) {
            snackbar.error(errorParser(err));
        }
        setSubmitting(false);
    };

    const getModalData = () => {
        const initialValues =
            (showDistributeModal && initialDistribute) ||
            (showCreateAssignmentModal && initialCreteAssignmentFormValues) ||
            (updateAssetModal && initialUpdateValues);

        const validationSchema =
            (showDistributeModal && distributeValidationSchema) ||
            (showCreateAssignmentModal &&
                creteAssignmentFormValidationSchema) ||
            (updateAssetModal && updateValidationSchema);

        const options = wallets.map((item) => ({
            value: item.name,
            name: item.name,
        }));

        return (
            <>
                <Typography variant="body1" textAlign="center">
                    {showDistributeModal
                        ? 'Distribute Asset'
                        : 'Create Assignment'}
                </Typography>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        if (showDistributeModal) {
                            distributeAssetRequest(
                                values,
                                setSubmitting,
                                resetForm
                            );
                        }
                        if (showCreateAssignmentModal) {
                            sendAssignments(values, setSubmitting, resetForm);
                        }
                        if (updateAssetModal) {
                            updateAsset(values, setSubmitting, resetForm);
                        }
                    }}
                >
                    {({ submitForm, isSubmitting }) => (
                        <Form noValidate style={{ width: '100%' }}>
                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                justifyContent="center"
                            >
                                {showDistributeModal && (
                                    <Grid item xs={12}>
                                        <Field
                                            component={FormikAutocomplete}
                                            name="walletName"
                                            label="Wallet Name"
                                            options={options}
                                            optionKey="name"
                                        />
                                    </Grid>
                                )}
                                {showCreateAssignmentModal && (
                                    <>
                                        <Grid item xs={12}>
                                            <Field
                                                component={TextField}
                                                name="registeredUserId"
                                                label="Registered User Id"
                                                type="string"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                component={TextField}
                                                name="amount"
                                                label="Amount"
                                                type="number"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Field
                                                component={TextField}
                                                name="vestingTimestamp"
                                                label="Timestamp"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                component={CheckboxWithLabel}
                                                type="checkbox"
                                                name="readyForDistribution"
                                                Label={{
                                                    label:
                                                        'Ready For Distribution',
                                                }}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {updateAssetModal && (
                                    <Grid item xs={12}>
                                        <Field
                                            component={TextField}
                                            name="issuerAuthorizationEndpoint"
                                            label="Issuer Authorization Endpoint"
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                        />
                                    </Grid>
                                )}
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={submitForm}
                                        disabled={isSubmitting}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </>
        );
    };

    return (
        <>
            <StyledPaper elevation={3} marginBottom={20} padding={2}>
                <Box mb={2}>
                    <Typography textAlign="center" variant="body1">
                        All Assets
                    </Typography>
                </Box>
                {loading ? (
                    <CircularLoader />
                ) : (
                    <>
                        {!!assets.length ? (
                            <TableComponent
                                columns={tableCell}
                                items={assets}
                                onRowClick={(itemId, item) =>
                                    setSelectedAsset(item)
                                }
                                threeDotsActions={[
                                    {
                                        label: 'Create Assignment',
                                        handler: createAssignment,
                                    },
                                    {
                                        label: 'Register',
                                        handler: registerAsset,
                                        disableOn: 'isRegistered',
                                    },
                                    {
                                        label: 'Authorize',
                                        handler: authorizeAsset,
                                        disableOn: 'isAuthorized',
                                    },
                                    {
                                        label: 'Distribute',
                                        handler: distributeAssets,
                                    },
                                    {
                                        label: 'Cancel Distribution',
                                        handler: cancelAssetDistribution,
                                    },
                                    {
                                        label: 'Check Distribution Tasks',
                                        handler: checkDistributionTasks,
                                    },
                                    {
                                        label:
                                            'Set Issuer Authorization Endpoint',
                                        handler: (id, item) =>
                                            setUpdateAssetModal(item),
                                    },
                                ]}
                            />
                        ) : (
                            <Typography textAlign="center" variant="body2">
                                There're no assets!
                            </Typography>
                        )}
                    </>
                )}
            </StyledPaper>
            <StyledPaper elevation={3} marginBottom={20}>
                <Accordion elevation={0} style={{ borderRadius: '15px' }}>
                    <AccordionSummary>
                        <Typography variant="body1">
                            Issuing new Asset
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <IssuingToken />
                    </AccordionDetails>
                </Accordion>
            </StyledPaper>
            <StyledPaper elevation={3} marginBottom={20}>
                <Accordion elevation={0} style={{ borderRadius: '15px' }}>
                    <AccordionSummary>
                        <Typography variant="body1">Transfer Asset</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TransferForm />
                    </AccordionDetails>
                </Accordion>
            </StyledPaper>
            {selectedAsset && (
                <StyledPaper elevation={3}>
                    <Box mb={2}>
                        <Typography variant="body1" textAlign="center">
                            Asset Information
                        </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <Balance assetUuid={selectedAsset.assetUuid} />
                        <ReissueAsset asset={selectedAsset} wallets={wallets} />
                        <Activities assetUuid={selectedAsset.assetUuid} />
                        <Assignments assetUuid={selectedAsset.assetUuid} />
                    </Box>
                </StyledPaper>
            )}
            {/* Create Assignment Modal */}
            <Modal
                className={modalClasses.modal}
                open={
                    showCreateAssignmentModal ||
                    showDistributeModal ||
                    updateAssetModal
                }
                onClose={() => {
                    setShowCreateAssignmentModal(false);
                    setShowDistributeModal(false);
                    setUpdateAssetModal(false);
                }}
            >
                <Box className={modalClasses.paper}>{getModalData()}</Box>
            </Modal>
        </>
    );
};

export default Assets;
