import React from 'react';
import Container from '@mui/material/Container';
import Header from '../../../components/Header/Header';
import Title from '../../../components/Title/Title';
import DataTable from '../../../components/DataTable/DataTable';
import * as config from './../../../config';

const Events = () => {
    const columns = [
        {
            id: 'date',
            label: 'Date',
            format: { type: 'date', format: 'date' },
            align: 'left',
            width: '20%',
        },
        {
            id: 'title',
            label: 'Title',
            format: { type: 'text' },
            align: 'left',
            width: '80%',
        },
    ];

    return (
        <React.Fragment>
            <Header />
            <Title text="Events" />
            <Container>
                <DataTable
                    dataColumns={columns}
                    remoteUrl={`${config.API_BASE_URL}/event/list`}
                    enableSearch
                    enableExport
                    autoLayout
                />
            </Container>
        </React.Fragment>
    );
};

export default Events;
