import React, { useState } from 'react';
import authStyles from '../authStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Paper from '../../../components/StyledPaper';
import PasswordField from '../../../components/PasswordField';

import { useSnackbar } from 'notistack';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as profileActions from '../../../store/profile/profileActions';

const validationSchema = Yup.object({
    old_password: Yup.string().required('Please enter your current password.'),
    password: Yup.string()
        .required('Please enter your new password.')
        //  .min(8, 'Password is too short - must containt at least 8 characters.')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,

            'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, number and one symbol.'
        ),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match.')
        .required('Please confirm your password.'),
});

const initialValues = {
    old_password: '',
    password: '',
    confirm_password: '',
};

const UpdatePassword = (props) => {
    const classes = authStyles();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    return (
        <React.Fragment>
            <Grid item xs={props.xs || 12} md={props.md || 6}>
                <Paper padding={props.padding}>
                    <Typography variant="overline">
                        <h1>Update Password</h1>
                    </Typography>
                    <FormControl component="fieldset" className="content">
                        <FormLabel component="legend">
                            Our suggestion is minimum 8 characters, one upper
                            case, at least one number and one symbol.
                        </FormLabel>
                        <FormGroup>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={(
                                    values,
                                    { setSubmitting, resetForm }
                                ) => {
                                    props.profileActions.updatePassword(
                                        values,
                                        setLoading,
                                        setSubmitting,
                                        resetForm,
                                        enqueueSnackbar
                                    );
                                }}
                            >
                                {({ errors, setFieldValue }) => (
                                    <React.Fragment>
                                        <Typography
                                            component="div"
                                            style={{ textAlign: 'center' }}
                                        ></Typography>
                                        <Form noValidate>
                                            <PasswordField
                                                label="Current password"
                                                name="old_password"
                                            />
                                            <PasswordField
                                                label="New password"
                                                name="password"
                                            />
                                            <PasswordField
                                                label="Confirm password"
                                                name="confirm_password"
                                            />
                                            <br />
                                            <Button
                                                style={{ marginTop: 10 }}
                                                type="submit"
                                                variant="outlined"
                                                className={classes.buttonLabel}
                                                color="primary"
                                                disabled={loading}
                                            >
                                                {loading ? (
                                                    <CircularProgress
                                                        size={24}
                                                    />
                                                ) : (
                                                    'Update'
                                                )}
                                            </Button>
                                        </Form>
                                    </React.Fragment>
                                )}
                            </Formik>
                        </FormGroup>
                        <br />
                    </FormControl>
                </Paper>
            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps = ({ profile }) => {
    return {
        profile: profile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        profileActions: bindActionCreators(profileActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);
