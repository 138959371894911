import makeStyles from '@mui/styles/makeStyles';
import BackgroundImage from '../../assets/img/bg-box-primary.jpg';
const dashBoxStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        width: '100%',
        minHeight: '8.5rem',
        fontSize: '1.5rem',
        fontWeight: '500',
        textAlign: 'right',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        padding: '0.5rem 1rem',
        cursor: 'pointer',
        boxShadow:
            '0 10px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05)',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        '&:hover': {
            boxShadow:
                '0 15px 30px -3px rgba(0,0,0,.15), 0 8px 12px -2px rgba(0,0,0,.15)',
        },
        [theme.breakpoints.down('md')]: {
            minHeight: '6rem',
            gridArea: 'unset !important',
        },
    },
    disabled: {
        '&:hover': {
            cursor: 'not-allowed',
        },
    },
    primary: {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: 'cover',
        backgroundColor: '#070B13',
        color: '#FFF',
        fontSize: '3rem',
    },
    white: {
        backgroundColor: '#FFF',
        color: '#333',
    },
    blue: {
        backgroundColor: '#3E9CD9',
        color: '#FFF',
    },
    gray: {
        backgroundColor: '#666',
        color: '#FFF',
    },
    lightGray: {
        backgroundColor: '#CCC',
        color: '#333',
    },
    darkGray: {
        backgroundColor: '#333',
        color: '#FFF',
    },
}));

export default dashBoxStyles;
