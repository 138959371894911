import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import Container from '@mui/material/Container';
import authStyles from '../../authStyles';
import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
import { CheckboxWithLabel } from 'formik-mui';
import Typography from '@mui/material/Typography';
import FundButtonGroup from '../FundButtonGroup';
const Complete = (props) => {
    const classes = authStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [isAcknowledged, setIsAcknowledged] = useState(
        props.initialValues.acknowledge_information
    );
    let [refreshForm, setRefreshForm] = useState(0);

    const nextStep = () => {
        props.nextStep();
    };

    const previousStep = () => {
        props.previousStep();
    };

    const closeDialog = () => {
        props.closeDialog();
    };

    const steps = [
        {
            description:
                'Our compliance team will execute the bad actor checks on your company and all the associated persons listed.',
            key: 0,
        },
        {
            description:
                'Our compliance team will review your PPM for completion and accuracy and may come back to you with comments that will require review on your part with your securities attorney.',
            key: 1,
        },
        {
            description:
                'Our compliance team will review your subscription agreement for completeness and accuracy and we will add necessary macros to the document that will be used to input investor data collected from the invest now button or your API system.',
            key: 2,
        },
        {
            description:
                'It will take approximately 2 to 5 business days to complete the bad actor checks and review the PPM and Subscription agreement.',
            key: 3,
        },
        {
            description:
                'If you are using broker-dealer services, it will take an additional 2 to 5 business days to complete the broker dealer-due diligence requirements as prescribed by FINRA and the SEC. This is to give us time to make sure that if there are any comments that need to be addressed as required by the broker-dealer, there is ample time to complete the changes.',
            key: 4,
        },
    ];

    return (
        <div id="step9">
            <Formik
                key={refreshForm}
                initialValues={props.initialValues}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    if (values['acknowledge_information'] === true) {
                        props.saveValues(values);
                        nextStep();
                        props.changeKey();
                    } else {
                        enqueueSnackbar('Please check box to continue', {
                            variant: 'error',
                        });
                    }

                    resetForm();
                    refreshForm++;
                    setRefreshForm(refreshForm);
                }}
            >
                {({ setFieldValue }) => (
                    <Container maxWidth="lg">
                        <Form className={classes.form} noValidate>
                            <Typography component="div">
                                <h3>
                                    Congratulations on completing the offering
                                    onboarding wizard!
                                </h3>
                            </Typography>

                            <Typography component="div">
                                Now that you have completed inputting your raise
                                information here is what you can expect while we
                                work to get your offering live:
                            </Typography>
                            <br />
                            <Typography component="div">
                                <ol>
                                    {steps.map((step) => {
                                        return (
                                            <li key={step.key}>
                                                {step.description}
                                            </li>
                                        );
                                    })}
                                </ol>
                            </Typography>
                            <br />
                            <Grid container spacing={1}>
                                <Field
                                    component={CheckboxWithLabel}
                                    type="checkbox"
                                    name="acknowledge_information"
                                    Label={{
                                        label: (
                                            <div className={classes.checkbox}>
                                                I acknowledge that the
                                                information I have provided for
                                                my issue is true and complete to
                                                the best of my knowledge. I
                                                accept that any fees listed
                                                above will be charged to my
                                                business account. I understand
                                                that due diligence for the
                                                escrow service and technology
                                                service application may take 2-5
                                                business days for completion. I
                                                understand that if I elect to
                                                use broker-dealer services the
                                                due diligence on that selling
                                                agreement may take an additional
                                                2-5 business days to be
                                                completed. I understand that
                                                changes or omissions that must
                                                be corrected during any review
                                                process can further delay the
                                                compliance process.
                                            </div>
                                        ),
                                    }}
                                    checked={isAcknowledged}
                                    onClick={() =>
                                        setIsAcknowledged(!isAcknowledged)
                                    }
                                    required
                                />
                            </Grid>

                            <FundButtonGroup
                                previousStep={previousStep}
                                closeDialog={closeDialog}
                            />
                        </Form>
                    </Container>
                )}
            </Formik>
        </div>
    );
};

export default Complete;
