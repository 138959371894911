import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as profileActions from '../../../store/profile/profileActions';
import { useSnackbar } from 'notistack';
import authStyles from '../authStyles';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import FormPanel from '../../../components/FormPanel/FormPanel';
import FormPanelRow from '../../../components/FormPanelRow/FormPanelRow';
import TextField from '../../../components/TextField/TextField';
import HelpTooltip from '../../../components/HelpTooltip/HelpTooltip';
import request from '../../../utilities/request';
import * as config from '../../../config';
import { isLargeScreen } from '../../../utilities/utils';
import Paper from '../../../components/StyledPaper';

const initialValues = {
    email: '',
    created_at: '',
    legal_entity_name: '',
    organization_code: '',
    registered_country: '',
    status: '',
    subscription_model: '',
    subscription_choice: '',
};

const validationSchema = Yup.object({
    name: Yup.string().required('Please enter your full name'),
    // email: Yup.string()
    //     .email('Please enter a valid email address')
    //     .required('Please enter your email address'),
});

const UserDetails = () => {
    const dispatch = useDispatch();
    const classes = authStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({ ...initialValues });

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            const response = await request.get(
                `${config.API_BASE_URL}/profile/`
            );

            for (let prop in response.data) {
                if (
                    response.data[prop] === undefined ||
                    response.data[prop] === null
                ) {
                    response.data[prop] = '';
                }
            }

            setData({ ...initialValues, ...response.data });
            setIsLoading(false);
        }
        fetchData();
    }, []);

    return (
        <React.Fragment>
            {!isLoading ? (
                <Formik
                    initialValues={data}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        closeSnackbar();
                        request
                            .patch(`${config.API_BASE_URL}/profile`, values)
                            .then(() => {
                                dispatch(profileActions.getProfile());
                                enqueueSnackbar(
                                    'The changes to your profile have been saved',
                                    { variant: 'success' }
                                );
                            })
                            .catch(() => {
                                enqueueSnackbar(
                                    'An error occurred while saving the changes',
                                    { variant: 'error' }
                                );
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {({ isSubmitting }) => (
                        <Paper>
                            <Typography variant="overline">
                                <h1>User Information</h1>
                            </Typography>
                            <Form noValidate>
                                <FormPanel>
                                    <FormPanelRow></FormPanelRow>
                                    <FormPanelRow
                                        className={
                                            isLargeScreen()
                                                ? null
                                                : 'smallScreenWidth'
                                        }
                                        title="Account Details"
                                        description={
                                            <HelpTooltip
                                                title={
                                                    'Name of the person who created account'
                                                }
                                            />
                                        }
                                    >
                                        <Field
                                            component={TextField}
                                            name="name"
                                            type="text"
                                            label="Representative Name"
                                            fullWidth={isLargeScreen()}
                                            required
                                        />
                                    </FormPanelRow>
                                    <FormPanelRow
                                        description={
                                            <HelpTooltip
                                                title={'Email address'}
                                            />
                                        }
                                    >
                                        <Field
                                            component={TextField}
                                            name="email"
                                            type="email"
                                            label="Email"
                                            fullWidth
                                            required
                                            variant="outlined"
                                            displayOnly
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </FormPanelRow>
                                    <FormPanelRow
                                        description={
                                            <HelpTooltip
                                                title={'Date of registration'}
                                            />
                                        }
                                    >
                                        <Field
                                            component={TextField}
                                            name="created_at"
                                            type="text"
                                            label="Created at"
                                            fullWidth
                                            variant="outlined"
                                            displayOnly
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </FormPanelRow>
                                    <FormPanelRow
                                        description={
                                            <HelpTooltip
                                                title={'Subscription model'}
                                            />
                                        }
                                    >
                                        <Field
                                            component={TextField}
                                            name="subscription_model"
                                            type="text"
                                            label="Subscription model"
                                            fullWidth
                                            variant="outlined"
                                            displayOnly
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </FormPanelRow>
                                    <FormPanelRow
                                        description={
                                            <HelpTooltip
                                                title={'Status of account'}
                                            />
                                        }
                                    >
                                        <Field
                                            component={TextField}
                                            name="status"
                                            type="text"
                                            label="Status"
                                            fullWidth
                                            variant="outlined"
                                            displayOnly
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </FormPanelRow>
                                </FormPanel>
                                <FormPanel>
                                    <FormPanelRow>
                                        <div className={classes.flexCenter}>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                variant="outlined"
                                                className={classes.buttonLabel}
                                                disabled={isSubmitting}
                                            >
                                                {!isSubmitting ? (
                                                    <span>Save</span>
                                                ) : (
                                                    <CircularProgress
                                                        size={24}
                                                    />
                                                )}
                                            </Button>
                                        </div>
                                    </FormPanelRow>
                                </FormPanel>
                            </Form>
                        </Paper>
                    )}
                </Formik>
            ) : (
                <Grid container item xs={12} justifyContent="center">
                    <CircularProgress />
                </Grid>
            )}
        </React.Fragment>
    );
};

export default UserDetails;
