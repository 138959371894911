import React from 'react'

import MuiSelect from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { Controller } from "react-hook-form";

const Select = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: { onChange, value } }) => (
        <FormControl error={props.error !== null && props.error !== undefined} fullWidth>
            {
                props.label &&
                <InputLabel>{props.label}</InputLabel>
            }
            <MuiSelect
                onChange={onChange}
                label={props.label}
                disabled={props.disabled}
                placeholder={props.placeholder}
                defaultValue={props.default !== null ? props.options[props.default].value : ""}
                // InputProps={{
                //     startAdornment: props.leftIcon ? (
                //         <InputAdornment position="start">
                //         {props.leftIcon}
                //         </InputAdornment>
                //     ) : null
                // }}
            >{
                props.options && props.options.map((option, i) => (
                    <MenuItem key={i} value={option.value} autoFocus={props.default === i}>
                        {option.text}
                    </MenuItem>
                ))
            }
            </MuiSelect>
            {
                (props.error !== null && props.error !== undefined) &&
                <FormHelperText>{props.error?.message}</FormHelperText>
            }
        </FormControl>
      )}
    />
  )
}

export default Select