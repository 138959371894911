import * as investorInviteTypes from './investorInviteTypes';

const initialState = {
    data: null,
    loading: true,
};

const investorInviteReducer = (state = initialState, action) => {
    switch (action.type) {
        case investorInviteTypes.GET_INVESTOR_INVITES_SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case investorInviteTypes.SET_INVESTOR_INVITES_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        default:
            return state;
    }
};

export default investorInviteReducer;
