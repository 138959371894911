import useSWR from "swr"
import { API_BASE_URL } from "../../config"
import fetcher from "../../utilities/fetcher"

const useHolders = () => {
    const { data, error, isValidating, mutate } = useSWR(`${API_BASE_URL}/blockchain/holders`, fetcher)

    return {
        holders: data,
        holdersLoading: !error && !data,
        holdersValidating: isValidating,
        holdersError: error,
        updateHolders: () => mutate()
    }
}

export default useHolders