import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
//import Humanize from 'humanize-plus';
import Tooltip from '@mui/material/Tooltip';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formatNumber } from '../../../utilities/utils';
import * as capTableActions from '../../../store/capTable/capTableActions';
import CategoryDialog from '../../../components/InfoDialog/CategoryDialog';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import IconButton from '@mui/material/IconButton';
import { NEXUS_BROWSER } from '../../../constants/blockchain';
import EditCategoryDialog from './EditCategoryDialog';
import DeleteCategoryDialog from './DeleteCategoryDialog';
const StyledTableCell = styled(TableCell)({
    '&.MuiTableCell-body': {
        fontSize: 14,
    },
    '&.MuiTableCell-head': {
        backgroundColor: '#EAEAEA',
        color: 'black',
    },
    borderBottom: 0,
});

const StyledTableRow = styled(TableRow)({
    '&:nth-of-type(odd)': {
        backgroundColor: '#FFF',
        height: '50px',
    },
    '& > *': {
        borderBottom: 'unset',
        marginBottom: '1rem',
    },
});

function CategoriesWithActionsRow(props) {
    const { categories } = props;
    //const [open, setOpen] = useState(false);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
    const [category, setCategory] = useState([]);

    const openDialog = (id) => {
        setDialogIsOpen(true);
        props.capTableActions.getEntry(id);
    };

    const displayEditCategoryDialog = (item) => {
        let data = Object.assign({}, item);
        if (data.category_price === null) {
            data.category_price = '';
        } else {
            data.category_price = formatNumber(data.category_price);
        }

        if (data.category_amount === null) {
            data.category_amount = '';
        } else {
            data.category_amount = formatNumber(data.category_amount);
        }
        if (data.current_price === null) {
            data.current_price = '';
        } else {
            data.current_price = formatNumber(data.current_price);
        }
        setCategory(data);
        setEditDialogIsOpen(true);
    };
    const displayDeleteCategoryDialog = (id) => {
        setDeleteDialogIsOpen(true);
    };

    return (
        <React.Fragment>
            <EditCategoryDialog
                categoryData={category}
                dialogIsOpen={editDialogIsOpen}
                setDialogIsOpen={setEditDialogIsOpen}
                setClassTableKey={props.setClassTableKey}
                classTableKey={props.classTableKey}
            />
            <DeleteCategoryDialog
                categoryData={categories}
                dialogIsOpen={deleteDialogIsOpen}
                setDialogIsOpen={setDeleteDialogIsOpen}
                setClassTableKey={props.setClassTableKey}
                classTableKey={props.classTableKey}
            />
            <CategoryDialog
                shareCategory={categories}
                dialogIsOpen={dialogIsOpen}
                setDialogIsOpen={setDialogIsOpen}
            />
            <StyledTableRow>
                <StyledTableCell
                    align="left"
                    style={{
                        color: '#9F9F9F',
                        fontSize: '1rem',
                    }}
                ></StyledTableCell>
                <StyledTableCell
                    onClick={() => openDialog(categories.category_id)}
                    colSpan={2}
                    style={{
                        color: '#9F9F9F',
                        fontSize: '1rem',
                        cursor: 'pointer',
                    }}
                >
                    <Tooltip
                        title={
                            'Click on the category description to see more information.'
                        }
                        arrow
                    >
                        <span>{categories.category_description}</span>
                    </Tooltip>
                </StyledTableCell>
                <StyledTableCell
                    align="left"
                    colSpan={2}
                    style={{
                        color: '#9F9F9F',
                        fontSize: '1rem',
                    }}
                >
                    {formatNumber(categories.category_amount)}
                </StyledTableCell>
                <StyledTableCell
                    align="left"
                    style={{
                        color: '#9F9F9F',
                        fontSize: '1rem',
                    }}
                >
                    {categories.category_price &&
                    categories.equity_main_currency
                        ? `${formatNumber(categories.category_price)} ${
                              categories.equity_main_currency
                          }`
                        : '-'}
                </StyledTableCell>
                <StyledTableCell>
                    <React.Fragment>
                        {
                            // Non-issued assets can be edited and deleted.
                            // Issued assets can be viewed on the blockchain.
                            categories && categories.edit_catagory ? (
                                !categories.edit_catagory.issue_tx ? (
                                    <React.Fragment>
                                        <Tooltip title="Edit Category">
                                            <IconButton
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                className="m-r-5"
                                                disabled={
                                                    categories.edit_catagory
                                                        .issue_tx
                                                }
                                                onClick={() => {
                                                    displayEditCategoryDialog(
                                                        categories.edit_catagory
                                                    );
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete Category">
                                            <IconButton
                                                variant="contained"
                                                color="secondary"
                                                size="small"
                                                className="m-r-5"
                                                disabled={
                                                    categories.edit_catagory
                                                        .issue_tx.issue_tx
                                                }
                                                onClick={() => {
                                                    displayDeleteCategoryDialog(
                                                        categories.edit_catagory
                                                    );
                                                }}
                                            >
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </React.Fragment>
                                ) : (
                                    <Tooltip title="View on blockchain">
                                        <IconButton
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            className="m-r-5"
                                            onClick={() =>
                                                window.open(
                                                    `${NEXUS_BROWSER}/tokenDetail?token=${categories.edit_catagory.ticker}`,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            <ViewModuleIcon />
                                        </IconButton>
                                    </Tooltip>
                                )
                            ) : null
                        }
                    </React.Fragment>
                </StyledTableCell>
            </StyledTableRow>
        </React.Fragment>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        capTableActions: bindActionCreators(capTableActions, dispatch),
    };
};

export default connect(null, mapDispatchToProps)(CategoriesWithActionsRow);
