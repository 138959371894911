import makeStyles from '@mui/styles/makeStyles';

const fundraiseOfferingStyles = makeStyles((theme) => ({
    title: {
        color: '#3E9CD9',
        textAlign: 'center',
    },
    horizontalLine: {
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    btn11: {
        backgroundColor: '#0000FF',
        color: 'white',
        //width: '115px',
    },
    btn12: {
        backgroundColor: '#0000FF',
        color: 'white',
    },
    btn13: {
        backgroundColor: '#0000FF',
        color: 'white',
    },
    btn21: {
        backgroundColor: '#00FF00',
        color: 'white',
        width: '115px',
    },
    btn22: {
        backgroundColor: '#00FF00',
        color: 'white',
    },
    btn23: {
        backgroundColor: '#00FF00',
        color: 'white',
    },
    btn31: {
        backgroundColor: '#FF0000',
        color: 'white',
        width: '115px',
    },
    btn32: {
        backgroundColor: '#FF0000',

        color: 'white',
    },
    btn33: {
        backgroundColor: '#FF0000',
        color: 'white',
    },
    actions: {
        marginTop: '1.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export default fundraiseOfferingStyles;
