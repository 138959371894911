import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconButton, Tooltip } from '@mui/material';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function ActionDropdown({ asset, items }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                id={`asset-dropdown-${asset?.assetUuid}`}
                aria-controls={open ? `asset-controls-${asset?.assetUuid}` : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                size='small'
                onClick={handleClick}
            >
                <KeyboardArrowDownIcon />
            </IconButton>
            <StyledMenu
                id={`asset-menu-${asset?.assetUuid}`}
                MenuListProps={{
                    'aria-labelledby': `asset-controls-${asset?.assetUuid}`,
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {
                    items.map((item, i) => (
                        item.tooltip ?
                            <Tooltip key={i} title={item.tooltip}>
                                <MenuItem onClick={() => { handleClose(); item.onClick(); }} disableRipple>
                                    {item.icon}
                                    {item.text}
                                </MenuItem>
                            </Tooltip> :
                            <MenuItem key={i} onClick={() => { handleClose(); item.onClick(); }} disableRipple>
                                {item.icon}
                                {item.text}
                            </MenuItem>
                    ))
                }
            </StyledMenu>
        </>
    );
}