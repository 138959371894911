import React from 'react';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import formPanelRowStyles from '../styles/formPanelRowStyles';
const FormPanelRow = (props) => {
    const classes = formPanelRowStyles();
    const { children, title, description } = props;

    return (
        <AccordionDetails className={classes.container}>
            <div className={classes.titleColumn}>
                {title ? (
                    <Typography className={classes.titleContent}>
                        {title}
                    </Typography>
                ) : null}
            </div>
            <div className={classes.contentColumn}>{children}</div>
            {description && window.screen.width >= 720 ? (
                <div className={classes.descriptionColumn}>
                    <Typography className={classes.descriptionContent}>
                        {description}
                    </Typography>
                </div>
            ) : null}
        </AccordionDetails>
    );
};

export default FormPanelRow;
