import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Container from '@mui/material/Container';
import authStyles from '../../authStyles';
import TextField from '../../../../components/TextField/TextField';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormikSelect from '../../../../components/Formik/FormikSelect';
import FundButtonGroup from '../FundButtonGroup';

const validationSchema = Yup.object({
    name_on_account: Yup.string().required('Please enter name on account'),
    bank_routing_number: Yup.string().required(
        'Please enter nine digit bank routing number'
    ),
    bank_account_number: Yup.string().required(
        'Please enter bank account number'
    ),
    ach_authorization_signature: Yup.string().required(
        'Please enter ACH authorization signature'
    ),
});

const ACHForm = (props) => {
    const classes = authStyles();
    const [accountType, setAccountType] = useState(
        props.initialValues.account_type
            ? props.initialValues.account_type
            : 'CHECKING'
    );

    const [checkType, setCheckType] = useState(
        props.initialValues.check_type
            ? props.initialValues.check_type
            : 'PERSONAL'
    );

    let account_types = [
        { name: 'Checking', value: 'CHECKING' },
        { name: 'Savings', value: 'SAVINGS' },
    ];

    let check_types = [
        { name: 'Personal', value: 'PERSONAL' },
        { name: 'Business', value: 'BUSINESS' },
    ];

    const nextStep = () => {
        props.nextStep();
    };

    const previousStep = () => {
        props.previousStep();
    };

    const closeDialog = () => {
        props.closeDialog();
    };

    const changeAccountType = (e) => {
        setAccountType(e.target.value);
    };

    const changeCheckType = (e) => {
        setCheckType(e.target.value);
    };

    return (
        <div id="step3">
            <Formik
                initialValues={props.initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    values['account_type'] = accountType;
                    values['check_type'] = checkType;
                    props.saveValues(values);
                    nextStep();
                    props.changeKey();
                }}
            >
                {({ setFieldValue }) => (
                    <Container maxWidth="lg">
                        <Form className={classes.form} noValidate>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Name on Account"
                                        name="name_on_account"
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Nine Digit Bank Routing Number"
                                        name="bank_routing_number"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />{' '}
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Bank Account Number"
                                        name="bank_account_number"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />{' '}
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Field
                                        component={FormikSelect}
                                        name="account_type"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Account Type"
                                        defaultValue={accountType}
                                        onClick={changeAccountType}
                                    >
                                        {account_types.map((account_type) => (
                                            <MenuItem
                                                key={account_type.name}
                                                value={account_type.value}
                                            >
                                                {account_type.name}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        component={FormikSelect}
                                        name="check_type"
                                        label="Check Type"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        defaultValue={checkType}
                                        onClick={changeCheckType}
                                    >
                                        {check_types.map((check_type) => (
                                            <MenuItem
                                                key={check_type.name}
                                                value={check_type.value}
                                            >
                                                {check_type.name}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Field
                                        component={TextField}
                                        name="ach_agreement"
                                        type="text"
                                        rows={2}
                                        multiline
                                        fullWidth
                                        label="ACH Authorization Agreement:"
                                        defaultValue={
                                            'ACH Authorization Agreement:'
                                        }
                                        inputProps={{ readOnly: true }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        name="ach_authorization_signature"
                                        variant="outlined"
                                        margin="normal"
                                        label="ACH Authorization Signature"
                                        fullWidth
                                        required
                                        placeholder="Please type your name here to sign ACH Authorization"
                                    />
                                </Grid>
                            </Grid>

                            <FundButtonGroup
                                nextStep={nextStep}
                                previousStep={previousStep}
                                closeDialog={closeDialog}
                            />
                        </Form>
                    </Container>
                )}
            </Formik>
        </div>
    );
};

export default ACHForm;
