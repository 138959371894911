import { Stack, Typography } from '@mui/material'
import React from 'react'

import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

const ComingSoon = () => {
    return (
        <Stack direction="row" alignItems="center" justifyContent='center' gap={0} sx={{ color: 'gray' }}>
            <HourglassBottomIcon fontSize='large' />
            <Typography variant='h4' sx={{ textAlign: 'center' }}>
                Coming soon...
            </Typography>
        </Stack>
    )
}

export default ComingSoon