import React, { useState } from 'react';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';

import TabPanel from '../../components/TabPanel';
import SubMenu from '../../components/SubMenu';

import UserDetails from '../../pages/user/Profile/UserDetails';
import CompanyDetails from '../../pages/user/Profile/CompanyDetails';
import Preferences from '../../pages/issuer/Dashboard/Preferences';

import VerticalContainer from '../../components/VerticalContainer';
import IssuerInvites from '../../pages/user/IssuerInvite/IssuerInvites';
import Chat from '../../pages/user/Messaging/Chat';

const PreferenceRoutesContainer = (props) => {
    let { path } = useRouteMatch();

    const location = useLocation();

    const getActiveTab = () => {
        let pathName = location.pathname;

        switch (pathName) {
            case '/issuer/preferences':
            case '/issuer/dash/preferences':
                return 0;
            case '/issuer/preferences/user':
            case '/issuer/dash/preferences/user':
                return 1;
            case '/issuer/preferences/company':
            case '/issuer/dash/preferences/company':
                return 2;
            case '/issuer/preferences/team':
            case '/issuer/dash/preferences/team':
                return 3;
            case '/issuer/preferences/messages':
            case '/issuer/dash/preferences/messages':
                return 4;
            default:
                return 0;
        }
    };

    const tabs = [
        {
            label: 'General',
            path: `${path}`,
        },
        {
            label: 'User Information',
            path: `${path}/user`,
        },
        {
            label: 'Company Information',
            path: `${path}/company`,
        },
        {
            label: 'Invite Issuers',
            path: `${path}/team`,
        },
        {
            label: 'Messages',
            path: `${path}/messages`,
        },
    ];

    const [value, setValue] = useState(getActiveTab());

    return (
        <VerticalContainer>
            <SubMenu
                value={value}
                onChange={(e, v) => setValue(v)}
                tabs={tabs}
                title="Preferences"
            />
            <TabPanel>
                <Switch>
                    <Route exact path={`${path}`} component={Preferences} />
                    <Route
                        exact
                        path={`${path}/user`}
                        component={UserDetails}
                    />
                    <Route
                        exact
                        path={`${path}/company`}
                        component={CompanyDetails}
                    />
                    <Route
                        exact
                        path={`${path}/team`}
                        component={IssuerInvites}
                    />
                    <Route exact path={`${path}/messages`} component={Chat} />
                </Switch>
            </TabPanel>
        </VerticalContainer>
    );
};

export default PreferenceRoutesContainer;
