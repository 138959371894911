import React, { useState } from 'react';
import axios from 'axios';
import request from '../../../utilities/request';
import * as config from './../../../config';
import { Formik, Form, Field } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import authStyles from '../authStyles';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormPanel from '../../../components/FormPanel/FormPanel';
import FormPanelRow from '../../../components/FormPanelRow/FormPanelRow';
import TextField from '../../../components/TextField/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import * as registry from '../../../utilities/registry';
import FormikSelect from '../../../components/Formik/FormikSelect';
import { CheckboxWithLabel } from 'formik-mui';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { snackbar } from '../../../utilities/snackbarUtils';

import { useDispatch } from 'react-redux';
import * as profileActions from '../../../store/profile/profileActions';
import FormikAutocomplete from '../../../components/FormikAutocomplete';

const validationSchema = Yup.object({
    first_name: Yup.string().required('Please enter your name'),
    last_name: Yup.string().required('Please enter your name'),
    email: Yup.string()
        .email('Please enter a valid email address')
        .required('Please enter your email address'),
    password: Yup.string()
        .required('Please enter your password')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, number and one symbol'
        ),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Please confirm your password'),
    company_name: Yup.string().required('Please enter company name'),
    legal_entity_name: Yup.string().required('Please enter legal entity name'),
    registered_country: Yup.string().required(
        'Please enter country of incorporation'
    ),
    authorized_shares: Yup.string().required(
        'Please provide number of authorized assets'
    ),
});

const setupWizardValidationSchema = Yup.object({
    company_name: Yup.string().required('Please enter company name'),
    legal_entity_name: Yup.string().required('Please enter legal entity name'),
    registered_country: Yup.string().required(
        'Please enter country of incorporation'
    ),
});

const Review = (props) => {
    const classes = authStyles();
    const [personalPanel, setPersonalPanel] = useState(true);
    const [passwordPanel, setPasswordPanel] = useState(true);
    const [companyPanel, setCompanyPanel] = useState(true);
    const [fileName, setFileName] = useState(props.initialValues.file_name);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [requiredData, setRequiredData] = useState([]);
    const [file, setFile] = useState();
    const [reuploaded, setReuploaded] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    const updateStep = (step) => {
        props.setCustomStep(step);
    };

    const uploadFile = (file) => {
        let type = file.type;
        if (file.type === '' && file.name.split('.')[1] === 'csv') {
            type = 'text/csv';
        }

        if (
            type !== 'application/pdf' &&
            type !== 'text/csv' &&
            //    file.type !== 'text/plain' &&
            type !== 'application/vnd.ms-excel' &&
            type !==
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
            snackbar.error(
                'Only .csv, .xlx, .xls, .xlsx and .pdf files are allowed'
            );
        } else {
            props.formData.set('csv_template', file, file.name);
            setFileName(file.name);
            setFile(file);
            setReuploaded(true);
        }
    };

    const setRequiredField = (e) => {
        if (e.target.name === 'company_name') {
            requiredData['company_name'] = e.target.value;
        } else if (e.target.name === 'legal_entity_name') {
            requiredData['legal_entity_name'] = e.target.value;
        } else {
        }

        setRequiredData(requiredData);
        props.saveValues(requiredData);
    };

    /*const nextStep = () => {
        props.nextStep();
    };*/

    const changeShowPassword = () => {
        showPassword === true ? setShowPassword(false) : setShowPassword(true);
    };

    const changeShowConfirmPassword = () => {
        showConfirmPassword === true
            ? setShowConfirmPassword(false)
            : setShowConfirmPassword(true);
    };

    const handleExpansionPanelChange = (panel) => {
        switch (panel) {
            case 'personal':
                personalPanel === true
                    ? setPersonalPanel(false)
                    : setPersonalPanel(true);
                break;
            case 'password':
                passwordPanel === true
                    ? setPasswordPanel(false)
                    : setPasswordPanel(true);
                break;
            case 'company':
                companyPanel === true
                    ? setCompanyPanel(false)
                    : setCompanyPanel(true);
                break;
            default:
                personalPanel === true
                    ? setPersonalPanel(false)
                    : setPersonalPanel(true);
                break;
        }
    };

    const handleSubmission = (values, history, setSubmitting) => {
        for (var x in values) {
            if (x === 'classes') {
                if (
                    values[x].length === 1 &&
                    values[x][0]['class_description'] === ''
                ) {
                    values[x] = [];
                }
            }
            if (
                x === 'classes' ||
                x === 'categories' ||
                x === 'shareholders' ||
                x === 'investors' ||
                x === 'class_categories_number'
            ) {
                props.formData.append(x, JSON.stringify(values[x]));
            } else {
                props.formData.append(x, values[x]);
            }
        }

        if (props.setupWizard) {
            props.formData.append('setup_wizard', true);
        }

        /* Choose appropriate Axios request type */
        let r;
        if (props.setupWizard) {
            r = request;
        } else {
            r = axios;
        }

        r.post(
            `${config.API_BASE_URL}/${
                props.setupWizard ? 'setup' : 'register'
            }`,
            props.formData
        )
            .then(() => {
                snackbar.success(
                    props.setupWizard
                        ? 'Your cap table has been uploaded.'
                        : 'Your account has been created.'
                );
                if (props.setupWizard) {
                    dispatch(profileActions.getProfile());
                    history.replace('/issuer'); // quick hack for now
                } else {
                    props.nextStep();
                }
            })
            .catch((error) => {
                if (error.response.data.message) {
                    if (
                        error.response.data.message.includes(
                            'in the CSV is missing the investor or company name.'
                        ) ||
                        error.response.data.message.includes(
                            'is required, but missing in row'
                        ) ||
                        error.response.data.message.includes(
                            'is missing from your CSV file'
                        )
                    ) {
                        snackbar.error(
                            <Container>
                                {error.response.data.message} +{' '}
                                <Button
                                    style={{
                                        color: 'white',
                                        textTransform: 'none',
                                    }}
                                    onClick={() => updateStep(3)}
                                >
                                    <span
                                        style={{ textDecoration: 'underline' }}
                                    >
                                        Click here
                                    </span>{' '}
                                    &nbsp;to go to previous step
                                </Button>
                            </Container>
                        );
                    } else {
                        snackbar.error(error.response.data.message);
                    }
                } else if (error.response.data.email) {
                    snackbar.error(error.response.data.email[0]);
                } else {
                    snackbar.error(
                        'An error occurred while creating your account.'
                    );
                }

                setSubmitting(false);
            });
    };

    return (
        <div id="step11">
            {' '}
            <Formik
                initialValues={props.initialValues}
                validationSchema={
                    props.setupWizard
                        ? setupWizardValidationSchema
                        : validationSchema
                }
                onSubmit={(values, { setSubmitting }) => {
                    if (
                        props.initialValues.input_type === 'template' &&
                        reuploaded
                    ) {
                        values['file_name'] = fileName;

                        let data = new FormData();
                        for (var x in values) {
                            data.append(x, values[x]);
                        }
                        data.append('csv_template', file, fileName);

                        if (props.setupWizard) {
                            data.append('setup_wizard', true);
                        }

                        setFileName('');

                        /*Choose appropriate Axios request type */
                        let r;
                        if (props.setupWizard) {
                            r = request;
                        } else {
                            r = axios;
                        }
                        r.post(
                            `${config.API_BASE_URL}/validate-template-data`,
                            data
                        )
                            .then(() => {
                                props.saveValues(values);
                                handleSubmission(
                                    values,
                                    history,
                                    setSubmitting
                                );
                            })
                            .catch((error) => {
                                if (
                                    error.response &&
                                    error.response.data.message
                                ) {
                                    snackbar.error(error.response.data.message);
                                }

                                setSubmitting(false);
                            });
                    } else {
                        props.saveValues(values);
                        handleSubmission(values, history, setSubmitting);
                    }
                }}
            >
                {({ isSubmitting, errors }) => (
                    <Paper className={classes.paper}>
                        <Typography
                            component="div"
                            style={{ textAlign: 'center' }}
                        >
                            <h2>Let's review your submitted information:</h2>
                        </Typography>
                        <Container maxWidth="md">
                            <Form className={classes.form} noValidate>
                                {!props.setupWizard && (
                                    <Grid>
                                        <Accordion
                                            style={{ width: '100%' }}
                                            expanded={personalPanel}
                                            onChange={() =>
                                                handleExpansionPanelChange(
                                                    'personal'
                                                )
                                            }
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                            >
                                                {personalPanel === false ? (
                                                    <Typography>
                                                        Your Personal
                                                        Information
                                                    </Typography>
                                                ) : null}
                                            </AccordionSummary>
                                            <FormPanel>
                                                <FormPanelRow title="Your Personal Information">
                                                    <Field
                                                        component={TextField}
                                                        name="first_name"
                                                        type="text"
                                                        label="First Name"
                                                        fullWidth
                                                        required
                                                        onKeyUp={
                                                            setRequiredField
                                                        }
                                                    />
                                                </FormPanelRow>
                                                <FormPanelRow>
                                                    <Field
                                                        component={TextField}
                                                        name="last_name"
                                                        type="text"
                                                        label="Last Name"
                                                        fullWidth
                                                        required
                                                        onKeyUp={
                                                            setRequiredField
                                                        }
                                                    />
                                                </FormPanelRow>
                                                <FormPanelRow>
                                                    <Field
                                                        component={TextField}
                                                        name="email"
                                                        type="email"
                                                        label="Email"
                                                        fullWidth
                                                        required
                                                    />
                                                </FormPanelRow>
                                            </FormPanel>
                                        </Accordion>

                                        <Accordion
                                            style={{ width: '100%' }}
                                            expanded={passwordPanel}
                                            onChange={() =>
                                                handleExpansionPanelChange(
                                                    'password'
                                                )
                                            }
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                            >
                                                {passwordPanel === false ? (
                                                    <Typography>
                                                        Password
                                                    </Typography>
                                                ) : null}
                                            </AccordionSummary>
                                            <FormPanel>
                                                <FormPanelRow title="Password">
                                                    <Field
                                                        component={TextField}
                                                        type={
                                                            showPassword ===
                                                            true
                                                                ? 'text'
                                                                : 'password'
                                                        }
                                                        label="Password"
                                                        name="password"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        required
                                                        onKeyUp={
                                                            setRequiredField
                                                        }
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={
                                                                            changeShowPassword
                                                                        }
                                                                        size="large"
                                                                    >
                                                                        {showPassword ? (
                                                                            <VisibilityIcon />
                                                                        ) : (
                                                                            <VisibilityOffIcon />
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </FormPanelRow>
                                                <FormPanelRow>
                                                    <Field
                                                        component={TextField}
                                                        type={
                                                            showConfirmPassword ===
                                                            true
                                                                ? 'text'
                                                                : 'password'
                                                        }
                                                        label="Confirm Password"
                                                        name="confirm_password"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        required
                                                        onKeyUp={
                                                            setRequiredField
                                                        }
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={
                                                                            changeShowConfirmPassword
                                                                        }
                                                                        size="large"
                                                                    >
                                                                        {showConfirmPassword ? (
                                                                            <VisibilityIcon />
                                                                        ) : (
                                                                            <VisibilityOffIcon />
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </FormPanelRow>
                                            </FormPanel>
                                        </Accordion>
                                    </Grid>
                                )}

                                <Accordion
                                    style={{ width: '100%' }}
                                    expanded={companyPanel}
                                    onChange={() =>
                                        handleExpansionPanelChange('company')
                                    }
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        {companyPanel === false ? (
                                            <Typography>
                                                Company Information
                                            </Typography>
                                        ) : null}
                                    </AccordionSummary>
                                    <FormPanel>
                                        <FormPanelRow title="Company Information">
                                            <Field
                                                component={TextField}
                                                type="company_name"
                                                label="Company Name"
                                                name="company_name"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                required
                                                onKeyUp={setRequiredField}
                                            />
                                        </FormPanelRow>
                                        <FormPanelRow>
                                            <Field
                                                component={TextField}
                                                type="legal_entity_name"
                                                label="Legal Entity Name"
                                                name="legal_entity_name"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                required
                                                onKeyUp={setRequiredField}
                                            />
                                        </FormPanelRow>
                                        <FormPanelRow>
                                            <Grid item xs={12}>
                                                <Field
                                                    component={
                                                        FormikAutocomplete
                                                    }
                                                    name="registered_country"
                                                    variant="outlined"
                                                    label="Country of Incorporation *"
                                                    fullWidth
                                                    inputProps={{
                                                        id:
                                                            'registered_country',
                                                    }}
                                                    optionKey="name"
                                                    dataKey="id"
                                                    required
                                                    options={registry.countries}
                                                />
                                            </Grid>
                                        </FormPanelRow>
                                        <FormPanelRow>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                numeric={1}
                                                label="Total Authorized Assets"
                                                name="authorized_shares"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                required
                                            />
                                        </FormPanelRow>
                                        <FormPanelRow>
                                            <Field
                                                component={CheckboxWithLabel}
                                                type="checkbox"
                                                name="raised_capital"
                                                Label={{
                                                    label: (
                                                        <div
                                                            className={
                                                                classes.checkbox
                                                            }
                                                        >
                                                            I have raised
                                                            outside capital in
                                                            the past
                                                        </div>
                                                    ),
                                                }}
                                            />
                                        </FormPanelRow>
                                        <FormPanelRow>
                                            <Field
                                                component={CheckboxWithLabel}
                                                type="checkbox"
                                                name="intend_to_raise"
                                                Label={{
                                                    label: (
                                                        <div
                                                            className={
                                                                classes.checkbox
                                                            }
                                                        >
                                                            I intend to raise
                                                            capital in the next
                                                            9 months
                                                        </div>
                                                    ),
                                                }}
                                            />
                                        </FormPanelRow>
                                        <FormPanelRow>
                                            <Grid item xs={12}>
                                                <Field
                                                    component={FormikSelect}
                                                    name="number_of_shareholders"
                                                    variant="outlined"
                                                    label="How many holders do you have today?"
                                                    fullWidth
                                                    inputProps={{
                                                        id: 'country',
                                                    }}
                                                    defaultValue={
                                                        props.initialValues
                                                            .number_of_shareholders
                                                    }
                                                >
                                                    <MenuItem
                                                        key={'<10'}
                                                        value={'<10'}
                                                    >
                                                        {'<10'}
                                                    </MenuItem>
                                                    <MenuItem
                                                        key={'10-25'}
                                                        value={'10-25'}
                                                    >
                                                        {'10-25'}
                                                    </MenuItem>
                                                    <MenuItem
                                                        key={'25+'}
                                                        value={'25+'}
                                                    >
                                                        {'25+'}
                                                    </MenuItem>
                                                </Field>
                                            </Grid>
                                        </FormPanelRow>
                                    </FormPanel>
                                </Accordion>

                                <Typography
                                    component="div"
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    <h3>
                                        You will be able to edit this
                                        information later
                                    </h3>
                                </Typography>
                                <div className={classes.actions}>
                                    {!isSubmitting ? (
                                        <ButtonGroup
                                            aria-label="outlined primary button group"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Button
                                                variant="outlined"
                                                className={
                                                    classes.secondaryButtonLabel
                                                }
                                                color="secondary"
                                                onClick={() =>
                                                    props.previousStep()
                                                }
                                                style={{ margin: 'inherit' }}
                                            >
                                                <ArrowBackIosIcon /> BACK
                                            </Button>
                                            {props.initialValues.input_type ===
                                                'template' && (
                                                <Button
                                                    component="label"
                                                    variant="outlined"
                                                    className={
                                                        classes.secondaryButtonLabel
                                                    }
                                                    color="secondary"
                                                    style={{
                                                        margin: 'auto',
                                                    }}
                                                >
                                                    Re-upload cap table
                                                    <input
                                                        onChange={(e) => {
                                                            uploadFile(
                                                                e.target
                                                                    .files[0]
                                                            );
                                                        }}
                                                        style={{
                                                            display: 'none',
                                                        }}
                                                        type="file"
                                                        name="fileFront"
                                                        accept="*"
                                                    />
                                                </Button>
                                            )}

                                            <Button
                                                type="submit"
                                                variant="outlined"
                                                className={classes.buttonLabel}
                                                color="primary"
                                                disabled={isSubmitting}
                                                style={{
                                                    margin: 'auto',
                                                }}
                                            >
                                                Save and Continue
                                            </Button>
                                        </ButtonGroup>
                                    ) : (
                                        <CircularProgress size={24} />
                                    )}
                                </div>
                                <Grid item xs={12}>
                                    {fileName !== '' &&
                                    fileName !== null &&
                                    fileName !== undefined ? (
                                        <Typography
                                            component="p"
                                            variant="body2"
                                        >
                                            File to upload: <b>{fileName}</b>
                                        </Typography>
                                    ) : null}
                                </Grid>
                            </Form>
                        </Container>
                    </Paper>
                )}
            </Formik>
        </div>
    );
};

export default Review;
