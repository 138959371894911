import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import authStyles from '../authStyles';
import * as registry from '../../../utilities/registry';
import HelpTooltip from '../../../components/HelpTooltip/HelpTooltip';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '../../../components/TextField/TextField';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { buildDropdown } from '../../../utilities/utils';
import FormikAutocomplete from '../../../components/FormikAutocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CLASS_DESCRIPTION_MAXIMUM_LENGTH } from '../../../constants/assets';
const validationSchema = Yup.object({
    number_of_share_classes: Yup.number().required(
        'Number of classes is required.'
    ),
    classes: Yup.array().of(
        Yup.object().shape({
            class_name: Yup.string().required('Class type is required.'),
            class_description: Yup.string().required(
                'Class description is required.'
            ),
            /*equity_main_currency: Yup.string().required(
                'Currency field is required.'
            ),*/
        })
    ),
});

const ClassSelect = ({
    field,
    form,
    values,
    setValues,
    children,
    ...props
}) => {
    const onChangeClass = (e, field, values, setValues) => {
        // update dynamic form
        const classes = [...values.classes];
        const numberOfClasses =
            e.target.value || values.number_of_share_classes;
        const previousNumber = parseInt(field.value || '0');

        if (previousNumber < numberOfClasses) {
            for (let i = previousNumber; i < numberOfClasses; i++) {
                classes.push({ class_name: 'Common', class_description: '' });
                setValues({ ...values, classes });
            }
        } else {
            for (let i = previousNumber; i >= numberOfClasses; i--) {
                classes.splice(i, 1);
                setValues({ ...values, classes });
            }
        }

        // call formik onChange method
        field.onChange(e);
    };

    return (
        <Select
            {...field}
            {...props}
            onChange={(e) => onChangeClass(e, field, values, setValues)}
        >
            {children}
        </Select>
    );
};

const EquityInformationClass = (props) => {
    const classes = authStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    let [refreshForm, setRefreshForm] = useState(0);
    let [defaultCurrency, setDefaultCurrency] = useState(null);
    const nextStep = () => {
        props.nextStep();
    };

    const changeExcludingOption = (e, values, i, setValues) => {
        const classes = [...values.classes];
        classes[i].excluding_option = e.target.checked;

        setValues({ ...values, classes });
    };
    useEffect(() => {
        for (var i = 0; i < registry.countryCurrencies.length; i++) {
            if (
                registry.countryCurrencies[i].id ===
                props.initialValues.registered_country
            ) {
                setDefaultCurrency(registry.countryCurrencies[i].name);
                refreshForm++;
                setRefreshForm(refreshForm);
            }
        }
    }, [defaultCurrency]);

    return (
        <div id="step5">
            <Formik
                key={refreshForm}
                initialValues={props.initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm, setSubmitting }) => {
                    closeSnackbar();
                    props.saveValues(values);
                    if (values['classes'].length === 0) {
                        enqueueSnackbar('You have to add at least one class', {
                            variant: 'error',
                        });
                        // resetForm({ values: '' });
                        refreshForm++;
                        setRefreshForm(refreshForm);
                    } else {

                        let totalAuthorizedAssets = 0;
                        for (var i = 0; i < values['classes'].length; i++) {
                            if(!['SAFE', 'Custom'].includes(values['classes'][i]['class_name'])){
                                totalAuthorizedAssets += parseFloat(
                                values['classes'][i][
                                    'authorized_shares'
                                ].replace(/,/g, '')
                                ); 
                            }
                         
                            values['classes'][i][
                                'equity_main_currency'
                            ] = values['classes'][i]['equity_main_currency']
                                ? values['classes'][i]['equity_main_currency']
                                : defaultCurrency;
                        }
                        console.log(values);
                        if (
                            totalAuthorizedAssets >
                            parseFloat(
                                props.initialValues.authorized_shares.replace(
                                    /,/g,
                                    ''
                                )
                            )
                        ) {
                            enqueueSnackbar(
                                'Sum of authorized assets cannot be higher than total authorized assets',
                                {
                                    variant: 'error',
                                }
                            );
                            setSubmitting(false);
                        } else {
                            nextStep();
                        }
                    }
                }}
            >
                {({ errors, values, touched, setValues }) => (
                    <Paper className={classes.paper}>
                        <Typography
                            component="div"
                            style={{ textAlign: 'center' }}
                        >
                            <h2>Equity Information - Class</h2>
                            <h5>
                                (You will be able to edit this information
                                later)
                            </h5>
                        </Typography>
                        <Container>
                            <Form className={classes.form} noValidate>
                                <InputLabel
                                    style={{
                                        color: '#666',
                                        fontSize: '1rem',
                                        marginBottom: '0.5rem',
                                    }}
                                    htmlFor="number_of_share_classes"
                                >
                                    How many asset classes do you have today?
                                    <HelpTooltip
                                        title={
                                            "This represents the asset class, which typically has different rules and treatments for voting rights, subordination, transfer restrictions as per the company's governance documentation."
                                        }
                                    />
                                </InputLabel>
                                <Grid container item xs={12} md={6}>
                                    <Field
                                        component={ClassSelect}
                                        name="number_of_share_classes"
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{
                                            id: 'number_of_share_classes',
                                        }}
                                        values={values}
                                        setValues={setValues}
                                        defaultValue={1}
                                    >
                                        {buildDropdown(5)}
                                    </Field>
                                </Grid>
                                <br />

                                {values.classes && values.classes.length > 0
                                    ? values.classes.map((shareClass, i) => (
                                          <Grid container spacing={2} key={i}>
                                              <Grid item xs={12} md={12}>
                                                  <Typography>
                                                      <h5>
                                                          Asset class #{i + 1}
                                                      </h5>
                                                  </Typography>
                                              </Grid>
                                              <br />
                                              <Grid item xs={12} md={6}>
                                                  <Field
                                                      component={
                                                          FormikAutocomplete
                                                      }
                                                      name={`classes.${i}.class_name`}
                                                      variant="outlined"
                                                      optionKey="name"
                                                      dataKey="id"
                                                      required
                                                      margin="normal"
                                                      label={
                                                          <div>
                                                              Class
                                                              <HelpTooltip
                                                                  title={
                                                                      "This represents the asset class, which typically has different rules and treatments for voting rights, subordination, transfer restrictions as per the company's governance documentation."
                                                                  }
                                                              />
                                                          </div>
                                                      }
                                                      options={[
                                                          {
                                                              id: 'Common',
                                                              name: 'Common',
                                                          },
                                                          {
                                                              id: 'Ordinary',
                                                              name: 'Ordinary',
                                                          },
                                                          {
                                                              id: 'Preferred',
                                                              name: 'Preferred',
                                                          },
                                                          {
                                                              id: 'SAFE',
                                                              name: 'SAFE',
                                                          },
                                                          {
                                                              id: 'Convertible',
                                                              name:
                                                                  'Convertible',
                                                          },
                                                          {
                                                              id: 'Custom',
                                                              name: 'Custom',
                                                          },
                                                      ]}
                                                  />
                                              </Grid>
                                              <Grid item xs={12} md={6}>
                                                  <Field
                                                      component={TextField}
                                                      type="text"
                                                      name={`classes.${i}.class_description`}
                                                      variant="outlined"
                                                      fullWidth
                                                      margin={'normal'}
                                                      placeholder={
                                                          'Apple Common A'
                                                      }
                                                      InputLabelProps={{
                                                          style: {
                                                              pointerEvents:
                                                                  'auto',
                                                          },
                                                      }}
                                                      inputProps={{
                                                          maxLength: CLASS_DESCRIPTION_MAXIMUM_LENGTH,
                                                      }}
                                                      label={
                                                          <div>
                                                              Class Description
                                                              <HelpTooltip
                                                                  title={
                                                                      'Use 2-4 words to make it easy to know which class this is (eg may want to track different buckets of comment so Applecommonfounder, Applecommonempoptions, Applecommonempshares to differentiate event among all the common shares)'
                                                                  }
                                                              />
                                                          </div>
                                                      }
                                                  />
                                              </Grid>

                                              {shareClass.class_name ===
                                                  'Custom' && (
                                                  <Grid item xs={12} md={6}>
                                                      <Field
                                                          component={TextField}
                                                          type="text"
                                                          name={`classes.${i}.name`}
                                                          variant="outlined"
                                                          margin="normal"
                                                          label={'Class Name'}
                                                          placeholder={
                                                              'Custom Name'
                                                          }
                                                          fullWidth
                                                      />
                                                  </Grid>
                                              )}
                                              <Grid item xs={12} md={6}>
                                                  <Field
                                                      component={
                                                          FormikAutocomplete
                                                      }
                                                      name={`classes.${i}.equity_main_currency`}
                                                      variant="outlined"
                                                      label="Currency"
                                                      margin="normal"
                                                      fullWidth
                                                      optionKey="id"
                                                      dataKey="id"
                                                      required
                                                      value={
                                                          shareClass.equity_main_currency
                                                              ? shareClass.equity_main_currency
                                                              : defaultCurrency
                                                              ? defaultCurrency
                                                              : props
                                                                    .initialValues
                                                                    .currency
                                                      }
                                                      options={
                                                          registry.currencies
                                                      }
                                                  />
                                              </Grid>
                                              {(shareClass.class_name ===
                                                  'Custom' ||
                                                  shareClass.class_name ===
                                                      'SAFE') && (
                                                  <Grid item xs={12} md={6}>
                                                      <Field
                                                          component={TextField}
                                                          type="text"
                                                          numeric={1}
                                                          name={`classes.${i}.amount_invested`}
                                                          variant="outlined"
                                                          margin="normal"
                                                          label={
                                                              'Amount Invested'
                                                          }
                                                          placeholder={'0.00'}
                                                          fullWidth
                                                      />
                                                  </Grid>
                                              )}

                                              {(shareClass.class_name ===
                                                  'Custom' ||
                                                  shareClass.class_name ===
                                                      'SAFE') && (
                                                  <Grid item xs={12} md={6}>
                                                      <Field
                                                          component={TextField}
                                                          type="text"
                                                          numeric={1}
                                                          name={`classes.${i}.valuation`}
                                                          variant="outlined"
                                                          placeholder={'0.00'}
                                                          fullWidth
                                                          margin={'normal'}
                                                          InputLabelProps={{
                                                              style: {
                                                                  pointerEvents:
                                                                      'auto',
                                                              },
                                                          }}
                                                          label={
                                                              <div>
                                                                  Valuation
                                                                  <HelpTooltip
                                                                      title={
                                                                          'Used to calculate an illustrative ownership % for the holders for these SAFE terms.'
                                                                      }
                                                                  />
                                                              </div>
                                                          }
                                                      />
                                                  </Grid>
                                              )}

                                              {shareClass.class_name !==
                                                  'Custom' &&
                                                  shareClass.class_name !==
                                                      'SAFE' && (
                                                      <Grid item xs={12} md={6}>
                                                          <Field
                                                              component={
                                                                  TextField
                                                              }
                                                              type="text"
                                                              numeric={1}
                                                              name={`classes.${i}.class_price`}
                                                              variant="outlined"
                                                              margin="normal"
                                                              label={
                                                                  'Par value per asset'
                                                              }
                                                              placeholder={
                                                                  '0.00'
                                                              }
                                                              //numeric={1}
                                                              fullWidth
                                                          />
                                                      </Grid>
                                                  )}
                                              {shareClass.class_name !==
                                                  'Custom' &&
                                                  shareClass.class_name !==
                                                      'SAFE' && (
                                                      <Grid item xs={12} md={6}>
                                                          <Field
                                                              component={
                                                                  TextField
                                                              }
                                                              type="text"
                                                              numeric={1}
                                                              name={`classes.${i}.authorized_shares`}
                                                              variant="outlined"
                                                              margin="normal"
                                                              label={
                                                                  <div>
                                                                      Authorized
                                                                      Assets
                                                                      <HelpTooltip
                                                                          color="#3E9CD9"
                                                                          title="Authorized assets refer to the total number of assets of stock that a company is legally authorized to issue."
                                                                      />
                                                                  </div>
                                                              }
                                                              placeholder={
                                                                  '0.00'
                                                              }
                                                              InputLabelProps={{
                                                                  style: {
                                                                      pointerEvents:
                                                                          'auto',
                                                                  },
                                                              }}
                                                              //numeric={1}
                                                              fullWidth
                                                          />
                                                      </Grid>
                                                  )}

                                              {shareClass.class_name ===
                                                  'Custom' && (
                                                  <Grid item xs={12} md={6}>
                                                      <FormControlLabel
                                                          label="Exclude this class from the equity calculation (e.g.debt)"
                                                          control={
                                                              <Checkbox
                                                                  onChange={(
                                                                      e
                                                                  ) =>
                                                                      changeExcludingOption(
                                                                          e,
                                                                          values,
                                                                          i,
                                                                          setValues
                                                                      )
                                                                  }
                                                                  checked={
                                                                      shareClass.excluding_option ===
                                                                      true
                                                                          ? shareClass.excluding_option
                                                                          : false
                                                                  }
                                                                  color="primary"
                                                              />
                                                          }
                                                      />
                                                  </Grid>
                                              )}
                                          </Grid>
                                      ))
                                    : null}
                                <div className={classes.actions}>
                                    <ButtonGroup
                                        aria-label="outlined primary button group"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        {' '}
                                        <Button
                                            variant="outlined"
                                            className={
                                                classes.secondaryButtonLabel
                                            }
                                            color="secondary"
                                            onClick={() => props.previousStep()}
                                            style={{ margin: 'inherit' }}
                                        >
                                            <ArrowBackIosIcon /> BACK
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="outlined"
                                            className={classes.buttonLabel}
                                            color="primary"
                                            style={{
                                                margin: 'auto',
                                            }}
                                        >
                                            CONTINUE
                                        </Button>
                                        {props.setupWizard ? null : (
                                            <Button
                                                variant="outlined"
                                                className={
                                                    classes.secondaryButtonLabel
                                                }
                                                color="secondary"
                                                onClick={nextStep}
                                                style={{ margin: 'inherit' }}
                                            >
                                                SKIP
                                                <DoubleArrowIcon />
                                            </Button>
                                        )}
                                    </ButtonGroup>
                                </div>
                            </Form>
                        </Container>
                    </Paper>
                )}
            </Formik>
        </div>
    );
};

export default EquityInformationClass;
