/**
 * Vesting type constants.
 */

export const VESTING_PRO_RATAS = [
    'daily',
    'weekly',
    'monthly',
    'quarterly',
    'biannual',
    'yearly',
    'biennial',
    'quadrennial',
]