import React, { useState } from 'react'

import Humanize from 'humanize-plus';
import TextField from '../TextField/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useController } from "react-hook-form";

const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

const countCommas = (value) => {
  if (value) {
      return value.split(',').length - 1;
  }
  return 0;
};

const Input = (props) => {
  const { field } = useController({
    control: props.control,
    name: props.name
  });

  const [value, setValue] = useState(field.value);

  return (
    <TextField
      ref={field.ref}
      type={props.type || 'text'}
      value={value}
      onChange={(e) => {
        // Numeric field support
        const caret = e.target.selectionStart;
        const element = e.target;
        
        if (props.numeric) {
          // eslint-disable-next-line no-useless-escape
          let stripped = e.target.value.replace(/,/g, '').replace(/[^0-9,\.]/gi, '');
          let num;
          if (stripped === '' || stripped === null) {
            num = '';
          } else {
            num = +e.target.value.replace(/,/g, '');
          }
          if (isNumeric(num)) {
              let ts = Humanize.formatNumber(num, props.precision ? props.precision : 0);
              let a = 0;

              if (countCommas(e.target.value) < countCommas(ts)) {
                  a += 1;
              }

              if (countCommas(e.target.value) > countCommas(ts)) {
                  a -= 1;
              }

              window.requestAnimationFrame(() => {
                  element.selectionStart = caret + a;
                  element.selectionEnd = caret + a;
              });

              field.onChange(num)
              setValue(ts)
          } else {
            field.onChange(num)
            setValue(num)
          }
        } else {
          field.onChange(e.target.value)
          setValue(e.target.value)
        }
      }}
      onBlur={field.onBlur}
      label={props.label}
      disabled={props.disabled}
      placeholder={props.placeholder}
      autoFocus={props.autoFocus}
      fullWidth
      error={props.error !== null && props.error !== undefined}
      helperText={props.error?.message}
      InputProps={{
          startAdornment: props.leftIcon ? (
              <InputAdornment position="start">
              {props.leftIcon}
              </InputAdornment>
          ) : null,
          endAdornment: props.endAdornment ? props.endAdornment : null
      }}
    />
  )
}

export default Input