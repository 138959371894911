import React from 'react';
import PropTypes from 'prop-types';

import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';

import { Field } from 'formik';
import { RadioGroup } from 'formik-mui';

const RadioButtons = (props) => {
    const { label, name, options } = props;

    return (
        <div>
            <FormControl component="fieldset">
                {label && (
                    <FormHelperText component="small">{label}</FormHelperText>
                )}
                <Field
                    component={RadioGroup}
                    aria-label={name}
                    name={name}
                    row={props.row ? true : false}
                    defaultValue={
                        props.defaultValue ? props.defaultValue : null
                    }
                >
                    {options.map((field, id) => (
                        <FormControlLabel
                            key={id}
                            control={<Radio />}
                            label={field.label}
                            value={field.value}
                        />
                    ))}
                </Field>
            </FormControl>
        </div>
    );
};

RadioButtons.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
};

export default RadioButtons;
