import React from 'react';
import Container from '@mui/material/Container';
import DashBox from '../DashBox/DashBox';
import dashGridStyles from '../styles/dashGridStyles';

const DashGrid = (props) => {
    const classes = dashGridStyles();
    const { boxes, columns, rows } = props;

    return (
        <Container
            className={classes.container}
            style={{
                gridTemplateColumns: `repeat(${columns}, 1fr)`,
                gridTemplateRows: `repeat(${rows}, 1fr)`,
            }}
        >
            {boxes.map((box) => (
                <DashBox key={box.id} {...box} />
            ))}
        </Container>
    );
};

export default DashGrid;
