import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as config from './../../../config';
import request from '../../../utilities/request';
import { Formik, Form, Field } from 'formik';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import authStyles from '../authStyles';
import { useSnackbar } from 'notistack';
import { CheckboxWithLabel } from 'formik-mui';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import ButtonGroup from '@mui/material/ButtonGroup';
import * as fundingActions from '../../../store/funding/fundingActions';
import CircularProgress from '@mui/material/CircularProgress';
import fundraiseStyles from '../../../components/styles/fundraiseStyles';
import TextField from '../../../components/TextField/TextField';
import LockedTile from '../../../components/LockedTile';
import {
    allowedFiles,
    allowedFilesMessage,
    validateFileExtension,
} from '../../../utilities/utils';
import { SUBSCRIPTION_ENTERPRISE } from '../../../constants/profile';

let initialValues = {};
const formData = new FormData();
formData.append('document', '');

const DataRoom = (props) => {
    const classes = authStyles();
    const fundraiseClasses = fundraiseStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    let demo;
    try {
        demo = props.profile.demo;
    } catch (e) {
        demo = null;
    }

    const [investorDocuments, setInvestorDocuments] = useState([]);
    const [newDocument, setnewDocument] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    useEffect(props.fundingActions.getFundInvestorDocuments, []);

    useEffect(() => {
        if (
            props.funding.data &&
            props.funding.data.data &&
            props.funding.data.data.length
        ) {
            setInvestorDocuments(props.funding.data.data);
            props.funding.data.data.forEach((item) => {
                initialValues[item.id] =
                    item.show_on_fundraise === 1 ? true : false;
            });
        }
    }, [props.funding.data]);

    const uploadFile = (file) => {
        if (!validateFileExtension(file)) {
            enqueueSnackbar(allowedFilesMessage, {
                variant: 'error',
            });
            setTimeout(function () {
                closeSnackbar();
            }, 3500);
        } else {
            formData.set('document', file, file.name);
            setnewDocument(file.name);
        }
    };

    const uploadDocument = () => {
        setIsUploading(true);
        request
            .post(
                `${config.API_BASE_URL}/fundraise/investor-document`,
                formData
            )
            .then((response) => {
                setIsUploading(false);
                enqueueSnackbar('The new document has been uploaded', {
                    variant: 'success',
                });
                props.fundingActions.getFundInvestorDocuments();
            })
            .catch(() => {
                setIsUploading(false);
                enqueueSnackbar('An error occurred while saving the new logo', {
                    variant: 'error',
                });
            })
            .finally(() => {
                setnewDocument('');
            });
    };

    const updateDocumentShow = (e) => {
        let data = {
            id: parseInt(e.target.name),
            show_on_fundraise: e.target.value === 'false',
        };

        request
            .post(
                `${config.API_BASE_URL}/fundraise/investor-document/edit`,
                data
            )
            .then(() => {
                enqueueSnackbar('The change has been saved', {
                    variant: 'success',
                });
            })
            .catch(() => {
                enqueueSnackbar('An error occurred while saving the changes', {
                    variant: 'error',
                });
            });
    };

    return (
        <LockedTile requiredPlan={SUBSCRIPTION_ENTERPRISE}>
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {}}
            >
                <Form noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Paper
                                elevation={3}
                                className={fundraiseClasses.paper}
                            >
                                <Typography className={fundraiseClasses.title}>
                                    Investor Documents
                                </Typography>

                                <Grid
                                    container
                                    style={{ marginTop: '1rem' }}
                                    spacing={2}
                                >
                                    <Grid item xs={12} sm={3} md={3}>
                                        <TextField
                                            id="standard-basic"
                                            variant="standard"
                                            value={newDocument}
                                            placeholder=""
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={5} md={5}>
                                        <ButtonGroup>
                                            <Button
                                                component="label"
                                                variant="contained"
                                                color="primary"
                                                className={
                                                    fundraiseClasses.browseButton
                                                }
                                            >
                                                Browse
                                                <input
                                                    onChange={(e) => {
                                                        uploadFile(
                                                            e.target.files[0]
                                                        );
                                                    }}
                                                    style={{ display: 'none' }}
                                                    type="file"
                                                    name="new_logo"
                                                    accept={allowedFiles.join(
                                                        ','
                                                    )}
                                                />
                                            </Button>{' '}
                                            <Button
                                                component="label"
                                                variant="contained"
                                                disabled={newDocument === ''}
                                                className={
                                                    fundraiseClasses.uploadButton
                                                }
                                                type="submit"
                                                onClick={() => uploadDocument()}
                                            >
                                                {isUploading ? (
                                                    <CircularProgress
                                                        size={24}
                                                        style={{
                                                            color: '#fff',
                                                        }}
                                                        className={
                                                            classes.buttonProgress
                                                        }
                                                    />
                                                ) : (
                                                    'Upload'
                                                )}
                                            </Button>
                                        </ButtonGroup>
                                    </Grid>
                                </Grid>
                                {demo ? (
                                    <Grid style={{ marginTop: '1rem' }}>
                                        {props.funding.data &&
                                            investorDocuments.map(
                                                (investor_document, i) => (
                                                    <Grid container key={i}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={9}
                                                            md={9}
                                                            className={
                                                                fundraiseClasses.documentTitle
                                                            }
                                                        >
                                                            {
                                                                investor_document.title
                                                            }
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={3}
                                                            md={3}
                                                        >
                                                            <Field
                                                                component={
                                                                    CheckboxWithLabel
                                                                }
                                                                type="checkbox"
                                                                name={
                                                                    investor_document.id
                                                                }
                                                                Label={{
                                                                    label: (
                                                                        <div
                                                                            className={
                                                                                fundraiseClasses.checkboxDescription
                                                                            }
                                                                        >
                                                                            Show
                                                                            on
                                                                            Fundraising
                                                                            page
                                                                        </div>
                                                                    ),
                                                                }}
                                                                onClick={(e) =>
                                                                    updateDocumentShow(
                                                                        e
                                                                    )
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )
                                            )}
                                    </Grid>
                                ) : (
                                    <Grid
                                        className={fundraiseClasses.gridNoData}
                                    >
                                        No data
                                    </Grid>
                                )}
                            </Paper>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </LockedTile>
    );
};

const mapStateToProps = ({ profile, funding }) => {
    return {
        profile: profile,
        funding: funding,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fundingActions: bindActionCreators(fundingActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataRoom);
