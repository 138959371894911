import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
} from '@mui/material';

import { snackbar } from '../../../../utilities/snackbarUtils';
import SubmitButton from '../../../../components/Form/SubmitButton';
import Input from '../../../../components/Form/Input';
import createHolderSubaccount from '../../../../api/blockchain/createHolderSubaccount';
import { parseError } from '../../../../utilities/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';

const CreateSubaccountDialog = ({ holder, handleClose, open }) => {

    const schema = yup.object({
        account_name: yup
            .string()
            .required('Please enter the name of the account.'),
    });

    const defaultValues = {
        account_name: '',
    }

    const { handleSubmit, control, formState: { isSubmitting, errors }, } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    const onSubmit = (data) => {
        return createHolderSubaccount(holder.id, data.account_name).then(response => {
            snackbar.success("New subaccount successfully created");
            const subaccount = response.data;
            holder.subaccounts.push(subaccount);
            handleClose();
        }).catch((error) => {
            snackbar.error(parseError(error));
        });
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xl">
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <div style={{ display: 'flex', color: 'gray' }}>
                        <Typography variant='body2'>
                            Account name
                        </Typography>
                    </div>
                    <br />
                    <Input
                        autoFocus
                        name="account_name"
                        label="Account name"
                        type="text"
                        fullWidth
                        error={errors?.account_name}
                        control={control}
                        variant="outlined"
                        placeholder=""
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={isSubmitting} onClick={handleClose}>Close</Button>
                    <SubmitButton 
                        text="Create"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                    />
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default CreateSubaccountDialog;