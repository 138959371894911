import makeStyles from '@mui/styles/makeStyles';

const transferStyles = makeStyles((theme) => ({
    greyTitle: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '22px',
        color: '#7D8696',
    },
    blackTitle: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '22px',
        display: 'flex',
        alignItems: 'center',
        color: '#333333',
    },
    blueContent: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '22px',
        display: 'flex',
        alignItems: 'center',
        color: '#3E9CD9',
    },

    subtitle: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '22px',
        display: 'flex',
        alignItems: 'center',
        color: '#333333',
    },
    hr: {
        border: '1px solid #CBCFD4',
    },
    amount: {
        fontFamily: 'Saira',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '26px',
        lineHeight: '36px',
        color: '#3E9CD9',
        textAlign: 'end',
    },
    historyDetailsTable: {
        padding: '0.8rem',
    },
    reasonField: {
        marginTop: '1rem',
    },
    buttonAlignment: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '1rem',
    },
    red: {
        color: '#FF0000',
    },
    green: {
        color: '#008000',
    },
    grey: {
        color: '#808080',
    },
}));

export default transferStyles;
