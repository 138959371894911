import { Typography } from '@mui/material'
import React from 'react'

const NoData = ({ show, text, sx }) => {
    return (
        show ?
        <Typography variant='body2' as='p' sx={{ textAlign: 'center', ...sx }}>
            {text}
        </Typography> : null
    )
}

export default NoData