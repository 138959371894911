const baseTableStyles = () => ({
    componentRoot: {
        width: '100%',
    },
    loadingContainer: {
        position: 'absolute',
        top: '0%',
        left: '0%',
        height: '100%',
        width: '100%',
        backgroundColor: '#FFF',
        borderRadius: '4px',
        zIndex: 11,
        opacity: '0.9',
    },
    loadingContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: 11,
        marginTop: -12,
        marginLeft: -12,
    },
    toolbarRoot: {
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tabsRoot: {
        minHeight: '24px',
        backgroundColor: '#F1F1F1',
    },
    tabsIndicator: {
        display: 'none',
    },
    tabRoot: {
        minHeight: 'auto',
        marginTop: '8px',
    },
    tabWrapper: {
        margin: '0 4px',
        borderRadius: '4px 4px 0 0',
        backgroundColor: '#E1E1E1',
    },
    tabLabelContainer: {
        padding: '6px 12px',
        border: 'none',
        margin: '0',
    },
    tabSelected: {
        backgroundColor: '#FFF',
    },
    tableContainer: {
        position: 'relative',
    },
    tableContainerLoading: {
        minHeight: '15rem',
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    tableRoot: {
        minWidth: '500px',
        borderCollapse: 'separate',
    },
    tableHeadCellRoot: {
        padding: '12px 8px',
        borderTop: '1px solid #E0E0E0',
        borderBottom: '2px solid #E0E0E0',
        backgroundColor: '#FFF',
        '&:first-child': {
            paddingLeft: '24px',
        },
        '&:last-child': {
            paddingRight: '24px',
            borderRight: 'none',
        },
    },
    tableCellRoot: {
        padding: '12px 8px',
        backgroundColor: '#FFF',
        '&:first-child': {
            paddingLeft: '24px',
        },
        '&:last-child': {
            paddingRight: '24px',
            borderRight: 'none',
        },
    },
    tableFooterCellRoot: {
        padding: '12px 8px',
        borderTop: '1px solid #E0E0E0',
        borderBottom: '1px solid #E0E0E0',
        fontWeight: '500',
        fontSize: '0.8125rem',
        color: '#000',
        backgroundColor: '#FAFAFA',
        '&:first-child': {
            paddingLeft: '24px',
        },
        '&:last-child': {
            paddingRight: '24px',
            borderRight: 'none',
        },
    },
    tableSortLabel: {
        maxWidth: '100%',
        '& > div': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    tablePaginationToolbar: {
        paddingTop: '6px',
        paddingBottom: '6px',
        paddingRight: '6px',
    },
    tableFixedCell: {
        position: 'sticky',
        zIndex: 10,
    },
    tableFixedCellWebkit: {
        position: '-webkit-sticky',
    },
    tableFitCell: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    tableDividedCell: {
        boxShadow:
            'inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC',
    },
    searchInput: {
        paddingRight: '0',
        fontSize: '0.9rem',
    },
    exportButton: {
        display: 'inline-block',
        marginLeft: '0.5rem',
        color: 'inherit',
        textDecoration: 'none',
    },
    bodyMessageContainer: {
        height: '8rem',
        borderBottom: '2px solid #E0E0E0',
    },
    bodyMessageText: {
        position: 'absolute',
        left: '0',
        right: '0',
        paddingTop: '3rem',
        textAlign: 'center',
        fontSize: '1.3rem',
        color: '#777',
    },
});
export default baseTableStyles;
