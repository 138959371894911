import * as transferTypes from './transferTypes';

const initialState = {
    summary: {
        data: null,
        loading: true,
    },
    data: null,
    loading: true,
};

const transferReducer = (state = initialState, action) => {
    switch (action.type) {
        case transferTypes.GET_TRANSFERS_SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case transferTypes.SET_TRANSFERS_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case transferTypes.GET_SUMMARY_SUCCESS:
            return {
                ...state,
                summary: {
                    ...state.summary,
                    data: action.payload,
                },
            };
        case transferTypes.SET_SUMMARY_LOADING:
            return {
                ...state,
                summary: {
                    ...state.summary,
                    loading: action.payload,
                },
            };

        default:
            return state;
    }
};

export default transferReducer;
