import * as listingDetailTypes from './listingDetailTypes';

const initialState = {
    data: null,
    loading: true,
};

const listingDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case listingDetailTypes.GET_LISTING_DETAILS_SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case listingDetailTypes.SET_LISTING_DETAILS_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        default:
            return state;
    }
};

export default listingDetailReducer;
