import request from '../../utilities/request';
import * as config from '../../config';
import * as subscriptionAgreementTypes from './subscriptionAgreementTypes';

export const getSubscriptionAgreementsSuccess = (data) => {
    return {
        type: subscriptionAgreementTypes.GET_SUBSCRIPTION_AGREEMENTS_SUCCESS,
        payload: data,
    };
};

export const setSubscriptionAgreementsLoading = (loading) => {
    return {
        type: subscriptionAgreementTypes.SET_SUBSCRIPTION_AGREEMENTS_LOADING,
        payload: loading,
    };
};

export const getSubscriptionAgreements = () => {
    return (dispatch) => {
        dispatch(setSubscriptionAgreementsLoading(true));
        request
            .get(`${config.API_BASE_URL}/subscription-agreements`)
            .then((res) => {
                dispatch(getSubscriptionAgreementsSuccess(res));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setSubscriptionAgreementsLoading(false));
            });
    };
};
