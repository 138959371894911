import * as investorDocumentTypes from './investorDocumentTypes';

const initialState = {
    data: null,
    loading: true,
};

const investorDocumentReducer = (state = initialState, action) => {
    switch (action.type) {
        case investorDocumentTypes.GET_INVESTOR_DOCUMENTS_SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case investorDocumentTypes.SET_INVESTOR_DOCUMENTS_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        default:
            return state;
    }
};

export default investorDocumentReducer;
