import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

import registerHolder from '../../../../api/blockchain/registerHolder';
import registerAllHolders from '../../../../api/blockchain/registerAllHolders';

import { snackbar } from '../../../../utilities/snackbarUtils'
import { errorParser } from '../../../../components/LiquidForm/utils/errorParser';
import SubmitButton from "../../../../components/Form/SubmitButton";

const RegisterDialog = ({ user, open, handleClose }) => {
    const [loading, setLoading] = React.useState(false);

    // Register a single holder
    const registerOne = () => {
        setLoading(true)
        registerHolder(user?.id).then(response => {
            snackbar.success(response.data.message);
            handleClose()
        }).catch((error) => {
            snackbar.error(errorParser(error));
        }).finally(() => {
            setLoading(false)
        })
    }

    // Register all holders in the cap table
    const registerAll = () => {
        setLoading(true)
        registerAllHolders().then(response => {
            snackbar.success(response.data.message);
            handleClose()
        }).catch((error) => {
            snackbar.error(errorParser(error));
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                {
                    user ?
                        'Register a holder' :
                        'Register all holders'
                }
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This action will create a wallet for
                    {
                        user ?
                            <b> the holder </b> :
                            <> <b>all</b> of your holders </>
                    }
                    to receive their assets.
                    <br />
                    <br />
                    This may take a few moments but we will notify you by email once the process has completed.
                    <br />
                    <br />
                    Alternatively, if the holder already has a <b>Green Wallet</b>, please use the <b>Import</b> feature.
                    <br />
                    <br />
                    Do you want to proceed?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <SubmitButton
                    text={user ? 'Register' : 'Register all'}
                    onClick={user ? registerOne : registerAll}
                    disabled={loading}
                    loading={loading}
                />
            </DialogActions>
        </Dialog>
    )
}

export default RegisterDialog