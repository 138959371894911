import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import {
    Switch,
    Route,
    useRouteMatch,
    NavLink,
    useLocation,
} from 'react-router-dom';

import TabPanel from '../../components/TabPanel';
import SubMenu from '../../components/SubMenu';

import AdminPreferences from '../../pages/admin/Dashboard/AdminPreferences';
import TransferAgentIssuers from '../../pages/admin/TransferAgents/TransferAgentIssuers';
import TAPartners from '../../pages/transferAgent/partnerIssuers/TAPartners';
import Reports from '../../pages/transferAgent/partnerIssuers/Reports';
import Transfers from '../../pages/transferAgent/partnerIssuers/Transfers';
import Chat from '../../pages/user/Messaging/Chat';
import ManageAccounts from '../../pages/transferAgent/ManageAccounts';
function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const TransferAgentUserRoutes = (props) => {
    let { path } = useRouteMatch();
    const location = useLocation();

    const getActiveTab = () => {
        switch (location.pathname) {
            case '/transfer-agent/issuers':
            case '/transfer-agent':
                return 0;

            case '/transfer-agent/ta-partners':
                return 1;
            case '/transfer-agent/reports':
                return 2;
            case '/transfer-agent/transfers':
                return 3;
            case '/transfer-agent/preferences':
                return 4;
            case '/transfer-agent/messages':
                return 5;
            case '/transfer-agent/manage-accounts':
                return 6;
            default:
                return 0;
        }
    };

    const [value, setValue] = useState(getActiveTab());
    return (
        <div>
            <SubMenu value={value} onChange={(e, v) => setValue(v)}>
                <Tab
                    label="Issuers"
                    {...a11yProps(0)}
                    to={`${path}`}
                    component={NavLink}
                />

                <Tab
                    label="Requests"
                    {...a11yProps(1)}
                    to={`${path}/ta-partners`}
                    component={NavLink}
                />
                <Tab
                    label="Reports"
                    {...a11yProps(2)}
                    to={`${path}/reports`}
                    component={NavLink}
                />
                <Tab
                    label="Transfers"
                    {...a11yProps(3)}
                    to={`${path}/transfers`}
                    component={NavLink}
                />
                <Tab
                    label="Preferences"
                    {...a11yProps(4)}
                    to={`${path}/preferences`}
                    component={NavLink}
                />
                <Tab
                    label="Communication"
                    {...a11yProps(5)}
                    to={`${path}/messages`}
                    component={NavLink}
                />
            </SubMenu>
            <TabPanel>
                <Switch>
                    <Route
                        exact
                        path={`${path}`}
                        component={TransferAgentIssuers}
                    />

                    <Route
                        exact
                        path={`${path}/ta-partners`}
                        component={TAPartners}
                    />
                    <Route exact path={`${path}/reports`} component={Reports} />
                    <Route
                        exact
                        path={`${path}/transfers`}
                        component={Transfers}
                    />

                    <Route
                        exact
                        path={`${path}/preferences`}
                        component={AdminPreferences}
                    />
                    <Route exact path={`${path}/messages`} component={Chat} />
                    <Route
                        exact
                        path={`${path}/manage-accounts`}
                        component={ManageAccounts}
                    />
                </Switch>
            </TabPanel>
        </div>
    );
};

export default TransferAgentUserRoutes;
