import request from '../../utilities/request';
import * as config from '../../config';
import * as profileTypes from './profileTypes';
import { resetSession } from '../../utilities/auth';

export const getProfileSuccess = (profile) => {
    return {
        type: profileTypes.GET_PROFILE_SUCCESS,
        payload: profile,
    };
};

export const getProfile = () => {
    return (dispatch) => {
        request
            .get(`${config.API_BASE_URL}/profile`)
            .then((response) => {
                const profile = response.data;
                dispatch(getProfileSuccess(profile));
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    resetSession()
                    clearProfile()
                }
            });
    };
};

export const clearProfileSuccess = () => {
    return {
        type: profileTypes.CLEAR_PROFILE_SUCCESS,
    };
};

export const clearProfile = () => {
    return (dispatch) => {
        dispatch(clearProfileSuccess());
    };
};

// Preferred tabs
export const updatePreferencesSuccess = (preferences) => {
    return {
        type: profileTypes.UPDATE_PREFERENCES_SUCCESS,
        payload: preferences
    }
}

export const updatePreferences = (preferences, setLoading, snackbar) => {
    return (dispatch) => {
        setLoading(true)
        request
            .patch(`${config.API_BASE_URL}/preferences`, { preferred_tabs: preferences })
            .then((res) => {
                snackbar(res.data.message, {
                    variant: 'success'
                })
                dispatch(updatePreferencesSuccess(preferences));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false)
            });
    }
}

// Update password
export const updatePassword = (body, setLoading, setSubmitting, resetForm, snackbar) => {
    return (dispatch) => {
        setLoading(true)
        request
            .patch(`${config.API_BASE_URL}/password`, body)
            .then((res) => {
                resetForm({
                    old_password: '',
                    password: '',
                    confirm_password: ''
                })
                snackbar(res.data.message, {
                    variant: 'success'
                })
            })
            .catch((error) => {
                snackbar(error.response.data.message, {
                    variant: 'error'
                })
            })
            .finally(() => {
                setLoading(false)
                setSubmitting(false)
            });
    }
}

// Preferred cap table columns
export const updatePreferredCapTableColumnsSuccess = (preferred_cap_table_columns) => {
    return {
        type: profileTypes.UPDATE_PREFERRED_CAP_TABLE_COLUMNS_SUCCESS,
        payload: preferred_cap_table_columns
    }
}

export const updatePreferredTableColumns = (preferred_columns, table_to_update = 'preferred_cap_table_columns') => {
    return (dispatch) => {
        request
            .patch(`${config.API_BASE_URL}/preferred_table_columns`, { preferred_columns: preferred_columns, table_to_update: table_to_update })
            .then((res) => {
                if(table_to_update === 'preferred_cap_table_columns'){
                    dispatch(updatePreferredCapTableColumnsSuccess(preferred_columns));
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
}