import React, { useState } from 'react'
import { CSVLink } from 'react-csv';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import moment from 'moment';
import Humanize from 'humanize-plus';

import HelpTooltip from './HelpTooltip/HelpTooltip';
import * as config from './../config';
import request from '../utilities/request';

const DownloadCapTable = (props) => {
    const [csvData, setCsvData] = useState([]);
    const [csvFileName, setCsvFilename] = useState('cap-table.csv');
    const csvLink = React.useRef();

    const downloadCapTable = () => {
        request
            .get(`${config.API_BASE_URL}/cap-table/download`)
            .then((response) => {
                for (var i = 0; i < response.data.length; i++) {
                    response.data[i]['Fully diluted shares'] = Humanize.formatNumber(response.data[i]['Fully diluted shares']);
                    response.data[i]['Vesting shares'] = Humanize.formatNumber(response.data[i]['Vesting shares']);
                }
                setCsvData(response.data);

                setCsvFilename(
                    'cap-table-' +
                        moment().format('DD-MM-YYYY hh:mm:ss') +
                        '.xls'
                );
                csvLink.current.link.click();
            })
            .catch((error) => {})
            .finally(() => {});
    };

    return (
        <span className="flex-right">
            <IconButton
                onClick={downloadCapTable}
                style={{
                    color: '#3E9CD9',
                    opacity: '50%',
                    float: 'right',
                }}
                size="large">
                <GetAppIcon />{' '}
                <HelpTooltip
                    title={'Download your cap table as an Excel file.'}
                />
            </IconButton>
            <CSVLink
                data={csvData}
                filename={csvFileName}
                className="hidden"
                ref={csvLink}
                target="_blank"
            />
        </span>
    );
}

export default DownloadCapTable
