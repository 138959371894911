export const LIQUID_NETWORK_GET_GREEN_WALLETS_SUCCESS =
    'LIQUID_NETWORK_GET_GREEN_WALLETS_SUCCESS';
export const LIQUID_NETWORK_GET_ASSETS_SUCCESS =
    'LIQUID_NETWORK_GET_ASSETS_SUCCESS';
export const LIQUID_NETWORK_GET_WALLETS_SUCCESS =
    'LIQUID_NETWORK_GET_WALLETS_SUCCESS';
export const LIQUID_NETWORK_GET_MANAGERS_SUCCESS =
    'LIQUID_NETWORK_GET_MANAGERS_SUCCESS';
export const LIQUID_NETWORK_SET_LOADING = 'LIQUID_NETWORK_SET_LOADING';
export const LIQUID_NETWORK_GET_CATEGORIES_SUCCESS =
    'LIQUID_NETWORK_GET_CATEGORIES_SUCCESS';
