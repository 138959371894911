import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';

import TabPanel from '../../components/TabPanel';
import SubMenu from '../../components/SubMenu';
import Overview from '../../pages/issuer/List/Overview';
import DisclosureRequirements from '../../pages/issuer/List/DisclosureRequirements';
import DocumentsTemplates from '../../pages/issuer/List/DocumentsTemplates';
import VerifiedPartners from '../../pages/issuer/List/VerifiedPartners';
import ListingDetails from '../../pages/issuer/List/ListingDetails';
import SponsorAdvisorUpdate from '../../pages/issuer/List/SponsorAdvisorUpdate';

import VerticalContainer from '../../components/VerticalContainer';
import { LIST_ROUTES, LIST_ROUTES_DEMO } from './routeList';

const ListRoutes = (props) => {
    let { path } = useRouteMatch();
    const location = useLocation();

    //    let listing;
    let demo;
    let vEmail;
    try {
        demo = props.profile.demo;
        vEmail = localStorage
            .getItem('user-email')
            .includes('veronica@curzioresearch.com');
        //  listing = props.profile.scope === 'list';
    } catch (e) {
        demo = null;
        vEmail = null;
        //listing = null;
    }

    const getActiveTab = () => {
        let index;
        if (demo || vEmail) {
            index = LIST_ROUTES_DEMO.indexOf(location.pathname);
        } else {
            index = LIST_ROUTES.indexOf(location.pathname);
        }

        return index >= 0 ? index : 0;
    };

    const [value, setValue] = useState(getActiveTab());

    const tabs =
        demo || vEmail
            ? [
                  {
                      label: 'Overview',
                      path: `${path}`,
                      display: true,
                  },
                  {
                      label: 'Disclosure Requirements',
                      path: `${path}/disclosure-requirements`,
                      display: true,
                  },
                  {
                      label: 'Listing Advisor Update',
                      path: `${path}/sponsor-advisor-updates`,
                      display: true,
                  },
                  {
                      label: 'Documents/Templates',
                      path: `${path}/documents-templates`,
                      display: true,
                  },
                  {
                      label: 'Listing Details',
                      path: `${path}/listing-details`,
                      display: true,
                  },
                  {
                      label: 'Verified Partners',
                      path: `${path}/verified-partners`,
                      display: true,
                  },
              ]
            : [
                  {
                      label: 'Overview',
                      path: `${path}`,
                      display: true,
                  },
                  {
                      label: 'Disclosure Requirements',
                      path: `${path}/disclosure-requirements`,
                      display: true,
                  },
                  {
                      label: 'Documents/Templates',
                      path: `${path}/documents-templates`,
                      display: true,
                  },
                  {
                      label: 'Listing Details',
                      path: `${path}/listing-details`,
                      display: true,
                  },
                  {
                      label: 'Verified Partners',
                      path: `${path}/verified-partners`,
                      display: true,
                  },
              ];

    return (
        <VerticalContainer>
            <SubMenu
                orientation="vertical"
                value={value}
                onChange={(e, v) => setValue(v)}
                tabs={tabs}
                title="List"
            />
            <TabPanel>
                <Switch>
                    <Route exact path={`${path}/`} component={Overview} />
                    <Route
                        exact
                        path={`${path}/disclosure-requirements`}
                        component={DisclosureRequirements}
                    />

                    <Route
                        exact
                        path={`${path}/sponsor-advisor-updates`}
                        component={SponsorAdvisorUpdate}
                    />

                    <Route
                        exact
                        path={`${path}/documents-templates`}
                        component={DocumentsTemplates}
                    />
                    <Route
                        exact
                        path={`${path}/listing-details`}
                        component={ListingDetails}
                    />
                    <Route
                        exact
                        path={`${path}/verified-partners`}
                        component={VerifiedPartners}
                    />
                </Switch>
            </TabPanel>
        </VerticalContainer>
    );
};

const mapStateToProps = ({ profile }) => {
    return {
        profile: profile,
    };
};

export default connect(mapStateToProps)(ListRoutes);
