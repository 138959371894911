import React from 'react'

import { Alert, Button, Grid, Stack, Typography } from '@mui/material'

import { useHistory } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CategoryIcon from '@mui/icons-material/Category';
import ReorderIcon from '@mui/icons-material/Reorder';
import PublishIcon from '@mui/icons-material/Publish';

import useHolders from '../../../api/blockchain/useHolders';

import BCTable from './Utils/BCTable';
import RegisterDialog from './Shareholders/RegisterDialog';
import AccountInfoDialog from './Shareholders/AccountInfoDialog';
import HolderCategoriesDialog from './Shareholders/HolderCategoriesDialog';
import HolderTransactionsDialog from './Shareholders/HolderTransactionsDialog';
import DataLoading from './Utils/DataLoading';
import NoData from './Utils/NoData';
import DataError from './Utils/DataError';
import HolderBurnTransactionsDialog from './Shareholders/HolderBurnTransactionsDialog';
import ImportGreenWalletDialog from './Shareholders/ImportGreenWalletDialog';

const Shareholders = () => {
    
    const [openRegisterAll, setOpenRegisterAll] = React.useState(false);
    const [openAccountInfo, setOpenAccountInfo] = React.useState(false);
    const [openImportAccount, setOpenImportAccount] = React.useState(false);
    const [openCategories, setOpenCategories] = React.useState(false);
    const [openTransactions, setOpenTransactions] = React.useState(false);
    const [openBurnTransactions, setOpenBurnTransactions] = React.useState(false);

    const [activeUser, setActiveUser] = React.useState(null);

    const { holders, holdersLoading, holdersError, updateHolders } = useHolders()
    const history = useHistory()

    const handleOpenRegisterAll = (user) => {
        setOpenRegisterAll(true);
        setActiveUser(user)
    };

    const handleCloseRegisterAll = () => {
        setOpenRegisterAll(false);
        setActiveUser(null)
    };

    const handleOpenAccountInfo = (user) => {
        setOpenAccountInfo(true);
        setActiveUser(user)
    };

    const handleCloseAccountInfo = () => {
        setOpenAccountInfo(false);
        setActiveUser(null)
    };

    const handleOpenImportAccount = (user) => {
        setOpenImportAccount(true);
        setActiveUser(user)
    };

    const handleCloseImportAccount = (refresh) => {

        if (refresh) {
            updateHolders();
        }
        
        setOpenImportAccount(false);
        setActiveUser(null)
    };

    const handleOpenCategories = (row) => {
        setOpenCategories(true);
        setActiveUser(row)
    };

    const handleCloseCategories = () => {
        setOpenCategories(false);
        setActiveUser(null)
    };

    const handleOpenTransactions = (user) => {
        setOpenTransactions(true);
        setActiveUser(user)
    };
    const handleOpenBurnTransactions = (user) => {
        setOpenBurnTransactions(true);
        setActiveUser(user)
    };

    const handleCloseTransactions = () => {
        setOpenTransactions(false);
        setActiveUser(null)
    };
    const handleCloseBurnTransactions = () => {
        setOpenBurnTransactions(false);
        setActiveUser(null)
    };

    return (
        <>
            <Typography variant="h6">
                My holders
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent='space-between' gap={0} sx={{ color: 'gray' }}>
                <div style={{ display: 'flex' }}>
                    <Typography variant='body2'>
                        Manage your holder wallets
                    </Typography>
                </div>
                <Button variant="contained" startIcon={<AddIcon />} sx={{ float: 'right' }} onClick={() => handleOpenRegisterAll()}>
                    Register all
                </Button>
            </Stack>
            <Alert variant='outlined' severity="info" sx={{ marginTop: '10px' }}>
                Holders are taken from your cap table.&nbsp;
                <span
                    style={{ textDecoration: 'none', color: '#3E9CD9', cursor: 'pointer' }}
                    onClick={() => history.push('/issuer/issue/cap-table/holder-info')}
                >
                    Click here to create new holders.
                </span>
            </Alert>
            <br />
            {/* Holder data loading */}
            <DataLoading
                loading={holdersLoading}
                text='Loading your holders...'
            />

            {
                (!holdersLoading && holders) &&
                <>
                    <BCTable
                        columns={[
                            { name: 'holder', label: 'Holder', extraInfoColumn: 'type', bold: true },
                            { name: 'created_at', label: 'Created on', type: 'date', format: 'MMM DD, YYYY' },
                            {
                                name: 'registered_at', label: 'Registered on', type: 'date', format: 'MMM DD, YYYY',
                                tooltip: 'Registered holders have their wallets created and registered.'
                            },
                            {
                                name: 'actions', label: 'Actions', type: 'actions', render: (holder) => (<>
                                    {
                                        holder.has_green_wallet ? <Grid container columnSpacing={{ xs: 1 }} spacing={{ xs: 1 }}>
                                            <Grid item xs="auto">
                                                <Button variant="contained" size="small" startIcon={<ReorderIcon />}
                                                    onClick={() => handleOpenAccountInfo(holder)}
                                                >
                                                    Details
                                                </Button>
                                            </Grid>
                                            <Grid item xs="auto">
                                                <Button variant="contained" color="secondary" size="small" startIcon={<CategoryIcon />}
                                                    sx={{ marginRight: '10px;' }}
                                                    onClick={() => handleOpenCategories(holder)}
                                                >
                                                    Whitelists
                                                </Button>
                                            </Grid>
                                            <Grid item xs="auto">
                                                <Button variant="outlined" size="small" startIcon={<ReceiptLongIcon />}
                                                    sx={{ marginRight: '10px;' }}
                                                    onClick={() => handleOpenTransactions(holder)}
                                                >
                                                    Transactions
                                                </Button>
                                            </Grid>
                                            <Grid item xs="auto">
                                                <Button variant="outlined" size="small" startIcon={<ReceiptLongIcon />}
                                                    onClick={() => handleOpenBurnTransactions(holder)}
                                                >
                                                    Retirements
                                                </Button>
                                            </Grid>
                                        </Grid> : <>
                                            <Button
                                                variant="contained" size="small" startIcon={<AddIcon />} sx={{ marginRight: '10px;' }}
                                                onClick={() => handleOpenRegisterAll(holder)}
                                            >
                                                Register
                                            </Button>
                                            <Button variant="outlined" size="small" startIcon={<PublishIcon />}
                                                onClick={() => handleOpenImportAccount(holder)}
                                            >
                                                Import
                                            </Button>
                                        </>
                                    }
                                </>)
                            }
                        ]}
                        data={holders}
                    />
                </>
            }

            {/* No AMP holders */}
            <NoData
                show={!holdersLoading && !holdersError && holders.length === 0}
                text='You have no holders.'
            />

            {/* AMP holders error */}
            <DataError
                show={holdersError && !holders}
                text='Could not load holders due to error. Please try again.'
            />

            {
                openRegisterAll &&
                <RegisterDialog open={openRegisterAll} handleClose={handleCloseRegisterAll} user={activeUser} />
            }
            {
                openAccountInfo &&
                <AccountInfoDialog open={openAccountInfo} handleClose={handleCloseAccountInfo} user={activeUser} />
            }
            {
                openImportAccount &&
                <ImportGreenWalletDialog open={openImportAccount} handleClose={handleCloseImportAccount} user={activeUser} />
            }
            {
                openCategories &&
                <HolderCategoriesDialog open={openCategories} handleClose={handleCloseCategories} user={activeUser} />
            }
            {
                openTransactions &&
                <HolderTransactionsDialog open={openTransactions} handleClose={handleCloseTransactions} user={activeUser} />
            }
            {
                openBurnTransactions &&
                <HolderBurnTransactionsDialog open={openBurnTransactions} handleClose={handleCloseBurnTransactions} user={activeUser} />
            }
        </>
    )
}

export default Shareholders