import React, { useState } from 'react';
import { connect } from 'react-redux';
import PersonalInformation from '../../user/Signup/PersonalInformation';
import Password from '../../user/Signup/Password';
import SubscriptionType from '../../user/Signup/SubscriptionType';
import CompanyInformation from '../../user/Signup/CompanyInformation';
import EquityInformationClass from '../../user/Signup/EquityInformationClass';
import EquityInformationShare from '../../user/Signup/EquityInformationShare';
import EquityInformationCategory from '../../user/Signup/EquityInformationCategory';
import CapitalizationTable from '../../user/Signup/CapitalizationTable';
import CapitalizationTableOptions from '../../user/Signup/CapitalizationTableOptions';
import UploadDocuments from '../../user/Signup/UploadDocuments';
import Review from '../../user/Signup/Review';
import Complete from '../../user/Signup/Complete';

let initialValues = {
    account_type: 'issuer',
    password: '',
    confirm_password: '',
    human: false,
    subscription_type: '',
    company_name: '',
    legal_entity_name: '',
    registered_country: '',
    raised_capital: false,
    intend_to_raise: false,
    number_of_shareholders: '',
    total_number_of_fully_diluted_shares: '',
    cap_table_input_type: '',
    //citizenship: '',
    input_type: '',
    number_of_share_classes: '1',
    classes: [
        {
            class_name: 'Common',
            class_description: '',
            excluding_option: false,
            name: '',
            equity_main_currency: '',
            amount_invested: '',
            valuation: '',
            class_price: '',
            authorized_shares: '',
        },
    ],
    vesting_equities: 'no',
    option_plan: 'no',
    track_different_categories: 'no',
    categories: [],
    entity_type: 'individual',
    shareholders: [],
    excluding_option: false,
    investors: [],
    exercise_price: '',
    option_expiration_term: '',
    additional_comment: '',
    email: '',
    class_categories_number: [],
    captable_dashboard: true,
};

const formData = new FormData();
formData.append('csv_template', '');
formData.append('articles_of_incorporation', '');
formData.append('shareholders_agreement', '');
formData.append('amendments ', '');
formData.append('additional_documents', '');
formData.append('new_documents', '');

const SetupWizard = (props) => {
    const [step, setStep] = useState(3);

    const saveValues = (field_value) => {
        return function () {
            initialValues = Object.assign({}, initialValues, field_value);
        }.bind()();
    };

    const nextStep = () => {
        setStep(step + 1);
    };

    const previousStep = () => {
        setStep(step - 1);
    };

    const skipNextPage = () => {
        setStep(step + 2);
    };

    const setCustomStep = (step) => {
        setStep(step);
    };

    const showStep = () => {
        switch (step) {
            case 0:
                return (
                    <PersonalInformation
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        setupWizard={true}
                    />
                );
            case 1:
                return (
                    <Password
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        setupWizard={true}
                    />
                );
            case 2:
                return (
                    <SubscriptionType
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        setupWizard={true}
                    />
                );
            case 3:
                return (
                    <CompanyInformation
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        setCustomStep={setCustomStep}
                        formData={formData}
                        setupWizard={true}
                        profileData={props.profile ? props.profile : null}
                    />
                );
            case 4:
                return (
                    <EquityInformationClass
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        setupWizard={true}
                    />
                );
            case 5:
                return (
                    <EquityInformationShare
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        skipNextPage={skipNextPage}
                        setupWizard={true}
                    />
                );
            case 6:
                return (
                    <EquityInformationCategory
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        skipNextPage={skipNextPage}
                        setupWizard={true}
                    />
                );
            case 7:
                return (
                    <CapitalizationTable
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        skipNextPage={skipNextPage}
                        setupWizard={true}
                    />
                );
            case 8:
                return (
                    <CapitalizationTableOptions
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        skipNextPage={skipNextPage}
                        setupWizard={true}
                    />
                );
            case 9:
                return (
                    <UploadDocuments
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        formData={formData}
                        setupWizard={true}
                    />
                );
            case 10:
                return (
                    <Review
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        formData={formData}
                        setupWizard={true}
                    />
                );
            case 11:
                return (
                    <Complete
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        formData={formData}
                        setupWizard={true}
                    />
                );
            default:
                return (
                    <PersonalInformation
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        setupWizard={true}
                    />
                );
        }
    };
    return (
        <React.Fragment>
            <div>{showStep()}</div>
        </React.Fragment>
    );
};

const mapStateToProps = ({ profile }) => {
    return {
        profile: profile,
    };
};

export default connect(mapStateToProps, null)(SetupWizard);
