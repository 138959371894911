import request from '../../utilities/request'
import * as config from '../../config'
import * as adminTypes from './adminTypes'

import { getAccessToken, setAccessToken, setRefreshToken } from '../../utilities/auth';
import { setStorageItem, getStorageItem, clearStorageItem } from '../../utilities/storage';
import { parseError } from '../../utilities/utils';
import { snackbar } from '../../utilities/snackbarUtils';

import { getProfile, clearProfile } from '../profile/profileActions'
import { closeDialog, loadDialog } from '../dialog/dialogActions'

/**
 * Fetch a list of all issuers.
 */
export const getIssuersSuccess = (issuers) => {
    return {
        type: adminTypes.GET_ISSUERS_SUCCESS,
        payload: issuers
    }
}

export const setIssuersLoading = (loading) => {
    return {
        type: adminTypes.SET_ISSUERS_LOADING,
        payload: loading
    }
}

export const getIssuers = (page = 1) => {
    return (dispatch) => {
        dispatch(setIssuersLoading(true))
        request
            .get(`${config.API_BASE_URL}/admin/issuers?page=${page}`)
            .then((res) => {
                dispatch(getIssuersSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                dispatch(setIssuersLoading(false))
            });
    };
};

export const setLoginAsLoading = (loading) => {
    return {
        type: adminTypes.SET_LOGIN_AS_LOADING,
        payload: loading
    }
}

/**
 * Log in as a different user
 */
export const loginAs = (email, closeSnackbar, enqueueSnackbar, history,) => {
    return (dispatch) => {
        closeSnackbar();
        dispatch(setLoginAsLoading(true))

        setStorageItem('admin_token', getAccessToken())

        request
            .post(`${config.API_BASE_URL}/admin/login-as`, { email: email })
            .then((response) => {
                const { token } = response.data;
                setAccessToken(token);
                setRefreshToken(token);
                //  setAccessToken(access_token);
                //  setRefreshToken(refresh_token);
                localStorage.setItem('user-email', email);
                // localStorage.setItem('company-logo', companyLogo);

                dispatch(getProfile());

                history.replace('/issuer/'); // quick hack for now
            })
            .catch(() => {
                enqueueSnackbar(
                    'You cannot log in as this user.',
                    { variant: 'error' }
                );
            }).finally(() => {
                dispatch(setLoginAsLoading(false))
            });
    };
};

/**
 * Go back to admin account.
 */
export const switchToAdmin = (history) => {
    return (dispatch) => {

        /* Replace user data with admin data */
        const token = getStorageItem('admin_token')
        setAccessToken(token);
        setRefreshToken(token);

        clearStorageItem('admin_token')

        clearProfile();
        dispatch(getProfile());

        history.replace({ pathname: '/admin/listx' });
    }
}

/**
 * Fetch a list of all admins.
 */
export const getAdministratorsSuccess = (administrators) => {
    return {
        type: adminTypes.GET_ADMINISTRATORS_SUCCESS,
        payload: administrators
    }
}

export const setAdministratorsLoading = (loading) => {
    return {
        type: adminTypes.SET_ADMINISTRATORS_LOADING,
        payload: loading
    }
}

export const getAdministrators = () => {
    return (dispatch) => {
        dispatch(setAdministratorsLoading(true))
        request
            .get(`${config.API_BASE_URL}/admin/administrators`)
            .then((res) => {
                dispatch(getAdministratorsSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                dispatch(setAdministratorsLoading(false))
            });
    };
};

/**
 * Create a new administrator
 */
export const addAdministratorSuccess = (administrator) => {
    return {
        type: adminTypes.ADD_ADMINISTRATOR_SUCCESS,
        payload: administrator
    }
}

export const createAdministrator = (body, setSubmitting, resetForm, initialValues, handleClose, snackbar) => {
    return (dispatch) => {
        dispatch(setAdministratorsLoading(true))
        request
            .post(`${config.API_BASE_URL}/admin/create`, body)
            .then((res) => {
                handleClose(resetForm, initialValues) // reset and close dialog
                dispatch(addAdministratorSuccess(res.data.data))
                snackbar(res.data.message, {
                    variant: 'success'
                })
            })
            .catch((error) => {
                let errorMessage = parseError(error)
                snackbar(errorMessage, {
                    variant: 'error'
                })
            })
            .finally(() => {
                dispatch(setAdministratorsLoading(false))
                setSubmitting(false)
            });
    }
}

/**
 * Delete an administrator
 */

export const deleteAdministratorSuccess = (id) => {
    return {
        type: adminTypes.DELETE_ADMINISTRATOR_SUCCESS,
        payload: id
    }
}

export const deleteAdministrator = (id) => {
    return (dispatch) => {
        dispatch(loadDialog(true))
        request
            .delete(`${config.API_BASE_URL}/admin/${id}`)
            .then((res) => {
                dispatch(deleteAdministratorSuccess(id))
                dispatch(closeDialog())
                snackbar.success(res.data.message)
            })
            .catch((error) => {
                let errorMessage = parseError(error)
                snackbar.error(errorMessage)            
            })
            .finally(() => {
                dispatch(loadDialog(false))
            })
    }
}

/**
 * Fetch a list of all transferAgents.
 */
 export const getTransferAgentsSuccess = (transferAgents) => {
    return {
        type: adminTypes.GET_TRANSFER_AGENTS_SUCCESS,
        payload: transferAgents
    }
}

export const setTransferAgentsLoading = (loading) => {
    return {
        type: adminTypes.SET_TRANSFER_AGENTS_LOADING,
        payload: loading
    }
}

export const getTransferAgents = () => {
    return (dispatch) => {
        dispatch(setTransferAgentsLoading(true))
        request
            .get(`${config.API_BASE_URL}/admin/transfer-agents`)
            .then((res) => {
                dispatch(getTransferAgentsSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                dispatch(setTransferAgentsLoading(false))
            });
    };
};

/**
 * Create a new transfer agent
 */
export const addTransferAgentSuccess = (transferAgent) => {
    return {
        type: adminTypes.ADD_TRANSFER_AGENT_SUCCESS,
        payload: transferAgent
    }
}

export const createTransferAgent = (body, setSubmitting, resetForm, initialValues, handleClose) => {
    return (dispatch) => {
        dispatch(setTransferAgentsLoading(true))
        request
            .post(`${config.API_BASE_URL}/transfer-agent/create`, body)
            .then((res) => {
                handleClose(resetForm, initialValues) // reset and close dialog
                dispatch(addTransferAgentSuccess(res.data.data))
                snackbar.success(res.data.message)            
            })
            .catch((error) => {
                let errorMessage = parseError(error)
                snackbar.error(errorMessage)
            })
            .finally(() => {
                dispatch(setTransferAgentsLoading(false))
                setSubmitting(false)
            });
    }
}

/**
 * Delete an administrator
 */

export const deleteTransferAgentSuccess = (id) => {
    return {
        type: adminTypes.DELETE_TRANSFER_AGENT_SUCCESS,
        payload: id
    }
}

export const deleteTransferAgent = (id) => {
    return (dispatch) => {
        dispatch(loadDialog(true))
        request
            .delete(`${config.API_BASE_URL}/transfer-agent/${id}`)
            .then((res) => {
                dispatch(deleteTransferAgentSuccess(id))
                dispatch(closeDialog())
                snackbar.success(res.data.message)
            })
            .catch((error) => {
                let errorMessage = parseError(error)
                snackbar.error(errorMessage)            
            })
            .finally(() => {
                dispatch(loadDialog(false))
            })
    }
}
