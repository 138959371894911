import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Grid } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { assets } from '../../api';
import { snackbar } from '../../../../utilities/snackbarUtils';
import { errorParser } from '../../utils/errorParser';
import TextField from '../../../TextField/TextField';
import FormikAutocomplete from '../../../FormikAutocomplete';

const validationSchema = Yup.object({
    amount: Yup.number()
        .required('Please enter an Amount.')
        .min(1, 'Minimal amount is 1')
        .max(2100000000000000, 'Maximum amount is 2100000000000000')
        .notOneOf(['0'], 'The Amount cannot be 0.'),
    walletName: Yup.string().required('Please select Wallet.'),
});

const ReissueAsset = ({ asset, wallets }) => {
    const [walletOptions, setWalletOptions] = useState([]);

    useEffect(() => {
        setWalletOptions(
            wallets.map((wallet) => ({
                value: wallet.name,
                name: wallet.name,
            }))
        );
    }, [wallets]);

    const handleFormSubmit = async (type, formikProps) => {
        const { values, submitForm, setSubmitting, isValid } = formikProps;
        if (isValid) {
            submitForm();
            const amount = {
                [type === 'reissue'
                    ? 'amountToReissue'
                    : 'amountToBurn']: values.amount,
            };
            try {
                type === 'reissue'
                    ? await assets.reissue(
                          asset.assetUuid,
                          values.walletName,
                          amount
                      )
                    : await assets.burn(
                          asset.assetUuid,
                          values.walletName,
                          amount
                      );
            } catch (error) {
                snackbar.error(errorParser(error));
            }
            setSubmitting(false);
        }
    };

    return (
        <Box display="flex" flexDirection="column" mb={2}>
            <Typography variant="body1" textAlign="center">
                Reissue Asset
            </Typography>
            <Box my={2}>
                <Formik
                    initialValues={{
                        amount: 0,
                        walletName: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={() => {}}
                >
                    {(formikProps) => {
                        const { isSubmitting } = formikProps;
                        return (
                            <Form noValidate style={{ width: '100%' }}>
                                <Grid
                                    container
                                    spacing={2}
                                    direction="row"
                                    justifyContent="center"
                                >
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            component={TextField}
                                            type="number"
                                            name="amount"
                                            label="Amount"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            component={FormikAutocomplete}
                                            name="walletName"
                                            label="Wallet Name"
                                            options={walletOptions}
                                            optionKey="name"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                                handleFormSubmit(
                                                    'reissue',
                                                    formikProps
                                                )
                                            }
                                            disabled={isSubmitting}
                                            fullWidth
                                        >
                                            Reissue
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                                handleFormSubmit(
                                                    'burn',
                                                    formikProps
                                                )
                                            }
                                            disabled={isSubmitting}
                                            fullWidth
                                        >
                                            Burn
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </Box>
        </Box>
    );
};

export default ReissueAsset;
