import * as adminTypes from './adminTypes'

import { removeFromArray } from '../../utilities/utils'

const initialState = {
    issuers: {
        data: null,
        loading: true,
        counts: null
    },
    administrators: {
        data: null,
        loading: true
    },
    transferAgents: {
        data: null,
        loading: true
    },
    loginAsLoading: false
}

const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        /* Get and load issuers */
        case adminTypes.GET_ISSUERS_SUCCESS:
            return {
                ...state,
                issuers: {
                    ...state.issuers, 
                    data: action.payload.data,
                    counts: action.payload.counts 
                }
            }
        case adminTypes.SET_ISSUERS_LOADING:
            return {
                ...state,
                issuers: {
                    ...state.issuers, 
                    loading: action.payload 
                }
            }
        /* Get and load admin list */
        case adminTypes.GET_ADMINISTRATORS_SUCCESS:
            return {
                ...state,
                administrators: {
                    ...state.administrators, 
                    data: action.payload 
                }
            }
        case adminTypes.SET_ADMINISTRATORS_LOADING:
            return {
                ...state,
                administrators: {
                    ...state.administrators, 
                    loading: action.payload 
                }
            }
        /* Add a new admin */
        case adminTypes.ADD_ADMINISTRATOR_SUCCESS:
            return {
                ...state,
                administrators: {
                    ...state.administrators, 
                    data: [
                        ...state.administrators.data, 
                        action.payload
                    ] 
                }
            }
        /* Delete an administrator */
        case adminTypes.DELETE_ADMINISTRATOR_SUCCESS:
            let newAdministrators = removeFromArray(state.administrators.data, 'id', action.payload)
            return {
                ...state,
                administrators: {
                    ...state.administrators, 
                    data: newAdministrators
                }
            }
        /* Get and load transfer agent list */
        case adminTypes.GET_TRANSFER_AGENTS_SUCCESS:
            return {
                ...state,
                transferAgents: {
                    ...state.transferAgents, 
                    data: action.payload 
                }
            }
        case adminTypes.SET_TRANSFER_AGENTS_LOADING:
            return {
                ...state,
                transferAgents: {
                    ...state.transferAgents, 
                    loading: action.payload 
                }
            }
        /* Add a new admin */
        case adminTypes.ADD_TRANSFER_AGENT_SUCCESS:
            return {
                ...state,
                transferAgents: {
                    ...state.transferAgents, 
                    data: [
                        ...state.transferAgents.data, 
                        action.payload
                    ] 
                }
            }
        /* Delete an administrator */
        case adminTypes.DELETE_TRANSFER_AGENT_SUCCESS:
            let newTransferAgents = removeFromArray(state.transferAgents.data, 'id', action.payload)
            return {
                ...state,
                transferAgents: {
                    ...state.transferAgents, 
                    data: newTransferAgents
                }
            }
        /* Set "login as different user" */
        case adminTypes.SET_LOGIN_AS_LOADING:
            return {
                ...state,
                loginAsLoading: action.payload
            }
        default:
            return state
    }
}

export default adminReducer