import request from '../../utilities/request';
import * as config from '../../config';

const blockstreamUrl = `${config.API_BASE_URL}/blockchain`;

const managers = {
    get: () => {
        return request.get(`${blockstreamUrl}/managers`);
    },
    create: (body) => {
        return request.post(`${blockstreamUrl}/managers`, { ...body });
    },
    lock: (managerId) => {
        return request.put(`${blockstreamUrl}/managers/${managerId}/lock`);
    },
    unlock: (managerId) => {
        return request.put(`${blockstreamUrl}/managers/${managerId}/unlock`);
    },
    assign: (managerId, assetUuid) => {
        return request.post(
            `${blockstreamUrl}/managers/${managerId}/assets/${assetUuid}/assign`
        );
    },
    revoke: (managerId, assetUuid) => {
        return request.post(
            `${blockstreamUrl}/managers/${managerId}/assets/${assetUuid}/revoke`
        );
    },
};

const assets = {
    get: () => {
        return request.get(`${blockstreamUrl}/assets`);
    },
    issueNew: (body) => {
        return request.post(`${blockstreamUrl}/assets`, { ...body });
    },
    reissue: (assetUuid, walletName, body) => {
        return request.post(
            `${blockstreamUrl}/assets/${assetUuid}/wallet/${walletName}/reissue`,
            body
        );
    },
    updateAsset: (assetUuid, body) => {
        return request.put(`${blockstreamUrl}/assets/${assetUuid}`, body);
    },
    register: (assetUuid) => {
        return request.get(`${blockstreamUrl}/assets/${assetUuid}/register`);
    },
    grantPermission: (assetUuid, body) => {
        return request.post(
            `${blockstreamUrl}/assets/${assetUuid}/grant-permission`,
            { ...body }
        );
    },
    authorize: (assetUuid, body) => {
        return request.post(`${blockstreamUrl}/assets/${assetUuid}/authorize`);
    },
    distribute: (assetUuid, walletName) => {
        return request.post(
            `${blockstreamUrl}/assets/${assetUuid}/wallet/${walletName}/distribute`
        );
    },
    cancelDistribution: (assetUuid, distributionUuid) => {
        return request.delete(
            `${blockstreamUrl}/assets/${assetUuid}/distributions/${distributionUuid}/cancel`
        );
    },
    burn: (assetUuid, walletName, body) => {
        return request.post(
            `${blockstreamUrl}/assets/${assetUuid}/wallet/${walletName}/burn`,
            body
        );
    },
    balance: (assetUuid) => {
        return request.get(`${blockstreamUrl}/assets/${assetUuid}/balance`);
    },
    getOwner: (assetUuid) => {
        return request.get(`${blockstreamUrl}/assets/${assetUuid}/ownerships`);
    },
    getActivities: (assetUuid) => {
        return request.get(`${blockstreamUrl}/assets/${assetUuid}/activities`);
    },
    getAssignments: (assetUuid, body) => {
        return request.get(
            `${blockstreamUrl}/assets/${assetUuid}/assignments`,
            { params: { ...body } }
        );
    },
    sendAssignments: (assetUuid, body) => {
        return request.post(
            `${blockstreamUrl}/assets/${assetUuid}/assignments`,
            { ...body }
        );
    },
    getMemo: (assetUuid) => {
        return request.get(`${blockstreamUrl}/assets/${assetUuid}/memo`);
    },
    sendMemo: (assetUuid, body) => {
        return request.post(`${blockstreamUrl}/assets/${assetUuid}/memo`, {
            ...body,
        });
    },
    transfer: (body) => {
        return request.post(`${blockstreamUrl}/assets/transfer`, { ...body });
    },
    calculateReward: (assetUuid, totalDividendAvailable) => {
        return request.get(
            `${blockstreamUrl}/assets/${assetUuid}/calculate-reward/${totalDividendAvailable}`
        );
    },
    utxoBlacklist: (assetUuid, body) => {
        return request.post(
            `${blockstreamUrl}/assets/${assetUuid}/utxos/blacklist`,
            { ...body }
        );
    },
};

const category = {
    assignCategoryToUser: (categoryId, userId) => {
        return request.put(
            `${blockstreamUrl}/category/${categoryId}/registered-users/${userId}/add`
        );
    },
    assignCategoryToAsset: (categoryId, assetUuid) => {
        return request.put(
            `${blockstreamUrl}/category/${categoryId}/assets/${assetUuid}/add`
        );
    },
    removeUserFromCategory: (categoryId, userId) => {
        return request.put(
            `${blockstreamUrl}/category/${categoryId}/registered-users/${userId}/remove`
        );
    },
    removeAssetFromCategory: (categoryId, assetUuid) => {
        return request.put(
            `${blockstreamUrl}/category/${categoryId}/assets/${assetUuid}/remove`
        );
    },
    addCategory: (body) => {
        return request.post(`${blockstreamUrl}/category/add`, { ...body });
    },
    categories: () => {
        return request.get(`${blockstreamUrl}/category`);
    },
};
const gaid = {
    getGaidBalances: (gaid) => {
        return request.get(`${blockstreamUrl}/gaids/${gaid}/balance`);
    },
    getUserGaid: (gaid, assetUuid) => {
        return request.get(
            `${blockstreamUrl}/gaids/${gaid}/balance/${assetUuid}`
        );
    },
};
const wallet = {
    getWalletAddresses: (name) => {
        return request.get(`${blockstreamUrl}/wallet/${name}/addresses`);
    },
    getWallets: () => {
        return request.get(`${blockstreamUrl}/wallet`);
    },
    createWallet: (name) => {
        return request.post(`${blockstreamUrl}/wallet`, { name: name });
    },
    getPubkey: (name) => {
        return request.get(`${blockstreamUrl}/wallet/${name}/generate-pubkey`);
    },
    getWalletBalance: (name) => {
        return request.get(`${blockstreamUrl}/wallet/${name}/balance`);
    },
    claimPegIn: (name, body) => {
        return request.post(`${blockstreamUrl}/wallet/${name}/claim-peg-in`, {
            ...body,
        });
    },
    peginAddress: (name) => {
        return request.get(`${blockstreamUrl}/wallet/${name}/pegin-address`);
    },
    initPegout: (name, body) => {
        return request.post(`${blockstreamUrl}/wallet/${name}/init-pegout`, {
            ...body,
        });
    },
    pegout: (name, body) => {
        return request.post(`${blockstreamUrl}/wallet/${name}/pegout`, {
            ...body,
        });
    },
    getTaskStatus: (taskId) => {
        return request.get(`${blockstreamUrl}/wallet/${taskId}/tasks`);
    },
};
const greenWallet = {
    getWallets: () => {
        return request.get(`${blockstreamUrl}/green-wallets`);
    },
    createWallet: (wallet) => {
        return request.post(`${blockstreamUrl}/green-wallet`, {
            wallet: wallet,
        });
    },
    setPin: (body) => {
        return request.post(`${blockstreamUrl}/green-wallet/pin`, { ...body });
    },
    validatePin: (body) => {
        return request.post(`${blockstreamUrl}/green-wallet/pin/validate`, {
            pin: body.pin,
            wallet: body.name,
        });
    },
    getSettings: (body) => {
        return request.post(`${blockstreamUrl}/green-wallet/settings`, {
            ...body,
            method: 'get',
        });
    },
    saveSettings: (body) => {
        return request.put(`${blockstreamUrl}/green-wallet/settings`, {
            ...body,
        });
    },
    getAccounts: (body) => {
        return request.post(`${blockstreamUrl}/green-wallet/accounts`, {
            ...body,
            method: 'get',
        });
    },
    createAccount: (body) => {
        return request.post(`${blockstreamUrl}/green-wallet/accounts`, {
            ...body,
        });
    },
    getAccountAddress: (accountId, creds) => {
        return request.post(
            `${blockstreamUrl}/green-wallet/accounts/${accountId}/address`,
            {
                ...creds,
                method: 'get',
            }
        );
    },
    getAccountTransactions: (accountId, creds) => {
        return request.post(
            `${blockstreamUrl}/green-wallet/accounts/${accountId}/transaction`,
            {
                ...creds,
                method: 'get',
            }
        );
    },
    getAccountBalance: (accountId, creds) => {
        return request.post(
            `${blockstreamUrl}/green-wallet/accounts/${accountId}/balance`,
            {
                ...creds,
                method: 'get',
            }
        );
    },
    sendAsset: (accountId, body) => {
        return request.post(
            `${blockstreamUrl}/green-wallet/accounts/${accountId}/send-asset`,
            { ...body }
        );
    },
    getCurrencies: (body) => {
        return request.post(`${blockstreamUrl}/green-wallet/currencies`, {
            ...body,
            method: 'get',
        });
    },
};

const user = {
    getUsers: () => {
        return request.get(`${blockstreamUrl}/registered-users`);
    },
    create: (data) => {
        return request.post(`${blockstreamUrl}/registered-users`, { ...data });
    },
    getSummary: (registeredUserId) => {
        return request.get(
            `${blockstreamUrl}/registered-users/${registeredUserId}/summary`
        );
    },
    getGaids: (registeredUserId) => {
        return request.get(
            `${blockstreamUrl}/registered-users/${registeredUserId}/gaids`
        );
    },
    addGaid: (registeredUserId, data) => {
        return request.post(
            `${blockstreamUrl}/registered-users/${registeredUserId}/gaids/add`,
            { ...data }
        );
    },
    setDefaultGaid: (registeredUserId, data) => {
        return request.post(
            `${blockstreamUrl}/registered-users/${registeredUserId}/gaids/set-default`,
            { ...data }
        );
    },
};

export { managers, assets, category, greenWallet, gaid, wallet, user };
