import React, { useState, useEffect } from 'react';
import StyledPaper from '../../StyledPaper';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography,
    Popover,
    Grid,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import CircularLoader from '../../CircularLoader';

import * as liquidBlocksreamActions from '../../../store/liquidBlocksteam/liquidBlockstreamActions';
import TextField from '../../TextField/TextField';
import Button from '../../Button/Button';

import { managers as managersAPI } from '../api';
import { snackbar } from '../../../utilities/snackbarUtils';
import { errorParser } from '../utils/errorParser';

const createInitialValues = {
    username: '',
    password: '',
};

const createValidationSchema = Yup.object({
    username: Yup.string().required('Please enter your Username.'),
    password: Yup.string()
        .required('Please enter the account password.')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,

            'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, number and one symbol.'
        ),
});

const Managers = () => {
    const dispatch = useDispatch();
    const { managers, loading } = useSelector(
        (state) => state.liquidBlockstream
    );
    const [anchorEl, setAnchorEl] = useState(null);
    const [loadingLockUnlock, setLoadingLockUnlock] = useState(false);
    const [selectedManager, setSelectedManager] = useState();
    const [assetUuid, setAssetUuid] = useState('');

    useEffect(() => {
        if (!managers.length) {
            getManagers();
        }
    }, []);

    useEffect(() => {
        if (selectedManager && !!managers.length) {
            const managerTmp = managers.find(
                (item) => item.id === selectedManager.id
            );
            setSelectedManager(managerTmp);
        }
    }, [managers]);
    
    const getManagers = () => {
        dispatch(liquidBlocksreamActions.getManagers());
    };

    const handleManager = async (type) => {
        setLoadingLockUnlock(true);
        let res;
        try {
            res =
                type === 'lock'
                    ? await managersAPI.lock(selectedManager.id)
                    : await managersAPI.unlock(selectedManager.id);
        } catch (error) {
            snackbar.error(errorParser(error));
        }
        setLoadingLockUnlock(false);
        if (res.status === 200) {
            getManagers();
        }
    };

    const handleAsset = async (type) => {
        if (assetUuid.trim()) {
            try {
                type === 'assign'
                    ? await managersAPI.assign(selectedManager.id, assetUuid)
                    : await managersAPI.revoke(selectedManager.id, assetUuid);
                snackbar.success(`Successfully ${type} manager!`);
            } catch (error) {
                snackbar.error(errorParser(error));
            }
        }
    };

    const createManager = (data, setSubmitting, reset) => {
        managersAPI
            .create(data)
            .then((res) => {
                console.log('success');
                reset();
                getManagers();
            })
            .catch((error) => snackbar.error(errorParser(error)))
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <>
            <StyledPaper elevation={3} padding={10} marginBottom={20}>
                <Typography align="center" variant="subtitle1">
                    All Managers
                </Typography>
                {loading ? (
                    <CircularLoader />
                ) : (
                    <>
                        {!!managers.length ? (
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">
                                                id
                                            </TableCell>
                                            <TableCell align="center">
                                                Username
                                            </TableCell>
                                            <TableCell align="center">
                                                isLocked
                                            </TableCell>
                                            <TableCell align="right">
                                                Assets
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {managers.map((item) => (
                                            <TableRow
                                                hover
                                                style={{ cursor: 'pointer' }}
                                                onClick={() =>
                                                    setSelectedManager(item)
                                                }
                                                key={item.id}
                                            >
                                                <TableCell
                                                    align="center"
                                                    scope="row"
                                                >
                                                    {item.id}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    scope="row"
                                                >
                                                    {item.username}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    scope="row"
                                                >
                                                    {item.isLocked ? '+' : '-'}
                                                </TableCell>
                                                <TableCell
                                                    align="right"
                                                    scope="row"
                                                >
                                                    {item.assets}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <Typography align="center">No Managers</Typography>
                        )}
                    </>
                )}
            </StyledPaper>
            <StyledPaper elevation={3} padding={10} marginBottom={20}>
                <Typography align="center" variant="subtitle2">
                    Create Manager
                </Typography>
                <Formik
                    initialValues={createInitialValues}
                    validationSchema={createValidationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        createManager(values, setSubmitting, resetForm);
                    }}
                >
                    {({ submitForm, isSubmitting }) => (
                        <Form noValidate style={{ width: '100%' }}>
                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                justify="center"
                            >
                                <Grid item xs={12} lg={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        name="username"
                                        label="Username"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Field
                                        component={TextField}
                                        type="password"
                                        name="password"
                                        label="Password"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={submitForm}
                                        disabled={isSubmitting}
                                    >
                                        Create Manager
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </StyledPaper>
            {selectedManager && (
                <>
                    <StyledPaper elevation={3} padding={10} marginBottom={20}>
                        <Typography align="center" variant="subtitle2">
                            Current Selected Manager
                        </Typography>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-around"
                            mb={3}
                        >
                            <Typography>{selectedManager.id}</Typography>
                            <Typography>{selectedManager.username}</Typography>
                            <Typography>
                                {selectedManager.isLocked ? '+' : '-'}
                            </Typography>
                            <Box>
                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={(event) =>
                                        setAnchorEl(event.currentTarget)
                                    }
                                >
                                    Open Assets
                                </Button>
                                <Popover
                                    open={Boolean(anchorEl)}
                                    anchorEl={anchorEl}
                                    onClose={() => setAnchorEl(null)}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    {selectedManager.assets?.map((item) => (
                                        <Typography>{item}</Typography>
                                    ))}
                                </Popover>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            {loadingLockUnlock ? (
                                <CircularLoader />
                            ) : (
                                <>
                                    <Box mr={2}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                                handleManager('lock')
                                            }
                                            disabled={selectedManager.isLocked}
                                        >
                                            Lock Manager
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                                handleManager('unlock')
                                            }
                                            disabled={!selectedManager.isLocked}
                                        >
                                            Unlock Manager
                                        </Button>
                                    </Box>
                                </>
                            )}
                        </Box>
                    </StyledPaper>
                    <StyledPaper elevation={3} padding={10} marginBottom={20}>
                        <Typography align="center" variant="subtitle2">
                            Managers and Assets
                        </Typography>
                        <Box display="flex" justifyContent="center" m={2}>
                            <TextField
                                id="filled-basic"
                                value={assetUuid}
                                label="Asset User Id"
                                onChange={(event) =>
                                    setAssetUuid(event.target.value)
                                }
                                variant="outlined"
                                required
                                fullWidth
                            />
                        </Box>
                        <Box display="flex" justifyContent="center">
                            <Box mr={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleAsset('assign')}
                                >
                                    Assign Manager to Asset
                                </Button>
                            </Box>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleAsset('revoke')}
                            >
                                Revoke Manager from Asset
                            </Button>
                        </Box>
                    </StyledPaper>
                </>
            )}
        </>
    );
};

export default Managers;
