import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useSnackbar } from 'notistack';
import * as config from './../../../config';
import requestApi from '../../../utilities/request';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DataTable from '../../../components/DataTable/MuiDataTable';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import * as transferAgentActions from '../../../store/transferAgent/transferAgentActions';
import authStyles from '../../issuer/authStyles';
import { Editor } from '@tinymce/tinymce-react';
import TextField from '../../../components/TextField/TextField';
import Humanize from 'humanize-plus';
import { DateFormatter } from '../../../utilities/formatters';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
const TAPartners = (props) => {
    const classes = authStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [request, setRequest] = useState([]);
    const [reviewRequestDialog, setReviewRequestDialog] = useState(false);
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [documentsDialog, setDocumentsDialog] = useState(false);

    useEffect(() => props.transferAgentActions.getPartnershipRequests(), [
        props.transferAgentActions,
    ]);

    const displayReviewRequestDialog = (request) => {
        setRequest(request);
        setReviewRequestDialog(true);
    };

    const closeReviewRequestDialog = () => {
        setReviewRequestDialog(false);
    };

    const handleEditorChange = (content) => {
        setMessage(content);
    };

    const respondToRequest = (status, id) => {
        setIsLoading(true);
        requestApi
            .patch(`${config.API_BASE_URL}/transfer-agents/partnership/edit`, {
                id: id,
                status: status,
                subject: subject,
                message: message,
                partnership_request: request,
            })
            .then((response) => {
                if (status === 'rejected') {
                    enqueueSnackbar(
                        'Partnership request successfully rejected.',
                        {
                            variant: 'success',
                        }
                    );
                } else {
                    enqueueSnackbar(
                        'Partnership request successfully approved.',
                        {
                            variant: 'success',
                        }
                    );
                }

                setReviewRequestDialog(false);
                setIsLoading(false);
                props.transferAgentActions.getPartnershipRequests();
            })
            .catch((error) => {
                setIsLoading(false);
                enqueueSnackbar(error.response.data.message, {
                    variant: 'error',
                });
            });
    };

    const displayDocumentsDialog = (id) => {
        props.transferAgentActions.listIssuerDocuments(id);
        setDocumentsDialog(true);
    };

    const closeDocumentsDialog = () => {
        setDocumentsDialog(false);
    };

    const viewDocument = (file_path) => {
        if (file_path !== null && file_path !== undefined) {
            window.open(file_path);
        } else {
            enqueueSnackbar('File has not been uploaded', {
                variant: 'error',
            });
        }
    };

    console.log(props);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {props.loading ? (
                    <Grid container item xs={12} justifyContent="center">
                        <CircularProgress />
                    </Grid>
                ) : (
                    <DataTable
                        title={'TA Partner Request'}
                        data={
                            props.transferAgents && props.transferAgents.data
                                ? props.transferAgents.data
                                : []
                        }
                        columns={[
                            {
                                label: 'Company name',
                                name: 'company_name',
                            },
                            {
                                label: 'User',
                                name: 'user',
                            },
                            {
                                label: 'Email',
                                name: 'email_address',
                            },
                            {
                                label: 'Created at',
                                name: 'created_at',
                            },
                            {
                                label: 'Documents',
                                name: 'id',
                                options: {
                                    download: false,
                                    print: false,
                                    customBodyRenderLite: (dataIndex) => {
                                        const row =
                                            props.transferAgents.data[
                                                dataIndex
                                            ];
                                        return (
                                            <React.Fragment>
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    color="secondary"
                                                    size={'sm'}
                                                    onClick={() =>
                                                        displayDocumentsDialog(
                                                            row.issuer_id
                                                        )
                                                    }
                                                >
                                                    View
                                                </Button>
                                            </React.Fragment>
                                        );
                                    },
                                },
                            },
                            {
                                label: 'Review',
                                name: 'id',
                                options: {
                                    download: false,
                                    print: false,
                                    customBodyRenderLite: (dataIndex) => {
                                        const row =
                                            props.transferAgents.data[
                                                dataIndex
                                            ];

                                        return (
                                            <React.Fragment>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => {
                                                        displayReviewRequestDialog(
                                                            row,
                                                            'approved'
                                                        );
                                                    }}
                                                >
                                                    Review
                                                </Button>
                                            </React.Fragment>
                                        );
                                    },
                                },
                            },
                        ]}
                    />
                )}
            </Grid>
            <Dialog
                maxWidth="lg"
                fullWidth
                open={reviewRequestDialog}
                onBackdropClick={(e) => setReviewRequestDialog(false)}
            >
                <DialogTitle className={classes.dialogTitle}>
                    Review request from{' '}
                    {request.issuer ? request.issuer : request.company_name}
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <Formik
                                initialValues={{
                                    subject: '',
                                    message: message,
                                }}
                                //validationSchema={}
                                onSubmit={(values, { setSubmitting }) => {
                                    if (
                                        values['subject'] !== null &&
                                        values['subject'] !== undefined &&
                                        values['subject'] !== '' &&
                                        message !== null &&
                                        message !== undefined &&
                                        message !== ''
                                    ) {
                                        requestApi
                                            .post(
                                                `${config.API_BASE_URL}/transfer-agents/partnership/note`,
                                                {
                                                    message: message,
                                                    request: request,
                                                    subject: values['subject'],
                                                }
                                            )
                                            .then((response) => {
                                                props.transferAgentActions.getPartnershipRequests();
                                                setReviewRequestDialog(false);
                                                enqueueSnackbar(
                                                    'Message sent successfully',
                                                    {
                                                        variant: 'success',
                                                    }
                                                );
                                                setSubmitting(false);
                                            })
                                            .catch((error) => {
                                                setSubmitting(false);
                                                enqueueSnackbar(
                                                    error.response.data.message,
                                                    {
                                                        variant: 'error',
                                                    }
                                                );
                                            });
                                    } else {
                                        setSubmitting(false);
                                        enqueueSnackbar(
                                            'Please enter subject and message',
                                            {
                                                variant: 'error',
                                            }
                                        );
                                    }
                                }}
                            >
                                {({ isSubmitting }) => (
                                    <Form className={classes.form} noValidate>
                                        <Grid container>
                                            <InputLabel
                                                className={classes.label}
                                                htmlFor="message"
                                            >
                                                Company
                                            </InputLabel>
                                        </Grid>
                                        <span>
                                            {request && request.company_name
                                                ? request.company_name
                                                : ''}
                                        </span>
                                        <Grid container>
                                            <InputLabel
                                                className={classes.label}
                                                htmlFor="message"
                                            >
                                                Email
                                            </InputLabel>
                                        </Grid>
                                        <span>
                                            {request && request.email_address
                                                ? request.email_address
                                                : ''}
                                        </span>
                                        <Grid container>
                                            <InputLabel
                                                className={classes.label}
                                                htmlFor="message"
                                            >
                                                Requested received at:
                                            </InputLabel>
                                        </Grid>
                                        <span>
                                            {request && request.created_at
                                                ? request.created_at
                                                : ''}
                                        </span>
                                        <Grid container>
                                            <InputLabel
                                                className={classes.label}
                                                htmlFor="message"
                                            >
                                                Asset classes:
                                            </InputLabel>
                                        </Grid>
                                        <br />
                                        {request.share_classes.length > 0 && (
                                            <DataTable
                                                title={'Asset Classes'}
                                                data={request.share_classes}
                                                columns={[
                                                    {
                                                        label: 'Class',
                                                        name: 'description',
                                                    },
                                                    {
                                                        label: 'Type',
                                                        name: 'class',
                                                        options: {
                                                            customBodyRender: (
                                                                value
                                                            ) => (
                                                                <span>
                                                                    {value.toUpperCase()}
                                                                </span>
                                                            ),
                                                        },
                                                    },
                                                    {
                                                        label:
                                                            'Authorized assets',
                                                        name:
                                                            'authorized_shares',
                                                        options: {
                                                            customBodyRenderLite: (
                                                                dataIndex
                                                            ) => {
                                                                const row =
                                                                    request
                                                                        .share_classes[
                                                                        dataIndex
                                                                    ];
                                                                return (
                                                                    <span>
                                                                        {row.authorized_shares
                                                                            ? Humanize.formatNumber(
                                                                                  row.authorized_shares,
                                                                                  2
                                                                              )
                                                                            : 'N/A'}
                                                                    </span>
                                                                );
                                                            },
                                                        },
                                                    },
                                                    {
                                                        label:
                                                            'Price per asset',
                                                        name: 'price',
                                                        options: {
                                                            customBodyRenderLite: (
                                                                dataIndex
                                                            ) => {
                                                                const row =
                                                                    request
                                                                        .share_classes[
                                                                        dataIndex
                                                                    ];
                                                                return (
                                                                    <span>
                                                                        {row.price &&
                                                                        row.equity_main_currency
                                                                            ? `${Humanize.formatNumber(
                                                                                  row.price,
                                                                                  2
                                                                              )} ${
                                                                                  row.equity_main_currency
                                                                              }`
                                                                            : 'N/A'}
                                                                    </span>
                                                                );
                                                            },
                                                        },
                                                    },
                                                ]}
                                            />
                                        )}
                                        <br />
                                        <Grid container>
                                            <InputLabel
                                                className={classes.label}
                                                htmlFor="message"
                                            >
                                                Message from Issuer
                                            </InputLabel>
                                        </Grid>

                                        <p>
                                            <b>Subject:</b>
                                        </p>
                                        <p>{request.note_title}</p>
                                        <p>
                                            <b>Message:</b>
                                        </p>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: request.note,
                                            }}
                                        ></div>
                                        <br />
                                        <br />
                                        <Grid container>
                                            <InputLabel
                                                className={classes.label}
                                                htmlFor="message"
                                            >
                                                Message to Issuer
                                            </InputLabel>
                                        </Grid>
                                        <Grid container>
                                            <InputLabel
                                                className={classes.label}
                                                htmlFor="subject"
                                            >
                                                Subject
                                            </InputLabel>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                name={'subject'}
                                                variant="outlined"
                                                fullWidth
                                                onChange={(e) =>
                                                    setSubject(e.target.value)
                                                }
                                            />
                                        </Grid>
                                        <Grid container>
                                            <InputLabel
                                                className={classes.label}
                                                htmlFor="message"
                                            >
                                                Message
                                            </InputLabel>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Editor
                                                apiKey="uf9matrx4ivq9fcl3zpum8qtj7b4bb79w95bmuwn5d2hkhyi"
                                                initialValue="<p></p>"
                                                name="message"
                                                init={{
                                                    height: 250,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount',
                                                    ],
                                                    toolbar:
                                                        'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                                                }}
                                                onEditorChange={
                                                    handleEditorChange
                                                }
                                            />
                                        </Grid>
                                        <DialogActions
                                            className={classes.actions}
                                        >
                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    closeReviewRequestDialog();
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                type="submit"
                                                disabled={
                                                    isSubmitting || isLoading
                                                }
                                            >
                                                {!isSubmitting && !isLoading ? (
                                                    <span>Send</span>
                                                ) : (
                                                    <CircularProgress
                                                        size={24}
                                                    />
                                                )}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={
                                                    isSubmitting || isLoading
                                                }
                                                onClick={() => {
                                                    respondToRequest(
                                                        'approved',
                                                        request.id
                                                    );
                                                }}
                                            >
                                                {!isSubmitting && !isLoading ? (
                                                    <span>Approve</span>
                                                ) : (
                                                    <CircularProgress
                                                        size={24}
                                                    />
                                                )}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="danger"
                                                disabled={
                                                    isSubmitting || isLoading
                                                }
                                                onClick={() => {
                                                    respondToRequest(
                                                        'rejected',
                                                        request.id
                                                    );
                                                }}
                                            >
                                                {!isSubmitting && !isLoading ? (
                                                    <span>Reject</span>
                                                ) : (
                                                    <CircularProgress
                                                        size={24}
                                                    />
                                                )}
                                            </Button>
                                        </DialogActions>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog
                maxWidth="sm"
                fullWidth
                open={documentsDialog}
                onBackdropClick={(e) => setDocumentsDialog(false)}
            >
                <DialogTitle>
                    Issuer Documents
                    <br />
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            {props.documents.data &&
                                props.documents.data.data.map(
                                    (document, id) => (
                                        <div>
                                            <div>
                                                <b>Name:</b> {document.name}
                                            </div>
                                            <div>
                                                <b>Type:</b> {document.type}
                                            </div>
                                            <div>
                                                <b>Updated at:</b>{' '}
                                                {DateFormatter({
                                                    value: document.updated_at,
                                                    format: 'date',
                                                })}
                                            </div>
                                            <div>
                                                {' '}
                                                <Button
                                                    component="label"
                                                    color="primary"
                                                    onClick={() =>
                                                        viewDocument(
                                                            document.file_path
                                                                ? document.file_path
                                                                : null
                                                        )
                                                    }
                                                >
                                                    <b>View Document</b>
                                                </Button>
                                            </div>
                                            <br />
                                            <br />
                                        </div>
                                    )
                                )}
                            <DialogActions>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        closeDocumentsDialog();
                                    }}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Grid>
    );
};

const mapStateToProps = ({ profile, transferAgent }) => {
    return {
        profile: profile,
        transferAgents: transferAgent.taRequests,
        loading: transferAgent.loading,
        documents: transferAgent.issuerDocuments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        transferAgentActions: bindActionCreators(
            transferAgentActions,
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TAPartners);
