import makeStyles from '@mui/styles/makeStyles';

const listTemplateStyles = makeStyles((theme) => ({
    listItemAvatar: {
        color: '#3E9CD9',
    },
    h2: {
        textAlign: 'center',
        color: '#ED7571',
    },
    paper: {
        borderRadius: 15,
        padding: 20,
        height: '100%',
    },
    listElement: { marginTop: -25 },
    title: {
        fontSize: '1rem',
    },
    inputLabel: {
        color: '#666',
        fontSize: '0.875rem',
        marginBottom: '0.5rem',
    },
    circularProgressDiv: {
        display: 'flex',
        justifyContent: 'center',
    },
    dialogActions: {
        marginTop: '1.5rem',
        alignItems: 'center',
    },
    verifiedPartnersDescription: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '21px',
        color: '#7D8696',
    },
    listingDetailsTitle: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '21px',
        display: 'flex',
        alignItems: 'center',
        color: '#333333',
        textAlign: 'left',
        marginBottom: '1rem',
    },
    listingDetailsValue: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '21px',
        textAlign: 'right',
        color: '#3E9CD9',
        marginBottom: '1rem',
        overflowWrap: 'break-word',
    },
    checkbox: {
        background: '#FFFFFF',
        border: '1px solid #E9E9E9',
        boxSizing: 'border-box',
        borderRadius: '3px',
    },
    disclosureRequirementsPrimaryList: {
        fontFamily: 'Saira',
        fontStyle: 'normal',
        fontWeight: 550,
        fontSize: '12px',
        lineHeight: '22px',
        color: '#333333',
    },
    disclosureRequirementsSecondaryList: {
        fontFamily: 'Saira',
        fontStyle: 'normal',
        fontWeight: 550,
        fontSize: '12px',
        lineHeight: '22px',
        color: '#727272',
        marginLeft: '1rem',
    },
    quartersList: {
        fontFamily: 'Saira',
        fontStyle: 'normal',
        fontWeight: 550,
        fontSize: '12px',
        lineHeight: '22px',
        color: '#333333',
        marginLeft: '3rem',
    },
    open: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '21px',
        textAlign: 'right',
        //textTransform: 'uppercase',
        color: '#ED7571',
        verticalAlign: '-webkit-baseline-middle',
    },
    completed: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '21px',
        //textTransform: 'uppercase',
        color: '#4E984B',
        textAlign: 'right',
        verticalAlign: '-webkit-baseline-middle',
    },
    editItem: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '22px',
        textAlign: 'right',
        color: '#3E9CD9',
        width: '100%',
        display: 'inherit',
        alignItems: 'inherit',
        justifyContent: 'initial',
    },
    dialogSecondaryList: {
        fontFamily: 'Saira',
        fontStyle: 'normal',
        fontWeight: 550,
        fontSize: '12px',
        lineHeight: '22px',
        color: '#727272',
    },
}));

export default listTemplateStyles;
