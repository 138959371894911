import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import authStyles from '../../issuer/authStyles';
import { useSnackbar } from 'notistack';
import * as config from './../../../config';
import request from '../../../utilities/request';
import Grid from '@mui/material/Grid';
import { Formik, Form } from 'formik';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import * as transferAgentActions from '../../../store/transferAgent/transferAgentActions';
import transferStyles from '../../../components/styles/transferStyles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
//import TextField from '../../../components/TextField/TextField';
//import Autocomplete from '@mui/material/Autocomplete';
import DataTable from '../../../components/DataTable/MuiDataTable';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import Humanize from 'humanize-plus';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { DateFormatter } from '../../../utilities/formatters';
import { PENDING, APPROVED, REJECTED } from '../../../constants/transfers';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
const Transfers = (props) => {
    const classes = authStyles();
    const transferClasses = transferStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [approveDialog, setApproveDialog] = useState(false);
    const [verificationParameters, setVerificationParameters] = useState([]);
    const [parameterLoading, setParameterLoading] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [transferId, setTransferId] = useState(null);
    //const { / transferAgentActions } = props;
    //useEffect(transferAgentActions.getIssuers, []);
    useEffect(
        () =>
            props.transferAgentActions.listTATransfers(
                transferStatus ? transferStatus : PENDING
            ),
        [props.transferAgentActions]
    );
    /*const [issuer, setIssuer] = useState(
        issuers && issuers.data ? issuers.data[0] : null
    );*/

    let status = [
        {
            value: 'all',
            description: 'ALL',
        },
        {
            value: PENDING,
            description: 'PENDING',
        },
        {
            value: APPROVED,
            description: 'APPROVED',
        },
        {
            value: REJECTED,
            description: 'REJECTED',
        },
    ];

    const [transferStatus, setTransferStatus] = useState(status[1].value);

    /*    const handleIssuerChange = (value, type) => {
        if (issuers && issuers.data) {
            let selectedIssuer = issuers.data.filter((element) => {
                return element.name === value;
            })[0];
            setIssuer(selectedIssuer);
        }
    };*/

    const handleStatusChange = (value) => {
        setTransferStatus(value);
        props.transferAgentActions.listTATransfers(value);
    };

    const displayApproveDialog = (id) => {
        setTransferId(id);
        setParameterLoading(true);
        request
            .get(
                `${config.API_BASE_URL}/transfers/verification-parameters/` + id
            )
            .then((response) => {
                if (response && response.data.data.length) {
                    setVerificationParameters(response.data.data);
                    setIsReady(response.data.ready_to_approve);
                }
                setParameterLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setParameterLoading(false);
            });
        setApproveDialog(true);
    };

    const closeDialog = () => {
        setApproveDialog(false);
    };

    const rejectTransfer = (id) => {
        request
            .put(`${config.API_BASE_URL}/transfers/reject/` + id)
            .then((response) => {
                enqueueSnackbar('Transfer has been rejected successfully  .', {
                    variant: 'success',
                });
                props.transferAgentActions.listTATransfers(transferStatus);
            });
    };

    const handleParameterChange = (eventValue, verification) => {
        let data = [];
        let ready = true;
        verificationParameters.map((item) => {
            if (item.id === verification.id) {
                item.verified = !item.verified;
            }

            if (!item.verified) {
                ready = false;
            }
            data.push(item);
            return true;
        });
        setIsReady(ready);
        setVerificationParameters(data);
        request
            .put(
                `${config.API_BASE_URL}/transfers/verificaton-parameters/` +
                    transferId,
                {
                    id: verification.id,
                    status: verification.verified,
                }
            )
            .then((response) => {});
    };
    return (
        <div>
            <Formik>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper elevation={3} className={classes.paper}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} sm={12}>
                                    <Typography className={classes.title}>
                                        Transfers
                                    </Typography>

                                    <Grid container spacing={2}>
                                        {/*<Grid item xs={12} sm={6} md={6}>
                                        <InputLabel className={classes.label}>
                                            Issuer
                                        </InputLabel>
                                        <Autocomplete
                                            id="free-solo-demo"
                                            value={
                                                issuer !== null &&
                                                issuer !== undefined
                                                    ? issuer.name
                                                        ? issuer.name
                                                        : ''
                                                    : 'Select Issuer'
                                            }
                                            onInputChange={(
                                                event,
                                                newInputValue
                                            ) => {
                                                handleIssuerChange(
                                                    newInputValue
                                                );
                                            }}
                                            options={
                                                issuers.data
                                                    ? issuers.data.map(
                                                          (issuer, id) =>
                                                              issuer.name
                                                      )
                                                    : []
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    type="text"
                                                    label=""
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </Grid>*/}
                                        <Grid item xs={12} sm={6} md={6}>
                                            <InputLabel
                                                className={classes.label}
                                            >
                                                Status
                                            </InputLabel>
                                            <FormControl
                                                variant="outlined"
                                                className={classes.formControl}
                                                fullWidth
                                                style={{ marginTop: '20px' }}
                                            >
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={transferStatus}
                                                    fullWidth
                                                    onChange={(e) =>
                                                        handleStatusChange(
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    {status.map(
                                                        (status_value, i) => (
                                                            <MenuItem
                                                                value={
                                                                    status_value.value
                                                                }
                                                            >
                                                                {
                                                                    status_value.description
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {props.loading ? (
                                                <Grid
                                                    container
                                                    item
                                                    xs={12}
                                                    justifyContent="center"
                                                >
                                                    <CircularProgress />
                                                </Grid>
                                            ) : (
                                                <DataTable
                                                    title={'Transfers History'}
                                                    data={
                                                        props.transfers
                                                            ? props.transfers
                                                                  .data
                                                            : []
                                                    }
                                                    columns={[
                                                        /*{
                                                        label: 'Ticker',
                                                        name: 'equity',
                                                        options: {
                                                            customBodyRender: (
                                                                value
                                                            ) => (
                                                                <Typography variant="overline">
                                                                    {
                                                                        value.ticker
                                                                    }
                                                                </Typography>
                                                            ),
                                                        },
                                                    },*/
                                                        {
                                                            label: 'From',
                                                            name: 'sender',
                                                            options: {
                                                                customBodyRender: (
                                                                    value
                                                                ) => (
                                                                    <span>
                                                                        {value &&
                                                                        value.first_name
                                                                            ? value.first_name +
                                                                              ' ' +
                                                                              value.last_name
                                                                            : '-'}
                                                                    </span>
                                                                ),
                                                            },
                                                        },
                                                        {
                                                            label:
                                                                'Sender Wallet',
                                                            name: 'sender',
                                                            options: {
                                                                customBodyRender: (
                                                                    value
                                                                ) => (
                                                                    <div>
                                                                        <Tooltip
                                                                            title={
                                                                                value &&
                                                                                value.wallet
                                                                                    ? value
                                                                                          .wallet
                                                                                          .public_address
                                                                                    : 'Not available'
                                                                            }
                                                                        >
                                                                            <IconButton size="small">
                                                                                <AccountBalanceWalletIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </div>
                                                                ),
                                                            },
                                                        },
                                                        {
                                                            label: 'To',
                                                            name: 'receiver',
                                                            options: {
                                                                customBodyRender: (
                                                                    value
                                                                ) => (
                                                                    <span>
                                                                        {value &&
                                                                        value.first_name
                                                                            ? value.first_name +
                                                                              ' ' +
                                                                              value.last_name
                                                                            : '-'}
                                                                    </span>
                                                                ),
                                                            },
                                                        },
                                                        {
                                                            label:
                                                                'Recipient wallet',
                                                            name: 'receiver',
                                                            options: {
                                                                customBodyRender: (
                                                                    value
                                                                ) => (
                                                                    <div>
                                                                        <Tooltip
                                                                            title={
                                                                                value &&
                                                                                value.wallet
                                                                                    ? value
                                                                                          .wallet
                                                                                          .public_address
                                                                                    : 'Not available'
                                                                            }
                                                                        >
                                                                            <IconButton size="small">
                                                                                <AccountBalanceWalletIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </div>
                                                                ),
                                                            },
                                                        },
                                                        {
                                                            label: 'Amount',
                                                            name: 'amount',
                                                            options: {
                                                                customBodyRender: (
                                                                    value
                                                                ) => (
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                '#3E9CD9',
                                                                        }}
                                                                    >
                                                                        <b>
                                                                            {Humanize.formatNumber(
                                                                                value,
                                                                                2
                                                                            )}
                                                                        </b>
                                                                    </span>
                                                                ),
                                                            },
                                                        },
                                                        {
                                                            label:
                                                                'Price per Asset',
                                                            name:
                                                                'price_per_share',
                                                            options: {
                                                                customBodyRender: (
                                                                    value
                                                                ) => (
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                '#3E9CD9',
                                                                        }}
                                                                    >
                                                                        <b>
                                                                            {Humanize.formatNumber(
                                                                                value,
                                                                                2
                                                                            )}
                                                                        </b>
                                                                    </span>
                                                                ),
                                                            },
                                                        },
                                                        {
                                                            label: '',
                                                            name: 'submitter',
                                                            options: {
                                                                display:
                                                                    'excluded',
                                                            },
                                                        },
                                                        {
                                                            label: '',
                                                            name: 'reviewer',
                                                            options: {
                                                                display:
                                                                    'excluded',
                                                            },
                                                        },
                                                        {
                                                            label: '',
                                                            name: 'updated_at',
                                                            options: {
                                                                display:
                                                                    'excluded',
                                                                customBodyRender: (
                                                                    value
                                                                ) => (
                                                                    <span>
                                                                        {DateFormatter(
                                                                            {
                                                                                value: value,
                                                                                format:
                                                                                    'date',
                                                                            }
                                                                        )}
                                                                    </span>
                                                                ),
                                                            },
                                                        },
                                                        {
                                                            label: '',
                                                            name: 'transaction',
                                                            options: {
                                                                display:
                                                                    'excluded',
                                                            },
                                                        },
                                                        {
                                                            label: 'Status',
                                                            name: 'status',
                                                            options: {
                                                                customBodyRender: (
                                                                    value
                                                                ) => (
                                                                    <div
                                                                        className={
                                                                            value ===
                                                                            'pending'
                                                                                ? transferClasses.grey
                                                                                : value ===
                                                                                  'approved'
                                                                                ? transferClasses.green
                                                                                : transferClasses.red
                                                                        }
                                                                    >
                                                                        {value
                                                                            ? value.toUpperCase()
                                                                            : ''}
                                                                    </div>
                                                                ),
                                                            },
                                                        },
                                                        {
                                                            label: 'Type',
                                                            name: 'type',
                                                            options: {
                                                                customBodyRender: (
                                                                    value
                                                                ) => (
                                                                    <div>
                                                                        {value
                                                                            ? value.toUpperCase()
                                                                            : ''}
                                                                    </div>
                                                                ),
                                                            },
                                                        },
                                                        {
                                                            label: '',
                                                            name: 'status',
                                                            options: {
                                                                display:
                                                                    'excluded',
                                                            },
                                                        },
                                                        {
                                                            label: 'Is routine',
                                                            name: 'is_routine',
                                                            options: {
                                                                customBodyRender: (
                                                                    value
                                                                ) => (
                                                                    <div>
                                                                        {value
                                                                            ? 'ROUTINE'
                                                                            : 'NON-ROUTINE'}
                                                                    </div>
                                                                ),
                                                            },
                                                        },

                                                        {
                                                            label: 'Approve',
                                                            name: 'id',
                                                            options: {
                                                                download: false,
                                                                print: false,
                                                                customBodyRender: (
                                                                    value
                                                                ) => {
                                                                    return (
                                                                        <React.Fragment>
                                                                            <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                size="small"
                                                                                onClick={() => {
                                                                                    displayApproveDialog(
                                                                                        value
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Approve
                                                                            </Button>
                                                                        </React.Fragment>
                                                                    );
                                                                },
                                                            },
                                                        },
                                                        {
                                                            label: 'Reject',
                                                            name: 'id',
                                                            options: {
                                                                download: false,
                                                                print: false,
                                                                customBodyRender: (
                                                                    value
                                                                ) => {
                                                                    return (
                                                                        <React.Fragment>
                                                                            <Button
                                                                                variant="contained"
                                                                                color="secondary"
                                                                                size="small"
                                                                                onClick={() => {
                                                                                    rejectTransfer(
                                                                                        value
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Reject
                                                                            </Button>
                                                                        </React.Fragment>
                                                                    );
                                                                },
                                                            },
                                                        },
                                                        {
                                                            label: '',
                                                            name: 'documents',
                                                            options: {
                                                                display:
                                                                    'excluded',
                                                            },
                                                        },
                                                    ]}
                                                    expandable={{
                                                        expandableRows: true,
                                                        expandableRowsHeader: false,
                                                        expandableRowsOnClick: false,
                                                        renderExpandableRow: (
                                                            rowData,
                                                            rowMeta
                                                        ) => {
                                                            const colSpan =
                                                                rowData.length +
                                                                1;

                                                            return (
                                                                <TableRow>
                                                                    <TableCell
                                                                        colSpan={
                                                                            colSpan
                                                                        }
                                                                        className={
                                                                            transferClasses.historyDetailsTable
                                                                        }
                                                                    >
                                                                        <Grid
                                                                            container
                                                                        >
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    3
                                                                                }
                                                                                md={
                                                                                    3
                                                                                }
                                                                                sm={
                                                                                    3
                                                                                }
                                                                                className={
                                                                                    transferClasses.greyTitle
                                                                                }
                                                                            >
                                                                                Submitted
                                                                                by:
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    6
                                                                                }
                                                                                md={
                                                                                    6
                                                                                }
                                                                                sm={
                                                                                    6
                                                                                }
                                                                                className={
                                                                                    transferClasses.blackTitle
                                                                                }
                                                                            >
                                                                                {rowData[6]
                                                                                    .company_name
                                                                                    ? rowData[6]
                                                                                          .company_name
                                                                                    : ''}
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid
                                                                            container
                                                                        >
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    2
                                                                                }
                                                                                md={
                                                                                    2
                                                                                }
                                                                                sm={
                                                                                    2
                                                                                }
                                                                                className={
                                                                                    transferClasses.greyTitle
                                                                                }
                                                                            >
                                                                                {rowData[12] ===
                                                                                'approved'
                                                                                    ? 'Approved at:'
                                                                                    : rowData[12] ===
                                                                                      'rejected'
                                                                                    ? 'Rejected at:'
                                                                                    : ''}
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    6
                                                                                }
                                                                                md={
                                                                                    6
                                                                                }
                                                                                sm={
                                                                                    6
                                                                                }
                                                                                className={
                                                                                    transferClasses.blackTitle
                                                                                }
                                                                            >
                                                                                {rowData[12] !==
                                                                                    'pending' &&
                                                                                rowData[8]
                                                                                    ? rowData[8]
                                                                                    : ''}
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid
                                                                            container
                                                                        >
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    2
                                                                                }
                                                                                md={
                                                                                    2
                                                                                }
                                                                                sm={
                                                                                    2
                                                                                }
                                                                                className={
                                                                                    transferClasses.greyTitle
                                                                                }
                                                                            >
                                                                                Documents:
                                                                            </Grid>
                                                                        </Grid>
                                                                        {rowData[16].map(
                                                                            (
                                                                                document,
                                                                                i
                                                                            ) => (
                                                                                <div>
                                                                                    <a
                                                                                        href={
                                                                                            document.document
                                                                                        }
                                                                                        target="_blank"
                                                                                        rel="noreferrer"
                                                                                    >
                                                                                        {
                                                                                            document.document
                                                                                        }
                                                                                    </a>
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        },
                                                    }}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Formik>
            <Dialog
                maxWidth="md"
                fullWidth
                open={approveDialog}
                onBackdropClick={(e) => setApproveDialog(false)}
            >
                <DialogTitle className={classes.dialogTitle}>
                    Approve Transfer
                    <br />
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <Formik
                                initialValues={{}}
                                onSubmit={(values, { setSubmitting }) => {
                                    request
                                        .put(
                                            `${config.API_BASE_URL}/transfers/approve/` +
                                                transferId
                                        )
                                        .then((response) => {
                                            setApproveDialog(false);
                                            setIsReady(false);
                                            enqueueSnackbar(
                                                'Transfer has been approved successfully  .',
                                                {
                                                    variant: 'success',
                                                }
                                            );
                                            props.transferAgentActions.listTATransfers(
                                                transferStatus
                                            );
                                        });
                                }}
                            >
                                {({ isSubmitting }) => (
                                    <Form className={classes.form} noValidate>
                                        {parameterLoading ? (
                                            <CircularProgress size={24} />
                                        ) : verificationParameters.length >
                                          0 ? (
                                            <Grid>
                                                {verificationParameters.map(
                                                    (
                                                        verificationParameter,
                                                        i
                                                    ) => (
                                                        <Grid
                                                            container
                                                            item
                                                            xs={12}
                                                            sm={6}
                                                            md={6}
                                                        >
                                                            {' '}
                                                            <Grid
                                                                item
                                                                xs={2}
                                                                sm={1}
                                                                md={1}
                                                            >
                                                                <FormControlLabel
                                                                    label=""
                                                                    control={
                                                                        <Checkbox
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                handleParameterChange(
                                                                                    event.target,
                                                                                    verificationParameter
                                                                                )
                                                                            }
                                                                            key={
                                                                                i
                                                                            }
                                                                            checked={
                                                                                verificationParameter.verified
                                                                            }
                                                                            name={
                                                                                verificationParameter.id
                                                                            }
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={10}
                                                                sm={10}
                                                                md={10}
                                                                className={
                                                                    classes.checkboxLabel
                                                                }
                                                            >
                                                                {
                                                                    verificationParameter.name
                                                                }
                                                            </Grid>{' '}
                                                        </Grid>
                                                    )
                                                )}
                                            </Grid>
                                        ) : (
                                            <Grid>No data</Grid>
                                        )}

                                        <DialogActions
                                            className={classes.actions}
                                        >
                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    closeDialog();
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={
                                                    isSubmitting || !isReady
                                                }
                                            >
                                                {!isSubmitting ? (
                                                    <span>Approve</span>
                                                ) : (
                                                    <CircularProgress
                                                        size={24}
                                                    />
                                                )}
                                            </Button>
                                        </DialogActions>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};

const mapStateToProps = ({ profile, transfers }) => {
    return {
        profile: profile,
        transfers: transfers.data,
        loading: transfers.loading,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        transferAgentActions: bindActionCreators(
            transferAgentActions,
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transfers);
