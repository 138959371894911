import request from '../../utilities/request'
import * as config from '../../config'
import * as documentTypes from './documentTypes'

export const getDocumentsSuccess = (summary) => {
    return {
        type: documentTypes.GET_DOCUMENTS_SUCCESS,
        payload: summary
    }
}

export const setDocumentsLoading = (loading) => {
    return {
        type: documentTypes.SET_DOCUMENTS_LOADING,
        payload: loading
    }
}

export const getDocuments = () => {
    return (dispatch) => {
        dispatch(setDocumentsLoading(true))
        request
            .get(`${config.API_BASE_URL}/documents`)
            .then((res) => {
                dispatch(getDocumentsSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                dispatch(setDocumentsLoading(false))
            });
    };
};