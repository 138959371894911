/**
 * User scopes.
 */
export const SCOPE_SUPER_ADMIN = 'superadmin';
export const SCOPE_ADMIN = 'admin';
export const SCOPE_ISSUER = 'issuer';

/**
 * Account types.
 */
export const ACCOUNT_ADMIN = 'admin';
export const ACCOUNT_ISSUER = 'issuer';
export const ACCOUNT_TRANSFER_AGENT = 'transfer-agent';

/**
 * Subscription levels
 */
export const SUBSCRIPTION_BASIC = 'basic';
export const SUBSCRIPTION_PRO = 'pro';
export const SUBSCRIPTION_ENTERPRISE = 'enterprise';
