import { Badge, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import DataError from "../Utils/DataError";
import DataLoading from "../Utils/DataLoading";
import NoData from "../Utils/NoData";
import HelpTooltip from "../../../../components/HelpTooltip/HelpTooltip";
import RefreshIcon from '@mui/icons-material/Refresh';
import { useEffect, useState } from "react";
import useGreenWalletTransactions from "../../../../api/blockchain/useGreenWalletTransactions";
import { BLOCKSTREAM_EXPLORER_URL } from "../../../../config";
import Humanize from 'humanize-plus';

const TransactionLog = ({transaction}) => {

    const openTransaction = () => {
        window.open(
            `${BLOCKSTREAM_EXPLORER_URL}/tx/${transaction.txhash}`,
            '_blank'
        )
    }

    return (
        <Stack direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={4}
        onClick={openTransaction}
        sx={{ marginTop: '20px', position: 'relative', cursor: 'pointer', padding: '10px', backgroundColor: '#fafafa' }}>
            <Badge sx={{ position: "absolute", top: "0px", right: "20px"}} badgeContent="Pending" color="primary" invisible={!transaction.pending}></Badge>
            <Stack alignItems="center" direction="row" gap={1}>
                <small>{transaction.created_at}</small>
                <span>{ transaction.type_name }</span>
            </Stack>

            <Stack alignItems="center" direction="row" gap={1} sx={{color: transaction.type === 'IN' ? 'green' : 'inherit'}}>
                <span>{Humanize.formatNumber(transaction.amount, transaction.asset?.precision ?? 0)}</span>
                <span>{ transaction.asset?.ticker }</span>
            </Stack>
        </Stack>
    );
}

const SubaccountTransactionsDialog = ({ subaccount, open, handleClose }) => {
    
    const [ transactions, setTransactions ] = useState([]);

    const {
        greenWalletTransactions,
        greenWalletTransactionsLoading,
        greenWalletTransactionsError,
        updateGreenWalletTransactions
    } = useGreenWalletTransactions(subaccount?.investor_id, subaccount?.amp_user_id);

    const [ loading, setLoading ] = useState(greenWalletTransactionsLoading);

    useEffect(() => {
        setTransactions(greenWalletTransactions?.map(transaction => {
            const amount = transaction.asset.precision ? transaction.amount * Math.pow(10, -transaction.asset.precision) : transaction.amount;
            return {
                ...transaction,
                amount: transaction.type === 'OUT' ? amount * -1 : amount
            };
        }))
    }, [greenWalletTransactions]);

    useEffect(() => {
        setLoading(greenWalletTransactionsLoading);
    }, [greenWalletTransactionsLoading]);

    const refresh = async () => {
        setLoading(true);
        try {
            await updateGreenWalletTransactions();
        } finally {
            setLoading(false);
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg">
            <DialogTitle>
                { subaccount.account_name } Transactions 
                <HelpTooltip
                    title={
                        <>
                            This table shows the{' '}
                            <b>transactions</b> of
                            the subaccount
                        </>
                    }
                />
            </DialogTitle>
            <DialogContent>
                {/* Loading subaccount transaction */}
                <DataLoading
                    loading={loading}
                    text="Loading subaccount transactions..."
                />
                {
                    !loading && (
                        <Stack alignItems="end" justifyContent="end">
                            <Button variant="contained" color='secondary' startIcon={<RefreshIcon />} sx={{ marginRight: '10px', float: 'right' }} onClick={() => refresh()}>
                                Refresh transaction
                            </Button>
                        </Stack>
                    )
                }
                {!loading && greenWalletTransactions && transactions?.map(transaction => (
                    <TransactionLog 
                        transaction={transaction}
                        key={transaction.txhash}
                    />
                ))}
                <br />
                {/* No data */}
                <NoData
                    show={
                        !loading &&
                        !greenWalletTransactionsError &&
                        greenWalletTransactions.length === 0
                    }
                    text="This subaccount has no transactions."
                />

                {/* AMP asset error */}
                <DataError
                    show={
                        greenWalletTransactionsError &&
                        (!greenWalletTransactions || greenWalletTransactions.length === 0)
                    }
                    text="Could not load subaccount transactions due to error. Please try again."
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default SubaccountTransactionsDialog;