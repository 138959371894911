import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import BCTable from "../Utils/BCTable";
import DataError from "../Utils/DataError";
import DataLoading from "../Utils/DataLoading";
import NoData from "../Utils/NoData";
import HelpTooltip from "../../../../components/HelpTooltip/HelpTooltip";
import RefreshIcon from '@mui/icons-material/Refresh';
import { useEffect, useState } from "react";
import useGreenWalletAssets from "../../../../api/blockchain/useGreenWalletAssets";

const SubaccountAssetsDialog = ({ subaccount, open, handleClose }) => {
    
    const [ assets, setAssets ] = useState([]);

    const {
        greenWalletAssets,
        greenWalletAssetsLoading,
        greenWalletAssetsError,
        updateGreenWalletAssets
    } = useGreenWalletAssets(subaccount?.investor_id, subaccount?.amp_user_id);

    const [ loading, setLoading ] = useState(greenWalletAssetsLoading);

    useEffect(() => {

        setAssets(greenWalletAssets?.map(asset => ({
            ...asset,
            balance: asset.precision ? asset.balance * Math.pow(10, -asset.precision) : asset.balance
        })))
    }, [greenWalletAssets]);

    useEffect(() => {
        setLoading(greenWalletAssetsLoading);
    }, [greenWalletAssetsLoading]);

    const refresh = async () => {
        setLoading(true);
        try {
            await updateGreenWalletAssets();
        } finally {
            setLoading(false);
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg">
            <DialogTitle>
                { subaccount.account_name } Assets 
                <HelpTooltip
                    title={
                        <>
                            This table shows the{' '}
                            <b>confirmed blockchain balance</b> of
                            the holder
                        </>
                    }
                />
            </DialogTitle>
            <DialogContent>
                {/* Loading subaccount assets */}
                <DataLoading
                    loading={loading}
                    text="Loading subaccount assets..."
                />
                {
                    !loading && (
                        <Button variant="contained" color='secondary' startIcon={<RefreshIcon />} sx={{ marginRight: '10px', float: 'right' }} onClick={() => refresh()}>
                            Refresh assets
                        </Button>
                    )
                }
                {!loading && greenWalletAssets && (
                    <BCTable
                        columns={[
                            {
                                name: 'name',
                                label: 'Asset',
                                bold: true,
                                render: (row) => (<Stack direction="row" alignItems="center" gap={3}>
                                    { row.icon && <img alt={row.name} width={24} height={24} src={`data:image/png;base64,${row.icon}`} /> }
                                    <p>{row.name}</p>
                                </Stack>)
                            },
                            {
                                name: 'balance',
                                label: 'Balance',
                                type: 'amount',
                            },
                        ]}
                        data={assets}
                    />
                )}
                <br />
                {/* No data */}
                <NoData
                    show={
                        !loading &&
                        !greenWalletAssetsError &&
                        greenWalletAssets.length === 0
                    }
                    text="This subaccount has no assets."
                />

                {/* AMP asset error */}
                <DataError
                    show={
                        greenWalletAssetsError &&
                        (!greenWalletAssets || greenWalletAssets.length === 0)
                    }
                    text="Could not load subaccount assets due to error. Please try again."
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default SubaccountAssetsDialog;