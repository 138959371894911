import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useSnackbar } from 'notistack';
import * as config from '../../config';
import request from '../../utilities/request';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DataTable from '../../components/DataTable/MuiDataTable';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import * as transferAgentActions from '../../store/transferAgent/transferAgentActions';
import authStyles from '../issuer/authStyles';
import TextField from '../../components/TextField/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import Select from '@mui/material/Select';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
const ManageAccounts = (props) => {
    const classes = authStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [addUserDialog, setAddUserDialog] = useState(false);
    const [deleteUserId, setDeleteUserId] = useState(null);
    const [scope, setScope] = useState('transfer-agent-admin');
    const [password, setPassword] = useState(null);
    useEffect(() => props.transferAgentActions.listAccountUsers(), [
        props.transferAgentActions,
    ]);

    const displayAddUserDialog = () => {
        setPassword((Math.random() + 1).toString(36).substring(7));
        setAddUserDialog(true);
    };

    const closeAddUserDialog = () => {
        setAddUserDialog(false);
    };

    const displayDeleteUserDialog = (id) => {
        setDeleteUserId(id);
        setDeleteDialog(true);
    };
    const closeDeleteUserDialog = () => {
        setDeleteDialog(false);
    };

    return (
        <Grid container spacing={2}>
            {props.profile && props.profile.scope === 'transfer-agent-owner' && (
                <Grid container className={classes.alignEnd}>
                    <div>
                        <Button
                            component="label"
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.alignEnd}
                            onClick={() => displayAddUserDialog()}
                        >
                            <AddIcon />
                            New User
                        </Button>
                    </div>
                </Grid>
            )}
            <Grid item xs={12}>
                {props.loading ? (
                    <Grid container item xs={12} justifyContent="center">
                        <CircularProgress />
                    </Grid>
                ) : (
                    <DataTable
                        title={'Account Users'}
                        data={
                            props.accountUsers && props.accountUsers.data
                                ? props.accountUsers.data
                                : []
                        }
                        columns={[
                            {
                                label: 'Name',
                                name: 'name',
                            },
                            {
                                label: 'Email',
                                name: 'email',
                            },
                            {
                                label: 'Status',
                                name: 'status',
                                options: {
                                    customBodyRender: (value) => (
                                        <span>{value.toUpperCase()}</span>
                                    ),
                                },
                            },
                            {
                                label: 'Scope',
                                name: 'scope',
                                options: {
                                    customBodyRender: (value) => (
                                        <span>{value.toUpperCase()}</span>
                                    ),
                                },
                            },
                            {
                                label: 'Remove',
                                name: 'id',
                                options: {
                                    download: false,
                                    print: false,
                                    customBodyRenderLite: (dataIndex) => {
                                        const row =
                                            props.accountUsers.data[dataIndex];
                                        return (
                                            <React.Fragment>
                                                <Tooltip title="Delete">
                                                    <IconButton
                                                        variant="contained"
                                                        color="danger"
                                                        size="small"
                                                        className="m-r-5"
                                                        disabled={
                                                            row &&
                                                            row.scope ===
                                                                'transfer-agent-owner'
                                                        }
                                                        onClick={() => {
                                                            displayDeleteUserDialog(
                                                                row.id
                                                            );
                                                        }}
                                                    >
                                                        <DeleteForeverIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </React.Fragment>
                                        );
                                    },
                                },
                            },
                        ]}
                    />
                )}
            </Grid>
            <Dialog maxWidth="sm" fullWidth open={deleteDialog}>
                <DialogTitle className={classes.dialogTitle}>
                    Delete User
                </DialogTitle>
                <DialogContent>
                    <Typography component="h2" style={{ color: '#ED7571' }}>
                        <b> Are you sure that you want to delete this user?</b>
                    </Typography>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={12}>
                            <Formik
                                initialValues={{ confirm_deletion: null }}
                                onSubmit={(values, { setSubmitting }) => {
                                    let confirm_deletion = '';
                                    if (
                                        values['confirm_deletion'] !== null &&
                                        values['confirm_deletion'] !== undefined
                                    ) {
                                        confirm_deletion = values[
                                            'confirm_deletion'
                                        ].toLowerCase();
                                    }

                                    if (confirm_deletion === 'delete') {
                                        request
                                            .patch(
                                                `${config.API_BASE_URL}/transfer-agent/account-users/delete/${deleteUserId}`
                                            )
                                            .then((response) => {
                                                enqueueSnackbar(
                                                    'User has been deleted successfully',
                                                    {
                                                        variant: 'success',
                                                    }
                                                );
                                                setDeleteDialog(false);
                                                props.transferAgentActions.listAccountUsers();
                                            })
                                            .catch(() => {
                                                enqueueSnackbar(
                                                    'Error occured while deleting user',
                                                    {
                                                        variant: 'error',
                                                    }
                                                );
                                            });
                                    } else {
                                        enqueueSnackbar(
                                            'Please type "delete" in the box to confirm request',
                                            {
                                                variant: 'error',
                                            }
                                        );
                                    }
                                }}
                            >
                                {({ isSubmitting }) => (
                                    <Container>
                                        <Form
                                            className={classes.form}
                                            noValidate
                                        >
                                            <Field
                                                component={TextField}
                                                type="text"
                                                name="confirm_deletion"
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                label={
                                                    <span>
                                                        Type{' '}
                                                        <b className="ihq-red">
                                                            delete
                                                        </b>{' '}
                                                        in the box to confirm
                                                        your request
                                                    </span>
                                                }
                                                fullWidth
                                            />
                                            <DialogActions
                                                className={classes.actions}
                                            >
                                                <Button
                                                    color="primary"
                                                    onClick={() => {
                                                        closeDeleteUserDialog();
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                >
                                                    Delete
                                                </Button>
                                            </DialogActions>
                                        </Form>
                                    </Container>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog
                maxWidth="sm"
                fullWidth
                open={addUserDialog}
                onBackdropClick={(e) => setAddUserDialog(false)}
            >
                <DialogTitle className={classes.dialogTitle}>
                    New User
                    <br />
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <Formik
                                initialValues={{
                                    scope: 'transfer-agent-admin',
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                    values['scope'] = scope;
                                    values['password'] = password;
                                    request
                                        .post(
                                            `${config.API_BASE_URL}/transfer-agent/account-users`,
                                            values
                                        )
                                        .then((response) => {
                                            props.transferAgentActions.listAccountUsers();
                                            enqueueSnackbar(
                                                'User has been added successfully',
                                                {
                                                    variant: 'success',
                                                }
                                            );
                                            closeAddUserDialog();
                                        })
                                        .catch((error) => {
                                            closeAddUserDialog();
                                            enqueueSnackbar(
                                                'Error occured while adding user',
                                                {
                                                    variant: 'error',
                                                }
                                            );

                                            setSubmitting(false);
                                        });
                                }}
                            >
                                {({ values, isSubmitting }) => (
                                    <Form className={classes.form} noValidate>
                                        <Grid>
                                            <Grid container spacing={1}>
                                                <InputLabel
                                                    className={classes.label}
                                                    htmlFor="scope"
                                                >
                                                    Select type{' '}
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={scope}
                                                    onChange={(e) =>
                                                        setScope(e.target.value)
                                                    }
                                                    label={'Select type'}
                                                    fullWidth
                                                >
                                                    <MenuItem
                                                        value={
                                                            'transfer-agent-admin'
                                                        }
                                                    >
                                                        Admin
                                                    </MenuItem>
                                                    <MenuItem
                                                        value={
                                                            'transfer-agent-owner'
                                                        }
                                                    >
                                                        Owner
                                                    </MenuItem>
                                                    )
                                                </Select>
                                            </Grid>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Name"
                                                name={'name'}
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                            />
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Email"
                                                name={'email'}
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                            />
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Password"
                                                name={'password'}
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                readOnly
                                                value={password}
                                            />
                                        </Grid>

                                        <DialogActions
                                            className={classes.actions}
                                        >
                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    closeAddUserDialog();
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={isSubmitting}
                                            >
                                                {!isSubmitting ? (
                                                    <span>Save</span>
                                                ) : (
                                                    <CircularProgress
                                                        size={24}
                                                    />
                                                )}
                                            </Button>
                                        </DialogActions>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Grid>
    );
};

const mapStateToProps = ({ profile, transferAgent }) => {
    return {
        profile: profile,
        accountUsers: transferAgent.accountUsers.data,
        loading: transferAgent.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        transferAgentActions: bindActionCreators(
            transferAgentActions,
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAccounts);
