import React from 'react';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Grid from "@mui/material/Grid";

const RemoveDocumentButton = (props) => {
    const { onClick } = props;

    return (
        <Grid
            item
            xs={3}
            sm={3}
            md={3}
            justify="flex-end"
        >
            <Tooltip title="Remove">
                <IconButton
                    variant="contained"
                    color="primary"
                    size="small"
                    className="m-r-5"
                    onClick={onClick}
                >
                    <DeleteForeverIcon />
                </IconButton>
            </Tooltip>
        </Grid>
    );
};

export default RemoveDocumentButton;
