import request from '../../utilities/request';
import * as config from '../../config';
import * as listTemplateTypes from './listTemplateTypes';

export const setListTemplateLoading = (loading) => {
    return {
        type: listTemplateTypes.SET_LIST_TEMPLATE_LOADING,
        payload: loading,
    };
};

export const getTemplateDataSuccess = (data) => {
    return {
        type: listTemplateTypes.GET_TEMPLATE_DATA_SUCCESS,
        payload: data,
    };
};

export const getTemplateData = () => {
    return (dispatch) => {
        dispatch(setListTemplateLoading(true));
        request
            .get(`${config.API_BASE_URL}/templates/data`)
            .then((res) => {
                dispatch(getTemplateDataSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setListTemplateLoading(false));
            });
    };
};
