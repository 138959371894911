import React from 'react'
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const ActionButton = (props) => {
    const { loading, loadingSize, children, ...rest } = props

    return (
        <Button {...rest}>
            {
                loading ? <CircularProgress size={loadingSize || 24} /> : children
            }
        </Button>
    )
}

ActionButton.propTypes = {
    ...Button.propTypes,
    loading: PropTypes.bool,
    loadingSize: PropTypes.number,
}

export default ActionButton
