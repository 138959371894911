import * as disclosureRequirementTypes from './disclosureRequirementTypes';

const initialState = {
    data: null,
    loading: true,
};

const disclosureRequirementReducer = (state = initialState, action) => {
    switch (action.type) {
        case disclosureRequirementTypes.GET_DISCLOSURE_REQUIREMENTS_SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case disclosureRequirementTypes.SET_DISCLOSURE_REQUIREMENTS_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        default:
            return state;
    }
};

export default disclosureRequirementReducer;
