import useSWR from "swr"
import { API_BASE_URL } from "../../config"
import fetcher from "../../utilities/fetcher"

const useAmpAssets = () => {
    const { data, error, isValidating, mutate } = useSWR(`${API_BASE_URL}/blockchain/assets`, fetcher)

    return {
        ampAssets: data,
        ampAssetsLoading: !error && !data,
        ampAssetsValidating: isValidating,
        ampAssetsError: error,
        updateAmpAssets: () => mutate()
    }
}

export default useAmpAssets