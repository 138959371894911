import makeStyles from '@mui/styles/makeStyles';

const authStyles = makeStyles((theme) => ({
    container: {
        maxWidth: '27rem',
        alignItems: 'center',
        marginTop: '10rem',
    },
    paper: {
        //marginTop: '1.25rem',
        padding: '0.7rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    brand: {
        fontSize: '2.5rem',
    },
    logo: {
        display: 'block',
        margin: 'auto',
        width: '80%',
    },
    ihqLogo: {
        display: 'block',
        margin: 'auto',
        width: '50%',
    },
    title: {
        marginBottom: '2rem',
        fontSize: '2rem',
        textAlign: 'center',
    },
    note: {
        fontSize: '0.9rem',
    },
    form: {
        marginTop: '1rem',
    },
    actions: {
        marginTop: '1.5rem',
    },
    button: {
        //padding: '0.75rem 1.5rem',
        marginRight: '1rem',
        marginBottom: '1rem',
        //minWidth: '7rem',
        fontSize: '1.1rem',
        textTransform: 'none',
    },
    additionalButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    link: {
        display: 'block',
        fontSize: '1rem',
        textAlign: 'left',
        textDecoration: 'none',
        marginTop: '0.5rem',
        marginBottom: '1rem',
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.primary.main,
        },
        fontWeight: '700',
    },
    checkbox: {
        fontSize: '1rem',
        '& > a': {
            textDecoration: 'none',
            color: theme.palette.secondary.main,
            fontWeight: '500',
            marginLeft: '0.25rem',
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
    },
    table: {
        minWidth: 100,
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paperSmall: {
        borderRadius: 15,
        padding: 10,
        height: '100%',
    },
    buttonLabel: {
        color: '#3E9CD9',
        cursor: 'pointer',
        textTransform: 'none',
        fontSize: '1rem',
        marginRight: '10px',
        marginBottom: '1rem',
        borderRightColor: '#3E9CD9 !important',
        '&:hover': {
            backgroundColor: '#3E9CD9',
            color: 'white',
        },
        '&:disabled': {
            borderRightColor: '#BDC9CC !important',
        },
    },
    secondaryButtonLabel: {
        color: '#000000',
        cursor: 'pointer',
        marginBottom: '1rem',
        textTransform: 'none',
        fontSize: '1rem',
        //    marginRight: '10px',
        borderRightColor: '#000000  !important',
        '&:hover': {
            backgroundColor: '#000000',
            color: 'white',
        },
    },
    subscriptionTypeTitleDescription: {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '28px',
        alignItems: 'center',
        textAlign: 'center',
        letterSpacing: '-0.03em',
        color: '#333333',
        marginBottom: '1rem',
        marginTop: '-1rem',
    },
    subscriptionTypeTitle: {
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '13px',
        lineHeight: '19px',
        textAlign: 'center',
        letterSpacing: '-0.03em',
        textTransform: 'uppercase',
        color: '#333333',
    },
    subscriptionTypeDescription: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '13px',
        lineHeight: '19px',
        textAlign: 'center',
        letterSpacing: '-0.03em',
        color: '#333333',
    },
    subscriptionTypeFooter: {
        fontSize: '12px',
        textAlign: 'center',
        fontStyle: 'italic',
        marginTop: '1.5rem',
    },
    inputLabel: {
        color: '#666',
        fontSize: '0.8rem',
        marginBottom: '0.3rem',
        marginTop: '0.3rem',
    },
    errorMessage: {
        fontSize: '10px',
        color: '#ED7571',
    },
    conditionChecked: {
        fontSize: '15px',
        color: '#66DC7E',
    },
    conditionPending: {
        fontSize: '15px',
        color: '#FFFFFF',
    },
}));

export default authStyles;
