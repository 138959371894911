import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Container from '@mui/material/Container';
import { useSnackbar } from 'notistack';
import authStyles from '../../authStyles';
import fundraiseOfferingStyles from '../../../../components/styles/fundraiseOfferingStyles';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '../../../../components/TextField/TextField';
import Grid from '@mui/material/Grid';
import { CheckboxWithLabel } from 'formik-mui';
import FundButtonGroup from '../FundButtonGroup';
const validationSchema = Yup.object({
    household_income_last_year: Yup.string().required(
        'Please enter annual household income — last year'
    ),
    household_income_current_year: Yup.string().required(
        'Please enter annual household income — est. current year'
    ),
    annual_average_expenses: Yup.string().required(
        'Please enter annual living average expenses'
    ),
    net_worth: Yup.string().required('Please enter net worth'),
    liquidity: Yup.string().required('Please enter liquidity'),
    private_assets_estimated_percentage: Yup.string().required(
        'Please enter estimated percentage of my/our assets in private (non-exchange listed) companies'
    ),
});
const InvestorSuitability = (props) => {
    const classes = authStyles();
    const fundraiseOfferingClasses = fundraiseOfferingStyles();
    const { enqueueSnackbar } = useSnackbar();
    let [refreshForm, setRefreshForm] = useState(0);
    const [understandInvestment, setUnderstandInvestment] = useState(
        props.initialValues.understand_investment
    );
    const [
        certifyAccurateInformation,
        setCertifyAccurateInformation,
    ] = useState(props.initialValues.certify_accurate_information);

    const [largeExpensesPlanned, setLargeExpensesPlanned] = useState(
        props.initialValues.large_expenses_planned
            ? props.initialValues.large_expenses_planned === true
                ? 'yes'
                : 'no'
            : 'no'
    );

    const [investingExperience, setInvestingExperience] = useState(
        props.initialValues.investing_experience
            ? props.initialValues.investing_experience
            : '<3'
    );

    const [
        cummulativeAmountOfInvestments,
        setCummulativeAmountOfInvestments,
    ] = useState(
        props.initialValues.cummulative_amount_of_investments
            ? props.initialValues.cummulative_amount_of_investments
            : '<500000'
    );

    const [currentInvestmentValue, setCurrentInvestmentValue] = useState(
        props.initialValues.current_investment_value
            ? props.initialValues.current_investment_value
            : '<20'
    );

    const [riskTolerance, setRiskTolerance] = useState(
        props.initialValues.risk_tolerance
            ? props.initialValues.risk_tolerance
            : 'very_low'
    );

    const [primaryFundSource, setPrimaryFundSource] = useState(
        props.initialValues.primary_funds_source
            ? props.initialValues.primary_funds_source
            : 'savings'
    );

    let invested_company_types = [
        { name: 'Businesses — US', value: 'us_businesss' },
        { name: 'Businesses — International', value: 'us_international' },
        { name: 'Real Estate', value: 'real_estate' },
        { name: 'Limited Partnerships', value: 'limited_partnership' },
        { name: 'Oil and Gas', value: 'oil_gas' },
        { name: 'Other', value: 'other' },
    ];

    let investment_goals = [
        {
            name: 'Preservation of Capital',
            value: 'preservation_of_capital',
            description:
                "The money I invest in private securities needs to be safe. Even if the anticipated returns might be risky I don't want the original money I invested to have any risk.",
        },
        {
            name: 'Income',
            value: 'income',
            description:
                "I'd like to invest in companies which promise to provide a stream of cash (e.g. interest, revenue participation, etc).",
        },
        {
            name: 'Growth',
            value: 'growth',
            description:
                "I'd like to invest in companies where my investment can grow over time and become worth more before some exit event.",
        },
        {
            name: 'Speculation',
            value: 'speculation',
            description:
                "I'm interested in investments with the highest return potential, which might be income or growth oriented.",
        },
    ];

    let risk_tolerances = [
        {
            name: 'Very Low',
            value: 'very_low',
            description: "I can't afford to lose a dime.",
        },
        {
            name: 'Low',
            value: 'low',
            description: "I can lose a little, but it'd hurt.",
        },
        {
            name: 'Medium',
            value: 'medium',
            description:
                "I'm okay if I lose some of my investment, but certainly not all of it.",
        },
        {
            name: 'High',
            value: 'high',
            description:
                "I understand and accept that I may lose some, and maybe even all, of my money, but feel the potential rewards outweigh the risks, and regardless, it won't hurt me if I never get anything back as I'll be careful to limit how much of my money I invest here.",
        },
        {
            name: 'Very High',
            value: 'very_high',
            description:
                "I plan to swing for the fences and don't care at all if this risk part of my portfolio works out or not. The money I invest here has been allocated for this and won't affect me in the slightest if I lose it all.",
        },
    ];

    let primary_funds_sources = [
        { name: 'Savings', value: 'savings' },
        {
            name: 'Proceeds from selling other securities',
            value: 'selling_other_securities',
        },
        { name: 'Income', value: 'income' },
        { name: 'Loan', value: 'load' },
        {
            name: 'Proceeds from the sale of a business',
            value: 'sale_of_business',
        },
        { name: 'Gift', value: 'gift' },
        { name: 'Inheritance', value: 'inheritance' },
        { name: 'Social Security Benefits', value: 'social_security_benefits' },
        { name: 'Insurance payout', value: 'insurance_payout' },
        { name: 'Other', value: 'other' },
    ];

    const nextStep = () => {
        props.nextStep();
    };

    const previousStep = () => {
        props.previousStep();
    };

    const closeDialog = () => {
        props.closeDialog();
    };

    const changeLargeExpensesPlanned = (e) => {
        setLargeExpensesPlanned(e.target.value);
    };

    const changeInvestingExperience = (e) => {
        setInvestingExperience(e.target.value);
    };

    const changeCummulativeAmountOfInvestments = (e) => {
        setCummulativeAmountOfInvestments(e.target.value);
    };
    const changeCurrentInvestmentValue = (e) => {
        setCurrentInvestmentValue(e.target.value);
    };

    const changeRiskTolerance = (e) => {
        setRiskTolerance(e.target.value);
    };

    const changePrimaryFundSource = (e) => {
        setPrimaryFundSource(e.target.value);
    };
    return (
        <div id="step3">
            <Formik
                key={refreshForm}
                initialValues={props.initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    if (
                        values['understand_investment'] === false ||
                        values['certify_accurate_information'] === false
                    ) {
                        if (largeExpensesPlanned === 'yes') {
                            values['large_expenses_planned'] = true;
                        } else {
                            values['large_expenses_planned'] = false;
                        }

                        values[
                            'cummulative_amount_of_investments'
                        ] = cummulativeAmountOfInvestments;
                        values[
                            'current_investment_value'
                        ] = currentInvestmentValue;
                        values['risk_tolerance'] = riskTolerance;
                        values['primary_funds_source'] = primaryFundSource;
                        enqueueSnackbar('Please certify statements above', {
                            variant: 'error',
                        });
                        refreshForm++;
                        setRefreshForm(refreshForm);
                    } else {
                        if (largeExpensesPlanned === 'yes') {
                            values['large_expenses_planned'] = true;
                        } else {
                            values['large_expenses_planned'] = false;
                        }

                        values[
                            'cummulative_amount_of_investments'
                        ] = cummulativeAmountOfInvestments;
                        values[
                            'current_investment_value'
                        ] = currentInvestmentValue;
                        values['risk_tolerance'] = riskTolerance;
                        values['primary_funds_source'] = primaryFundSource;

                        props.saveValues(values);
                        nextStep();
                        props.changeKey();
                    }
                }}
            >
                {({ setFieldValue }) => (
                    <Container maxWidth="lg">
                        <Form className={classes.form} noValidate>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Annual Household Income — last year"
                                        name="household_income_last_year"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        numeric={1}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Annual Household Income — est. current year"
                                        name="household_income_current_year"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        numeric={1}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Annual Average Living Expenses"
                                        name="annual_average_expenses"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        numeric={1}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Net Worth"
                                        name="net_worth"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        numeric={1}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Liquidity (cash, assets easily convertible into cash and marketable securities, minus current expenses due and payable)"
                                        name="liquidity"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        numeric={1}
                                    />
                                </Grid>
                            </Grid>
                            <hr
                                className={
                                    fundraiseOfferingClasses.horizontalLine
                                }
                            />
                            <Typography component="div">
                                <b>
                                    Do you have any planned, large expenses in
                                    the future (greater than 50% of your average
                                    annual income)?
                                </b>
                            </Typography>
                            <RadioGroup
                                name="large_expenses_planned"
                                row
                                required
                                defaultValue={largeExpensesPlanned}
                            >
                                <FormControlLabel
                                    value={'yes'}
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                    labelPlacement="end"
                                    onChange={changeLargeExpensesPlanned}
                                />
                                <FormControlLabel
                                    value={'no'}
                                    control={<Radio color="primary" />}
                                    label="No"
                                    labelPlacement="end"
                                    onChange={changeLargeExpensesPlanned}
                                />
                            </RadioGroup>
                            <hr
                                className={
                                    fundraiseOfferingClasses.horizontalLine
                                }
                            />
                            <Typography component="div">
                                <b>
                                    I/We have the following experience investing
                                    in privately held (non-exchange traded)
                                    companies:
                                </b>
                            </Typography>
                            <RadioGroup
                                name="investing_experience"
                                row
                                required
                                defaultValue={investingExperience}
                            >
                                <FormControlLabel
                                    value={'<3'}
                                    control={<Radio color="primary" />}
                                    label="Less than 3 years"
                                    labelPlacement="end"
                                    onChange={changeInvestingExperience}
                                />
                                <FormControlLabel
                                    value={'>=3'}
                                    control={<Radio color="primary" />}
                                    label="3 Years or More"
                                    labelPlacement="end"
                                    onChange={changeInvestingExperience}
                                />
                            </RadioGroup>
                            <hr
                                className={
                                    fundraiseOfferingClasses.horizontalLine
                                }
                            />
                            <Typography component="div">
                                <b>
                                    Type(s) of private companies or securities
                                    in which I/we have invested (check all that
                                    apply):
                                </b>
                            </Typography>
                            <Grid container spacing={1}>
                                {invested_company_types.map(
                                    (invested_company_type) => (
                                        <Grid>
                                            <Field
                                                component={CheckboxWithLabel}
                                                type="checkbox"
                                                name={
                                                    invested_company_type.value
                                                }
                                                Label={{
                                                    label: (
                                                        <div
                                                            className={
                                                                classes.checkbox
                                                            }
                                                        >
                                                            {
                                                                invested_company_type.name
                                                            }
                                                        </div>
                                                    ),
                                                }}
                                                required
                                            />
                                            <br /> <br />
                                        </Grid>
                                    )
                                )}
                            </Grid>
                            <br />
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Field
                                        component={TextField}
                                        name="invested_company_types_comments"
                                        type="text"
                                        label="Comments"
                                        rows={3}
                                        multiline
                                        fullWidth
                                        placeholder={'optional'}
                                    />
                                </Grid>
                            </Grid>
                            <hr
                                className={
                                    fundraiseOfferingClasses.horizontalLine
                                }
                            />
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Estimated percentage of my/our assets in private (non-exchange listed) companies"
                                        name="private_assets_estimated_percentage"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        numeric={1}
                                    />
                                </Grid>
                            </Grid>
                            <hr
                                className={
                                    fundraiseOfferingClasses.horizontalLine
                                }
                            />
                            <Typography component="div">
                                <b>
                                    I/We have the following experience investing
                                    in privately held (non-exchange traded)
                                    companies:
                                </b>
                            </Typography>
                            <RadioGroup
                                name="cummulative_amount_of_investments"
                                row
                                required
                                defaultValue={cummulativeAmountOfInvestments}
                            >
                                <FormControlLabel
                                    value={'<500000'}
                                    control={<Radio color="primary" />}
                                    label="Less than $500,000 USD"
                                    labelPlacement="end"
                                    onChange={
                                        changeCummulativeAmountOfInvestments
                                    }
                                />
                                <FormControlLabel
                                    value={'>500000'}
                                    control={<Radio color="primary" />}
                                    label="Greater than $500,000 USD"
                                    labelPlacement="end"
                                    onChange={
                                        changeCummulativeAmountOfInvestments
                                    }
                                />
                            </RadioGroup>
                            <hr
                                className={
                                    fundraiseOfferingClasses.horizontalLine
                                }
                            />
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography component="div">
                                        <b>
                                            I/We have the following experience
                                            investing in privately held
                                            (non-exchange traded) companies:
                                        </b>
                                    </Typography>
                                    <RadioGroup
                                        name="current_investment_value"
                                        row
                                        required
                                        defaultValue={currentInvestmentValue}
                                    >
                                        <FormControlLabel
                                            value={'<20'}
                                            control={<Radio color="primary" />}
                                            label="Less than 20%"
                                            labelPlacement="end"
                                            onChange={
                                                changeCurrentInvestmentValue
                                            }
                                        />
                                        <FormControlLabel
                                            value={'20-33'}
                                            control={<Radio color="primary" />}
                                            label="20% - 33%"
                                            labelPlacement="end"
                                            onChange={
                                                changeCurrentInvestmentValue
                                            }
                                        />
                                        <FormControlLabel
                                            value={'>33'}
                                            control={<Radio color="primary" />}
                                            label="Greater than 33%"
                                            labelPlacement="end"
                                            onChange={
                                                changeCurrentInvestmentValue
                                            }
                                        />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Field
                                        component={TextField}
                                        name="current_investment_value_comments"
                                        type="text"
                                        label="Comments"
                                        rows={3}
                                        multiline
                                        fullWidth
                                        placeholder={'optional'}
                                    />
                                </Grid>
                            </Grid>
                            <hr
                                className={
                                    fundraiseOfferingClasses.horizontalLine
                                }
                            />
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography component="div">
                                        <b>
                                            Primary Source of Funds for this
                                            investment:
                                        </b>
                                    </Typography>
                                    <RadioGroup
                                        name="risk_tolerance"
                                        onChange={changePrimaryFundSource}
                                        required
                                        row
                                        defaultValue={primaryFundSource}
                                    >
                                        {primary_funds_sources.map(
                                            (primary_funds_source) => (
                                                <FormControlLabel
                                                    value={
                                                        primary_funds_source.value
                                                    }
                                                    control={
                                                        <Radio color="primary" />
                                                    }
                                                    label={
                                                        primary_funds_source.name
                                                    }
                                                    labelPlacement="end"
                                                />
                                            )
                                        )}
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            <hr
                                className={
                                    fundraiseOfferingClasses.horizontalLine
                                }
                            />
                            <Typography component="div">
                                <b>My investment goals are:</b>
                            </Typography>{' '}
                            <br /> <br />
                            <Grid container spacing={1}>
                                {investment_goals.map((investment_goal) => (
                                    <Grid xs={12}>
                                        <Field
                                            component={CheckboxWithLabel}
                                            type="checkbox"
                                            name={investment_goal.value}
                                            Label={{
                                                label: (
                                                    <div>
                                                        <b>
                                                            {
                                                                investment_goal.name
                                                            }
                                                        </b>
                                                        : &nbsp;
                                                        {
                                                            investment_goal.description
                                                        }
                                                    </div>
                                                ),
                                            }}
                                            required
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            <hr
                                className={
                                    fundraiseOfferingClasses.horizontalLine
                                }
                            />
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography component="div">
                                        <b>
                                            My risk tolerance for money I
                                            invest:
                                        </b>
                                    </Typography>
                                    <RadioGroup
                                        name="risk_tolerance"
                                        onChange={changeRiskTolerance}
                                        required
                                        defaultValue={riskTolerance}
                                    >
                                        {risk_tolerances.map(
                                            (risk_tolerance) => (
                                                <FormControlLabel
                                                    value={risk_tolerance.value}
                                                    control={
                                                        <Radio color="primary" />
                                                    }
                                                    label={
                                                        <div>
                                                            <b>
                                                                {
                                                                    risk_tolerance.name
                                                                }
                                                            </b>
                                                            : &nbsp;
                                                            {
                                                                risk_tolerance.description
                                                            }
                                                        </div>
                                                    }
                                                    labelPlacement="end"
                                                />
                                            )
                                        )}
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            <hr
                                className={
                                    fundraiseOfferingClasses.horizontalLine
                                }
                            />
                            <Typography component="div">
                                <b>I certify that:</b>
                                <br />
                                <br />
                            </Typography>
                            <Grid container spacing={1}>
                                <Field
                                    component={CheckboxWithLabel}
                                    type="checkbox"
                                    name="understand_investment"
                                    Label={{
                                        label: (
                                            <div className={classes.checkbox}>
                                                I understand that investments in
                                                the equity or debt securities of
                                                private companies are generally
                                                illiquid and that I may have to
                                                hold them indefinitely. I also
                                                understand that investments in
                                                the equity and/or debt
                                                securities of private companies
                                                are risky and I may lose some or
                                                all of my principal, as well as
                                                any expected returns or
                                                benefits, and that I am both
                                                aware of the risks and prepared
                                                and capable of bearing such
                                                loses.
                                            </div>
                                        ),
                                    }}
                                    checked={understandInvestment}
                                    onClick={() =>
                                        setUnderstandInvestment(
                                            !understandInvestment
                                        )
                                    }
                                    required
                                />
                            </Grid>
                            <br />
                            <br />
                            <Grid container spacing={1}>
                                <Field
                                    component={CheckboxWithLabel}
                                    type="checkbox"
                                    name="certify_accurate_information"
                                    Label={{
                                        label: (
                                            <div className={classes.checkbox}>
                                                The above statements and all
                                                information contained in this
                                                document, including my risk
                                                tolerances and investment
                                                objectives, are true and
                                                accurate. I agree to inform my
                                                broker immediately, in writing,
                                                if any information changes in
                                                any way and to cease investment
                                                activities until such changes
                                                have been reviewed and discussed
                                                with them.
                                            </div>
                                        ),
                                    }}
                                    checked={certifyAccurateInformation}
                                    onClick={() =>
                                        setCertifyAccurateInformation(
                                            !certifyAccurateInformation
                                        )
                                    }
                                    required
                                />
                            </Grid>
                            <FundButtonGroup
                                previousStep={previousStep}
                                closeDialog={closeDialog}
                            />
                        </Form>
                    </Container>
                )}
            </Formik>
        </div>
    );
};

export default InvestorSuitability;
