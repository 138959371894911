import React, { useState, useEffect } from 'react';
import * as config from './../../../config';
import request from '../../../utilities/request';
import { snackbar } from '../../../utilities/snackbarUtils';
import authStyles from '../authStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '../../../components/StyledPaper';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { Formik, Form } from 'formik';
import Container from '@mui/material/Container';
import PasswordField from '../../../components/PasswordField';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as profileActions from '../../../store/profile/profileActions';
import * as paymentActions from '../../../store/payment/paymentActions';
import { find } from 'lodash';
import ActionButton from '../../../components/Button/ActionButton';
import { getHost } from '../../../utilities/utils';

let initialValues = {};

const SubscriptionType = (props) => {
    const {
        payment,
        paymentActions,
        profileActions,
        paymentPortal,
        profile,
    } = props;

    const classes = authStyles();
    const [cancelConfirmationDialog, setCancelConfirmationDialog] = useState(
        false
    );
    const [key, setKey] = useState(0);

    const displayCancelConfirmationDialog = () => {
        setCancelConfirmationDialog(true);
    };
    const closeCancelConfirmationDialog = () => {
        setCancelConfirmationDialog(false);
    };

    useEffect(paymentActions.getPricePlans, []);
    useEffect(paymentActions.getPaymentPortal, []);

    const upgradeSubscription = (tier) => {
        let priceId = find(payment.pricePlans.data, { name: tier })?.price_id;
        if (!priceId) {
            snackbar.error('Invalid price plan.');
            return;
        }

        let data = {
            success_url: getHost() + '/issuer/preferences?payment=success',
            cancel_url: getHost() + '/issuer/preferences?payment=fail',
            // success_url: 'https://dash.digtl.co/issuer/preferences?payment=success',
            // cancel_url: 'https://dash.digtl.co/issuer/preferences?payment=fail',
            price_id: priceId,
        };

        paymentActions.createCheckoutSession(data);
    };

    const openPaymentPortal = () => {
        window.location.href = paymentPortal.data.url;
    };

    return (
        <React.Fragment>
            <Grid item xs={props.xs || 12} md={props.md || 6}>
                <Paper padding={props.padding}>
                    <Typography variant="overline">
                        <h1>Subscription Type</h1>
                    </Typography>

                    <Typography
                        variant="overline"
                        className={classes.centerText}
                    >
                        <h3>CURRENT SUBSCRIPTION PLAN:</h3>

                        <h3>
                            {profile ? profile.subscription_model : ''}{' '}
                            MEMBERSHIP
                        </h3>
                    </Typography>

                    <Typography
                        component="div"
                        style={{ textAlign: 'center' }}
                    ></Typography>

                    {profile && profile.subscription_model === 'basic' && (
                        <Grid container>
                            <Grid item xs={8} cm={8} md={8}>
                                <Typography variant="overline">
                                    <h3 style={{ marginBottom: '0px' }}>
                                        PRO MEMBERSHIP - $99/MO :
                                    </h3>
                                </Typography>
                                <Typography variant="caption">
                                    * 30-day free trial
                                </Typography>
                            </Grid>
                            <Grid item xs={4} cm={4} md={4}>
                                <Typography variant="overline">
                                    <ActionButton
                                        component="label"
                                        variant="text"
                                        size="small"
                                        disabled={payment.pricePlans.loading}
                                        loading={payment.pricePlans.loading}
                                        onClick={() =>
                                            upgradeSubscription('PRO')
                                        }
                                        className={classes.blueText}
                                    >
                                        <h3>
                                            {profile && profile.missing_payment
                                                ? 'FINALIZE'
                                                : 'UPGRADE'}
                                        </h3>
                                    </ActionButton>
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8}>
                                <Typography variant="overline">
                                    <h3>ENTERPRISE MEMBERSHIP</h3>
                                </Typography>
                            </Grid>
                            <Grid item xs={4} cm={4} md={4}>
                                <Typography variant="overline">
                                    <ActionButton
                                        component="label"
                                        variant="text"
                                        size="small"
                                        disabled={payment.loading}
                                        onClick={() => {
                                            window.open(
                                                'https://digtl.co/contact',
                                                '_blank'
                                            );
                                        }}
                                        className={classes.blueText}
                                    >
                                        <h3>CONTACT US</h3>
                                    </ActionButton>
                                </Typography>
                            </Grid>
                        </Grid>
                    )}

                    {profile && profile.subscription_model === 'pro' && (
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                cm={12}
                                md={12}
                                className={classes.centerText}
                            >
                                <div
                                    className={classes.dangerButtonLabel}
                                    onClick={() => {
                                        displayCancelConfirmationDialog();
                                    }}
                                >
                                    <b>Cancel Subscription</b>
                                </div>
                                <br />
                                <br />
                                <Button
                                    variant="outlined"
                                    className={classes.buttonLabel}
                                    color="primary"
                                    size="small"
                                    onClick={openPaymentPortal}
                                >
                                    Manage subscription
                                </Button>
                            </Grid>
                            <Grid item xs={8} cm={8} md={8}>
                                <Typography variant="overline">
                                    <h3>ENTERPRISE MEMBERSHIP</h3>
                                </Typography>
                            </Grid>
                            <Grid item xs={4} cm={4} md={4}>
                                <Typography variant="overline">
                                    <Button
                                        component="label"
                                        variant="text"
                                        size="small"
                                        onClick={() => {
                                            window.open(
                                                'https://digtl.co/contact',
                                                '_blank'
                                            );
                                        }}
                                        className={classes.blueText}
                                    >
                                        <h3>CONTACT US</h3>
                                    </Button>
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Paper>
            </Grid>

            <Dialog maxWidth="sm" fullWidth open={cancelConfirmationDialog}>
                <DialogTitle className={classes.dialogTitle}>
                    Cancel Subscription
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        This will <b class="ihq-red">cancel</b> your current
                        subscription plan and revert you to "Basic" plan.
                        <br />
                        Are you sure you want to proceed?
                        <br />
                        Please enter your password below to confirm.
                    </Typography>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={12}>
                            <Formik
                                initialValues={initialValues}
                                key={key}
                                onSubmit={(values, { setSubmitting }) => {
                                    if (
                                        values['password'] === null ||
                                        values['password'] === undefined
                                    ) {
                                        snackbar.error(
                                            'Please type your password in the box to confirm request.'
                                        );
                                        setKey(key + 1);
                                    } else {
                                        request
                                            .post(
                                                `${config.API_BASE_URL}/password/check`,
                                                values
                                            )
                                            .then((response) => {
                                                if (response.data.valid) {
                                                    request
                                                        .get(
                                                            `${config.API_BASE_URL}/stripe/cancel`
                                                        )
                                                        .then((response) => {
                                                            snackbar.success(
                                                                'Subscription has been canceled successfully.'
                                                            );
                                                            setTimeout(() => {
                                                                profileActions.getProfile();
                                                            }, 1000);
                                                            setCancelConfirmationDialog(
                                                                false
                                                            );
                                                        })
                                                        .catch(() => {
                                                            snackbar.error(
                                                                'Error occured while canceling subscription.'
                                                            );
                                                            setKey(key + 1);
                                                        });
                                                } else {
                                                    snackbar.error(
                                                        'Please type your password in the box to confirm request.'
                                                    );
                                                    setKey(key + 1);
                                                }
                                            })
                                            .catch(() => {
                                                snackbar.error(
                                                    'Error occured during password validation process.'
                                                );
                                                setKey(key + 1);
                                            });
                                    }
                                }}
                            >
                                {({ isSubmitting }) => (
                                    <Container>
                                        <Form
                                            className={classes.form}
                                            noValidate
                                        >
                                            <PasswordField
                                                name="password"
                                                label={'Password'}
                                                disableAutocomplete
                                            />
                                            <DialogActions
                                                className={classes.actions}
                                            >
                                                <Button
                                                    color="secondary"
                                                    variant="outlined"
                                                    className={
                                                        classes.secondaryButtonLabel
                                                    }
                                                    onClick={() => {
                                                        closeCancelConfirmationDialog();
                                                    }}
                                                >
                                                    Close
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    variant="outlined"
                                                    className={
                                                        classes.dangerButtonLabel
                                                    }
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                >
                                                    Cancel
                                                </Button>
                                            </DialogActions>
                                        </Form>
                                    </Container>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

const mapStateToProps = ({ profile, payment }) => {
    return {
        profile: profile,
        payment: payment,
        paymentPortal: payment.paymentPortal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        profileActions: bindActionCreators(profileActions, dispatch),
        paymentActions: bindActionCreators(paymentActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionType);
