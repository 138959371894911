import React, { useState } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as profileActions from '../../store/profile/profileActions';
import * as adminActions from '../../store/admin/adminActions';
import * as transferAgentActions from '../../store/transferAgent/transferAgentActions';
import * as drawerActions from '../../store/drawer/drawerActions';
import { logout } from '../../store/auth/authActions';
import { Link, NavLink, withRouter, useLocation } from 'react-router-dom';
import { isViewMode } from '../../utilities/utils';
import HelpTooltip from '../../components/HelpTooltip/HelpTooltip';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import LinearProgress from '@mui/material/LinearProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import UserIcon from '@mui/icons-material/AccountCircle';
import BusinessIcon from '@mui/icons-material/Business';
import MenuIcon from '@mui/icons-material/Menu';
import LogOutIcon from '@mui/icons-material/PowerSettingsNew';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import LogoImage from '../../assets/img/new_logo_digtl.png';
import EmailIcon from '@mui/icons-material/Email';
import withStyles from '@mui/styles/withStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import headerStyles from '../styles/headerStyles';

import { getAccountType, getTokenByType } from '../../utilities/auth';
import { getBasePath } from '../../utilities/router';

import {
    ACCOUNT_ADMIN,
    ACCOUNT_ISSUER,
    ACCOUNT_TRANSFER_AGENT,
} from '../../constants/profile';
import useUnreadMessages from '../../api/messaging/useUnreadMessages';

const Header = (props) => {
    const [menuAnchor, setMenuAnchor] = useState(null);
    const modules = {
        investor: {
            path: 'investor',
            title: 'Investor Portal',
        },
        issuer: {
            path: 'issuer',
            title: 'Issuer Portal',
        },
        admin: {
            path: 'admin',
            title: 'Admin Portal',
        },
        'transfer-agent': {
            path: 'transfer-agent',
            title: 'Transfer Agent Portal',
        },
        signup: {
            path: 'signup',
            title: 'Issuer Onboarding',
        },
    };

    const handleMenuOpen = (event) => {
        setMenuAnchor(event.currentTarget.parentNode);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    const handleLogout = () => {
        handleMenuClose();
        logout(props.history, getAccountType() === ACCOUNT_ADMIN);
    };

    const {
        classes,
        location,
        profile,
        isLoading,
        adminActions,
        transferAgentActions,
        drawerActions,
    } = props;
    const module = modules[getBasePath(location)];

    const accountType = getAccountType();
    const adminLoginToken = getTokenByType('admin_token');
    const transferAgentToken = getTokenByType('ta_token');

    const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

    const getPathByAccountType = (accountType) => {
        if (accountType === ACCOUNT_ADMIN) return ACCOUNT_ADMIN;
        if (accountType === ACCOUNT_ISSUER) return ACCOUNT_ISSUER;
        if (accountType === ACCOUNT_TRANSFER_AGENT)
            return ACCOUNT_TRANSFER_AGENT;
    };
    const { unread } = useUnreadMessages(module.path !== 'signup');
    const currentLocation = useLocation().pathname;

    return (
        <React.Fragment>
            <AppBar position="static" className={classes.bar}>
                <Container>
                    <Toolbar disableGutters>
                        {!mdUp && (
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={() => drawerActions.toggleDrawer(true)}
                                edge="start"
                                className={clsx(classes.menuButton)}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                        <Link
                            to={
                                accountType === ACCOUNT_ISSUER
                                    ? `/${accountType}/issue`
                                    : `/${accountType}`
                            }
                            className={classes.brand}
                        >
                            {profile.name && (
                                <img
                                    src={
                                        profile.company_logo
                                            ? profile.company_logo
                                            : LogoImage
                                    }
                                    alt="Exponential"
                                    className={classes.logo}
                                />
                            )}
                        </Link>
                        <div className={classes.navigation}>
                            {/* Administrative view notice */}
                            {accountType === ACCOUNT_ADMIN && (
                                <small>Administrative view</small>
                            )}
                            {/* Transfer agent view notice */}
                            {accountType === ACCOUNT_TRANSFER_AGENT && (
                                <small>Transfer agent view</small>
                            )}

                            {/* "Logged in as an admin" notice */}
                            {adminLoginToken && (
                                <small>Logged in as an administrator.</small>
                            )}
                            {/* "Logged in as a transfer agent" notice */}
                            {transferAgentToken && (
                                <small>Logged in as a transfer agent.</small>
                            )}
                            <NavLink
                                to={`/${module.path}`}
                                className={clsx(classes.link, classes.title)}
                                activeClassName="active"
                            ></NavLink>
                        </div>
                        {module.path !== 'signup' && (
                            <div className={classes.profile}>
                                {smUp && (
                                    <React.Fragment>
                                        <div className={classes.taNote}>
                                            {profile &&
                                            isViewMode(props.profile) ? (
                                                <span>
                                                    Account is managed by TA
                                                    <HelpTooltip
                                                        color="#3e9cd9"
                                                        title="Account is managed by transfer agent. Therefore, functionalities such as transfer of the assets and approvals of the transfer, issuance and retirements are disabled for the issuer account."
                                                    />
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </div>

                                        <div className={classes.static}>
                                            Welcome,
                                        </div>
                                        <div
                                            onClick={handleMenuOpen}
                                            className={classes.link}
                                        >
                                            {profile && profile.user_id
                                                ? `${profile.name}`
                                                : null}
                                        </div>
                                    </React.Fragment>
                                )}
                                <IconButton
                                    onClick={handleMenuOpen}
                                    className={classes.button}
                                >
                                    <UserIcon color="primary" />
                                </IconButton>
                                <Link
                                    to={
                                        accountType === ACCOUNT_ISSUER
                                            ? '/issuer/messages'
                                            : accountType ===
                                              ACCOUNT_TRANSFER_AGENT
                                            ? '/transfer-agent/messages'
                                            : ''
                                    }
                                >
                                    {unread && unread.unread > 0 ? (
                                        <Chip
                                            color="danger"
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            icon={<NotificationImportantIcon />}
                                            label={unread?.unread}
                                            sx={{
                                                marginRight: '1em',
                                            }}
                                        />
                                    ) : (
                                        <Chip
                                            color="primary"
                                            style={{
                                                cursor: 'pointer',
                                                display: 'inline-flex',
                                                verticalAlign: 'middle',
                                                paddingLeft: '9px',
                                            }}
                                            icon={<EmailIcon />}
                                        />
                                    )}
                                </Link>
                                <Menu
                                    anchorEl={menuAnchor}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(menuAnchor)}
                                    onClose={handleMenuClose}
                                    disableScrollLock
                                    keepMounted
                                >
                                    <MenuItem
                                        component={NavLink}
                                        to={`/${getPathByAccountType(
                                            accountType
                                        )}/preferences`}
                                        style={{
                                            color:
                                                currentLocation ===
                                                `/${getPathByAccountType(
                                                    accountType
                                                )}/preferences`
                                                    ? '#3E9CD9'
                                                    : null,
                                        }}
                                    >
                                        <SettingsIcon />
                                        <span className={classes.item}>
                                            Preferences
                                        </span>
                                    </MenuItem>
                                    {accountType === ACCOUNT_ISSUER && [
                                        <MenuItem
                                            key={1}
                                            component={NavLink}
                                            to={'/issuer/preferences/user'}
                                            style={{
                                                color:
                                                    currentLocation ===
                                                    '/issuer/preferences/user'
                                                        ? '#3E9CD9'
                                                        : null,
                                            }}
                                        >
                                            <UserIcon />
                                            <span className={classes.item}>
                                                User Information
                                            </span>
                                        </MenuItem>,
                                        <MenuItem
                                            key={2}
                                            component={NavLink}
                                            to={'/issuer/preferences/company'}
                                            style={{
                                                color:
                                                    currentLocation ===
                                                    '/issuer/preferences/company'
                                                        ? '#3E9CD9'
                                                        : null,
                                            }}
                                        >
                                            <BusinessIcon />
                                            <span className={classes.item}>
                                                Company Information
                                            </span>
                                        </MenuItem>,
                                    ]}
                                    {adminLoginToken && [
                                        <MenuItem
                                            onClick={() =>
                                                adminActions.switchToAdmin(
                                                    props.history
                                                )
                                            }
                                        >
                                            <SupervisorAccountIcon color="primary" />
                                            <ListItemText
                                                primary="DIGTL Admin"
                                                secondary="Go back to ListX"
                                                className={classes.item}
                                            />
                                        </MenuItem>,
                                    ]}
                                    {transferAgentToken && (
                                        <MenuItem
                                            onClick={() =>
                                                transferAgentActions.switchToTransferAgent(
                                                    props.history
                                                )
                                            }
                                        >
                                            <SupervisorAccountIcon color="primary" />
                                            <ListItemText
                                                primary="DIGTL Transfer Agent"
                                                secondary="Go back to Issuers"
                                                className={classes.item}
                                            />
                                        </MenuItem>
                                    )}
                                    <MenuItem onClick={handleLogout}>
                                        <LogOutIcon />
                                        <span className={classes.item}>
                                            Log Out
                                        </span>
                                    </MenuItem>
                                </Menu>
                            </div>
                        )}
                    </Toolbar>
                </Container>
            </AppBar>
            {isLoading ? (
                <LinearProgress />
            ) : (
                <div className={classes.placeholder} />
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.profile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        profileActions: bindActionCreators(profileActions, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch),
        transferAgentActions: bindActionCreators(
            transferAgentActions,
            dispatch
        ),
        drawerActions: bindActionCreators(drawerActions, dispatch),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(headerStyles)(Header)));
