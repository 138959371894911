import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as dialogActions from '../../../store/dialog/dialogActions';
import * as adminActions from '../../../store/admin/adminActions';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PlaylistAddCheck from '@mui/icons-material/PlaylistAddCheck';

const UpdateAllowedIssuers = (props) => {
    const { dialogActions, adminActions, id } = props;

    /* Build a "delete" callback method. */
    const handleDelete = () => {
        adminActions.deleteAdministrator(id);
    };

    return (
        <Tooltip title="Update allowed issuers">
            <IconButton
                color="primary"
                size="small"
                onClick={() =>
                    dialogActions.openDialog(
                        'Delete an administrator',
                        'Are you sure you want to delete this administrator?',
                        handleDelete,
                        'Cancel',
                        'Delete'
                    )
                }
            >
                <PlaylistAddCheck />
            </IconButton>
        </Tooltip>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        dialogActions: bindActionCreators(dialogActions, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch),
    };
};

UpdateAllowedIssuers.propTypes = {
    id: PropTypes.number,
};

export default connect(null, mapDispatchToProps)(UpdateAllowedIssuers);
