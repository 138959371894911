import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { formatNumber } from '../../../utilities/utils';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as capTableActions from '../../../store/capTable/capTableActions';
import TableFooter from '@mui/material/TableFooter';

const StyledTableCell = styled(TableCell)({
    '&.MuiTableCell-body': {
        fontSize: 14,
    },
    '&.MuiTableCell-head': {
        backgroundColor: '#EAEAEA',
        color: 'black',
    },
    borderBottom: 0,
});

const StyledTableRow = styled(TableRow)({
    '&:nth-of-type(odd)': {
        backgroundColor: '#FFF',
        height: '50px',
    },
    '& > *': {
        borderBottom: 'unset',
        marginBottom: '1rem',
    },
});

function CapTableFooter(props) {
    const { capTableFooterData } = props;
    return (
        <React.Fragment>
            <TableFooter>
                <StyledTableRow>
                    <StyledTableCell
                        align="center"
                        style={{ color: '#63A4DA' }}
                    >
                        Outstanding Assets
                    </StyledTableCell>
                    <StyledTableCell
                        align="center"
                        style={{ color: '#63A4DA' }}
                    >
                        Authorized Assets
                    </StyledTableCell>
                    <StyledTableCell
                        align="center"
                        style={{ color: '#63A4DA' }}
                    >
                        Authorized Assets
                    </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableCell
                        align="center"
                        style={{ color: '#23587B' }}
                    >
                        {formatNumber(capTableFooterData.total.a)}
                    </StyledTableCell>
                    <StyledTableCell
                        align="center"
                        style={{ color: '#23587B' }}
                    >
                        {formatNumber(
                            capTableFooterData.profile &&
                                capTableFooterData.profile.authorized_shares
                                ? capTableFooterData.profile.authorized_shares
                                : 0
                        )}
                    </StyledTableCell>
                    <StyledTableCell
                        align="center"
                        style={{ color: '#23587B' }}
                    >
                        {formatNumber(capTableFooterData.total.p, 2)}
                    </StyledTableCell>
                </StyledTableRow>{' '}
            </TableFooter>
        </React.Fragment>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        capTableActions: bindActionCreators(capTableActions, dispatch),
    };
};

export default connect(null, mapDispatchToProps)(CapTableFooter);
