import makeStyles from '@mui/styles/makeStyles';

const containerStyles = makeStyles((theme) => ({
    boxContainer: {
        maxWidth: '27rem',
        alignItems: 'center',
        marginTop: '10rem',
    },
    paper: {
        //marginTop: '1.25rem',
        padding: '0.7rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // additional
        borderRadius: '15px 15px 0 0',
        paddingTop: 20,
        paddingBottom: 20,
        marginBottom: 10,
    },
    logo: {
        display: 'block',
        margin: 'auto',
        width: '50%',
    },
}));

export default containerStyles;
