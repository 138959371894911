import {
    Breadcrumbs,
    Collapse,
    Stack,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import useElementWallets from '../../../api/blockchain/useElementWallets';
import useAmpAssets from '../../../api/blockchain/useAmpAssets';

import React from 'react';
import StyledPaper from '../../../components/StyledPaper';
import Wallets from './Wallets';
import Shareholders from './Shareholders';
import Assets from './Assets';
import ComingSoon from './Utils/ComingSoon';
import Whitelists from './Whitelists';

import { setIssuanceStep } from '../../../store/assetIssuance/assetIssuanceActions';
import {
    CREATE_WALLET_STEP,
    DISTRIBUTE_ASSETS_STEP,
    FUND_WALLET_STEP,
    ISSUE_ASSET_STEP,
    REGISTER_HOLDERS_STEP,
    WHITELIST_STEP,
} from '../../../constants/blockchain';
import PopupMenu from './Utils/BreadcrumbPopupMenu';
import LockedTile from '../../../components/LockedTile';
import { SUBSCRIPTION_PRO } from '../../../constants/profile';
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

const BlockchainScreen = ({ issuanceStep }) => {
    const [value, setValue] = React.useState(0);
    const [hideSteps, setHideSteps] = React.useState(false);
    const dispatch = useDispatch();

    const { wallets } = useElementWallets();
    const { ampAssets } = useAmpAssets();

    const handleChange = (e, newValue) => {
        setValue(newValue);
        dispatch(setIssuanceStep({ item: null, step: null }));
    };

    const handleBreadcrumbChange = (breadcrumb) => {
        setValue(breadcrumb.tab);
        dispatch(
            setIssuanceStep({
                step: breadcrumb.step,
            })
        );
    };

    const breadcrumbs = [
        { step: CREATE_WALLET_STEP, tab: 0 },
        { step: REGISTER_HOLDERS_STEP, tab: 1 },
        { step: ISSUE_ASSET_STEP, tab: 2 },
        { step: WHITELIST_STEP, tab: 3 },
        { step: FUND_WALLET_STEP, tab: 0, items: wallets, itemLabel: 'wallet' },
        {
            step: DISTRIBUTE_ASSETS_STEP,
            tab: 2,
            items: ampAssets,
            itemLabel: 'name',
        },
    ];

    return (
        <LockedTile requiredPlan={SUBSCRIPTION_PRO}>
            <>
                <div
                    style={{
                        border: '1px solid #3E9CD9',
                        borderRadius: '1em',
                        padding: '10px',
                    }}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        gap={12}
                        sx={{ cursor: 'pointer', color: '#3E9CD9' }}
                        onClick={() => setHideSteps(!hideSteps)}
                    >
                        <Typography
                            variant="body2"
                            as="p"
                            sx={{ color: '#3E9CD9' }}
                        >
                            <b>Your asset issuance journey</b>
                        </Typography>
                        {hideSteps ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </Stack>
                    {
                        <Collapse in={!hideSteps}>
                            <Breadcrumbs
                                separator={
                                    <NavigateNextIcon fontSize="small" />
                                }
                                aria-label="breadcrumb"
                                sx={{ marginTop: '10px' }}
                            >
                                {breadcrumbs.map((breadcrumb, i) => (
                                    <Typography
                                        variant="body2"
                                        key={i}
                                        sx={{
                                            cursor: 'pointer',
                                            '&:hover': {
                                                textDecoration: 'underline',
                                            },
                                            fontWeight:
                                                value === breadcrumb.tab
                                                    ? 700
                                                    : 400,
                                            color:
                                                value === breadcrumb.tab
                                                    ? '#3E9CD9'
                                                    : 'inherit',
                                        }}
                                        onClick={() =>
                                            handleBreadcrumbChange(breadcrumb)
                                        }
                                    >
                                        {breadcrumb.step === FUND_WALLET_STEP ||
                                        breadcrumb.step ===
                                            DISTRIBUTE_ASSETS_STEP ? (
                                            <PopupMenu
                                                label={breadcrumb.step}
                                                items={breadcrumb.items}
                                                itemLabel={breadcrumb.itemLabel}
                                            />
                                        ) : (
                                            breadcrumb.step
                                        )}
                                    </Typography>
                                ))}
                            </Breadcrumbs>
                        </Collapse>
                    }
                </div>

                <br />
                <StyledPaper>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab label="Wallets" {...a11yProps(0)} />
                        <Tab label="Holders" {...a11yProps(1)} />
                        <Tab label="Assets" {...a11yProps(2)} />
                        <Tab label="Whitelists" {...a11yProps(3)} />
                        <Tab label="Distributions" {...a11yProps(4)} />
                        <Tab label="Voting" {...a11yProps(5)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <Wallets />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Shareholders />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Assets />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <Whitelists />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <ComingSoon />
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        <ComingSoon />
                    </TabPanel>
                </StyledPaper>
            </>
        </LockedTile>
    );
};

const mapStateToProps = ({ profile }) => {
    return {
        profile: profile,
    };
};

export default connect(mapStateToProps, null)(BlockchainScreen);
