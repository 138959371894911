import * as listTemplateTypes from './listTemplateTypes';

const initialState = {
    data: null,
    loading: true,
};

const listTemplateReducer = (state = initialState, action) => {
    switch (action.type) {
        case listTemplateTypes.SET_LIST_TEMPLATE_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case listTemplateTypes.GET_TEMPLATE_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        default:
            return state;
    }
};

export default listTemplateReducer;
