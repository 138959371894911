import React from 'react';
import { useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActions from '../../../store/auth/authActions';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';

import TextField from '../../../components/TextField/TextField';
import authStyles from '../authStyles';
import QRCode from 'qrcode.react';

/* Initial form values */
const initialValues = {
    otp: '',
};

/* OTP form validation */
const validationSchema = Yup.object({
    otp: Yup.string().required('Please enter your OTP code.'),
});

const TwoFactorAuth = (props) => {
    const { auth, authActions } = props;
    const classes = authStyles();
    const history = useHistory();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                values.login_hash = auth.login_hash;
                authActions.submitOtp(
                    values,
                    closeSnackbar,
                    enqueueSnackbar,
                    setSubmitting,
                    history,
                    true
                );
            }}
        >
            {({ isSubmitting, submitForm }) => (
                <Form className={classes.form} noValidate>
                    {auth.otp_qr && (
                        <React.Fragment>
                            <Typography component="p" variant="body2">
                                Since this is your first time logging in, please
                                scan the QR code using an Authenticator app.
                            </Typography>
                            <br />
                            <Typography component="p" variant="body2">
                                Afterwards, type in the OTP (one-time password).
                            </Typography>
                            <div className="centered">
                                <QRCode
                                    size={160}
                                    value={auth.otp_qr}
                                    className="m-t-10"
                                />
                            </div>
                        </React.Fragment>
                    )}
                    <Field
                        component={TextField}
                        name="otp"
                        type="text"
                        label="One-time password"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                    />

                    <div className={classes.actions}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="outlined"
                            className={classes.buttonLabel}
                            fullWidth={true}
                            disabled={isSubmitting}
                            onClick={submitForm}
                        >
                            {!isSubmitting ? (
                                <span>Log In</span>
                            ) : (
                                <CircularProgress size={30} />
                            )}
                        </Button>

                        <Typography variant="body1" className="centered">
                            <Link href="#" onClick={authActions.clearOtp}>
                                Go back to credentials
                            </Link>
                        </Typography>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

const mapStateToProps = ({ auth }) => {
    return {
        auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        authActions: bindActionCreators(authActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorAuth);
