import React from 'react';
import Container from '@mui/material/Container';
import Header from '../../../components/Header/Header';
import Title from '../../../components/Title/Title';
import DataTable from '../../../components/DataTable/DataTable';
import * as config from './../../../config';

const Holdings = () => {
    const columns = [
        {
            id: 'published_on',
            label: 'Date',
            format: { type: 'date', format: 'date' },
            align: 'left',
            width: '20%',
        },
        {
            id: 'title',
            label: 'Memo',
            format: { type: 'text' },
            align: 'left',
            width: '80%',
        },
    ];

    return (
        <React.Fragment>
            <Header />
            <Title text="Holdings" />
            <Container>
                <DataTable
                    dataColumns={columns}
                    remoteUrl={`${config.API_BASE_URL}/holding/list`}
                    enableSearch
                    enableExport
                    autoLayout
                />
            </Container>
        </React.Fragment>
    );
};

export default Holdings;
