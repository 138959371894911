import * as issuerInviteTypes from './issuerInviteTypes';

const initialState = {
    data: null,
    loading: true,
};

const issuerInviteReducer = (state = initialState, action) => {
    switch (action.type) {
        case issuerInviteTypes.GET_ISSUER_INVITES_SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case issuerInviteTypes.SET_ISSUER_INVITES_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        default:
            return state;
    }
};

export default issuerInviteReducer;
