import useSWR from "swr"
import { API_BASE_URL } from "../config"
import fetcher from "../utilities/fetcher"

const useShareholders = () => {
    const { data, error } = useSWR(`${API_BASE_URL}/shareholders`, fetcher)

    return {
        shareholders: data,
        shareholdersLoading: !error && !data,
        shareholdersError: error
    }
}

export default useShareholders