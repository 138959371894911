import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@mui/material';

import { errorParser } from '../../../../components/LiquidForm/utils/errorParser';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { snackbar } from '../../../../utilities/snackbarUtils';
import Input from '../../../../components/Form/Input';
import SubmitButton from '../../../../components/Form/SubmitButton';
import importGreenWallet from '../../../../api/blockchain/importGreenWallet';

const ImportGreenWalletDialog = (props) => {

    const info = "Enter the wallet's mnemonic (seed phrase) to import it. Each word that composes the seed phrase should be put into a single string separated by blank spaces.";

    const schema = yup.object({
        mnemonic: yup.string().required('mnemonic is required.'),
    });

    const defaultValues = {
        mnemonic: '',
    };

    const {
        handleSubmit,
        control,
        reset,
        formState: { isSubmitting, errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    const importOne = async (data) => {
        await importGreenWallet(props.user?.id, data.mnemonic)
            .then((response) => {
                snackbar.success(response.data.message);
                reset(defaultValues);
                props.handleClose(true);
            })
            .catch((error) => {
                snackbar.error(errorParser(error));
            });
    };

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>Import an existing multi sig green wallet</DialogTitle>
            <form onSubmit={handleSubmit(importOne)}>
                <DialogContent>
                    <DialogContentText>
                        <div style={{ display: 'flex', color: 'gray' }}>
                            <Typography variant="body2">
                                { info }
                            </Typography>
                        </div>
                        <br />
                        <Input
                            autoFocus
                            name="mnemonic"
                            label="Seed phrase"
                            placeholder="Enter seed phrase"
                            type="text"
                            fullWidth
                            error={errors?.mnemonic}
                            control={control}
                            variant="outlined"
                        />
                        <br />
                        <br />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose}>Cancel</Button>
                    <SubmitButton
                        text="Import"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                    />
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default ImportGreenWalletDialog;
