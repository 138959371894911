import React, { useState } from 'react';

import { connect } from 'react-redux';

import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';

import LockedTile from '../../../components/LockedTile';
import FormikAutocomplete from '../../../components/FormikAutocomplete';
import StyledPaper from '../../../components/StyledPaper';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { LIQUID_BROWSER, NEXUS_BROWSER } from '../../../constants/blockchain';

import StacsIssuance from '../../../components/StacsForm/StacsIssuance';
import LiquidIssuance from '../../../components/LiquidForm/LiquidIssuance';
import { SUBSCRIPTION_PRO } from '../../../constants/profile';

/* Initial form values */
const initialValues = {
    blockchain: 'liquid',
};

const validationSchema = Yup.object({
    blockchain: Yup.string()
        .oneOf(['nexus', 'symbol', 'liquid'])
        .required('Please select a blockchain.'),
});

const chains = [
    { name: 'Liquid', value: 'liquid' },
    { name: 'NEXUS', value: 'nexus' },
    // { name: 'Symbol', value: 'symbol' },
];

const Blockchain = (props) => {
    const [chain, setChain] = useState('liquid');

    /* Update chain */
    const updateChain = (value) => {
        setTimeout(() => {
            setChain(value?.value);
        }, 0);
    };

    return (
        <LockedTile requiredPlan={SUBSCRIPTION_PRO}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {({ values }) => (
                    <Form noValidate>
                        <StyledPaper
                            elevation={3}
                            padding={10}
                            marginBottom={20}
                        >
                            <Typography variant="body1">
                                Blockchain selection
                            </Typography>
                            <br />
                            <Grid container item spacing={2}>
                                <Grid item xs={12}>
                                    <Field
                                        component={FormikAutocomplete}
                                        name="blockchain"
                                        label="Blockchain"
                                        options={chains}
                                        helperText="Select the blockchain where you want to issue."
                                        optionKey="name"
                                        defaultValue={chains[0]}
                                        callback={updateChain}
                                    />
                                </Grid>
                                {/* NEXUS block explorer */}
                                {chain === 'nexus' && (
                                    <Grid item xs={12}>
                                        <Typography variant="body2">
                                            <a
                                                className="no-decoration link"
                                                href={`${NEXUS_BROWSER}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Visit the NEXUS block explorer
                                            </a>
                                        </Typography>
                                    </Grid>
                                )}
                                {/* Liquid block explorer */}
                                {chain === 'liquid' && (
                                    <Grid item xs={12}>
                                        <Typography variant="body2">
                                            <a
                                                className="no-decoration link"
                                                href={`${LIQUID_BROWSER}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Visit the Liquid block explorer
                                            </a>
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </StyledPaper>
                    </Form>
                )}
            </Formik>
            <div>
                {chain === 'nexus' && <StacsIssuance />}
                {chain === 'liquid' && <LiquidIssuance />}
                {/* Other blockchains */}
                {chain && chain !== 'nexus' && chain !== 'liquid' && (
                    <Grid item xs={12} className="flex-center">
                        <AlarmOnIcon />
                        <Typography variant="h5">Coming soon.</Typography>
                    </Grid>
                )}
            </div>
        </LockedTile>
    );
};

const mapStateToProps = ({ profile }) => {
    return {
        profile: profile,
    };
};

export default connect(mapStateToProps, null)(Blockchain);
