import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as config from './../../../config';
import request from '../../../utilities/request';
//import { isViewMode } from '../../../utilities/utils';
import { useSnackbar } from 'notistack';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import authStyles from '../authStyles';
import transferStyles from '../../../components/styles/transferStyles';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import TextField from '../../../components/TextField/TextField';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import * as capTableActions from '../../../store/capTable/capTableActions';
import Blockchain from '../Dashboard/Blockchain';
import CircularProgress from '@mui/material/CircularProgress';
import LockedTile from '../../../components/LockedTile';
import { SUBSCRIPTION_PRO } from '../../../constants/profile';
import { ISSUANCE } from '../../../constants/transfers';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Humanize from 'humanize-plus';
import {
    validateFileExtension,
    allowedFiles,
    allowedFilesMessage,
} from '../../../utilities/utils';
const formData = new FormData();
formData.append('document', '');
const Issuance = (props) => {
    const classes = authStyles();
    const { closeSnackbar } = useSnackbar();
    const transferClasses = transferStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [shareClass, setShareClass] = useState(null);
    const [category, setCategory] = useState(null);

    const [toShareholder, setToShareholder] = useState(null);

    let [read, setRead] = useState(0);
    const [amount, setAmount] = useState(0);
    const [sharePrice, setSharePrice] = useState(0);
    const [issuanceReason, setIssuanceReason] = useState('');
    const [issuanceDocument, setIssuanceDocument] = useState('');

    const [totalAuthorizedShares, setTotalAuthorizedShares] = useState(
        props.profile && props.profile.authorized_shares
            ? props.profile.authorized_shares - props.profile.outstanding_shares
            : 0
    );
    useEffect(props.capTableActions.getClasses, []);
    useEffect(props.capTableActions.getInvestors, []);

    useEffect(() => {
        if (props.classes && props.classes.length && read !== 1) {
            setShareClass(props.classes[0].id);
            props.capTableActions.getCategoriesByClassId(props.classes[0].id);
            if (props.categories && props.categories.length) {
                setCategory(props.categories[0].id);
                setRead(1);
            }
        }
    }, [props.capTableActions, props.classes, props.categories, read]);

    const handleClassChange = (class_id) => {
        setShareClass(class_id);
        props.capTableActions.getCategoriesByClassId(class_id);
        if (props.categories && props.categories.length) {
            setCategory(props.categories[0].id);
        } else {
            setCategory(null);
        }
    };

    const handleShareholderChange = (value, type) => {
        let investor = props.investors.filter((element) => {
            return element.investor === value;
        })[0];
        setToShareholder(investor);
    };

    const uploadFile = (file) => {
        if (!validateFileExtension(file)) {
            enqueueSnackbar(allowedFilesMessage, {
                variant: 'error',
            });
            setTimeout(function () {
                closeSnackbar();
            }, 3500);
        } else {
            formData.set('document', file, file.name);
            setIssuanceDocument(file.name);
        }
    };

    return (
        <LockedTile
            requiredPlan={SUBSCRIPTION_PRO}
            //lock={isViewMode(props.profile)}
        >
            <Blockchain />
            <Formik
                initialValues={{ amount: amount, reason: issuanceReason }}
                onSubmit={(values, { setSubmitting }) => {
                    if (
                        shareClass === null ||
                        shareClass === undefined ||
                        category === null ||
                        category === undefined ||
                        toShareholder === null ||
                        toShareholder === undefined ||
                        issuanceDocument === null ||
                        issuanceDocument === undefined ||
                        issuanceDocument === '' ||
                        issuanceReason === null ||
                        issuanceReason === undefined ||
                        issuanceReason === ''
                    ) {
                        enqueueSnackbar(
                            'Something went wrong. Please enter all information',
                            {
                                variant: 'error',
                            }
                        );
                        setSubmitting(false);
                    } else if (
                        parseFloat(amount.replace(/,/g, '')) >
                        parseFloat(totalAuthorizedShares)
                    ) {
                        enqueueSnackbar(
                            'Amount of assets cannot be higer than total amount of remaining authorized assets',
                            {
                                variant: 'error',
                            }
                        );
                        setSubmitting(false);
                    } else {
                        values['share_class_id'] = shareClass;
                        values['equity_id'] = category;
                        values['receiver_id'] = toShareholder.investor_id;
                        values['receiver'] = toShareholder;
                        values['type'] = ISSUANCE;
                        values['amount'] = parseFloat(amount.replace(/,/g, ''));
                        values['reason'] = issuanceReason;
                        values['price_per_share'] = parseFloat(
                            sharePrice.replace(/,/g, '')
                        );
                        values['is_routine'] = false;

                        for (var key in values) {
                            formData.append(key, values[key]);
                        }
                        values['document'] = formData;
                        request
                            .post(
                                `${config.API_BASE_URL}/transfers/issuer/issuance`,
                                formData
                            )
                            .then((response) => {
                                setToShareholder(null);
                                setSubmitting(false);
                                setTotalAuthorizedShares(
                                    totalAuthorizedShares -
                                        parseFloat(amount.replace(/,/g, ''))
                                );
                                setAmount(0);
                                setSharePrice(0);
                                setIssuanceReason('');
                                enqueueSnackbar(
                                    'Issuance request submitted successfully',
                                    {
                                        variant: 'success',
                                    }
                                );
                            });
                    }
                }}
            >
                {({ isSubmitting }) => (
                    <Form noValidate>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={8} md={8}>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography className={classes.title}>
                                        Issuance
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel
                                                className={classes.label}
                                            >
                                                Select class
                                            </InputLabel>

                                            <FormControl
                                                variant="outlined"
                                                className={classes.formControl}
                                                fullWidth
                                            >
                                                {props.classes &&
                                                props.classes.length ? (
                                                    <Select
                                                        value={
                                                            shareClass
                                                                ? shareClass
                                                                : props
                                                                      .classes[0]
                                                                      .id
                                                        }
                                                        //multiple
                                                        onChange={(e) =>
                                                            handleClassChange(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        {props.classes.map(
                                                            (class_item, i) => (
                                                                <MenuItem
                                                                    value={
                                                                        class_item.id
                                                                    }
                                                                >
                                                                    {
                                                                        class_item.class_description
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                ) : (
                                                    <Select
                                                        value={null}
                                                        onChange={(e) =>
                                                            setShareClass(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        <MenuItem>
                                                            No data
                                                        </MenuItem>
                                                    </Select>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel
                                                className={classes.label}
                                            >
                                                Select category
                                            </InputLabel>
                                            <FormControl
                                                variant="outlined"
                                                className={classes.formControl}
                                                fullWidth
                                            >
                                                {props.categories &&
                                                props.categories.length ? (
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={
                                                            category
                                                                ? category
                                                                : props
                                                                      .categories[0]
                                                                      .id
                                                        }
                                                        onChange={(e) =>
                                                            setCategory(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        {props.categories.map(
                                                            (
                                                                category_item,
                                                                i
                                                            ) => (
                                                                <MenuItem
                                                                    value={
                                                                        category_item.id
                                                                    }
                                                                >
                                                                    {
                                                                        category_item.description
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                ) : (
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        value={category}
                                                        onChange={(e) =>
                                                            setCategory(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        <MenuItem>
                                                            No data
                                                        </MenuItem>
                                                    </Select>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <InputLabel
                                                className={classes.label}
                                            >
                                                Investor
                                            </InputLabel>

                                            <Autocomplete
                                                name="from"
                                                id="free-solo-demo"
                                                value={
                                                    toShareholder !== null &&
                                                    toShareholder !== undefined
                                                        ? toShareholder.investor
                                                            ? toShareholder.investor
                                                            : ''
                                                        : ''
                                                }
                                                onInputChange={(
                                                    event,
                                                    newInputValue
                                                ) => {
                                                    handleShareholderChange(
                                                        newInputValue,
                                                        'from'
                                                    );
                                                }}
                                                options={
                                                    props.investors
                                                        ? props.investors.map(
                                                              (option) =>
                                                                  option.investor
                                                          )
                                                        : []
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        type="text"
                                                        label="Investor"
                                                        margin="normal"
                                                        variant="outlined"
                                                        name="from"
                                                        fullWidth
                                                        placeholder={
                                                            'Search...'
                                                        }
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel
                                                className={classes.label}
                                            >
                                                Amount
                                            </InputLabel>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                name="amount"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                required
                                                defaultValue={amount}
                                                numeric={1}
                                                onKeyUp={(e) =>
                                                    setAmount(e.target.value)
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <InputLabel
                                                className={classes.label}
                                            >
                                                Asset price
                                            </InputLabel>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                name="price_per_share"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                numeric={1}
                                                required
                                                defaultValue={sharePrice}
                                                onKeyUp={(e) =>
                                                    setSharePrice(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <InputLabel
                                                className={classes.label}
                                            >
                                                Document
                                            </InputLabel>
                                            <Grid container spacing={3}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={6}
                                                >
                                                    <TextField
                                                        id="standard-basic"
                                                        variant="outlined"
                                                        value={issuanceDocument}
                                                        placeholder=""
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={6}
                                                >
                                                    <Button
                                                        sx={{ float: 'left' }}
                                                        color="primary"
                                                        variant="outlined"
                                                        className={
                                                            classes.buttonLabel
                                                        }
                                                    >
                                                        Browse
                                                        <input
                                                            onChange={(e) => {
                                                                uploadFile(
                                                                    e.target
                                                                        .files[0]
                                                                );
                                                            }}
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                            type="file"
                                                            name="document"
                                                            accept={allowedFiles.join(
                                                                ','
                                                            )}
                                                        />
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12}>
                                            <InputLabel
                                                className={classes.label}
                                            >
                                                Issuance Reason
                                            </InputLabel>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                name="reason"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                multiline
                                                rows={2}
                                                required
                                                defaultValue={issuanceReason}
                                                onKeyUp={(e) =>
                                                    setIssuanceReason(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={3} md={4}>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography className={classes.title}>
                                        Confirm Issuance
                                    </Typography>

                                    <List>
                                        <ListItem>
                                            <ListItemAvatar
                                                className={
                                                    transferClasses.greyTitle
                                                }
                                            >
                                                Remaining authorized
                                                shares:&nbsp;
                                            </ListItemAvatar>

                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            transferClasses.blackTitle
                                                        }
                                                    >
                                                        <b>
                                                            {Humanize.formatNumber(
                                                                totalAuthorizedShares,
                                                                2
                                                            )}
                                                        </b>
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar
                                                className={
                                                    transferClasses.greyTitle
                                                }
                                            >
                                                Investor:&nbsp;
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            transferClasses.blackTitle
                                                        }
                                                    >
                                                        <b>
                                                            {toShareholder
                                                                ? toShareholder.investor
                                                                    ? toShareholder.investor
                                                                    : ''
                                                                : ''}
                                                        </b>
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>

                                        <ListItem>
                                            <ListItemAvatar
                                                className={
                                                    transferClasses.greyTitle
                                                }
                                            >
                                                Amount:&nbsp;
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            transferClasses.blackTitle
                                                        }
                                                    >
                                                        <b>
                                                            {amount
                                                                ? amount
                                                                : ''}
                                                        </b>
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>

                                        <ListItem>
                                            <ListItemAvatar
                                                className={
                                                    transferClasses.greyTitle
                                                }
                                            >
                                                Price per Share:&nbsp;
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            transferClasses.blackTitle
                                                        }
                                                    >
                                                        <b>
                                                            {sharePrice
                                                                ? sharePrice
                                                                : ''}
                                                        </b>
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>

                                        <ListItem>
                                            <ListItemAvatar
                                                className={
                                                    transferClasses.greyTitle
                                                }
                                            >
                                                Issuance Reason:&nbsp;
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        className={
                                                            transferClasses.blackTitle
                                                        }
                                                    >
                                                        <b>
                                                            {issuanceReason
                                                                ? issuanceReason
                                                                : ''}
                                                        </b>
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </List>

                                    <br />

                                    <div
                                        className={classes.buttonGroupAlignment}
                                    >
                                        <Button
                                            type="submit"
                                            variant="outlined"
                                            color="primary"
                                            className={`${classes.secondaryButtonLabel} ${classes.buttonGroupAlignment}`}
                                            disabled={
                                                isSubmitting ||
                                                !toShareholder ||
                                                shareClass === null ||
                                                shareClass === undefined ||
                                                category === null ||
                                                category === undefined ||
                                                amount <= 0 ||
                                                amount === null ||
                                                amount === undefined ||
                                                amount === '' ||
                                                sharePrice < 0 ||
                                                sharePrice === null ||
                                                sharePrice === undefined ||
                                                sharePrice === '' ||
                                                issuanceDocument === null ||
                                                issuanceDocument ===
                                                    undefined ||
                                                issuanceDocument === '' ||
                                                issuanceReason === null ||
                                                issuanceReason === undefined ||
                                                issuanceReason === ''
                                            }
                                        >
                                            {!isSubmitting ? (
                                                <div className="flex-left">
                                                    Submit for approval
                                                </div>
                                            ) : (
                                                <CircularProgress size={24} />
                                            )}
                                        </Button>
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </LockedTile>
    );
};

const mapStateToProps = ({ profile, capTable, categories, investors }) => {
    return {
        profile: profile,
        classes: capTable.classes.data,
        categories: capTable.categories.data,
        investors: capTable.investors.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        capTableActions: bindActionCreators(capTableActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Issuance);
