import React from 'react';
import Header from '../../../components/Header/Header';
import DashGrid from '../../../components/DashGrid/DashGrid';

const boxes = [
    {
        id: 'welcome',
        label: 'Welcome',
        area: '1 / 1 / 5 / 15',
        color: 'primary',
    },
    {
        id: 'offering-subscriptions',
        label: 'Offering Subscriptions',
        area: '1 / 15 / 3 / 20',
        color: 'darkGray',
        path: '/offering-subscriptions',
    },
    {
        id: 'shareholder-notices',
        label: 'Shareholder Notices',
        area: '1 / 20 / 3 / 25',
        color: 'lightGray',
        path: '/shareholder-notices',
    },
    {
        id: 'transaction-history',
        label: 'Transaction History',
        area: '3 / 15 / 5 / 25',
        color: '#ED7571',
        path: '/transaction-history',
    },
    {
        id: 'events',
        label: 'Events',
        area: '5 / 1 / 7 / 13',
        color: 'gray',
        path: '/events',
    },
    {
        id: 'contracts',
        label: 'Contracts',
        area: '5 / 13 / 7 / 25',
        color: 'lightGray',
        path: '/contracts',
    },
    {
        id: 'holdings',
        label: 'Holdings',
        area: '7 / 1 / 9 / 13',
        color: 'gray',
        path: '/holdings',
    },
    {
        id: 'profile',
        label: 'Profile',
        area: '7 / 13 / 9 / 25',
        color: 'gray',
        path: '/profile',
    },
];

const Dashboard = () => {
    return (
        <React.Fragment>
            <Header type="investor" />
            <DashGrid boxes={boxes} columns={24} rows={8} />
        </React.Fragment>
    );
};

export default Dashboard;
