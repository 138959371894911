import React from 'react';
import Accordion from '@mui/material/Accordion';
import formPanelStyles from '../styles/formPanelStyles';
const FormPanel = (props) => {
    const classes = formPanelStyles();
    const { children } = props;

    return (
        <Accordion defaultExpanded className={classes.container}>
            {children}
        </Accordion>
    );
};

export default FormPanel;
