import React, { useState, useEffect } from 'react'

import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '../TextField/TextField';

import { Controller } from "react-hook-form";
import { GetRandomString } from '../../utilities/utils';

/**
 * React Hook Form combo box (autocomplete)
 */
const ComboBox = (props) => {
  const [key, setKey] = useState(GetRandomString(8))
  const valueLabel = props.valueLabel || 'id'

  useEffect(() => {
    if (props.dependsOn && props.dependsOn !== null) {
      setKey(GetRandomString(8))
    }
  }, [props.dependsOn])

  const getOptionLabel = (option) => {
    if (props.optionLabel.includes('+')) {
      const [ optionA, optionB ] = props.optionLabel.split("+");
      return option[optionB] ? `${option[optionA]}: ${option[optionB]}` : option[optionA];
    }

    return option[props.optionLabel];
  }

  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          key={key}
          options={props.options}
          getOptionLabel={getOptionLabel}
          disabled={props.disabled}
          loading={props.loading}
          autoSelect={props.autoHighlight}
          defaultValue={props.defaultShareClass}
          onChange={(e, data) => {
            onChange(data ? data[valueLabel] : data)
            // Run reset callbacks
            if (props.callbacks) {
              for (let callback of props.callbacks) {
                callback()
              }
            }
          }}
          renderInput={(params) =>
            <TextField
              {...params}
              label={props.label}
              placeholder={props.placeholder}
              error={props.error !== null && props.error !== undefined}
              helperText={props.error?.message}
              InputProps={{
                ...params.InputProps,
                startAdornment: props.leftIcon ? (
                  <InputAdornment position="start">
                    {props.leftIcon}
                  </InputAdornment>
                ) : null, 
                endAdornment: (
                  <React.Fragment>
                    {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          }
        />
      )}
    />
  )
}

export default ComboBox