import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import StyledPaper from '../../StyledPaper';
import { Grid, Button, Typography, Box } from '@mui/material';
import * as Yup from 'yup';

import TextField from '../../TextField/TextField';
import * as liquidBlocksreamActions from '../../../store/liquidBlocksteam/liquidBlockstreamActions';
import { useDispatch, useSelector } from 'react-redux';
import { wallet } from '../api';
import { errorParser } from '../utils/errorParser';
import { snackbar } from '../../../utilities/snackbarUtils';
import CircularLoader from '../../CircularLoader';
import TableComponent from '../components/Table';

const walletsColumns = [{ headerName: 'Name', field: 'name' }];

const createWalletData = {
    name: '',
};

const createWalletSchema = Yup.object({
    name: Yup.string().required('Please enter Wallet name.'),
});

const Wallet = () => {
    const dispatch = useDispatch();
    const { wallets, loading } = useSelector(
        (state) => state.liquidBlockstream
    );
    const [selectedWallet, setSelectedWallet] = useState(null);
    const [loadingWalletData, setLoadingWalletData] = useState(false);

    const getWalletData = async (item) => {
        setSelectedWallet(item);
        setLoadingWalletData(true);
        let balance;
        try {
            balance = (await wallet.getWalletBalance(item.name)).data;
        } catch (err) {
            snackbar.error(errorParser(err));
        }
        setSelectedWallet({ ...item, balance: balance });
        setLoadingWalletData(false);
    };

    useEffect(() => {
        if (!wallets.length) {
            getWallets();
        }
    }, []);

    const getWallets = () => dispatch(liquidBlocksreamActions.getWallets());
    const createWallet = (values, setSubmitting, resetForm) => {
        wallet
            .createWallet(values.name)
            .then(() => {
                resetForm();
                getWallets();
            })
            .catch((error) => snackbar.error(errorParser(error)))
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <>
            <StyledPaper elevation={3} marginBottom={20} padding={2}>
                <Box mb={2}>
                    <Typography variant="body1" textAlign="center">
                        Wallets
                    </Typography>
                </Box>
                {loading ? (
                    <CircularLoader />
                ) : (
                    <>
                        {wallets && !!wallets.length ? (
                            <TableComponent
                                columns={walletsColumns}
                                items={wallets}
                                onRowClick={(id, item) => getWalletData(item)}
                                headAlign="center"
                                bodyAlign="center"
                            />
                        ) : (
                            <Typography variant="body2" textAlign="center">
                                There're no wallets.
                            </Typography>
                        )}
                    </>
                )}
            </StyledPaper>
            {selectedWallet && (
                <StyledPaper elevation={3} marginBottom={20} padding={2}>
                    <Typography textAlign="center" variant="body1">
                        Selected Wallet Info
                    </Typography>
                    {loadingWalletData ? (
                        <CircularLoader />
                    ) : (
                        <Box display="flex" justifyContent="center">
                            Balance: {selectedWallet?.balance?.bitcoin}
                        </Box>
                    )}
                </StyledPaper>
            )}
            <StyledPaper elevation={3} padding={10} marginBottom={20}>
                <Box mb={2}>
                    <Typography textAlign="center" variant="body1">
                        Create Wallet
                    </Typography>
                </Box>
                <Formik
                    initialValues={createWalletData}
                    validationSchema={createWalletSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) =>
                        createWallet(values, setSubmitting, resetForm)
                    }
                >
                    {({ submitForm, isSubmitting }) => (
                        <Form noValidate style={{ width: '100%' }}>
                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                justifyContent="center"
                            >
                                <Grid item xs={12}>
                                    <Field
                                        component={TextField}
                                        name="name"
                                        label="Wallet Name"
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={submitForm}
                                        disabled={isSubmitting}
                                    >
                                        Create Wallet
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </StyledPaper>
        </>
    );
};

export default Wallet;
