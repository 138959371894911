import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as profileActions from '../../../store/profile/profileActions';
import { useSnackbar } from 'notistack';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
//import Title from '../../../components/Title/Title';
import FormPanel from '../../../components/FormPanel/FormPanel';
import FormPanelRow from '../../../components/FormPanelRow/FormPanelRow';
import TextField from '../../../components/TextField/TextField';
// import ComboField from '../../../components/ComboField/ComboField';
// import { countries, states } from '../../../utilities/registry';
import request from '../../../utilities/request';
import * as config from './../../../config';

const initialValues = {
    email: '',
    created_at: '',
    legal_entity_name: '',
    organization_code: '',
    registered_country: '',
    status: '',
};

const validationSchema = Yup.object({
    legal_entity_name: Yup.string().required(
        'Please enter your enterprise name'
    ),
    organization_code: Yup.string().required(
        'Please enter your organization code'
    ),
    email: Yup.string()
        .email('Please enter a valid email address')
        .required('Please enter your email address'),
});

const Profile = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({ ...initialValues });

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            const response = await request.get(
                `${config.API_BASE_URL}/profile/`
            );
            setData({ ...initialValues, ...response.data });
            setIsLoading(false);
        }
        fetchData();
    }, []);

    return (
        <React.Fragment>
            {!isLoading && (
                <Formik
                    initialValues={data}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        closeSnackbar();
                        request
                            .patch(`${config.API_BASE_URL}/profile`, values)
                            .then(() => {
                                dispatch(profileActions.getProfile());
                                enqueueSnackbar(
                                    'The changes to your profile have been saved',
                                    { variant: 'success' }
                                );
                            })
                            .catch(() => {
                                enqueueSnackbar(
                                    'An error occurred while saving the changes',
                                    { variant: 'error' }
                                );
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {({ isSubmitting }) => (
                        <Container>
                            <Typography variant="h4" component="h4">
                                Profile
                            </Typography>
                            <Form noValidate>
                                <FormPanel>
                                    <FormPanelRow
                                        title="Account Details"
                                        description="Name of the person who created account"
                                    >
                                        <Field
                                            component={TextField}
                                            name="name"
                                            type="text"
                                            label="Representative Name"
                                            fullWidth
                                            required
                                        />
                                    </FormPanelRow>
                                    <FormPanelRow description="Legal entity name">
                                        <Field
                                            component={TextField}
                                            name="legal_entity_name"
                                            type="text"
                                            label="Enterprise Name"
                                            fullWidth
                                            required
                                        />
                                    </FormPanelRow>
                                    <FormPanelRow description="Organization code">
                                        <Field
                                            component={TextField}
                                            name="organization_code"
                                            type="text"
                                            label="Organization Code"
                                            fullWidth
                                            required
                                        />
                                    </FormPanelRow>
                                    <FormPanelRow description="Email address">
                                        <Field
                                            component={TextField}
                                            name="email"
                                            type="email"
                                            label="Email"
                                            fullWidth
                                            required
                                        />
                                    </FormPanelRow>
                                    <FormPanelRow description="Date of regisration">
                                        <Field
                                            component={TextField}
                                            name="created_at"
                                            type="text"
                                            label="Created at"
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </FormPanelRow>
                                    <FormPanelRow description="Status of account">
                                        <Field
                                            component={TextField}
                                            name="status"
                                            type="text"
                                            label="Status"
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </FormPanelRow>
                                </FormPanel>
                                {/* <FormPanel>
                                    <FormPanelRow
                                        title="Contact Details"
                                        description="Your home phone number"
                                    >
                                        <Field
                                            component={TextField}
                                            name="home_phone"
                                            type="text"
                                            label="Home Phone"
                                            fullWidth
                                        />
                                    </FormPanelRow>
                                    <FormPanelRow description="Your work phone number">
                                        <Field
                                            component={TextField}
                                            name="work_phone"
                                            type="text"
                                            label="Work Phone"
                                            fullWidth
                                        />
                                    </FormPanelRow>
                                    <FormPanelRow description="Your mobile phone number">
                                        <Field
                                            component={TextField}
                                            name="mobile_phone"
                                            type="text"
                                            label="Mobile Phone"
                                            fullWidth
                                            required
                                        />
                                    </FormPanelRow>
                                </FormPanel>
                                <FormPanel>
                                    <FormPanelRow
                                        title="Address Details"
                                        description="Enter the address you reside at"
                                    >
                                        <Field
                                            component={TextField}
                                            name="address"
                                            type="text"
                                            label="Address"
                                            fullWidth
                                            required
                                        />
                                    </FormPanelRow>
                                    <FormPanelRow description="Select the country you live in">
                                        <Field
                                            component={ComboField}
                                            name="country"
                                            type="text"
                                            label="Country"
                                            options={countries}
                                            fullWidth
                                            required
                                        />
                                    </FormPanelRow>
                                    <FormPanelRow description="Select the state you live in">
                                        <Field
                                            component={ComboField}
                                            name="state"
                                            type="text"
                                            label="State"
                                            options={states}
                                            fullWidth
                                            required
                                        />
                                    </FormPanelRow>
                                    <FormPanelRow description="Enter the name of the city you live in">
                                        <Field
                                            component={TextField}
                                            name="city"
                                            type="text"
                                            label="City"
                                            fullWidth
                                            required
                                        />
                                    </FormPanelRow>
                                </FormPanel>*/}
                                <FormPanel>
                                    <FormPanelRow
                                        title="Actions"
                                        description="Save your changes to update your profile."
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={isSubmitting}
                                        >
                                            {!isSubmitting ? (
                                                <span>Save</span>
                                            ) : (
                                                <CircularProgress size={24} />
                                            )}
                                        </Button>
                                    </FormPanelRow>
                                </FormPanel>
                            </Form>
                        </Container>
                    )}
                </Formik>
            )}
        </React.Fragment>
    );
};

export default Profile;
