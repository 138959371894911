import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Button, Grid } from '@mui/material';
import { cloneDeep } from 'lodash';

import TextField from '../../../TextField/TextField';
import FormikAutocomplete from '../../../FormikAutocomplete';
import { assets } from '../../api';
import { errorParser } from '../../utils/errorParser';
import { snackbar } from '../../../../utilities/snackbarUtils';
import { CheckboxWithLabel } from 'formik-mui';

const initialValues = {
    address: '',
    amount: '',
    comment: '',
    commentTo: '',
    subtractFeeFromAmount: false,
    replaceable: false,
    confirmationTarget: '',
    estimateMode: '',
    assetLabel: '',
    ignoreBlindFail: false,
};
const validationSchema = Yup.object({
    address: Yup.string().required('Please enter address'),
    amount: Yup.string()
        .required('Please enter the amount')
        .matches(
            /^(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)$/,
            'Please enter a valid amount.'
        )
        .notOneOf(['0'], 'The amount cannot be 0.'),
    estimateMode: Yup.string()
        .oneOf(['unset', 'economical', 'conservative'])
        .required('Please select Estimate Mode.'),
});
const estimateOptions = [
    { name: 'UNSET', value: 'unset' },
    { name: 'ECONOMICAL', value: 'economical' },
    { name: 'CONSERVATIVE', value: 'conservative' },
];
function TransferTokenForm(props) {
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                let data = cloneDeep(values);
                data.amount = parseInt(data.amount);
                data.confTarget = parseInt(data.confTarget);
                assets
                    .transfer(values)
                    .then(() => resetForm())
                    .catch((err) => snackbar.error(errorParser(err)))
                    .finally(setSubmitting(false));
            }}
            validationSchema={validationSchema}
        >
            {({ submitForm, isSubmitting, setFieldValue }) => (
                <Form noValidate style={{ width: '100%' }}>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                    >
                        <Grid item xs={12} md={6}>
                            <Field
                                component={TextField}
                                name="address"
                                label="Address"
                                type="text"
                                variant="outlined"
                                margin="normal"
                                helperText="Destination Address"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={TextField}
                                name="amount"
                                label="Amount"
                                type="number"
                                variant="outlined"
                                margin="normal"
                                helperText="Amount of the assets"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Field
                                component={TextField}
                                name="comment"
                                label="Comment"
                                type="textArea"
                                variant="outlined"
                                margin="normal"
                                helperText="A comment used to store what the transaction is for. This is not part of the transaction, just kept in your wallet."
                                fullWidth
                                multiline
                                rows={5}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={TextField}
                                name="commentTo"
                                label="Comment to"
                                type="text"
                                variant="outlined"
                                margin="normal"
                                helperText="A comment to store the name of the person or organization to which you're sending the transaction. This is not part of the transaction, just kept in your wallet."
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={TextField}
                                name="confirmationTarget"
                                label="Confirm Target (in blocks)"
                                type="number"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={FormikAutocomplete}
                                name="estimateMode"
                                label="Estimate Mode"
                                options={estimateOptions}
                                optionKey="name"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={TextField}
                                name="assetLabel"
                                label="Hex ID or Asset Label"
                                type="number"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={CheckboxWithLabel}
                                type="checkbox"
                                name="subtractFeeFromAmount"
                                Label={{
                                    label: 'Subtract Fee From Amount',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={CheckboxWithLabel}
                                type="checkbox"
                                name="replaceable"
                                Label={{
                                    label: 'Replaceable',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Field
                                component={CheckboxWithLabel}
                                type="checkbox"
                                name="ignoreBlindFail"
                                Label={{
                                    label: 'Ignore Blind Fail',
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={submitForm}
                                disabled={isSubmitting}
                            >
                                Transfer
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}

export default TransferTokenForm;
