import React, { useState } from 'react';
import axios from 'axios';
import * as config from './../../../config';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import authStyles from '../authStyles';
import TextField from '../../../components/TextField/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { snackbar } from '../../../utilities/snackbarUtils';

const validationSchema = Yup.object({
    first_name: Yup.string().required('Please enter your first name'),
    last_name: Yup.string().required('Please enter your last name'),
    email: Yup.string()
        .email('Please enter a valid email address')
        .required('Please enter your email address'),
});

const PersonalInformation = (props) => {
    const classes = authStyles();
    const [emailError, setEmailError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [requiredData, setRequiredData] = useState([]);

    const nextStep = () => {
        props.nextStep();
    };

    const setRequiredField = (e) => {
        if (e.target.name === 'first_name') {
            requiredData['first_name'] = e.target.value;
        }

        if (e.target.name === 'last_name') {
            requiredData['last_name'] = e.target.value;
        }

        setRequiredData(requiredData);
        props.saveValues(requiredData);
    };

    return (
        <div id="step1">
            <Formik
                initialValues={props.initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setLoading(true);
                    axios
                        .post(`${config.API_BASE_URL}/email/check`, {
                            email: values.email,
                        })
                        .then((res) => {
                            setEmailError(false);
                            props.saveValues(values);
                            nextStep();
                        })
                        .catch((error) => {
                            // Handle non-unique user, or a different error.
                            setSubmitting(false);
                            if (
                                'unique' in error.response.data &&
                                !error.response.data.unique
                            ) {
                                setEmailError(true);
                            } else {
                                snackbar.error('An unknown error has occured.');
                            }
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                }}
            >
                <Paper className={classes.paper}>
                    <Typography component="div" style={{ textAlign: 'center' }}>
                        <h2>The Start of Your Digital Security Journey</h2>
                    </Typography>
                    <Container maxWidth="xs">
                        <Form className={classes.form} noValidate>
                            <Field
                                component={TextField}
                                type="text"
                                label="First Name"
                                name="first_name"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                                onKeyUp={setRequiredField}
                            />
                            <Field
                                component={TextField}
                                type="text"
                                label="Last Name"
                                name="last_name"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                                onKeyUp={setRequiredField}
                            />
                            <Field
                                component={TextField}
                                type="email"
                                label="Email"
                                name="email"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                            />
                            {emailError && (
                                <FormHelperText error>
                                    The email has already been taken.
                                </FormHelperText>
                            )}
                            <div
                                className={classes.actions}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Button
                                    type="submit"
                                    variant="outlined"
                                    className={classes.buttonLabel}
                                    color="primary"
                                    disabled={loading}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    {loading ? (
                                        <CircularProgress size={30} />
                                    ) : (
                                        'CONTINUE'
                                    )}
                                </Button>
                            </div>
                        </Form>
                    </Container>
                </Paper>
            </Formik>
        </div>
    );
};

export default PersonalInformation;
