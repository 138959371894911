import React from 'react';
import Container from '@mui/material/Container';
import Header from '../../../components/Header/Header';
import Title from '../../../components/Title/Title';
import DataTable from '../../../components/DataTable/DataTable';
import * as config from './../../../config';

const TransactionHistory = () => {
    const columns = [
        {
            id: 'date',
            label: 'Date',
            format: { type: 'date', format: 'date' },
            align: 'left',
            width: '15%',
        },
        {
            id: 'note',
            label: 'Memo',
            format: { type: 'text' },
            align: 'left',
            width: '40%',
        },
        {
            id: 'status',
            label: 'Status',
            format: { type: 'text' },
            width: '15%',
        },
        {
            id: 'amount',
            label: 'Amount',
            format: { type: 'currency', currency: 'usd', parsed: true },
            align: 'right',
            width: '15%',
        },
        {
            id: 'shares',
            label: 'Assets',
            format: { type: 'number' },
            align: 'right',
            width: '15%',
        },
    ];

    return (
        <React.Fragment>
            <Header />
            <Title text="Transaction History" />
            <Container>
                <DataTable
                    dataColumns={columns}
                    remoteUrl={`${config.API_BASE_URL}/transaction/list`}
                    enableSearch
                    enableExport
                    autoLayout
                />
            </Container>
        </React.Fragment>
    );
};

export default TransactionHistory;
