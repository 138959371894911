import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Stack,
} from '@mui/material';
import { snackbar } from '../../../../utilities/snackbarUtils';
import importSubaccounts from '../../../../api/blockchain/importSubaccounts';
import useHolders from '../../../../api/blockchain/useHolders';
import DataLoading from '../Utils/DataLoading';
import { errorParser } from '../../../../components/LiquidForm/utils/errorParser';

export const CreateOrImportAction = {
    CREATE: "CREATE",
    IMPORT: "IMPORT"
}

const CreateOrImportSubaccountDialog = ({ holder, handleClose, open }) => {

    const [ loading, setLoading ] = useState(false);
    const { updateHolders } = useHolders();

    const importSubaccountsFromGDK = async () => {
        setLoading(true);
        // run import subaccounts routine
        await importSubaccounts(holder?.id)
        .then(async (response) => {
            await updateHolders();
            snackbar.success(response.data.message);
            handleClose(CreateOrImportAction.IMPORT);
        })
        .catch((error) => {
            snackbar.error(errorParser(error));
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xl">
            <DialogContent>
                <DataLoading
                    loading={loading}
                    text='Loading your subaccounts...'
                />
                {
                    !loading && (
                        <Stack 
                            direction="column"
                            alignItems="center"
                            justifyContent="space-between"
                            gap={2}
                        >
                            <Button onClick={() => handleClose(CreateOrImportAction.CREATE)}>
                                Create new sub-account
                            </Button>
                            {
                                !holder.subaccounts.length && (
                                    <Button onClick={importSubaccountsFromGDK}>
                                        Import existing sub-accounts
                                    </Button>
                                )
                            }
                        </Stack>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateOrImportSubaccountDialog;