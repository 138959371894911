export const treeBRColumns = [
    {
        name: 'full_name',
        label: 'Full Name',
    },
    {
        name: 'email_address',
        label: 'Email',
    },
    {
        name: 'country',
        label: 'Country',
    },
    {
        name: 'type',
        label: 'Type',
    },
    {
        name: 'amount',
        label: 'Amount',
    },
    {
        name: 'created_at',
        label: 'Created at',
    },
];
export default treeBRColumns;
