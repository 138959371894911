export const GET_ISSUERS_SUCCESS = 'GET_ISSUERS_SUCCESS';
export const SET_ISSUERS_LOADING = 'SET_ISSUERS_LOADING';

export const SET_LOGIN_AS_LOADING = 'SET_LOGIN_AS_LOADING';

export const GET_TRANSFER_AGENTS_LIST_SUCCESS =
    'GET_TRANSFER_AGENTS_LIST_SUCCESS';
export const SET_TRANSFER_AGENTS_LIST_LOADING =
    'SET_TRANSFER_AGENTS_LIST_LOADING';

export const GET_TRANSFER_AGENTS_REQUESTS_SUCCESS =
    'GET_TRANSFER_AGENTS_REQUESTS_SUCCESS';
export const SET_TRANSFER_AGENTS_REQUESTS_LOADING =
    'SET_TRANSFER_AGENTS_REQUESTS_LOADING';

export const GET_TA_TRANSFERS_SUCCESS = 'GET_TRANSFERS_SUCCESS';
export const SET_TA_TRANSFERS_LOADING = 'SET_TRANSFERS_LOADING';
export const GET_ACCOUNT_USERS_SUCCESS = 'GET_ACCOUNT_USERS_SUCCESS';
export const SET_ACCOUNT_USERS_LOADING = 'SET_ACCOUNT_USERS_LOADING';
export const GET_ISSUER_DOCUMENTS_SUCCESS = 'GET_ISSUER_DOCUMENTS_SUCCESS';
export const SET_ISSUER_DOCUMENTS_LOADING = 'SET_ISSUER_DOCUMENTS_LOADING';
