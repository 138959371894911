import * as profileTypes from './profileTypes';

const initialState = {};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case profileTypes.GET_PROFILE_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        case profileTypes.CLEAR_PROFILE_SUCCESS:
            return initialState;
        case profileTypes.UPDATE_PREFERENCES_SUCCESS:
            return {
                ...state,
                preferred_tabs: action.payload
            }
        case profileTypes.UPDATE_PREFERRED_CAP_TABLE_COLUMNS_SUCCESS:
            return {
                ...state, 
                preferred_cap_table_columns: action.payload
            }
        default:
            return state;
    }
};

export default profileReducer;
