/**
 * Constants for blockchain.
 */

export const NEXUS_BROWSER = 'https://explorer.digtl.co';
export const LIQUID_BROWSER = 'https://blockstream.info/liquid/';

export const CREATE_WALLET_STEP = 'Create a wallet'
export const REGISTER_HOLDERS_STEP = 'Register holders'
export const ISSUE_ASSET_STEP = 'Issue the asset'
export const WHITELIST_STEP = 'Whitelist the asset and holders'
export const FUND_WALLET_STEP = 'Fund the wallet'
export const DISTRIBUTE_ASSETS_STEP = 'Distribute your asset'


// eslint-disable-next-line no-useless-escape
export const domainRegMatch = /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
export const numberRegMatch = /^(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)$/