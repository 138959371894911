import React from 'react';
import { Badge, Box, Menu, IconButton } from '@mui/material';
import Tooltip from './Tooltip';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export default function MenuWithContext(props) {
    const [anchorEl, setAnchorEl] = React.useState(false);

    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <Badge invisible={!props.badge} badgeContent={props.badge} max={999}>
            <Box>
                <Tooltip title={props.title} placement="top">
                    <IconButton
                        aria-label="filter-toggle"
                        onMouseDown={handleClick}
                        disabled={props.disabled}
                    >
                        {!!props.disabled ? (
                            <MoreHorizIcon />
                        ) : (
                            <MoreHorizIcon />
                        )}
                    </IconButton>
                </Tooltip>

                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    onClick={handleClose}
                >
                    {props.children}
                </Menu>
            </Box>
        </Badge>
    );
}
