import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useSnackbar } from 'notistack';
import * as config from './../../../config';
import request from '../../../utilities/request';
import { isViewMode } from '../../../utilities/utils';
import Grid from '@mui/material/Grid';
import authStyles from '../authStyles';
import Humanize from 'humanize-plus';
import Tooltip from '@mui/material/Tooltip';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import DataTable from '../../../components/DataTable/MuiDataTable';
import { DateFormatter } from '../../../utilities/formatters';
import CircularProgress from '@mui/material/CircularProgress';
import * as transferActions from '../../../store/transfer/transferActions';
import LockedTile from '../../../components/LockedTile';
import { SUBSCRIPTION_PRO } from '../../../constants/profile';

const Approvals = (props) => {
    const classes = authStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [isUpdating, setIsUpdating] = useState(false)

    useEffect(() => props.transferActions.getPendingTransfers(), [
        props.transferActions,
    ]);


    const updateTransferStatus = (id, status, action) => {
        setIsUpdating(true)
        request
            .put(`${config.API_BASE_URL}/transfers/` + action + `/` + id)
            .then((response) => {
                enqueueSnackbar(
                    'Transfer has been ' + status + ' successfully',
                    {
                        variant: 'success',
                    }
                );

                props.transferActions.getPendingTransfers();
                setIsUpdating(false)
            })
            .catch((error) => {
                enqueueSnackbar(
                    error.response.data.message
                        ? error.response.data.message
                        : 'Error occurred',
                    {
                        variant: 'error',
                    }
                );
                setIsUpdating(false)
            });
    };

    return (
        <LockedTile
            requiredPlan={SUBSCRIPTION_PRO}
            lock={isViewMode(props.profile)}
        >
            <Grid container style={{ marginTop: 10 }}>
                {props.loading ? (
                    <Grid container item xs={12} justifyContent="center">
                        <CircularProgress />
                    </Grid>
                ) : (
                    <DataTable
                        title={'Confirm transfers'}
                        data={props.transfers ? props.transfers.data : []}
                        columns={[
                            {
                                label: 'From',
                                name: 'sender',
                                options: {
                                    customBodyRender: (value) => (
                                        <span>
                                            {value && value.first_name
                                                ? value.first_name
                                                : '-'}
                                        </span>
                                    ),
                                },
                            },
                            {
                                label: 'To',
                                name: 'receiver',
                                options: {
                                    customBodyRender: (value) => (
                                        <span>
                                            {value && value.first_name
                                                ? value.first_name
                                                : '-'}
                                        </span>
                                    ),
                                },
                            },
                            {
                                label: 'Created at',
                                name: 'created_at',
                                options: {
                                    customBodyRender: (value) => (
                                        <span>
                                            {DateFormatter({
                                                value: value,
                                                format: 'date',
                                            })}
                                        </span>
                                    ),
                                },
                            },
                            /*{
                            label: 'Submitted By',
                            name: 'submitted_by_id',
                        },*/
                            {
                                label: 'Equity Description',
                                name: 'equity',
                                options: {
                                    customBodyRender: (value) => (
                                        <span>{value.description}</span>
                                    ),
                                },
                            },
                            {
                                label: 'Ticker',
                                name: 'equity',
                                options: {
                                    customBodyRender: (value) => (
                                        <span>{value.ticker}</span>
                                    ),
                                },
                            },
                            {
                                label: 'Amount',
                                name: 'amount',
                                options: {
                                    customBodyRender: (value) => (
                                        <span
                                            style={{
                                                color: '#3E9CD9',
                                            }}
                                        >
                                            <b>
                                                {Humanize.formatNumber(
                                                    value,
                                                    2
                                                )}
                                            </b>
                                        </span>
                                    ),
                                },
                            },
                            {
                                label: 'Type',
                                name: 'type',
                                options: {
                                    customBodyRender: (value) => (
                                        <span>
                                            {value ? value.toUpperCase() : ''}
                                        </span>
                                    ),
                                },
                            },
                            {
                                label: 'Is routine',
                                name: 'is_routine',
                                options: {
                                    customBodyRender: (value) => (
                                        <div>
                                            {value ? 'ROUTINE' : 'NON-ROUTINE'}
                                        </div>
                                    ),
                                },
                            },

                            {
                                label: 'Actions',
                                name: 'id',
                                options: {
                                    download: false,
                                    print: false,
                                    customBodyRender: (value) => {
                                        return (
                                            <React.Fragment>
                                               <Tooltip title="Approve">
                                                  <IconButton
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        className="m-r-5"
                                                        onClick={() => {
                                                            updateTransferStatus(
                                                                value,
                                                                'approved',
                                                                'approve'
                                                            );
                                                        }}
                                                        disabled={isUpdating}
                                                    >
                                                        <DoneIcon
                                                            className={
                                                                classes.greenIcon
                                                            }
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Deny">
                                                    <IconButton
                                                        variant="contained"
                                                        color="secondary"
                                                        size="small"
                                                        className="m-r-5"
                                                        onClick={() => {
                                                            updateTransferStatus(
                                                                value,
                                                                'rejected',
                                                                'reject'
                                                            );
                                                        }}
                                                        disabled={isUpdating}
                                                    >
                                                        <ClearIcon
                                                            className={
                                                                classes.redIcon
                                                            }
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </React.Fragment>
                                        );
                                    },
                                },
                            },
                        ]}
                        shareSort={3}
                    />
                )}
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}></Grid>
                        <Grid item xs={12} sm={6}></Grid>
                    </Grid>
                </Grid>
            </Grid>
        </LockedTile>
    );
};

const mapStateToProps = ({ profile, transfers }) => {
    return {
        profile: profile,
        transfers: transfers.data,
        loading: transfers.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        transferActions: bindActionCreators(transferActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Approvals);
