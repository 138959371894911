import { Button, CircularProgress } from '@mui/material'
import React from 'react'

const SubmitButton = ({ text, disabled, loading, variant, color, onClick }) => {
    return (
        <Button
            type={onClick ? null : 'submit'}
            variant={variant || 'contained'}
            color={color || 'primary'}
            disabled={disabled}
            onClick={onClick}
            loading={loading}
        >
            {
                loading ?
                    <>
                        <CircularProgress size={12} />&nbsp;
                        {text}
                    </> :
                    text
            }
        </Button>
    )
}

export default SubmitButton