import React from 'react'
import PropTypes from 'prop-types';

import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

const SubmitButton = (props) => {
    const { 
        loading, variant, color, onClick, text
    } = props

    return (
        <Button
            variant={variant || "contained"}
            color={color || "primary"}
            onClick={onClick}
            disabled={loading}
        >
            {loading ? <CircularProgress size={24} /> : text}
        </Button>
    )
}

SubmitButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    variant: PropTypes.string,
    color: PropTypes.string,
    loading: PropTypes.bool,
}

export default SubmitButton