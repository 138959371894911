import * as categoryTypes from './categoryTypes'

const initialState = {
    data: null,
    loading: true
}

const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case categoryTypes.GET_CATEGORIES_SUCCESS:
            return {
                ...state, 
                data: action.payload 
            }
        case categoryTypes.SET_CATEGORIES_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        default:
            return state
    }
};

export default categoryReducer;

