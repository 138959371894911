import React, { useState } from 'react';
import {
    Switch,
    Route,
    useRouteMatch,
    NavLink,
    useLocation,
} from 'react-router-dom';

import Container from '@mui/material/Container';
import StyledTabs from '../components/Tabs/StyledTabs';
import StyledMainTab from '../components/Tabs/StyledMainTab';
import Header from '../components/Header/Header';
import Footer from '../components/Footer';
import TransferAgentUserRoutes from './transferAgent/TransferAgentUserRoutes';

import { connect } from 'react-redux';
import Loading from 'react-fullscreen-loading';

import LogoImage from '../assets/img/new_logo.png';
import { ISSUERS } from '../constants/routes';
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AdminRoutes = (props) => {
    let { path } = useRouteMatch();
    const location = useLocation();

    const getActiveTab = () => {
        switch (location.pathname) {
            case '/transfer-agent/':
            case '/transfer-agent/issuers':
                return 0;
            case '/transfer-agent/ta-partners':
                return 1;
            case '/transfer-agent/reports':
                return 2;
            case '/transfer-agent/transfers':
                return 3;
            case '/transfer-agent/preferences':
                return 4;
            case '/transfer-agent/messages':
                return 5;
            case '/transfer-agent/manage-accounts':
                return 6;
            default:
                return 0;
        }
    };

    const [value, setValue] = useState(getActiveTab());
    return (
        <Container className="main-container">
            {/* Loading screen */}
            <Loading
                loading={!props.profile.preferred_tabs}
                background="#fff"
                loaderColor="#004a80"
            />
            <Header type="admin" companyLogo={LogoImage} />
            {props.profile.preferred_tabs && (
                <React.Fragment>
                    <main>
                        <StyledTabs
                            scrollButtons="auto"
                            value={value}
                            onChange={(e, v) => setValue(v)}
                            variant="scrollable"
                            aria-label="simple tabs example"
                            style={{
                                backgroundColor: '#fff',
                                color: '#000',
                            }}
                            TabIndicatorProps={{
                                style: { background: '#e0e0e0' },
                            }}
                        >
                            <StyledMainTab
                                value={0}
                                label={
                                    value === ISSUERS ? (
                                        <b>Issuers</b>
                                    ) : (
                                        'Issuers'
                                    )
                                }
                                to={`${path}/`}
                                component={NavLink}
                                {...a11yProps(0)}
                            />

                            <StyledMainTab
                                value={1}
                                label={'TA Partners'}
                                to={`${path}/ta-partners`}
                                component={NavLink}
                                {...a11yProps(1)}
                            />

                            <StyledMainTab
                                value={2}
                                label={'Reports'}
                                to={`${path}/reports`}
                                component={NavLink}
                                {...a11yProps(2)}
                            />
                            <StyledMainTab
                                value={3}
                                label={'Transfers'}
                                to={`${path}/transfers`}
                                component={NavLink}
                                {...a11yProps(3)}
                            />
                            <StyledMainTab
                                value={4}
                                label={'Preferences'}
                                to={`${path}/preferences`}
                                component={NavLink}
                                {...a11yProps(4)}
                            />
                            {/*<StyledMainTab
                                value={5}
                                label={'Messages'}
                                to={`${path}/messages`}
                                component={NavLink}
                                {...a11yProps(5)}
                            />*/}
                            <StyledMainTab
                                value={6}
                                label={'Manage Accounts'}
                                to={`${path}/manage-accounts`}
                                component={NavLink}
                                {...a11yProps(6)}
                            />
                        </StyledTabs>
                        <Switch>
                            <Route
                                path={`${path}/issuers`}
                                component={TransferAgentUserRoutes}
                            />
                            <Route
                                path={path}
                                component={TransferAgentUserRoutes}
                            />
                        </Switch>{' '}
                    </main>
                </React.Fragment>
            )}
            <Footer hideRelease={true} />
        </Container>
    );
};

const mapStateToProps = ({ profile }) => {
    return {
        profile: profile,
    };
};

export default connect(mapStateToProps, null)(AdminRoutes);
