import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import * as Yup from 'yup';
import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormikSelect from '../../../../components/Formik/FormikSelect';

import TextField from '../../../TextField/TextField';
import * as liquidBlockstreamActions from '../../../../store/liquidBlocksteam/liquidBlockstreamActions';
import { wallet, assets } from '../../api';
import { errorParser } from '../../utils/errorParser';
import { snackbar } from '../../../../utilities/snackbarUtils';

const formData = {
    name: '',
    amount: '',
    destinationAddress: '',
    domain: '',
    ticker: '',
    precision: '',
    pubkey: '',
    isConfidential: false,
    isReissuable: false,
    reissuanceAmount: '',
    reissuanceAddress: '',
    transferRestricted: false,
};

const validationSchema = Yup.object({
    amount: Yup.number()
        .required('Please enter an Amount.')
        .min(1, 'Minimal amount is 1')
        .max(2100000000000000, 'Maximum amount is 2100000000000000')
        .notOneOf(['0'], 'The Amount cannot be 0.'),
    domain: Yup.string()
        .required('Please enter a domain.')
        .matches(
            /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/,
            'Must be a valid domain name format, for example: example.com or sub.example.com. Do not include the http/s or www prefixes.'
        )
        .min(4, 'Domain less than 4 characters')
        .max(255, 'Domain longer than 255 characters'),
    name: Yup.string()
        .required('Please enter a name.')
        .max(255, 'Name longer than 255 characters')
        .min(5, 'Name less than 5 characters'),
    ticker: Yup.string()
        .required('Enter a ticker')
        .matches(
            /^([A-z]|\.|-)+$/,
            'Valid characters are a to z, A to Z, ‘.’ and ‘-‘.'
        )
        .min(3, 'Ticker less than 3 characters')
        .max(5, 'Ticker longer than 5 characters'),
    precision: Yup.number()
        .max(8, 'Maximum precision is 8')
        .notOneOf(['0'], 'The precision cannot be 0'),
    isConfidential: Yup.boolean().required("This field can't be empty."),
    isReissuable: Yup.boolean().required("This field can't be empty."),
    transferRestricted: Yup.boolean().required("This field can't be empty."),
    wallet: Yup.string().required('You should select a wallet.'),
});

const IssuingNewToken = () => {
    const dispatch = useDispatch();
    const { wallets } = useSelector((state) => state.liquidBlockstream);
    const [isReissuable, setIsReissuable] = useState(false);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [walletAddresses, setWalletAddresses] = useState({
        reissuanceAddress: '',
        destinationAddress: '',
        pubkey: '',
    });

    const getWalletAddresses = async (walletName) => {
        try {
            const data = (await wallet.getWalletAddresses(walletName)).data;
            if (data && data.length)
                setWalletAddresses({
                    destinationAddress: data[0].address,
                    reissuanceAddress: data[1].address,
                    pubkey: data[0].pubkey,
                });
        } catch (err) {
            snackbar.error(errorParser(err));
        }
    };

    const issuingToken = (data, setSubmitting, reset) => {
        assets
            .issueNew(data)
            .then((res) => {
                reset();
                snackbar.success('Asset successfully issued.');
                dispatch(liquidBlockstreamActions.getAssets());
            })
            .catch((err) => snackbar.error(errorParser(err)))
            .finally(() => {
                setFormSubmitting(false);
                setSubmitting(false);
            });
    };

    return (
        <Formik
            initialValues={formData}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                // assign the fetched addresses to the form fields
                values.reissuanceAddress = walletAddresses.reissuanceAddress;
                values.destinationAddress = walletAddresses.destinationAddress;
                values.pubkey = walletAddresses.pubkey;

                // remove the wallet
                delete values.wallet;

                let data = cloneDeep(values);
                data.amount = parseInt(data.amount);
                data.precision = parseInt(data.precision);
                data.reissuanceAmount = isReissuable
                    ? parseInt(data.reissuanceAmount)
                    : 0;
                data.reissuanceAddress = isReissuable
                    ? data.reissuanceAddress
                    : '';
                issuingToken(data, setSubmitting, resetForm);
            }}
        >
            {({ submitForm, setSubmitting, resetForm, errors, setFieldValue }) => (
                <Form noValidate style={{ width: '100%' }}>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                    >
                        <Grid item xs={12} md={6}>
                            <Field
                                component={TextField}
                                name="amount"
                                label="Amount"
                                type="number"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                helperText="Total amount of the equity that will be issued."
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={TextField}
                                name="domain"
                                label="Domain"
                                type="text"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Field
                                component={FormikSelect}
                                name="wallet"
                                variant="outlined"
                                formHelperText={{
                                    children:
                                        'Select the wallet you want to issue the asset for',
                                }}
                                label="Wallets *"
                                fullWidth
                                required
                                onChange={(e) => {
                                    setFieldValue('wallet', e.target.value)
                                    getWalletAddresses(e.target.value)
                                }}
                            >
                                {wallets.map((wallet, index) => (
                                    <MenuItem key={index} value={wallet.name}>
                                        {wallet.name}
                                    </MenuItem>
                                ))}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={TextField}
                                type="text"
                                name="name"
                                label="Name"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={TextField}
                                type="text"
                                name="ticker"
                                label="Ticker"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={TextField}
                                type="number"
                                name="precision"
                                label="Precision"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Field
                                component={TextField}
                                type="number"
                                name="reissuanceAmount"
                                label="Reissuance Amount"
                                disabled={!isReissuable}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Field
                                component={CheckboxWithLabel}
                                type="checkbox"
                                name="transferRestricted"
                                Label={{
                                    label: 'Transfer Restricted',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Field
                                component={CheckboxWithLabel}
                                type="checkbox"
                                name="isConfidential"
                                Label={{
                                    label: 'Is Confidential',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Field
                                component={CheckboxWithLabel}
                                type="checkbox"
                                name="isReissuable"
                                Label={{
                                    label: 'Is Reissuable',
                                }}
                                onClick={() => setIsReissuable(!isReissuable)}
                            />
                        </Grid>

                        {/** read-only fields: auto-filled on wallet selection */}

                        <Grid item xs={12} md={12}>
                            <Field
                                component={TextField}
                                name="destinationAddress"
                                label="Destination Address"
                                type="text"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                disabled
                                value={walletAddresses.destinationAddress}
                                placeholder="Auto-filled upon selecting a wallet"
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Field
                                component={TextField}
                                type="text"
                                name="pubkey"
                                label="Pubkey"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                disabled
                                value={walletAddresses.pubkey}
                                placeholder="Auto-filled upon selecting a wallet"
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Field
                                component={TextField}
                                type="text"
                                name="reissuanceAddress"
                                label="Reissuance Address"
                                variant="outlined"
                                margin="normal"
                                placeholder="Auto-filled upon selecting a wallet"
                                disabled
                                value={walletAddresses.reissuanceAddress}
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={submitForm}
                                disabled={formSubmitting}
                            >
                                Issue a Token
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default IssuingNewToken;
