import makeStyles from '@mui/styles/makeStyles';

const dashGridStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        gridColumnGap: '2em',
        gridRowGap: '2rem',
        padding: '2rem',
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr !important',
            gridTemplateRows: '1fr !important',
        },
    },
}));

export default dashGridStyles;
