import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';

import { snackbar } from '../../../../utilities/snackbarUtils';

const CreateNewAccountDialog = ({ open, handleClose }) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Create a new account</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This action is going to create a <b>new wallet account</b>{' '}
                    for the holder.
                    <br />
                    A holder may use different accounts to separate their
                    digital assets and securities.
                    <br />
                    <br />
                    Do you want to proceed?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        handleClose();
                        snackbar.success('New account successfully created.');
                    }}
                >
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateNewAccountDialog;
