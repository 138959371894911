import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import authStyles from '../../authStyles';
import fundraiseOfferingStyles from '../../../../components/styles/fundraiseOfferingStyles';

import FormikDatePicker from '../../../../components/Formik/FormikDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { DateFormatter } from '../../../../utilities/formatters';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '../../../../components/TextField/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CheckboxWithLabel } from 'formik-mui';
import FundButtonGroup from '../FundButtonGroup';
const validationSchema = Yup.object({
    target_amount: Yup.string().required('Please enter target amount'),
    offering_name: Yup.string().required('Please enter issuer offering name'),
});
const OfferingDetails = (props) => {
    const classes = authStyles();
    const offeringClasses = fundraiseOfferingStyles();
    const [brokerDealerService, setBrokerDealerService] = useState(
        props.initialValues.broker_dealer_service
            ? props.initialValues.broker_dealer_service === true
                ? 'yes'
                : 'no'
            : 'no'
    );

    const [offeringExpirationDate, setOfferingExpirationDate] = useState(
        props.initialValues.offering_expiration_date
            ? props.initialValues.offering_expiration_date
            : new Date()
    );
    const [newFile, setNewFile] = useState('');

    const fundTransferMethods = [
        { name: 'ACH (Electronic Checks)', value: 'ach' },
        { name: 'Non-US Bank Transfers', value: 'non_us_bank_transfer' },
        { name: 'Checks', value: 'check' },
        { name: 'Wires', value: 'wire' },
    ];

    const acceptedInvestorTypes = [
        { name: 'Allow accredited holders?', value: 'accredited' },
        { name: 'Allow non-accredited holders?', value: 'non_accredited' },
        { name: 'Allow US holders?', value: 'us' },
        { name: 'Allow non-US holders?', value: 'non_us' },
    ];

    const nextStep = () => {
        props.nextStep();
    };

    const previousStep = () => {
        props.previousStep();
    };

    const closeDialog = () => {
        props.closeDialog();
    };

    const changeBrokerDealerService = (e) => {
        setBrokerDealerService(e.target.value);
    };

    const handleDateChange = (date, type) => {
        let newDate = DateFormatter({
            value: date,
            format: 'date',
        });

        setOfferingExpirationDate(newDate);
    };

    const uploadFile = (file) => {
        setNewFile(file.name);
        props.formData.set('ppm_file', file, file.name);
    };

    return (
        <div id="step6">
            <Formik
                initialValues={props.initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    if (brokerDealerService === 'yes') {
                        values['broker_dealer_service'] = true;
                    } else {
                        values['broker_dealer_service'] = false;
                    }
                    values['offering_expiration_date'] = offeringExpirationDate;
                    props.saveValues(values);
                    nextStep();
                    props.changeKey();
                }}
            >
                {({ setFieldValue }) => (
                    <Container maxWidth="lg">
                        <Form className={classes.form} noValidate>
                            <Typography component="div">
                                <b>Use broker-dealer services?</b>
                            </Typography>
                            <br />
                            <RadioGroup
                                name="broker_dealer_service"
                                row
                                required
                                defaultValue={brokerDealerService}
                            >
                                <FormControlLabel
                                    value={'no'}
                                    control={<Radio color="primary" />}
                                    label="No, broker-dealer execution is not needed."
                                    labelPlacement="end"
                                    onChange={changeBrokerDealerService}
                                />
                                <FormControlLabel
                                    value={'yes'}
                                    control={<Radio color="primary" />}
                                    label="Yes, have broker-dealer execute transactions."
                                    labelPlacement="end"
                                    onChange={changeBrokerDealerService}
                                />
                            </RadioGroup>

                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Offering Name"
                                        name="offering_name"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                    >
                                        <Field
                                            component={FormikDatePicker}
                                            name="offering_expiration_date"
                                            value={offeringExpirationDate}
                                            label="Offering Expiration Date"
                                            onChange={(date) =>
                                                handleDateChange(
                                                    date,
                                                    'offering_expiration_date'
                                                )
                                            }
                                            format="MM/dd/yyyy"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                            <hr className={offeringClasses.horizontalLine} />
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="How much are you raising (aka target amount/goal)?"
                                        name="target_amount"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        numeric={1}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Minimum raise amount needed to break escrow?"
                                        name="break_escrow_minimum_raise"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        placeholder={'optional'}
                                        numeric={1}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Maximum raise amount allowed?"
                                        name="maximum_raise_amount"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        placeholder={'optional'}
                                        numeric={1}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Minimum investor amount allowed?"
                                        name="minimum_investor_amount_allowed"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        placeholder={'optional'}
                                        numeric={1}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Investment Increment:"
                                        name="investment_increment"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        placeholder={'optional'}
                                        numeric={1}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Maximum investor amount allowed?"
                                        name="maxmum_investor_amount_allowed"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        placeholder={'optional'}
                                        numeric={1}
                                    />
                                </Grid>
                            </Grid>
                            <Typography component="div">
                                <b>Accepted funds transfer methods:</b>
                            </Typography>
                            <Grid container spacing={1}>
                                {fundTransferMethods.map(
                                    (fundTransferMethod) => (
                                        <Grid>
                                            <Field
                                                component={CheckboxWithLabel}
                                                type="checkbox"
                                                name={fundTransferMethod.value}
                                                Label={{
                                                    label: (
                                                        <div
                                                            className={
                                                                classes.checkbox
                                                            }
                                                        >
                                                            {
                                                                fundTransferMethod.name
                                                            }
                                                        </div>
                                                    ),
                                                }}
                                                required
                                            />
                                        </Grid>
                                    )
                                )}
                            </Grid>
                            <hr className={offeringClasses.horizontalLine} />
                            <Typography component="div">
                                <b>Accept the following types of holders:</b>
                            </Typography>
                            <Grid container spacing={1}>
                                {acceptedInvestorTypes.map(
                                    (acceptedInvestorType) => (
                                        <Grid>
                                            <Field
                                                component={CheckboxWithLabel}
                                                type="checkbox"
                                                name={
                                                    acceptedInvestorType.value
                                                }
                                                Label={{
                                                    label: (
                                                        <div
                                                            className={
                                                                classes.checkbox
                                                            }
                                                        >
                                                            {
                                                                acceptedInvestorType.name
                                                            }
                                                        </div>
                                                    ),
                                                }}
                                                required
                                            />
                                        </Grid>
                                    )
                                )}
                            </Grid>
                            <hr className={offeringClasses.horizontalLine} />
                            <Typography component="div">
                                <b>PPM document:</b>
                            </Typography>

                            <br />
                            <Grid container spacing={1}>
                                <Button
                                    component="label"
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                >
                                    Browse
                                    <input
                                        onChange={(e) => {
                                            uploadFile(e.target.files[0]);
                                        }}
                                        style={{ display: 'none' }}
                                        type="file"
                                        name="ppm_file"
                                        accept="*"
                                    />
                                </Button>
                            </Grid>
                            <br />
                            {newFile !== '' ? (
                                <Typography component="div">
                                    {newFile}
                                </Typography>
                            ) : null}
                            <hr className={offeringClasses.horizontalLine} />
                            <Field
                                component={CheckboxWithLabel}
                                type="checkbox"
                                name="fill_investment_by_advisor"
                                Label={{
                                    label: (
                                        <div className={classes.checkbox}>
                                            Allow investment to be filled out by
                                            a financial advisor?
                                        </div>
                                    ),
                                }}
                            />
                            <br />
                            <Field
                                component={CheckboxWithLabel}
                                type="checkbox"
                                name="manually_enter_promo_codes"
                                Label={{
                                    label: (
                                        <div className={classes.checkbox}>
                                            Allow the investor to manually enter
                                            promotional codes?
                                        </div>
                                    ),
                                }}
                            />
                            <br />
                            <Field
                                component={CheckboxWithLabel}
                                type="checkbox"
                                name="custom_disclaimer_required"
                                Label={{
                                    label: (
                                        <div className={classes.checkbox}>
                                            Require holders to accept custom
                                            disclaimer?
                                        </div>
                                    ),
                                }}
                            />
                            <FundButtonGroup
                                previousStep={previousStep}
                                closeDialog={closeDialog}
                            />
                        </Form>
                    </Container>
                )}
            </Formik>
        </div>
    );
};

export default OfferingDetails;
