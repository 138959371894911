import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import * as dialogActions from '../store/dialog/dialogActions'

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

/**
 * Redux-controlled confirmation dialog.
 */
const ConfirmationDialog = (props) => {
    const { dialog, dialogActions } = props

    /* Generate aria-labelledby attribute */
    const kebabCase = (text) => {
        if (text) {
            return text.replace(' ', '-').toLowerCase()
        }
        return "dialog"
    }

    return (
        <Dialog
            open={dialog.open}
            onClose={dialogActions.closeDialog}
            aria-labelledby={kebabCase(dialog.title)}>
            <DialogTitle>{dialog.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {dialog.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={dialogActions.closeDialog} variant="contained" color="secondary">
                    {dialog.cancelText}
                </Button>
                <Button onClick={dialog.success} variant="contained" color="primary" type="submit" disabled={dialog.loading}>
                    {dialog.loading ? <CircularProgress size={24} /> : dialog.confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = ({ dialog }) => {
    return {
        dialog
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dialogActions: bindActionCreators(dialogActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationDialog)
