import request from '../../utilities/request';
import * as config from '../../config';
import * as investorDocumentTypes from './investorInviteTypes';

export const getInvestorInvitesSuccess = (summary) => {
    return {
        type: investorDocumentTypes.GET_INVESTOR_INVITES_SUCCESS,
        payload: summary,
    };
};

export const setInvestorInvitesLoading = (loading) => {
    return {
        type: investorDocumentTypes.SET_INVESTOR_INVITES_LOADING,
        payload: loading,
    };
};

export const getInvestorInvites = () => {
    return (dispatch) => {
        dispatch(setInvestorInvitesLoading(true));
        request
            .get(`${config.API_BASE_URL}/investors/invites`)
            .then((res) => {
                dispatch(getInvestorInvitesSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setInvestorInvitesLoading(false));
            });
    };
};
