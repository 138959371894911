import React from 'react';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Tooltip,
    Typography,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import PersonOffIcon from '@mui/icons-material/PersonOff';

import useHoldersByCategory from '../../../../api/blockchain/useHoldersByCategory';
import distributeAsset from '../../../../api/blockchain/distributeAsset';

import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import ComboBox from '../../../../components/Form/ComboBox';
import Input from '../../../../components/Form/Input';
import SubmitButton from '../../../../components/Form/SubmitButton';
import { numberRegMatch } from '../../../../constants/blockchain';

import { snackbar } from '../../../../utilities/snackbarUtils';
import { errorParser } from '../../../../components/LiquidForm/utils/errorParser';

const SendDialog = ({ open, handleClose, asset }) => {
    const {
        holdersByCategory,
        holdersByCategoryLoading,
    } = useHoldersByCategory(asset?.requirements?.[0]);

    const schema = yup.object({
        receivers: yup.array().of(
            yup.object().shape({
                receiver: yup
                    .string()
                    .required('You have to select a receiver.')
                    .nullable(),
                amount: yup
                    .string()
                    .required('Please enter the amount.')
                    .matches(numberRegMatch, 'Please enter a valid amount.')
                    .nullable(),
            })
        ),
    });

    const defaultValues = {
        receivers: [{ receiver: '', amount: null }],
    };

    const {
        handleSubmit,
        control,
        reset,
        watch,
        formState: { isSubmitting, errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    const {
        fields: rFields,
        append: receiversAppend,
        remove: receiversRemove,
    } = useFieldArray({ control, name: 'receivers' });
    const watchReceiversFieldArray = watch('receivers');
    const receiversFields = rFields.map((field, index) => {
        return {
            ...field,
            ...watchReceiversFieldArray[index],
        };
    });

    const onSubmit = async (data) => {
        console.log(data);
        await distributeAsset({ asset_uuid: asset?.assetUuid, ...data })
            .then((response) => {
                snackbar.success(
                    'Asset distribution started. Transactions should settle in ~2 minutes.'
                );
                reset(defaultValues);
                handleClose();
                // props.updateAssets()
            })
            .catch((error) => {
                snackbar.error(errorParser(error));
            });
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                handleClose();
                reset(defaultValues);
            }}
            maxWidth="lg"
        >
            <DialogTitle>Send asset ({asset?.name})</DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <div style={{ display: 'flex', color: 'gray' }}>
                        <Typography variant="body2">
                            Distribute the asset to your holders
                        </Typography>
                    </div>
                    {
                        // Whitelist notice for assets
                        asset?.isAuthorized === false && (
                            <Alert
                                variant="outlined"
                                severity="info"
                                sx={{ marginTop: '10px' }}
                            >
                                It looks like your asset is{' '}
                                <b>not authorized</b> yet, which is required for
                                distribution. <br />
                                Authorization should happen automatically ~ 5
                                minutes after issuance, but if it does not,{' '}
                                <br />
                                you can trigger it manually from the asset's{' '}
                                <b>"Details"</b> screen.
                            </Alert>
                        )
                    }
                    {
                        // Whitelist notice for assets
                        asset?.requirements.length === 0 && (
                            <Alert
                                variant="outlined"
                                severity="info"
                                sx={{ marginTop: '10px' }}
                            >
                                It looks like you have not{' '}
                                <b>whitelisted your asset</b>, which is required
                                for distribution. <br />
                                You can do that from the <b>
                                    "Whitelists"
                                </b>{' '}
                                tab.
                            </Alert>
                        )
                    }
                    {
                        // Whitelist notice for holders
                        (!holdersByCategoryLoading &&
                            holdersByCategory &&
                            holdersByCategory.length) === 0 && (
                            <Alert
                                variant="outlined"
                                severity="info"
                                sx={{ marginTop: '10px' }}
                            >
                                It looks like you have no{' '}
                                <b>whitelisted holders</b> yet, which is
                                required for distribution. <br />
                                You can whitelist them from the{' '}
                                <b>"Whitelists"</b> tab.
                            </Alert>
                        )
                    }
                    <br />
                    <Grid container spacing={3}>
                        {receiversFields.map((field, index) => (
                            <React.Fragment key={field.id}>
                                <Grid item xs={12} sm={8}>
                                    <ComboBox
                                        options={holdersByCategory}
                                        optionLabel={'holder+account_name'}
                                        valueLabel="amp_user_id"
                                        disabled={
                                            !holdersByCategory ||
                                            holdersByCategoryLoading
                                        }
                                        loading={
                                            holdersByCategoryLoading &&
                                            asset?.requirements.length
                                        }
                                        label="Holder"
                                        name={`receivers.${index}.receiver`}
                                        placeholder="Search..."
                                        error={
                                            errors?.['receivers']?.[index]?.[
                                                'receiver'
                                            ]
                                        }
                                        leftIcon={<SearchIcon />}
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Input
                                        name={`receivers.${index}.amount`}
                                        error={
                                            errors?.['receivers']?.[index]?.[
                                                'amount'
                                            ]
                                        }
                                        control={control}
                                        label="Amount"
                                        placeholder="0"
                                        numeric
                                        endAdornment={
                                            receiversFields.length !== 1 && (
                                                <InputAdornment position="end">
                                                    <Tooltip title="Remove receiver">
                                                        <IconButton
                                                            onClick={() =>
                                                                receiversRemove(
                                                                    index
                                                                )
                                                            }
                                                            edge="end"
                                                        >
                                                            <PersonOffIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            )
                                        }
                                    />
                                </Grid>
                            </React.Fragment>
                        ))}
                        <Grid item xs={12}>
                            <Divider>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() =>
                                        receiversAppend({
                                            receiver: '',
                                            amount: null,
                                        })
                                    }
                                >
                                    <AddIcon />
                                    Add receiver
                                </Button>
                            </Divider>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <SubmitButton
                        text="Send"
                        disabled={isSubmitting || !asset?.isAuthorized}
                        loading={isSubmitting}
                    />
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default SendDialog;
