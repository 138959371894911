import request from '../../utilities/request';
import * as config from '../../config';
import * as paymentTypes from './paymentTypes';

import { snackbar } from '../../utilities/snackbarUtils'
import { getHost, parseError } from '../../utilities/utils';

/**
 * PRICE PLANS
 */
export const getPricePlansSuccess = (pricePlans) => {
    return {
        type: paymentTypes.GET_PRICE_PLANS_SUCCESS,
        payload: pricePlans,
    };
};

export const setPricePlansLoading = (loading) => {
    return {
        type: paymentTypes.SET_PRICE_PLANS_LOADING,
        payload: loading,
    };
};

export const setPricePlansError = (error) => {
    return {
        type: paymentTypes.SET_PRICE_PLANS_ERROR,
        payload: error,
    };
};

export const getPricePlans = () => {
    return (dispatch) => {
        dispatch(setPricePlansLoading(true));
        request
            .get(`${config.API_BASE_URL}/stripe/prices`)
            .then((res) => {
                dispatch(getPricePlansSuccess(res.data));
            })
            .catch((error) => {
                const errorMessage = parseError(error)
                dispatch(setPricePlansError(errorMessage))
                snackbar.error(errorMessage);
            })
            .finally(() => {
                dispatch(setPricePlansLoading(false));
            });
    };
};

export const createCheckoutSession = (data) => {
    return (dispatch) => {
        dispatch(setPricePlansLoading(true));
        request
            .post(`${config.API_BASE_URL}/stripe/pay`, data)
            .then((res) => {
                window.location.href = res.data.message;
                snackbar.success('Checkout session has been created successfully.');
            })
            .catch((error) => {
                const errorMessage = parseError(error)
                dispatch(setPricePlansError(errorMessage))
                snackbar.error(errorMessage);
            })
            .finally(() => {
                dispatch(setPricePlansLoading(false));
            });
    };
};

/**
 * PAYMENT PORTAL
 */
 export const getPaymentPortalSuccess = (paymentPortal) => {
    return {
        type: paymentTypes.GET_PAYMENT_PORTAL_SUCCESS,
        payload: paymentPortal,
    };
};

export const setPaymentPortalLoading = (loading) => {
    return {
        type: paymentTypes.SET_PAYMENT_PORTAL_LOADING,
        payload: loading,
    };
};

export const setPaymentPortalError = (error) => {
    return {
        type: paymentTypes.SET_PAYMENT_PORTAL_ERROR,
        payload: error,
    };
};

export const getPaymentPortal = () => {
    return (dispatch) => {
        dispatch(setPaymentPortalLoading(true));
        request
            .get(`${config.API_BASE_URL}/stripe/portal?return_url=${getHost() + '/issuer/preferences'}`)
            .then((res) => {
                dispatch(getPaymentPortalSuccess(res.data));
            })
            .catch((error) => {
                const errorMessage = parseError(error)
                dispatch(setPaymentPortalError(errorMessage))
                snackbar.error(errorMessage);
            })
            .finally(() => {
                dispatch(setPaymentPortalLoading(false));
            });
    };
};