import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from '@mui/material'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';

import useHoldersByCategory from '../../../../api/blockchain/useHoldersByCategory';
import useHoldersOutsideCategory from '../../../../api/blockchain/useHoldersOutsideCategory';
import assignHolderToCategory from '../../../../api/blockchain/assignHolderToCategory';
import removeHolderFromCategory from '../../../../api/blockchain/removeHolderFromCategory';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import ComboBox from '../../../../components/Form/ComboBox';
import SubmitButton from '../../../../components/Form/SubmitButton';
import { snackbar } from '../../../../utilities/snackbarUtils'
import { errorParser } from '../../../../components/LiquidForm/utils/errorParser';

import BCTable from '../Utils/BCTable';
import DataLoading from '../Utils/DataLoading';
import NoData from '../Utils/NoData';
import DataError from '../Utils/DataError';

const AssignShareholderDialog = ({ open, handleClose, category, updateHolders }) => {
    const schema = yup.object({
        holder_id: yup.string().required('Selecting a holder subaccount is required.').nullable()
    });

    const defaultValues = {
        holder_id: ''
    }

    const { handleSubmit, control, reset, formState: { isSubmitting, errors }, } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    const { holdersOutsideCategory, holdersOutsideCategoryLoading, updateHoldersOutsideCategory } = useHoldersOutsideCategory(category?.id)

    const onSubmit = async (data) => {
        await assignHolderToCategory(category?.id, data.holder_id).then(response => {
            snackbar.success('Succesfully whitelisted the holder subaccount');
            reset(defaultValues);
            handleClose()
            updateHolders()
            updateHoldersOutsideCategory()
        }).catch((error) => {
            snackbar.error(errorParser(error));
        })
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Whitelist a holder subaccount</DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <DialogContentText>
                        <div style={{ display: 'flex', color: 'gray' }}>
                            <Typography variant='body2'>
                                Assign holders' subaccounts to the whitelist to
                                enable them distributions and transfers.
                            </Typography>
                        </div>
                        <br />
                        <ComboBox
                            options={holdersOutsideCategory}
                            optionLabel={'holder+account_name'}
                            valueLabel={'amp_user_id'}
                            disabled={holdersOutsideCategoryLoading}
                            loading={holdersOutsideCategoryLoading}
                            label="Holder"
                            name="holder_id"
                            error={errors.holder_id}
                            control={control}
                            defaultValue={holdersOutsideCategory[0]}
                        />

                        <br />
                        <br />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <SubmitButton 
                        text='Assign'
                        disabled={isSubmitting}
                        loading={isSubmitting}
                    />
                </DialogActions>
            </form>
        </Dialog>
    )
}

const DeleteShareholderDialog = ({ open, handleClose, updateHolders, category, holder }) => {
    const [loading, setLoading] = React.useState(false)

    const onSubmit = async () => {
        setLoading(true)
        await removeHolderFromCategory(category?.id, holder?.amp_user_id).then(response => {
            snackbar.success('Succesfully removed whitelisting for the holder subaccount.');
            handleClose()
            updateHolders()
        }).catch((error) => {
            snackbar.error(errorParser(error));
        }).finally(() => {
            setLoading(false)
        })
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Holder Subaccount whitelisting</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div style={{ display: 'flex', color: 'gray' }}>
                        <Typography variant='body2'>
                            Are you sure you want to remove <b>{holder?.holder}: {holder?.account_name}</b> from the <b>"{category?.name}"</b> whitelist?
                        </Typography>

                    </div>
                    <br />
                    <br />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <SubmitButton 
                    text='Remove'
                    color='danger'
                    disabled={loading}
                    loading={loading}
                    onClick={onSubmit}
                />
            </DialogActions>
        </Dialog>
    )
}

const WhitelistHoldersDialog = ({ open, handleClose, category }) => {
    const [openAssignShareholder, setOpenAssignShareholder] = React.useState(false);
    const [openDeleteShareholder, setOpenDeleteShareholder] = React.useState(false);
    const [activeHolder, setActiveHolder] = React.useState(null);

    const handleOpenAssignShareholder = () => {
        setOpenAssignShareholder(true);
    };

    const handleCloseAssignShareholder = () => {
        setOpenAssignShareholder(false);
    };

    const handleOpenDeleteShareholder = (holder) => {
        setOpenDeleteShareholder(true);
        setActiveHolder(holder)
    };

    const handleCloseDeleteShareholder = () => {
        setOpenDeleteShareholder(false);
        setActiveHolder(null)
    };

    const { holdersByCategory, holdersByCategoryError, holdersByCategoryLoading, updateHoldersByCategory } = useHoldersByCategory(category?.id)

    return (
        <Dialog open={open} onClose={handleClose} maxWidth='lg'>
            <DialogTitle>Holder subaccount whitelisting</DialogTitle>
            <DialogContent>
                <Stack direction="row" alignItems="center" justifyContent='space-between' gap={12}>
                    <div>
                        <Typography variant='body1' as='h5'>
                            {category?.name}
                        </Typography>
                        <Typography variant='caption' as='p' sx={{ color: 'gray' }}>
                            {category?.description}
                        </Typography>
                    </div>
                    <div>
                        <Button variant="contained" size='small' startIcon={<AddIcon />}
                            onClick={handleOpenAssignShareholder}
                        >
                            Add Holder Subaccount
                        </Button>
                    </div>
                </Stack>
                <br />
                {/* AMP category data loading */}
                <DataLoading 
                    loading={holdersByCategoryLoading}
                    text='Loading your whitelisted subaccounts...'
                />

                {/* Wallet data loaded */}
                {
                    (!holdersByCategoryLoading && holdersByCategory) &&
                    <BCTable
                        columns={[
                            { name: 'holder', label: 'Holder', bold: true, extraInfoColumn: 'type' },
                            { name: 'account_name', label: 'Subaccount', bold: false },
                            {
                                name: 'actions', label: 'Actions', type: 'actions', render: (row) => (
                                    <Button variant="contained" color='danger' size="small" startIcon={<DeleteForeverIcon />}
                                        onClick={() => handleOpenDeleteShareholder(row)}
                                    >
                                        Remove
                                    </Button>
                                )
                            },
                        ]}
                        data={holdersByCategory}
                    />
                }
                <br />
                {/* No data */}
                <NoData 
                    show={!holdersByCategoryLoading && !holdersByCategoryError && holdersByCategory.length === 0}
                    text='This whitelist has no holders in it.'
                />

                {/* category error */}
                <DataError 
                    show={holdersByCategoryError && !holdersByCategory}
                    text='Could not load holders due to error. Please try again.'
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>

            {
                openAssignShareholder &&
                <AssignShareholderDialog open={openAssignShareholder} handleClose={handleCloseAssignShareholder} category={category} updateHolders={updateHoldersByCategory} />
            }
            {
                openDeleteShareholder &&
                <DeleteShareholderDialog open={openDeleteShareholder} handleClose={handleCloseDeleteShareholder} category={category} holder={activeHolder} updateHolders={updateHoldersByCategory} />
            }
        </Dialog>
    )
}

export default WhitelistHoldersDialog