import React from 'react';
import Humanize from 'humanize-plus';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import ClassIcon from '@mui/icons-material/Class';
import CategoryIcon from '@mui/icons-material/Category';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PieChartIcon from '@mui/icons-material/PieChart';
import LockIcon from '@mui/icons-material/Lock';

import withStyles from '@mui/styles/withStyles';
import infoDialogStyles from '../styles/infoDialogStyles';

const CategoryDialog = (props) => {
    const { shareCategory, classes, dialogIsOpen, setDialogIsOpen } = props;

    const closeDialog = () => {
        setDialogIsOpen(false);
    };

    return (
        <Dialog
            maxWidth="sm"
            fullWidth
            open={dialogIsOpen}
            onBackdropClick={(e) => setDialogIsOpen(false)}
        >
            <DialogTitle>Equity Information</DialogTitle>
            <DialogContent>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        {shareCategory.loading ? (
                            <Grid
                                container
                                item
                                xs={12}
                                justifyContent="center"
                            >
                                <CircularProgress />
                            </Grid>
                        ) : (
                            <List component="nav">
                                <ListItem>
                                    <ListItemAvatar>
                                        <ClassIcon
                                            className={classes.blue}
                                            fontSize="large"
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body2">
                                                <b>Category</b>
                                            </Typography>
                                        }
                                        secondary={
                                            shareCategory.category_description
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <CategoryIcon
                                            fontSize="large"
                                            className={classes.blue}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body2">
                                                <b>Parent class</b>
                                            </Typography>
                                        }
                                        secondary={shareCategory.class}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <PieChartIcon
                                            fontSize="large"
                                            className={classes.blue}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body2">
                                                <b>Total amount</b>
                                            </Typography>
                                        }
                                        secondary={
                                            Humanize.formatNumber(
                                                shareCategory.category_amount
                                            ) || 0
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <AttachMoneyIcon
                                            fontSize="large"
                                            className={classes.blue}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body2">
                                                <b>Price per unit</b>
                                            </Typography>
                                        }
                                        secondary={`${
                                            shareCategory.price || 'N/A'
                                        }`}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <LockIcon
                                            fontSize="large"
                                            className={classes.blue}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body2">
                                                <b>Transfer restrictions</b>
                                            </Typography>
                                        }
                                        secondary={`${
                                            shareCategory.transfer_restrictions
                                        } ${
                                            shareCategory.transfer_restrictions ===
                                            'custom'
                                                ? ' / ' +
                                                  shareCategory.lock_period +
                                                  ' days'
                                                : ''
                                        }`}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <AttachMoneyIcon
                                            fontSize="large"
                                            className={classes.blue}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body2">
                                                <b>Issued on blockchain?</b>
                                            </Typography>
                                        }
                                        secondary={`${
                                            shareCategory.issue_tx
                                                ? 'Yes'
                                                : 'No'
                                        }`}
                                    />
                                </ListItem>
                            </List>
                        )}
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <DialogActions className={classes.action}>
                            <Button
                                color="primary"
                                onClick={() => {
                                    closeDialog();
                                }}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default withStyles(infoDialogStyles)(CategoryDialog);
