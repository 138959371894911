import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import * as config from './../../../config';
import request from '../../../utilities/request';
import authStyles from '../authStyles';
import * as disclosureRequirementActions from '../../../store/disclosureRequirement/disclosureRequirementActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form, Field } from 'formik';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '../../../components/TextField/TextField';
import { DateFormatter } from '../../../utilities/formatters';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';

import FormikDatePicker from '../../../components/Formik/FormikDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import HelpTooltip from '../../../components/HelpTooltip/HelpTooltip';
import * as Yup from 'yup';

const validationSchema = Yup.object({
    name0: Yup.string().required('Name field is required'),
    title0: Yup.string().required('Title field is required'),
});
const EditDisclosureRequirementsDialog = (props) => {
    const classes = authStyles();

    const {
        open,
        data,
        dialogTitle,
        fieldLabel,
        inputType,
        initialValues,
        helptooltips,
    } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [dialogIsOpen, setDialogIsOpen] = useState(open);
    const [date, setDate] = useState(
        data.item_value ? data.item_value : new Date()
    );
    const [annualFinancials, setAnnualFinancials] = useState('');
    const [formData, setFormData] = useState(new FormData());
    const f = new FormData();
    f.append('item_value', '');

    const uploadFile = (file, name) => {
        f.set('item_value', file, file.name);
        setFormData(f);
        setAnnualFinancials(file.name);
    };

    const closeDialog = () => {
        setDialogIsOpen(false);
    };

    const handleDateChange = (date) => {
        let newDate = DateFormatter({
            value: date,
            format: 'date',
        });
        setDate(newDate);
    };

    const [affectedPersonsForm, setAffectedPersonsForm] = useState([]);
    const [rowNumber, setRowNumber] = useState(
        data.item_value && data.item_value.length
            ? data.item_value.length - 1
            : 0
    );

    let rowIds = [
        data.item_value && data.item_value.length
            ? data.item_value.length - 1
            : 0,
    ];

    const [removedRowIds, setRemovedRowIds] = useState([]);

    let affected_persons_form = [];

    const renderAffectedPersons = () => {
        let length = data.item_value.length > 0 ? data.item_value.length : 1;
        for (var i = 0; i < length; i++) {
            let rowId = i;
            affected_persons_form.push(
                <Grid
                    container
                    spacing={2}
                    key={rowId}
                    style={{ marginTop: '2em' }}
                >
                    <Grid item xs={12} sm={6} md={5}>
                        <Field
                            component={TextField}
                            type="text"
                            name={'name' + i}
                            variant="outlined"
                            required
                            label={'Name'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={5}>
                        <Field
                            component={TextField}
                            type="text"
                            name={'title' + i}
                            variant="outlined"
                            required
                            label={'Title'}
                        />
                    </Grid>
                    {i === 0 ? (
                        <Grid item xs={12} sm={6} md={2}>
                            <IconButton
                                onClick={() => {
                                    addRow();
                                }}
                                style={{ color: '#3E9CD9' }}
                            >
                                <AddIcon fontSize="small" />
                            </IconButton>
                        </Grid>
                    ) : (
                        <Grid item xs={12} sm={6} md={2}>
                            <IconButton
                                onClick={() => {
                                    removeRow(rowId);
                                }}
                                style={{ color: '#3E9CD9' }}
                            >
                                <DeleteForeverIcon fontSize="small" />
                            </IconButton>{' '}
                        </Grid>
                    )}{' '}
                </Grid>
            );
        }
    };
    if (inputType === 'affected_persons') {
        renderAffectedPersons();
    }

    const addRow = () => {
        let rowId = rowIds[rowIds.length - 1] + 1;
        rowIds.push(rowId);
        setRowNumber(rowId);

        affected_persons_form.push(
            <Grid
                container
                spacing={2}
                style={{ marginTop: '2em' }}
                key={rowId}
            >
                <Grid item xs={12} sm={6} md={5}>
                    <Field
                        component={TextField}
                        type="text"
                        name={'name' + rowId}
                        variant="outlined"
                        required
                        label={'Name'}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                    <Field
                        component={TextField}
                        type="text"
                        name={'title' + rowId}
                        variant="outlined"
                        required
                        label={'Title'}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <IconButton
                        onClick={() => {
                            removeRow(rowId);
                        }}
                        size="large">
                        <DeleteForeverIcon
                            fontSize="small"
                            style={{ color: '#3E9CD9' }}
                        />
                    </IconButton>
                </Grid>
            </Grid>
        );
        setAffectedPersonsForm(affected_persons_form);
    };

    const removeRow = (rowId) => {
        affected_persons_form = affected_persons_form.filter((element) => {
            return element.key !== rowId.toString();
        });

        setAffectedPersonsForm(affected_persons_form);
        removedRowIds.push(rowId);
        setRemovedRowIds(removedRowIds);
    };
    return (
        <React.Fragment>
            <Dialog
                maxWidth="sm"
                fullWidth
                // fullWidth={inputType !== 'date'}
                open={dialogIsOpen}
                onBackdropClick={(e) => setDialogIsOpen(false)}
            >
                <DialogTitle className={classes.dialogTitle}>
                    {dialogTitle}{' '}
                    {inputType === 'affected_persons' && (
                        <HelpTooltip title={helptooltips.affected_persons} />
                    )}
                    <br />
                </DialogTitle>
                <DialogContent>
                    <Formik
                        validationSchema={
                            inputType === 'affected_persons' && validationSchema
                        }
                        initialValues={
                            inputType === 'affected_persons' &&
                            data.item_value.length > 0
                                ? initialValues
                                : data
                        }
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            if (inputType === 'date') {
                                values['item_value'] = date;
                            } else if (inputType === 'boolean') {
                                values['item_value'] = true;
                            } else if (inputType === 'upload') {
                                formData.append(
                                    'item_to_update',
                                    'annual_financials'
                                );
                            } else if (inputType === 'affected_persons') {
                                values['item_to_update'] = 'affected_persons';
                                values['item_value'] = [];

                                for (var i = 0; i <= rowNumber; i++) {
                                    if (
                                        values['name' + i] !== undefined &&
                                        values['name' + i] !== '' &&
                                        values['name' + i] !== null &&
                                        values['title' + i] !== undefined &&
                                        values['title' + i] !== '' &&
                                        values['title' + i] !== null &&
                                        !removedRowIds.includes(i)
                                    ) {
                                        values['item_value'].push({
                                            name: values['name' + i],
                                            title: values['title' + i],
                                        });

                                        delete values['name' + i];
                                        delete values['title' + i];
                                    }
                                }
                            }

                            if (inputType === 'upload') {
                                request
                                    .post(
                                        `${config.API_BASE_URL}/disclosure-requirements/edit`,
                                        formData
                                    )
                                    .then((response) => {
                                        enqueueSnackbar(
                                            'Information has been updated successfully',
                                            {
                                                variant: 'success',
                                            }
                                        );

                                        props.disclosureRequirementActions.getDisclosureRequirements();

                                        setDialogIsOpen(false);
                                    })
                                    .catch(() => {});
                            } else {
                                request
                                    .post(
                                        `${config.API_BASE_URL}/disclosure-requirements/edit`,
                                        values
                                    )
                                    .then((response) => {
                                        enqueueSnackbar(
                                            'Information has been updated successfully',
                                            {
                                                variant: 'success',
                                            }
                                        );

                                        props.disclosureRequirementActions.getDisclosureRequirements();

                                        setDialogIsOpen(false);
                                    })
                                    .catch(() => {});
                            }
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form noValidate>
                                {inputType === 'date' && (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <br />
                                        <Field
                                            component={FormikDatePicker}
                                            name="item_value"
                                            value={date}
                                            //  placeholder="10/10/2018"
                                            label={fieldLabel}
                                            onChange={(date) =>
                                                handleDateChange(date)
                                            }
                                            //minDate={new Date()}
                                            fullWidth
                                            format="MM/dd/yyyy"
                                            variant="outlined"
                                        />
                                    </LocalizationProvider>
                                )}

                                {inputType === 'text' && (
                                    <Field
                                        component={TextField}
                                        name="item_value"
                                        type="text"
                                        label={fieldLabel}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        multiline
                                        rows={2}
                                    />
                                )}

                                {inputType === 'affected_persons' && (
                                    <Grid key={rowNumber}>
                                        {affectedPersonsForm.length
                                            ? affectedPersonsForm
                                            : affected_persons_form}
                                    </Grid>
                                )}

                                {inputType === 'boolean' && (
                                    <Typography>{fieldLabel}</Typography>
                                )}

                                {inputType === 'upload' && (
                                    <Typography>
                                        {annualFinancials !== null
                                            ? annualFinancials
                                            : ''}
                                    </Typography>
                                )}

                                <DialogActions
                                    style={{
                                        marginTop: '1.5rem',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Button
                                        color="secondary"
                                        variant="outlined"
                                        onClick={() => {
                                            closeDialog();
                                        }}
                                    >
                                        <b>Cancel</b>
                                    </Button>
                                    {inputType === 'upload' && (
                                        <ButtonGroup
                                            aria-label="outlined primary button group"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Button
                                                component="label"
                                                variant="contained"
                                                color="primary"
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                Upload
                                                <input
                                                    onChange={(e) => {
                                                        uploadFile(
                                                            e.target.files[0],
                                                            'item_value'
                                                        );
                                                    }}
                                                    style={{ display: 'none' }}
                                                    type="file"
                                                    name="item_value"
                                                />
                                            </Button>
                                        </ButtonGroup>
                                    )}
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                    >
                                        {!isSubmitting ? (
                                            <span>
                                                {inputType === 'boolean'
                                                    ? 'Confirm'
                                                    : 'Save'}
                                            </span>
                                        ) : (
                                            <CircularProgress size={24} />
                                        )}
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

const mapStateToProps = ({ disclosureRequirements }) => {
    return {
        disclosureRequirements: disclosureRequirements,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        disclosureRequirementActions: bindActionCreators(
            disclosureRequirementActions,
            dispatch
        ),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditDisclosureRequirementsDialog);
