import { parseError } from "../../../utilities/utils";

const errorParser = (error) => {
    try {
        // Liquid errors
        const errorData = error.response?.data;
        if (errorData?.error?.includes('{')) {
            return Object.keys(JSON.parse(errorData.error))
                .map((key) => `${key}: ${JSON.parse(errorData.error)[key]}`)
                .join(', ');
        } else if (errorData?.message) {
            return parseError(error)
        } else {
            return errorData.error;
        }
    } catch (e) {
        // other Laravel errors
        return parseError(error)
    }
};

export { errorParser };
