import request from '../../utilities/request';
import * as config from '../../config';
import * as listingDetailTypes from './listingDetailTypes';

export const getListingDetailsSuccess = (summary) => {
    return {
        type: listingDetailTypes.GET_LISTING_DETAILS_SUCCESS,
        payload: summary,
    };
};

export const setListingDetailsLoading = (loading) => {
    return {
        type: listingDetailTypes.SET_LISTING_DETAILS_LOADING,
        payload: loading,
    };
};

export const getListingDetails = () => {
    return (dispatch) => {
        dispatch(setListingDetailsLoading(true));
        request
            .get(`${config.API_BASE_URL}/listing-details`)
            .then((res) => {
                dispatch(getListingDetailsSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setListingDetailsLoading(false));
            });
    };
};
