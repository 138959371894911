import * as exchangeTypes from './exchangeTypes';

const initialState = {
    data: null,
    loading: true,
};

const exchangeReducer = (state = initialState, action) => {
    switch (action.type) {
        case exchangeTypes.GET_EXCHANGES_SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case exchangeTypes.SET_EXCHANGES_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        default:
            return state;
    }
};

export default exchangeReducer;
