import useSWR from "swr"
import { API_BASE_URL } from "../../config"
import fetcher from "../../utilities/fetcher"

const useCommunicationUsers = () => {
    const { data, error } = useSWR(`${API_BASE_URL}/messages/users`, fetcher)

    return {
        commsUsers: data,
        commsUsersLoading: !error && !data,
        commsUsersError: error
    }
}

export default useCommunicationUsers