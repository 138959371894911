import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { snackbar } from '../../../../utilities/snackbarUtils';
import { errorParser } from '../../utils/errorParser';
import { assets } from '../../api';
import TableComponent from '../Table';

const tableColumns = [
    { headerName: 'Type', field: 'type' },
    {
        headerName: 'Date', field: 'datetime', cellType: 'truncated',
        useTooltip: true,
        colProps: {
            width: '10%'
        }
    },
    {
        headerName: 'Description', field: 'description', cellType: 'truncated',
        useTooltip: true,
        colProps: {
            width: '10%'
        }
    },
    {
        headerName: 'TXID', field: 'txid', cellType: 'truncated',
        useTooltip: true,
        colProps: {
            width: '10%'
        }
    },
    { headerName: 'Vout', field: 'vout' },
    { headerName: 'Blockheight', field: 'blockheight' },
    {
        headerName: 'Asset Blinder', field: 'assetBlinder', cellType: 'truncated',
        useTooltip: true,
        colProps: {
            width: '10%'
        }
    },
    {
        headerName: 'Amount Blinder', field: 'amountBlinder', cellType: 'truncated',
        useTooltip: true,
        colProps: {
            width: '10%'
        }
    },
    { headerName: 'Registered User Id', field: 'registeredUserId' },
    { headerName: 'Amount', field: 'amount' },
];

const Activities = ({ assetUuid }) => {
    const [activities, setActivities] = useState();

    useEffect(() => {
        getActivities();
    }, [assetUuid]);

    const getActivities = async () => {
        try {
            const res = (await assets.getActivities(assetUuid)).data;
            setActivities(res);
        } catch (err) {
            snackbar.error(errorParser(err));
        }
    };

    return (
        <Box mb={2}>
            <Typography textAlign="center" variant="body1">
                Activities
            </Typography>
            <TableComponent columns={tableColumns} items={activities} />
        </Box>
    );
};

export default Activities;
