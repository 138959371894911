import { Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useDispatch } from 'react-redux';
import { setIssuanceStep } from "../../../../store/assetIssuance/assetIssuanceActions";

const useStyles = makeStyles({
    popOverRoot: {
        pointerEvents: "none"
    }
});

const BreadcrumbPopupMenu = ({ label, items, itemLabel }) => {
    let currentlyHovering = false;
    const styles = useStyles();
    const dispatch = useDispatch()

    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleClick(event) {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    }

    function handleHover() {
        currentlyHovering = true;
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function handleCloseHover() {
        currentlyHovering = false;
        setTimeout(() => {
            if (!currentlyHovering) {
                handleClose();
            }
        }, 50);
    }

    return (
        <div>
            <span
                aria-owns={anchorEl ? "simple-menu" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                onMouseOver={handleClick}
                onMouseLeave={handleCloseHover}
            >
                {label}
            </span>
            {
                (items && items.length !== 0) &&
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{
                        onMouseEnter: handleHover,
                        onMouseLeave: handleCloseHover,
                        style: { pointerEvents: "auto" }
                    }}
                    getContentAnchorEl={null}
                    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                    PopoverClasses={{
                        root: styles.popOverRoot
                    }}
                > 
                {
                    items.map((item, i) => (
                        <MenuItem key={i} onClick={() => {
                            dispatch(setIssuanceStep({
                                item: item[itemLabel]
                            }))
                            handleClose()
                        }}>{item[itemLabel]}</MenuItem>
                    ))
                }
                </Menu>
            }
        </div>
    );
}

export default BreadcrumbPopupMenu;