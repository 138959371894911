import React, { useState } from 'react';
import ReactCodeInput from 'react-code-input';
import {
    Button,
    Box,
    Typography,
    Modal,
    Stepper,
    Step,
    StepLabel,
} from '@mui/material';
import TextField from '../../../TextField/TextField';
import { modalStyles } from '../../../styles/modalStyles';
import { greenWallet } from '../../api';
import { useDispatch } from 'react-redux';
import * as liquidBlocksreamActions from '../../../../store/liquidBlocksteam/liquidBlockstreamActions';
import DoneIcon from '@mui/icons-material/Done';
import CircularLoader from '../../../CircularLoader';

const steps = [
    'Enter Wallet Name',
    'Get 24 words',
    'Validate words',
    'Enter Pin Code',
];
const CreateGreenWalletAccount = (props) => {
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = useState(0);
    const [walletName, setWalletName] = useState('');
    const classes = modalStyles();
    const [isModal, setIsModal] = useState(false);
    const [words, setWords] = useState([]);
    const [numbers, setNumbers] = useState([]);
    const [pinCode, setPinCode] = useState('');
    const [currentValidateItem, setCurrentValidateItem] = useState(0);
    const [stepperLoading, setStepperLoading] = useState(false);

    const handleNext = async () => {
        if (activeStep === 0) {
            setStepperLoading(true);
            const response = await greenWallet.createWallet(walletName);
            setWords(response.data.mnemonic.split(' '));
            setStepperLoading(false);
        }
        if (activeStep === 1) {
            generateTestFields();
            setCurrentValidateItem(0);
        }
        if (activeStep === 3) {
            setStepperLoading(true);
            await greenWallet.setPin({
                wallet: walletName,
                pin: pinCode,
            });
            setStepperLoading(false);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleWalletName = (e) => {
        setWalletName(e.target.value);
    };

    const getRandomNumber = (min, max) => {
        console.log(min, max);
        min = min === 0 ? 1 : Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    };

    const generateTestFields = () => {
        const numbersTmp = [];
        const interval = 6;
        for (let i = 0; i < 4; i++) {
            numbersTmp.push(getRandomNumber(interval * i, interval * (i + 1)));
        }
        setNumbers(numbersTmp);
    };

    const createWallet = (type) => {
        if (type === 'open') {
            setIsModal(true);
        } else {
            setIsModal(false);
            modalClose()
        }
        setActiveStep(0);
        setWords([]);
        setNumbers([]);
        setPinCode('');
        setWalletName('');
    };

    const validateWords = (e) => {
        if (e.target.value === words[numbers[currentValidateItem] - 1]) {
            setCurrentValidateItem(currentValidateItem + 1);
            if (e.target.nextSibling) {
                console.log(e.target.nextSibling);
                e.target.nextSibling.focus();
            }
        }
    };

    const modalClose = () => {
        setIsModal(false);
        if (activeStep > 0) {
            dispatch(liquidBlocksreamActions.getGreenWallets());
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
            <Button onClick={() => createWallet('open')} variant="contained">
                Create new wallet
            </Button>
            <Modal
                className={classes.modal}
                open={isModal}
                onClose={() => modalClose()}
            >
                <Box className={classes.paper}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label) => {
                            return (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    {activeStep === 0 && (
                        <Box display="flex" my={2} alignItems="center">
                            <Box mr={2}>Name</Box>
                            <TextField
                                value={walletName}
                                type="string"
                                onChange={handleWalletName}
                            />
                        </Box>
                    )}
                    {activeStep === 1 && (
                        <Box>
                            <Box my={2}>
                                <Typography textAlign="center">
                                    Write down your recovery phrase on paper and
                                    store in a safe place!
                                </Typography>
                            </Box>
                            <ol className={classes.list}>
                                {words.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ol>
                        </Box>
                    )}
                    {activeStep === 2 && (
                        <Box
                            width={'100%'}
                            display="flex"
                            my={2}
                            flexDirection="column"
                        >
                            {numbers.map((item, index) => (
                                <Box
                                    display="flex"
                                    justifyContent="space-around"
                                    my={2}
                                    key={item}
                                >
                                    <Box width="50px">{item}</Box>
                                    <Box width="33%">
                                        <TextField
                                            disabled={
                                                index !== currentValidateItem
                                            }
                                            autoFocus={true}
                                            onChange={validateWords}
                                        />
                                    </Box>
                                    <Box minWidth="50px">
                                        {currentValidateItem > index && (
                                            <DoneIcon
                                                sx={{ color: '#52c41a' }}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    )}
                    {activeStep === 3 && (
                        <Box display="flex" my={2} alignItems="center">
                            <ReactCodeInput
                                value={pinCode}
                                type="password"
                                fields={6}
                                onChange={(e) => setPinCode(e)}
                            />
                        </Box>
                    )}
                    {activeStep === steps.length && (
                        <Box my={2}>
                            <Typography>
                                All steps completed - you're finished
                            </Typography>
                            <Box mt={2} display="flex" justifyContent="center">
                                <Button
                                    variant="contained"
                                    onClick={() => createWallet('close')}
                                >
                                    Close
                                </Button>
                            </Box>
                        </Box>
                    )}
                    {activeStep !== steps.length && (
                        <Box>
                            {stepperLoading ? (
                                <CircularLoader />
                            ) : (
                                <>
                                    <Button
                                        color="inherit"
                                        disabled={[0, 3].includes(activeStep)}
                                        onClick={handleBack}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        onClick={handleNext}
                                        disabled={
                                            (activeStep === 0 &&
                                                !walletName.trim()) ||
                                            (activeStep === 3 &&
                                                pinCode.length !== 6) ||
                                            (activeStep === 2 &&
                                                currentValidateItem !== 4)
                                        }
                                    >
                                        {activeStep === steps.length - 1
                                            ? 'Finish'
                                            : 'Next'}
                                    </Button>
                                </>
                            )}
                        </Box>
                    )}
                </Box>
            </Modal>
        </Box>
    );
};

export default CreateGreenWalletAccount;
