import React, { useState } from 'react';
import {
    Switch,
    Route,
    useRouteMatch,
    Redirect,
    useLocation,
    NavLink
} from 'react-router-dom';
import issuerRouteStyles from './issuerRouteStyles';
import TabPanel from '../../components/TabPanel';
import SubMenu from '../../components/SubMenu';
import Divider from '@mui/material/Divider';
import { connect } from 'react-redux';
import { isFeatureLocked } from '../../utilities/utils';
import { isTALoggedAsIssuer } from '../../utilities/utils';
import InvestorRoutes from './InvestorRoutes';
import TransferRoutes from './TransferRoutes';
import FundraiseRoutes from './FundraiseRoutes';
import CapTableRoutes from './CapTableRoutes';

import IssueRoutes from './IssueRoutes';
import TAPartners from '../../pages/issuer/Transfer/TAPartners';
import KycPage from '../../pages/issuer/Investors/KycPage';
import Container from '@mui/material/Container';
import Communications from '../../pages/issuer/Investors/Communications';
import UploadDocuments from '../../pages/issuer/Dashboard/UploadDocuments';
import CapTableList from '../../pages/issuer/Dashboard/CapTableList';
import BlockchainScreen from '../../pages/issuer/Blockchain/BlockchainScreen';
import EditInvestorInfo from '../../pages/issuer/Investors/EditInvestorInfo';
import ManageClassInfo from '../../pages/issuer/Dashboard/ManageClassInfo';
import SetupWizard from '../../pages/issuer/Dashboard/SetupWizard';
import { SUBSCRIPTION_BASIC, SUBSCRIPTION_PRO, SUBSCRIPTION_ENTERPRISE } from '../../constants/profile';
import { TRANSFERS_ROUTES, FUNDRAISE_ROUTES, INVESTORS_ROUTES } from './routeList';
import PreferenceRoutesContainer from './PreferenceRoutesContainer';
import MuiAlert from '@mui/material/Alert';
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const IssueRoutesContainer = (props) => {
    let { path } = useRouteMatch();
    const classes = issuerRouteStyles();
    const location = useLocation();
    let current_screen = location.pathname.split('/');
    let isWizard = current_screen[current_screen.length - 1] === 'setup-wizard';
    path = path === '/issuer'  ? `${path}/issue` : path;

    let tabs = [];
    if(props.profile && props.profile.subscription_model && props.profile.subscription_model === SUBSCRIPTION_ENTERPRISE){
      tabs = [
         {
             label: <span style={{ paddingLeft: '3rem' }}>Cap Table</span>,
             path: `${path}/cap-table`,
             display: true
         },
         {
             label: 'Digital Security',
             path: `${path}/issue`,
             display: false
         },
         {
             label: 'Holders',
             path: `${path}/holders`,
             display: true
         },
         {
             label: 'Fundraise',
             path: `${path}/fundraise`,
             display: true
         },
         {
             label: 'Transfers',
             path: `${path}/transfers`,
             display: true
         },
     ];
   } else {
         tabs = [
             {
                 label: 'Cap Table',
                 path: `${path}/cap-table`,
                 display:
                     props.profile && props.profile.preferred_tabs
                         ? props.profile.preferred_tabs.captable
                         : false,
             },
             /*{
                 label: 'Add New',
                 path: `${path}/new`,
             },*/

             {
                 label: 'Manage Classes',
                 path: `${path}/cap-table/class-info`,
                 display:
                     props.profile && props.profile.preferred_tabs
                         ? props.profile.preferred_tabs.classes
                         : false,
             },
             /*{
                 label: 'Manage Categories',
                 path: `${path}/category-info`,
             },*/
             {
                 label: 'Manage Holders',
                 path: `${path}/cap-table/holder-info`,
                 display:
                     props.profile && props.profile.preferred_tabs
                         ? props.profile.preferred_tabs.holders
                         : false,
             },
             {
                 label: 'KYC',
                 path: `${path}/cap-table/kyc`,
                 display:
                     props.profile && props.profile.preferred_tabs
                         ? props.profile.preferred_tabs.kyc
                         : false,
                 disabled: isFeatureLocked(
                     SUBSCRIPTION_PRO,
                     props.profile && props.profile.subscription_model
                         ? props.profile.subscription_model
                         : SUBSCRIPTION_BASIC
                 ),
             },
             {
                 label: 'Documents',
                 path: `${path}/cap-table/documents`,
                 disabled: isFeatureLocked(
                     SUBSCRIPTION_PRO,
                     props.profile && props.profile.subscription_model
                         ? props.profile.subscription_model
                         : SUBSCRIPTION_BASIC
                 ),
                 display:
                     props.profile && props.profile.preferred_tabs
                         ? props.profile.preferred_tabs.documents
                         : false,
             },
             {
                 label: 'Blockchain',
                 path: `${path}/cap-table/blockchain`,
                 disabled: isFeatureLocked(
                     SUBSCRIPTION_PRO,
                     props.profile && props.profile.subscription_model
                         ? props.profile.subscription_model
                         : SUBSCRIPTION_BASIC
                 ),
                 display:
                     props.profile && props.profile.preferred_tabs
                         ? props.profile.preferred_tabs.blockchain
                         : false,
             },
             {
                 label: 'Transfers',
                 path: `${path}/transfers`,
                 disabled: isFeatureLocked(
                     SUBSCRIPTION_PRO,
                     props.profile && props.profile.subscription_model
                         ? props.profile.subscription_model
                         : SUBSCRIPTION_BASIC
                 ),
                 display:
                     props.profile && props.profile.preferred_tabs
                         ? props.profile.preferred_tabs.transfers
                         : false,
             },
             {
                 label: 'Transfer Agent',
                 path: `${path}/cap-table/transfer-agents`,
                 disabled: isFeatureLocked(
                     SUBSCRIPTION_PRO,
                     props.profile && props.profile.subscription_model
                         ? props.profile.subscription_model
                         : SUBSCRIPTION_BASIC
                 ),
                 display:
                     props.profile && props.profile.preferred_tabs
                         ? props.profile.preferred_tabs.transferagent
                         : false,
             },
             /*    {
                 label: 'Scenarios',
                 path: `${path}/scenarios`,
             },*/
             {
                 label: 'Events',
                 path: `${path}/cap-table/events`,
                 disabled: isFeatureLocked(
                     SUBSCRIPTION_PRO,
                     props.profile && props.profile.subscription_model
                         ? props.profile.subscription_model
                         : SUBSCRIPTION_BASIC
                 ),
                 display:
                     props.profile && props.profile.preferred_tabs
                         ? props.profile.preferred_tabs.events
                         : false,
             },
             /*{
                 label: 'Registered Interests',
                 path: `${path}/registered-interests`,
             },*/
         ];
   }

   if (props.profile && isTALoggedAsIssuer(props.profile)) {
     tabs = tabs.filter(function(tab) {
       return tab.label !== 'Transfer Agent';
     });
   }

    const getActiveTab = () => {
        const preferred_tabs = tabs.filter(tab => tab.display).map(tab => tab.path);      
        let locationPath = location.pathname
        if (TRANSFERS_ROUTES.includes(location.pathname)) {
            locationPath = `${path}/transfers`;
        } else if (FUNDRAISE_ROUTES.includes(location.pathname)) {
            locationPath = `${path}/fundraise`;
        } else if(INVESTORS_ROUTES.includes(location.pathname)) {
            locationPath = `${path}/holders`;
        }

        return Math.max(preferred_tabs.indexOf(locationPath), 0)
    };

    const [value, setValue] = useState(getActiveTab());
    return (
      <Container>
          <SubMenu
              value={value}
              onChange={(e, v) => setValue(v)}
              tabs={tabs}
              title="Cap Table"
          />
          <Divider
              orientation="horizontal"
              fullWidth
              className={classes.divider}
          ></Divider>
                {!props.profile.has_cap_table &&
                    props.profile.scope !== 'list' &&
                    !isWizard && (
                        <React.Fragment>
                            <br />
                            <Alert
                                severity="warning"
                                style={{
                                    borderRadius: 15,
                                    opacity: '80%',
                                    fontSize: '1rem',
                                }}
                            >
                                Cap Table has not been detected. You can upload it
                                from the{' '}
                                <b>
                                    <NavLink
                                        to={`${path}/cap-table/setup-wizard`}
                                        style={{
                                            textDecoration: 'underline',
                                            fontSize: '1rem',
                                            color: 'white',
                                        }}
                                    >
                                        Setup Wizard tab.{' '}
                                    </NavLink>
                                </b>
                            </Alert>
                            <br />
                        </React.Fragment>
                    )}
            <TabPanel >

            {props.profile && props.profile.subscription_model && props.profile.subscription_model === SUBSCRIPTION_ENTERPRISE ?
              (
                <Switch>
                  <Route exact path={`${path}/`}>
                      <Redirect to={`${path}/cap-table`} />
                  </Route>
                  <Route
                      path={`${path}/cap-table`}
                      component={CapTableRoutes}
                  />
                  <Route path={`${path}/issue`} component={IssueRoutes} />
                  <Route
                      path={`${path}/preferences`}
                      component={PreferenceRoutesContainer}
                  />
                  <Route
                      path={`${path}/holders`}
                      component={InvestorRoutes}
                  />
                  <Route
                      path={`${path}/fundraise`}
                      component={FundraiseRoutes}
                  />
                  <Route
                      path={`${path}/transfers`}
                      component={TransferRoutes}
                  />
                   <Route
                      path={'/issuer/preferences'}
                      component={PreferenceRoutesContainer}
                  />
                    <Route
                      path={`${path}/cap-table/setup-wizard`}
                      component={SetupWizard}
                  />
              </Switch>
              ) : (
                <Switch>
                  <Route exact path={`${path}/`}>
                      <Redirect to={`${path}/cap-table`} />
                  </Route>
                  <Route
                      path={`${path}/cap-table/class-info`}
                      component={ManageClassInfo}
                  />
                  <Route
                      path={`${path}/cap-table/holder-info`}
                      component={EditInvestorInfo}
                  />
                  <Route  path={`${path}/cap-table/kyc`} component={KycPage} />
                  <Route
                      path={`${path}/cap-table/documents`}
                      component={UploadDocuments}
                  />
                  <Route
                      exact
                      path={`${path}/cap-table/blockchain`}
                      component={BlockchainScreen}
                  />
                  <Route
                      path={`${path}/cap-table/transfer-agents`}
                      component={TAPartners}
                  />

                  {/*    <Route
                      exact
                      path={`${path}/scenarios`}
                      component={Scenarios}
                  />*/}
                  <Route
                      path={`${path}/cap-table/events`}
                      component={Communications}
                  />
                  <Route
                      path={`${path}/cap-table/setup-wizard`}
                      component={SetupWizard}
                  />

                  {/*<Route path={`${path}/issue`} component={IssueRoutes} />*/}
                  <Route
                      path={'/issuer/preferences'}
                      component={PreferenceRoutesContainer}
                  />
                  <Route
                      path={`${path}/holders`}
                      component={InvestorRoutes}
                  />
                  <Route
                      path={`${path}/fundraise`}
                      component={FundraiseRoutes}
                  />
                  <Route
                      path={`${path}/transfers`}
                      component={TransferRoutes}
                  />
                  <Route
                      path={`${path}/cap-table`}
                      component={CapTableList}
                  />
              </Switch>
              )
            }
            </TabPanel>
      </Container>
  );
};

const mapStateToProps = ({ profile }) => {
    return {
        preferredTabs: profile.preferred_tabs,
        profile: profile
    };
};

export default connect(mapStateToProps, null)(IssueRoutesContainer);
