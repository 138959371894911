import * as assetIssuanceTypes from './assetIssuanceTypes'

const initialState = {
    step: null,
    item: null,
}

const assetIssuanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case assetIssuanceTypes.SET_ISSUANCE_STEP:
            return {
                ...state,
                ...action.payload 
            }
        default:
            return state
    }
}

export default assetIssuanceReducer