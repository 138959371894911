import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Grid, Button, Typography, Box, Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { category } from '../api';
import TextField from '../../../components/TextField/TextField';
import StyledPaper from '../../StyledPaper';
import { snackbar } from '../../../utilities/snackbarUtils';
import { errorParser } from '../utils/errorParser';
import CircularLoader from '../../CircularLoader';
import * as liquidBlocksreamActions from '../../../store/liquidBlocksteam/liquidBlockstreamActions';
import TableComponent from '../components/Table';
import { modalStyles } from '../../styles/modalStyles';

const initialValuesAssignToUser = {
    categoryId: '',
    userId: '',
};

const initialValuesAssignToAsset = {
    categoryId: '',
    assetUuid: '',
};

const validationUserSchema = Yup.object({
    categoryId: Yup.string().required('Please enter a Category Id'),
    userId: Yup.string().required('Please enter a User Id'),
});
const validationAssetSchema = Yup.object({
    categoryId: Yup.string().required('Please enter a Category Id'),
    assetUuid: Yup.string().required('Please enter a Assset User Id'),
});
const initialValuesCreate = {
    name: '',
    description: '',
};

const createValidationSchema = Yup.object({
    name: Yup.string().required('Please enter name'),
    description: Yup.string().required('Please enter description'),
});

const COLUMNS = [
    { field: 'id', headerName: 'Id' },
    { field: 'name', headerName: 'Name' },
    {
        field: 'description',
        headerName: 'Description',
    },
    { field: 'registered_users', headerName: 'Registered Users' },
    {
        field: 'assets',
        headerName: 'Assets',
        cellType: 'truncated',
        copy: true,
        useTooltip: true,
        colProps: {
            width: '15%',
        },
    },
];
const Categories = () => {
    const { categories, loading } = useSelector(
        (state) => state.liquidBlockstream
    );
    const dispatch = useDispatch();
    const classes = modalStyles();

    const [selectedCategory, setSelectedCategory] = useState({});
    const [modal, setModal] = useState(false);

    useEffect(() => {
        if (!categories.length) {
            getCategories();
        }
    }, []);

    const getCategories = () => {
        dispatch(liquidBlocksreamActions.getCategories());
    };

    const categoryFunctions = async (values, setSubmitting, reset, type) => {
        try {
            // eslint-disable-next-line default-case
            switch (type) {
                case 'assignToAsset':
                    await category.assignCategoryToAsset(
                        values.categoryId,
                        values.assetUuid
                    );
                    break;
                case 'assignToUser':
                    await category.assignCategoryToUser(
                        values.categoryId,
                        values.userId
                    );
                    break;
                case 'removeAsset':
                    await category.removeAssetFromCategory(
                        values.categoryId,
                        values.assetUuid
                    );
                    break;
                case 'removeUser':
                    await category.removeUserFromCategory(
                        values.categoryId,
                        values.userId
                    );
                    break;
                case 'create':
                    await category.addCategory(values);
                    break;
            }
            reset();
            getCategories();
        } catch (error) {
            snackbar.error(errorParser(error));
        }
        setSubmitting(false);
        if (modal) {
            setModal(false);
        }
    };

    const addCategory = async (values, setSubmitting, reset) => {
        try {
            await category.addCategory(values);
            reset();
            getCategories();
        } catch (error) {
            snackbar.error(errorParser(error));
        }
        setSubmitting(false);
    };

    const handleAssignAction = (item, type) => {
        setSelectedCategory({ ...item, type: type });
        setModal(true);
    };

    const renderModalBody = (type) => {
        const schema =
            type === 'user' || type === 'removeUser' ? validationUserSchema : validationAssetSchema;
        const initial =
            type === 'user' || type === 'removeUser'
                ? initialValuesAssignToUser
                : initialValuesAssignToAsset;
        return (
            <>
                <Typography align="center" variant="subtitle1">
                    {type === 'user'
                        ? 'Assign a registered User to a Category' :
                        type === 'asset' ? 'Assign an Asset to a Category' :
                            type === 'removeUser' ? 'Remove a User form a Category' :
                                'Remove an Asset form a Category'}
                </Typography>
                <Formik
                    initialValues={initial}
                    validationSchema={schema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        const requestType =
                            type === 'user' ? 'assignToUser' :
                                type === 'asset' ? 'assignToAsset' :
                                    type === 'removeUser' ? 'removeUser' : 'removeAsset';
                        categoryFunctions(
                            values,
                            setSubmitting,
                            resetForm,
                            requestType
                        );
                    }}
                >
                    {({ submitForm, isSubmitting }) => (
                        <Form noValidate style={{ width: '100%' }}>
                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                justify="center"
                            >
                                <Grid item xs={12} lg={6}>
                                    {type === 'user' || type === 'removeUser' ? (
                                        <Field
                                            component={TextField}
                                            type="text"
                                            name="userId"
                                            label="User Id"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            required
                                        />
                                    ) : (
                                        <Field
                                            component={TextField}
                                            type="text"
                                            name="assetUuid"
                                            label="Asset User Id"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            required
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        name="categoryId"
                                        label="Category Id"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={submitForm}
                                        disabled={isSubmitting}
                                    >
                                        {type === 'removeUser' || type === 'removeAsset' ? 'Remove' : 'Assign category'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </>
        );
    };
    return (
        <>
            <StyledPaper elevation={3} marginBottom={20} padding={2}>
                <Box mb={2}>
                    <Typography variant="body1" textAlign="center">
                        Categories
                    </Typography>
                </Box>
                {loading ? (
                    <CircularLoader />
                ) : (
                    <>
                        {!!categories.length ? (
                            <TableComponent
                                columns={COLUMNS}
                                items={categories}
                                threeDotsActions={[
                                    {
                                        label: 'Assign to User',
                                        handler: (id, item) =>
                                            handleAssignAction(item, 'user'),
                                    },
                                    {
                                        label: 'Assign to Asset',
                                        handler: (id, item) =>
                                            handleAssignAction(item, 'asset'),
                                    },
                                    {
                                        label: 'Remove User',
                                        handler: (id, item) =>
                                            handleAssignAction(item, 'removeUser'),
                                    },
                                    {
                                        label: 'Remove Asset',
                                        handler: (id, item) =>
                                            handleAssignAction(item, 'removeAsset'),
                                    },
                                ]}
                            />
                        ) : (
                            <Typography variant="body2" textAlign="center">
                                There're no categories.
                            </Typography>
                        )}
                    </>
                )}
            </StyledPaper>
            <StyledPaper elevation={3} padding={10}>
                <Typography align="center" variant="subtitle1">
                    Add Category
                </Typography>
                <Formik
                    initialValues={initialValuesCreate}
                    validationSchema={createValidationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        addCategory(values, setSubmitting, resetForm);
                    }}
                >
                    {({ submitForm, isSubmitting }) => (
                        <Form noValidate>
                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                justifyContent="center"
                            >
                                <Grid item xs={12} md={6}>
                                    <Field
                                        component={TextField}
                                        type="test"
                                        name="name"
                                        label="Name"
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field
                                        component={TextField}
                                        type="test"
                                        name="description"
                                        label="Description"
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={submitForm}
                                        disabled={isSubmitting}
                                    >
                                        Add category
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </StyledPaper>
            <Modal
                className={classes.modal}
                open={modal}
                onClose={() => setModal(false)}
            >
                <Box className={classes.paper}>
                    {renderModalBody(selectedCategory.type)}
                </Box>
            </Modal>
        </>
    );
};

export default Categories;
