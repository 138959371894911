import React from 'react';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Stack, Typography } from '@mui/material'

import useWalletAddresses from '../../../../api/blockchain/useWalletAddresses';
import WalletAddressButtons from './WalletAddressButtons';

const WalletAddressesDialog = ({ open, handleClose, wallet }) => {
    const { walletAddresses, walletAddressesLoading, walletAddressesValidating } = useWalletAddresses(wallet?.wallet)

    return (
        <Dialog open={open} onClose={handleClose} maxWidth='lg'>
            <DialogTitle>Wallet addresses ({wallet?.wallet})</DialogTitle>
            <DialogContent>
                {
                    (walletAddressesLoading || walletAddressesValidating) &&
                    <>
                        <Typography variant='body2' as='p'>Loading wallet addresses...</Typography>
                        <br />
                        <LinearProgress />
                    </>
                }
                {
                    (!walletAddressesLoading && !walletAddressesValidating && walletAddresses) && <>
                        <Alert variant='outlined' severity="info" sx={{ marginBottom: '10px' }}>
                            You will need to fund the <b>public issuance address</b> with <b>Liquid Bitcoin (L-BTC)</b> to be able to distribute your assets.
                        </Alert>
                        {
                            walletAddresses.map((walletAddress, index) => (
                                <>
                                    <Typography variant='body1' as='h5'>
                                        { walletAddress.type.slice(0,1).toUpperCase().concat(walletAddress.type.slice(1)) }
                                    </Typography>
                                    <Stack direction="row" alignItems="center" justifyContent='space-between' gap={1}>
                                        <div>
                                            <Typography variant='caption' as='p' sx={{ color: 'gray' }}>
                                                Public address
                                            </Typography>
                                            <Typography variant='body2' as='p' sx={{ marginBottom: '10px;' }}>
                                                {walletAddress.address}
                                            </Typography>
                                        </div>
                                        <WalletAddressButtons address={walletAddress?.address} />
                                    </Stack>
                                    <Typography variant='caption' as='p' sx={{ color: 'gray' }}>
                                        Public key
                                    </Typography>
                                    <Typography variant='body2' as='p'>
                                        {walletAddress.pubkey}
                                    </Typography>

                                    { index !== walletAddress.length-1 && <br /> } 
                                </>
                            ))
                        }
                    </>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                {/* <Button variant='contained'
                    disabled={walletAddressesLoading || walletAddressesValidating}
                    onClick={() => updateWalletAddresses()}
                >
                    Generate new
                </Button> */}
            </DialogActions>
        </Dialog>
    )
}

export default WalletAddressesDialog