import React, { useState } from 'react';
import StyledPaper from '../../StyledPaper';
import { Formik, Form, Field } from 'formik';
import { Grid, Button, Typography } from '@mui/material';
import * as Yup from 'yup';

import TextField from '../../TextField/TextField';
import { gaid } from '../api';
import { snackbar } from '../../../utilities/snackbarUtils';
import { errorParser } from '../utils/errorParser';

const gaidAssetsInitial = {
    gaid: '',
};

const gaidAssetsValidationSchema = Yup.object({
    gaid: Yup.string().required('Please fill gaid field.'),
});

const gaidUserInitial = {
    gaid: '',
    assetUuid: '',
};

const gaidUserValidationSchema = Yup.object({
    gaid: Yup.string().required('Please enter gaid field.'),
    assetUuid: Yup.string().required('Please enter Assset User Id'),
});

const GaidTab = (props) => {
    const [gaidBalances, setGaidBalances] = useState({
        data: [],
        loading: false,
    });
    const [userGaidBalance, setUserGaidBalance] = useState({
        data: '',
        loading: false,
    });

    const getGaid = (values, setSubmitting, resetForm) => {
        gaid.getGaidBalances(values.gaid)
            .then((res) => {
                setGaidBalances(res.data);
                resetForm();
            })
            .catch((err) => snackbar.error(errorParser(err)))
            .finally(() => {
                setSubmitting(false);
            });
    };

    const getUserGaid = (values, setSubmitting, resetForm) => {
        gaid.getUserGaid(values.gaid, values.assetUuid)
            .then((res) => {
                setUserGaidBalance(res.data);
                resetForm();
            })
            .catch((err) => snackbar.error(errorParser(err)))
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <>
            <StyledPaper elevation={3} padding={10} marginBottom={20}>
                <Typography align="center" variant="subtitle1">
                    Get All Gaid Balances
                </Typography>
                <Formik
                    initialValues={gaidAssetsInitial}
                    validationSchema={gaidAssetsValidationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) =>
                        getGaid(values, setSubmitting, resetForm)
                    }
                >
                    {({ submitForm, isSubmitting }) => (
                        <Form noValidate style={{ width: '100%' }}>
                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                justify="center"
                            >
                                <Grid item xs={12}>
                                    <Field
                                        component={TextField}
                                        name="gaid"
                                        label="Gaid"
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={submitForm}
                                        disabled={isSubmitting}
                                    >
                                        Get Gaid Balances
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </StyledPaper>
            {!!gaidBalances.length && (
                <StyledPaper elevation={3} padding={10} marginBottom={20}>
                    <Typography
                        align="center"
                        variant="subtitle2"
                    >
                            Asset ID - Asset UUID - Balance
                    </Typography>
                    {gaidBalances.map((item) => (
                        <Typography
                            key={item.assetId}
                            align="center"
                            variant="subtitle2"
                        >{`${item.assetId || 'Empty'} - ${
                            item.assetUuid || 'Empty'
                        } - ${item.balance}`}</Typography>
                    ))}
                </StyledPaper>
            )}
            <StyledPaper elevation={3} padding={10} marginBottom={20}>
                <Typography align="center" variant="subtitle1">
                    Get User Gaid
                </Typography>
                <Formik
                    initialValues={gaidUserInitial}
                    validationSchema={gaidUserValidationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) =>
                        getUserGaid(values, setSubmitting, resetForm)
                    }
                >
                    {({ submitForm, isSubmitting }) => (
                        <Form noValidate style={{ width: '100%' }}>
                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                justify="center"
                            >
                                <Grid item xs={12} md={6}>
                                    <Field
                                        component={TextField}
                                        name="gaid"
                                        label="Gaid"
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field
                                        component={TextField}
                                        name="assetUuid"
                                        label="AssetUuid"
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={submitForm}
                                        disabled={isSubmitting}
                                    >
                                        Get Gaid Balances
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </StyledPaper>
            {userGaidBalance?.assetId && (
                <StyledPaper elevation={3} padding={10}>
                    <Typography
                        align="center"
                        variant="subtitle2"
                    >
                        Asset ID - Asset UUID - Balance
                    </Typography>
                    <Typography variant="subtitle2" align="center">
                        {`${userGaidBalance.assetId} - ${userGaidBalance.assetUuid} - ${userGaidBalance.balance}`}
                    </Typography>
                </StyledPaper>
            )}
        </>
    );
};

export default GaidTab;
