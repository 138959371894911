import React, { useState } from 'react';
import {
    Switch,
    Route,
    useRouteMatch,
    useLocation,
    withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as profileActions from '../../store/profile/profileActions';
import { isTALoggedAsIssuer } from '../../utilities/utils';
import TabPanel from '../../components/TabPanel';
import SubMenu from '../../components/SubMenu';
import Transfers from '../../pages/issuer/Transfer/Transfers';
import Retirements from '../../pages/issuer/Transfer/Retirements';
import Issuance from '../../pages/issuer/Transfer/Issuance';
import Overview from '../../pages/issuer/Transfer/Overview';
import Approvals from '../../pages/issuer/Transfer/Approvals';
import History from '../../pages/issuer/Transfer/History';
import External from '../../pages/issuer/Transfer/External';
import TAPartners from '../../pages/issuer/Transfer/TAPartners';
import VerticalContainer from '../../components/VerticalContainer';
import { TRANSFERS_ROUTES } from './routeList';

const TransferRoutes = (props) => {
    let { path } = useRouteMatch();
    const location = useLocation();

    const { profile } = props;

    const getActiveTab = () => {
        let index = TRANSFERS_ROUTES.indexOf(location.pathname);
        return index >= 0 ? index : 0;
    };

    const tabs = [
        {
            label: 'Overview',
            path: `${path}`,
            display: true,
        },
        {
            label: 'Transfer',
            path: `${path}/transfer`,
            display: true,
        },
        {
            label: 'Issuance',
            path: `${path}/issuance`,
            display: false,
        },
        {
            label: 'Retirement',
            path: `${path}/retirement`,
            display: true,
        },
        {
            label: 'Approvals',
            path: `${path}/approvals`,
            display: true,
        },
        {
            label: 'History',
            path: `${path}/history`,
            display: true,
        },
        {
            label: 'External',
            path: `${path}/external`,
            display: true,
        },
    ];

    if (profile && !isTALoggedAsIssuer(props.profile)) {
        tabs.push({
            label: 'TA Partners',
            path: `${path}/transfer-agents`,
        });
    }
    const [value, setValue] = useState(getActiveTab());
    return (
        <VerticalContainer>
            <SubMenu
                orientation="vertical"
                value={value}
                onChange={(e, v) => setValue(v)}
                tabs={tabs}
                title="Transfer"
            />
            <TabPanel>
                <Switch>
                    <Route exact path={`${path}/`} component={Overview} />
                    <Route
                        exact
                        path={`${path}/transfer`}
                        component={Transfers}
                    />
                    <Route
                        exact
                        path={`${path}/issuance`}
                        component={Issuance}
                    />
                    <Route
                        exact
                        path={`${path}/retirement`}
                        component={Retirements}
                    />
                    <Route
                        exact
                        path={`${path}/approvals`}
                        component={Approvals}
                    />
                    <Route exact path={`${path}/history`} component={History} />
                    <Route
                        exact
                        path={`${path}/external`}
                        component={External}
                    />
                    {profile && !isTALoggedAsIssuer(props.profile) ? (
                        <Route
                            exact
                            path={`${path}/transfer-agents`}
                            component={TAPartners}
                        />
                    ) : null}
                </Switch>
            </TabPanel>
        </VerticalContainer>
    );
};

const mapStateToProps = (state) => {
    return {
        profile: state.profile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        profileActions: bindActionCreators(profileActions, dispatch),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TransferRoutes));
