import React, { useState } from 'react';
import Container from '@mui/material/Container';
import DetailIcon from '@mui/icons-material/FindInPage';
import Header from '../../../components/Header/Header';
import Title from '../../../components/Title/Title';
import DataTable from '../../../components/DataTable/DataTable';
import TableButton from '../../../components/TableButton/TableButton';
import ShareholderNoticeDetails from './ShareholderNoticeDetails';
import * as config from './../../../config';

const ShareholderNotices = () => {
    const [detailId, setDetailId] = useState(null);
    const [detailDialog, setDetailDialog] = useState(false);

    const handleDetailDialogOpen = (id) => {
        setDetailId(id);
        setDetailDialog(true);
    };

    const handleDetailDialogClose = () => {
        setDetailDialog(false);
    };

    const formatActions = (data) => {
        return (
            <TableButton
                preset="blue"
                onClick={() => handleDetailDialogOpen(data.row.id)}
            >
                <DetailIcon />
                Details
            </TableButton>
        );
    };

    const columns = [
        {
            id: 'issuer',
            label: 'Issuer',
            format: { type: 'text' },
            align: 'left',
            width: '25%',
        },
        {
            id: 'title',
            label: 'Title',
            format: { type: 'text' },
            align: 'left',
            width: '40%',
        },
        {
            id: 'published_on',
            label: 'Date',
            format: { type: 'date', format: 'date' },
            width: '20%',
        },
        {
            id: 'actions',
            label: 'Actions',
            format: { type: 'custom', formatter: formatActions },
            width: '15%',
            sortDisabled: true,
        },
    ];

    return (
        <React.Fragment>
            <Header />
            <Title text="Holder Notices" />
            <Container>
                <DataTable
                    dataColumns={columns}
                    remoteUrl={`${config.API_BASE_URL}/notice/list`}
                    enableSearch
                    enableExport
                    autoLayout
                />
            </Container>
            <ShareholderNoticeDetails
                open={detailDialog}
                id={detailId}
                onClose={handleDetailDialogClose}
            />
        </React.Fragment>
    );
};

export default ShareholderNotices;
