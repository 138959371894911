import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as capTableActions from '../../store/capTable/capTableActions';
import * as blockchainActions from '../../store/blockchain/blockchainActions';
import * as dialogActions from '../../store/dialog/dialogActions';

import Grid from '@mui/material/Grid';
import { filterArray, parseNumber } from '../../utilities/utils';
import { useHistory } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import Typography from '@mui/material/Typography';

import TextField from '../TextField/TextField';
import FormikAutocomplete from '../FormikAutocomplete';
import StyledPaper from '../StyledPaper';
import SubmitButton from '../SubmitButton';

import { DateFormatter } from '../../utilities/formatters';

import FormikDatePicker from '../Formik/FormikDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ConfirmationDialog from '../ConfirmationDialog';

/* Initial form values */
const initialValues = {
    equity_id: '',
    price_per_token: '',
    currency: '',
    ticker: '',
    sponsor_name: 'DIGTL',
    issuer_name: '',
    class_id: '',
    blockchain: 'nexus',
    token_type: 'STOCKS',
    issue_amount: '',
    available_amount: '',
    subscription_start_date: DateFormatter({
        value: new Date(),
        format: 'date',
    }),
    subscription_end_date: DateFormatter({
        value: new Date().setFullYear(new Date().getFullYear() + 1),
        format: 'date',
    }),
    num_lots: '',
    min_lots: '',
    max_lots: '',
    days_locked: '',
};

const validationSchema = Yup.object({
    blockchain: Yup.string()
        .oneOf(['nexus', 'symbol', 'liquid'])
        .required('Please select a blockchain.'),
    class_id: Yup.number().required('Please select a class.'),
    equity_id: Yup.number().required('Please select an equity.'),
    ticker: Yup.string().required('Please choose a ticker.'),
    token_type: Yup.string()
        .oneOf(['BONDS', 'STOCKS', 'STABLE', 'OPEN_ENDED_FUND'])
        .required('Please select a token type.'),
    issue_amount: Yup.string()
        .required('Please enter the total amount')
        .matches(
            /^(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)$/,
            'Please enter a valid amount.'
        )
        .notOneOf(['0'], 'The amount cannot be 0.'),
    available_amount: Yup.string()
        .required('Please enter the amount available for transfer.')
        .matches(
            /^(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)$/,
            'Please enter a valid amount.'
        )
        .notOneOf(['0'], 'The amount cannot be 0.')
        .test(
            'lteIssueAmmount',
            'You cannot issue more assets than you have in your cap table.',
            function (value) {
                let v1 = parseNumber(value);
                let v2 = parseNumber(this.resolve(Yup.ref('issue_amount')));
                return v1 <= v2;
            }
        ),
    currency: Yup.string().required('Please choose a valid currecy.'),
    price_per_token: Yup.number().required('Please enter the price per token.'),
});

const StacsIssuance = (props) => {
    const {
        blockchainActions,
        capTableActions,
        dialogActions,
        profile,
        registry,
        issueLoading,
    } = props;

    useEffect(capTableActions.getRegistry, []);
    initialValues.issuer_name = profile.company_name;

    const classes = registry.loading ? [] : registry.data.classes;
    const categories = registry.loading ? [] : registry.data.categories;
    const history = useHistory();

    const tokenTypes = [
        { name: 'Bonds', value: 'BONDS' },
        { name: 'Stocks', value: 'STOCKS' },
        { name: 'Stable', value: 'STABLE' },
        { name: 'Open-ended fund', value: 'OPEN_ENDED_FUND' },
    ];

    const handleDateChange = (date, name, setFieldValue) => {
        let newDate = DateFormatter({
            value: date,
            format: 'date',
        });

        setFieldValue(name, newDate);
        return newDate;
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                // console.log(values);

                /* Handle numeric fields with thousand-separators. */
                let data = cloneDeep(values);
                data.issue_amount = parseNumber(data.issue_amount);
                data.available_amount = parseNumber(data.available_amount);

                const reset = () => resetForm(initialValues);

                /* Issue the token, if everything is alright. */
                dialogActions.openDialog(
                    'Issue on NEXUS',
                    <React.Fragment>
                        <span>
                            Are you sure you want to issue this equity on the
                            blockchain?
                        </span>
                        <br />
                        <span>
                            Once issued, equity details <b>cannot be edited</b>{' '}
                            anymore.
                        </span>
                    </React.Fragment>,
                    () => {
                        blockchainActions.issueOnBlockchain(
                            data,
                            setSubmitting,
                            history,
                            reset
                        );
                        dialogActions.closeDialog();
                    },
                    'Cancel',
                    'Issue'
                );
            }}
        >
            {({ resetForm, setFieldValue, submitForm, values }) => (
                <React.Fragment>
                    <Form noValidate>
                        {/* Basic information */}
                        <StyledPaper
                            elevation={3}
                            padding={10}
                            marginBottom={20}
                        >
                            <Typography variant="body1">
                                Token issuance
                            </Typography>
                            <br />
                            <Grid container item spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Field
                                        component={FormikAutocomplete}
                                        name="class_id"
                                        label="Asset class"
                                        options={classes}
                                        optionKey="class_description"
                                        dataKey="id"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field
                                        component={FormikAutocomplete}
                                        name="equity_id"
                                        label="Asset category"
                                        options={filterArray(categories, {
                                            class_id: values.class_id,
                                        })}
                                        disabled={!values.class_id}
                                        optionKey="category_description"
                                        dataKey="id"
                                        dependsOn={values.class_id}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field
                                        component={TextField}
                                        name="ticker"
                                        label="Equity ticker"
                                        type="text"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field
                                        component={FormikAutocomplete}
                                        name="token_type"
                                        label="Token type"
                                        options={tokenTypes}
                                        helperText="Type of the token to be issued."
                                        optionKey="name"
                                        defaultValue={tokenTypes[1]}
                                    />
                                </Grid>
                            </Grid>
                        </StyledPaper>

                        {/* Issuer information */}
                        <StyledPaper
                            elevation={3}
                            padding={10}
                            marginTop={20}
                            marginBottom={20}
                        >
                            <Typography variant="body1">
                                Issuer information
                            </Typography>
                            <br />
                            <Grid container item spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Field
                                        component={TextField}
                                        name="issuer_name"
                                        variant="outlined"
                                        displayOnly
                                        label="Issuer name"
                                        fullWidth
                                        required
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field
                                        component={TextField}
                                        name="sponsor_name"
                                        label="Sponsor name"
                                        fullWidth
                                        variant="outlined"
                                        displayOnly
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </StyledPaper>

                        {/* Details about the issued amount */}
                        <StyledPaper
                            elevation={3}
                            padding={10}
                            marginTop={20}
                            marginBottom={20}
                        >
                            <Typography variant="body1">
                                Issuance information
                            </Typography>
                            <br />
                            <Grid container item spacing={2}>
                                <Grid item xs={12}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        name="issue_amount"
                                        label="Total circulating amount"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        disabled
                                        helperText="Total amount of the equity that will be issued."
                                        placeholder={'500,000'}
                                        numeric={1}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        name="available_amount"
                                        label="Available amount"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        helperText="Total amount of the equity that can be sent and traded."
                                        placeholder={'400,000'}
                                        numeric={1}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="caption">
                                        Issuance price per token
                                    </Typography>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        name="currency"
                                        label="Currency"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        placeholder={'USD'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="caption">
                                        &nbsp;
                                    </Typography>
                                    <Field
                                        component={TextField}
                                        type="number"
                                        name="price_per_token"
                                        label="Price"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        placeholder={'0.5'}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        component={TextField}
                                        type="number"
                                        name="num_lots"
                                        label="Number of assets per lot"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            component={FormikDatePicker}
                                            name="subscription_start_date"
                                            label="Subscription start date"
                                            onChange={(date) =>
                                                handleDateChange(
                                                    date,
                                                    'subscription_start_date',
                                                    setFieldValue
                                                )
                                            }
                                            format="MM/dd/yyyy"
                                            variant="inline"
                                            inputVariant="outlined"
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field
                                            component={FormikDatePicker}
                                            name="subscription_end_date"
                                            label="Subscription end date"
                                            onChange={(date) =>
                                                handleDateChange(
                                                    date,
                                                    'subscription_end_date',
                                                    setFieldValue
                                                )
                                            }
                                            format="MM/dd/yyyy"
                                            variant="inline"
                                            inputVariant="outlined"
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                        </StyledPaper>

                        {/* Restrictions */}
                        <StyledPaper
                            elevation={3}
                            padding={10}
                            marginTop={20}
                            marginBottom={20}
                        >
                            <Typography variant="body1">
                                Restrictions
                            </Typography>
                            <br />
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Field
                                        component={TextField}
                                        type="number"
                                        name="min_lots"
                                        label="Minimum purchase amount"
                                        variant="outlined"
                                        margin="normal"
                                        endAdornment="lots"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field
                                        component={TextField}
                                        type="number"
                                        name="max_lots"
                                        label="Maximum purchase amount"
                                        variant="outlined"
                                        margin="normal"
                                        endAdornment="lots"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        component={TextField}
                                        type="number"
                                        name="days_locked"
                                        label="Lock-up period"
                                        variant="outlined"
                                        margin="normal"
                                        endAdornment="days"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </StyledPaper>

                        <Grid item xs={12} className="flex-center">
                            <SubmitButton
                                onClick={submitForm}
                                text="Issue on Blockchain"
                                loading={issueLoading}
                            />
                        </Grid>
                    </Form>
                    <ConfirmationDialog />
                </React.Fragment>
            )}
        </Formik>
    );
};

const mapStateToProps = ({ profile, capTable, blockchain }) => {
    return {
        profile: profile,
        registry: capTable.registry,
        issueLoading: blockchain.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        capTableActions: bindActionCreators(capTableActions, dispatch),
        blockchainActions: bindActionCreators(blockchainActions, dispatch),
        dialogActions: bindActionCreators(dialogActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StacsIssuance);
