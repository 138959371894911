import React from 'react';
import Button from '@mui/material/Button';
import authStyles from '../authStyles';
import ButtonGroup from '@mui/material/ButtonGroup';

const FundButtonGroup = (props) => {
    const classes = authStyles();

    const previousStep = () => {
        props.previousStep();
    };

    const closeDialog = () => {
        props.closeDialog();
    };

    return (
        <div className={classes.actions}>
            <ButtonGroup
                aria-label="outlined primary button group"
                className={classes.buttonGroupAlignment}
            >
                <Button
                    variant="contained"
                    className={classes.button}
                    size="small"
                    onClick={closeDialog}
                >
                    Close
                </Button>
                {!props.first && (
                    <Button
                        color="secondary"
                        variant="contained"
                        size="small"
                        className={classes.button}
                        onClick={previousStep}
                    >
                        Previous
                    </Button>
                )}

                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="small"
                    className={classes.button}
                >
                    {props.last ? 'Finish' : 'Next'}
                </Button>
            </ButtonGroup>
        </div>
    );
};

export default FundButtonGroup;
