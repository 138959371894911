import React from 'react';
import pageStyles from '../styles/pageStyles';

const Page = (props) => {
    const classes = pageStyles();
    const { children } = props;

    return <div className={classes.container}>{children}</div>;
};

export default Page;
