import useSWR from "swr"
import { API_BASE_URL } from "../../config"
import fetcher from "../../utilities/fetcher"

const useElementWallets = () => {
    const { data, error, isValidating, mutate } = useSWR(`${API_BASE_URL}/blockchain/wallets`, fetcher)

    return {
        wallets: data,
        walletsLoading: !error && !data,
        walletsValidating: isValidating,
        walletsError: error,
        updateWallets: () => mutate()
    }
}

export default useElementWallets