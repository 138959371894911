import request from '../../utilities/request';
import * as config from '../../config';
import * as kycTypes from './kycTypes';

export const getKycSuccess = (summary) => {
    return {
        type: kycTypes.GET_KYC_SUCCESS,
        payload: summary,
    };
};

export const setKycLoading = (loading) => {
    return {
        type: kycTypes.SET_KYC_LOADING,
        payload: loading,
    };
};

export const getKyc = () => {
    return (dispatch) => {
        dispatch(setKycLoading(true));
        request
            .get(`${config.API_BASE_URL}/kyc`)
            .then((res) => {
                dispatch(getKycSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setKycLoading(false));
            });
    };
};
