import * as capTableTypes from './capTableTypes';
import _ from 'lodash';

const initialState = {
    summary: {
        data: null,
        loading: true,
    },
    shareholders: {
        data: null,
        loading: true,
    },
    investors: {
        data: null,
        loading: true,
    },
    classes: {
        data: null,
        loading: true,
    },
    categories: {
        data: null,
        loading: true,
    },
    registry: {
        data: null,
        loading: true,
    },
    newClass: {
        loading: false,
    },
    newCategory: {
        loading: false,
    },
    newInvestor: {
        loading: false,
    },
    chart: {
        data: null,
        loading: true,
    },
    capTableEntry: {
        data: null,
        loading: true,
    },
};

const capTableReducer = (state = initialState, action) => {
    switch (action.type) {
        case capTableTypes.GET_SUMMARY_SUCCESS:
            return {
                ...state,
                summary: {
                    ...state.summary,
                    data: action.payload,
                },
            };
        case capTableTypes.SET_SUMMARY_LOADING:
            return {
                ...state,
                summary: {
                    ...state.summary,
                    loading: action.payload,
                },
            };
        /* Cap table registry ops */
        case capTableTypes.GET_REGISTRY_SUCCESS:
            return {
                ...state,
                registry: {
                    ...state.registry,
                    data: action.payload,
                },
            };
        case capTableTypes.SET_REGISTRY_LOADING:
            return {
                ...state,
                registry: {
                    ...state.registry,
                    loading: action.payload,
                },
            };
        case capTableTypes.ADD_SHARE_CLASS_SUCCESS:
            return {
                ...state,
                registry: {
                    ...state.registry,
                    data: {
                        ...state.registry
                            .data /*,
                        classes: [
                            ...state.registry.data.classes,
                            {
                                class: action.payload.class.toLowerCase(),
                                class_description: action.payload.description,
                                class_amount: action.payload.amount,
                                class_price: action.payload.price,
                            },
                        ],*/,
                    },
                },
            };
        case capTableTypes.ADD_SHARE_CATEGORY_SUCCESS:
            return {
                ...state,
                registry: {
                    ...state.registry,
                    data: {
                        ...state.registry
                            .data /*,
                        categories: [
                            ...state.registry.data.categories,
                            {
                                category_description:
                                    action.payload.description,
                                class: action.payload.class,
                                category_amount: action.payload.amount,
                                category_price: action.payload.price,
                            },
                        ],*/,
                    },
                },
            };
        case capTableTypes.ADD_INVESTOR_SUCCESS:
            return {
                ...state,
                registry: {
                    ...state.registry,
                    data: {
                        ...state.registry
                            .data /*,
                        categories: [
                            ...state.registry.data.categories,
                            {
                                category_description:
                                    action.payload.description,
                                class: action.payload.class,
                                category_amount: action.payload.amount,
                                category_price: action.payload.price,
                            },
                        ],*/,
                    },
                },
            };

        case capTableTypes.GET_CLASSES_SUCCESS:
            return {
                ...state,
                classes: {
                    ...state.classes,
                    data: action.payload,
                },
            };
        case capTableTypes.SET_CLASSES_LOADING:
            return {
                ...state,
                classes: {
                    ...state.classes,
                    loading: action.payload,
                },
            };
        case capTableTypes.GET_CLASS_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    data: action.payload,
                },
            };
        case capTableTypes.SET_CLASS_CATEGORIES_LOADING:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    loading: action.payload,
                },
            };

        case capTableTypes.GET_CATEGORIES_BY_CLASS_SUCCESS:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    data: action.payload,
                },
            };
        case capTableTypes.SET_CATEGORIES_BY_CLASS_LOADING:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    loading: action.payload,
                },
            };
        /** === End cap table registry ops === */
        case capTableTypes.GET_SHAREHOLDERS_SUCCESS:
            return {
                ...state,
                shareholders: {
                    ...state.shareholders,
                    data: action.payload,
                },
            };
        case capTableTypes.SET_SHAREHOLDERS_LOADING:
            return {
                ...state,
                shareholders: {
                    ...state.shareholders,
                    loading: action.payload,
                },
            };
        case capTableTypes.GET_INVESTORS_SUCCESS:
            return {
                ...state,
                investors: {
                    ...state.investors,
                    data: action.payload,
                },
            };
        case capTableTypes.SET_INVESTORS_LOADING:
            return {
                ...state,
                investors: {
                    ...state.investors,
                    loading: action.payload,
                },
            };
        case capTableTypes.SET_SHARE_CLASS_LOADING:
            return {
                ...state,
                newClass: {
                    ...state.newClass,
                    loading: action.payload,
                },
            };
        case capTableTypes.GET_CAP_TABLE_CHART_SUCCESS:
            return {
                ...state,
                chart: {
                    ...state.chart,
                    data: action.payload,
                },
            };
        case capTableTypes.SET_CAP_TABLE_CHART_LOADING:
            return {
                ...state,
                chart: {
                    ...state.chart,
                    loading: action.payload,
                },
            };
        case capTableTypes.SET_SHARE_CATEGORY_LOADING:
            return {
                ...state,
                newCategory: {
                    ...state.newCategory,
                    loading: action.payload,
                },
            };
        case capTableTypes.SET_INVESTOR_LOADING:
            return {
                ...state,
                newInvestor: {
                    ...state.newInvestor,
                    loading: action.payload,
                },
            };
        case capTableTypes.GET_ENTRY_SUCCESS:
            return {
                ...state,
                capTableEntry: {
                    ...state.capTableEntry,
                    data: action.payload,
                },
            };
        case capTableTypes.SET_ENTRY_LOADING:
            return {
                ...state,
                capTableEntry: {
                    ...state.capTableEntry,
                    loading: action.payload,
                },
            };
        case capTableTypes.UPDATE_SHAREHOLDER_SUCCESS: {
            let i = _.findIndex(state.shareholders.data, {
                ct_id: action.payload.ct_id,
            });

            // Build a new shareholder array
            let newData = JSON.parse(JSON.stringify(state.shareholders.data));
            newData[i] = { ...state.shareholders.data[i], ...action.payload };
            newData[i].edit = { ...newData[i].edit, ...action.payload };

            return {
                ...state,
                shareholders: {
                    ...state.shareholders,
                    data: newData,
                },
            };
        }
        case capTableTypes.DELETE_SHAREHOLDER_SUCCESS: {
            let i = _.findIndex(state.shareholders.data, {
                ct_id: action.payload,
            });

            // Build a new shareholder array
            let newData = JSON.parse(JSON.stringify(state.shareholders.data));
            newData.splice(i, 1);

            return {
                ...state,
                shareholders: {
                    ...state.shareholders,
                    data: newData,
                },
            };
        }
        default:
            return state;
    }
};

export default capTableReducer;
