import React, { useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';

import Paper from '../../../components/StyledPaper';
import InviteModule from './InviteModule';
import ConfirmationDialog from '../../../components/ConfirmationDialog';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as profileActions from '../../../store/profile/profileActions';
import * as issuerInviteActions from '../../../store/issuerInvite/issuerInviteActions';
import * as dialogActions from '../../../store/dialog/dialogActions';

const IssuerInvites = (props) => {
    const { issuerInvite, issuerInviteActions, dialogActions } = props;

    const getStatusColor = (status) => {
        switch (status) {
            case 'accepted':
                return 'success';
            case 'pending':
                return 'warning';
            case 'canceled':
                return 'danger';
            default:
                return 'secondary';
        }
    };

    useEffect(issuerInviteActions.getIssuerInvites, []);

    const content = () => {
        return (
            <div>
                <Typography variant="overline">
                    <h1>Team members</h1>
                </Typography>

                {issuerInvite.loading && (
                    <Grid container item xs={12} justifyContent="center">
                        <CircularProgress />
                    </Grid>
                )}
                {issuerInvite.data &&
                    issuerInvite.data.map((invite, i) => (
                        <Grid
                            key={i}
                            container
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingLeft: '16px',
                                paddingRight: '16px',
                                marginBottom: '16px',
                            }}
                        >
                            <div>
                                <Typography variant="body2">
                                    {`${invite.first_name} ${invite.last_name}`}
                                </Typography>
                                <Typography variant="body2">
                                    <a
                                        href={`mailto:${invite.email}`}
                                        className="link no-decoration"
                                    >
                                        {invite.email}
                                    </a>
                                </Typography>
                            </div>
                            <Chip
                                label={invite.status}
                                color={getStatusColor(invite.status)}
                                onClick={() => {}}
                                onDelete={
                                    invite.status === 'pending'
                                        ? () => {
                                              dialogActions.openDialog(
                                                  'Cancel invitation',
                                                  "Are you sure you want to cancel this issuer's invitation?",
                                                  () => {
                                                      issuerInviteActions.cancelInvite(
                                                          invite.id,
                                                          () =>
                                                              issuerInviteActions.getIssuerInvites(),
                                                          dialogActions
                                                      );
                                                  },
                                                  'Close',
                                                  'Cancel'
                                              );
                                          }
                                        : null
                                }
                            />
                        </Grid>
                    ))}
                <ConfirmationDialog />
            </div>
        );
    };
    return (
        <React.Fragment>
            {/* Invite new issuers */}
            <InviteModule
                successCallback={() => issuerInviteActions.getIssuerInvites()}
                skipPaperComponent={props.skipPaperComponent}
            />
            <br />
            {/* Previous invites */}
            <Grid item xs={props.xs || 12}>
                {props.skipPaperComponent ? (
                    <div>{content()}</div>
                ) : (
                    <Paper padding={props.padding}>{content()}</Paper>
                )}
            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps = ({ profile, issuerInvite }) => {
    return {
        profile: profile,
        issuerInvite: issuerInvite,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        profileActions: bindActionCreators(profileActions, dispatch),
        issuerInviteActions: bindActionCreators(issuerInviteActions, dispatch),
        dialogActions: bindActionCreators(dialogActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IssuerInvites);
