import makeStyles from '@mui/styles/makeStyles';

const textFieldStyles = makeStyles((theme) => ({
    errorMessage: {
        fontSize: '10px',
        color: '#ED7571',
    },
}));

export default textFieldStyles;
