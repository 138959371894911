import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import BackIcon from '@mui/icons-material/ChevronLeft';
import { getBasePath, getPoppedPath } from '../../utilities/router';
import titleStyles from '../styles/titleStyles';

const Title = (props) => {
    const classes = titleStyles();
    const history = useHistory();
    const location = useLocation();
    const { children, localBack } = props;

    const handleBackButton = () => {
        if (localBack) {
            history.push(getPoppedPath(location));
        } else {
            history.push(`/${getBasePath(location)}`);
        }
    };

    return (
        <Container className={classes.container}>
            <div>
                <Typography component="h2" className={classes.text}>
                    {props.text}
                </Typography>
            </div>
            <div>
                {children ? children : null}
                <Button onClick={handleBackButton}>
                    <BackIcon />
                    Back{!localBack ? ' to Dashboard' : ''}
                </Button>
            </div>
        </Container>
    );
};

export default Title;
