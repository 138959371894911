import React from 'react'
import Humanize from 'humanize-plus';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

import ClassIcon from '@mui/icons-material/Class';
import CategoryIcon from '@mui/icons-material/Category';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PieChartIcon from '@mui/icons-material/PieChart';

import withStyles from '@mui/styles/withStyles';
import infoDialogStyles from '../styles/infoDialogStyles';

const ClassDialog = (props) => {
    const {
        shareClass, classes,
        dialogIsOpen, setDialogIsOpen,
    } = props
        
    const closeDialog = () => {
        setDialogIsOpen(false)
    }

    return (
        <Dialog maxWidth="sm" fullWidth open={dialogIsOpen} onBackdropClick={e => setDialogIsOpen(false)}>
            <DialogTitle>Class Information</DialogTitle>
            <DialogContent>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        {
                            shareClass.loading ? (
                                <Grid container item xs={12} justifyContent="center">
                                    <CircularProgress />
                                </Grid>
                            ) : (
                                <List component="nav">
                                    <ListItem>
                                        <ListItemAvatar>
                                            <ClassIcon
                                                className={classes.blue}
                                                fontSize="large"
                                            />
                                        </ListItemAvatar>
                                        <ListItemText primary={
                                            <Typography variant="body2">
                                                <b>Class</b>
                                            </Typography>
                                        } secondary={shareClass.class_description} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <CategoryIcon
                                                fontSize="large"
                                                className={classes.blue}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText primary={
                                            <Typography variant="body2">
                                                <b>Class type</b>
                                            </Typography>
                                        } secondary={shareClass.class} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <PieChartIcon
                                                fontSize="large"
                                                className={classes.blue}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText primary={
                                            <Typography variant="body2">
                                                <b>Total amount</b>
                                            </Typography>
                                        } secondary={Humanize.formatNumber(shareClass.class_amount) || 0} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <AttachMoneyIcon
                                                fontSize="large"
                                                className={classes.blue}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText primary={
                                            <Typography variant="body2">
                                                <b>Main currency / price per unit</b>
                                            </Typography>
                                        } secondary={`${shareClass.equity_main_currency} — ${shareClass.class_price || 'N/A'}`} />
                                    </ListItem>
                                </List>
                            )
                        }
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <DialogActions className={classes.action}>
                            <Button
                                color="primary"
                                onClick={() => {
                                    closeDialog();
                                }}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default withStyles(infoDialogStyles)(ClassDialog)