import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import authStyles from '../authStyles';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles';

import fundraiseStyles from '../../../components/styles/fundraiseStyles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Container from '@mui/material/Container';
import sta from '../../../assets/img/sta.png';
import merj from '../../../assets/img/merj.png';
import gsx from '../../../assets/img/gsx.png';
import fusang from '../../../assets/img/fusang.png';
import techemy from '../../../assets/img/techemy.png';
import acceleprise from '../../../assets/img/acceleprise.png';
import republic from '../../../assets/img/republic.png';
import front_fundr from '../../../assets/img/frontfundr.png';
import seeders from '../../../assets/img/seeders.png';
import ihq from '../../../assets/img/ihq.png';
import plaza from '../../../assets/img/plaza.jpg';
import cambridge_wilkson from '../../../assets/img/cambridge_wilkson.jpg';
import castle from '../../../assets/img/castle.jpg';
import fund_america from '../../../assets/img/fund_america.png';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';

const StyledTableCell = withStyles((theme) => ({
    head: {
        //color: 'white',
    },
    body: {
        //fontSize: '1rem',
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        height: '50px',
        '&:nth-of-type(odd)': {
            height: '50px',
        },
    },
}))(TableRow);

function createData(
    alternative,
    short_description,
    typical_cost_structure,
    verified_partners
) {
    return {
        alternative,
        short_description,
        typical_cost_structure,
        verified_partners,
    };
}

const rows = [
    createData(
        'Advisory /\nConsulting',
        'In-depth analysis of potential fundraising options and liquidity solutions',
        'Monthly retainer (not success-based)\n(estimate $5-10K per month)',
        [sta, techemy]
    ),
    createData(
        'Self-managed',
        'Use whitelabeling service to build custom website for your raise. Integrated investor onboarding with KYC/AML, funding and documents support.  That can be used in conjunction with you looking for Venture Capital funding as well.',
        'One-time up front and additional monthly costs during campaign. KYC/AML and investor accreditation charged as they occur.\n(estimate $5-15K total)',
        [ihq, fund_america]
    ),
    createData(
        'Venture capital /\nAngel investing',
        'Need to match stage of your company with stage of the fund/individual. Typically low odds to receive funding this route.  Will require deep diligence and potentially board seats.',
        'Estimate low cost up front, though typically most favorably terms for VC, board seats\n(estimate mostly time against low success rates)',
        [plaza, acceleprise]
    ),
    createData(
        'Crowdfunding',
        'Access retail holders. Typically based on where issuer is incorporated. Fundraising limits exist.',
        '$0 to minimal upfront direct cash cost. % of raise in cash and equity\n(up to 5% cash and 5% equity)',
        [republic, seeders, front_fundr]
    ),
    createData(
        'Investment bank /\nbroker-dealer',
        'Typically for later stage/post-revenue. Helps to have already done an earlier seed round. Receive significant support on preparation and your story/marketing materials by investment bankers',
        'Monthly retainer, % of successful raise and maybe equity/warrants\n(estimate $5-10K monthly / 5-10% of raise amount / potential 5-10% in “warrants” of raise amount)',
        [castle, cambridge_wilkson]
    ),
    createData(
        'Primary offering\non stock exchange',
        'Access to global holders and support secondary trading. Requires infrastructure to support ongoing disclosure requirements, which are significantly less onerous than typical public company reporting',
        'One-time up front and additional on going listing costs.\n(estimate $50-100K by listing + minimal ongoing)',
        [merj, gsx, fusang]
    ),
];

let initialValues = {};
const FundingOptions = (props) => {
    const classes = authStyles();
    const fundraiseClasses = fundraiseStyles();

    const [openContactDialog, setOpenContactDialog] = useState(false);

    const closeContactDialog = () => {
        setOpenContactDialog(false);
    };

    return (
        <Formik onSubmit={(values, { setSubmitting }) => {}}>
            <Form noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Paper elevation={3} className={fundraiseClasses.paper}>
                            <Grid
                                container
                                style={{
                                    marginTop: '2rem',
                                    marginBottom: '2rem',
                                }}
                            >
                                <Typography
                                    className={fundraiseClasses.subtitleContent}
                                    style={{ color: 'black' }}
                                >
                                    Below is a relatively exhaustive list of
                                    fundraising alternatives before your big
                                    IPO. Note: in most instances you can combine
                                    one or more options to increase your chances
                                    of fundraising success and speed up the
                                    process. This may require legal, accounting
                                    and marketing or other services support.
                                    Advisory services may help identify your
                                    need.
                                </Typography>
                            </Grid>
                            <TableContainer
                                className={fundraiseClasses.tableContainer}
                            >
                                <Table
                                    className={classes.table}
                                    aria-label="customized table"
                                >
                                    <TableHead>
                                        <StyledTableRow>
                                            <StyledTableCell
                                                className={
                                                    fundraiseClasses.tableHeadTitle
                                                }
                                            >
                                                Alternative
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className={
                                                    fundraiseClasses.tableHeadTitle
                                                }
                                            >
                                                Short description
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className={
                                                    fundraiseClasses.tableHeadTitle
                                                }
                                            >
                                                Typical cost structure
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className={
                                                    fundraiseClasses.tableHeadTitle
                                                }
                                            >
                                                Examples of Verified Partners
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <StyledTableRow
                                                key={row.alternative}
                                            >
                                                <StyledTableCell
                                                    component="th"
                                                    scope="row"
                                                    className={`${
                                                        fundraiseClasses.styleTableCellColor
                                                    }
                                                        ${
                                                            row.alternative ===
                                                            'Primary offering\non stock exchange'
                                                                ? fundraiseClasses.borderBottomNone
                                                                : null
                                                        }`}
                                                >
                                                    <b>{row.alternative}</b>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className={
                                                        fundraiseClasses.styleTableCellText
                                                    }
                                                >
                                                    {row.short_description}
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className={
                                                        fundraiseClasses.styleTableCellText
                                                    }
                                                >
                                                    {row.typical_cost_structure}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {row.verified_partners.map(
                                                        (partner) => (
                                                            <img
                                                                className={
                                                                    fundraiseClasses.imgMarginRight
                                                                }
                                                                src={partner}
                                                                width={80}
                                                                alt=""
                                                                key={partner}
                                                            />
                                                        )
                                                    )}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Grid>

                <Dialog maxWidth="sm" fullWidth open={openContactDialog}>
                    <DialogTitle
                        style={{ textAlign: 'center', marginTop: '1rem' }}
                    >
                        Please use the &nbsp;
                        <a
                            target="_blank"
                            href="https://digtl.co/contact"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <b>Contact form</b>
                        </a>
                        &nbsp;to Upgrade your current subscription plan
                    </DialogTitle>
                    <DialogContent>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} md={12}>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={(values, { setSubmitting }) => {}}
                                >
                                    {({ isSubmitting }) => (
                                        <Container>
                                            <Form
                                                className={classes.form}
                                                noValidate
                                            >
                                                <DialogActions
                                                    style={{
                                                        marginTop: '1.5rem',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Button
                                                        color="primary"
                                                        onClick={() => {
                                                            closeContactDialog();
                                                        }}
                                                    >
                                                        Close
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => {
                                                            window.open(
                                                                'https://digtl.co/contact',
                                                                '_blank'
                                                            );
                                                        }}
                                                    >
                                                        Contact Form
                                                    </Button>
                                                </DialogActions>
                                            </Form>
                                        </Container>
                                    )}
                                </Formik>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </Form>
        </Formik>
    );
};

export default FundingOptions;
