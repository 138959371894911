import React from 'react';
import clsx from 'clsx';
import Button from '../Button/Button';
import tableButtonStyles from '../styles/tableButtonStyles';

const TableButton = (props) => {
    const classes = tableButtonStyles();
    const { children, className, ...rest } = props;

    return (
        <Button
            size="small"
            className={clsx(classes.button, className)}
            {...rest}
        >
            {children}
        </Button>
    );
};

export default TableButton;
