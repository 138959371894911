import useSWR from "swr"
import { API_BASE_URL } from "../../config"
import fetcher from "../../utilities/fetcher"

const useWalletAddresses = (wallet) => {
    // const { mutate } = useSWRConfig()
    const { data, error, mutate, isValidating } = useSWR(() => wallet ? `${API_BASE_URL}/blockchain/wallet/${wallet}/addresses` : null, fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    })

    return {
        walletAddresses: data || [],
        walletAddressesLoading: !error && !data,
        walletAddressesValidating: isValidating,
        walletAddressesError: error,
        updateWalletAddresses: () => mutate(),
    }
}

export default useWalletAddresses