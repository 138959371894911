import React from 'react';
import {
    Link as RouterLink,
    useHistory /*useLocation*/,
} from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActions from '../../../store/auth/authActions';

import { Formik, Form, Field } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';

import authStyles from '../authStyles';
import TextField from '../../../components/TextField/TextField';
import PasswordField from '../../../components/PasswordField';

const initialValues = {
    email: '',
    password: '',
};

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Please enter a valid email address')
        .required('Please enter your email address'),
    password: Yup.string().required('Please enter your password'),
});

const LoginForm = (props) => {
    const { authActions, isAdmin } = props;
    const classes = authStyles();
    const history = useHistory();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const has2fa = isAdmin; // in the future, we might enable 2FA for all users

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                authActions.login(
                    values,
                    closeSnackbar,
                    enqueueSnackbar,
                    setSubmitting,
                    history,
                    isAdmin,
                    has2fa
                );
            }}
        >
            {({ isSubmitting }) => (
                <Form className={classes.form} noValidate>
                    <Field
                        component={TextField}
                        name="email"
                        type="email"
                        label="Email"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                    />
                    <PasswordField label="Password" name="password" />
                    <div className={classes.actions}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="outlined"
                            className={classes.buttonLabel}
                            fullWidth={isAdmin}
                            disabled={isSubmitting}
                        >
                            {!isSubmitting ? (
                                <span>Log In</span>
                            ) : (
                                <CircularProgress size={30} />
                            )}
                        </Button>
                        {!isAdmin && (
                            <Button
                                variant="outlined"
                                color="secondary"
                                className={classes.secondaryButtonLabel}
                                onClick={() => {
                                    history.push('/signup');
                                }}
                            >
                                Sign Up
                            </Button>
                        )}
                    </div>
                    {isAdmin && (
                        <Typography variant="body1" className="centered">
                            <Link
                                className={classes.link}
                                component={RouterLink}
                                to="/login"
                            >
                                Go to user login page
                            </Link>
                        </Typography>
                    )}
                    <Typography
                        component={RouterLink}
                        to="/forgot-password"
                        className={classes.link}
                    >
                        Forgot Password?
                    </Typography>
                    <Typography
                        component={'a'}
                        rel="noopener noreferrer"
                        href="https://invest.digtl.co/"
                        className={classes.link}
                    >
                        Investor Login
                    </Typography>
                </Form>
            )}
        </Formik>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        authActions: bindActionCreators(authActions, dispatch),
    };
};

export default connect(null, mapDispatchToProps)(LoginForm);
