import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '../../../../components/TextField/TextField';
import authStyles from '../../authStyles';
import fundraiseOfferingStyles from '../../../../components/styles/fundraiseOfferingStyles';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormikSelect from '../../../../components/Formik/FormikSelect';
import Grid from '@mui/material/Grid';
import { CheckboxWithLabel } from 'formik-mui';
import Typography from '@mui/material/Typography';

import FormikDatePicker from '../../../../components/Formik/FormikDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { DateFormatter } from '../../../../utilities/formatters';
import FundButtonGroup from '../FundButtonGroup';
const validationSchema = Yup.object({
    automated_email_address: Yup.string().required(
        'Please enter issuer automated email address'
    ),
    security_details_name: Yup.string().required(
        'Please enter issuer security details name'
    ),
    interest_rate: Yup.string().required('Please enter interest rate'),
    security_details_description: Yup.string().required(
        'Please enter security details description'
    ),
});
const SecurityDetails = (props) => {
    const classes = authStyles();
    const offeringClasses = fundraiseOfferingStyles();
    const [sendEmails, setSendEmails] = useState(
        props.initialValues.send_emails
    );
    const [newLogo, setNewLogo] = useState('');

    const [registeredTransferAgent, setRegisteredTransferAgent] = useState(
        props.initialValues.registered_transfer_agent
    );

    const [securityDetailsType, setSecurityDetailsType] = useState(
        props.initialValues.security_details_type
    );

    const [regulatoryExemption, setRegulatoryExemption] = useState(
        props.initialValues.regulatory_exemption
    );

    const [maturityDate, setMaturityDate] = useState(
        props.initialValues.maturity_date
            ? props.initialValues.maturity_date
            : new Date()
    );
    const [
        investorTransferRestrictions,
        setInvestorTransferRestrictions,
    ] = useState(
        props.initialValues.investor_transfer_restrictions
            ? props.initialValues.investor_transfer_restrictions === true
                ? 'yes'
                : 'no'
            : 'no'
    );

    const uploadFile = (file) => {
        setNewLogo(file.name);
        props.formData.set('logo', file, file.name);
    };

    const nextStep = () => {
        props.nextStep();
    };

    const previousStep = () => {
        props.previousStep();
    };

    const closeDialog = () => {
        props.closeDialog();
    };

    let security_details_types = [
        { name: 'Debt', value: 'DEBT' },
        { name: 'Equity', value: 'EQUITY' },
        { name: 'Option', value: 'OPTION' },
        { name: 'Warrant', value: 'WARRANT' },
    ];

    let regulatory_exemptions = [
        { name: 'None', value: 'NONE' },
        { name: '506(c)', value: '506(c)' },
        { name: '506(b)', value: '506(b)' },
        { name: 'Reg A, Tier 1', value: 'REGA_TIER1' },
        { name: 'Reg A, Tier 2', value: 'REGA_TIER2' },
        { name: '4(a)(6)', value: '4(a)(6)' },
        { name: 'US State Exemptions', value: 'US_STATE_EXEMPTION' },
        { name: 'Other', value: 'OTHER' },
    ];

    let registered_transfer_agents = [
        { name: 'None', value: 'NONE' },
        { name: 'FundAmerica Stock Transfer', value: 'FUNDAMERICA' },
        { name: 'Other (specify below)', value: 'OTHER' },
    ];

    const changeTransferAgent = (e) => {
        setRegisteredTransferAgent(e.target.value);
    };

    const changeTransferRestrictions = (e) => {
        setInvestorTransferRestrictions(e.target.value);
    };

    const changeSecurityDetailsType = (e) => {
        setSecurityDetailsType(e.target.value);
    };

    const changeRegulatoryExemption = (e) => {
        setRegulatoryExemption(e.target.value);
    };

    const handleDateChange = (date, type) => {
        let newDate = DateFormatter({
            value: date,
            format: 'date',
        });
        setMaturityDate(newDate);
    };

    return (
        <div id="step5">
            <Formik
                initialValues={props.initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    values['maturity_date'] = maturityDate;

                    props.saveValues(values);
                    nextStep();
                    props.changeKey();
                }}
            >
                {({ setFieldValue }) => (
                    <Container maxWidth="lg">
                        <Form className={classes.form} noValidate>
                            <Grid container spacing={3} className="flex-left">
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="email"
                                        label="Automated email from address:"
                                        name="automated_email_address"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputLabel shrink htmlFor="logo">
                                        Graphic/Logo (Optional):
                                    </InputLabel>
                                    <Button
                                        component="label"
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        className={classes.buttonWithAlignment}
                                    >
                                        Browse
                                        <input
                                            onChange={(e) => {
                                                uploadFile(e.target.files[0]);
                                            }}
                                            style={{ display: 'none' }}
                                            type="file"
                                            name="logo"
                                            accept=".png"
                                        />
                                    </Button>
                                    {newLogo !== '' ? (
                                        <Typography component="div">
                                            {newLogo}
                                        </Typography>
                                    ) : null}
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Field
                                        component={CheckboxWithLabel}
                                        type="checkbox"
                                        name="send_emails"
                                        Label={{
                                            label: (
                                                <div
                                                    className={classes.checkbox}
                                                >
                                                    Send e-mails to issuers and
                                                    holders.
                                                </div>
                                            ),
                                        }}
                                        checked={sendEmails}
                                        onClick={() =>
                                            setSendEmails(!sendEmails)
                                        }
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <hr className={offeringClasses.horizontalLine} />
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Field
                                        component={FormikSelect}
                                        name="security_details_type"
                                        variant="outlined"
                                        label="Type *"
                                        required
                                        fullWidth
                                        defaultValue={securityDetailsType}
                                        onClick={changeSecurityDetailsType}
                                    >
                                        {security_details_types.map(
                                            (security_details_type) => (
                                                <MenuItem
                                                    key={
                                                        security_details_type.value
                                                    }
                                                    value={
                                                        security_details_type.value
                                                    }
                                                >
                                                    {security_details_type.name}
                                                </MenuItem>
                                            )
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Name"
                                        name="security_details_name"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Interest Rate"
                                        name="interest_rate"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        numeric={1}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                    >
                                        <Field
                                            component={FormikDatePicker}
                                            name="maturity_date"
                                            value={maturityDate}
                                            label="Maturity Date"
                                            onChange={(date) =>
                                                handleDateChange(
                                                    date,
                                                    'maturity_date'
                                                )
                                            }
                                            format="MM/dd/yyyy"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Field
                                        component={TextField}
                                        name="security_details_description"
                                        type="text"
                                        label="Description"
                                        rows={5}
                                        multiline
                                        fullWidth
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <hr className={offeringClasses.horizontalLine} />

                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Field
                                        component={FormikSelect}
                                        name="regulatory_exemption"
                                        variant="outlined"
                                        label="The regulatory exemption:"
                                        required
                                        fullWidth
                                        defaultValue={regulatoryExemption}
                                        onClick={changeRegulatoryExemption}
                                    >
                                        {regulatory_exemptions.map(
                                            (regulatory_exemption) => (
                                                <MenuItem
                                                    key={
                                                        regulatory_exemption.value
                                                    }
                                                    value={
                                                        regulatory_exemption.value
                                                    }
                                                >
                                                    {regulatory_exemption.name}
                                                </MenuItem>
                                            )
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={FormikSelect}
                                        name="registered_transfer_agent"
                                        variant="outlined"
                                        label="Registered Transfer Agent"
                                        required
                                        fullWidth
                                        defaultValue={registeredTransferAgent}
                                        onClick={changeTransferAgent}
                                    >
                                        {registered_transfer_agents.map(
                                            (registered_transfer_agent) => (
                                                <MenuItem
                                                    key={
                                                        registered_transfer_agent.value
                                                    }
                                                    value={
                                                        registered_transfer_agent.value
                                                    }
                                                >
                                                    {
                                                        registered_transfer_agent.name
                                                    }
                                                </MenuItem>
                                            )
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={FormikSelect}
                                        name="investor_transfer_restrictions"
                                        variant="outlined"
                                        label="Are there any restrictions on investor's ability to transfer ownership to others?"
                                        required
                                        fullWidth
                                        defaultValue={
                                            investorTransferRestrictions
                                        }
                                        onClick={changeTransferRestrictions}
                                    >
                                        <MenuItem key={'no'} value={false}>
                                            NO
                                        </MenuItem>
                                        <MenuItem key={'yes'} value={true}>
                                            YES
                                        </MenuItem>
                                    </Field>
                                </Grid>
                            </Grid>

                            {registeredTransferAgent === 'OTHER' && (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Field
                                            component={TextField}
                                            name="registered_transfer_agent_description"
                                            type="text"
                                            label="Registered Transfer Agent Description"
                                            rows={5}
                                            multiline
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            <hr className={offeringClasses.horizontalLine} />
                            <Grid container spacing={1}>
                                <Typography component="div">
                                    <b>Security Identifiers: </b>Enable
                                    broker-dealers, research firms, data
                                    aggregators (e.g. Bloomberg), trading desks,
                                    exchanges and others to reference your
                                    company and the securities.
                                </Typography>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="CUSIP #"
                                        name="cusip"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        placeholder={'optional'}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={FormikSelect}
                                        name="is_dtc_eligible"
                                        variant="outlined"
                                        label="Is (or will be) this security DTC eligible?"
                                        required
                                        fullWidth
                                        defaultValue={
                                            props.initialValues.is_dtc_eligible
                                        }
                                    >
                                        <MenuItem key={'no'} value={false}>
                                            NO
                                        </MenuItem>
                                        <MenuItem key={'yes'} value={true}>
                                            YES
                                        </MenuItem>
                                    </Field>
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={FormikSelect}
                                        name="is_traded_on_exchange"
                                        variant="outlined"
                                        label="Will this security be traded on an exchange?"
                                        required
                                        fullWidth
                                        defaultValue={
                                            props.initialValues
                                                .is_traded_on_exchange
                                        }
                                    >
                                        <MenuItem key={'no'} value={false}>
                                            NO
                                        </MenuItem>
                                        <MenuItem key={'yes'} value={true}>
                                            YES
                                        </MenuItem>
                                    </Field>
                                </Grid>
                            </Grid>
                            <FundButtonGroup
                                previousStep={previousStep}
                                closeDialog={closeDialog}
                            />
                        </Form>
                    </Container>
                )}
            </Formik>
        </div>
    );
};

export default SecurityDetails;
