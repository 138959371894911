import * as paymentTypes from './paymentTypes';

const initialState = {
    pricePlans: {
        data: null,
        loading: true,
        error: null,
    },
    paymentPortal: {
        data: null,
        loading: true,
        error: null,
    },
};

const kycReducer = (state = initialState, action) => {
    switch (action.type) {
        case paymentTypes.GET_PRICE_PLANS_SUCCESS:
            return {
                ...state,
                pricePlans: {
                    ...state.pricePlans,
                    data: action.payload,
                },
            };
        case paymentTypes.SET_PRICE_PLANS_LOADING:
            return {
                ...state,
                pricePlans: {
                    ...state.pricePlans,
                    loading: action.payload,
                },
            };
        case paymentTypes.SET_PRICE_PLANS_ERROR:
            return {
                ...state,
                pricePlans: {
                    ...state.pricePlans,
                    error: action.payload,
                },
            };

        case paymentTypes.GET_PAYMENT_PORTAL_SUCCESS:
            return {
                ...state,
                paymentPortal: {
                    ...state.paymentPortal,
                    data: action.payload,
                },
            };
        case paymentTypes.SET_PAYMENT_PORTAL_LOADING:
            return {
                ...state,
                paymentPortal: {
                    ...state.paymentPortal,
                    loading: action.payload,
                },
            };
        case paymentTypes.SET_PAYMENT_PORTAL_ERROR:
            return {
                ...state,
                paymentPortal: {
                    ...state.paymentPortal,
                    error: action.payload,
                },
            };
        default:
            return state;
    }
};

export default kycReducer;
