import { LinearProgress, Typography } from '@mui/material'
import React from 'react'

const DataLoading = ({ loading, text, sx }) => {
    return (
        loading &&
        <>
            <Typography variant='body2' as='p' sx={sx}>{text}</Typography>
            <br />
            <LinearProgress />
        </>
    )
}

export default DataLoading