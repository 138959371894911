import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'

import deleteAmpCategory from '../../../../api/blockchain/deleteAmpCategory';

import SubmitButton from '../../../../components/Form/SubmitButton';
import { snackbar } from '../../../../utilities/snackbarUtils'
import { errorParser } from '../../../../components/LiquidForm/utils/errorParser';

const DeleteCategoryDialog = ({ open, handleClose, updateCategories, category }) => {
    const [loading, setLoading] = React.useState(false)

    const onSubmit = async () => {
        setLoading(true)
        await deleteAmpCategory(category?.id).then(response => {
            snackbar.success('Succesfully deleted the whitelist.');
            handleClose()
            updateCategories()
        }).catch((error) => {
            snackbar.error(errorParser(error));
        }).finally(() => {
            setLoading(false)
        })
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Delete the whitelist</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div style={{ display: 'flex' }}>
                        <Typography variant='body2'>
                            Are you sure you want to delete the <b>{category?.name}</b> whitelist?
                        </Typography>

                    </div>
                    <br />
                    <br />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <SubmitButton 
                    text='Delete'
                    color='danger'
                    disabled={loading}
                    loading={loading}
                    onClick={onSubmit}
                />
            </DialogActions>
        </Dialog>
    )
}

export default DeleteCategoryDialog