import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import authStyles from '../authStyles';
import HelpTooltip from '../../../components/HelpTooltip/HelpTooltip';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSnackbar } from 'notistack';
import RemoveDocumentButton from '../../../components/Button/RemoveDocumentButton';

const UploadDocuments = (props) => {
    const classes = authStyles();
    const [additionalDocuments, setAdditionalDocuments] = useState('');
    const [amendments, setAmendments] = useState('');
    const [shareholdersAgreement, setShareholdersAgreement] = useState('');
    const [articlesOfIncorporation, setArticlesOfIncorporation] = useState('');
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const uploadFile = (file, name) => {
        let type = file.type;
        if (file.type === '' && file.name.split('.')[1] === 'csv') {
            type = 'text/csv';
        }

        if (
            type !== 'application/pdf' &&
            type !== 'text/csv' &&
            //    file.type !== 'text/plain' &&
            type !== 'application/vnd.ms-excel' &&
            type !==
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
            enqueueSnackbar(
                'Only .csv, .xlx, .xls, .xlsx and .pdf files are allowed',
                {
                    variant: 'error',
                }
            );

            setTimeout(function () {
                closeSnackbar();
            }, 2000);
        } else {
            props.formData.set(name, file, file.name);
            switch (name) {
                case 'additional_documents':
                    setAdditionalDocuments(file.name);
                    break;
                case 'amendments':
                    setAmendments(file.name);
                    break;
                case 'shareholders_agreement':
                    setShareholdersAgreement(file.name);
                    break;
                case 'articles_of_incorporation':
                    setArticlesOfIncorporation(file.name);
                    break;

                default:
                    setAdditionalDocuments(file.name);
                    break;
            }
        }
    };

    const cancelFile = (name) => {
        props.formData.set(name, null);
        switch (name) {
            case 'additional_documents':
                setAdditionalDocuments('');
                break;
            case 'amendments':
                setAmendments('');
                break;
            case 'shareholders_agreement':
                setShareholdersAgreement('');
                break;
            case 'articles_of_incorporation':
                setArticlesOfIncorporation('');
                break;
            default:
                break;
        }
    };

    const nextStep = () => {
        props.nextStep();
    };

    const previousStep = () => {
        if (props.initialValues.option_plan === 'no') {
            props.setCustomStep(7);
        } else {
            props.previousStep();
        }
    };

    return (
        <div id="step10">
            <Formik
                initialValues={props.initialValues}
                onSubmit={(values, { setSubmitting }) => {}}
            >
                <Paper className={classes.paper}>
                    <Typography component="div" style={{ textAlign: 'center' }}>
                        <h2>
                            Would you like to upload your key documents?{' '}
                            <HelpTooltip
                                title={
                                    'Start to create your data room now so that you are ready for new investor inquiries. It will also quicken the launch of fundraising campaigns and listing applications.'
                                }
                            />
                        </h2>
                    </Typography>

                    <Container style={{ textAlign: 'right' }}>
                        <Form className={classes.form} noValidate>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <InputLabel
                                        style={{
                                            color: '#666',
                                            fontSize: '1rem',
                                        }}
                                        htmlFor="articles_of_incorporation"
                                    >
                                        1. Articles of Incorporation
                                        <HelpTooltip
                                            title={
                                                'The document or charter that establishes the existence of a corporation is needed.'
                                            }
                                        />
                                    </InputLabel>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <ButtonGroup
                                        aria-label="outlined primary button group"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Button
                                            component="label"
                                            variant="outlined"
                                            className={classes.buttonLabel}
                                            color="primary"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            Upload
                                            <input
                                                onChange={(e) => {
                                                    uploadFile(
                                                        e.target.files[0],
                                                        'articles_of_incorporation'
                                                    );
                                                }}
                                                style={{ display: 'none' }}
                                                type="file"
                                                name="fileFront"
                                            />
                                        </Button>
                                    </ButtonGroup>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        justifyContent: 'left',
                                        alignItems: 'left',
                                        marginBottom: 20,
                                    }}
                                >
                                    {' '}
                                    {articlesOfIncorporation !== '' ? (
                                        <Paper
                                            elevation={3}
                                            style={{
                                                borderRadius: 15,
                                                padding: 10,
                                            }}
                                        >
                                            <Typography
                                                component="p"
                                                variant="body2"
                                            >
                                                Uploaded file:
                                            </Typography>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    xs={9}
                                                    sm={9}
                                                    md={9}
                                                    justify="flex-start"
                                                >
                                                    <Typography
                                                        component="p"
                                                        variant="body1"
                                                    >
                                                        <b>
                                                            {
                                                                articlesOfIncorporation
                                                            }
                                                        </b>
                                                    </Typography>
                                                </Grid>
                                                <RemoveDocumentButton
                                                    onClick={() => {
                                                        cancelFile(
                                                            'articles_of_incorporation'
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        </Paper>
                                    ) : null}
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <InputLabel
                                        style={{
                                            color: '#666',
                                            fontSize: '1rem',
                                        }}
                                        htmlFor="shareholders_agreement"
                                    >
                                        2. Holders Agreement /<br /> Article of
                                        Association
                                        <HelpTooltip
                                            title={
                                                "A holders' agreement is an arrangement among a company's holders that describes how the company should be operated and outlines holders' rights and obligations"
                                            }
                                        />
                                    </InputLabel>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <ButtonGroup
                                        aria-label="outlined primary button group"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Button
                                            component="label"
                                            variant="outlined"
                                            className={classes.buttonLabel}
                                            color="primary"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            Upload
                                            <input
                                                onChange={(e) => {
                                                    uploadFile(
                                                        e.target.files[0],
                                                        'shareholders_agreement'
                                                    );
                                                }}
                                                style={{ display: 'none' }}
                                                type="file"
                                                name="fileFront"
                                            />
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        justifyContent: 'left',
                                        alignItems: 'left',
                                        marginBottom: 20,
                                    }}
                                >
                                    {' '}
                                    {shareholdersAgreement !== '' ? (
                                        <Paper
                                            elevation={3}
                                            style={{
                                                borderRadius: 15,
                                                padding: 10,
                                            }}
                                        >
                                            <Typography
                                                component="p"
                                                variant="body2"
                                            >
                                                Uploaded file:
                                            </Typography>

                                            <Grid container>
                                                <Grid
                                                    item
                                                    xs={9}
                                                    sm={9}
                                                    md={9}
                                                    justify="flex-start"
                                                >
                                                    <Typography
                                                        component="p"
                                                        variant="body1"
                                                    >
                                                        <b>
                                                            {
                                                                shareholdersAgreement
                                                            }
                                                        </b>
                                                    </Typography>
                                                </Grid>
                                                <RemoveDocumentButton
                                                    onClick={() => {
                                                        cancelFile(
                                                            'shareholders_agreement'
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        </Paper>
                                    ) : null}
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <InputLabel
                                        style={{
                                            color: '#666',
                                            fontSize: '1rem',
                                        }}
                                        htmlFor="amendments"
                                    >
                                        3. Amendments (if any)
                                    </InputLabel>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <ButtonGroup
                                        aria-label="outlined primary button group"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Button
                                            component="label"
                                            variant="outlined"
                                            className={classes.buttonLabel}
                                            color="primary"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            Upload
                                            <input
                                                onChange={(e) => {
                                                    uploadFile(
                                                        e.target.files[0],
                                                        'amendments'
                                                    );
                                                }}
                                                style={{ display: 'none' }}
                                                type="file"
                                                name="fileFront"
                                            />
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        justifyContent: 'left',
                                        alignItems: 'left',
                                        marginBottom: 20,
                                    }}
                                >
                                    {' '}
                                    {amendments !== '' ? (
                                        <Paper
                                            elevation={3}
                                            style={{
                                                borderRadius: 15,
                                                padding: 10,
                                            }}
                                        >
                                            <Typography
                                                component="p"
                                                variant="body2"
                                            >
                                                Uploaded file:
                                            </Typography>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    xs={9}
                                                    sm={9}
                                                    md={9}
                                                    justify="flex-start"
                                                >
                                                    <Typography
                                                        component="p"
                                                        variant="body1"
                                                    >
                                                        <b>{amendments}</b>
                                                    </Typography>
                                                </Grid>
                                                <RemoveDocumentButton
                                                    onClick={() => {
                                                        cancelFile(
                                                            'amendments'
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        </Paper>
                                    ) : null}
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <InputLabel
                                        style={{
                                            color: '#666',
                                            fontSize: '1rem',
                                        }}
                                        htmlFor="additional_documents"
                                    >
                                        4. Additional documents (if any)
                                    </InputLabel>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <ButtonGroup
                                        aria-label="outlined primary button group"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Button
                                            component="label"
                                            variant="outlined"
                                            className={classes.buttonLabel}
                                            color="primary"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            Upload
                                            <input
                                                onChange={(e) => {
                                                    uploadFile(
                                                        e.target.files[0],
                                                        'additional_documents'
                                                    );
                                                }}
                                                style={{ display: 'none' }}
                                                type="file"
                                                name="additional_documents"
                                            />
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        justifyContent: 'left',
                                        alignItems: 'left',
                                        marginBottom: 20,
                                    }}
                                >
                                    {' '}
                                    {additionalDocuments !== '' ? (
                                        <Paper
                                            elevation={3}
                                            style={{
                                                borderRadius: 15,
                                                padding: 10,
                                            }}
                                        >
                                            <Typography
                                                component="p"
                                                variant="body2"
                                            >
                                                Uploaded file:
                                            </Typography>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    xs={9}
                                                    sm={9}
                                                    md={9}
                                                    justify="flex-start"
                                                >
                                                    <Typography
                                                        component="p"
                                                        variant="body1"
                                                    >
                                                        <b>
                                                            {
                                                                additionalDocuments
                                                            }
                                                        </b>
                                                    </Typography>
                                                </Grid>
                                                <RemoveDocumentButton
                                                    onClick={() => {
                                                        cancelFile(
                                                            'additional_documents'
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        </Paper>
                                    ) : null}
                                </Grid>
                            </Grid>

                            <div className={classes.actions}>
                                <ButtonGroup
                                    aria-label="outlined primary button group"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        className={classes.secondaryButtonLabel}
                                        color="secondary"
                                        onClick={() => previousStep()}
                                        style={{ margin: 'inherit' }}
                                    >
                                        <ArrowBackIosIcon /> BACK
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        className={classes.buttonLabel}
                                        color="primary"
                                        style={{
                                            margin: 'auto',
                                        }}
                                        onClick={nextStep}
                                        disabled={
                                            additionalDocuments === '' &&
                                            amendments === '' &&
                                            shareholdersAgreement === '' &&
                                            articlesOfIncorporation === ''
                                        }
                                    >
                                        CONTINUE
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        className={classes.secondaryButtonLabel}
                                        color="secondary"
                                        onClick={nextStep}
                                        style={{ margin: 'inherit' }}
                                    >
                                        SKIP
                                        <DoubleArrowIcon />
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </Form>
                    </Container>
                </Paper>
            </Formik>
        </div>
    );
};

export default UploadDocuments;
