import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import authStyles from '../../authStyles';
import fundraiseOfferingStyles from '../../../../components/styles/fundraiseOfferingStyles';
import * as registry from '../../../../utilities/registry';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormikSelect from '../../../../components/Formik/FormikSelect';

import FormikDatePicker from '../../../../components/Formik/FormikDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { DateFormatter } from '../../../../utilities/formatters';
import Grid from '@mui/material/Grid';
import TextField from '../../../../components/TextField/TextField';
import AddIcon from '@mui/icons-material/Add';
import FundButtonGroup from '../FundButtonGroup';
const AssociatedPersons = (props) => {
    const classes = authStyles();
    const offeringClasses = fundraiseOfferingStyles();
    const [birthDate, setBirthDate] = useState(new Date());
    const [birthDateValues, setBirthDateValues] = useState(
        props.initialValues.birthDateValues.length > 0
            ? props.initialValues.birthDateValues
            : [new Date()]
    );
    const [countryValues, setCountryValues] = useState(
        props.initialValues.countryValues.length > 0
            ? props.initialValues.countryValues
            : []
    );
    const [associatedPersonsNumber, setAssociatedPersonsNumber] = useState(
        props.initialValues.associated_persons.length > 1
            ? props.initialValues.associated_persons.length
            : 1
    );

    const [personTypes, setPersonTypes] = useState(
        props.initialValues.aPersonTypes.length > 0
            ? props.initialValues.aPersonTypes
            : ['PERSON']
    );

    const [personTitles, setPersonTitles] = useState(
        props.initialValues.aPersonTitles.length > 0
            ? props.initialValues.aPersonTitles
            : ['COMPANY']
    );
    const nextStep = () => {
        props.nextStep();
    };

    const previousStep = () => {
        props.previousStep();
    };

    const closeDialog = () => {
        props.closeDialog();
    };

    let associated_person_types = [
        { name: 'Person', value: 'PERSON' },
        { name: 'Company', value: 'COMPANY' },
        { name: 'Trust', value: 'TRUST' },
    ];

    let associated_person_titles = [
        { name: 'Executive', value: 'EXECUTIVE' },
        { name: 'Director', value: 'DIRECTOR' },
        { name: 'Holder', value: 'HOLDER' },
        { name: 'Affiliated', value: 'AFFILIATED' },
        { name: 'Promoter', value: 'PROMOTER' },
        { name: 'Solicitor', value: 'SOLICITOR' },
        { name: 'Manager', value: 'MANAGER' },
    ];

    const handleDateChange = (date, type, index) => {
        let newDate = DateFormatter({
            value: date,
            format: 'date',
        });

        setBirthDate(newDate);
        let birthDates = birthDateValues;
        birthDates[index] = newDate;
        setBirthDateValues(birthDates);
    };

    const handleCountryChange = (e, index) => {
        let countries = countryValues;
        countries[index] = e.target.getAttribute('data-value');
        setCountryValues(countries);
    };

    const handleTypeChange = (e, index) => {
        let types = personTypes;
        types[index] = e.target.value;
        setPersonTypes(types);
    };

    const handleTitleChange = (e, index) => {
        let titles = personTitles;
        titles[index] = e.target.value;
        setPersonTitles(titles);
    };

    const changeAssociatedPersonsNumber = (e) => {
        setAssociatedPersonsNumber(associatedPersonsNumber + 1);
        newAssociatedPerson();
    };

    const newAssociatedPerson = () => {
        let associated_persons = [];
        for (var i = 0; i < associatedPersonsNumber; i++) {
            let index = i;
            associated_persons.push(
                <div>
                    <h2>Associated Person {associatedPersonsNumber}</h2>
                    <hr className={offeringClasses.horizontalLine} />
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Field
                                component={FormikSelect}
                                name={'associated_person_type_' + i}
                                variant="outlined"
                                label="Type"
                                required
                                fullWidth
                                defaultValue={
                                    props.initialValues.aPersonTypes.length >=
                                    index + 1
                                        ? props.initialValues.aPersonTypes[
                                              index
                                          ]
                                        : 'PERSON'
                                }
                                onClick={(e, v) => handleTypeChange(e, index)}
                            >
                                {associated_person_types.map(
                                    (associated_person_type) => (
                                        <MenuItem
                                            key={associated_person_type.value}
                                            value={associated_person_type.value}
                                        >
                                            {associated_person_type.name}
                                        </MenuItem>
                                    )
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={6}>
                            <Field
                                component={FormikSelect}
                                name={'associated_person_title_' + i}
                                variant="outlined"
                                label="Title"
                                required
                                fullWidth
                                defaultValue={
                                    props.initialValues.aPersonTitles.length >=
                                    index + 1
                                        ? props.initialValues.aPersonTitles[
                                              index
                                          ]
                                        : 'EXECUTIVE'
                                }
                                onClick={(e, v) => handleTitleChange(e, index)}
                            >
                                {associated_person_titles.map(
                                    (associated_person_title) => (
                                        <MenuItem
                                            key={associated_person_title.value}
                                            value={
                                                associated_person_title.value
                                            }
                                        >
                                            {associated_person_title.name}
                                        </MenuItem>
                                    )
                                )}
                            </Field>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Field
                                component={TextField}
                                type="text"
                                label="Name"
                                name={'associated_person_name_' + i}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Field
                                component={FormikSelect}
                                name={'associated_person_country_' + i}
                                variant="outlined"
                                label="Country"
                                fullWidth
                                inputProps={{
                                    id: 'country',
                                }}
                                required
                                defaultValue={
                                    countryValues[i]
                                        ? countryValues[i]
                                        : registry.countries[0].name
                                }
                            >
                                {registry.countries.map((c) => (
                                    <MenuItem
                                        key={c.name}
                                        value={c.id}
                                        onClick={(e, v) =>
                                            handleCountryChange(e, index)
                                        }
                                    >
                                        {c.name}
                                    </MenuItem>
                                ))}
                            </Field>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Field
                                component={TextField}
                                type="text"
                                label="Social Security Number"
                                name={'social_security_number_' + i}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Field
                                    component={FormikDatePicker}
                                    name={'associated_person_birth_date_' + i}
                                    value={
                                        birthDateValues[i] !== undefined
                                            ? birthDateValues[i]
                                            : birthDate
                                    }
                                    label="Date of Birth"
                                    onChange={(date) =>
                                        handleDateChange(
                                            date,
                                            'associated_person_birth_date',
                                            index
                                        )
                                    }
                                    format="MM/dd/yyyy"
                                    variant="outlined"
                                    fullWidth
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <hr className={offeringClasses.horizontalLine} />
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Field
                                component={TextField}
                                type="text"
                                label="Street Address"
                                name={'associated_person_street_address_' + i}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Field
                                component={TextField}
                                type="text"
                                label="Apartment, Suite or Unit Number"
                                name={'associated_person_apartment_' + i}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                                numeric={1}
                            />{' '}
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <Field
                                component={TextField}
                                type="text"
                                label="City"
                                name={'associated_person_city_' + i}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Field
                                component={TextField}
                                type="text"
                                label="State"
                                name={'associated_person_state_' + i}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Field
                                component={TextField}
                                type="text"
                                label="Zip Code"
                                name={'associated_person_zip_code_' + i}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>
                    <hr className={offeringClasses.horizontalLine} />
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Field
                                component={TextField}
                                type="email"
                                label="Email"
                                name={'associated_person_email_' + i}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Field
                                component={TextField}
                                type="text"
                                label="Phone"
                                name={'associated_person_phone_number_' + i}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>
                </div>
            );
        }

        return associated_persons;
    };

    return (
        <div id="step3">
            <Formik
                initialValues={props.initialValues}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    values['birthDateValues'] = birthDateValues;
                    values['countryValues'] = countryValues;
                    values['aPersonTypes'] = personTypes;
                    values['aPersonTitles'] = personTitles;
                    values['associated_persons'] = [];
                    for (var i = 0; i < associatedPersonsNumber; i++) {
                        if (
                            values['associated_person_name_' + i] !== undefined
                        ) {
                            values['associated_persons'].push({
                                id: i,
                                associated_person_type: personTypes[i], //values['associated_person_type_' + i],
                                associated_person_name:
                                    values['associated_person_name_' + i],
                                associated_person_country: countryValues[i]
                                    ? countryValues[i]
                                    : null,
                                associated_person_birth_date: birthDateValues[i]
                                    ? birthDateValues[i]
                                    : null,
                                social_security_number:
                                    values['social_security_number_' + i],
                                associated_person_zip_code:
                                    values['associated_person_zip_code_' + i],
                                associated_person_state:
                                    values['associated_person_state_' + i],
                                associated_person_city:
                                    values['associated_person_city_' + i],
                                associated_person_apartment:
                                    values['associated_person_apartment_' + i],
                                associated_person_street_address:
                                    values[
                                        'associated_person_street_address_' + i
                                    ],
                                associated_person_email:
                                    values['associated_person_email_' + i],
                                associated_person_phone_number:
                                    values[
                                        'associated_person_phone_number_' + i
                                    ],
                                associated_person_title: personTitles[i], //values['associated_person_title_' + i],
                            });
                        }
                    }
                    for (i = 0; i < associatedPersonsNumber; i++) {
                        delete props.initialValues[
                            'associated_person_type_' + i
                        ];
                        delete props.initialValues[
                            'associated_person_name_' + i
                        ];
                        delete props.initialValues[
                            'associated_person_country_' + i
                        ];
                        delete props.initialValues[
                            'associated_person_birth_date_' + i
                        ];
                        delete props.initialValues[
                            'social_security_number_' + i
                        ];
                        delete props.initialValues[
                            'associated_person_zip_code_' + i
                        ];
                        delete props.initialValues[
                            'associated_person_state_' + i
                        ];
                        delete props.initialValues[
                            'associated_person_city_' + i
                        ];
                        delete props.initialValues[
                            'associated_person_apartment_' + i
                        ];
                        delete props.initialValues[
                            'associated_person_street_address_' + i
                        ];
                        delete props.initialValues[
                            'associated_person_email_' + i
                        ];
                        delete props.initialValues[
                            'associated_person_phone_number_' + i
                        ];
                        delete props.initialValues[
                            'associated_person_title_' + i
                        ];
                    }
                    props.saveValues(values);
                    nextStep();
                    props.changeKey();
                }}
            >
                {({ setFieldValue }) => (
                    <Container maxWidth="lg">
                        <Form className={classes.form} noValidate>
                            <Typography component="div">
                                <b>
                                    Per securities regulations and the Dodd
                                    Frank investor protection Act issuers and
                                    their associated persons can NOT raise
                                    capital by selling securities if anyone is
                                    deemed to be a “bad actor”.
                                </b>
                            </Typography>
                            <br />
                            <Typography component="div">
                                <b>
                                    Use the forms below to list all “covered
                                    persons” as required by the SEC. This
                                    includes every executive officer, director,
                                    and major holder (owner of 20%+ equity) in
                                    your company, as well as certain types of
                                    “promoters” and “solicitors”. This is for
                                    required AML and bad actor checks.
                                </b>
                            </Typography>
                            <br />
                            <Typography component="div">
                                <b>
                                    A $45.00 fee will be charged to run a bad
                                    actor check on each person.
                                </b>
                            </Typography>
                            <br />
                            {newAssociatedPerson()}

                            <Button
                                className="float-right"
                                color="primary"
                                variant="contained"
                                startIcon={<AddIcon />}
                                onClick={changeAssociatedPersonsNumber}
                            >
                                New
                            </Button>
                            <FundButtonGroup
                                nextStep={nextStep}
                                previousStep={previousStep}
                                closeDialog={closeDialog}
                            />
                        </Form>
                    </Container>
                )}
            </Formik>
        </div>
    );
};

export default AssociatedPersons;
