import React, { useState } from 'react';
import {
    Switch,
    Route,
    useRouteMatch,
    useLocation,
    Redirect,
} from 'react-router-dom';

import TabPanel from '../../components/TabPanel';
import SubMenu from '../../components/SubMenu';

import ListRoutes from './ListRoutes';
import TradeRoutes from './TradeRoutes';
import * as routes from './routeList';

const ListRoutesContainer = (props) => {
    let { path } = useRouteMatch();

    const location = useLocation();

    const getActiveTab = () => {
        let pathName = location.pathname;

        if (routes.LIST_ROUTES.includes(pathName)) {
            return 0;
        } else if (routes.TRADE_ROUTES.includes(pathName)) {
            return 1;
        } else {
            return 0;
        }
    };

    const tabs = [
        {
            label: 'List',
            path: `${path}/list`,
            display: true,
        },
        {
            label: 'Trade',
            path: `${path}/trade`,
            display: true,
        },
    ];

    const [value, setValue] = useState(getActiveTab());

    return (
        <div>
            <SubMenu
                value={value}
                onChange={(e, v) => setValue(v)}
                tabs={tabs}
            />
            <TabPanel>
                <Switch>
                    <Route exact path={`${path}/`}>
                        <Redirect to={`${path}/list`} />
                    </Route>
                    <Route path={`${path}/list`} component={ListRoutes} />
                    <Route path={`${path}/trade`} component={TradeRoutes} />
                </Switch>
            </TabPanel>
        </div>
    );
};

export default ListRoutesContainer;
