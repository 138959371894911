import useSWR from "swr"
import { API_BASE_URL } from "../../config"
import fetcher from "../../utilities/fetcher"

const useAmpCategories = () => {
    const { data, error, isValidating, mutate } = useSWR(`${API_BASE_URL}/blockchain/categories`, fetcher)

    return {
        ampCategories: data,
        ampCategoriesLoading: !error && !data,
        ampCategoriesValidating: isValidating,
        ampCategoriesError: error,
        updateAmpCategories: () => mutate()
    }
}

export default useAmpCategories