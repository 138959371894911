import * as subscriptionAgreementTypes from './subscriptionAgreementTypes';

const initialState = {
    data: null,
    loading: true,
};

const subscriptionAgreementReducer = (state = initialState, action) => {
    switch (action.type) {
        case subscriptionAgreementTypes.GET_SUBSCRIPTION_AGREEMENTS_SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case subscriptionAgreementTypes.SET_SUBSCRIPTION_AGREEMENTS_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        default:
            return state;
    }
};

export default subscriptionAgreementReducer;
