import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from '@mui/material'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';

import useAssetsByCategory from '../../../../api/blockchain/useAssetsByCategory';
import useAssetsOutsideCategory from '../../../../api/blockchain/useAssetsOutsideCategory';
import assignAssetToCategory from '../../../../api/blockchain/assignAssetToCategory';
import removeAssetFromCategory from '../../../../api/blockchain/removeAssetFromCategory';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import ComboBox from '../../../../components/Form/ComboBox';
import { snackbar } from '../../../../utilities/snackbarUtils'
import { errorParser } from '../../../../components/LiquidForm/utils/errorParser';

import BCTable from '../Utils/BCTable';
import DataLoading from '../Utils/DataLoading';
import NoData from '../Utils/NoData';
import DataError from '../Utils/DataError';
import SubmitButton from "../../../../components/Form/SubmitButton";

const AssignAssetDialog = ({ open, handleClose, updateAssets, category }) => {
    const schema = yup.object({
        asset_uuid: yup.string().required('Selecting an asset is required.').nullable()
    });

    const defaultValues = {
        asset_uuid: ''
    }

    const { handleSubmit, control, reset, formState: { isSubmitting, errors }, } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    const { assetsOutsideCategory, assetsOutsideCategoryLoading, updateAssetsOutsideCategory } = useAssetsOutsideCategory(category?.id)

    const onSubmit = async (data) => {
        await assignAssetToCategory(category?.id, data.asset_uuid).then(response => {
            snackbar.success('Succesfully whitelisted the asset.');
            reset(defaultValues);
            handleClose()
            updateAssets()
            updateAssetsOutsideCategory()
        }).catch((error) => {
            snackbar.error(errorParser(error));
        })
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Assign asset to whitelist</DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <DialogContentText>
                        <div style={{ display: 'flex', color: 'gray' }}>
                            <Typography variant='body2'>
                                Assign assets to the whitelist to
                                enable asset operations for holders within the same whitelist.
                            </Typography>
                        </div>
                        <br />
                        <ComboBox
                            options={assetsOutsideCategory}
                            optionLabel={'name'}
                            valueLabel={'asset_uuid'}
                            disabled={assetsOutsideCategoryLoading}
                            loading={assetsOutsideCategoryLoading}
                            label="Asset"
                            name="asset_uuid"
                            error={errors.asset_uuid}
                            control={control}
                            defaultValue={assetsOutsideCategory[0]}
                        />

                        <br />
                        <br />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <SubmitButton 
                        text='Assign'
                        disabled={isSubmitting}
                        loading={isSubmitting}
                    />
                </DialogActions>
            </form>
        </Dialog>
    )
}

const DeleteAssetDialog = ({ open, handleClose, updateAssets, category, asset }) => {
    const [loading, setLoading] = React.useState(false)

    const onSubmit = async () => {
        setLoading(true)
        await removeAssetFromCategory(category?.id, asset?.asset_uuid).then(response => {
            snackbar.success('Succesfully removed the asset from the whitelist.');
            handleClose()
            updateAssets()
        }).catch((error) => {
            snackbar.error(errorParser(error));
        }).finally(() => {
            setLoading(false)
        })
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Remove asset whitelisting</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div style={{ display: 'flex', color: 'gray' }}>
                        <Typography variant='body2'>
                            Are you sure you want to remove <b>{asset?.name}</b> from the <b>"{category?.name}"</b> whitelist?
                        </Typography>

                    </div>
                    <br />
                    <br />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <SubmitButton 
                    text='Remove'
                    color='danger'
                    disabled={loading}
                    loading={loading}
                    onClick={onSubmit}
                />
            </DialogActions>
        </Dialog>
    )
}

const WhitelistAssetsDialog = ({ category, open, handleClose }) => {
    const [openAssignAsset, setOpenAssignAsset] = React.useState(false);
    const [openRemoveAsset, setOpenRemoveAsset] = React.useState(false);
    const [activeAsset, setActiveAsset] = React.useState(false);

    const handleOpenAssignAsset = (asset) => {
        setOpenAssignAsset(true);
        setActiveAsset(asset)
    };

    const handleCloseAssignAsset = () => {
        setOpenAssignAsset(false);
        setActiveAsset(null)
    };

    const handleOpenRemoveAsset = (asset) => {
        setOpenRemoveAsset(true);
        setActiveAsset(asset)
    };

    const handleCloseRemoveAsset = () => {
        setOpenRemoveAsset(false);
        setActiveAsset(null)
    };

    const { assetsByCategory, assetsByCategoryError, assetsByCategoryLoading, updateAssetsByCategory } = useAssetsByCategory(category?.id)

    return (
        <Dialog open={open} onClose={handleClose} maxWidth='lg'>
            <DialogTitle>Asset whitelisting</DialogTitle>
            <DialogContent>
                <Stack direction="row" alignItems="center" justifyContent='space-between' gap={12}>
                    <div>
                        <Typography variant='body1' as='h5'>
                            {category?.name}
                        </Typography>
                        <Typography variant='caption' as='p' sx={{ color: 'gray' }}>
                            {category?.description}
                        </Typography>
                    </div>
                    <div>
                        <Button variant="contained" size='small' startIcon={<AddIcon />}
                            onClick={handleOpenAssignAsset}
                        >
                            Add Asset
                        </Button>
                    </div>
                </Stack>
                <br />
                {/* AMP category data loading */}
                <DataLoading 
                    loading={assetsByCategoryLoading}
                    text='Loading your whitelisted assets...'
                />

                {/* Wallet data loaded */}
                {
                    (!assetsByCategoryLoading && assetsByCategory) &&
                    <BCTable
                        columns={[
                            { name: 'name', label: 'Asset', bold: true },
                            {
                                name: 'actions', label: 'Actions', type: 'actions', render: (row) => (
                                    <Button variant="contained" color='danger' size="small" startIcon={<DeleteForeverIcon />}
                                        onClick={() => handleOpenRemoveAsset(row)}
                                    >
                                        Remove
                                    </Button>
                                )
                            },
                        ]}
                        data={assetsByCategory}
                    />
                }
                <br />
                {/* No data */}
                <NoData 
                    show={!assetsByCategoryLoading && !assetsByCategoryError && assetsByCategory.length === 0}
                    text='This whitelist has no assets in it.'
                />

                {/* category error */}
                <DataError 
                    show={assetsByCategoryError && !assetsByCategory}
                    text='Could not load assets due to error. Please try again.'
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>

            {
                openAssignAsset &&
                <AssignAssetDialog open={openAssignAsset} handleClose={handleCloseAssignAsset} category={category} updateAssets={updateAssetsByCategory} />
            }
            {
                openRemoveAsset &&
                <DeleteAssetDialog open={openRemoveAsset} handleClose={handleCloseRemoveAsset} category={category} asset={activeAsset} updateAssets={updateAssetsByCategory} />
            }
        </Dialog>
    )
}

export default WhitelistAssetsDialog