import React, { useState } from 'react';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';

import TabPanel from '../../components/TabPanel';
import SubMenu from '../../components/SubMenu';
import Overview from '../../pages/issuer/Investors/Overview';
import Communications from '../../pages/issuer/Investors/Communications';
import Invite from '../../pages/issuer/Investors/Invite';
import UploadDocuments from '../../pages/issuer/Investors/UploadDocuments';
import KycPage from '../../pages/issuer/Investors/KycPage';
import Distributions from '../../pages/issuer/Investors/Distributions';

import VerticalContainer from '../../components/VerticalContainer';
import { INVESTORS_ROUTES } from './routeList';

const InvestorRoutes = (props) => {
    let { path } = useRouteMatch();
    const location = useLocation();

    const getActiveTab = () => {
        let index = INVESTORS_ROUTES.indexOf(location.pathname);
        return index >= 0 ? index : 0;
    };

    const tabs = [
        {
            label: 'Overview',
            path: `${path}`,
            display: true,
        },
        {
            label: 'Events',
            path: `${path}/events`,
            display: true,
        },
        {
            label: 'KYC',
            path: `${path}/kyc`,
            display: true,
        },
        {
            label: 'Documents',
            path: `${path}/documents`,
            display: true,
        },
        {
            label: 'Distributions',
            path: `${path}/distributions`,
            display: true,
        },

        {
            label: 'Invite',
            path: `${path}/invite`,
            display: true,
        },
    ];

    const [value, setValue] = useState(getActiveTab());
    return (
        <VerticalContainer>
            <SubMenu
                orientation="vertical"
                value={value}
                onChange={(e, v) => setValue(v)}
                tabs={tabs}
                title="Holders"
            />
            <TabPanel>
                <Switch>
                    <Route exact path={`${path}/`} component={Overview} />
                    <Route
                        exact
                        path={`${path}/events`}
                        component={Communications}
                    />
                    <Route exact path={`${path}/kyc`} component={KycPage} />
                    <Route
                        exact
                        path={`${path}/documents`}
                        component={UploadDocuments}
                    />
                    <Route
                        exact
                        path={`${path}/distributions`}
                        component={Distributions}
                    />
                    {/*<Route exact path={`${path}/wallets`} component={Wallets} />*/}
                    <Route exact path={`${path}/invite`} component={Invite} />
                    {/* <Route
                        exact
                        path={`${path}/investor-view`}
                        component={InvestorView}
                    /> */}
                </Switch>
            </TabPanel>
        </VerticalContainer>
    );
};

export default InvestorRoutes;
