import React, { useEffect } from 'react';
import DataTable from '../../../components/DataTable/MuiDataTable';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as offeringActions from '../../../store/interimOffering/offeringActions';
import treeBRColumns from '../../../utilities/treeBRColumns';
const TreeBROfferings = (props) => {
    useEffect(props.offeringActions.getOfferings, []);

    return (
        <div>
            {props.offerings.loading ? (
                <Grid container item xs={12} justifyContent="center">
                    <CircularProgress />
                </Grid>
            ) : (
                <DataTable
                    title={'Registered Interest'}
                    data={
                        props.offerings.data &&
                        props.offerings.data.data.length > 0
                            ? props.offerings.data.data
                            : []
                    }
                    columns={treeBRColumns}
                    shareSort={2}
                />
            )}
        </div>
    );
};

const mapStateToProps = ({ offerings }) => {
    return {
        offerings: offerings,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        offeringActions: bindActionCreators(offeringActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TreeBROfferings);

//export default TreeBROfferings;
