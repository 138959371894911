import * as sponsorAdvisorUpdateTypes from './sponsorAdvisorUpdateTypes';

const initialState = {
    data: null,
    loading: true,
};

const sponsorAdvisorUpdateReducer = (state = initialState, action) => {
    switch (action.type) {
        case sponsorAdvisorUpdateTypes.GET_SPONSOR_ADVISOR_UPDATES_SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case sponsorAdvisorUpdateTypes.SET_SPONSOR_ADVISOR_UPDATES_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};

export default sponsorAdvisorUpdateReducer;
