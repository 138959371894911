import useSWR from "swr"
import { API_BASE_URL } from "../../config"
import fetcher from "../../utilities/fetcher"

const useAssetOwners = (assetId) => {
    const { data, error, isValidating } = useSWR(() => assetId ? `${API_BASE_URL}/blockchain/assetOwners/${assetId}` : null, fetcher)

    return {
        assetOwners: data || [],
        assetOwnersLoading: !error && !data,
        assetOwnersError: error,
        assetOwnersValidating: isValidating,
    }
}

export default useAssetOwners