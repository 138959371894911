import useSWR from "swr"
import { API_BASE_URL } from "../../config"
import fetcher from "../../utilities/fetcher"

const useHolderAddresses = (holderId) => {
    const { data, error, mutate } = useSWR(() => holderId ? `${API_BASE_URL}/blockchain/holder/${holderId}/addresses` : null, fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    })
    
    return {
        holderAddresses: data || [],
        holderAddressesLoading: !error && !data,
        holderAddressesError: error,
        updateHolderAddresses: () => mutate()
    }
}

export default useHolderAddresses