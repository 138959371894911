const headerStyles = (theme) => ({
    bar: {
        backgroundColor: '#F6F6F6',
        color: '#333333',
        boxShadow: 'none',
        // paddingLeft: '3rem',
        // paddingRight: '3rem',
    },
    logo: {
        height: '2rem',
        // marginTop: 20,
    },
    navigation: {
        flexGrow: 1,
        padding: '1.25rem',
    },
    profile: {
        display: 'flex',
        alignItems: 'center',
        '& $link': {
            marginRight: '0',
            marginLeft: '0.55rem',
        },
    },
    link: {
        fontSize: '1rem',
        fontWeight: 500,
        color: 'black',
        textDecoration: 'none',
        transition: 'ease-in 0.2s',
        '&:hover': {
            color: '#40aeff',
        },
        display: 'inline-flex',
        cursor: 'pointer',
        margin: '0.15rem 1rem 0 1rem',
    },
    static: {
        fontSize: '0.95rem',
        marginTop: '0.15rem',
    },
    title: {
        fontSize: '1.5rem',
        fontWeight: '700',
        color: 'black',
    },
    button: {
        color: 'inherit',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
    },
    item: {
        marginLeft: '0.75rem',
        marginRight: '0.5rem',
    },
    spacing: {
        marginTop: '0.75rem',
    },
    placeholder: {
        height: '2px',
        backgroundColor: '#fff',
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color: '#3E9CD9 !important',
    },
    taNote: {
        fontSize: '0.95rem',
        marginTop: '0.15rem',
        color: '#3e9cd9',
        fontWeight: 'bold',
        marginRight: '2rem',
    },
});

export default headerStyles;
