import useSWR from "swr"
import { API_BASE_URL } from "../../config"
import fetcher from "../../utilities/fetcher"

const useWalletAssets = (walletName) => {
    const { data, error } = useSWR(() => walletName ? `${API_BASE_URL}/blockchain/walletAssets/${walletName}` : null, fetcher)

    return {
        walletAssets: data || [],
        walletAssetsLoading: !error && !data,
        walletAssetsError: error,
    }
}

export default useWalletAssets