import * as documentTypes from './documentTypes'

const initialState = {
    data: null,
    loading: true
}

const documentReducer = (state = initialState, action) => {
    switch (action.type) {
        case documentTypes.GET_DOCUMENTS_SUCCESS:
            return {
                ...state,
                data: action.payload 
            }
        case documentTypes.SET_DOCUMENTS_LOADING:
            return {
                ...state,
                loading: action.payload 
            }
        default:
            return state
    }
}

export default documentReducer