import React from 'react';
import { Grid, Button } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import * as Yup from 'yup';

import TextField from '../../../TextField/TextField';
import { greenWallet } from '../../api';
import FormikAutocomplete from '../../../FormikAutocomplete';

const validationSchema = Yup.object({
    pricing: Yup.object().shape({
        currency: Yup.string().required('Please select Currency.'),
        exchange: Yup.string().required('Please select Exchange.'),
    }),
});

const Settings = ({ currencies, settings, wallet }) => {
    const getOptions = (field, selectedCurrency) => {
        let options = [];
        if (field === 'currency') {
            options = currencies?.map((item) => ({
                value: item.currency,
                name: item.currency,
            }));
        }
        if (field === 'exchange') {
            const filtered = currencies?.find(
                (item) => item.currency === selectedCurrency
            );
            filtered?.exchange?.map((item) =>
                options.push({ value: item, name: item })
            );
        }
        return options;
    };

    return (
        <Formik
            initialValues={settings}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                await greenWallet.saveSettings({
                    ...values,
                    wallet: wallet.name,
                    pin: wallet.pin,
                });
                setSubmitting(false);
            }}
        >
            {({ submitForm, isSubmitting, values, initialValues }) => (
                <Form>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justify="center"
                    >
                        <Grid item xs={12} md={6}>
                            <Field
                                component={FormikAutocomplete}
                                name="pricing.currency"
                                label="Currency"
                                options={getOptions('currency')}
                                optionKey="name"
                                defaultValue={{
                                    value: initialValues?.pricing?.currency,
                                    name: initialValues?.pricing?.currency,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={FormikAutocomplete}
                                name="pricing.exchange"
                                label="Exchange"
                                options={getOptions(
                                    'exchange',
                                    values?.pricing?.currency
                                )}
                                dependsOn={values.pricing?.currency}
                                disabled={!values.pricing?.currency}
                                defaultValue={{
                                    value: initialValues?.pricing?.exchange,
                                    name: initialValues?.pricing?.exchange,
                                }}
                                optionKey="name"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={TextField}
                                name="csvtime"
                                label="CSV Time"
                                type="number"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={TextField}
                                type="number"
                                variant="outlined"
                                margin="normal"
                                name="altimeout"
                                label="Al Timeout"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={TextField}
                                type="number"
                                variant="outlined"
                                margin="normal"
                                name="nlocktime"
                                label="N Lock Time"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={TextField}
                                type="number"
                                variant="outlined"
                                margin="normal"
                                name="requiredNumBlocks"
                                label="Required Num Blocks"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={TextField}
                                type="text"
                                variant="outlined"
                                margin="normal"
                                name="unit"
                                label="Unit"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={TextField}
                                type="text"
                                variant="outlined"
                                margin="normal"
                                name="pgp"
                                label="PGP"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={CheckboxWithLabel}
                                type="checkbox"
                                name="notifications.emailIncoming"
                                Label={{
                                    label: 'Email Incoming',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={CheckboxWithLabel}
                                type="checkbox"
                                name="notifications.emailOutgoing"
                                Label={{
                                    label: 'Email Outgoing',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                component={CheckboxWithLabel}
                                type="checkbox"
                                name="sound"
                                Label={{
                                    label: 'Sound',
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={submitForm}
                                disabled={isSubmitting}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default Settings;
