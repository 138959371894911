import React from 'react';
// import TableRowExtended from './TableRowExtended';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    MenuItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MenuWithContext from '../../MenuWithContext';
import DefaultTableCell from './TableCells/DefaultTableCell';
import TruncatedTableCell from './TableCells/Truncated';

const useStyles = makeStyles((theme) => ({
    actionCell: {
        width: 24,
        paddingLeft: 8,
        paddingRight: 8,
    },
    headerCell: {
        whiteSpace: 'nowrap',
    },
    tableRow: {
        '& .inlineActionsHoverOnly': {
            opacity: 0,
            transition: 'opacity 0.2s ease-in-out',
        },
        '&:hover': {
            '& .inlineActionsHoverOnly': {
                opacity: 1,
            },
        },
        '&.disabled': {
            background: '#F8F8F8',
            '& .MuiTableCell-body': {
                color: '#A4A4A4',
            },
        },
    },
}));

const TableComponent = (props) => {
    const classes = useStyles();
    const {
        columns,
        items,
        onRowClick,
        headAlign,
        // bodyAlign,
        // onHoverOptions,
        threeDotsActions,
    } = props;

    const preventClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const renderCell = (item, col, i) => {
        switch (col.cellType) {
            case 'truncated':
                return <TruncatedTableCell key={i} col={col} item={item} />;
            default:
                return <DefaultTableCell key={i} col={col} item={item} />;
        }
    };

    const renderThreeDotsAction = (item) => {
        return (
            <TableCell onClick={preventClick} className={classes.actionCell}>
                <Box display="flex">
                    <MenuWithContext
                        title="More Actions"
                        disabled={item.isDisabled}
                    >
                        {threeDotsActions
                            .filter((e) =>
                                item.threeDotsActions
                                    ? item.threeDotsActions.includes(e.label)
                                    : true
                            )
                            .filter(
                                (action) =>
                                    !action.hidden ||
                                    !action.hidden(item.id, item)
                            )
                            .map((action, i) => (
                                !(action.disableOn && item[action.disableOn]) && <MenuItem
                                    key={`${i}-${item.label}`}
                                    onClick={() =>
                                        action.handler(item.id, item)
                                    }
                                >
                                    {action.label}
                                </MenuItem>
                            ))}
                    </MenuWithContext>
                </Box>
            </TableCell>
        );
    };
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((item) => (
                            <TableCell
                                key={item.value}
                                align={headAlign}
                                className={classes.headerCell}
                            >
                                {item.headerName}
                            </TableCell>
                        ))}
                        {threeDotsActions && <TableCell />}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items?.map((item) => (
                        <>
                            <TableRow
                                key={item.id}
                                hover={!!onRowClick}
                                onClick={() =>
                                    onRowClick && onRowClick(item.id, item)
                                }
                                className={classes.tableRow}
                            >
                                {columns.map((col, i) =>
                                    renderCell(item, col, i)
                                )}
                                {threeDotsActions &&
                                    renderThreeDotsAction(item)}
                            </TableRow>
                        </>
                    ))}
                    {/* {items &&
                        items.map((item) => (
                            <TableRowExtended
                                item={item}
                                onRowClick={onRowClick}
                                bodyAlign={bodyAlign}
                                columns={columns}
                                onHoverOptions={onHoverOptions}
                            />
                        ))} */}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableComponent;
