import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Container from '@mui/material/Container';
import TextField from '../../../../components/TextField/TextField';
import authStyles from '../../authStyles';
import fundraiseOfferingStyles from '../../../../components/styles/fundraiseOfferingStyles';
import MenuItem from '@mui/material/MenuItem';
import FormikSelect from '../../../../components/Formik/FormikSelect';
import Grid from '@mui/material/Grid';

import FormikDatePicker from '../../../../components/Formik/FormikDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { DateFormatter } from '../../../../utilities/formatters';
import FundButtonGroup from '../FundButtonGroup';
const validationSchema = Yup.object({
    investor_name: Yup.string().required('Please enter investor name'),
    social_security_number: Yup.string().required(
        'Please enter social security number'
    ),
    street_address: Yup.string().required('Please enter street address'),
    apartment: Yup.string().required('Please enter apartment'),
    city: Yup.string().required('Please enter city'),
    state: Yup.string().required('Please enter state'),
    zip_code: Yup.string().required('Please enter zip code'),
    email: Yup.string()
        .email('Please enter a valid email address')
        .required('Please enter your email address'),
    phone_number: Yup.string().required('Please enter phone number'),
    invest_amount: Yup.string().required('Please enter invest amount'),
});
const InvestorVesting = (props) => {
    const classes = authStyles();
    const offeringClasses = fundraiseOfferingStyles();
    const [birthDate, setBirthDate] = useState(
        props.initialValues.birthDate
            ? props.initialValues.birthDate
            : new Date()
    );

    const [investorType, setInvestorType] = useState(
        props.initialValues.investor_type
            ? props.initialValues.investor_type
            : 'INDIVIDUAL'
    );

    const [isJointAccount, setIsJointAccount] = useState(
        props.initialValues.is_joint_account
            ? props.initialValues.is_joint_account === true
                ? 'yes'
                : 'no'
            : 'no'
    );

    const [paymentMethod, setPaymentMethod] = useState(
        props.initialValues.payment_method
            ? props.initialValues.payment_method
            : 'ACH'
    );

    let investor_types = [
        { name: 'Individual', value: 'INDIVIDUAL' },
        { name: 'Company', value: 'COMPANY' },
        { name: 'Trust', value: 'TRUST' },
        { name: 'Self-Directed IRA', value: 'SELF-DIRECTED IRA' },
    ];

    let payment_methods = [
        { name: 'ACH (easiest)', value: 'ACH' },
        { name: 'Wire', value: 'WIRE' },
        { name: 'Check', value: 'CHECK' },
    ];

    const nextStep = () => {
        props.nextStep();
    };

    const closeDialog = () => {
        props.closeDialog();
    };

    const handleDateChange = (date, type) => {
        let newDate = DateFormatter({
            value: date,
            format: 'date',
        });

        setBirthDate(newDate);
    };

    const changeInvestorType = (e) => {
        setInvestorType(e.target.value);
    };

    const changePaymentMethod = (e) => {
        setPaymentMethod(e.target.value);
    };

    const changeIsJointAccount = (e) => {
        setIsJointAccount(e.target.value);
    };

    return (
        <div id="step1">
            <Formik
                initialValues={props.initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    values['investor_type'] = investorType;
                    values['date_of_birth'] = birthDate;
                    values['payment_method'] = paymentMethod;

                    if (isJointAccount === 'yes') {
                        values['is_joint_account'] = true;
                    } else {
                        values['is_joint_account'] = false;
                    }
                    props.saveValues(values);
                    nextStep();
                    props.changeKey();
                }}
            >
                {({ setFieldValue }) => (
                    <Container maxWidth="lg">
                        <Form className={classes.form} noValidate>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Name"
                                        name="investor_name"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <Field
                                        component={FormikSelect}
                                        name="investor_type"
                                        label="I wish to invest as:"
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{
                                            id: 'investor_type',
                                        }}
                                        required
                                        defaultValue={investorType}
                                        onClick={changeInvestorType}
                                    >
                                        {investor_types.map((investor_type) => (
                                            <MenuItem
                                                key={investor_type.name}
                                                value={investor_type.value}
                                            >
                                                {investor_type.name}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </Grid>
                            </Grid>

                            <hr className={offeringClasses.horizontalLine} />
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Social Security Number"
                                        name={'social_security_number'}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Field
                                            component={FormikDatePicker}
                                            name={'birth_date'}
                                            value={birthDate}
                                            label="Date of Birth"
                                            onChange={(date) =>
                                                handleDateChange(
                                                    date,
                                                    'birth_date'
                                                )
                                            }
                                            format="MM/dd/yyyy"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                            <hr className={offeringClasses.horizontalLine} />
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Street Address"
                                        name="street_address"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Apartment, Suite or Unit Number"
                                        name="apartment"
                                        variant="outlined"
                                        margin="normal"
                                        numeric={1}
                                        fullWidth
                                        required
                                    />{' '}
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="City"
                                        name="city"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="State"
                                        name="state"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Zip Code"
                                        name="zip_code"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <hr className={offeringClasses.horizontalLine} />

                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="email"
                                        label="Email"
                                        name="email"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Phone"
                                        name="phone_number"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <hr className={offeringClasses.horizontalLine} />
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Amount"
                                        name="invest_amount"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        numeric={1}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={FormikSelect}
                                        name="is_joint_account"
                                        variant="outlined"
                                        label="Is this a joint account?"
                                        required
                                        fullWidth
                                        defaultValue={isJointAccount}
                                        onClick={changeIsJointAccount}
                                    >
                                        <MenuItem key={'no'} value={false}>
                                            NO
                                        </MenuItem>
                                        <MenuItem key={'yes'} value={true}>
                                            YES
                                        </MenuItem>
                                    </Field>
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={FormikSelect}
                                        name="payment_method"
                                        label="Payment Method"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        defaultValue={paymentMethod}
                                        onClick={changePaymentMethod}
                                    >
                                        {payment_methods.map(
                                            (payment_method) => (
                                                <MenuItem
                                                    key={payment_method.name}
                                                    value={payment_method.value}
                                                >
                                                    {payment_method.name}
                                                </MenuItem>
                                            )
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>
                            <hr className={offeringClasses.horizontalLine} />
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        name="financial_advisor"
                                        variant="outlined"
                                        margin="normal"
                                        label="My Financial Advisor"
                                        fullWidth
                                        placeholder={'optional'}
                                    />
                                </Grid>
                            </Grid>

                            <FundButtonGroup
                                closeDialog={closeDialog}
                                first={true}
                            />
                        </Form>
                    </Container>
                )}
            </Formik>
        </div>
    );
};

export default InvestorVesting;
