import React from "react";

import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material'

import ViewModuleIcon from '@mui/icons-material/ViewModule';

import BCTable from '../Utils/BCTable';
import moment from "moment";
import useAmpAssets from "../../../../api/blockchain/useAmpAssets";
import useHolderBurnTransactions from "../../../../api/blockchain/useHolderBurnTransactions";
import DataLoading from "../Utils/DataLoading";
import NoData from "../Utils/NoData";
import DataError from "../Utils/DataError";

/**
 * 
 * A component that displays a dialog (modal) with the burn transactions of a holder.
 * The authenticated user needs to select of the assets the holder is whitelisted
 * so it can list the asset's burn transactions.
 * 
 * @param {boolean} open The flag that indicates whether the dialog is open or not
 * @param {()=>void} handleClose The handler function to close the dialog
 * @param {*} user The holder
 * @returns the view for the dialog
 */
const HolderBurnTransactionsDialog = ({ open, handleClose, user }) => {
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');

    const { ampAssets, ampAssetsLoading } = useAmpAssets()
    const { holderBurnTransactions, holderBurnTransactionsLoading, holderBurnTransactionsError } = useHolderBurnTransactions(user?.id, value?.assetUuid)

    return (
        <Dialog open={open} onClose={handleClose} maxWidth='lg'>
            <DialogTitle>Holder's burn transactions</DialogTitle>
            <DialogContent>
                <Stack direction="row" alignItems="center" justifyContent='space-between' gap={12}>
                    <div>
                        <Typography variant='body1' as='h5'>
                            {user?.holder}
                        </Typography>
                        <Typography variant='caption' as='p' sx={{ color: 'gray' }}>
                            Holder since {moment(user?.created_at).format("MMM DD, YYYY")}
                            {
                                user?.registered_at &&
                                <><br />Registered since {moment(user?.registered_at).format("MMM DD, YYYY")}</>
                            }
                        </Typography>
                    </div>
                </Stack>
                <br />
                <Typography variant='body1' as='h5'>
                    Burn Transactions
                </Typography>
                <div style={{ display: 'flex', color: 'gray' }}>
                    <Typography variant='body2'>
                        View and filter all holder's burn transactions
                    </Typography>
                </div>
                <br />
                <Autocomplete
                    id="combo-box-demo"
                    options={ampAssets}
                    getOptionLabel={(option) => `${option['name']} (${option['ticker']})`}
                    fullWidth
                    loading={ampAssetsLoading}
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    sx={{ marginTop: '10px;', marginBottom: '20px', float: 'right' }}
                    renderInput={(params) => <TextField {...params} label="Assets" placeholder="Select an asset to see transactions" />}
                />
                {
                    value && <>
                        <DataLoading
                            loading={holderBurnTransactionsLoading}
                            text="Loading holder's burn transactions for the asset..."
                        />
                        {
                            (!holderBurnTransactionsLoading && holderBurnTransactions) &&
                            <BCTable
                                sx={{ marginTop: '10px' }}
                                columns={[
                                    { name: 'description', label: 'Description', bold: true, extraInfoColumn: 'extra' },
                                    { name: 'parsedAmount', label: 'Amount', type: 'amount' },
                                    { name: 'created_at', label: 'Date', type: 'date', format: 'MMM DD, YYYY' },
                                    {
                                        name: 'actions', label: 'Actions', type: 'actions', render: (row) => (
                                            <>
                                                <IconButton variant="outlined" size="small"
                                                    sx={{ marginRight: '10px;' }}
                                                    onClick={() => window.open(
                                                        row.unblinded_url,
                                                        '_blank'
                                                    )}
                                                >
                                                    <Tooltip title="View transaction on-chain">
                                                        <ViewModuleIcon />
                                                    </Tooltip>
                                                </IconButton>

                                                { row.burn_unblinded_url && 
                                                    <IconButton variant="outlined" size="small"
                                                        sx={{ marginRight: '10px;' }}
                                                        onClick={() => window.open(
                                                            row.burn_unblinded_url,
                                                            '_blank'
                                                        )}
                                                    >
                                                        <Tooltip title="View burned transaction on-chain">
                                                            <ViewModuleIcon />
                                                        </Tooltip>
                                                    </IconButton>
                                                }
                                            </>
                                        )
                                    }
                                ]}
                                data={holderBurnTransactions}
                            />
                        }
                        <br />
                        {/* No data */}
                        <NoData
                            show={!holderBurnTransactionsLoading && !holderBurnTransactionsError && holderBurnTransactions.length === 0}
                            text='There are not any burn transactions of this asset.'
                        />

                        {/* AMP asset error */}
                        <DataError
                            show={holderBurnTransactionsError && (!holderBurnTransactions || holderBurnTransactions.length === 0)}
                            text="Could not load the holder's burn transactions due to error. Please try again."
                        />
                    </>
                }
                {
                    !value &&
                    <Typography>
                        Select an asset to see the transactions.
                    </Typography>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog >
    )
}

export default HolderBurnTransactionsDialog