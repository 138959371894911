import React, { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';

import SearchIcon from '@mui/icons-material/Search';

import Paper from '../../../components/StyledPaper';
import CircularLoader from '../../../components/CircularLoader';
import LoadingOverlay from '../../../components/LoadingOverlay';
import Pagination from '../../../components/Pagination';
import { DateFormatter } from '../../../utilities/formatters';
/* Redux */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as transferAgentActions from '../../../store/transferAgent/transferAgentActions';

import Humanize from 'humanize-plus';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import TextField from '../../../components/TextField/TextField';

const TransferAgentIssuers = (props) => {
    const { issuers, transferAgentActions } = props;

    const history = useHistory();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [disabledButton, setDisabledButton] = useState(-1);
    const [search, setSearch] = useState('');

    const [documentsDialog, setDocumentsDialog] = useState(false);

    /* Load the issuers */
    useEffect(transferAgentActions.getIssuers, []);

    /* Log in as issuer */
    const loginAs = (email, dButton) => {
        setDisabledButton(dButton);
        transferAgentActions.loginAs(
            email,
            closeSnackbar,
            enqueueSnackbar,
            history
        );
    };

    const updateSearch = (text) => {
        setSearch(text);
    };

    const handleDebouncedSearch = debounce(updateSearch, 500);

    const handleSearch = (e) => {
        handleDebouncedSearch(e.target.value);
    };

    const displayDocumentsDialog = (id) => {
        transferAgentActions.listIssuerDocuments(id);

        setDocumentsDialog(true);
    };

    const closeDocumentsDialog = () => {
        setDocumentsDialog(false);
    };

    const viewDocument = (file_path) => {
        if (file_path !== null && file_path !== undefined) {
            window.open(file_path);
        } else {
            enqueueSnackbar('File has not been uploaded', {
                variant: 'error',
            });
        }
    };

    return (
        <div>
            <Grid container spacing={4}>
                {/* Search for an issuer */}
                <Grid item xs={12} md={6} lg={3}>
                    <TextField
                        name="search"
                        label="Search"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        onChange={handleSearch}
                        placeholder="Search for an issuer..."
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <FormHelperText>
                        Search based on name, email or company name
                    </FormHelperText>
                    <br />
                </Grid>
            </Grid>

            {issuers.loading && !issuers.data ? (
                <CircularLoader /> // initial data load
            ) : issuers.data && issuers.data.length ? ( // has data from the backend
                <LoadingOverlay active={issuers.loading}>
                    <Grid container spacing={4}>
                        {issuers.data.map((issuer, id) => (
                            <Grid key={id} item xs={12} sm={6} md={4} lg={3}>
                                <Paper zoom={true} padding={10}>
                                    <Typography variant="h6">
                                        {issuer.name}
                                    </Typography>
                                    <Typography
                                        className="muted"
                                        variant="body1"
                                    >
                                        {issuer.company_name}
                                    </Typography>
                                    <Typography
                                        className="muted"
                                        variant="body2"
                                    >
                                        {issuer.email}
                                    </Typography>
                                    <Typography
                                        className="muted"
                                        variant="body2"
                                    >
                                        Member since: {issuer.created_at}
                                    </Typography>
                                    <br />

                                    <Typography variant="body2">
                                        Number of holders:{' '}
                                        <b>{issuer.investors}</b>
                                    </Typography>
                                    <Typography variant="body2">
                                        Total authorized assets:{' '}
                                        <b>
                                            {Humanize.formatNumber(
                                                issuer.authorized_shares
                                            )}
                                        </b>
                                    </Typography>
                                    <Typography variant="body2">
                                        Total outstanding assets:{' '}
                                        <b>
                                            {Humanize.formatNumber(
                                                issuer.shares
                                            )}
                                        </b>
                                    </Typography>
                                    <br />
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        size={'sm'}
                                        onClick={() =>
                                            displayDocumentsDialog(issuer.id)
                                        }
                                        fullWidth
                                    >
                                        View Documents
                                    </Button>
                                    <br />
                                    {/* <Typography variant="body2">Listed: </Typography> */}
                                    <br />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        onClick={() =>
                                            loginAs(issuer.email, id)
                                        }
                                        disabled={
                                            disabledButton === id &&
                                            props.loadingUser
                                        }
                                        fullWidth
                                    >
                                        {!(
                                            disabledButton === id &&
                                            props.loadingUser
                                        ) ? (
                                            <span>Log In</span>
                                        ) : (
                                            <CircularProgress size={30} />
                                        )}
                                    </Button>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                    <Dialog
                        maxWidth="sm"
                        fullWidth
                        open={documentsDialog}
                        onBackdropClick={(e) => setDocumentsDialog(false)}
                    >
                        <DialogTitle>
                            Issuer Documents
                            <br />
                        </DialogTitle>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12} md={12}>
                                    {props.documents && props.documents.data
                                        ? props.documents.data.data.map(
                                              (document, id) => (
                                                  <div>
                                                      <div>
                                                          <b>Name:</b>{' '}
                                                          {document.name}
                                                      </div>
                                                      <div>
                                                          <b>Type:</b>{' '}
                                                          {document.type}
                                                      </div>
                                                      <div>
                                                          <b>Updated at:</b>{' '}
                                                          {DateFormatter({
                                                              value:
                                                                  document.updated_at,
                                                              format: 'date',
                                                          })}
                                                      </div>
                                                      <div>
                                                          {' '}
                                                          <Button
                                                              component="label"
                                                              color="primary"
                                                              onClick={() =>
                                                                  viewDocument(
                                                                      document.file_path
                                                                          ? document.file_path
                                                                          : null
                                                                  )
                                                              }
                                                          >
                                                              <b>
                                                                  View Document
                                                              </b>
                                                          </Button>
                                                      </div>
                                                      <br />
                                                      <br />
                                                  </div>
                                              )
                                          )
                                        : null}
                                    <DialogActions>
                                        <Button
                                            color="primary"
                                            onClick={() => {
                                                closeDocumentsDialog();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </DialogActions>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </LoadingOverlay> // no data
            ) : (
                <Grid container justify="center">
                    <Grid item>
                        <Typography variant="h6">No data available.</Typography>
                    </Grid>
                </Grid>
            )}
            {
                // Load the paginator only if there's data
                issuers.data && (
                    <Pagination
                        count={issuers.counts.lastPage}
                        search={search}
                        callback={transferAgentActions.getIssuers}
                    />
                )
            }
        </div>
    );
};

const mapStateToProps = ({ transferAgent }) => {
    return {
        issuers: transferAgent.issuers,
        loadingUser: transferAgent.loginAsLoading,
        documents: transferAgent.issuerDocuments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        transferAgentActions: bindActionCreators(
            transferAgentActions,
            dispatch
        ),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransferAgentIssuers);
