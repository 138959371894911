import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from './TextField/TextField';
import { fieldToTextField } from 'formik-mui';

import withStyles from '@mui/styles/withStyles';
import Humanize from 'humanize-plus';

const StyledAutocomplete = withStyles({
    root: {
        '& .MuiAutocomplete-input': {
            padding: '5px 4px !important',
        },
    },
})(Autocomplete);

const FormikAutocomplete = (props) => {
    const {
        form: { setTouched, setFieldValue, touched },
    } = props;

    const {
        error,
        helperText,
        value,
        dataKey,
        optionKey,
        dependsOn,
        label,
        clearOnChange,
        callback,
        ...field
    } = fieldToTextField(props);
    const { name } = field;

    const [key, setKey] = useState(0);

    /* Default field names are 'name' for label, and 'value' for value. */
    const handleChange = (value) => {
        setFieldValue(name, value?.[dataKey || 'value'] || '');

        // Handle a callback on value change
        if (callback) {
            callback(value);
        }

        //  TODO: Make a separate component for this.
        if (name === 'equity_id' && value) {
            setTimeout(() => {
                setFieldValue(
                    'issue_amount',
                    Humanize.formatNumber(value.category_amount)
                );
                setFieldValue(
                    'available_amount',
                    Humanize.formatNumber(value.category_amount)
                );
            }, 0);
        }
    };

    /* Clear dependent fields. */
    useEffect(() => {
        if (dependsOn !== undefined) {
            setFieldValue(name, '');
            setKey(key + 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dependsOn]);

    /* Equality test for items */
    const equalityTest = (option, value) => {
        if (!dataKey) {
            return option.value === value.value;
        }
        return option[dataKey] === value[dataKey];
    };

    return (
        <StyledAutocomplete
            key={key}
            {...field}
            onChange={(e, value) => handleChange(value)}
            onBlur={() => setTouched({ ...touched, [name]: true })}
            getOptionLabel={(option) => option[optionKey]}
            getOptionSelected={equalityTest}
            isOptionEqualToValue={equalityTest}
            defaultValue={props.options.find((os) => os[dataKey] === value)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    helperText={helperText}
                    error={error}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputLabelProps={{
                        style: {
                            pointerEvents: 'auto',
                        },
                    }}
                />
            )}
        />
    );
};

export default FormikAutocomplete;
