import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery';

import useStyles from './styles/sidebarStyles'

const VerticalContainer = ({ children }) => {

    const classes = useStyles()
    const smUp = useMediaQuery(theme => theme.breakpoints.up('sm'));

    return (
        <div className={smUp ? classes.root : ''}>
            {children}
        </div>
    )
}

export default VerticalContainer
