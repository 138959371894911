import React, { useEffect } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import * as adminActions from '../../../store/admin/adminActions' 
import { SCOPE_SUPER_ADMIN } from '../../../constants/profile'

import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip';

import DataTable from '../../../components/DataTable/MuiDataTable';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import AddNewAdmin from './AddNewAdmin';
import DeleteAdmin from './DeleteAdmin';
import moment from 'moment';

const Dashboard = (props) => {

    const { administrators, adminActions, profile } = props

    /* Load admin list */
    useEffect(adminActions.getAdministrators, [])

    return (
        <div>
            <Grid container spacing={2}>
                {/* Administrator management (limited to superadmin) */}
                {
                    profile.scope === SCOPE_SUPER_ADMIN &&
                    <React.Fragment>
                        <AddNewAdmin />
                        <ConfirmationDialog /> {/* confirm delete, update, etc. */}
                    </React.Fragment>
                }
                {/* List administrators */}
                <Grid item xs={12}>
                    <DataTable title="Administrators" 
                        data={administrators.data} 
                        columns={[
                            { label: 'Name', name: 'name' },
                            { label: 'Email', name: 'email' },
                            { label: 'Role', name: 'scope', options: {
                                customBodyRender: (value) => (
                                    <Chip size="small" label={value} color={value === SCOPE_SUPER_ADMIN ? 'secondary' : 'primary'} />
                                )
                            } },
                            { label: 'Added at', name: 'created_at', options: {
                                customBodyRender: (value) => (
                                    <span>{moment(value).format('DD/MM/YYYY hh:mm:ss')}</span>
                                ),
                            }},
                            { label: 'Actions', name: 'id', 
                                options: {
                                    display: (profile.scope === SCOPE_SUPER_ADMIN) ? true : 'excluded', 
                                    customBodyRender: (value) => (
                                        profile.user_id !== value && // cannot delete yourself
                                        <DeleteAdmin id={value} />
                                    )
                                }
                            },
                        ]}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = ({ admin, profile }) => {
    return {
        administrators: admin.administrators,
        profile
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        adminActions: bindActionCreators(adminActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)