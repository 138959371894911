import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import merj from '../../../assets/img/merj.png';
import gsx from '../../../assets/img/gsx.png';
import fusang from '../../../assets/img/fusang.png';
import { connect } from 'react-redux';

import authStyles from '../../../pages/user/authStyles';
import listTemplateStyles from '../../../components/styles/listTemplateStyles';
import LockedTile from '../../../components/LockedTile';
import { SUBSCRIPTION_ENTERPRISE } from '../../../constants/profile';

const VerifiedPartners = (props) => {
    const classes = authStyles();
    const listTemplateClasses = listTemplateStyles();

    let partners = [
        {
            name: 'merj',
            src: merj,
            website: 'https://merj.exchange/',
            description:
                'Financial Services Authority Seychelles regulated global exchange with end-to-end licenses, including clearing, settlement and depository',
        },
        {
            name: 'gsx',
            src: gsx,
            website: 'https://www.gsxgroup.global/',
            width: 180,
            description:
                'Gibraltar Financial Services Commission regulated global exchange operating in the UK and EU, with conditional licence in Labuan, Malaysia',
        },
        {
            name: 'fusang',
            src: fusang,
            website: 'https://www.fusang.co/',
            description:
                "Asia's only fully operational digital securities exchange, authorized by the Financial Services Authority of Labuan",
        },
    ];
    return (
        <LockedTile requiredPlan={SUBSCRIPTION_ENTERPRISE}>
            <Grid
                container
                spacing={2}
            >
                {partners.map((partner, i) => (
                    <Grid item xs={12} sm={6} md={4} key={i}>
                        <Paper
                            elevation={3}
                            className={classes.paper}
                            style={{ minHeight: '320px' }}
                        >
                            <Grid
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: '2rem',
                                    marginTop: '2rem',
                                }}
                            >
                                <img
                                    src={partner.src}
                                    width={partner.width ? partner.width : 300}
                                    height={150}
                                    alt=""
                                    onClick={() =>
                                        window.open(partner.website, '_blank')
                                    }
                                    style={{ cursor: 'pointer' }}
                                />
                            </Grid>
                            <Grid
                                item
                                className={
                                    listTemplateClasses.verifiedPartnersDescription
                                }
                            >
                                {partner.description}
                            </Grid>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </LockedTile>
    );
};

const mapStateToProps = ({ profile }) => {
    return {
        profile: profile,
    };
};

export default connect(mapStateToProps, null)(VerifiedPartners);
