import useSWR from "swr"
import { API_BASE_URL } from "../../config"
import fetcher from "../../utilities/fetcher"

/**
 * A hook that fetches the burn transactions of a holder based
 * on the selected asset.
 * 
 * @param {string} holderId The amp_user_id from the amp_users table
 * @param {string} assetUuid The asset_uuid from the amp_assets table
 * @returns the transactions
 */
const useHolderBurnTransactions = (holderId, assetUuid) => {
    const { data, error, mutate } = useSWR(() => assetUuid ? `${API_BASE_URL}/blockchain/holders/burn-transactions/${holderId}/${assetUuid}` : null, fetcher)

    return {
        holderBurnTransactions: data || [],
        holderBurnTransactionsLoading: !error && !data,
        holderBurnTransactionsError: error,
        updateTransactionsAssets: () => mutate()
    }
}

export default useHolderBurnTransactions