import makeStyles from '@mui/styles/makeStyles';

const titleStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    text: {
        fontSize: '1.65rem',
        fontWeight: 500,
        padding: '1rem 0',
    },
}));

export default titleStyles;
