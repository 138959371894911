import React from 'react';
import { Box } from '@mui/material';

import Tooltip from '../../../Tooltip';
import BaseTableCell from './BaseTableCell';

export default function DefaultTableCell(props) {
    const { col, item } = props;

    const copyToClipboard = (event, item) => {
        event.preventDefault();
        event.stopPropagation();
        if (col.copy) {
            navigator.clipboard.writeText(item.toString());
        }
    };

    return (
        <BaseTableCell col={col} item={item}>
            <Tooltip
                title={
                    item?.tooltipValue ||
                    item[col.tooltipValue] ||
                    item[col.field]
                }
                placement="top"
                disableHoverListener={!col.useTooltip}
            >
                <Box
                    display="inline-block"
                    onClick={(e) => copyToClipboard(e, item[col.field])}
                >
                    {item[col.field] || (col.isEmpty ? null : 'N/A')}
                </Box>
            </Tooltip>
        </BaseTableCell>
    );
}
