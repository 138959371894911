import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import authStyles from '../authStyles';
import * as config from './../../../config';
import request from '../../../utilities/request';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import StyledPaper from '../../../components/StyledPaper';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as documentActions from '../../../store/document/documentActions';
import HelpTooltip from '../../../components/HelpTooltip/HelpTooltip';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MenuItem from '@mui/material/MenuItem';
import { Field } from 'formik';
import FormikSelect from '../../../components/Formik/FormikSelect';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import LockedTile from '../../../components/LockedTile';
import { SUBSCRIPTION_PRO } from '../../../constants/profile';
const StyledTableCell = styled(TableCell)({
    '&.MuiTableCell-head': {
        backgroundColor: 'white',
    },
});

const StyledTableRow = styled(TableRow)({});
const formData = new FormData();
formData.append('document', '');
formData.append('type', '');
const UploadDocuments = (props) => {
    const classes = authStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [deleteDocumentDialog, setDeleteDocumentDialog] = useState(false);
    const [deleteDocumentId, setDeleteDocumentId] = useState(null);
    const [addDocumentDialog, setAddDocumentDialog] = useState(false);
    const [documentType, setDocumentType] = useState(
        'Memorandum of Incorporation/Association'
    );

    const [key, setKey] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(props.documentActions.getDocuments, []);

    const uploadFile = (file, type, reupload = false, id = null) => {
        if (
            file.type !== 'application/pdf' &&
            file.type !== 'text/csv' &&
            //    file.type !== 'text/plain' &&
            file.type !== 'application/vnd.ms-excel' &&
            file.type !==
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
            enqueueSnackbar(
                'Only .csv, .xlx, .xls, .xlsx and .pdf files are allowed',
                {
                    variant: 'error',
                }
            );

            setTimeout(function () {
                closeSnackbar();
            }, 2000);
        } else {
            formData.set('document', file, file.name);
            formData.set('type', type);
            setKey(key + 1);

            if (reupload) {
                formData.set('id', id);
                reuploadDocument(formData);
            } else {
                uploadDocument();
            }
        }
    };
    const editDocument = (id, to_update, new_value) => {
        setIsLoading(true);
        request
            .patch(`${config.API_BASE_URL}/document/edit`, {
                id: id,
                to_update: to_update,
                new_value: new_value,
            })
            .then((response) => {
                enqueueSnackbar('Document updated successfully.', {
                    variant: 'success',
                });

                setIsLoading(false);
                props.documentActions.getDocuments();
            })
            .catch((error) => {
                setIsLoading(false);
                enqueueSnackbar(error.response.data.message, {
                    variant: 'error',
                });
            });
    };

    const uploadDocument = () => {
        setIsLoading(true);
        request
            .post(`${config.API_BASE_URL}/document`, formData)
            .then((response) => {
                setIsLoading(false);
                enqueueSnackbar('The new document has been uploaded', {
                    variant: 'success',
                });
                props.documentActions.getDocuments();
                setAddDocumentDialog(false);
            })
            .catch(() => {
                setIsLoading(false);
                enqueueSnackbar(
                    'An error occurred while saving the new document',
                    {
                        variant: 'error',
                    }
                );
            })
            .finally(() => {});
    };

    const reuploadDocument = (formData) => {
        setIsLoading(true);
        request
            .post(`${config.API_BASE_URL}/document/reupload`, formData)
            .then((response) => {
                setIsLoading(false);
                enqueueSnackbar('The new document has been uploaded', {
                    variant: 'success',
                });
                props.documentActions.getDocuments();
            })
            .catch(() => {
                setIsLoading(false);
                enqueueSnackbar(
                    'An error occurred while saving the new document',
                    {
                        variant: 'error',
                    }
                );
            })
            .finally(() => {});
    };

    const viewDocument = (file_path) => {
        if (file_path !== null && file_path !== undefined) {
            window.open(file_path);
        } else {
            enqueueSnackbar('File has not been uploaded', {
                variant: 'error',
            });
        }
    };

    const displayAddDocumentDialog = () => {
        setAddDocumentDialog(true);
    };

    const displayDeleteDocumentDialog = (documentId) => {
        setDeleteDocumentDialog(true);
        setDeleteDocumentId(documentId);
    };

    const closeDocumentDialog = () => {
        setDeleteDocumentDialog(false);
        setAddDocumentDialog(false);
    };

    const deleteDocument = (id) => {
        setIsLoading(true);
        request
            .delete(`${config.API_BASE_URL}/document/${id}`)
            .then((response) => {
                enqueueSnackbar('Document has been deleted successfully', {
                    variant: 'success',
                });
                setIsLoading(false);
                setDeleteDocumentDialog(false);
                props.documentActions.getDocuments();
            })
            .catch(() => {
                enqueueSnackbar('Error occured while deleting document', {
                    variant: 'error',
                });
            });
    };

    /*const displayTemplates = () => {
        //window.open(templates_link);
    };*/
    return (
        <LockedTile lock requiredPlan={SUBSCRIPTION_PRO}>
            <Formik onSubmit={(values, { setSubmitting }) => {}}>
                <div>
                    <Container maxWidth="lg">
                        <Form className={classes.form} noValidate>
                            <StyledPaper padding={15}>
                                <div className="flex-left">
                                    <Typography
                                        className={classes.documentsTitle}
                                    >
                                        Company documents{' '}
                                        <HelpTooltip title="These are documents that you'd like to share with your holders. You can think of this as a mini-dataroom." />{' '}
                                    </Typography>
                                </div>
                                <Table
                                    className={classes.table}
                                    aria-label="simple table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell
                                                className={classes.tableCell}
                                            >
                                                <b>Name</b>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className={classes.tableCell}
                                            >
                                                <b>File Name</b>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className={classes.tableCell}
                                            >
                                                <b>Actions</b>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className={classes.tableCell}
                                                style={{ textAlign: 'center' }}
                                            >
                                                <b>Is Visible for TA</b>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                style={{ textAlign: 'center' }}
                                                className={classes.tableCell}
                                            >
                                                <b>Is Visible for Holders</b>
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.documents &&
                                            props.documents.data.map((row) => (
                                                <StyledTableRow>
                                                    <StyledTableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                    >
                                                        {row.type}
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                    >
                                                        {row.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                        style={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <div>
                                                            <Tooltip title="View">
                                                                <IconButton
                                                                    component="label"
                                                                    color="primary"
                                                                    sx={{
                                                                        color:
                                                                            'green',
                                                                    }}
                                                                    onClick={() =>
                                                                        viewDocument(
                                                                            row.file_path
                                                                                ? row.file_path
                                                                                : null
                                                                        )
                                                                    }
                                                                >
                                                                    <ZoomInIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            {isLoading ? (
                                                                <CircularProgress
                                                                    size={24}
                                                                />
                                                            ) : (
                                                                <Tooltip title="Update">
                                                                    <IconButton
                                                                        component="label"
                                                                        color="primary"
                                                                        name="document"
                                                                    >
                                                                        <input
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                uploadFile(
                                                                                    e
                                                                                        .target
                                                                                        .files[0],
                                                                                    row.type,
                                                                                    true,
                                                                                    row.id
                                                                                );
                                                                            }}
                                                                            style={{
                                                                                display:
                                                                                    'none',
                                                                            }}
                                                                            type="file"
                                                                        />
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                            <Tooltip title="Delete">
                                                                <IconButton
                                                                    component="label"
                                                                    color="danger"
                                                                    onClick={() =>
                                                                        displayDeleteDocumentDialog(
                                                                            row.id
                                                                        )
                                                                    }
                                                                >
                                                                    <DeleteForeverIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                    >
                                                        <FormControlLabel
                                                            className={
                                                                classes.alignEnd
                                                            }
                                                            label=""
                                                            control={
                                                                <Checkbox
                                                                    onChange={() =>
                                                                        editDocument(
                                                                            row.id,
                                                                            'visible_to_ta',
                                                                            !row.visible_to_ta
                                                                        )
                                                                    }
                                                                    checked={
                                                                        row.visible_to_ta ===
                                                                        1
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    color="primary"
                                                                />
                                                            }
                                                        />
                                                    </StyledTableCell>

                                                    <StyledTableCell
                                                        className={
                                                            classes.tableCell
                                                        }
                                                    >
                                                        <FormControlLabel
                                                            label=""
                                                            className={
                                                                classes.alignCenter
                                                            }
                                                            control={
                                                                <Checkbox
                                                                    onChange={() =>
                                                                        editDocument(
                                                                            row.id,
                                                                            'visible_to_investors',
                                                                            !row.visible_to_investors
                                                                        )
                                                                    }
                                                                    checked={
                                                                        row.visible_to_investors ===
                                                                        1
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    color="primary"
                                                                />
                                                            }
                                                        />
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                                <br />
                                <br />
                                <div>
                                    <ButtonGroup className={classes.alignEnd}>
                                        {/*    <Button
                                        color="secondary"
                                        variant="outlined"
                                        className={classes.secondaryButtonLabel}
                                        onClick={() => displayTemplates()}
                                        style={{ marginRight: '10px' }}
                                    >
                                        See Templates
                                    </Button>*/}
                                        <Button
                                            component="label"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.buttonLabel}
                                            onClick={() =>
                                                displayAddDocumentDialog()
                                            }
                                        >
                                            Upload Document
                                        </Button>{' '}
                                    </ButtonGroup>
                                </div>
                            </StyledPaper>

                            <p
                                className={`${classes.documentsNote} ${classes.centerText}`}
                            >
                                <i>
                                    Note: Transfer Agents will require Articles
                                    of Incorporation and Holders Agreement to be
                                    uploaded.
                                    <br />
                                    You can find templates for those by clicking
                                    the "See Templates" button
                                </i>
                            </p>
                        </Form>
                        <Dialog
                            maxWidth="sm"
                            fullWidth
                            open={deleteDocumentDialog}
                        >
                            <DialogTitle
                                id="responsive-dialog-title"
                                className={classes.dialogTitle}
                            >
                                {
                                    'Are you sure you want to delete this document?'
                                }
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    You will not be able to discard this action
                                </DialogContentText>
                            </DialogContent>

                            <DialogActions className={classes.dialogActions}>
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    className={classes.secondaryButtonLabel}
                                    onClick={() => {
                                        closeDocumentDialog();
                                    }}
                                >
                                    <b>Cancel</b>
                                </Button>
                                {!isLoading ? (
                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        className={classes.buttonLabel}
                                        color="primary"
                                        onClick={() => {
                                            deleteDocument(deleteDocumentId);
                                        }}
                                    >
                                        <b>Delete</b>
                                    </Button>
                                ) : (
                                    <CircularProgress size={24} />
                                )}
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            maxWidth="sm"
                            fullWidth
                            open={addDocumentDialog}
                        >
                            <DialogTitle className={classes.dialogTitle}>
                                New Document
                            </DialogTitle>
                            <DialogContent>
                                <Field
                                    component={FormikSelect}
                                    name="class"
                                    variant="outlined"
                                    inputProps={{
                                        id: 'class',
                                    }}
                                    fullWidth
                                    required
                                    label="Type"
                                    value={documentType}
                                    onChange={(e) =>
                                        setDocumentType(e.target.value)
                                    }
                                >
                                    <MenuItem
                                        key={'1'}
                                        value={
                                            'Memorandum of Incorporation/Association'
                                        }
                                    >
                                        {
                                            'Memorandum of Incorporation/Association'
                                        }
                                    </MenuItem>
                                    <MenuItem
                                        key={'2'}
                                        value={
                                            'Articles of Incorporation/Association'
                                        }
                                    >
                                        {
                                            'Articles of Incorporation/Association'
                                        }
                                    </MenuItem>
                                    <MenuItem
                                        key={'3'}
                                        value={'Holder Agreement'}
                                    >
                                        {'Holder Agreement'}
                                    </MenuItem>
                                    <MenuItem
                                        key={'4'}
                                        value={'Custom Document'}
                                    >
                                        {'Custom Document'}
                                    </MenuItem>
                                </Field>
                            </DialogContent>

                            <DialogActions className={classes.dialogActions}>
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    onClick={() => {
                                        closeDocumentDialog();
                                    }}
                                    style={{ marginRight: '10px' }}
                                >
                                    <b>Cancel</b>
                                </Button>
                                {!isLoading ? (
                                    <Button
                                        component="label"
                                        variant="outlined"
                                        color="primary"
                                        name="document"
                                    >
                                        <input
                                            onChange={(e) => {
                                                uploadFile(
                                                    e.target.files[0],
                                                    documentType
                                                );
                                            }}
                                            style={{
                                                display: 'none',
                                            }}
                                            type="file"
                                            name="new_document"
                                            disabled={isLoading}
                                        />

                                        <b>Select File and Upload</b>
                                    </Button>
                                ) : (
                                    <CircularProgress size={24} />
                                )}
                            </DialogActions>
                        </Dialog>
                    </Container>
                </div>
            </Formik>
        </LockedTile>
    );
};

const mapStateToProps = ({ documents }) => {
    return {
        documents: documents.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        documentActions: bindActionCreators(documentActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocuments);
