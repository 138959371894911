import * as drawerTypes from './drawerTypes'

/**
 * ACTIONS
 */

 export const toggleDrawerSuccess = (data) => {
    return {
        type: drawerTypes.TOGGLE_DRAWER_SUCCESS,
        payload: data
    }
}

/**
 * ACTION CREATORS
 */

export const toggleDrawer = (open) => {
    return (dispatch) => {
        dispatch(toggleDrawerSuccess(open))
    }
}