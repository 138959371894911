import useSWR, { useSWRConfig } from "swr"
import { API_BASE_URL } from "../../config"
import fetcher from "../../utilities/fetcher"

const useUnreadMessages = (signedIn = true) => {
    const { data, error } = useSWR(() => signedIn ? `${API_BASE_URL}/messages/unread` : null, fetcher)
    const { mutate } = useSWRConfig()

    return {
        unread: data,
        unreadLoading: !error && !data,
        unreadError: error,
        updateUnreadMessages: () => {
            mutate(`${API_BASE_URL}/messages/unread`)
        }
    }
}

export default useUnreadMessages