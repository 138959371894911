import useSWR from "swr"
import { API_BASE_URL } from "../../config"
import fetcher from "../../utilities/fetcher"

const useWalletBalance = (wallet) => {
    const { data, error, isValidating, mutate } = useSWR(() => wallet ? `${API_BASE_URL}/blockchain/wallet/${wallet}/balance` : null, fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    })

    return {
        walletBalance: data || [],
        walletBalanceLoading: !error && !data,
        walletBalanceError: error,
        walletBalanceValidating: isValidating,
        updateWalletBalance: () => mutate()
    }
}

export default useWalletBalance