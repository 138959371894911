import React from 'react'
import PropTypes from 'prop-types';

import ReactLoadingOverlay from 'react-loading-overlay'

const LoadingOverlay = (props) => {

    const { children, active, text, fadeSpeed } = props

    return (
        <ReactLoadingOverlay
            active={active}
            spinner
            fadeSpeed={fadeSpeed || 0}
            text={text || 'Loading data...'}
        >
            {children}
        </ReactLoadingOverlay>
    )
}

LoadingOverlay.propTypes = {
    children: PropTypes.node,
    active: PropTypes.bool.isRequired,
    text: PropTypes.string,
}

export default LoadingOverlay
