import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import Container from '@mui/material/Container';
import authStyles from '../../authStyles';
import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
import { CheckboxWithLabel } from 'formik-mui';
import Typography from '@mui/material/Typography';
import FundButtonGroup from '../FundButtonGroup';
const Review = (props) => {
    const classes = authStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [isReviewed, setIsReviewed] = useState(
        props.initialValues.is_reviewed
    );
    let [refreshForm, setRefreshForm] = useState(0);
    const nextStep = () => {
        props.nextStep();
    };

    const previousStep = () => {
        props.previousStep();
    };

    const closeDialog = () => {
        props.closeDialog();
    };

    return (
        <div id="step8">
            <Formik
                key={refreshForm}
                initialValues={props.initialValues}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    if (values['is_reviewed'] === true) {
                        props.saveValues(values);
                        nextStep();
                        props.changeKey();
                    } else {
                        enqueueSnackbar('Please check box to continue', {
                            variant: 'error',
                        });
                    }

                    resetForm();
                    refreshForm++;
                    setRefreshForm(refreshForm);
                }}
            >
                {({ setFieldValue }) => (
                    <Container maxWidth="lg">
                        <Form className={classes.form} noValidate>
                            <Typography component="div">
                                <h3>Review Your Offering!</h3>
                            </Typography>

                            <Typography component="div">
                                In the next steps you'll be e-signing the
                                various agreements necessary to complete your
                                offering. Now is the time to review all the
                                prior steps you have put data into and make sure
                                the information is accurate and complete, make
                                sure that you have added every individual
                                mentioned in your PPM that has a management,
                                executive or operational position into the
                                associated persons field. Do not proceed until
                                you have completed the previous steps in full.
                            </Typography>
                            <br />
                            <Typography component="div">
                                Review, edit if necessary, and click on the
                                "Next" button on the bottom. Repeat this
                                procedure until you are satisfied your offering
                                information is accurate then check the box below
                                and click the "Next" button.
                            </Typography>
                            <br />
                            <Grid container spacing={1}>
                                <Field
                                    component={CheckboxWithLabel}
                                    type="checkbox"
                                    name="is_reviewed"
                                    Label={{
                                        label: (
                                            <div className={classes.checkbox}>
                                                The information entered on the
                                                previous steps is correct and
                                                has been reviewed. We are ready
                                                to proceed and understand that
                                                during the review process, data
                                                should not be updated unless
                                                requested by the compliance
                                                team.
                                            </div>
                                        ),
                                    }}
                                    checked={isReviewed}
                                    onClick={() => setIsReviewed(!isReviewed)}
                                    required
                                />
                            </Grid>

                            <FundButtonGroup
                                previousStep={previousStep}
                                closeDialog={closeDialog}
                            />
                        </Form>
                    </Container>
                )}
            </Formik>
        </div>
    );
};

export default Review;
