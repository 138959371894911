import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import Container from '@mui/material/Container';
import authStyles from '../../authStyles';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '../../../../components/TextField/TextField';
import Grid from '@mui/material/Grid';
import FundButtonGroup from '../FundButtonGroup';
const SecStatement = (props) => {
    const classes = authStyles();
    const [secStatement, setSecStatement] = useState(
        props.initialValues.sec_statement
            ? props.initialValues.sec_statement === true
                ? 'yes'
                : 'no'
            : 'no'
    );
    const nextStep = () => {
        props.nextStep();
    };

    const previousStep = () => {
        props.previousStep();
    };

    const closeDialog = () => {
        props.closeDialog();
    };

    const changeSecStatement = (e) => {
        setSecStatement(e.target.value);
    };

    return (
        <div id="step4">
            <Formik
                initialValues={props.initialValues}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    if (secStatement === 'yes') {
                        values['sec_statement'] = true;
                    } else {
                        values['sec_statement'] = false;
                    }
                    props.saveValues(values);
                    nextStep();
                    props.changeKey();
                }}
            >
                {({ setFieldValue }) => (
                    <Container maxWidth="lg">
                        <Form className={classes.form} noValidate>
                            <Typography component="div">
                                <b>
                                    Per securities regulations and the Dodd
                                    Frank investor protection Act issuers and
                                    their associated persons can NOT raise
                                    capital by selling securities if anyone is
                                    deemed to be a “bad actor”. Answer these
                                    questions for your company and all
                                    associated persons collectively – if ANY of
                                    them have a “yes” answer then you must say
                                    “yes” to the question and provide details
                                    below.
                                </b>
                            </Typography>
                            <br />
                            <Typography component="div">
                                <b>
                                    Has the issuer (company) or any officer,
                                    director or major holder (owner of 20% of
                                    more) had or been subject to…
                                </b>
                            </Typography>
                            <br />
                            <Typography component="div">
                                <b>
                                    Criminal convictions related to securities -
                                </b>{' '}
                                Criminal felony or misdemeanor convictions
                                within ten years before this offering in
                                connection with the purchase or sale of any
                                security, involving any false filing with the
                                SEC or arising out of the conduct of an
                                underwriter, broker, dealer, municipal
                                securities dealer, investment adviser or paid
                                solicitor of purchasers of securities;
                            </Typography>
                            <br />
                            <Typography component="div">
                                <b>
                                    Court restraining orders and injunctions
                                    related to securities -{' '}
                                </b>
                                Court orders within five years of this offering
                                that restrain or enjoin them from any connection
                                with the purchase or sale of any security,
                                involving any false filing with the SEC or
                                arising out of the conduct of an underwriter,
                                broker, dealer, municipal securities dealer,
                                investment adviser or paid solicitor of
                                purchasers of securities;
                            </Typography>
                            <br />
                            <Typography component="div">
                                <b>Final regulatory orders - </b>
                                Is under a final order from any state securities
                                commission, state banking regulator, state
                                insurance commission, federal banking agency,
                                the Commodities Futures Trading Commission or
                                the National Credit Union Administration that
                                either currently bars the person from
                                association with an entity regulated by such
                                authority, engaging in securities, insurance or
                                banking business or engaging in savings
                                association or credit union activities, or that
                                constitutes a final order entered within the
                                last ten years based on a violation of a law or
                                regulation that prohibits fraudulent,
                                manipulative or deceptive conduct;
                            </Typography>
                            <br />
                            <Typography component="div">
                                <b>SEC disciplinary orders - </b>
                                SEC disciplinary orders that currently suspend
                                or revoke the person‘s registration as a broker,
                                dealer, municipal securities dealer or
                                investment adviser, or that limit the activities
                                of such person or bar such person from being
                                associated with any entity using federal or
                                state securities exemptions to raise capital
                                and/or sell securities;
                            </Typography>
                            <br />
                            <Typography component="div">
                                <b>SEC cease and desist orders - </b>
                                SEC cease and desist orders within the last five
                                years that order the person to cease and desist
                                from violating either scienter-based anti-fraud
                                provisions of federal securities laws or the
                                Section 5 registration requirements of the
                                Securities Act of 1933;
                            </Typography>
                            <br />
                            <Typography component="div">
                                <b>SRO suspension or expulsion - </b>
                                Is currently suspended from membership in, or
                                association with, a registered national
                                securities exchange or registered securities
                                association for conduct inconsistent with just
                                and equitable principles of trade;
                            </Typography>
                            <br />
                            <Typography component="div">
                                <b>SEC stop orders - </b>
                                SEC stop orders relating to a registration
                                statement or Regulation A offering statement
                                filed with the SEC within the last five years
                                being the subject of a stop order investigation;
                                or
                            </Typography>
                            <br />
                            <Typography component="div">
                                <b>
                                    Postal Service false representation orders -{' '}
                                </b>
                                United States Postal Service false
                                representation orders entered within the last
                                five years being subject to a temporary
                                restraining order or preliminary injunction for
                                conduct alleged by the postal service to
                                constitute a scheme for obtaining money or
                                property through the mail by means of false
                                representations.
                            </Typography>
                            <br />

                            <RadioGroup
                                name="sec_statement"
                                row
                                required
                                defaultValue={secStatement}
                            >
                                <FormControlLabel
                                    value={'no'}
                                    control={<Radio color="primary" />}
                                    label="No, all clear"
                                    labelPlacement="end"
                                    onChange={changeSecStatement}
                                />
                                <FormControlLabel
                                    value={'yes'}
                                    control={<Radio color="primary" />}
                                    label="Yes, please describe below."
                                    labelPlacement="end"
                                    onChange={changeSecStatement}
                                />
                            </RadioGroup>
                            <br />
                            {secStatement === 'yes' && (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Field
                                            component={TextField}
                                            name="sec_statement_description"
                                            type="text"
                                            label="Description"
                                            rows={5}
                                            multiline
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            )}

                            <FundButtonGroup
                                previousStep={previousStep}
                                closeDialog={closeDialog}
                            />
                        </Form>
                    </Container>
                )}
            </Formik>
        </div>
    );
};

export default SecStatement;
