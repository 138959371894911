import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Typography from '@mui/material/Typography';
import LockIcon from '@mui/icons-material/Lock';
import { SUBSCRIPTION_BASIC } from '../constants/profile';
import { isFeatureLocked } from '../utilities/utils';

/**
 * Disable the page and show a 'locked' screen.
 */
const LockedTile = (props) => {
    const {
        children,
        demo,
        plan,
        listing,
        lock,
        requiredPlan = SUBSCRIPTION_BASIC,
    } = props;
    const toSentenceCase = (text) => {
        return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
    };

    const featureLocked = isFeatureLocked(requiredPlan, plan);
    return (
        <div
            className={
                (!demo && !listing && featureLocked) || lock
                    ? 'locked-tile'
                    : null
            }
        >
            {!demo && !listing && featureLocked && (
                <React.Fragment>
                    <Typography
                        variant="caption"
                        style={{ float: 'right', marginBottom: '1.8rem' }}
                    >
                        <span className="flex-left muted">
                            <LockIcon />
                            <span>
                                Required plan: {toSentenceCase(requiredPlan)}
                            </span>
                        </span>
                    </Typography>
                    <br />
                    <br />
                </React.Fragment>
            )}
            {children}
        </div>
    );
};

const mapStateToProps = ({ profile }) => {
    return {
        demo: profile.demo,
        plan: profile.subscription_model,
        listing: profile.scope === 'list',
    };
};

LockedTile.propTypes = {
    requiredPlan: PropTypes.string,
};

export default connect(mapStateToProps, null)(LockedTile);
