import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import BoxWithLogo from '../../../components/Containers/BoxWithLogo';
import IHQLogoImage from '../../../assets/img/new_logo_digtl.png';
import PasswordField from '../../../components/PasswordField';

import authStyles from '../authStyles';
import * as config from './../../../config';
import { snackbar } from '../../../utilities/snackbarUtils';

const ContainerWrapper = ({ children, isDone }) => {
    const classes = authStyles();

    return (
        <>
            <BoxWithLogo logo={IHQLogoImage} title={'Reset Your Password'}>
                {children}
                {!isDone && (
                    <Typography variant="body1" className="centered">
                        <Link
                            className={classes.link}
                            component={RouterLink}
                            to="/login"
                        >
                            Back to Login
                        </Link>
                    </Typography>
                )}
            </BoxWithLogo>
        </>
    );
};

const Success = () => {
    const classes = authStyles();

    return (
        <div>
            <h3>
                You've successfully updated your password. You can now log in
                using your new credentials.
            </h3>

            <RouterLink
                component={Button}
                className={classes.button}
                to="/login"
                variant="contained"
                color="primary"
            >
                Log in now
            </RouterLink>
        </div>
    );
};

const initialValues = {
    password: '',
    password_confirmation: '',
};

const validationSchema = Yup.object({
    password: Yup.string()
        .required('Please enter your new password.')
        //  .min(8, 'Password is too short - must containt at least 8 characters.')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,

            'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, number and one symbol.'
        ),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match.')
        .required('Please confirm your password.'),
});

const ResetPassword = (props) => {
    const classes = authStyles();
    const { hash } = useParams();

    const [isValidating, setIsValidating] = useState(true);
    const [isValid, setIsValid] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        axios
            .get(`${config.API_BASE_URL}/check-password-reset?token=${hash}`)
            .then((response) => {
                setIsValid(true);
            })
            .catch((error) => {
                snackbar.error(
                    'Invalid reset token. Please request a new one.'
                );
            })
            .finally(() => {
                setIsValidating(false);
            });
    }, [hash]);

    if (isValidating) {
        return (
            <ContainerWrapper>
                <Grid container alignContent="center" alignItems="center">
                    <CircularProgress style={{ marginRight: 10 }} />
                    <b>Checking password reset link...</b>
                </Grid>
            </ContainerWrapper>
        );
    } else if (success) {
        return (
            <ContainerWrapper isDone={true}>
                <Success />
            </ContainerWrapper>
        );
    } else {
        return isValid ? (
            <ContainerWrapper>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        const resetData = {
                            ...values,
                            token: hash,
                        };
                        axios
                            .post(
                                `${config.API_BASE_URL}/password-reset`,
                                resetData
                            )
                            .then(() => {
                                snackbar.success(
                                    'You have successfully reset your password. You can now try to log in again.'
                                );
                                setSuccess(true);
                                // history.replace({ pathname: '/login' });
                            })
                            .catch(() => {
                                snackbar.error(
                                    'Invalid reset attempt. Please try again.'
                                );
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <PasswordField
                                        name="password"
                                        label="New Password"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <PasswordField
                                        name="password_confirmation"
                                        label="Confirm Password"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.actions}>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            variant="outlined"
                                            className={classes.buttonLabel}
                                            disabled={isSubmitting}
                                        >
                                            {!isSubmitting ? (
                                                <span>Reset My Password</span>
                                            ) : (
                                                <CircularProgress size={30} />
                                            )}
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </ContainerWrapper>
        ) : (
            <ContainerWrapper>
                <h3>
                    Invalid reset token. Please request another password reset.
                </h3>
            </ContainerWrapper>
        );
    }
};

export default ResetPassword;
