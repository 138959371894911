import React, { useState /*, useEffect*/ } from 'react';
//import authStyles from '../../authStyles';
import InvestorVesting from './InvestorVesting';
import SecStatement from './SecStatement';
import InvestorSuitability from './InvestorSuitability';
import ACHForm from './ACHForm';
import OfferingAgreement from './OfferingAgreement';
import Congratulations from './Congratulations';
import { Dialog, DialogTitle, DialogContent, Grid } from '@mui/material';
import fundraiseOfferingStyles from '../../../../components/styles/fundraiseOfferingStyles';
let initialValues = {
    investor_name: '',
    investor_type: 'INDIVIDUAL',
    social_security_number: '',
    date_of_birth: '',
    street_address: '',
    apartment: '',
    city: '',
    state: '',
    zip_code: '',
    email: '',
    phone_number: '',
    is_joint_account: true,
    invest_amount: '',
    payment_method: 'ACH',
    financial_advisor: '',
    accredited_investor: true,
    accredited_investor_type: 'TYPE1',
    confirmation_type: '',
    sec_statement_note: '',
    backup_withholding: false,
    us_resident: true,
    customer_agreement_accepted: false,
    household_income_last_year: '',
    household_income_current_year: '',
    annual_average_expenses: '',
    net_worth: '',
    liquidity: '',
    large_expenses_planned: true,
    investing_experience: '<3',
    invested_company_types: '',
    invested_company_types_comments: '',
    private_assets_estimated_percentage: '',
    cummulative_amount_of_investments: '<500000',
    current_investment_value: '<20',
    current_investment_value_comments: '',
    primary_funds_source: '',
    investment_goals: '',
    risk_tolerance: 'very_low',
    certify_accurate_information: false,
    understand_investment: false,
    name_on_account: '',
    bank_routing_number: '',
    bank_account_number: '',
    account_type: 'CHECKING',
    check_type: 'PERSONAL',
    ach_authorization_signature: '',
    form_filling_person: 'investor',
    offering_agreement_signature: '',
    us_businesss: false,
    us_international: false,
    real_estate: false,
    limited_partnership: false,
    oil_gas: false,
    other: false,
    preservation_of_capital: false,
    income: false,
    growth: false,
    speculation: false,
    google: false,
    website: false,
    large_investment: false,
    accountant: false,
    broker: false,
    lawyer: false,
    email_tax_returns: false,
    email_bank_statement: false,
    offering_id: 108,
};

const Investment = (props) => {
    //const classes = authStyles();
    const offeringClasses = fundraiseOfferingStyles();
    const { open } = props;
    const [dialogIsOpen, setDialogIsOpen] = useState(open);

    const [step, setStep] = useState(0);
    const [key, setKey] = useState(0);

    const saveValues = (field_value) => {
        return function () {
            initialValues = Object.assign({}, initialValues, field_value);
        }.bind()();
    };

    const nextStep = () => {
        setStep(step + 1);
    };

    const previousStep = () => {
        setStep(step - 1);
    };

    const changeKey = () => {
        setKey(key + 1);
    };

    const closeDialog = () => {
        setDialogIsOpen(false);

        for (var x in initialValues) {
            initialValues[x] = '';
        }
        saveValues(initialValues);
        //setStep(0);
        setKey(key + 1);
    };

    const getTitle = () => {
        switch (step) {
            case 0:
                return 'Investor Vesting Information';
            case 1:
                return 'SEC Statements';
            case 2:
                return 'Investor Suitability';
            case 3:
                return 'ACH Form';
            case 4:
                return 'Offering Agreement';
            case 5:
                return 'Congratulations!';
            default:
                return 'Investor Vesting Information';
        }
    };

    const showStep = () => {
        switch (step) {
            case 0:
                return (
                    <InvestorVesting
                        initialValues={initialValues}
                        nextStep={nextStep}
                        saveValues={saveValues}
                        closeDialog={closeDialog}
                        changeKey={changeKey}
                    />
                );
            case 1:
                return (
                    <SecStatement
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        closeDialog={closeDialog}
                        changeKey={changeKey}
                    />
                );
            case 2:
                return (
                    <InvestorSuitability
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        closeDialog={closeDialog}
                        changeKey={changeKey}
                    />
                );
            case 3:
                return (
                    <ACHForm
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        closeDialog={closeDialog}
                        changeKey={changeKey}
                    />
                );
            case 4:
                return (
                    <OfferingAgreement
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        closeDialog={closeDialog}
                        changeKey={changeKey}
                    />
                );
            case 5:
                return (
                    <Congratulations
                        initialValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        closeDialog={closeDialog}
                        changeKey={changeKey}
                    />
                );
            default:
                return (
                    <InvestorVesting
                        initialValues={initialValues}
                        defaultValues={initialValues}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        closeDialog={closeDialog}
                        changeKey={changeKey}
                    />
                );
        }
    };
    return (
        <Dialog
            fullWidth
            maxWidth="lg"
            open={dialogIsOpen}
            onBackdropClick={(e) => setDialogIsOpen(false)}
        >
            <DialogTitle className={offeringClasses.title}>
                {getTitle()}
            </DialogTitle>
            <DialogContent>
                <Grid container justifyContent="center">
                    <Grid item xs={12} md={12}>
                        {showStep()}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
export default Investment;
