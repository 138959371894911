import React, { useEffect /*, { useState }*/ } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Container from '@mui/material/Container';
import TextField from '../../../../components/TextField/TextField';
import authStyles from '../../authStyles';
import * as profileActions from '../../../../store/profile/profileActions';
import FundButtonGroup from '../FundButtonGroup';
const validationSchema = Yup.object({
    offering_name: Yup.string().required('Please enter offering name'),
});
const OfferingName = (props) => {
    const classes = authStyles();

    useEffect(() => {
        props.initialValues['issuer_name'] = props.profile.company_name;
        props.initialValues['country'] = props.profile.incorporation_country;
        props.initialValues['street_address'] =
            props.profile.company_registered_address;
        props.saveValues(props.initialValues);
    });

    const nextStep = () => {
        props.nextStep();
    };

    const closeDialog = () => {
        props.closeDialog();
    };

    return (
        <div id="step1">
            <Formik
                initialValues={props.initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    props.saveValues(values);
                    nextStep();
                    props.changeKey();
                }}
            >
                {({ setFieldValue }) => (
                    <Container maxWidth="lg">
                        <Form className={classes.form} noValidate>
                            <Field
                                component={TextField}
                                type="text"
                                label="Name"
                                name="offering_name"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                            />
                            <FundButtonGroup
                                closeDialog={closeDialog}
                                first={true}
                            />
                        </Form>
                    </Container>
                )}
            </Formik>
        </div>
    );
};

const mapStateToProps = ({ profile }) => {
    return {
        profile: profile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        profileActions: bindActionCreators(profileActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferingName);
