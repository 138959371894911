import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import authStyles from '../../authStyles';
import { useSnackbar } from 'notistack';
import Typography from '@mui/material/Typography';
import * as config from './../../../../config';
import request from '../../../../utilities/request';
import TextField from '../../../../components/TextField/TextField';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormikSelect from '../../../../components/Formik/FormikSelect';
import fundraiseStyles from '../../../../components/styles/fundraiseOfferingStyles';
import FundButtonGroup from '../FundButtonGroup';

const Buttons = (props) => {
    const classes = authStyles();
    const offeringClasses = fundraiseStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [color, setColor] = useState(
        props.initialValues.button_color
            ? props.initialValues.button_color
            : '#0000FF'
    );
    const [size, setSize] = useState(
        props.initialValues.button_size
            ? props.initialValues.button_size
            : 'small'
    );

    const previousStep = () => {
        props.previousStep();
    };

    const closeDialog = () => {
        props.closeDialog();
    };

    const handleColorChange = (e) => {
        setColor(e.target.value);
    };

    const handleSizeChange = (e) => {
        setSize(e.target.value);
    };

    const handleButtonClassValue = (color, size) => {
        if (color === '#0000FF') {
            switch (size) {
                case 'small':
                    return 'btn11';
                case 'medium':
                    return 'btn12';
                case 'large':
                    return 'btn13';
                default:
                    return 'btn11';
            }
        } else if (color === '#00FF00') {
            switch (size) {
                case 'small':
                    return 'btn21';
                case 'medium':
                    return 'btn22';
                case 'large':
                    return 'btn23';
                default:
                    return 'btn21';
            }
        } else {
            switch (size) {
                case 'small':
                    return 'btn31';
                case 'medium':
                    return 'btn32';
                case 'large':
                    return 'btn33';
                default:
                    return 'btn31';
            }
        }
    };

    const handleButtonClass = (color, size) => {
        if (color === '#0000FF') {
            switch (size) {
                case 'small':
                    return offeringClasses.btn11;
                case 'medium':
                    return offeringClasses.btn12;
                case 'large':
                    return offeringClasses.btn13;
                default:
                    return offeringClasses.btn11;
            }
        } else if (color === '#00FF00') {
            switch (size) {
                case 'small':
                    return offeringClasses.btn21;

                case 'medium':
                    return offeringClasses.btn22;

                case 'large':
                    return offeringClasses.btn23;

                default:
                    return offeringClasses.btn21;
            }
        } else {
            switch (size) {
                case 'small':
                    return offeringClasses.btn31;

                case 'medium':
                    return offeringClasses.btn32;

                case 'large':
                    return offeringClasses.btn33;

                default:
                    return offeringClasses.btn31;
            }
        }
    };

    let button_colors = [
        { name: 'Blue', value: '#0000FF' },
        { name: 'Green', value: '#00FF00' },
        { name: '#ED7571', value: '#FF0000' },
    ];

    let button_sizes = [
        { name: 'Small', value: 'small' },
        { name: 'Medium', value: 'medium' },
        { name: 'Large', value: 'large' },
    ];

    return (
        <div id="step11">
            <Formik
                initialValues={props.initialValues}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    values['button_color'] = color;
                    values['button_size'] = size;
                    values['button_class'] = handleButtonClassValue(
                        color,
                        size
                    );

                    for (var x in values) {
                        if (x === 'associated_persons') {
                            props.formData.append(x, JSON.stringify(values[x]));
                        } else {
                            props.formData.append(x, values[x]);
                        }
                    }

                    request
                        .post(
                            `${config.API_BASE_URL}/fundraise/offering`,
                            props.formData
                        )
                        .then((response) => {
                            for (var x in values) {
                                values[x] = '';
                            }
                            props.saveValues(values);
                            enqueueSnackbar(
                                'Information has been saved successfully',
                                {
                                    variant: 'success',
                                }
                            );

                            closeDialog();
                        })
                        .catch((error) => {
                            enqueueSnackbar(error.response.data.message, {
                                variant: 'error',
                            });
                        });
                }}
            >
                {({ setFieldValue }) => (
                    <Container maxWidth="lg">
                        <Form className={classes.form} noValidate>
                            <Typography component="div">
                                <h3>
                                    Step 1: Add the "Invest Now" code to your
                                    investment page.
                                </h3>
                            </Typography>
                            <Typography component="div">
                                Copy and paste the following code into any web
                                page that will be using an "Invest Now" button.
                                This code won't be visible to your users, so the
                                location doesn't matter.
                            </Typography>

                            <Field
                                component={TextField}
                                name="script"
                                type="text"
                                rows={2}
                                multiline
                                fullWidth
                                defaultValue="script"
                                inputProps={{ readOnly: true }}
                            />
                            <hr className={offeringClasses.horizontalLine} />
                            <Typography component="div">
                                <h3>Step 2. Choose a button.</h3>
                            </Typography>
                            <Typography component="div">
                                Pick a button and copy and paste the code into
                                any web page that you want it displayed.
                            </Typography>

                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Field
                                        component={FormikSelect}
                                        name={'button_size'}
                                        variant="outlined"
                                        label="Size"
                                        required
                                        fullWidth
                                        defaultValue={size ? size : 'small'}
                                        onClick={handleSizeChange}
                                    >
                                        {button_sizes.map((button_size) => (
                                            <MenuItem
                                                key={button_size.value}
                                                value={button_size.value}
                                            >
                                                {button_size.name}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        component={FormikSelect}
                                        name={'button_color'}
                                        variant="outlined"
                                        label="Color"
                                        required
                                        fullWidth
                                        defaultValue={color ? color : '#0000FF'}
                                        onClick={handleColorChange}
                                    >
                                        {button_colors.map((button_color) => (
                                            <MenuItem
                                                key={button_color.value}
                                                value={button_color.value}
                                            >
                                                {button_color.name}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </Grid>
                            </Grid>
                            <br />
                            <hr />
                            <Typography component="div">
                                <h3>Your button.</h3>
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs={3}>
                                    <Button
                                        variant="contained"
                                        size={size}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        className={handleButtonClass(
                                            color,
                                            size
                                        )}
                                    >
                                        INVEST NOW!
                                    </Button>
                                </Grid>
                                <Grid item xs={9}>
                                    <Field
                                        component={TextField}
                                        name="button_style"
                                        type="text"
                                        rows={1}
                                        multiline
                                        fullWidth
                                        defaultValue={
                                            "<button type='button' class='" +
                                            handleButtonClassValue(
                                                color,
                                                size
                                            ) +
                                            "'>INVEST NOW!</button>"
                                        }
                                        inputProps={{ readOnly: true }}
                                    />
                                </Grid>
                            </Grid>

                            <FundButtonGroup
                                previousStep={previousStep}
                                closeDialog={closeDialog}
                                last={true}
                            />
                        </Form>
                    </Container>
                )}
            </Formik>
        </div>
    );
};

export default Buttons;
