import React, { useState } from 'react';
import axios from 'axios';
import * as config from './../../../config';
import { Formik, Form } from 'formik';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import authStyles from '../authStyles';
/*import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { withStyles } from '@mui/material/styles';
*/
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Grid from '@mui/material/Grid';
// import CardHeader from '@mui/material/CardHeader';
import { useSnackbar } from 'notistack';

let basic = [
    {
        title: 'CAP TABLE MANAGEMENT',
        description: 'Easily create and manage your digital cap table',
    },

    {
        title: 'ASSET CLASSES',
        description: 'Issue multiple asset classes',
    },
    {
        title: 'VESTING',
        description: 'Program complicated vesting schedules',
    },
];
let pro = [
    {
        title: 'DIGITAL ASSETS',
        description: 'Digital asset issuances and wallet creation',
    },
    {
        title: 'SECURE YOUR TICKER',
        description: 'Secure your unique 5-digit ticker',
    },
    {
        title: 'INVESTOR ACCESS',
        description: 'Onboard investors, including KYC/AML/Accreditation*',
    },
    {
        title: 'ADVANCED ASSET RULES',
        description: 'Smart contract creation and embedded compliance',
    },
    {
        title: 'ADVANCED INVESTOR RIGHTS',
        description: 'Enable voting, dividends and communications',
    },
    {
        title: 'TRANSFERS BETWEEN INVESTORS',
        description: 'Facilitate transfers between current investors',
    },
];

let enterprise = [
    { title: 'FUNDRAISE', description: 'Launch a fundraising campaign' },
    {
        title: 'TRANSFERS TO NEW INVESTORS',
        description:
            'Access a private marketplace of external investors. US Transfer Agent services.',
    },
    {
        title: 'LISTING',
        description: 'Manage listings and ongoing requirements',
    },
    {
        title: 'TRADE',
        description: 'Access secondary markets via exchanges',
    },
    {
        title: 'FUNDS OR DEBT ISSUANCE',
        description: 'Funds (closed or open) and debt instruments',
    },
    {
        title: 'CUSTOM SUPPORT',
        description: 'Premium support with our team of experts',
    },
    {
        title: 'API INTEGRATION',
        description: 'Receive custom data sets suited to your needs',
    },
];

const SubscriptionType = (props) => {
    const classes = authStyles();
    const [subscriptionType, setSubscriptionType] = useState(
        props.initialValues.subscription_type
    );

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    //  const [error, setError] = useState(false);

    const nextStep = () => {
        props.nextStep();
    };

    const changeSubscriptionType = (type) => {
        //setError(false);
        setSubscriptionType(type);
        props.initialValues.subscription_type = type;
    };

    const contactUs = () => {
        let values = {
            account_type: 'issuer',
            first_name: props.initialValues.first_name,
            last_name: props.initialValues.last_name,
            email: props.initialValues.email,
            password: props.initialValues.password,
            confirm_password: props.initialValues.confirm_password,
        };

        // let values = {
        //     n: props.initialValues.first_name + ' ' + props.initialValues.last_name,
        //     e: props.initialValues.email,
        //     i: '',
        //     s: '',
        //     c: 0,
        //     l: window.location.href,
        //     notif: 'connect@digtl.co',
        //     custom_fields: [
        //         { account_type: 'issuer' }
        //     ]
        // };

        closeSnackbar();
        axios
            .post(`${config.API_BASE_URL}/register-contact`, values)
            .then(() => {
                enqueueSnackbar(
                    'Thank you. You will be redirected to our contact page in 5 seconds.',
                    { variant: 'success' }
                );
                setTimeout(() => {
                    window.location = 'https://digtl.co/contact';
                }, 3000);
            })
            .catch((error) => {});
    };

    return (
        <div id="step3">
            <Formik
                initialValues={props.initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    values['subscription_type'] = subscriptionType;
                    props.saveValues(values);
                    nextStep();
                }}
            >
                <Paper className={classes.paper}>
                    <Typography component="div" style={{ textAlign: 'center' }}>
                        <h2>
                            Next, please select the type of account that works
                            for you
                        </h2>
                        <h3>You can always upgrade or downgrade later</h3>
                    </Typography>
                    <Container>
                        <Form className={classes.form} noValidate>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    md={4}
                                    style={{ position: 'relative' }}
                                >
                                    <Paper
                                        elevation={3}
                                        style={{
                                            borderRadius: 15,
                                            padding: 20,
                                            height: '100%',
                                        }}
                                    >
                                        <Typography
                                            component="div"
                                            style={{
                                                padding: '0px',
                                                fontSize: '1.6rem',
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            BASIC
                                        </Typography>

                                        <h1
                                            style={{
                                                color: '#3E9CD9',
                                                fontSize: '50px',
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            $0{' '}
                                            <small
                                                style={{
                                                    color: '#3E9CD9',
                                                    fontSize: '20px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                / mo
                                            </small>
                                        </h1>
                                        <h2
                                            className={
                                                classes.subscriptionTypeTitleDescription
                                            }
                                            style={{ visibility: 'hidden' }}
                                        >
                                            &nbsp;
                                        </h2>

                                        {basic.map((feature, i) => (
                                            <li
                                                style={{
                                                    listStyleType: 'none',
                                                    marginBottom:
                                                        i === basic.length - 1
                                                            ? '3rem'
                                                            : null,
                                                }}
                                                key={i}
                                            >
                                                <div
                                                    className={
                                                        classes.subscriptionTypeTitle
                                                    }
                                                >
                                                    {feature.title}
                                                </div>

                                                <div
                                                    className={
                                                        classes.subscriptionTypeDescription
                                                    }
                                                >
                                                    {feature.description}
                                                </div>
                                                <br />
                                            </li>
                                        ))}
                                        <div
                                            className={classes.actions}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                position: 'absolute',
                                                bottom: 0,
                                                left: '50%',
                                                margin: '-50px 0 0 -50px',
                                            }}
                                        >
                                            <Button
                                                type="submit"
                                                variant="outlined"
                                                className={classes.buttonLabel}
                                                color="primary"
                                                style={{}}
                                                onClick={() =>
                                                    changeSubscriptionType(
                                                        'basic'
                                                    )
                                                }
                                            >
                                                Get Started{' '}
                                                <KeyboardArrowRightIcon />
                                            </Button>
                                        </div>
                                    </Paper>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    md={4}
                                    style={{ position: 'relative' }}
                                >
                                    <Paper
                                        elevation={3}
                                        style={{
                                            borderRadius: 15,
                                            padding: 20,
                                            height: '100%',
                                        }}
                                    >
                                        <Typography
                                            component="div"
                                            style={{
                                                padding: '0px',
                                                fontSize: '1.6rem',
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            PROFESSIONAL
                                        </Typography>

                                        <h1
                                            style={{
                                                color: '#3E9CD9',
                                                fontSize: '50px',
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            $99{' '}
                                            <small
                                                style={{
                                                    color: '#3E9CD9',
                                                    fontSize: '20px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                / mo
                                            </small>
                                        </h1>
                                        <h2
                                            className={
                                                classes.subscriptionTypeTitleDescription
                                            }
                                        >
                                            All Basic plan benefits +
                                        </h2>
                                        {pro.map((feature, i) => (
                                            <li
                                                style={{
                                                    listStyleType: 'none',
                                                    marginBottom:
                                                        i === pro.length - 1
                                                            ? '3rem'
                                                            : null,
                                                }}
                                                key={i}
                                            >
                                                <div
                                                    className={
                                                        classes.subscriptionTypeTitle
                                                    }
                                                >
                                                    {feature.title}
                                                </div>

                                                <div
                                                    className={
                                                        classes.subscriptionTypeDescription
                                                    }
                                                >
                                                    {feature.description}
                                                </div>
                                                <br />
                                            </li>
                                        ))}
                                        <div
                                            className={classes.actions}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                position: 'absolute',
                                                bottom: 0,
                                                left: '50%',
                                                margin: '-50px 0 0 -50px',
                                            }}
                                        >
                                            <Button
                                                type="submit"
                                                variant="outlined"
                                                className={classes.buttonLabel}
                                                color="primary"
                                                //onClick={contactUs}
                                                onClick={() =>
                                                    changeSubscriptionType(
                                                        'pro'
                                                    )
                                                }
                                            >
                                                Get Started{' '}
                                                <KeyboardArrowRightIcon />
                                            </Button>
                                        </div>
                                    </Paper>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    md={4}
                                    style={{ position: 'relative' }}
                                >
                                    <Paper
                                        elevation={3}
                                        style={{
                                            borderRadius: 15,
                                            padding: 20,
                                            height: '100%',
                                        }}
                                    >
                                        <Typography
                                            component="div"
                                            style={{
                                                padding: '0px',
                                                fontSize: '1.6rem',
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            ENTERPRISE **
                                        </Typography>

                                        <h1
                                            style={{
                                                color: '#3E9CD9',
                                                fontSize: '50px',
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            250+
                                            <small
                                                style={{
                                                    color: '#3E9CD9',
                                                    fontSize: '20px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                / mo
                                            </small>
                                        </h1>
                                        <h2
                                            className={
                                                classes.subscriptionTypeTitleDescription
                                            }
                                        >
                                            All Basic and Pro plan benefits +
                                        </h2>

                                        {enterprise.map((feature, i) => (
                                            <li
                                                style={{
                                                    listStyleType: 'none',
                                                    marginBottom:
                                                        i ===
                                                        enterprise.length - 1
                                                            ? '3rem'
                                                            : null,
                                                }}
                                                key={i}
                                            >
                                                <div
                                                    className={
                                                        classes.subscriptionTypeTitle
                                                    }
                                                >
                                                    {feature.title}
                                                </div>

                                                <div
                                                    className={
                                                        classes.subscriptionTypeDescription
                                                    }
                                                >
                                                    {feature.description}
                                                </div>
                                                <br />
                                            </li>
                                        ))}
                                        <div
                                            className={classes.actions}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                position: 'absolute',
                                                bottom: 0,
                                                left: '50%',
                                                margin: '-50px 0 0 -50px',
                                            }}
                                        >
                                            <Button
                                                type="button"
                                                variant="outlined"
                                                className={classes.buttonLabel}
                                                color="primary"
                                                onClick={contactUs}
                                            >
                                                Contact Us{' '}
                                                <KeyboardArrowRightIcon />
                                            </Button>
                                        </div>
                                    </Paper>
                                </Grid>
                            </Grid>
                            {/*    <RadioGroup
                            name="subscription_type"
                            row
                            required
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            defaultValue={subscriptionType}
                        >
                            <FormControlLabel
                                value="freemium"
                                control={<Radio color="primary" />}
                                label={
                                    <p
                                        style={{
                                            fontWeight: 900,
                                            marginTop: '40px',
                                            lineHeight: '1.8em',
                                            marginRight: '2em',
                                        }}
                                    >
                                        Basic <br />
                                        $0/mo
                                    </p>
                                }
                                labelPlacement="end"
                                onChange={changeSubscriptionType}
                            />

                            <FormControlLabel
                                value="startup"
                                control={<Radio color="primary" />}
                                label={
                                    <p
                                        style={{
                                            fontWeight: 900,
                                            marginTop: '62px',
                                            lineHeight: '1.8em',
                                            marginRight: '2em',
                                        }}
                                    >
                                        Pro** <br />
                                        $1,000/Setup <br />
                                        +$99/month
                                    </p>
                                }
                                labelPlacement="end"
                                onChange={changeSubscriptionType}
                            />
                            <FormControlLabel
                                value="enterprise"
                                control={<Radio color="primary" />}
                                label={
                                    <p
                                        style={{
                                            fontWeight: 900,
                                            marginTop: '62px',
                                            lineHeight: '1.8em',
                                        }}
                                    >
                                        Enterprise** <br />
                                        $1,000/Setup <br />
                                        +$99/month
                                    </p>
                                }
                                labelPlacement="end"
                                onChange={changeSubscriptionType}
                            />
                        </RadioGroup>
                        {error === true ? (
                            <Typography
                                component="div"
                                style={{ color: '#ED7571', textAlign: 'center' }}
                            >
                                Please select account type
                            </Typography>
                        ) : null}*/}

                            <div className={classes.subscriptionTypeFooter}>
                                * Any direct costs associated with KYC, AML and
                                Accreditation are additional.
                                <br />
                                ** Separate pricing depending on needs.
                            </div>

                            {/*    <div
                            className={classes.actions}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                //onClick={nextStep}
                            >
                                {' '}
                                ONBOARD NOW
                            </Button>
                        </div>*/}
                        </Form>
                    </Container>
                </Paper>
            </Formik>
        </div>
    );
};

export default SubscriptionType;
