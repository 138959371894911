import request from '../../utilities/request';
import * as config from '../../config';
import * as sponsorAdvisorUpdateTypes from './sponsorAdvisorUpdateTypes';

export const getSponsorAdvisorUpdatesSuccess = (summary) => {
    return {
        type: sponsorAdvisorUpdateTypes.GET_SPONSOR_ADVISOR_UPDATES_SUCCESS,
        payload: summary,
    };
};

export const setSponsorAdvisorUpdatesLoading = (loading) => {
    return {
        type: sponsorAdvisorUpdateTypes.SET_SPONSOR_ADVISOR_UPDATES_LOADING,
        payload: loading,
    };
};

export const getSponsorAdvisorUpdates = () => {
    return (dispatch) => {
        dispatch(setSponsorAdvisorUpdatesLoading(true));
        request
            .get(`${config.API_BASE_URL}/sponsor-advisor-updates`)
            .then((res) => {
                dispatch(getSponsorAdvisorUpdatesSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setSponsorAdvisorUpdatesLoading(false));
            });
    };
};
