import useSWR from "swr"
import { API_BASE_URL } from "../../config"
import fetcher from "../../utilities/fetcher"

const useLBTCBalance = (investor_id, holder_id) => {
    const { data, error, isValidating, mutate } = useSWR(() => investor_id && holder_id ? `${API_BASE_URL}/blockchain/${investor_id}/sub-accounts/${holder_id}/lbtc-balance` : null, fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    })

    return {
        lbtcBalance: data || [],
        lbtcBalanceLoading: !error && !data,
        lbtcBalanceError: error,
        lbtcBalanceValidating: isValidating,
        updateLBTCBalance: () => mutate()
    }
}

export default useLBTCBalance