import * as fundingTypes from './fundingTypes';

const initialState = {
    data: null,
    loading: true,
};

const fundingReducer = (state = initialState, action) => {
    switch (action.type) {
        case fundingTypes.GET_FUNDING_SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case fundingTypes.SET_FUNDING_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        case fundingTypes.GET_OFFERINGS_SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case fundingTypes.SET_OFFERINGS_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        default:
            return state;
    }
};

export default fundingReducer;
