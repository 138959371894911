import React from 'react';
import * as config from './../../../config';
import request from '../../../utilities/request';
import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import HelpTooltip from '../../../components/HelpTooltip/HelpTooltip';
import { Formik, Form, Field } from 'formik';
import TextField from '../../../components/TextField/TextField';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as capTableActions from '../../../store/capTable/capTableActions';
import authStyles from '../authStyles';
import { parseError } from '../../../utilities/utils';
const EditAuthorizedSharesDialog = (props) => {
    const { classData, dialogIsOpen, setDialogIsOpen } = props;
    const classes = authStyles();
    const { enqueueSnackbar } = useSnackbar();

    const closeDialog = () => {
        setDialogIsOpen(false);
    };
    return (
        <Dialog maxWidth="sm" fullWidth open={dialogIsOpen}>
            <DialogTitle className={classes.dialogTitle}>
                Edit Class Authorized Assets
            </DialogTitle>
            <DialogContent>
                <Typography component="h2" style={{ color: '#ED7571' }}>
                    <b>
                        {' '}
                        Are you sure that you want to edit amount of authorized assets for class {classData?.edit?.class_description??null}?
                    </b>
                </Typography>
                <Grid container justifyContent="center">
                    <Grid item xs={12} md={12}>
                        <Formik
                            initialValues={{authorized_shares: classData.assets}}
                            onSubmit={(values, { setSubmitting }) => {
                                classData['authorized_shares'] = values['authorized_shares'] ? values['authorized_shares'] : null;
                                request
                                    .patch(
                                        `${config.API_BASE_URL}/share-classes/edit/authorized-assets`, classData
                                    )
                                    .then((response) => {
                                        enqueueSnackbar(
                                            'Class Authorized Assets have been updated successfully',
                                            {
                                                variant: 'success',
                                            }
                                        );
                                        
                                        props.capTableActions.getClasses();
                                        setDialogIsOpen(false);
                                        
                                    })
                                    .catch((error) => {
                                        enqueueSnackbar(
                                            parseError(error),
                                            {
                                                variant:'error',
                                            }
                                        );
                                        props.capTableActions.getClasses();
                                        setSubmitting(false);
                                    });
                                
                            }}
                        >
                            {({ isSubmitting }) => (
                                <Container>
                                    <Form className={classes.form} noValidate>
                                        <Field
                                            component={
                                                TextField
                                            }
                                            type="text"
                                            numeric={1}
                                            name="authorized_shares"
                                            variant="outlined"
                                            margin="normal"
                                            label={
                                                <div>
                                                    Authorized Assets
                                                    <HelpTooltip
                                                        color="#3E9CD9"
                                                        title="Authorized assets refer to the total number of assets of stock that a company is legally authorized to issue."
                                                    />
                                                </div>
                                            }
                                            InputLabelProps={{
                                                style: {
                                                    pointerEvents:
                                                        'auto',
                                                },
                                            }}
                                            placeholder={
                                                '0.00'
                                            }
                                            fullWidth
                                        />
                                        <DialogActions
                                            className={classes.actions}
                                        >
                                            <Button
                                                color="secondary"
                                                variant="outlined"
                                                className={
                                                    classes.secondaryButtonLabel
                                                }
                                                onClick={() => {
                                                    closeDialog();
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="submit"
                                                variant="outlined"
                                                className={classes.buttonLabel}
                                                color="primary"
                                                disabled={isSubmitting}
                                            >
                                                Save
                                            </Button>
                                        </DialogActions>
                                    </Form>
                                </Container>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
const mapStateToProps = ({ profile, capTable, category_loading }) => {
    return {
        profile: profile,
        classes: capTable.classes.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        capTableActions: bindActionCreators(capTableActions, dispatch),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditAuthorizedSharesDialog);
