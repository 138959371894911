import React, { useState } from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import authStyles from '../authStyles';
import * as config from './../../../config';
import InputLabel from '@mui/material/InputLabel';
import { useDispatch } from 'react-redux';

import { snackbar } from '../../../utilities/snackbarUtils';
import LegalForm from './LegalForm';

import { handleLogin } from '../../../utilities/login';
import { SUBSCRIPTION_PRO } from '../../../constants/profile';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as paymentActions from '../../../store/payment/paymentActions';
import { upgradeSubscription } from '../../../utilities/payments';

const InvestorRegistrationSuccess = (props) => {
    const classes = authStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { payment, paymentActions } = props;

    return (
        <Formik
            initialValues={props.initialValues}
            onSubmit={(values, { setSubmitting }) => {
                if (
                    props.initialValues.subscription_type === SUBSCRIPTION_PRO
                ) {
                    snackbar.success(
                        'You will be redirected to finalize your payment in 3 seconds.'
                    );
                    setTimeout(() => {
                        upgradeSubscription(
                            payment,
                            paymentActions,
                            props.initialValues.subscription_type.toUpperCase(),
                            values['email'],
                            values['password']
                        );
                    }, 3000);
                } else {
                    handleLogin(
                        values['email'],
                        values['password'],
                        history,
                        dispatch
                    );
                }
            }}
        >
            {({ isSubmitting }) => (
                <Paper className={classes.paper}>
                    <Typography component="div" style={{ textAlign: 'center' }}>
                        <h2>Email confirmation</h2>
                    </Typography>
                    <Typography component="p" variant="body1">
                        Please check your email to proceed to the next step of
                        Investor registration. If you don’t receive any email
                        from us within the next 5 minutes, please check your
                        Spam folder.
                    </Typography>
                    <Container maxWidth="sm">
                        <Form className={classes.form} noValidate>
                            <div
                                className={classes.actions}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Button
                                    type="submit"
                                    variant="outlined"
                                    className={classes.buttonLabel}
                                    color="primary"
                                    disabled={isSubmitting}
                                    // onClick={nextStep}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    {props.initialValues.subscription_type ===
                                    SUBSCRIPTION_PRO
                                        ? 'Finalize Payment & Login as Issuer'
                                        : 'Issuer Login'}
                                </Button>
                                <Button
                                    // type="submit"
                                    variant="outlined"
                                    className={classes.secondaryButtonLabel}
                                    color="secondary"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? (
                                        <CircularProgress size={30} />
                                    ) : (
                                        'Investor Login'
                                    )}
                                </Button>
                            </div>
                        </Form>
                    </Container>
                </Paper>
            )}
        </Formik>
    );
};

const Complete = (props) => {
    const classes = authStyles();

    const [showLegalForm, setShowLegalForm] = useState(false);
    const [agree, setAgree] = useState(false);
    const [
        investorRegistrationSuccess,
        setInvestorRegistrationSuccess,
    ] = useState(false);
    const { payment, paymentActions } = props;

    const onShowLegalForm = (e) => {
        e.preventDefault();
        setShowLegalForm(true);
        setAgree(false);
    };

    const onAgree = (e) => {
        setAgree(true);
        setShowLegalForm(false);
    };

    const handleInvestorSignup = (name, email, password, setSubmitting) => {
        setSubmitting(true);
        axios
            .post(`${config.API_BASE_URL}/ipo-register`, {
                email: email,
                password: password,
                password_confirmation: password,
                name: name,
            })
            .then((response) => {
                snackbar.success('Investor account created.');
                setInvestorRegistrationSuccess(true);
            })
            .catch((error) => {
                snackbar.error(
                    'There has been an error creating your account. Please try again.'
                );
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <div id="step12">
            {investorRegistrationSuccess ? (
                <InvestorRegistrationSuccess
                    initialValues={props.initialValues}
                    payment={payment}
                    paymentActions={paymentActions}
                />
            ) : (
                <Formik
                    initialValues={props.initialValues}
                    onSubmit={(values, { setSubmitting }) => {
                        handleInvestorSignup(
                            `${values['first_name']} ${values['last_name']}`,
                            values['email'],
                            values['password'],
                            setSubmitting
                        );
                    }}
                >
                    {({ isSubmitting }) => (
                        <Paper className={classes.paper}>
                            <Typography
                                component="div"
                                style={{ textAlign: 'center' }}
                            >
                                <h2>Investor Account Creation</h2>
                            </Typography>
                            <Container maxWidth="sm">
                                <Form className={classes.form} noValidate>
                                    <InputLabel
                                        style={{
                                            color: '#666',
                                            fontSize: '1rem',
                                            textAlign: 'center',
                                        }}
                                    >
                                        Your Investor account will use the same
                                        credentials as the Issuer account.
                                    </InputLabel>
                                    <Switch
                                        color="primary"
                                        onClick={(e) => {
                                            setAgree(e.target.checked);
                                        }}
                                        value={agree}
                                        checked={agree}
                                    />
                                    I accept all the{' '}
                                    <span
                                        className="link no-decoration"
                                        onClick={onShowLegalForm}
                                    >
                                        Legal Undertakings
                                    </span>
                                    <div
                                        className={classes.actions}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            variant="outlined"
                                            className={classes.buttonLabel}
                                            color="primary"
                                            disabled={isSubmitting || !agree}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {isSubmitting ? (
                                                <CircularProgress size={30} />
                                            ) : (
                                                'Create Investor account'
                                            )}
                                        </Button>
                                    </div>
                                </Form>
                            </Container>
                        </Paper>
                    )}
                </Formik>
            )}

            <LegalForm
                show={showLegalForm}
                onSuccess={onAgree}
                onCancel={() => {
                    setShowLegalForm(false);
                    setAgree(false);
                }}
            />
        </div>
    );
};

const mapStateToProps = ({ payment }) => {
    return {
        payment: payment,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        paymentActions: bindActionCreators(paymentActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Complete);
