import React, { useState } from 'react';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';

import TabPanel from '../../components/TabPanel';
import SubMenu from '../../components/SubMenu';
import Overview from '../../pages/issuer/Fundraise/Overview';
import DataRoom from '../../pages/issuer/Fundraise/DataRoom';
import Templates from '../../pages/issuer/Fundraise/Templates';
import VerifiedPartners from '../../pages/issuer/Fundraise/VerifiedPartners';
import FundingOptions from '../../pages/issuer/Fundraise/FundingOptions';
//import Offerings from '../../pages/issuer/Fundraise/Offerings';
import RegisteredInterests from '../../pages/issuer/Dashboard/RegisteredInterests';
import VerticalContainer from '../../components/VerticalContainer';
import { FUNDRAISE_ROUTES } from './routeList';

const FundraiseRoutes = (props) => {
    let { path } = useRouteMatch();
    const location = useLocation();

    const getActiveTab = () => {
        let index = FUNDRAISE_ROUTES.indexOf(location.pathname);
        return index >= 0 ? index : 0;
    };

    const tabs = [
        {
            label: 'Overview',
            path: `${path}`,
            display: true,
        },

        {
            label: 'Data Room',
            path: `${path}/data-room`,
            display: true,
        },
        {
            label: 'Templates',
            path: `${path}/templates`,
            display: true,
        },
        {
            label: 'Registered Interests',
            path: `${path}/registered-interests`,
            display: true,
        },
        /*{
            label: 'My Republic',
            path: `${path}/offerings`,
        },*/
        // {
        //     label: 'Verified Partners',
        //     path: `${path}/verified-partners`,
        // },
        // {
        //     label: 'Funding Options',
        //     path: `${path}/funding-options`,
        // },
    ];

    const [value, setValue] = useState(getActiveTab());
    return (
        <VerticalContainer>
            <SubMenu
                orientation="vertical"
                value={value}
                onChange={(e, v) => setValue(v)}
                tabs={tabs}
                title="Fundraise"
            />
            <TabPanel>
                <Switch>
                    <Route exact path={`${path}/`} component={Overview} />
                    <Route
                        exact
                        path={`${path}/data-room`}
                        component={DataRoom}
                    />
                    <Route
                        exact
                        path={`${path}/templates`}
                        component={Templates}
                    />
                    <Route
                        exact
                        path={`${path}/verified-partners`}
                        component={VerifiedPartners}
                    />
                    <Route
                        exact
                        path={`${path}/funding-options`}
                        component={FundingOptions}
                    />
                    <Route
                        exact
                        path={`${path}/registered-interests`}
                        component={RegisteredInterests}
                    />
                    {/*<Route
                        exact
                        path={`${path}/offerings`}
                        component={Offerings}
                    />*/}
                </Switch>
            </TabPanel>
        </VerticalContainer>
    );
};

export default FundraiseRoutes;
