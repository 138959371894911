import makeStyles from '@mui/styles/makeStyles';

const subMenuStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: 224,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        marginRight: 20,
        overflow: 'visible'
    },
}));

export default subMenuStyles