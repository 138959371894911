import makeStyles from '@mui/styles/makeStyles';

const helpTooltipStyles = makeStyles((theme) => ({
    customToolTip: {
        maxWidth: 300,
        fontSize: '.8em',
        padding: 10,
        fontWeight: 'normal',
    },
    toolTipIns: {
        fontSize: '10px',
        width: '80px',
        right: '30px',
        background: 'transparent !important',
        fontStyle: 'italic',
        position: 'absolute',
        top: '10px',
        color: '#666',
        display: 'none',
        textTransform: 'capitalize',
        textAlign: 'right',
    },
}));

export default helpTooltipStyles;
