import React /*, { useState }*/ from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Container from '@mui/material/Container';
import TextField from '../../../../components/TextField/TextField';
import authStyles from '../../authStyles';
import fundraiseOfferingStyles from '../../../../components/styles/fundraiseOfferingStyles';
import * as registry from '../../../../utilities/registry';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormikSelect from '../../../../components/Formik/FormikSelect';
import HelpTooltip from '../../../../components/HelpTooltip/HelpTooltip';
import Grid from '@mui/material/Grid';
import FundButtonGroup from '../FundButtonGroup';
const validationSchema = Yup.object({
    fast_symbol: Yup.string()
        .required(
            'The FAST Symbol you select must be between 2-8 characters and can only contain alphanumeric characters.'
        )
        .min(
            2,
            'FAST Symbol is too short — must contain at least 2 characters.'
        )
        .max(8, 'FAST Symbol is too long — can contain at most 8 characters.')
        .matches(
            /^([a-zA-Z0-9]+)$/,

            'FAST Symbol can only contain alphanumeric characters'
        ),
    issuer_name: Yup.string().required('Please enter issuer name'),
    ein: Yup.string().required('Please enter ein'),

    year_founded: Yup.string().required('Please enter year founded'),
    contact_name: Yup.string().required('Please enter contact name'),
    title: Yup.string().required('Please enter title'),
    street_address: Yup.string().required('Please enter street address'),
    apartment: Yup.string().required('Please enter apartment'),
    city: Yup.string().required('Please enter city'),
    state: Yup.string().required('Please enter state'),
    zip_code: Yup.string().required('Please enter zip code'),
    email: Yup.string()
        .email('Please enter a valid email address')
        .required('Please enter your email address'),
    phone_number: Yup.string().required('Please enter phone number'),
});
const Issuer = (props) => {
    const classes = authStyles();
    const offeringClasses = fundraiseOfferingStyles();

    let issuer_types = [
        { name: 'LLC', value: 'LLC' },
        { name: 'C-Corp', value: 'C-Corp' },
        { name: 'S-Corp', value: 'S-Corp' },
        { name: 'LLP', value: 'LLP' },
        { name: 'Sole Proprietorship', value: 'Sole Proprietorship' },
        { name: 'Non-US Business', value: 'Non-US Business' },
    ];

    const nextStep = () => {
        props.nextStep();
    };

    const previousStep = () => {
        props.previousStep();
    };

    const closeDialog = () => {
        props.closeDialog();
    };

    return (
        <div id="step2">
            <Formik
                initialValues={props.initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    props.saveValues(values);
                    nextStep();
                    props.changeKey();
                }}
            >
                {({ setFieldValue }) => (
                    <Container maxWidth="lg">
                        <Form className={classes.form} noValidate>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Name"
                                        name="issuer_name"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <Field
                                        component={FormikSelect}
                                        name="country"
                                        variant="outlined"
                                        label="Country"
                                        fullWidth
                                        inputProps={{
                                            id: 'country',
                                        }}
                                        required
                                        defaultValue={
                                            registry.countries[0].name
                                        }
                                    >
                                        {registry.countries.map((c) => (
                                            <MenuItem key={c.name} value={c.id}>
                                                {c.name}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </Grid>
                            </Grid>
                            <Field
                                component={FormikSelect}
                                name="issuer_type"
                                variant="outlined"
                                label="Issuer is a"
                                required
                                fullWidth
                                defaultValue={
                                    props.initialValues.issuer_type
                                        ? props.initialValues.issuer_type
                                        : 'LLC'
                                }
                            >
                                {issuer_types.map((issuer_type) => (
                                    <MenuItem
                                        key={issuer_type.name}
                                        value={issuer_type.name}
                                    >
                                        {issuer_type.name}
                                    </MenuItem>
                                ))}
                            </Field>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="EIN"
                                        name="ein"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                {/*<Grid item xs={4}>
                                    <InputLabel
                                        shrink
                                        htmlFor="incorporation_state"
                                    >
                                        State of Incorporation
                                    </InputLabel>
                                    <Field
                                        component={FormikSelect}
                                        name="incorporation_state"
                                        variant="outlined"
                                        label="State of Incorporation"
                                        fullWidth
                                        inputProps={{
                                            id: 'incorporation_state',
                                        }}
                                        required
                                        defaultValue={
                                            registry.countries[0].name
                                        }
                                    >
                                        {registry.countries.map((c) => (
                                            <MenuItem key={c.name} value={c.id}>
                                                {c.name}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </Grid>*/}
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Year Founded"
                                        name="year_founded"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <hr className={offeringClasses.horizontalLine} />
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Contact Name"
                                        name="contact_name"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Title"
                                        name="title"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="email"
                                        label="Email"
                                        name="email"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Phone"
                                        name="phone_number"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <hr className={offeringClasses.horizontalLine} />
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Street Address"
                                        name="street_address"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Apartment, Suite or Unit Number"
                                        name="apartment"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                        numeric={1}
                                    />{' '}
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="City"
                                        name="city"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="State"
                                        name="state"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        label="Zip Code"
                                        name="zip_code"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <hr className={offeringClasses.horizontalLine} />
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <InputLabel shrink htmlFor="fast_symbol">
                                        Fast Symbol
                                        <HelpTooltip title="The FAST Symbol you select must be between 2-8 characters and can only contain alphanumeric characters." />
                                    </InputLabel>
                                    <Field
                                        component={TextField}
                                        type="text"
                                        name="fast_symbol"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <FundButtonGroup
                                previousStep={previousStep}
                                closeDialog={closeDialog}
                            />
                        </Form>
                    </Container>
                )}
            </Formik>
        </div>
    );
};

export default Issuer;
