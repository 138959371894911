import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import authStyles from '../authStyles';
import { useSnackbar } from 'notistack';
import * as config from './../../../config';
import request from '../../../utilities/request';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import FormikSelect from '../../../components/Formik/FormikSelect';
import MenuItem from '@mui/material/MenuItem';
import merj from '../../../assets/img/merj.png';
import gsx from '../../../assets/img/gsx.png';
import fusang from '../../../assets/img/fusang.png';
import withStyles from '@mui/styles/withStyles';
import Switch from '@mui/material/Switch';
import ContactsIcon from '@mui/icons-material/Contacts';
import DraftsIcon from '@mui/icons-material/Drafts';
import PersonIcon from '@mui/icons-material/Person';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import TodayIcon from '@mui/icons-material/Today';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import TextField from '../../../components/TextField/TextField';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sponsorAdvisorUpdateActions from '../../../store/sponsorAdvisorUpdate/sponsorAdvisorUpdateActions';
import * as disclosureRequirementActions from '../../../store/disclosureRequirement/disclosureRequirementActions';
import HelpTooltip from '../../../components/HelpTooltip/HelpTooltip';
import EditDisclosureRequirementsDialog from './EditDisclosureRequirementsDialog';
import { DateFormatter } from '../../../utilities/formatters';
import * as Yup from 'yup';

import FormikDatePicker from '../../../components/Formik/FormikDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import PaymentDateIcon from '@mui/icons-material/InsertInvitation';
import FinalizationDateIcon from '@mui/icons-material/EventAvailable';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import listTemplateStyles from '../../../components/styles/listTemplateStyles';
import LockedTile from '../../../components/LockedTile';
import { SUBSCRIPTION_ENTERPRISE } from '../../../constants/profile';

const BlueSwitch = withStyles({
    switchBase: {
        color: '#3E9CD9',
        '&$checked': {
            color: '#3E9CD9',
        },
        '&$checked + $track': {
            backgroundColor: '#3E9CD9',
        },
    },
    checked: {},
    track: {},
})(Switch);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            color: '#3E9CD9',
        },
    },
}))(TableRow);

let formikInitialValues = {};

const dividendValidationSchema = Yup.object({
    dividend_gross_amount_per_share: Yup.string().required(
        'This field is required'
    ),
});
/*
const keyInputsValidationSchema = Yup.object({
    board: Yup.string().required('This field is required'),
    ticket: Yup.string().required('This field is required'),
});
*/

const StyledButton = withStyles((theme) => ({
    label: {
        width: '100%',
        display: 'inherit',
        alignItems: 'inherit',
        justifyContent: 'initial',
    },
}))(Button);
const DisclosureRequirements = (props) => {
    const classes = authStyles();
    const listClasses = listTemplateStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    let demo;
    let listing;
    try {
        demo = props.profile.demo;
        listing = props.profile.scope === 'list';
    } catch (e) {
        demo = null;
        listing = null;
    }

    let [exchange, setExchange] = useState('MERJ');

    const [sentHistoryDialog, setSentHistoryDialog] = useState(false);
    const [sentHistoryData, setSentHistoryData] = useState([]);
    const [sponsorAdvisorDialog, setSponsorAdvisorDialog] = useState(false);
    const [sponsorAdvisorData, setSponsorAdvisorData] = useState([]);
    const [key, setKey] = useState(0);

    const [editDialog, setEditDialog] = useState(false);
    const [editData, setEditData] = useState([]);
    const [inputType, setInputType] = useState('date');
    const [dialogTitle, setDialogTitle] = useState(null);
    const [fieldLabel, setFieldLabel] = useState(null);
    const [initialValues, setInitialValues] = useState({});

    const [editDividendDialog, setEditDividendDialog] = useState(false);
    const [dividendInitialValues, setDividendInitialValues] = useState([]);
    const [finalizationDate, setFinalizationDate] = useState();
    const [recordDate, setRecordDate] = useState();
    const [paymentDate, setPaymentDate] = useState();
    const [isEditedDividend, setIsEditedDividend] = useState(false);
    const [isDividendUpdateAvailable, setIsDividendUpdateAvailable] = useState(
        false
    );
    const [isSubmittingDividend, setIsSubmittingDividend] = useState(false);
    const [quarter, setQuarter] = useState('Q1');

    const [editKeyInputsDialog, setEditKeyInputsDialog] = useState(false);
    const [keyInputsInitialValues, setKeyInputsInitialValues] = useState([]);
    const [listingDate, setListingDate] = useState();

    const [dividendQuarterDialog, setDividendQuarterDialog] = useState(false);

    useEffect(props.sponsorAdvisorUpdateActions.getSponsorAdvisorUpdates, []);
    useEffect(props.disclosureRequirementActions.getDisclosureRequirements, []);

    const [
        disclosureRequirementData,
        setDisclosureRequirementData,
    ] = useState();

    useEffect(() => {
        if (
            props.disclosureRequirements.data &&
            props.disclosureRequirements.data.data.length
        ) {
            setDisclosureRequirementData(
                props.disclosureRequirements.data.data[0]
            );
        }
    }, [props.disclosureRequirements.data]);

    const [dividendItems, setDividendItems] = useState([]);
    const [requiredItems, setRequiredItems] = useState([]);
    const [currentReportUpdatesItems, setCurrentReportUpdatesItems] = useState(
        []
    );
    const [keyInputItems, setKeyInputItems] = useState([]);
    const [keyInputPriorInfoItems, setKeyInputPriorInfoItems] = useState([]);
    const [keyInputDividendItems, setKeyInputDividendItems] = useState([]);
    const [quarters, setQuarters] = useState([]);
    const [quarterData, setQuarterData] = useState([]);
    const [agmDate, setAgmDate] = useState();
    const [annualFinancialsDate, setAnnualFinancialsDate] = useState();
    const [dividend, setDividend] = useState(true);
    const [/*q1,*/ setQ1] = useState();
    const [/*q2,*/ setQ2] = useState();
    const [/*q3,*/ setQ3] = useState();
    const [/*q4,*/ setQ4] = useState();

    useEffect(() => {
        if (props.disclosureRequirements.data) {
            let dividend_data = [],
                required_data = [],
                current_report_updates_data = [],
                key_inputs_data = [],
                key_inputs_prior_info = [],
                key_inputs_dividends = [],
                quarters_data = [];
            props.disclosureRequirements.data.data.filter((element) => {
                if (element.type === 'dividend') {
                    dividend_data.push(element);
                } else if (element.type === 'required') {
                    required_data.push(element);
                } else if (element.type === 'current_report_updates') {
                    current_report_updates_data.push(element);
                } else if (element.type === 'key_inputs') {
                    key_inputs_data.push(element);
                } else if (element.type === 'key_inputs-prior_info') {
                    key_inputs_prior_info.push(element);
                } else if (element.type === 'key_inputs-dividends') {
                    key_inputs_dividends.push(element);
                } else if (element.type === 'quarters') {
                    quarters_data.push(element);
                }
                return true;
            });
            setDividendItems(dividend_data);
            setRequiredItems(required_data);
            setCurrentReportUpdatesItems(current_report_updates_data);
            setKeyInputItems(key_inputs_data);
            setKeyInputPriorInfoItems(key_inputs_prior_info);
            setKeyInputDividendItems(key_inputs_dividends);
            setQuarters(quarters_data);
            setIsEditedDividend(
                props.disclosureRequirements.data.dividend_edited
            );
            setIsDividendUpdateAvailable(
                props.disclosureRequirements.data.dividend_update
            );
        }
    }, [props.disclosureRequirements.data]);

    const displayEditDialog = (
        value,
        toUpdate,
        inputType,
        dialogTitle,
        fieldLabel,
        initialValues
    ) => {
        let data = {
            item_value: value,
            item_to_update: toUpdate,
        };

        if (data.item_value) {
            let affected_persons_data = {};
            for (var i = 0; i < data.item_value.length; i++) {
                affected_persons_data['name' + i] = data.item_value[i]['name'];
                affected_persons_data['title' + i] =
                    data.item_value[i]['title'];
            }
            setInitialValues(affected_persons_data);
        }

        setInputType(inputType);
        setFieldLabel(fieldLabel);
        setDialogTitle(dialogTitle);
        setEditData(data);
        //  setInitialValues([]);
        setEditDialog(true);
        setKey(key + 1);
    };

    const displayEditDividendDialog = () => {
        let dividendInitialValues = {};
        for (var i = 0; i < dividendItems.length; i++) {
            dividendInitialValues[dividendItems[i].toUpdate] =
                dividendItems[i].formData;
        }

        setDividendInitialValues(dividendInitialValues);
        setFinalizationDate(dividendInitialValues.dividend_finalization_date);
        setRecordDate(dividendInitialValues.dividend_record_date);
        setPaymentDate(dividendInitialValues.dividend_payment_date);
        setEditDividendDialog(true);
    };

    const displayEditKeyInputsDialog = () => {
        let keyInputsInitialValues = {};

        for (var i = 0; i < keyInputItems.length; i++) {
            keyInputsInitialValues[keyInputItems[i].toUpdate] =
                keyInputItems[i].formData;
        }

        /*      for (i = 0; i < keyInputPriorInfoItems.length; i++) {
            keyInputsInitialValues[keyInputPriorInfoItems[i].toUpdate] =
                keyInputPriorInfoItems[i].formData;
        }
        for (i = 0; i < keyInputDividendItems.length; i++) {
            keyInputsInitialValues[keyInputDividendItems[i].toUpdate] =
                keyInputDividendItems[i].formData;
        }*/

        setKeyInputsInitialValues(keyInputsInitialValues);
        setListingDate(keyInputsInitialValues.listing_date);
        setAgmDate(keyInputsInitialValues.agm_date);
        setAnnualFinancialsDate(keyInputsInitialValues.annual_financials_date);
        /*  setQ1(keyInputsInitialValues.q1);
        setQ2(keyInputsInitialValues.q2);
        setQ3(keyInputsInitialValues.q3);
        setQ4(keyInputsInitialValues.q4);*/

        setEditKeyInputsDialog(true);
    };

    const displayDividendQuarterDetails = (quarter) => {
        if (!Array.isArray(quarters) || typeof quarters[0] === 'undefined') {
            enqueueSnackbar('Something went wrong. Please try later', {
                variant: 'error',
            });
            setTimeout(function () {
                closeSnackbar();
            }, 2000);
        } else {
            switch (quarter) {
                case 'Q1:':
                    setQuarterData(quarters[0].value.q1);
                    break;
                case 'Q2:':
                    setQuarterData(quarters[0].value.q2);
                    break;
                case 'Q3:':
                    setQuarterData(quarters[0].value.q3);
                    break;
                case 'Q4:':
                    setQuarterData(quarters[0].value.q4);
                    break;
                default:
                    setQuarterData([]);
                    break;
            }
            setDividendQuarterDialog(true);
        }
    };

    const changeExchange = (e) => {
        if (e.target.value) {
            setExchange(e.target.value);
        }
    };

    const changeDividend = (e) => {
        setDividend(e.target.value);
    };

    const sendDividendAnnouncement = () => {
        setIsSubmittingDividend(true);
        let values = {
            template_type: 'dividend_announcement',
            action: 'fetch',
            today: new Date().toLocaleDateString(),
        };
        setIsDividendUpdateAvailable(false);
        request
            .post(`${config.API_BASE_URL}/templates/generate`, values)
            .then((response) => {
                enqueueSnackbar(
                    'Information has been sent to listing advisor',
                    {
                        variant: 'success',
                    }
                );
                setIsSubmittingDividend(false);
            });
    };

    const changeQuarter = (e) => {
        setQuarter(e.target.value);
    };

    let imageSrc = merj;
    switch (exchange) {
        case 'fusang':
            imageSrc = fusang;
            break;
        case 'gsx':
            imageSrc = gsx;
            break;
        default:
    }
    /*  let sentHistory = [
        { id: 1, name: 'Board of Directors', date: '11/23/2020' },
    ];*/

    let months = [
        { name: 'January', value: 'January', key: '1' },
        { name: 'February', value: 'February', key: '2' },
        { name: 'March', value: 'March', key: '3' },
        { name: 'April', value: 'April', key: '4' },
        { name: 'May', value: 'May', key: '5' },
        { name: 'June', value: 'June', key: '6' },
        { name: 'July', value: 'July', key: '7' },
        { name: 'August', value: 'August', key: '8' },
        { name: 'September', value: 'September', key: '9' },
        { name: 'October', value: 'October', key: '10' },
        { name: 'November', value: 'November', key: '11' },
        { name: 'December', value: 'December', key: '12' },
    ];
    let updates = [
        {
            title: 'Change to corporate calendar',
            type: 'CORPORATE_CALENDAR',
        },
        {
            title: 'Change of external auditors',
            type: 'EXTERNAL_AUDITORS',
        },
        {
            title: 'Update of Board of Directors',
            type: 'BOARD_OF_DIRECTORS',
        },
        {
            title: 'Change of legal name (or company name)',
            type: 'LEGAL_COMPANY_NAME',
        },
        {
            title:
                'Change of company registration number in its place of Incorporation',
            type: 'REGISTRATION_NUMBER',
        },
        {
            title: 'Change of address of registered office and invoice address',
            type: 'ADDRESS',
        },
        {
            title: 'Change of website address',
            type: 'WEBSITE_ADDRESS',
        },

        {
            title: 'Change in corporate officers',
            type: 'CORPORATE_OFFICERS',
        },
        {
            title: "Company's fiscal year change",
            type: 'FISCAL_YEAR',
        },
        {
            title: 'Change of company secretary',
            type: 'COMPANY_SECRETARY',
        },
        {
            title: 'Change of balance sheet date (close of financial year)',
            type: 'BALANCE_SHEET_DATA',
        },

        {
            title: 'Capitalization issues',
            type: 'CAPITALIZATION_ISSUES',
        },
        { title: 'Right issues', type: 'RIGHT_ISSUES' },

        { title: 'Asset buybacks', change: 'No', type: 'ASSET_BUYBACKS' },
        {
            title: 'Takeovers and reverse takeovers',
            type: 'TAKEOVERS',
        },
        {
            title: 'Event of impending default',
            type: 'EVENT_OF_IMPENDING_DEFAULT',
        },
        {
            title: 'Any fundamental change in the business',
            type: 'ANY_FUNDAMENTAL_CHANGE',
        },
        {
            title: 'Other',
            type: 'OTHER',
        },
    ];

    let helptooltips = {
        annual_financials: (
            <div>
                The issuer post post upon publication of the report, but in any
                case, within the following deadlines:
                <br />
                <ul>
                    <li>
                        Annual report: 4 months following the closing date for
                        the financial year.
                    </li>
                    <li>
                        Interim report: 3 months from the end of the applicable
                        period.
                    </li>
                </ul>
            </div>
        ),
        affected_persons: (
            <div>
                In terms of Section 4.10 of the MERJ Listing Requirements, an
                issuer will be in a "closed period" for the trading of its
                assets during the period where the financial results are being
                compiled until their release.
                <br />
                <br />
                During this period, all affected persons are prohibited from
                dealing in the Company’s assets, as it could be implied that
                they may be aware of unpublished price-sensitive information.
                Unpublished price-sensitive information is information affecting
                a company which if released is likely to materially affect the
                company’s asset price.
                <br />
                <br />
                People considerd "affected" are as follows:
                <br />
                1. any officer or other person employed by the issuer in a
                managerial or executive capacity; or
                <br />
                2. any officer or person employed in the finance department of
                the issuer and the finance department of the issuer's
                accountant; or
                <br />
                3. the Company’s board of directors; or
                <br />
                4. any officer or other person employed by the issuer's Sponsor
                Advisor, external auditors or external Company Secretary who
                works on the Company’s account;
                <br />
                5. any of the following in relation to any of the persons
                prescribed in 1) through 4) – an associated person and that
                person’s spouse
            </div>
        ),
    };

    let defaultSwitchValues = [];
    updates.map((update) => {
        defaultSwitchValues.push({ [update.type]: false });
        return true;
    });

    const [switchValues /*, setSwitchValues*/] = useState(defaultSwitchValues);

    const closeDialog = () => {
        setKey(key + 1);
        setSentHistoryDialog(false);
        setSponsorAdvisorDialog(false);
        setEditDividendDialog(false);
        setEditKeyInputsDialog(false);
    };

    const displaySentHistoryDialog = (data) => {
        request
            .get(
                `${config.API_BASE_URL}/sponsor-advisor-updates/type/` +
                    data['type'] +
                    `?date=` +
                    data['created_at']
            )
            .then((response) => {
                if (response.data.length > 0) {
                    data['after_update'] = response.data[0].value;
                    if (response.data[1]) {
                        data['before_update'] = response.data[1].value;
                    }
                } else {
                    data['after_update'] = '';
                    data['before_update'] = '';
                }
                setSentHistoryData(data);
                setSentHistoryDialog(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const displaysponsorAdvisorDialog = (target, data) => {
        /*  setSwitchValues({
            ...switchValues,
            [target.name]: target.checked,
        });*/
        request
            .get(
                `${config.API_BASE_URL}/sponsor-advisor-updates/type/` +
                    data['type']
            )
            .then((response) => {
                if (response.data.length > 0) {
                    data['value'] = response.data[0].value;
                } else {
                    data['value'] = '';
                }
                setSponsorAdvisorData(data);
                setSponsorAdvisorDialog(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleDateChange = (date, type) => {
        let newDate = DateFormatter({
            value: date,
            format: 'date',
        });

        switch (type) {
            case 'dividend_finalization_date':
                setFinalizationDate(newDate);
                break;
            case 'dividend_record_date':
                setRecordDate(newDate);
                break;
            case 'dividend_payment_date':
                setPaymentDate(newDate);
                break;
            case 'listing_date':
                setListingDate(newDate);
                break;
            case 'agm_date':
                setAgmDate(newDate);
                break;
            case 'annual_financials_date':
                setAnnualFinancialsDate(newDate);
                break;
            case 'q1':
                setQ1(newDate);
                break;
            case 'q2':
                setQ2(newDate);
                break;
            case 'q3':
                setQ3(newDate);
                break;
            case 'q4':
                setQ4(newDate);
                break;
            default:
                setPaymentDate(newDate);
                break;
        }
    };

    return (
        <LockedTile requiredPlan={SUBSCRIPTION_ENTERPRISE}>
            <Formik
                initialValues={formikInitialValues}
                onSubmit={(values, { setSubmitting }) => {}}
            >
                <Form>
                    <Typography component="div" style={{ textAlign: 'right' }}>
                        <h3>
                            Listing Status:{' '}
                            <b style={{ color: '#3E9CD9' }}>Active</b>
                        </h3>
                    </Typography>
                    {(demo || listing) &&
                    disclosureRequirementData !== undefined ? (
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6}>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography className={classes.title}>
                                        Required
                                    </Typography>
                                    <List>
                                        {props.disclosureRequirements.data &&
                                            requiredItems.map(
                                                (required_item, i) => (
                                                    <Grid key={i}>
                                                        <ListItem>
                                                            <Grid
                                                                container
                                                                className="flex-left"
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                    sm={6}
                                                                    md={6}
                                                                    className="flex-left"
                                                                >
                                                                    <ListItemText
                                                                        primary={
                                                                            <Typography
                                                                                className={
                                                                                    listClasses.disclosureRequirementsPrimaryList
                                                                                }
                                                                            >
                                                                                {
                                                                                    required_item.title
                                                                                }
                                                                            </Typography>
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={3}
                                                                    sm={3}
                                                                    md={3}
                                                                    className={
                                                                        classes.rightText
                                                                    }
                                                                >
                                                                    <b
                                                                        className={
                                                                            required_item.status ===
                                                                            'OPEN'
                                                                                ? listClasses.open
                                                                                : required_item.status ===
                                                                                  'Completed'
                                                                                ? listClasses.completed
                                                                                : listClasses.disclosureRequirementsPrimaryList
                                                                        }
                                                                    >
                                                                        {(required_item.inputType ===
                                                                            'date' ||
                                                                            required_item.inputType ===
                                                                                'upload') &&
                                                                        required_item.status !==
                                                                            'OPEN'
                                                                            ? DateFormatter(
                                                                                  {
                                                                                      value:
                                                                                          required_item.status,
                                                                                      format:
                                                                                          'date',
                                                                                  }
                                                                              )
                                                                            : required_item.status}
                                                                    </b>{' '}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={1}
                                                                    sm={1}
                                                                    md={1}
                                                                >
                                                                    {required_item.info !==
                                                                        null && (
                                                                        <HelpTooltip
                                                                            title={
                                                                                required_item.info ===
                                                                                'ui_info'
                                                                                    ? helptooltips.annual_financials
                                                                                    : required_item.info ===
                                                                                      'affected_persons'
                                                                                    ? helptooltips.affected_persons
                                                                                    : required_item.info
                                                                            }
                                                                        />
                                                                    )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                    sm={2}
                                                                    md={2}
                                                                    className={
                                                                        listClasses.editItem
                                                                    }
                                                                >
                                                                    <StyledButton
                                                                        type="label"
                                                                        color="primary"
                                                                        size="small"
                                                                        style={{
                                                                            width:
                                                                                '100px',
                                                                        }}
                                                                        disabled={
                                                                            required_item.action ===
                                                                            'Confirmed'
                                                                        }
                                                                        onClick={() =>
                                                                            displayEditDialog(
                                                                                required_item.value,
                                                                                required_item.toUpdate,
                                                                                required_item.inputType,
                                                                                required_item.title,
                                                                                required_item.fieldLabel,
                                                                                required_item.initialValues
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            required_item.action
                                                                        }
                                                                    </StyledButton>
                                                                </Grid>
                                                                {required_item.url && (
                                                                    <span
                                                                        style={{
                                                                            marginTop: -17,
                                                                            marginLeft:
                                                                                '2rem',
                                                                            wordBreak:
                                                                                'break-all',
                                                                        }}
                                                                    >
                                                                        <br />

                                                                        <a
                                                                            href={
                                                                                required_item.value
                                                                            }
                                                                            className={
                                                                                classes.listOverviewLink
                                                                            }
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            {
                                                                                required_item.value
                                                                            }
                                                                        </a>
                                                                    </span>
                                                                )}
                                                            </Grid>
                                                        </ListItem>
                                                        <Grid>
                                                            {required_item.toUpdate ===
                                                                'dates_added_to_ir' && (
                                                                <List>
                                                                    {required_item.dates_to_ir.map(
                                                                        (
                                                                            dates_to_ir,
                                                                            i
                                                                        ) => (
                                                                            <ListItem
                                                                                key={
                                                                                    i
                                                                                }
                                                                            >
                                                                                <Grid
                                                                                    container
                                                                                >
                                                                                    <Grid
                                                                                        item
                                                                                        xs={
                                                                                            7
                                                                                        }
                                                                                        sm={
                                                                                            7
                                                                                        }
                                                                                        md={
                                                                                            7
                                                                                        }
                                                                                    >
                                                                                        <ListItemText
                                                                                            primary={
                                                                                                <Typography
                                                                                                    className={
                                                                                                        listClasses.disclosureRequirementsSecondaryList
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        dates_to_ir.title
                                                                                                    }
                                                                                                </Typography>
                                                                                            }
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        item
                                                                                        xs={
                                                                                            2
                                                                                        }
                                                                                        sm={
                                                                                            2
                                                                                        }
                                                                                        md={
                                                                                            2
                                                                                        }
                                                                                        className={
                                                                                            classes.rightText
                                                                                        }
                                                                                    >
                                                                                        <b
                                                                                            className={
                                                                                                dates_to_ir.status ===
                                                                                                'OPEN'
                                                                                                    ? listClasses.open
                                                                                                    : dates_to_ir.status ===
                                                                                                      'Completed'
                                                                                                    ? listClasses.completed
                                                                                                    : listClasses.disclosureRequirementsPrimaryList
                                                                                            }
                                                                                        >
                                                                                            {(dates_to_ir.inputType ===
                                                                                                'date' ||
                                                                                                dates_to_ir.inputType ===
                                                                                                    'upload') &&
                                                                                            dates_to_ir.status !==
                                                                                                'OPEN'
                                                                                                ? DateFormatter(
                                                                                                      {
                                                                                                          value:
                                                                                                              dates_to_ir.status,
                                                                                                          format:
                                                                                                              'date',
                                                                                                      }
                                                                                                  )
                                                                                                : dates_to_ir.status}
                                                                                        </b>
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        item
                                                                                        xs={
                                                                                            1
                                                                                        }
                                                                                        sm={
                                                                                            1
                                                                                        }
                                                                                        md={
                                                                                            1
                                                                                        }
                                                                                    ></Grid>
                                                                                    <Grid
                                                                                        item
                                                                                        xs={
                                                                                            2
                                                                                        }
                                                                                        sm={
                                                                                            2
                                                                                        }
                                                                                        md={
                                                                                            2
                                                                                        }
                                                                                        className={
                                                                                            classes.rightText
                                                                                        }
                                                                                    >
                                                                                        <StyledButton
                                                                                            color="primary"
                                                                                            size="small"
                                                                                            style={{
                                                                                                width:
                                                                                                    '100px',
                                                                                            }}
                                                                                            disabled={
                                                                                                dates_to_ir.action ===
                                                                                                'Confirmed'
                                                                                            }
                                                                                            onClick={() =>
                                                                                                displayEditDialog(
                                                                                                    dates_to_ir.value,
                                                                                                    dates_to_ir.toUpdate,
                                                                                                    dates_to_ir.inputType,
                                                                                                    dates_to_ir.title,
                                                                                                    dates_to_ir.fieldLabel
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                dates_to_ir.action
                                                                                            }
                                                                                        </StyledButton>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </ListItem>
                                                                        )
                                                                    )}
                                                                </List>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                )
                                            )}
                                    </List>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Paper elevation={3} className={classes.paper}>
                                    <Grid container className="flex-left">
                                        <Grid item xs={9} sm={9} md={9}>
                                            <Typography
                                                className={classes.title}
                                            >
                                                Key Inputs
                                            </Typography>
                                        </Grid>{' '}
                                        <Grid item xs={1} sm={1} md={1}></Grid>
                                        <Grid item xs={2} sm={2} md={2}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'flex-end',
                                                }}
                                            >
                                                <Button
                                                    component="label"
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                    }}
                                                    //disabled={}
                                                    onClick={() =>
                                                        displayEditKeyInputsDialog()
                                                    }
                                                >
                                                    <b>Edit</b>
                                                </Button>
                                            </div>
                                        </Grid>{' '}
                                    </Grid>
                                    <List>
                                        {props.disclosureRequirements.data &&
                                            keyInputItems.map(
                                                (key_input, i) => (
                                                    <ListItem key={i}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={8}
                                                                sm={8}
                                                                md={8}
                                                            >
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography
                                                                            className={
                                                                                listClasses.disclosureRequirementsPrimaryList
                                                                            }
                                                                        >
                                                                            {
                                                                                key_input.title
                                                                            }
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={4}
                                                                sm={4}
                                                                md={4}
                                                                className={
                                                                    classes.rightText
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        key_input.value !==
                                                                        null
                                                                            ? listClasses.disclosureRequirementsPrimaryList
                                                                            : null
                                                                    }
                                                                >
                                                                    {key_input.toUpperCase
                                                                        ? key_input.value
                                                                              .charAt(
                                                                                  0
                                                                              )
                                                                              .toUpperCase() +
                                                                          key_input.value.slice(
                                                                              1
                                                                          )
                                                                        : key_input.value}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </ListItem>
                                                )
                                            )}
                                    </List>
                                    <List>
                                        {props.disclosureRequirements.data &&
                                            keyInputPriorInfoItems.map(
                                                (info, i) => (
                                                    <ListItem key={i}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={8}
                                                                sm={8}
                                                                md={8}
                                                            >
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            component="p"
                                                                            className={
                                                                                listClasses.disclosureRequirementsSecondaryList
                                                                            }
                                                                        >
                                                                            {
                                                                                info.title
                                                                            }
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={4}
                                                                sm={4}
                                                                md={4}
                                                                className={
                                                                    classes.rightText
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        dividend.value !==
                                                                        null
                                                                            ? listClasses.disclosureRequirementsSecondaryList
                                                                            : null
                                                                    }
                                                                >
                                                                    {info.value
                                                                        ? info.value
                                                                        : '-'}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </ListItem>
                                                )
                                            )}
                                    </List>
                                    <List>
                                        {props.disclosureRequirements.data &&
                                            keyInputDividendItems.map(
                                                (dividend, i) => (
                                                    <ListItem key={i}>
                                                        <Grid
                                                            container
                                                            className="flex-left"
                                                        >
                                                            <Grid
                                                                item
                                                                xs={8}
                                                                sm={8}
                                                                md={8}
                                                                className="flex-left"
                                                            >
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            className={
                                                                                listClasses.quartersList
                                                                            }
                                                                        >
                                                                            {
                                                                                dividend.title
                                                                            }
                                                                        </Typography>
                                                                    }
                                                                />

                                                                <div
                                                                    className={
                                                                        dividend.value !==
                                                                        null
                                                                            ? listClasses.disclosureRequirementsPrimaryList
                                                                            : null
                                                                    }
                                                                >
                                                                    {dividend.value
                                                                        ? dividend.value
                                                                        : '-'}
                                                                </div>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={2}
                                                                sm={2}
                                                                md={2}
                                                            ></Grid>
                                                            {dividend.view_more ? (
                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                    sm={2}
                                                                    md={2}
                                                                    className="flex-right"
                                                                >
                                                                    <div
                                                                        style={{
                                                                            textAlign:
                                                                                'left',
                                                                        }}
                                                                    >
                                                                        <StyledButton
                                                                            component="label"
                                                                            color="primary"
                                                                            size="small"
                                                                            style={{
                                                                                display:
                                                                                    'flex',
                                                                                justifyContent:
                                                                                    'center',
                                                                                alignItems:
                                                                                    'center',
                                                                                width:
                                                                                    '100px',
                                                                            }}
                                                                            onClick={() =>
                                                                                displayDividendQuarterDetails(
                                                                                    dividend.title
                                                                                )
                                                                            }
                                                                        >
                                                                            View
                                                                        </StyledButton>
                                                                    </div>{' '}
                                                                </Grid>
                                                            ) : null}
                                                        </Grid>
                                                    </ListItem>
                                                )
                                            )}
                                    </List>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography className={classes.title}>
                                        Current Report updates and Board
                                        Meetings
                                    </Typography>
                                    <List>
                                        {props.disclosureRequirements.data &&
                                            currentReportUpdatesItems.map(
                                                (report_update, i) => (
                                                    <ListItem key={i}>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={7}
                                                                sm={7}
                                                                md={7}
                                                            >
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography
                                                                            className={
                                                                                listClasses.disclosureRequirementsPrimaryList
                                                                            }
                                                                        >
                                                                            {
                                                                                report_update.title
                                                                            }
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                xs={2}
                                                                sm={2}
                                                                md={2}
                                                                className={
                                                                    classes.rightText
                                                                }
                                                            >
                                                                <b
                                                                    className={
                                                                        report_update.status ===
                                                                        'OPEN'
                                                                            ? listClasses.open
                                                                            : report_update.status ===
                                                                              'Completed'
                                                                            ? listClasses.completed
                                                                            : listClasses.disclosureRequirementsPrimaryList
                                                                    }
                                                                >
                                                                    {' '}
                                                                    {report_update.inputType ===
                                                                        'date' &&
                                                                    report_update.status !==
                                                                        'OPEN'
                                                                        ? DateFormatter(
                                                                              {
                                                                                  value:
                                                                                      report_update.status,
                                                                                  format:
                                                                                      'date',
                                                                              }
                                                                          )
                                                                        : report_update.status}
                                                                </b>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={1}
                                                                sm={1}
                                                                md={1}
                                                            ></Grid>
                                                            <Grid
                                                                item
                                                                xs={2}
                                                                sm={2}
                                                                md={2}
                                                            >
                                                                <StyledButton
                                                                    component={
                                                                        report_update.redirect
                                                                            ? NavLink
                                                                            : 'label'
                                                                    }
                                                                    to={
                                                                        report_update.url
                                                                    }
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{
                                                                        width:
                                                                            '100px',
                                                                    }}
                                                                    disabled={
                                                                        report_update.action ===
                                                                        'Confirmed'
                                                                    }
                                                                    onClick={() =>
                                                                        displayEditDialog(
                                                                            report_update.value,
                                                                            report_update.toUpdate,
                                                                            'date',
                                                                            'Next Board Meeting Date',
                                                                            'Select next board meeting date'
                                                                        )
                                                                    }
                                                                >
                                                                    {
                                                                        report_update.action
                                                                    }
                                                                </StyledButton>
                                                            </Grid>
                                                        </Grid>
                                                    </ListItem>
                                                )
                                            )}
                                    </List>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Paper elevation={3} className={classes.paper}>
                                    <Grid container className="flex-left">
                                        <Grid item xs={9} sm={9} md={9}>
                                            <Typography
                                                className={classes.title}
                                            >
                                                Dividend
                                            </Typography>
                                        </Grid>{' '}
                                        <Grid item xs={1} sm={1} md={1}>
                                            {' '}
                                        </Grid>
                                        <Grid item xs={2} sm={2} md={2}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'flex-end',
                                                }}
                                            >
                                                <Button
                                                    component="label"
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        width: '100px',
                                                    }}
                                                    disabled={isEditedDividend}
                                                    onClick={() =>
                                                        displayEditDividendDialog()
                                                    }
                                                >
                                                    <b>Edit</b>
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <ListItem
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        <Grid container>
                                            <Grid item xs={8} sm={8} md={8}>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            className={
                                                                listClasses.disclosureRequirementsPrimaryList
                                                            }
                                                        >
                                                            Next dividend info:
                                                        </Typography>
                                                    }
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={3}
                                                sm={3}
                                                md={3}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'flex-end',
                                                }}
                                            >
                                                <b
                                                    className={
                                                        isEditedDividend
                                                            ? listClasses.completed
                                                            : listClasses.open
                                                    }
                                                >
                                                    {isEditedDividend
                                                        ? 'Completed'
                                                        : 'OPEN'}
                                                </b>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                    <List>
                                        {props.disclosureRequirements.data &&
                                            dividendItems.map((dividend, i) => (
                                                <ListItem key={i}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={7}
                                                            sm={7}
                                                            md={7}
                                                        >
                                                            <ListItemText
                                                                primary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        className={
                                                                            listClasses.disclosureRequirementsSecondaryList
                                                                        }
                                                                    >
                                                                        {
                                                                            dividend.title
                                                                        }
                                                                    </Typography>
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            sm={4}
                                                            md={4}
                                                            className={`${listClasses.dialogSecondaryList} ${classes.rightText}`}
                                                        >
                                                            {dividend.value
                                                                ? dividend.value
                                                                : '-'}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            sm={1}
                                                            md={1}
                                                        >
                                                            {dividend.info !==
                                                                null && (
                                                                <HelpTooltip
                                                                    title={
                                                                        dividend.info
                                                                    }
                                                                />
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            ))}
                                    </List>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        {isSubmittingDividend ? (
                                            <CircularProgress />
                                        ) : (
                                            <Button
                                                component="label"
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                                disabled={
                                                    !isDividendUpdateAvailable
                                                }
                                                onClick={() =>
                                                    sendDividendAnnouncement()
                                                }
                                            >
                                                <b>SUBMIT CHANGES</b>
                                            </Button>
                                        )}
                                    </div>
                                </Paper>
                            </Grid>{' '}
                        </Grid>
                    ) : demo || listing ? (
                        <Grid container item xs={12} justifyContent="center">
                            <CircularProgress />
                        </Grid>
                    ) : null}

                    {!demo && !listing && (
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6}>
                                <Paper elevation={3} className={classes.paper}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <h2 style={{ color: '#3E9CD9' }}>
                                                Exchange
                                            </h2>
                                            <Field
                                                component={FormikSelect}
                                                name="exchange"
                                                variant="outlined"
                                                fullWidth
                                                defaultValue="merj"
                                                label="Exchange"
                                                onClick={changeExchange}
                                            >
                                                <MenuItem
                                                    key={'merj'}
                                                    value={'merj'}
                                                >
                                                    MERJ
                                                </MenuItem>
                                                <MenuItem
                                                    key={'fusang'}
                                                    value={'fusang'}
                                                >
                                                    Fusang
                                                </MenuItem>
                                                <MenuItem
                                                    key={'gsx'}
                                                    value={'gsx'}
                                                >
                                                    GSX
                                                </MenuItem>
                                            </Field>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={6}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {' '}
                                            <img
                                                src={imageSrc}
                                                width={300}
                                                height={180}
                                                alt=""
                                                style={{
                                                    margin: 'auto',
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            style={{ fontSize: '1rem' }}
                                            justifyContent="flex-end"
                                        >
                                            <h4 style={{ color: '#3E9CD9' }}>
                                                Listing Rules
                                            </h4>
                                            <h4 style={{ color: '#3E9CD9' }}>
                                                Directive on Regular Reporting
                                            </h4>
                                            <h4 style={{ color: '#3E9CD9' }}>
                                                Obligations
                                            </h4>
                                            <h4 style={{ color: '#3E9CD9' }}>
                                                Listing Schedule
                                            </h4>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Paper elevation={3} className={classes.paper}>
                                    <Typography component="div">
                                        <h2 style={{ color: '#3E9CD9' }}>
                                            Member Firm
                                        </h2>

                                        <List>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <ContactsIcon
                                                        fontSize="large"
                                                        style={{
                                                            color: '#3E9CD9',
                                                        }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="p"
                                                            style={{
                                                                color:
                                                                    '#3E9CD9',
                                                            }}
                                                        >
                                                            Listing Advisor:
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <b
                                                                style={{
                                                                    fontSize:
                                                                        '1.1rem',
                                                                    color:
                                                                        'black',
                                                                }}
                                                            >
                                                                {demo || listing
                                                                    ? 'DIGTL'
                                                                    : 'No data'}
                                                            </b>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        </List>

                                        <List>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <PersonIcon
                                                        fontSize="large"
                                                        style={{
                                                            color: '#3E9CD9',
                                                        }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="p"
                                                            style={{
                                                                color:
                                                                    '#3E9CD9',
                                                            }}
                                                        >
                                                            Key Contact:
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <b
                                                                style={{
                                                                    fontSize:
                                                                        '1.1rem',
                                                                    color:
                                                                        'black',
                                                                }}
                                                            >
                                                                {demo || listing
                                                                    ? 'Kyle Fry - President'
                                                                    : 'No data'}
                                                            </b>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        </List>

                                        <List>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <DraftsIcon
                                                        fontSize="large"
                                                        style={{
                                                            color: '#3E9CD9',
                                                        }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="body2"
                                                            component="p"
                                                            style={{
                                                                color:
                                                                    '#3E9CD9',
                                                            }}
                                                        >
                                                            Contact email:
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <b
                                                                style={{
                                                                    fontSize:
                                                                        '1.1rem',
                                                                    color:
                                                                        'black',
                                                                }}
                                                            >
                                                                {demo || listing
                                                                    ? 'kyle@digitalmarkets.ai'
                                                                    : 'No data'}
                                                            </b>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        </List>
                                    </Typography>
                                </Paper>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <Paper
                                    elevation={3}
                                    style={{
                                        borderRadius: 15,
                                        padding: 10,
                                        height: '100%',
                                    }}
                                >
                                    <TableContainer
                                        style={{ marginTop: '2rem' }}
                                    >
                                        <Table
                                            className={classes.table}
                                            aria-label="simple table"
                                        >
                                            <TableBody>
                                                {updates.map((row, i) => (
                                                    <TableRow
                                                        style={{
                                                            border: 'none',
                                                        }}
                                                        key={i}
                                                    >
                                                        <TableCell
                                                            className={
                                                                classes.tableCell
                                                            }
                                                        >
                                                            {row.title}
                                                        </TableCell>

                                                        <TableCell
                                                            className={
                                                                classes.tableCell
                                                            }
                                                        >
                                                            <BlueSwitch
                                                                key={key}
                                                                checked={
                                                                    switchValues[
                                                                        row.type
                                                                    ]
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    displaysponsorAdvisorDialog(
                                                                        event.target,
                                                                        row
                                                                    )
                                                                }
                                                                name={row.type}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>{' '}
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Paper
                                    elevation={3}
                                    style={{
                                        borderRadius: 15,
                                        padding: 20,
                                        height: '100%',
                                    }}
                                >
                                    {/*}<Typography>
                                    <h3>Sent History</h3>
                                </Typography>*/}
                                    <h2 style={{ color: '#3E9CD9' }}>
                                        Sent History:
                                    </h2>
                                    <TableContainer>
                                        <Table
                                            style={{ border: 'none' }}
                                            className={classes.table}
                                            aria-label="simple table"
                                        >
                                            <TableBody>
                                                {(demo || listing) &&
                                                props.sponsorAdvisorUpdates
                                                    .data ? (
                                                    props.sponsorAdvisorUpdates.data.map(
                                                        (
                                                            sponosrAdvisorUpdate,
                                                            i
                                                        ) => (
                                                            <StyledTableRow
                                                                key={i}
                                                            >
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                    style={{
                                                                        cursor:
                                                                            'pointer',
                                                                    }}
                                                                    onClick={() => {
                                                                        displaySentHistoryDialog(
                                                                            sponosrAdvisorUpdate
                                                                        );
                                                                    }}
                                                                >
                                                                    {
                                                                        sponosrAdvisorUpdate.title
                                                                    }
                                                                </TableCell>
                                                                <TableCell
                                                                    className={
                                                                        classes.tableCell
                                                                    }
                                                                >
                                                                    {
                                                                        sponosrAdvisorUpdate.created_at
                                                                    }
                                                                </TableCell>
                                                            </StyledTableRow>
                                                        )
                                                    )
                                                ) : (
                                                    <TableRow
                                                        style={{
                                                            fontSize: '1.1rem',
                                                        }}
                                                    >
                                                        <TableCell>
                                                            No data
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>{' '}
                                </Paper>
                            </Grid>
                        </Grid>
                    )}
                    <Dialog
                        maxWidth="sm"
                        fullWidth
                        open={sentHistoryDialog}
                        onBackdropClick={(e) => setSentHistoryDialog(false)}
                    >
                        <DialogTitle style={{ color: '#3E9CD9' }}>
                            Sent History
                            <br />
                        </DialogTitle>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={6} sm={7} md={7}>
                                    <List>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <CreateIcon
                                                    fontSize="large"
                                                    style={{ color: '#3E9CD9' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        variant="body2"
                                                        component="p"
                                                        style={{
                                                            color: '#3E9CD9',
                                                        }}
                                                    >
                                                        Title
                                                    </Typography>
                                                }
                                                secondary={
                                                    <React.Fragment>
                                                        <b
                                                            style={{
                                                                fontSize:
                                                                    '1.1rem',
                                                                color: 'black',
                                                            }}
                                                        >
                                                            {sentHistoryData.length !==
                                                            null
                                                                ? sentHistoryData.title
                                                                : ''}
                                                        </b>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={6} sm={5} md={5}>
                                    <List>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <TodayIcon
                                                    fontSize="large"
                                                    style={{ color: '#3E9CD9' }}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        variant="body2"
                                                        component="p"
                                                        style={{
                                                            color: '#3E9CD9',
                                                        }}
                                                    >
                                                        Date
                                                    </Typography>
                                                }
                                                secondary={
                                                    <React.Fragment>
                                                        <b
                                                            style={{
                                                                fontSize:
                                                                    '1.1rem',
                                                                color: 'black',
                                                            }}
                                                        >
                                                            {sentHistoryData.length !==
                                                            null
                                                                ? sentHistoryData.created_at
                                                                : ''}
                                                        </b>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid>
                                    <Typography
                                        component="div"
                                        className="flex-left"
                                    >
                                        <NavigateNextIcon />
                                        <h3 style={{ color: '#3E9CD9' }}>
                                            After Update
                                        </h3>
                                    </Typography>
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                sentHistoryData.after_update
                                            }
                                        />
                                    </ListItem>
                                </Grid>
                                <hr
                                    style={{ color: '#3E9CD9', width: '100%' }}
                                />
                                <Grid>
                                    <Typography
                                        component="div"
                                        className="flex-left"
                                    >
                                        <NavigateBeforeIcon />
                                        <h3 style={{ color: '#3E9CD9' }}>
                                            Before Update
                                        </h3>
                                    </Typography>
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                sentHistoryData.before_update
                                            }
                                        />
                                    </ListItem>
                                </Grid>{' '}
                            </Grid>
                        </DialogContent>
                        <DialogActions
                            style={{
                                marginTop: '1.5rem',
                                alignItems: 'right',
                            }}
                        >
                            <Button
                                color="primary"
                                onClick={() => {
                                    closeDialog();
                                }}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        maxWidth="sm"
                        fullWidth
                        open={sponsorAdvisorDialog}
                        onBackdropClick={(e) => setSponsorAdvisorDialog(false)}
                    >
                        <DialogTitle style={{ color: '#3E9CD9' }}>
                            {sponsorAdvisorData.title}
                        </DialogTitle>
                        <DialogContent>
                            <Grid container justifyContent="center">
                                <Grid item xs={12} md={12}>
                                    <Formik
                                        initialValues={sponsorAdvisorData}
                                        onSubmit={(
                                            values,
                                            { setSubmitting }
                                        ) => {
                                            request
                                                .post(
                                                    `${config.API_BASE_URL}/sponsor-advisor-updates`,
                                                    values
                                                )
                                                .then((response) => {
                                                    enqueueSnackbar(
                                                        'Information has been updated successfully',
                                                        {
                                                            variant: 'success',
                                                        }
                                                    );

                                                    setKey(key + 1);
                                                    setSponsorAdvisorDialog(
                                                        false
                                                    );
                                                })
                                                .catch(() => {});
                                        }}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form
                                                className={classes.form}
                                                noValidate
                                            >
                                                <Grid item xs={12}>
                                                    <Field
                                                        component={TextField}
                                                        name="value"
                                                        type="text"
                                                        label=""
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        required
                                                        multiline
                                                        rows={5}
                                                    />
                                                </Grid>

                                                <DialogActions
                                                    style={{
                                                        marginTop: '1.5rem',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Button
                                                        color="secondary"
                                                        variant="outlined"
                                                        onClick={() => {
                                                            closeDialog();
                                                        }}
                                                    >
                                                        <b>Cancel</b>
                                                    </Button>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={isSubmitting}
                                                    >
                                                        Save
                                                    </Button>
                                                </DialogActions>
                                            </Form>
                                        )}
                                    </Formik>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        maxWidth="sm"
                        open={editDividendDialog}
                        onBackdropClick={(e) => setEditDividendDialog(false)}
                    >
                        <DialogTitle className={classes.dialogTitle}>
                            <Typography className={classes.dialogTitle}>
                                Edit Dividend
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container justifyContent="center">
                                <Grid item xs={12} md={12}>
                                    <Formik
                                        validationSchema={
                                            dividendValidationSchema
                                        }
                                        initialValues={dividendInitialValues}
                                        onSubmit={(
                                            values,
                                            { setSubmitting, resetForm }
                                        ) => {
                                            values[
                                                'dividend_finalization_date'
                                            ] = finalizationDate;
                                            values[
                                                'dividend_record_date'
                                            ] = recordDate;
                                            values[
                                                'dividend_payment_date'
                                            ] = paymentDate;
                                            values['quarter'] = quarter;
                                            values['item_to_update'] =
                                                'dividend';
                                            request
                                                .post(
                                                    `${config.API_BASE_URL}/disclosure-requirements/edit`,
                                                    values
                                                )
                                                .then((response) => {
                                                    enqueueSnackbar(
                                                        'Information has been updated successfully',
                                                        {
                                                            variant: 'success',
                                                        }
                                                    );
                                                    props.disclosureRequirementActions.getDisclosureRequirements();
                                                    setEditDividendDialog(
                                                        false
                                                    );
                                                    setIsEditedDividend(true);
                                                })
                                                .catch(() => {});
                                        }}
                                    >
                                        {({ isSubmitting }) => (
                                            <Form
                                                className={classes.form}
                                                noValidate
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                >
                                                    <Field
                                                        component={FormikSelect}
                                                        name="quarter"
                                                        variant="outlined"
                                                        label="Quarter"
                                                        required
                                                        fullWidth
                                                        onClick={changeQuarter}
                                                        defaultValue={'Q1'}
                                                    >
                                                        <MenuItem
                                                            key={'q1'}
                                                            value={'Q1'}
                                                        >
                                                            Q1
                                                        </MenuItem>
                                                        <MenuItem
                                                            key={'q2'}
                                                            value={'Q2'}
                                                        >
                                                            Q2
                                                        </MenuItem>
                                                        <MenuItem
                                                            key={'q3'}
                                                            value={'Q3'}
                                                        >
                                                            Q3
                                                        </MenuItem>
                                                        <MenuItem
                                                            key={'q4'}
                                                            value={'Q4'}
                                                        >
                                                            Q4
                                                        </MenuItem>
                                                    </Field>
                                                </Grid>
                                                <br />
                                                <Field
                                                    component={TextField}
                                                    type="text"
                                                    name="dividend_gross_amount_per_share"
                                                    variant="outlined"
                                                    //numeric={1}
                                                    required
                                                    fullWidth
                                                    label="Dividend"
                                                ></Field>
                                                <br /> <br />
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                >
                                                    <Field
                                                        component={
                                                            FormikDatePicker
                                                        }
                                                        name="dividend_finalization_date"
                                                        value={finalizationDate}
                                                        label="Finalization Date"
                                                        onChange={(date) =>
                                                            handleDateChange(
                                                                date,
                                                                'dividend_finalization_date'
                                                            )
                                                        }
                                                        format="MM/dd/yyyy"
                                                        variant="outlined"
                                                    />
                                                    <br /> <br />
                                                    <Field
                                                        component={
                                                            FormikDatePicker
                                                        }
                                                        name="dividend_record_date"
                                                        value={recordDate}
                                                        label="Record Date"
                                                        onChange={(date) =>
                                                            handleDateChange(
                                                                date,
                                                                'dividend_record_date'
                                                            )
                                                        }
                                                        format="MM/dd/yyyy"
                                                        variant="outlined"
                                                    />
                                                    <br /> <br />
                                                    <Field
                                                        component={
                                                            FormikDatePicker
                                                        }
                                                        name="dividend_payment_date"
                                                        value={paymentDate}
                                                        label="Payment Date"
                                                        onChange={(date) =>
                                                            handleDateChange(
                                                                date,
                                                                'dividend_payment_date'
                                                            )
                                                        }
                                                        format="MM/dd/yyyy"
                                                        variant="outlined"
                                                    />
                                                </LocalizationProvider>
                                                <DialogActions
                                                    style={{
                                                        marginTop: '1.5rem',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Button
                                                        color="secondary"
                                                        variant="outlined"
                                                        onClick={() => {
                                                            closeDialog();
                                                        }}
                                                    >
                                                        <b>Cancel</b>
                                                    </Button>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={isSubmitting}
                                                    >
                                                        Save
                                                    </Button>
                                                </DialogActions>
                                            </Form>
                                        )}
                                    </Formik>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        maxWidth="sm"
                        open={editKeyInputsDialog}
                        fullWidth
                        onBackdropClick={(e) => setEditKeyInputsDialog(false)}
                    >
                        <DialogTitle className={classes.dialogTitle}>
                            <Typography className={classes.dialogTitle}>
                                Edit Key Inputs
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container justifyContent="center">
                                <Typography>
                                    <h4
                                        style={{
                                            color: '#3E9CD9',
                                        }}
                                    >
                                        NOTE: Any changes here will be disclosed
                                        to listing advisor
                                    </h4>
                                </Typography>
                                <Grid item xs={12} md={12}>
                                    <Formik
                                        //validationSchema={keyInputsValidationSchema}
                                        initialValues={keyInputsInitialValues}
                                        onSubmit={(
                                            values,
                                            { setSubmitting }
                                        ) => {
                                            values['item_to_update'] =
                                                'key_inputs';
                                            values[
                                                'listing_date'
                                            ] = listingDate;
                                            values['agm_date'] = agmDate;
                                            values[
                                                'annual_financials_date'
                                            ] = annualFinancialsDate;
                                            /*  values['q1'] = q1;
                                            values['q2'] = q2;
                                            values['q3'] = q3;
                                            values['q4'] = q4;*/

                                            request
                                                .post(
                                                    `${config.API_BASE_URL}/disclosure-requirements/edit`,
                                                    values
                                                )
                                                .then((response) => {
                                                    enqueueSnackbar(
                                                        'Information has been updated successfully',
                                                        {
                                                            variant: 'success',
                                                        }
                                                    );
                                                    props.disclosureRequirementActions.getDisclosureRequirements();
                                                    setEditKeyInputsDialog(
                                                        false
                                                    );
                                                })
                                                .catch(() => {});
                                        }}
                                    >
                                        {({ isSubmitting }) => (
                                            <Container maxWidth="sm">
                                                <Form
                                                    className={classes.form}
                                                    noValidate
                                                >
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                    >
                                                        <Field
                                                            component={
                                                                FormikSelect
                                                            }
                                                            name="fiscal_year_end"
                                                            variant="outlined"
                                                            label="Fiscal Year End"
                                                            required
                                                            fullWidth
                                                            defaultValue="January"
                                                        >
                                                            {months.map(
                                                                (month) => (
                                                                    <MenuItem
                                                                        key={
                                                                            month.key
                                                                        }
                                                                        value={
                                                                            month.value
                                                                        }
                                                                    >
                                                                        {
                                                                            month.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Field>
                                                    </Grid>
                                                    <br />
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                    >
                                                        <Field
                                                            component={
                                                                FormikSelect
                                                            }
                                                            name="dividend"
                                                            variant="outlined"
                                                            label="Dividend"
                                                            required
                                                            fullWidth
                                                            onClick={
                                                                changeDividend
                                                            }
                                                        >
                                                            <MenuItem
                                                                key={'1'}
                                                                value={true}
                                                            >
                                                                YES
                                                            </MenuItem>
                                                            <MenuItem
                                                                key={'0'}
                                                                value={false}
                                                            >
                                                                NO
                                                            </MenuItem>
                                                        </Field>
                                                    </Grid>
                                                    {dividend && (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                        >
                                                            {' '}
                                                            <br />
                                                            <Field
                                                                component={
                                                                    FormikSelect
                                                                }
                                                                name="dividend_frequency"
                                                                variant="outlined"
                                                                label="Dividend Frequency"
                                                                required
                                                                fullWidth
                                                            >
                                                                <MenuItem
                                                                    key={'1'}
                                                                    value={
                                                                        'quarterly'
                                                                    }
                                                                >
                                                                    Quarterly
                                                                </MenuItem>
                                                            </Field>
                                                        </Grid>
                                                    )}
                                                    <br />
                                                    <Field
                                                        component={TextField}
                                                        type="text"
                                                        name="ticker"
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        label="Ticker"
                                                    ></Field>
                                                    <br /> <br />
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                    >
                                                        <Field
                                                            component={
                                                                FormikSelect
                                                            }
                                                            name="type"
                                                            variant="outlined"
                                                            label="Type"
                                                            required
                                                            fullWidth
                                                        >
                                                            <MenuItem
                                                                key={'1'}
                                                                value={'equity'}
                                                            >
                                                                Equity
                                                            </MenuItem>
                                                        </Field>
                                                    </Grid>
                                                    <br />
                                                    <LocalizationProvider
                                                        dateAdapter={
                                                            AdapterDateFns
                                                        }
                                                    >
                                                        <Field
                                                            component={
                                                                FormikDatePicker
                                                            }
                                                            name="listing_date"
                                                            value={listingDate}
                                                            label="Listing Date"
                                                            onChange={(date) =>
                                                                handleDateChange(
                                                                    date,
                                                                    'listing_date'
                                                                )
                                                            }
                                                            format="MM/dd/yyyy"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </LocalizationProvider>
                                                    {/* <br /> <br />{' '}
                                                    <Typography component="div">
                                                        <h3
                                                            style={{
                                                                color: '#3E9CD9',
                                                            }}
                                                        >
                                                            Prior Info
                                                        </h3>
                                                    </Typography>
                                                    <MuiPickersUtilsProvider
                                                        utils={DateFnsUtils}
                                                    >
                                                        <KeyboardDatePicker
                                                            name="agm_date"
                                                            value={agmDate}
                                                            label="AGM"
                                                            onChange={(date) =>
                                                                handleDateChange(
                                                                    date,
                                                                    'agm_date'
                                                                )
                                                            }
                                                            format="MM/dd/yyyy"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    <br /> <br />
                                                    <MuiPickersUtilsProvider
                                                        utils={DateFnsUtils}
                                                    >
                                                        <KeyboardDatePicker
                                                            name="annual_financials_date"
                                                            value={
                                                                annualFinancialsDate
                                                            }
                                                            label="Annual Financials"
                                                            onChange={(date) =>
                                                                handleDateChange(
                                                                    date,
                                                                    'annual_financials_date'
                                                                )
                                                            }
                                                            format="MM/dd/yyyy"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    {dividend && (
                                                        <Grid>
                                                            <br /> <br />
                                                            <Typography component="div">
                                                                <h3
                                                                    style={{
                                                                        color:
                                                                            '#3E9CD9',
                                                                    }}
                                                                >
                                                                    Dividend
                                                                </h3>
                                                            </Typography>
                                                            <MuiPickersUtilsProvider
                                                                utils={DateFnsUtils}
                                                            >
                                                                <KeyboardDatePicker
                                                                    name="q1"
                                                                    value={q1}
                                                                    label="Q1"
                                                                    onChange={(
                                                                        date
                                                                    ) =>
                                                                        handleDateChange(
                                                                            date,
                                                                            'q1'
                                                                        )
                                                                    }
                                                                    format="MM/dd/yyyy"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                            <br /> <br />
                                                            <MuiPickersUtilsProvider
                                                                utils={DateFnsUtils}
                                                            >
                                                                <KeyboardDatePicker
                                                                    name="q2"
                                                                    value={q2}
                                                                    label="Q2"
                                                                    onChange={(
                                                                        date
                                                                    ) =>
                                                                        handleDateChange(
                                                                            date,
                                                                            'q2'
                                                                        )
                                                                    }
                                                                    format="MM/dd/yyyy"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                            <br /> <br />
                                                            <MuiPickersUtilsProvider
                                                                utils={DateFnsUtils}
                                                            >
                                                                <KeyboardDatePicker
                                                                    name="q3"
                                                                    value={q3}
                                                                    label="Q3"
                                                                    onChange={(
                                                                        date
                                                                    ) =>
                                                                        handleDateChange(
                                                                            date,
                                                                            'q3'
                                                                        )
                                                                    }
                                                                    format="MM/dd/yyyy"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                            <br /> <br />
                                                            <MuiPickersUtilsProvider
                                                                utils={DateFnsUtils}
                                                            >
                                                                <KeyboardDatePicker
                                                                    name="q4"
                                                                    value={q4}
                                                                    label="Q4"
                                                                    onChange={(
                                                                        date
                                                                    ) =>
                                                                        handleDateChange(
                                                                            date,
                                                                            'q4'
                                                                        )
                                                                    }
                                                                    format="MM/dd/yyyy"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </Grid>
                                                    )}*/}
                                                    <DialogActions
                                                        style={{
                                                            marginTop: '1.5rem',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Button
                                                            color="secondary"
                                                            variant="outlined"
                                                            onClick={() => {
                                                                closeDialog();
                                                            }}
                                                        >
                                                            <b>Cancel</b>
                                                        </Button>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={
                                                                isSubmitting
                                                            }
                                                        >
                                                            Save
                                                        </Button>
                                                    </DialogActions>
                                                </Form>
                                            </Container>
                                        )}
                                    </Formik>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                    <EditDisclosureRequirementsDialog
                        open={editDialog}
                        data={editData}
                        initialValues={initialValues}
                        dialogTitle={dialogTitle}
                        fieldLabel={fieldLabel}
                        inputType={inputType}
                        key={key}
                        helptooltips={helptooltips}
                    />

                    <Dialog
                        maxWidth="md"
                        open={dividendQuarterDialog}
                        onBackdropClick={(e) => setDividendQuarterDialog(false)}
                    >
                        <DialogTitle className={classes.dialogTitle}>
                            <Typography className={classes.dialogTitle}>
                                Quarter Details
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container justifyContent="center">
                                <List>
                                    <ListItem>
                                        <ListItemAvatar
                                            className={classes.listItemAvatar}
                                        >
                                            <AttachMoneyIcon />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    className={
                                                        listClasses.disclosureRequirementsPrimaryList
                                                    }
                                                >
                                                    Gross Dividend Amount per
                                                    Asset
                                                </Typography>
                                            }
                                            secondary={
                                                <b
                                                    className={
                                                        listClasses.dialogSecondaryList
                                                    }
                                                >
                                                    {quarterData.dividend_gross_amount_per_share
                                                        ? quarterData.dividend_gross_amount_per_share
                                                        : 0}
                                                </b>
                                            }
                                        />
                                    </ListItem>{' '}
                                    <ListItem>
                                        <ListItemAvatar
                                            className={classes.listItemAvatar}
                                        >
                                            <FinalizationDateIcon />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    className={
                                                        listClasses.disclosureRequirementsPrimaryList
                                                    }
                                                >
                                                    Finalization Date
                                                </Typography>
                                            }
                                            secondary={
                                                <b
                                                    className={
                                                        listClasses.dialogSecondaryList
                                                    }
                                                >
                                                    {quarterData.dividend_finalization_date
                                                        ? DateFormatter({
                                                              value:
                                                                  quarterData.dividend_finalization_date,
                                                              format: 'date',
                                                          })
                                                        : 'N/A'}
                                                </b>
                                            }
                                        />
                                    </ListItem>{' '}
                                    <ListItem>
                                        <ListItemAvatar
                                            className={classes.listItemAvatar}
                                        >
                                            <TodayIcon />
                                        </ListItemAvatar>

                                        <ListItemText
                                            primary={
                                                <Typography
                                                    className={
                                                        listClasses.disclosureRequirementsPrimaryList
                                                    }
                                                >
                                                    Record Date
                                                </Typography>
                                            }
                                            secondary={
                                                <b
                                                    className={
                                                        listClasses.dialogSecondaryList
                                                    }
                                                >
                                                    {quarterData.dividend_record_date
                                                        ? DateFormatter({
                                                              value:
                                                                  quarterData.dividend_record_date,
                                                              format: 'date',
                                                          })
                                                        : 'N/A'}
                                                </b>
                                            }
                                        />
                                    </ListItem>{' '}
                                    <ListItem>
                                        <ListItemAvatar
                                            className={classes.listItemAvatar}
                                        >
                                            <PaymentDateIcon />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    className={
                                                        listClasses.disclosureRequirementsPrimaryList
                                                    }
                                                >
                                                    Payment Date
                                                </Typography>
                                            }
                                            secondary={
                                                <b
                                                    className={
                                                        listClasses.dialogSecondaryList
                                                    }
                                                >
                                                    {quarterData.dividend_payment_date
                                                        ? DateFormatter({
                                                              value:
                                                                  quarterData.dividend_payment_date,
                                                              format: 'date',
                                                          })
                                                        : 'N/A'}
                                                </b>
                                            }
                                        />
                                    </ListItem>{' '}
                                </List>
                            </Grid>
                        </DialogContent>
                        <DialogActions
                            style={{
                                marginTop: '1.5rem',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Button
                                color="primary"
                                onClick={() => {
                                    setDividendQuarterDialog(false);
                                }}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Form>
            </Formik>
        </LockedTile>
    );
};
const mapStateToProps = ({
    sponsorAdvisorUpdates,
    disclosureRequirements,
    profile,
}) => {
    return {
        sponsorAdvisorUpdates: sponsorAdvisorUpdates,
        disclosureRequirements: disclosureRequirements,
        profile: profile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        sponsorAdvisorUpdateActions: bindActionCreators(
            sponsorAdvisorUpdateActions,
            dispatch
        ),
        disclosureRequirementActions: bindActionCreators(
            disclosureRequirementActions,
            dispatch
        ),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DisclosureRequirements);

//export default DisclosureRequirements;
