import useSWR, { useSWRConfig } from "swr"
import { API_BASE_URL } from "../../config"
import fetcher from "../../utilities/fetcher"

const useMessages = (userId) => {
    const { data, error } = useSWR(() => userId ? `${API_BASE_URL}/messages/${userId}` : null, fetcher)
    const { mutate } = useSWRConfig()

    return {
        messages: data || [],
        messagesLoading: userId && !error && !data,
        messagesError: error,
        updateMessages: () => {
            mutate(`${API_BASE_URL}/messages/${userId}`)
        }
    }
}

export default useMessages