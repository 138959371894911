import request from '../../utilities/request';
import * as config from '../../config';
import * as snippetTypes from './snippetTypes';

export const getSnippetsSuccess = (summary) => {
    return {
        type: snippetTypes.GET_SNIPPETS_SUCCESS,
        payload: summary,
    };
};

export const setSnippetsLoading = (loading) => {
    return {
        type: snippetTypes.SET_SNIPPETS_LOADING,
        payload: loading,
    };
};

export const getSnippets = () => {
    return (dispatch) => {
        dispatch(setSnippetsLoading(true));
        request
            .get(`${config.API_BASE_URL}/snippets`)
            .then((res) => {
                dispatch(getSnippetsSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setSnippetsLoading(false));
            });
    };
};
