/**
 * Admin routes.
 */
export const DASHBOARD = 0;
export const LISTX = 1;
export const TREEBR = 2;
export const TRANSFER_AGENTS = 3;

/**
 * Transfer agent routes.
 */
 export const ISSUERS = 0;