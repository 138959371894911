export const getBasePath = ({ pathname }) => {
    if (pathname) {
        const match = pathname.match(/^\/([a-z-]+)/);
        if (match && match[1]) {
            return match[1];
        }
    }
    return '';
};

export const getPoppedPath = ({ pathname }) => {
    if (pathname) {
        return pathname.replace(/\/[a-z-]+\/?([0-9]+\/?)?$/, '');
    }
    return '';
};
