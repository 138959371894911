import React, { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import Paper from '../../../components/StyledPaper';
import CircularLoader from '../../../components/CircularLoader';
import LoadingOverlay from '../../../components/LoadingOverlay';
import Pagination from '../../../components/Pagination';

/* Redux */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as adminActions from '../../../store/admin/adminActions';

import Humanize from 'humanize-plus';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

const Issuers = (props) => {
    const { issuers, adminActions } = props;

    const history = useHistory();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [disabledButton, setDisabledButton] = useState(-1);

    /* Load the issuers */
    useEffect(adminActions.getIssuers, []);

    /* Log in as issuer */
    const loginAs = (email, dButton) => {
        setDisabledButton(dButton);
        adminActions.loginAs(email, closeSnackbar, enqueueSnackbar, history);
    };

    return (
        <div>
            {issuers.loading && !issuers.data ? (
                <CircularLoader /> // initial data load
            ) : issuers.data ? ( // has data from the backend
                <LoadingOverlay active={issuers.loading}>
                    <Grid container spacing={4}>
                        {issuers.data.map((issuer, id) => (
                            <Grid key={id} item xs={12} sm={6} md={4} lg={3}>
                                <Paper zoom={true} padding={10}>
                                    <Typography variant="h6">
                                        {issuer.name}
                                    </Typography>
                                    <Typography
                                        className="muted"
                                        variant="body1"
                                    >
                                        {issuer.company_name}
                                    </Typography>
                                    <Typography
                                        className="muted"
                                        variant="body2"
                                    >
                                        {issuer.email}
                                    </Typography>
                                    <Typography
                                        className="muted"
                                        variant="body2"
                                    >
                                        Member since: {issuer.created_at}
                                    </Typography>
                                    <br />
                                    <Typography variant="body2">
                                        Amount of assets:{' '}
                                        <b>
                                            {Humanize.formatNumber(
                                                issuer.shares
                                            )}
                                        </b>
                                    </Typography>
                                    <Typography variant="body2">
                                        Number of holders:{' '}
                                        <b>{issuer.investors}</b>
                                    </Typography>
                                    {/* <Typography variant="body2">Listed: </Typography> */}
                                    <br />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        onClick={() =>
                                            loginAs(issuer.email, id)
                                        }
                                        disabled={
                                            disabledButton === id &&
                                            props.loadingUser
                                        }
                                        fullWidth
                                    >
                                        {!(
                                            disabledButton === id &&
                                            props.loadingUser
                                        ) ? (
                                            <span>Log In</span>
                                        ) : (
                                            <CircularProgress size={30} />
                                        )}
                                    </Button>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </LoadingOverlay> // no data
            ) : (
                <Grid container justifyContent="center">
                    <Grid item>
                        <Typography variant="h6">No data available.</Typography>
                    </Grid>
                </Grid>
            )}
            {
                // Load the paginator only if there's data
                issuers.data && (
                    <Pagination
                        count={issuers.counts.lastPage}
                        callback={adminActions.getIssuers}
                    />
                )
            }
        </div>
    );
};

const mapStateToProps = ({ admin }) => {
    return {
        issuers: admin.issuers,
        loadingUser: admin.loginAsLoading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        adminActions: bindActionCreators(adminActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Issuers);
