import request from '../../utilities/request';
import * as config from '../../config';
import * as disclosureRequirementTypes from './disclosureRequirementTypes';

export const getDisclosureRequirementsSuccess = (summary) => {
    return {
        type: disclosureRequirementTypes.GET_DISCLOSURE_REQUIREMENTS_SUCCESS,
        payload: summary,
    };
};

export const setDisclosureRequirementsLoading = (loading) => {
    return {
        type: disclosureRequirementTypes.SET_DISCLOSURE_REQUIREMENTS_LOADING,
        payload: loading,
    };
};

export const getDisclosureRequirements = () => {
    return (dispatch) => {
        dispatch(setDisclosureRequirementsLoading(true));
        request
            .get(`${config.API_BASE_URL}/disclosure-requirements`)
            .then((res) => {
                dispatch(getDisclosureRequirementsSuccess(res.data));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                dispatch(setDisclosureRequirementsLoading(false));
            });
    };
};
