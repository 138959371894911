import { useCallback, useEffect, useRef } from "react";

/** 
 * useEffect() with a delay.
 */
export const useDebouncedEffect = (effect, delay, deps) => {
    const callback = useCallback(effect, deps);

    useEffect(() => {
        const handler = setTimeout(() => {
            callback();
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [callback, delay]);
}

/**
 * useEffect() which does not run on initial render.
 * Adjusted from: https://stackoverflow.com/a/57941438
 */
 export const useDidMountEffect = (func, deps) => {
     const didMount = useRef(false);
     const callback = useCallback(func, deps);

     useEffect(() => {
         if (didMount.current) callback();
         else didMount.current = true;
     }, [callback]);
 }