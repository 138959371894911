import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'

import editAmpCategory from '../../../../api/blockchain/editAmpCategory';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Input from '../../../../components/Form/Input';
import SubmitButton from '../../../../components/Form/SubmitButton';

import { snackbar } from '../../../../utilities/snackbarUtils'
import { errorParser } from '../../../../components/LiquidForm/utils/errorParser';

const EditCategoryDialog = ({ open, handleClose, category, updateCategories }) => {
    const schema = yup.object({
        name: yup.string().required('Category name is required.'),
        description: yup.string().required('Category description is required.'),
    });

    const defaultValues = {
        name: '',
        description: '',
    }

    const { handleSubmit, control, reset, formState: { isSubmitting, errors }, } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    React.useEffect(() => {
        reset({
            name: category?.name,
            description: category?.description,
        })
    }, [category])

    const onSubmit = async (data) => {
        await editAmpCategory(category?.id, data).then(response => {
            snackbar.success('Whitelist category successfully updated.');
            handleClose()
            updateCategories()
        }).catch((error) => {
            snackbar.error(errorParser(error));
        })
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Edit the whitelist</DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <div style={{ display: 'flex', color: 'gray' }}>
                        <Typography variant='body2'>
                            Edit whitelist details
                        </Typography>
                    </div>
                    <br />
                    <Input
                        autoFocus
                        name="name"
                        label="Name"
                        type="text"
                        fullWidth
                        error={errors?.name}
                        control={control}
                        variant="outlined"
                        placeholder="Whitelist name..."
                    />
                    <br />
                    <br />
                    <Input
                        autoFocus
                        name="description"
                        label="Description"
                        type="text"
                        fullWidth
                        error={errors?.description}
                        control={control}
                        variant="outlined"
                        placeholder="Whitelist description..."
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <SubmitButton 
                        text='Update'
                        disabled={isSubmitting}
                        loading={isSubmitting}
                    />
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default EditCategoryDialog